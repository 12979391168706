import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Avatar, Col, List, Popover, Row, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { Button } from 'components/UIExternal';
import { Tabs, TabPane } from 'components/ui';
import { useUser } from 'stores';
import apiRoutes from 'config/apiRoutes';
import { chatStatus } from 'enums/ChatEnum';
import { formatDefaultDate, formatTime } from 'utils/valueFormatter';
import { delay } from 'utils/queryUtils';
import { markChatAsArchived } from 'services/api/chatService';
import useError from 'services/errorHandling/useError';
import { usePageNumberPaging } from 'services/axios/paging';
import noChatsImg from 'assets/images/chat/no-messages.png';
import { mb, mt } from 'assets/css/globalCSS';
import { loadMoreBtn } from '../../../MerchantOffice/components/styles';
import {
  filterButtons,
  notificationsTable,
  typeTime,
  message,
  moreBtn,
  mobileWrapperTypeMessage,
  bold,
  noNotificationImage,
  noChatsContainer,
  dealTitle,
  filterWrapper,
  messageWrapper,
  blackTextLink,
} from './styles';

const pageSize = 5;
const getChatsURLFunctions = {
  inbox: apiRoutes.CHAT_LIST_CHATS,
  'group-messages': apiRoutes.CHAT_LIST_GROUP_CHATS,
  moderators: apiRoutes.CHAT_LIST_MODERATOR_CHATS,
};

const MessagesTab = () => {
  const theme = useTheme();
  const history = useHistory();
  const { setError } = useError();
  const currentAccountId = useUser()?.userInfo?.id;
  const { getText } = useTranslations();
  const [pageNumber, setPageNumber] = useState(1);
  const [activeTab, setActiveTab] = useState('inbox');
  const [noMoreMessages, setNoMoreMessages] = useState(false);

  const [loadedChats, loadNewPage, loadNewFilter, reloadAllLoadedPages, isLoading] = usePageNumberPaging(
    getChatsURLFunctions[activeTab],
    pageSize,
  );

  useEffect(() => {
    loadNewFilter();
  }, [activeTab]);

  useEffect(() => {
    pageSize * pageNumber - loadedChats?.length > 0 ? setNoMoreMessages(true) : setNoMoreMessages(false);
  }, [loadedChats]);

  const onTabClickHandler = e => setActiveTab(e);

  const chatContent = (
    <>
      <List
        className={notificationsTable(theme)}
        itemLayout="horizontal"
        bordered={!isMobile && 'true'}
        dataSource={loadedChats}
        renderItem={chat => {
          const isCurrentUser = chat.lastMessageSenderName !== chat.name;
          return (
            <List.Item className={chat.statusId === chatStatus.Unread.id && 'unread'}>
              {isMobile ? (
                <Link to={`/chat?chatId=${chat.id}`} className={mobileWrapperTypeMessage}>
                  <div className={typeTime(theme)}>
                    <Avatar.Group maxCount={2} onClick={() => history.push(`/chat?chatId=${chat.id}`)} size="large">
                      {chat.participants
                        .filter(user => user.id !== currentAccountId)
                        .map((participant, i) => (
                          <Avatar
                            key={i}
                            onClick={() => history.push(`/chat?chatId=${chat.id}`)}
                            src={participant.avatarThumbnailUrl}
                          >
                            {participant.firstName?.charAt(0)}
                          </Avatar>
                        ))}
                    </Avatar.Group>
                    <Link to={`/chat?chatId=${chat.id}`} className="flex flex-column">
                      <label>{chat.name}</label>
                      <label className="time">{moment(chat?.lastMessageDate).format('DD/MM/YYYY · hh:mm')}</label>
                    </Link>
                    <Popover
                      trigger="click"
                      placement="bottom"
                      className={chat.statusId === chatStatus.Unread.id && 'unread'}
                      content={
                        <div className="flex-column">
                          <Button
                            type="link"
                            small
                            onClick={async () => {
                              await markChatAsArchived(chat.id, setError);
                              reloadAllLoadedPages();
                            }}
                            className={blackTextLink(theme)}
                          >
                            {getText('archive')}
                          </Button>
                          <Button type="link" small theme={theme} className={blackTextLink(theme)}>
                            {getText('viewMerchant')}
                          </Button>
                          {chat.deal && (
                            <Button
                              type="link"
                              small
                              onClick={() => history.push(`/deal/${chat.deal.name}`)}
                              className={blackTextLink(theme)}
                            >
                              {getText('viewDeal')}
                            </Button>
                          )}
                        </div>
                      }
                    >
                      <Button type="info" small className={moreBtn(theme)}>
                        <FeatherIcon icon="more-vertical" size={20} />
                      </Button>
                    </Popover>
                  </div>
                  {chat.deal?.title && (
                    <label
                      className={`${chat.statusId === chatStatus.Unread.id && bold(theme)} ${message} ${dealTitle}`}
                    >
                      {chat.deal.title}
                    </label>
                  )}
                  {chat.deal?.description && <label>{chat.deal.description}</label>}
                </Link>
              ) : (
                <Row style={{ width: '100%' }}>
                  <Col lg={6} span={24}>
                    <Link to={`/chat?chatId=${chat.id}`} style={{ cursor: 'pointer' }} className="flex">
                      <Avatar.Group
                        style={{ cursor: 'pointer' }}
                        maxCount={2}
                        onClick={() => history.push(`/chat?chatId=${chat.id}`)}
                        size="large"
                      >
                        {chat.participants
                          .filter(user => user.id !== currentAccountId)
                          .map((participant, i) => (
                            <Avatar
                              style={{ cursor: 'pointer' }}
                              key={i}
                              onClick={() => history.push(`/chat?chatId=${chat.id}`)}
                              src={participant.avatarThumbnailUrl}
                            >
                              {participant.firstName?.charAt(0)}
                            </Avatar>
                          ))}
                      </Avatar.Group>
                      <Link style={{ cursor: 'pointer' }} to={`/chat?chatId=${chat.id}`} className={typeTime(theme)}>
                        <label className={`${chat.statusId === chatStatus.Unread.id && bold(theme)} type`}>
                          {chat.name}
                        </label>
                        <br />
                        <label className="time">
                          {formatDefaultDate(chat.lastMessageDate)}
                          <span className="icon-Bullet" />
                          {formatTime(chat.lastMessageDate)}
                        </label>
                      </Link>
                    </Link>
                  </Col>
                  <Col lg={16} span={24} className={`${messageWrapper(theme, isCurrentUser)}`}>
                    {(chat?.deal?.title || chat?.lastMessageText) && (
                      <Link to={`/chat?chatId=${chat.id}`} style={{ cursor: 'pointer' }} className="flex-column">
                        {!chat.deal?.title && (
                          <label
                            className={`${chat.statusId === chatStatus.Unread.id &&
                              bold(theme)} ${message} ${dealTitle} type flex-column`}
                          >
                            {chat?.deal?.title}
                          </label>
                        )}
                        {chat.lastMessageText && (
                          <>
                            <div
                              className={`flex space-between alignItemsCenter ${(isCurrentUser && 'reverse-avatar') ||
                                'avatar'}`}
                            >
                              <div className={(isCurrentUser && 'reverse-avatar') || ''}>
                                <Avatar src={!isCurrentUser && chat.participants[0]?.avatarThumbnailUrl}>
                                  {chat.name?.charAt(0)}
                                </Avatar>
                                <label className="user-name-label">{isCurrentUser ? getText('you') : chat.name}</label>
                              </div>
                              <label className="hour-label">{formatTime(chat.lastMessageDate)}</label>
                            </div>
                            <label className="message">{chat.lastMessageText}</label>
                          </>
                        )}
                      </Link>
                    )}
                  </Col>
                  <Col lg={{ span: 1, offset: 1 }} span={24}>
                    <Popover
                      trigger="click"
                      placement="bottom"
                      className={chat?.statusId === chatStatus.Unread.id && 'unread'}
                      content={
                        <>
                          <Button
                            type="link"
                            small
                            className={blackTextLink(theme)}
                            onClick={async () => {
                              await markChatAsArchived(chat.id, setError);
                              reloadAllLoadedPages();
                            }}
                          >
                            {getText('archive')}
                          </Button>
                          {chat?.deal && (
                            <div className="flex-column">
                              {chat?.participants?.length === 1 && (
                                <Button
                                  type="link"
                                  small
                                  className={blackTextLink(theme)}
                                  linkTo={`/merchant/${chat.deal?.businessId}/overview`}
                                >
                                  {getText('viewMerchant')}
                                </Button>
                              )}
                              <Button
                                type="link"
                                small
                                className={blackTextLink(theme)}
                                linkTo={`/deal/${chat.deal.name}`}
                              >
                                {getText('viewDeal')}
                              </Button>
                            </div>
                          )}
                        </>
                      }
                    >
                      <Button type="link" small className={moreBtn(theme)}>
                        <FeatherIcon icon="more-vertical" size={20} />
                      </Button>
                    </Popover>
                  </Col>
                </Row>
              )}
            </List.Item>
          );
        }}
      />
      {!noMoreMessages && (
        <Button
          type="secondary"
          small
          onClick={() =>
            delay(() => {
              loadNewPage(pageNumber + 1);
              setPageNumber(prev => prev + 1);
            })
          }
          className={loadMoreBtn}
        >
          {getText('loadMore')}
        </Button>
      )}
    </>
  );

  const noChats = (
    <div className={noChatsContainer(theme)}>
      <img className={noNotificationImage} src={noChatsImg} alt="No chats" />
      <div className="flex flex-column">
        <h4>{getText('dontHaveMessages')}</h4>
        <label>{getText('yourMessagesAppearHere')}</label>
        <Button type="primary" small linkTo="/listing">
          {getText('browseDeals')}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div className={filterWrapper}>
        <div className={filterButtons(theme)}>
          <div className="content-container">
            <Tabs
              destroyInactiveTabPane
              defaultActiveKey={activeTab}
              activeKey={activeTab}
              onTabClick={onTabClickHandler}
              theme={theme}
            >
              <TabPane
                tab={
                  <>
                    <FeatherIcon icon="inbox" size={12} stroke={activeTab === 'inbox' ? theme.blue : theme.gray300} />
                    {getText('inbox')}
                  </>
                }
                key="inbox"
              >
                {/* {chats.filter(chat => chat.statusId === chatStatus.Unread.id).length} */}
              </TabPane>
              <TabPane
                tab={
                  <>
                    <FeatherIcon
                      icon="users"
                      size={12}
                      stroke={activeTab === 'group-messages' ? theme.blue : theme.gray300}
                    />
                    {getText('groupMessage')}
                  </>
                }
                key="group-messages"
              />
              <TabPane
                tab={
                  <>
                    <FeatherIcon
                      icon="shield"
                      size={12}
                      stroke={activeTab === 'moderators' ? theme.blue : theme.gray300}
                    />
                    {getText('moderators')}
                  </>
                }
                key="moderators"
              />
              <TabPane
                tab={
                  <Button type="link" small className="start-chat" linkTo="/chat">
                    {getText('startChat')}
                  </Button>
                }
              />
            </Tabs>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className={`flex flex-center ${mt({ lg: 50, md: 20 })} ${mb({ lg: 50, span: 20 })}`}>
          <Spin size="large" />
        </div>
      ) : loadedChats.length > 0 ? (
        chatContent
      ) : (
        noChats
      )}
    </>
  );
};

export default MessagesTab;
