import React, { useRef } from 'react';
import { useTheme } from 'emotion-theming';
import { Table, useUrlParams } from 'components/UIExternal';
import { getCacheVal } from 'utils/cacheUtils';
import { getPromoCodes, disablePromoCode, enablePromoCode } from 'services';
import { merchantOfficeTab } from '../components/styles';
import { codeListColumns } from './config';
import { PromoCodesFilterBar } from './PromoCodeFilterBar';

const filterKeys = {
  statusId: 'statusId',
  promoCodeSorting: 'promoCodeSorting',
};

const PromoCodes = () => {
  const theme = useTheme();
  const accountId = getCacheVal('accountId');
  const { queryParams } = useUrlParams();
  const tableRef = useRef();

  const handleCodeStatus = async (statusId, promoCodeId) => {
    statusId === 1 ? await enablePromoCode(promoCodeId) : await disablePromoCode(promoCodeId);
    tableRef.current?.refreshData();
  };

  return (
    <Table
      ref={tableRef}
      hasPagination
      className={merchantOfficeTab}
      filterBar={PromoCodesFilterBar}
      pageSize={10}
      withPageDropdown
      filterKeys={filterKeys}
      getDataMethod={options => getPromoCodes({ ...options, ...queryParams, accountId })}
      columns={codeListColumns(theme, handleCodeStatus)}
    />
  );
};

export default PromoCodes;
