import queryString from 'query-string';
import { isNumber, pick } from '@veraio/core';
import { calculateDealPrice } from '@oneecosystem/dealshaker-core';

export const brandMaterialsParams = options => {
  const { businessId, dealsSorting, searchText, statusId } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(businessId) && { businessId }),
      ...(isNumber(dealsSorting) && { dealsSorting }),
      ...(isNumber(statusId) && { statusId }),
      ...(searchText && searchText?.length && { searchText }),
    },
    { arrayFormat: 'index' },
  );
};

export const brandMaterialsBody = res => ({ ...res, items: res.items.map(el => calculateDealPrice(el)) });
