import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { isFunction, pick, isArray } from '@veraio/core';
import { useTheme } from 'emotion-theming';
import { Button } from 'components/UIExternal';
import { addressesStyles, addressCart, addCart } from './styles';

const AddressesSmallList = props => {
  const { addresses = [], active, onChange, addLink } = props;
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();

  return (
    <div className={addressesStyles}>
      {addresses?.map((address, idx) => (
        <Button
          key={idx}
          className={addressCart(theme, address?.id === active)}
          type="default"
          onClick={() => isFunction(onChange) && onChange(address.id)}
        >
          <h3>{isArray(address.name) ? getDynamicTranslation(address.name)?.name : address.name}</h3>
          <p>{address.street}</p>
          <p>
            {Object.values(pick(address, ['city', 'state', 'postcode', 'country']))
              .filter(Boolean)
              .join(', ')}
          </p>
          <p>{`+${address.phoneNumber}`}</p>
        </Button>
      ))}
      {addLink && (
        <Button type="link" small linkTo={addLink} className={addCart(theme)}>
          <FeatherIcon icon="plus" size={20} />
          <p>{getText('addNewAddress')}</p>
        </Button>
      )}
    </div>
  );
};

AddressesSmallList.propTypes = {
  addresses: PropTypes.array,
  active: PropTypes.any,
  onChange: PropTypes.func,
  addLink: PropTypes.string,
};

export default AddressesSmallList;
