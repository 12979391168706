import { css } from 'emotion';

export const selectCategoryDropDown = css({
  margin: '0',
  width: '21rem',
});

export const selectSubCategoryDropDown = css({
  margin: '0',
  width: '21rem',
});

export const categoryContainer = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1em',
});
