import { css } from 'emotion';

export const merchantBusinessInfoCard = (selected, hasHover, theme) =>
  css({
    boxShadow: '0px 1px 2px 1px rgba(0, 0, 0, 0.08)',
    background: selected ? theme.gray600 : theme.white,
    cursor: hasHover ? 'pointer' : 'default',
    marginBottom: '1em',
    padding: '1em',
    display: 'block',
    color: theme.gray100,
    whiteSpace: 'break-spaces',
    '&:hover': hasHover && {
      background: selected ? theme.gray600 : theme.gray700,
    },
    '& .business-header': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: '0.6em',
    },
    '& .business-rating': {
      color: theme.gray100,
      fontSize: '0.9rem',
      '& .ant-rate-star:not(:last-child)': {
        marginRight: '0.35em',
      },
    },
    '& .business-rating-count': {
      fontSize: '0.86rem',
      marginLeft: '0.6em',
    },
    '& .business-active-deals-count': {
      color: theme.gray300,
      fontSize: '0.8rem',
      fontWeight: 500,
      marginBottom: '0.6rem',
    },
    '& .business-images': {
      display: 'flex',
      gap: '0.7rem',
      marginBottom: '0.6rem',
    },
    '& .business-image': {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '3rem',
    },
    '& .business-address': {
      fontSize: '0.85rem',
    },
    '& .working-time': {
      color: theme.gray300,
      fontSize: '0.8rem',
      marginTop: '0.6em',
    },
  });
