import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const globalStructureFindManagerContainer = theme =>
  css({
    position: 'relative',
    minHeight: '35rem',
    height: 'auto',
    backgroundColor: theme.gray600,
    borderRadius: '30px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 3em',
    '& .managers': {
      [Mobile]: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4em 0',
        flexWrap: 'wrap',
      },
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      gap: '5rem',
    },
  });

export const globalStructureFindManagerTitle = css({ textAlign: 'center', marginBottom: '1em' });

export const managerSearchInput = theme =>
  css({
    position: 'absolute',
    width: '30rem',
    height: '3rem',
    margin: '0 auto',
    left: 0,
    right: 0,
    top: '-1.5em',
    background: theme.white,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 50,
    '& > div': {
      border: 'none',
    },
  });

export const countryNotFoundContainer = css({
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto',
  '& .content': {
    marginLeft: '2em',
  },
});

export const contactUs = css({
  display: 'block',
  marginTop: '1em',
});
