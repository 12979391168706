import { css } from 'emotion';

export const globalStructureFindManagerProfile = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '14rem',
    '& .name': {
      fontWeight: 600,
      marginTop: '1em',
    },
    '& .country': {
      textTransform: 'uppercase',
      margin: '1em 0',
      color: theme.gray300,
    },
  });

export const globalStructureFindManagerProfileImage = css({
  borderRadius: '100%',
});
