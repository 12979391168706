import React from 'react';
import PropTypes from 'prop-types';
import { isString } from '@veraio/core';
import { useTheme } from 'emotion-theming';
import { baseIcon } from './styles';

const Icon = props => {
  const { onClick, style, className, iconName } = props;
  const theme = useTheme();
  const classList = [isString(className) && className, iconName].filter(Boolean).join(' ');

  return <i role="presentation" className={`${baseIcon(props, theme)} ${classList}`} style={style} onClick={onClick} />;
};

Icon.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  iconName: PropTypes.string,
  material: PropTypes.bool,
};

export default Icon;
