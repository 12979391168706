import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const noAddressesContainer = css({
  marginTop: '2.5em',
  marginBottom: '5em',
  alignItems: 'center',
  [Mobile]: {
    marginTop: '5em',
  },
});

export const noAddressesText = theme =>
  css({
    color: theme.gray200,
    fontSize: '1.125rem',
    fontWeight: 700,
    [Mobile]: {
      marginTop: '1.25em',
    },
    [Desktop]: {
      marginLeft: '1.5em',
    },
  });

export const addressesList = css({
  marginTop: '1.875em',
  marginBottom: '5em',
  [Mobile]: {
    marginBottom: '2.5em',
  },
});

export const addressBox = (theme, isPublic) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1em',
    [Desktop]: {
      boxShadow: !isPublic && '0px 3px 4px 0px #00000014',
      border: isPublic ? `2px solid ${theme.gray500}` : `1px solid ${theme.gray800}`,
      minHeight: '38.4375rem',
    },
    [Mobile]: {
      border: `2px solid ${theme.gray500}`,
    },
  });

export const topContentWrapper = theme =>
  css({
    width: '100%',
    borderBottom: `1px solid ${theme.gray500}`,
    paddingBottom: '0.5em',
    '& label': {
      fontSize: '0.8125em',
      fontWeight: 500,
      color: theme.gray300,
    },
  });

export const addressTitle = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h6': {
      fontSize: '1.1875rem',
      fontWeight: 700,
      color: theme.gray100,
      marginBottom: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& button': {
      border: 'none',
      background: theme.white,
      marginLeft: 'auto',
      fontSize: '1.1875rem',
    },
    '& button:hover': {
      background: theme.ui_hover,
    },
  });

export const messageWrapper = theme =>
  css({
    background: theme.ui_hover_rgba,
    padding: '0.5625em',
    textAlign: 'center',
    marginTop: '0.3125em',
    width: '100%',
    '& .comment-message-updater': {
      display: 'flex',
      fontSize: '0.75rem',
      marginBottom: '0.25em',
      textAlign: 'left',
    },
    '& .comment-message-button': {
      fontSize: '0.75rem',
      textDecoration: 'underline',
      color: theme.blue2,
      background: 'transparent',
      border: 'none',

      '&:hover': {
        textDecoration: 'underline',
        color: theme.primary_blue_hover,
      },
    },
  });

export const commentMessage = css({
  fontSize: '0.8125rem !important',
  fontWeight: 500,
});

export const infoTextWrapper = theme =>
  css({
    marginTop: '0.625em',
    marginBottom: 0,
    color: theme.gray100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    '& label': {
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray200,
    },
  });

export const titleForm = theme =>
  css({
    marginBottom: '0.875em',
    fontWeight: 500,
    fontSize: '2.375rem',
    color: theme.background100,
    [Mobile]: {
      fontSize: '1.375rem',
    },
  });

export const subtitle = theme =>
  css({
    fontSize: '1rem',
    color: theme.gray100,
  });

export const addressFormWrapper = () =>
  css({
    marginTop: '1.875em',
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: '0.8125rem !important',
      fontWeight: 500,
    },
    '& .ant-input::placeholder, .ant-input-number-input::placeholder, .ant-select-selection-placeholder': {
      fontSize: '0.75rem',
    },
    '& .ant-form-item-label': {
      paddingBottom: '0 !important',
    },
  });

export const formSectionWrapper = theme =>
  css({
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      padding: '1.25em 1.75em',
      marginBottom: '1.5em',
    },
  });

export const merchantProfileTags = theme =>
  css({
    '& .ant-checkbox': {
      display: 'none',
    },
    '& .ant-checkbox-wrapper': {
      border: `1px solid ${theme.primary_blue}`,
      borderRadius: 4,
      padding: '0.25em 0.5em',
      fontWeight: 400,
      marginTop: '0.5em',
      '&.ant-checkbox-wrapper-checked': {
        border: '1px solid green',
        background: `${theme.skyblue}`,
      },
    },
  });

export const mobileLabel = css({
  fontWeight: 700,
});

export const dayTimeWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  width: '70%',
  [Mobile]: {
    width: '100%',
  },
});

export const dayTimeInput = css({
  width: '47%',
  gap: '1em',
  '& .ant-picker': {
    width: '100%',
  },
  [Mobile]: {
    flexDirection: 'row !important',
  },
});

export const mandatoryStar = css({
  marginRight: '0.25em',
  color: '#ff4d4f',
  fontSize: '0.875rem',
  fontFamily: 'SimSun, sans-serif',
  lineHeight: 1,
});

export const formButtons = isInModal =>
  css({
    marginBottom: isInModal ? 0 : '4.875em',
    display: 'flex !important',
    justifyContent: 'space-between',
    '& .confirm-btn': {
      width: isInModal && '100%',
    },
    '& span': {
      margin: 'auto',
    },
    [Mobile]: {
      flexDirection: 'column-reverse',
      marginBottom: '3em',
      '& .confirm-btn': {
        minHeight: '2.5rem',
      },
    },
  });

export const languagesFormWrapper = css({
  marginBottom: '0px !important',
});

export const tabs = theme =>
  css({
    overflow: 'unset !important',
    '& .ant-tabs-tab': {
      background: 'none !important',
      border: 'none !important',
    },
    '& .ant-tabs-tab:first-child': {
      paddingLeft: '0px !important',
    },
    '& .ant-tabs-tab-active .ant-tabs-tab-btn': {
      borderBottom: `2px solid ${theme.primary_yellow} !important`,
    },
    '& .ant-tabs-nav::before': {
      borderBottom: 'none !important',
    },
  });

export const blackTextLink = theme =>
  css({
    color: theme.gray100,
  });

export const selectLangDropdown = css({
  minWidth: '12.5rem',
  '& div:nth-child(2)': {
    maxHeight: '12.1875rem',
  },
});

export const phoneCodeStyles = css({
  lineHeight: '1.5715',
  height: 'auto',
  borderRadius: '4px !important',
  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export const countryDropdownStyles = css({
  lineHeight: '1.5715',
  height: 'auto',
  borderRadius: '4px',
  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});
