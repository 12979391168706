import { css } from 'emotion';
import { Mobile } from '../../../assets/css/breakpoints';

export const featuredDealsPanel = theme =>
  css({
    backgroundColor: theme.gray600,
    '& .slick-list': {
      margin: '0 -0.9375em',
      padding: '0px !important',
    },
    '& .slick-slide > div': {
      padding: '0 0.9375em',
    },
    '& .slick-arrow:before': {
      display: 'none',
    },
  });

export const contentWrapper = css({
  [Mobile]: {
    marginTop: '-2em',
    '& > h4': {
      paddingBottom: '1.5em',
    },
  },
  paddingBottom: '4.375em',
});

export const title = theme =>
  css({
    color: theme.background100,
    fontSize: '1.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
  });

export const arrowButton = position =>
  css({
    position: 'absolute',
    top: '50%',
    cursor: 'pointer',
    zIndex: 900,
    margin: position === 'right' ? '0 0 0 1.25em' : '0 0 0 -1.25em',
    left: position === 'left' ? '-10px' : '3px',
  });
