import React from 'react';
import moment from 'moment';
import { notificationType } from 'enums';
import { typeTime, notificationIcon, notificationIsRead } from './styles';

const toLocal = date => moment.utc(date).local();

export const tableColumns = (getText, theme) => [
  {
    render: notification => (
      <img className={notificationIcon} src={notificationType[notification.type]?.icon} alt={notification.type} />
    ),
    width: 50,
  },
  {
    name: getText('title'),
    render: notification => {
      const timeContainer = (
        <div className="time">
          <span>{moment(notification.publishDate ?? notification.createdAt).format('DD/MM/YYYY')}</span>
          <span className="icon-Bullet" />
          <span>{moment(notification.publishDate ?? toLocal(notification.createdAt)).format('HH:mm')}</span>
        </div>
      );

      return notification.notificationManual ? (
        <div>
          <h4 className="title">{notification.notificationManual?.title}</h4>
          {timeContainer}
        </div>
      ) : (
        <div className={typeTime(theme)}>
          <h4 className={notificationIsRead(notification.isRead, theme)}>
            {getText(`${notification.type}Title`, notification.additionalData)}
          </h4>
          {timeContainer}
        </div>
      );
    },
    width: 160,
  },
  {
    name: getText('message'),
    render: notification =>
      notification.notificationManual ? (
        <div className={notificationIsRead(notification.isRead, theme)}>
          <h4>{notification.notificationManual?.title}</h4>
          <p>{notification.notificationManual?.message}</p>
        </div>
      ) : (
        <p className={notificationIsRead(notification.isRead, theme)}>
          {getText(`${notification.type}Message`, notification.additionalData)}
        </p>
      ),
  },
];
