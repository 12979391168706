import { mapValues, pick, isArray, isStrNum, isFunction, isNil, isPlainObject } from '@veraio/core';

export const performAction = (value, action) => {
  if (isFunction(action)) return isArray(value) ? value.map(action) : action(value);
  return null;
};

export const removeNodes = (options, displayKey = 'label', uniqueKey = 'value') => {
  const remove = element =>
    isStrNum(element) ? element : mapValues(element, val => pick(val, [displayKey, uniqueKey]));
  return performAction(options, remove);
};

export const prepareValue = ({ value, options, multiSelect, uniqueKey }) => {
  const setValue = val => {
    const match = options?.find(el => (isPlainObject(val) ? el[uniqueKey] === val[uniqueKey] : el[uniqueKey] === val));
    return match ?? val;
  };

  return isNil(value) ? (multiSelect ? [] : null) : performAction(value, setValue);
};

export const outputValue = (value, displayKey, mappingFunction, shouldSimplify) => {
  if (!shouldSimplify) return value;

  const getSimplify = val => (val?.simple ? val[displayKey] : val);
  const simpleValue = performAction(value, getSimplify);

  return isFunction(mappingFunction) ? mappingFunction(simpleValue) : simpleValue;
};

export const prepareOptions = (options, displayKey, uniqueKey) =>
  isArray(options) ? options.map(el => (isStrNum(el) ? { [displayKey]: el, [uniqueKey]: el, simple: true } : el)) : [];
