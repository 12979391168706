import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const anonymousMiniUserProfileMenu = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.white,
    width: '30rem',
    '& .login-title': {
      marginBottom: '1em',
    },
    '& .ant-form': {
      width: '100%',
      '& .email-input label': {
        marginRight: 27,
      },
    },
    '& .ant-popover-arrow': {
      display: 'none !important',
      '& .ant-popover-arrow-content': {
        display: 'none !important',
      },
    },
  });

export const popoverWrapper = css({
  [Mobile]: {
    width: '100% !important',
    position: 'fixed !important',
    left: '0px !important',
    borderRadius: 4,
    paddingTop: '0px !important',
    '& .ant-popover-content': {
      width: '100%',
    },
  },
  position: 'fixed !important',
  paddingTop: '0px !important',
});

export const userDefaultImageIcon = theme =>
  css({
    border: `1px solid ${theme.white}`,
    borderRadius: '15px',
    padding: '0.1875em',
  });
