import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { isNil, isEmpty } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Popover, Modal } from 'antd';
import BaseDeal from 'components/deal/BaseDeal';
import { SearchInput } from 'components/ui';
import { Button, useUrlParams } from 'components/UIExternal';
import { getAvailableDealsToConnect, addConnectedDeals } from 'services/api/dealsService';
import useError from 'services/errorHandling/useError';
import noDealsImg from 'assets/images/addDeal/star-box.svg';
import { blackTextLink } from 'assets/css/globalCSS';
import {
  mainContainer,
  titleWrapper,
  dealsContainer,
  noDealsContainer,
  singleDealContainer,
  connectedDealsModal,
  buttonsWrapper,
  availableDealsContainer,
  popoverWrapper,
} from './styles';

const ConnectedDealsPanel = ({ deals, dealId, isMerchant }) => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const { dealName } = useParams();
  const { setError } = useError();
  const { queryParams, setQueryParams } = useUrlParams();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [availableDeals, setAvailableDeals] = useState([]);

  useEffect(() => {
    fetchDeals();
  }, []);

  const fetchDeals = async options => {
    const params = { ...queryParams, ...options };
    setQueryParams(params);
    const [res, err] = await getAvailableDealsToConnect(dealId, params);
    err && setError(err);
    setAvailableDeals(res?.availabledeals);
    setSelectedDeals(res?.selectedDealIds);
  };

  const handleAddDeals = async () => {
    const [, err] = await addConnectedDeals({ dealId, connectedDealIds: selectedDeals });
    err ? setError(err) : history.push(`/deal/${dealName}/connected-deals`);
  };

  const dealsContent = (
    <div className={`${dealsContainer} btn-and-img`}>
      {deals?.map((deal, idx) => (
        <div key={idx} className={singleDealContainer}>
          <Button type="link" linkTo={`/deal/${deal?.name}`}>
            <BaseDeal deal={deal} />
          </Button>
        </div>
      ))}
      <Button type="default" small fullWidth linkTo={`/deal/${dealName}/connected-deals`}>
        {getText('seeAll')}
      </Button>
    </div>
  );

  const noDealsContent = (
    <div className={noDealsContainer(theme)}>
      <img src={noDealsImg} alt="No connected deals" />
      <div>
        <label>{getText('noConnectedDealsYet')}</label>
        {isMerchant && (
          <Button type="link" onClick={() => setIsModalVisible(true)}>
            {getText('addConnectedDeals')}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <aside className={`connected-deals-panel ${mainContainer(theme)}`}>
        <div className={titleWrapper}>
          <h2>{getText('connectedDeals')}</h2>
          {isMerchant && (
            <Popover
              content={
                <>
                  <Button type="link" onClick={() => setIsModalVisible(true)}>
                    {getText('edit')}
                  </Button>
                  <Button type="link" linkTo={`/deal/${dealName}/connected-deals`}>
                    {getText('allDeals')}
                  </Button>
                </>
              }
              overlayClassName={popoverWrapper(theme)}
              zIndex={900}
              placement="bottomRight"
              trigger="click"
            >
              <FeatherIcon icon="more-vertical" size={12} />
            </Popover>
          )}
        </div>
        {!isNil(deals) && !isEmpty(deals) ? dealsContent : noDealsContent}
      </aside>
      <Modal
        title={getText('addConnectedDeals')}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className={connectedDealsModal}
        width="60vw"
      >
        <span>{getText('selectDealsToConnect')}</span>
        <SearchInput
          defaultValue={queryParams.searchText}
          onPressEnter={searchText => fetchDeals({ searchText })}
          placeholder={getText('searchForDeals')}
        />
        <div className={availableDealsContainer}>
          {availableDeals?.map(deal => (
            <BaseDeal deal={deal} isSelectable selectedDeals={selectedDeals} setSelectedDeals={setSelectedDeals} />
          ))}
        </div>
        <div className={buttonsWrapper(theme)}>
          <Button type="primary" small onClick={handleAddDeals}>
            {getText('addConnectedDeals', [selectedDeals?.length])}
          </Button>
          <Button type="link" small className={blackTextLink(theme)} onClick={() => setIsModalVisible(false)}>
            {getText('cancel')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

ConnectedDealsPanel.propTypes = {
  deals: PropTypes.array,
  dealId: PropTypes.number,
  isMerchant: PropTypes.bool,
};

export default ConnectedDealsPanel;
