import React from 'react';
import { Link } from 'react-router-dom';
import { pick, isArray } from '@veraio/core';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'components/UIExternal';
import { Routes } from 'enums';
import { mr } from 'assets/css/globalCSS';
import TickIcon from 'assets/images/icn-tick-filled.svg';
import {
  businessContainer,
  textHighlight,
  addressContainer,
  actionButton,
  messageMerchantButtonStyles,
  shoppingFromMerchantStyles,
} from './styles';

const CheckoutBusiness = ({ business, children, address, className }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();

  return (
    <div className={businessContainer(theme, className)}>
      <div className="section-subtitle">
        <div className={shoppingFromMerchantStyles}>
          <h4 className="subtitle">{getText('shoppingFrom')}</h4>
          <div className="name-verified">
            <span className={textHighlight(theme)}>
              <Link to={`/merchant/${business?.businessId}/overview`}>
                {getDynamicTranslation(business?.businessName)?.name}
              </Link>
            </span>
            <img src={TickIcon} alt="tick" />
          </div>
        </div>
        <Button
          className={messageMerchantButtonStyles}
          type="link"
          linkTo={`/chat?dealId=${business?.items?.map(el => el.dealId)}&userId=${business?.businessId}`}
        >
          <FeatherIcon icon="message-square" size={12} className={mr({ lg: 3 })} />
          {getText('messageMerchant')}
        </Button>
      </div>

      {children}

      {address && (
        <div className={addressContainer(theme)}>
          <h4 className="title">
            {address.businessAddress ? getText('pickUpAtLocation') : getText('deliveryAtAddress')}
          </h4>
          {/* If the user select pickup at location it is business address which have translations and it has name as array */}
          {/* It the user select delivery at address it will be user address which do not have translations and name is string */}
          <h5>{isArray(address.name) ? getDynamicTranslation(address.name)?.name : address.name} </h5>
          <p>{address.street}</p>
          <p>
            {Object.values(pick(address, ['city', 'state', 'postcode', 'country']))
              .filter(Boolean)
              .join(', ')}
          </p>
          {address.phoneNumber && <p>{`+${address.phoneNumber}`}</p>}
          <Button linkTo={Routes.shoppingCartCheckoutDelivery} type="link" small className={actionButton}>
            {getText('change')}
          </Button>
        </div>
      )}
    </div>
  );
};

CheckoutBusiness.propTypes = {
  business: PropTypes.object,
  children: PropTypes.any,
  address: PropTypes.object,
  className: PropTypes.string,
};

export default CheckoutBusiness;
