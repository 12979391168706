import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const addressesStyles = css({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  [Desktop]: {
    justifyContent: 'space-between',
  },
  [Mobile]: {
    flexWrap: 'wrap',
  },
});

export const addressCart = (theme, active) =>
  css({
    fontSize: '0.75rem',
    lineHeight: '1.1875rem',
    fontWeight: 400,
    border: `1px solid ${active ? 'none' : theme.gray500}`,
    backgroundColor: active ? theme.skyblue : 'transparent',
    borderRadius: 4,
    padding: 8,
    margin: '0 0.625em 0.625em 0',
    flexWrap: 'wrap',
    minHeight: '5.9375rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& h3': {
      fontSize: '0.8125rem',
      marginBottom: '0.1875em',
      [Mobile]: {
        fontSize: '0.75rem',
      },
    },
    '&:hover': {
      cursor: 'pointer',
    },
    [Mobile]: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
    },
  });

export const defaultTag = theme =>
  css({
    backgroundColor: theme.primary_blue,
    borderRadius: 2,
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '0.5rem',
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: '0.16em',
    padding: '0.25em',
  });

export const label = css({
  fontWeight: 500,
  fontSize: '0.8125rem !important',
});

export const addressesBlock = css({
  flexDirection: 'column',
  padding: 0,
  alignItems: 'center',
});

export const addNewAddressBlock = css({
  padding: 0,
});

export const requiredStyles = theme =>
  css({
    color: theme.red,
    marginRight: '0.25em',
  });
