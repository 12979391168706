import React from 'react';
import PropTypes from 'prop-types';
import { fromEntries } from '@veraio/core';
import { colComp } from './styles';

export const COLUMN_DISPLAY_NAME = 'GridColumn';

const Col = props => {
  const { children, role, tabIndex, onClick, onMouseDown, onMouseUp, onMouseEnter, onMouseLeave, onDrag } = props;

  const events = [
    ['onClick', onClick],
    ['onMouseDown', onMouseDown],
    ['onMouseUp', onMouseUp],
    ['onMouseEnter', onMouseEnter],
    ['onMouseLeave', onMouseLeave],
    ['onDrag', onDrag],
  ].filter(el => Boolean(el[1]));

  if (events.length) events.push(['role', role || 'button'], ['tabIndex', tabIndex || -1]);

  return (
    <div className={colComp(props)} {...fromEntries(events)}>
      {children}
    </div>
  );
};

Col.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  role: PropTypes.string,
  tabIndex: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onDrag: PropTypes.func,
};

Col.displayName = COLUMN_DISPLAY_NAME;

export default Col;
