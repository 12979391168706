import styled from '@emotion/styled';
import { Checkbox } from 'antd';

export const BaseCheckbox = styled(Checkbox)`
  font-weight: 400;
  font-size: 0.875rem;
  color: ${props => props.theme.gray100};
  font-weight: ${props => (props.isBold ? 500 : 400)};
  padding: 0.375em;
  &:hover {
    background-color: ${props => props.theme.ui_hover_rgba};
  }
  & .ant-checkbox + span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  & .ant-checkbox-inner {
    border: 2px solid ${props => props.theme.gray500};
  }
  & .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 2px solid ${props => props.theme.gray500};
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
  }
  & .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${props => props.theme.green2};
  }
  & .ant-checkbox-inner::after {
    top: 48%;
    left: 20%;
  }
  & .ant-checkbox-checked + span {
    font-weight: 500;
  }
  & .checkbox-icon {
    font-size: 1.5rem;
    color: ${props => props.theme.gray200};
  }
`;
