import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const checkboxesWrapper = css({
  marginTop: '1.5em',
  '& .ant-form-item': {
    marginBottom: 0,
  },
});

export const categoryButtonsWrapper = theme =>
  css({
    marginBottom: '1.5em',
    marginTop: '0.25em',
    '& > div': {
      marginBottom: '0.3125em',
    },
    '&.categorySelected': {
      backgroundColor: theme.default_hover,
      color: 'red',
    },
    '& .ant-btn': {
      [Mobile]: { width: '100%', marginTop: '0.5em' },
      marginRight: '1em',
      marginTop: '0.25em',
      borderRadius: '4px',
      color: 'black',
      textShadow: 'none',
    },
    '& .ant-btn-primary': {
      backgroundColor: theme.skyblue,
    },
    '& button:focus': {
      background: theme.skyblue,
      color: 'black',
    },
  });

export const tagListStyles = css({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '0.3125em',
});

export const tagStyles = theme =>
  css({
    background: theme.gray600,
    borderRadius: 4,
    padding: '0.25em 0.75em',
    marginRight: '0.25em',
    marginTop: '0.3125em',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '0.3125em',
      cursor: 'pointer',
    },
  });

export const requiredStyles = theme =>
  css({
    color: theme.red,
    marginRight: '0.25em',
  });

export const noCategoryContainer = (showError, theme) =>
  css({
    border: showError ? `1px solid ${theme.red}` : `2px solid ${theme.gray500}`,
    borderRadius: 3,
  });

export const tagsHintText = theme =>
  css({
    color: theme.gray300,
    fontSize: '0.75rem',
    marginTop: '0.3125em',
    display: 'inline-block',
  });

export const labelAndInput = css({
  '& .ant-form-item-row': {
    flexDirection: 'column',
  },
});

export const registeredBusinessStyle = css({
  width: '21rem',
});
