import { css } from 'emotion';

export const transactionsFilterBar = css({
  display: 'flex',
  marginBottom: '1rem',

  '& > .inputs-container': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginRight: 'auto',
    gap: '10px',

    '& > label': {
      whiteSpace: 'nowrap',
    },

    '& > div': {
      width: '300px',
      display: 'block',
    },

    '& > div.width-narrow': {
      width: '200px',
    },

    '&.align-right': {
      width: 'auto',
      marginRight: '0',
    },
    '& .nowrap span': {
      whiteSpace: 'nowrap',
    },
  },
});
