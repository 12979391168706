import React, { useEffect, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import PropTypes from 'prop-types';
import { Modal, Rate, Spin, Form, Input } from 'antd';
import { Button } from 'components/UIExternal';
import { getSingleReview } from 'services/api/reviewService';
import { formatDefaultDate, formatTime } from 'utils/valueFormatter';
import useError from 'services/errorHandling/useError';
import DefaultAvatar from 'assets/images/footer_user_default.svg';
import {
  avatarContainer,
  avatar,
  starRatings,
  ratingsCountLabel,
  mobileAvatarContainer,
  authorNameTitle,
  desktopReview,
  mobileReview,
  responsiveImg,
} from '../styles';
import { modalContainer, responseForm } from './styles';

const ReplyModal = ({ isModalVisible, handleOk, handleCancel, reviewId }) => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [review, setReview] = useState();

  const submitForm = values => {
    handleOk(values.response);
  };

  useEffect(() => {
    fetchSingleReview();
  }, []);

  const fetchSingleReview = async () => {
    const [res, err] = await getSingleReview(reviewId);
    err && setError(err);
    setReview(res);
  };

  return (
    <Modal
      open={isModalVisible}
      zIndex={1100}
      width={850}
      onCancel={handleCancel}
      className={modalContainer}
      footer={null}
      centered
    >
      {review ? (
        <>
          <div className="flex">
            <div className={avatarContainer}>
              <img
                src={review.authorPhoto.url ? review.authorPhoto.url : DefaultAvatar}
                className={avatar(theme)}
                alt="Avatar"
              />
              <Rate className={starRatings(theme)} value={review.stars} disabled />
              <label className={ratingsCountLabel(theme)}>{`${review.stars} of 5`}</label>
            </div>
            <div style={{ flexGrow: 1 }}>
              <div className="flex">
                {/* Mobile only */}
                <div className={mobileAvatarContainer(theme)}>
                  <img src={review.authorPhoto.url ? review.authorPhoto.url : DefaultAvatar} alt="Avatar" />
                </div>
                <div>
                  <p className={authorNameTitle(theme)}>{review.authorName}</p>
                  {/* Desktop */}
                  <p className={desktopReview}>
                    {getText('reviewedOnDateAtTime', {
                      date: formatDefaultDate(review.date),
                      time: formatTime(review.date),
                    })}
                  </p>
                  {/* Mobile */}
                  <div className={mobileReview}>
                    <p>
                      {formatDefaultDate(review.date)}
                      <span className="bold-text"> {formatTime(review.date)}</span>
                    </p>
                    <Rate className={starRatings(theme)} value={review.stars} disabled />
                  </div>
                </div>
              </div>
              <p className="bold-text">{review.title}</p>
              <p>{review.description}</p>
              <div className="flex">
                {review.media &&
                  review.media.length > 0 &&
                  review.media.map(
                    (item, i) =>
                      item.url && (
                        <div key={i} className={responsiveImg}>
                          <img src={item.url} alt="review comment" />
                        </div>
                      ),
                  )}
              </div>
            </div>
          </div>
          <Form form={form} onFinish={submitForm} layout="vertical" className={responseForm}>
            <Form.Item
              label={getText('response')}
              name="response"
              rules={[
                {
                  required: true,
                  message: getText('pleaseEnterResponse'),
                },
              ]}
            >
              <TextArea type="text" placeholder={getText('typeYourResponse')} />
            </Form.Item>
          </Form>
        </>
      ) : (
        <Spin size="large" />
      )}
      <div className="flex buttons">
        <Button type="secondary" small onClick={() => form.submit()}>
          {getText('sendReply')}
        </Button>
        <Button type="default" small onClick={() => handleCancel()}>
          {getText('cancel')}
        </Button>
      </div>
    </Modal>
  );
};

ReplyModal.propTypes = {
  isModalVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  reviewId: PropTypes.number,
};

export default ReplyModal;
