import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Text, View, Svg, Path, G, Image } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const color = {
  st0: '#3A6DB5',
  st1: '#3469B3',
  st2: '#FECF09',
};

const PaperBagTemplate = ({ qrCode, businessInfo }) => {
  const SVGComponent = () => (
    <Svg x="0px" y="0px" viewBox="0 0 841.9 1190.6" style={generalStyles.pageBackground}>
      <G>
        <Path
          fill={color.st0}
          d="M199.5,310.8h85.6v130.5c0,37,14.7,72.4,40.8,98.5c26.1,26.1,61.6,40.8,98.5,40.8   s72.4-14.7,98.5-40.8c26.1-26.1,40.8-61.6,40.8-98.5V310.8h85.6v130.5c0,59.7-23.7,116.9-65.9,159.1   c-42.2,42.2-99.4,65.9-159.1,65.9c-59.7,0-116.9-23.7-159.1-65.9c-42.2-42.2-65.9-99.4-65.9-159.1L199.5,310.8z"
        />
        <G>
          <Path
            fill={color.st0}
            d="M240.3,84.7c-11.1-1.5-22.6-0.6-33.9-0.7c-0.4,0-0.9,0.5-1.5,0.9v78.7    c10.1,0,19.8,0.1,29.4,0c20.3-0.4,37.5-16.1,39.1-35.7C275.3,106.6,261.3,87.6,240.3,84.7z M249.2,133.1    c-4.4,7.1-13.8,10-22.5,8.3v-34.8c8.4-2.3,16.9,0,21.7,6.5c2,2.9,3.2,6.3,3.4,9.9C251.8,126.6,251,130.1,249.2,133.1L249.2,133.1z    "
          />
          <Path fill={color.st0} d="M276,253.5v-77.7h20.2v27.7h26.4v-27.6h20.6v77.7h-20.3v-27.8h-26.3v27.7H276z" />
          <Path fill={color.st0} d="M298,105.5v7.4h16.5V135l-16.5,0.2v7h37.6v21H276V84.3h59.5v21.3H298z" />
          <Path fill={color.st0} d="M564.9,254.1h-59v-78.4h58.9v20.3h-37.5v8.5h16.4v20.8h-16.2v8.2h37.5L564.9,254.1z" />
          <Path
            fill={color.st0}
            d="M271.7,187.1c-5.1,3.9-15.3,12.8-15.3,12.8s-7.7-3.9-14-5.2c-4-0.9-12-1.8-14.6,2.5    c-2.6,4.5,3.9,6.6,6.9,7.1c3.9,0.6,8.7,0.1,12.6,0.8c16.4,2.8,25.3,12.5,23.7,27.2c-0.6,5.2-2.8,10.1-6.2,14    c-14.1,15.4-45.4,11.5-59.3-5.6l16.1-11.4c0,0,6.6,4.8,13.7,5.8c4.2,0.6,9,0.6,12.7-1.6c1.1-0.6,1.9-1.6,2.2-2.8    c0.7-2.9-2.7-4.5-5-4.8c-4-0.6-8-0.9-12-1.4c-14.7-1.6-24.9-9.9-27.1-22.2c-1.3-7.1,0.6-13.4,5.2-18.8    C224.7,168,256.1,172.2,271.7,187.1z"
          />
          <Path
            fill={color.st0}
            d="M501.5,242.4l-17.1,11.8l-26.1-28.9l-0.4,28.2h-20.3v-77.7h20.1v29.3    c0,0,18.1-19.9,26.8-29.8l16.5,12.5l-22.9,26.8L501.5,242.4z"
          />
          <G>
            <Path
              fill={color.st2}
              d="M389.6,84.4c14.9,26.7,29.1,52.2,43.7,78.4H410l-20.4-36.3c-7.2,12.8-13.8,24.5-20.5,36.3     h-22.9L389.6,84.4z"
            />
          </G>
          <G>
            <Path
              fill={color.st2}
              d="M389.6,217.3l-20.4,36.1h-22.9l43.3-78.3l43.7,78.3H410     C403.5,241.9,396.8,230.1,389.6,217.3z"
            />
          </G>
          <Path fill={color.st0} d="M458.1,84.3v57.8h37.9v21.1h-59.3V84.3H458.1z" />
          <Path
            fill={color.st0}
            d="M623.4,229.9c1.8-2.4,3.2-4.1,4.5-5.8c12.6-17.5,3.5-43.9-17.7-47.5    c-13.4-2.3-27.6-0.4-41.7-0.4v78h20.8v-16.7c0,0,11.1-1.3,16.8,4.1c6.3,6,12.5,12.1,19.6,18.8l14.3-15.2V244L623.4,229.9z     M612.5,206.9c-0.2,2.3-1.2,4.4-2.8,6.1c-1.6,1.6-3.8,2.6-6.1,2.9c-4.5,0.3-9,0.1-13.9,0.1v-19.5c5.3,0,10.2-0.5,15,0.1    C609.6,197.2,612.9,202.2,612.5,206.9z"
          />
        </G>
      </G>
    </Svg>
  );

  return (
    <Document>
      <Page size="A3">
        <View>
          <SVGComponent />
          <View style={generalStyles.wrapper}>
            <View style={s.contentBox}>
              <View style={s.textBox}>
                <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
                <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
                <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
                <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
                <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
              </View>
              <Image source={qrCode} style={s.qrCodeStyles} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

PaperBagTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default PaperBagTemplate;
