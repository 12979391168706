import { css } from 'emotion';
import styled from '@emotion/styled';
import icons from 'assets/icons/dealShaker/dealshaker';
import { Mobile } from 'assets/css/breakpoints';

export const headingPanel = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const title = css({
  fontSize: '2.375rem',
  [Mobile]: { fontSize: '1.1875rem', fontWeight: 700 },
  fontWeight: 500,
  marginBottom: '1.25em',
  display: 'inline-block',
});

export const panelContent = css({
  display: 'flex',
});

export const categoriesColumn = css({
  '&[class*="ant-col-"]': {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill,minmax(13em, 1fr))',
    gap: '1em',
    [Mobile]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },

    '& > .category-top': {
      flex: 1,
    },
  },
  '& .ant-skeleton.ant-skeleton-element': {
    paddingRight: '1.5625em',
    paddingBottom: '1.5625em',
  },
});

export const OnlineServiceIcon = styled.span`
  font-size: 1.6875rem;
  color: ${props => props.theme.primary_blue};
  &:before {
    content: ${icons.DeliveryTypeOnlineService};
  }
  margin-bottom: 0.1875em;
`;

export const SelfOrganizedIcon = styled.span`
  font-size: 1.6875rem;
  color: ${props => props.theme.primary_orange};
  &:before {
    content: ${icons.DeliveryTypeSelforganized};
  }
  margin-bottom: 0.1875em;
`;

export const RedeemAtLocationIcon = styled.span`
  font-size: 1.6875rem;
  color: ${props => props.theme.green};
  &:before {
    content: ${icons.DeliveryTypeRedeematlocation};
  }
  margin-bottom: 0.1875em;
`;
