import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const titleForm = theme =>
  css({
    fontWeight: 500,
    fontSize: '2.375rem',
    color: theme.background100,
    marginBottom: '3em',
    [Mobile]: {
      fontSize: '1.375rem',
      marginBottom: '1.375em',
    },
  });

export const refundFormWrapper = theme =>
  css({
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      padding: '1.375em 1.75em',
    },
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: '0.8125rem !important',
      fontWeight: 500,
    },
    '& .ant-input::placeholder, .ant-select-selection-placeholder': {
      fontSize: '0.75rem',
    },
    '& .ant-form-item-label': {
      paddingBottom: '0 !important',
    },
  });

export const wrapperInput = css({
  width: '85%',
  [Mobile]: { width: '100%' },
});

export const uploadItem = css({ marginBottom: 0 });

export const imageUploadDragDrop = css({ height: '9.25rem !important' });

export const imagesPreview = css({
  '& .ant-upload-list-item.ant-upload-list-item-done': {
    padding: '0 ',
    border: 'none',
  },
  '& .ant-upload-list-item-actions a': { display: 'none' },
  '& .ant-upload-list-item-image': {
    objectFit: 'cover !important',
  },
  '& .ant-upload-list-item-info': {
    borderRadius: 3,
  },
  '& .ant-upload-list-picture-card-container': {
    borderRadius: '4px !important',
    [Desktop]: { width: '4.8125rem', height: '4.25rem' },
    [Mobile]: { width: '6.25rem', height: '5rem' },
  },
});

export const formButtons = css({
  marginTop: '1.4375em',
  marginBottom: '4.875em',
  display: 'flex !important',
  justifyContent: 'space-between',
  '& span': {
    margin: 'auto',
  },
  [Mobile]: {
    flexDirection: 'column',
    marginBottom: '3em',
  },
});

export const blackTextLink = theme =>
  css({
    color: theme.gray100,
  });
