import { isNil } from '@veraio/core';
import queryString from 'query-string';

export const graphParams = options => {
  const { businessId, statisticsPeriodId, categoryId, currencyId } = options;

  return queryString.stringify(
    {
      ...(!isNil(businessId) && { businessId }),
      ...(!isNil(statisticsPeriodId) && { statisticsPeriodId }),
      ...(!isNil(categoryId) && { categoryId }),
      ...(!isNil(currencyId) && { currencyId }),
    },
    { arrayFormat: 'index' },
  );
};
