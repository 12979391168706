import { css } from 'emotion';

export const guideBrandColorsContainer = css({
  display: 'flex',
  alignItems: 'center',
  gap: '3em',
});

export const guideBrandColorsTextContainer = theme =>
  css({
    flex: '1 0 0',
    '& .brandColorsTitle': {
      color: theme.blue,
      fontWeight: 600,
      lineHeight: 1,
      fontSize: '3rem',
    },
    '& .brandColorsText': {
      color: theme.blue,
      fontSize: '1rem',
      textAlign: 'justify',
    },
  });
