import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'components';
import clockIcon from 'assets/images/clearingSettlementExplainedPage/clock.png';
import settlementIcon from 'assets/images/clearingSettlementExplainedPage/settlement.png';
import walletIcon from 'assets/images/clearingSettlementExplainedPage/wallet.png';
import { mainContainer, header } from './styles';

const ClearingSettlementExplained = () => {
  const { getText } = useTranslations();
  const history = useHistory();

  return (
    <div className={`content-container ${mainContainer}`}>
      <div className={header}>
        <Button type="link" onClick={() => history.goBack()} small>
          <Icon iconName="las la-arrow-left" />
        </Button>
        <h2 className="flex flex-center deal-name">{getText('clearingSettlementExplained')}</h2>
      </div>

      <div className="paragraph-text-wrap">
        <p>{getText('clearingSettlementExplainedOverview')}</p>
      </div>

      <div className="paragraph-text-wrap icon-text-columns">
        <div className="icon-column">
          <img alt="walletIcon" src={walletIcon} />
        </div>
        <div className="text-column">
          <h4>{getText('clearingWallet')}</h4>
          <p>{getText('clearingSettlementExplainedWalletText')}</p>

          <h4>{getText('awaitingClearing')}</h4>
          <p>{getText('clearingSettlementExplainedAawaitingClearingText1')}</p>
          <p>
            <strong>{getText('clearingSettlementExplainedAawaitingClearingExampleText')}</strong>
          </p>
          <p>{getText('clearingSettlementExplainedAawaitingClearingText2')}</p>
        </div>
      </div>

      <div className="paragraph-text-wrap icon-text-columns">
        <div className="icon-column">
          <img alt="walletIcon" src={clockIcon} />
        </div>
        <div className="text-column">
          <h4>{getText('clearingPeriod')}</h4>
          <p>{getText('clearingSettlementExplainedClearingPeriodText1')}</p>
          <p>{getText('clearingSettlementExplainedClearingPeriodExampleText')}</p>
          <p>{getText('clearingSettlementExplainedClearingPeriodText2')}</p>
        </div>
      </div>

      <div className="paragraph-text-wrap icon-text-columns">
        <div className="icon-column">
          <img alt="settlementIcon" src={settlementIcon} />
        </div>
        <div className="text-column">
          <h4>{getText('awaitingSettlement')}</h4>
          <p>{getText('clearingSettlementExplainedClearingSettlementText1')}</p>

          <h4>{getText('clearingSettlementExplainedSettlementTitle')}</h4>
          <p>{getText('clearingSettlementExplainedClearingSettlementText2')}</p>
        </div>
      </div>
    </div>
  );
};

export default ClearingSettlementExplained;
