import { css } from 'emotion';

export const textLinkStyle = theme =>
  css({
    color: theme.primary_blue,
    padding: 0,
    '&:hover': {
      color: theme.primary_blue,
    },
  });
