import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const wrapper = css({
  padding: '3em 0',
  maxWidth: '75rem',
  margin: '0 auto',
  '& .font-download': {
    marginTop: '1em',
    '& a': {
      padding: 0,
      marginLeft: '0.375em',
      textDecoration: 'underline',
    },
  },
});

export const imgContainer = css({
  width: '47.5rem',
  height: '32.6875rem',
  borderRadius: '5px',
  overflow: 'hidden',
  position: 'relative',
  '& .overlay': {
    display: 'none',
  },
  '&:hover .overlay': {
    display: 'flex',
  },
  [Mobile]: {
    width: '22.5rem',
    height: '26.4375rem',
  },
});

export const mainImgStyles = css({
  display: 'block',
  width: '100%',
  position: 'absolute',
  bottom: 0,

  [Mobile]: {
    height: '100%',
    marginBottom: '1em',
  },
});

export const mainImgDownLoadBtn = theme =>
  css({
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '3.75rem',
    background: 'linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& div[role="button"]': {
      color: theme.white,
      fontSize: '0.875rem',
    },
    '& .feather': {
      marginRight: '0.75em',
    },
  });

export const free = theme =>
  css({
    background: theme.free,
    color: theme.green,
    padding: '0.375em 0.75em',
    fontSize: '0.875rem',
    display: 'inline-block',
    textTransform: 'uppercase',
    fontWeight: 600,
    borderRadius: '4px',
  });

export const materialTitle = css({
  fontSize: '4rem',
});

export const materialSubtitle = theme =>
  css({
    fontSize: '1.5rem',
    color: theme.gray100,
    marginBottom: '0.5em',
  });

export const dropDownBusiness = css({
  marginTop: '0.75em',
  marginBottom: '0.625em',
  maxWidth: '31.25rem',
});

export const bodyContainer = css({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  [Mobile]: {
    padding: '0 3.125em',
  },
});

export const actionContainer = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  flex: 1,
  marginRight: '1em',
  maxWidth: '31.25rem',
});

export const imageContainer = css({
  marginRight: '2em',
  display: 'flex',
  alignItems: 'center',
  [Mobile]: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
});

export const selectSection = css({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  [Mobile]: {
    marginRight: '1em',
  },
});

export const headerSection = css({
  [Mobile]: {
    padding: '0 3.125em',
  },
});
