import { css } from 'emotion';

export const promoCodesFilterBar = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '2rem 0',
});

export const promoCodesFilters = css({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
});
