import React from 'react';
import { useTheme } from 'emotion-theming';
import { BrandGuideLogos } from 'components/screens/Public/brandGuidePage';
import { Icon } from 'components/ui';
import { useScrollToTop } from 'components/UIExternal';
import { guidePages } from './config';
import { brandGuideLineBackground, brandGuideLinePageContainer, scrollToTopArrow } from './styles';

const BrandGuidePage = () => {
  const theme = useTheme();
  const { scrollPosition, scrollToTop } = useScrollToTop();

  return (
    <div className={brandGuideLinePageContainer(theme)}>
      <BrandGuideLogos />
      {guidePages.map((page, ind) => (
        <div className="element" key={ind}>
          <div className={brandGuideLineBackground(page)}>{page.content}</div>
        </div>
      ))}
      <div className={scrollToTopArrow(scrollPosition, theme)}>
        <Icon iconName="las la-arrow-circle-up" size={50} onClick={scrollToTop} />
      </div>
      <BrandGuideLogos />
    </div>
  );
};

export default BrandGuidePage;
