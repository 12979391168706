import { css } from 'emotion';

export const applyButton = css({
  width: 200,
});

export const cancelButton = css({
  textDecoration: 'underline',
  width: 100,
});

export const columnContainer = css({
  margin: '1em 0',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const columnListOptionsContainer = css({
  maxHeight: '15rem',
  overflowY: 'scroll',
  flex: 1,
});

export const columnTitle = css({
  display: 'inline-block',
  marginBottom: '1em',
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  fontWeight: '500',
});

export const itemContainer = (isActive, theme) =>
  css({
    padding: '0.4em',
    backgroundColor: isActive && theme.gray600,
    color: isActive ? theme.gray100 : theme.gray200,

    '&: hover': {
      backgroundColor: theme.gray600,
      color: theme.gray100,
    },
  });

export const modalContainer = isActive => theme =>
  css({
    display: 'flex',
    flexDirection: 'row',
    minWidth: 550,

    '.modal-content': {
      border: `1.5px solid ${theme.gray500}`,
      display: 'flex',
      margin: '1em',
      padding: '0.5em 1em',
      borderRadius: 4,

      '.vertical-line': {
        width: '0.0625rem',
        backgroundColor: theme.gray500,
        margin: '0.75em 2.25em',
      },
    },

    '.display-price-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0.5em 1em',
      marginBottom: '1rem',

      '.price-info': {
        flex: 1,
        marginRight: '1rem',

        '.title': {
          marginBottom: '0.5rem',
        },

        '.description': {
          color: theme.gray200,
          fontSize: 13,
          lineHeight: '1.2rem',
        },
      },

      '.button-container': {
        display: 'flex',
        flexDirection: 'column',

        '.switch-content-container': {
          marginBottom: '0.75rem',

          '.label-left': {
            color: !isActive ? theme.blue2 : theme.gray300,
          },

          '.label-right': {
            color: isActive ? theme.blue2 : theme.gray300,
          },
        },

        '.example-container': {
          border: `1px solid ${theme.gray500}`,
          padding: '1.5em',
          borderRadius: 3,
          backgroundColor: theme.gray600,

          '.example-text': {
            color: theme.gray200,
          },

          '.price-styles': {
            fontSize: 18,
            fontWeight: 500,
            color: theme.gray100,
          },
        },
      },
    },
  });
