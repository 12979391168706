import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const stepsStyles = (theme, className) =>
  css(
    {
      [Mobile]: { flexDirection: 'row' },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    className,
  );

export const stepContainer = (theme, isActive, isCompleted) =>
  css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.2em',
    cursor: 'pointer',
    '& *': {
      cursor: 'inherit',
    },

    '&[disabled]': {
      pointerEvents: 'none',
    },

    '& .index': {
      width: '1.75rem',
      height: '1.75rem',
      margin: '0 0.8em 0 0',
      fontSize: '0.8rem',
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: isActive ? theme.primary_blue : theme.background100,
      borderRadius: 3,
      color: theme.white,
      opacity: isActive || isCompleted ? 1 : 0.6,
    },

    '& .label': {
      fontSize: '0.9rem',
      color: isActive || isCompleted ? theme.gray100 : theme.gray200,
    },
  });
