import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const mainContainer = css({
  display: 'flex',
  marginBottom: '2.5em',
  '& p': {
    marginBottom: '0.125em',
  },
});

export const desktopReview = css({
  display: 'block',
  [Mobile]: {
    display: 'none',
  },
});

export const mobileReview = css({
  display: 'none',
  [Mobile]: {
    display: 'block',
  },
  '& .ant-rate': {
    fontSize: '1rem',
    marginTop: 0,
  },
  '& p': {
    marginBottom: 0,
    [Mobile]: {
      fontSize: '0.8125rem',
    },
  },
});

export const avatarContainer = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginRight: '2.1875em',
  minWidth: '6.25rem',
  [Mobile]: {
    display: 'none',
  },
});

export const mobileAvatarContainer = theme =>
  css({
    display: 'none',
    width: '1.75rem',
    height: '1.75rem',
    borderRadius: '28px',
    border: `1px solid ${theme.gray500}`,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5625em',
    [Mobile]: {
      display: 'flex',
    },
  });

export const authorNameTitle = theme =>
  css({
    color: theme.gray100,
    fontWeight: 500,
    [Mobile]: {
      marginTop: '0.125em',
      fontSize: '1rem',
    },
  });

export const avatar = theme =>
  css({
    width: '2.5rem',
    borderRadius: '40px',
    border: `1px solid ${theme.gray500}`,
    marginTop: '0.5em',
  });

export const starRatings = theme =>
  css({
    '&.ant-rate': {
      fontSize: '0.5625rem',
      color: theme.primary_yellow,
      margin: '0.8125em 0',
    },
    [Mobile]: {
      '&.ant-rate': {
        fontSize: '1rem',
        marginTop: 0,
      },
    },
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.75rem',
    color: theme.gray100,
  });

export const responsiveImg = css({
  border: 'none',
  background: 'transparent',
  padding: 0,
  marginTop: '0.5em',
  width: '2.6875rem',
  height: '1.5rem',
  overflow: 'hidden',
  borderRadius: 4,
  marginRight: '0.5em',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
});

export const popoverWrapper = theme =>
  css({
    color: theme.gray100,
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '4px',
    '& .ant-popover-inner-content': {
      padding: 0,
    },
  });

export const labelButtonWrapper = css({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
});

export const disputeLabel = theme =>
  css({
    textTransform: 'uppercase',
    color: theme.background100,
    background: theme.primary_yellow,
    fontSize: '0.6875rem',
    fontWeight: 500,
    letterSpacing: 1,
    padding: '0.0625em 0.1875em',
  });

export const replyDetailsWrapper = theme =>
  css({
    marginTop: '0.9375em',
    marginBottom: '0.3125em',
    display: 'flex',
    '& p': {
      marginLeft: '0.3125em',
      marginRight: '0.3125em',
      color: theme.violet,
      fontWeight: 500,
    },
    '& label': {
      color: theme.gray200,
    },
  });
