import { toast } from 'react-hot-toast';

export const copyToClipBoard = async (text, message) => {
  try {
    if (navigator?.clipboard?.writeText) {
      await navigator.clipboard.writeText(text);
      toast.success(message);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};
