import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Button } from 'components/UIExternal';
import { mr } from 'assets/css/globalCSS';
import { modalContainer, questionMarkIcon } from './styles';

const CloseConfirmationPopup = ({ isModalVisible, handleOk, handleCancel }) => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <Modal
      open={isModalVisible}
      zIndex={1100}
      onCancel={handleCancel}
      className={modalContainer(theme)}
      footer={null}
      centered
    >
      <span className={questionMarkIcon(theme)}>?</span>
      <h5 className="section-subtitle">{getText('sureCloseDeal')}?</h5>
      <p>{getText('dealClosedWontAppear')}</p>
      <div className="flex modal-buttons-container">
        <Button type="secondary" small onClick={() => handleCancel()} className={mr({ lg: 10 })}>
          {getText('cancel')}
        </Button>
        <Button type="danger" small onClick={() => handleOk()}>
          {getText('closeDeal')}
        </Button>
      </div>
    </Modal>
  );
};

CloseConfirmationPopup.propTypes = {
  isModalVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default CloseConfirmationPopup;
