import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import { Dropdown, Button } from 'components/UIExternal';
import { useUser } from 'stores';
import DealStatus from 'enums/DealStatus';
import { materials } from 'enums/BrandMaterials';
import { selectAllOption } from 'utils';
import useError from 'services/errorHandling/useError';
import { getDeals } from 'services/api/brandMaterialService';
import { selectTemplate } from './materialsTemplates';
import {
  tabWrapper,
  headerContainer,
  materialContainer,
  imgContainer,
  infoContainer,
  free,
  downLoadBtn,
  materialTitle,
  materialSizes,
  materialRow,
  dropDownBusiness,
  actionContainer,
  selectSection,
} from './styles';

const BrandMaterials = () => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const [qrCode, setQrCode] = useState({ src: null });
  const [businessInfo, setBusinessInfo] = useState();
  const { setError } = useError();
  const { currentAccountBusinesses } = useUser();
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [labelsDeals, setLabelsDeals] = useState({ list: [], selectedDeals: [] });
  const businessOptions = currentAccountBusinesses.map(el => ({
    value: el.id,
    label: getDynamicTranslation(el.name)?.name?.currentValue,
  }));
  const hasSelectedDeal = !!labelsDeals?.selectedDeals?.length;

  const stateParams = {
    business: selectedBusiness,
    deals: labelsDeals?.list,
  };

  const changeBusiness = async business => {
    if (isNil(business)) {
      setSelectedBusiness(null);
      return;
    }

    const [res, err] = await getDeals({
      businessId: business?.value,
      dealsSorting: 0,
      pageSize: 100,
    });

    err && setError(err);
    setSelectedBusiness(business);
    setBusinessInfo(
      currentAccountBusinesses
        .map(el => ({ ...el, name: getDynamicTranslation(el.name)?.name }))
        .find(el => el.id === business?.value),
    );
    generateQR(`${window.location.host}/merchant/${business?.value}/overview`);
    setLabelsDeals(prev => ({
      ...prev,
      list: [
        { title: 'All', id: 0 },
        ...res?.items
          ?.filter(deal => deal.businessId === business?.value && deal.statusId === DealStatus.Approved.id)
          .map(el => ({
            ...el,
            title: getDynamicTranslation(el.dealDetails)?.title,
          })),
      ],
    }));
  };

  const handleLabelDeal = currentSelection =>
    setLabelsDeals(prev => ({
      ...prev,
      selectedDeals: selectAllOption(prev.list, prev.selectedDeals, currentSelection),
    }));

  const generateQR = async text => {
    const res = await QRCode.toDataURL(text);
    res && setQrCode(prev => ({ ...prev, src: res }));
  };

  return (
    <div className={tabWrapper}>
      <div className={headerContainer}>
        <Dropdown
          withSearch
          onChange={changeBusiness}
          className={dropDownBusiness}
          placeholder={getText('selectBusiness')}
          value={selectedBusiness?.value}
          options={businessOptions}
        />
      </div>
      {materials.map(material => (
        <div className={materialRow(theme)} key={material.id}>
          <Link
            className={materialContainer}
            to={{
              pathname: `/brand-materials/${material.id}`,
              state: stateParams,
            }}
          >
            <div className={imgContainer}>
              <img src={material.imgReduced} alt="" />
            </div>
          </Link>

          <div className={actionContainer}>
            <Link
              className={infoContainer}
              to={{
                pathname: `/brand-materials/${material.id}`,
                state: stateParams,
              }}
            >
              <span className={free(theme)}>{material.isFree && getText('free')}</span>
              <div className={materialTitle(theme)}>{getText(material.title)}</div>
              <div className={materialSizes(theme)}>
                The file is in <b>pdf</b> format. {material.sizeInfo}
              </div>
            </Link>
            <div className={selectSection}>
              {material.businessInfo && (
                <Dropdown
                  onChange={handleLabelDeal}
                  className={dropDownBusiness}
                  multiSelect
                  displayKey="title"
                  uniqueKey="id"
                  placeholder={getText('selectDeal')}
                  value={labelsDeals.selectedDeals}
                  options={labelsDeals?.list}
                />
              )}

              <PDFDownloadLink
                document={selectTemplate(material.id, qrCode.src, businessInfo, labelsDeals.selectedDeals)}
                fileName="brand-material"
              >
                {() => (
                  <Button
                    type="secondary"
                    fullWidth
                    className={downLoadBtn}
                    disabled={
                      (material.merchantInfo && !selectedBusiness) || (material.businessInfo && !hasSelectedDeal)
                    }
                  >
                    {getText('download')}
                  </Button>
                )}
              </PDFDownloadLink>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BrandMaterials;
