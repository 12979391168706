import { css } from 'emotion';

export const orderDetailsUserContainer = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '2.5em 0',
    padding: '2em',
    background: theme.gray900,
    borderRadius: 10,

    '& .order-details-user': {
      display: 'flex',
      alignItems: 'center',

      '& .order-details-user-avatar': {
        textTransform: 'uppercase',
        background: theme.purple,
        marginRight: '0.75em',
        color: theme.white,
        borderRadius: '50%',
        width: '3rem',
        height: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1rem',
      },
    },
  });
