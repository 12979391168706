import apiRoutes from 'config/apiRoutes';
import { getReq, postReq } from 'services/axios/makeRequest';

export const getRefundRequestDeal = async (couponId, setError) => {
  const result = await getReq(apiRoutes.GET_REFUND_REQUEST(couponId));
  result[1] && setError && setError(result[1]);
  return result[0];
};

export const createRefund = async (payload, setError) => {
  const result = await postReq(`${apiRoutes.CREATE_REFUND}`, payload);
  result[1] && setError && setError(result[1]);
  return result[0];
};
