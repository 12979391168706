export const chatSortOptions = {
  UnreadFirst: {
    id: 1,
    value: 'UnreadFirst',
  },
  NewestFirst: {
    id: 2,
    value: 'NewestFirst',
  },
};

export const chatStatus = {
  Read: {
    id: 1,
    value: 'Read',
  },
  Unread: {
    id: 2,
    value: 'Unread',
  },
  Archived: {
    id: 3,
    value: 'Archived',
  },
  getById: id => {
    const key = Object.keys(chatStatus).find(key => chatStatus[key].id === id);
    return key && chatStatus[key];
  },
};
