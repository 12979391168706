import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const dealContainer = css({
  marginBottom: '1.25em',
  borderRadius: 8,
  '& .section-subtitle': {
    fontWeight: 500,
    marginBottom: 0,
    [Mobile]: {
      fontSize: '0.875rem',
    },
  },
  '& .mobile-block': {
    [Mobile]: {
      display: 'block',
      position: 'relative',
    },
  },
});

export const thumbnailContainer = css({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '9.375rem',
  borderRadius: '3px',
});

export const priceInfoContainer = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5em',
    '& .section-subtitle': {
      marginBottom: 0,
      marginLeft: '0.75em',
    },
    'input[type=number]::-webkit-inner-spin-button': {
      opacity: 1,
    },
    '& .ant-input-affix-wrapper': {
      width: '5.5rem',
      padding: '0.3125em 0.625em',
      [Mobile]: {
        width: '100%',
      },
    },
    '& .ant-input': {
      fontSize: '1.125rem',
    },
    '& .ant-input-prefix': {
      color: theme.gray100,
      marginRight: '0.625em',
      height: '1.375rem',
    },
    '& .price-info-container-input': {
      flex: '1 0 0',
    },
    '& .price-info-container-label': {
      flex: '2 0 0',
    },
  });

export const dealContentContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flex: 1,
  height: '100%',
  order: '3',
  '& .flex': {
    justifyContent: 'space-between',
  },
});

export const onlyOneStyles = css({
  fontSize: '0.75rem',
});

export const removeButton = theme =>
  css({
    color: theme.red,
    padding: 0,
  });

export const textHighlight = theme =>
  css({
    color: theme.blue2,
    margin: '0 0.375em',
  });

export const marginBottom = val =>
  css({
    marginBottom: val,
  });

export const dealMerchantCardHeader = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  '& .section-subtitle.flex': {
    flexWrap: 'wrap',
    gap: '0 0.5em',
    '& .span-img': {
      display: 'flex',
      gap: '0.5em',
      '& span': {
        margin: '0',
      },
    },
  },
  [Mobile]: {
    '& > div': {
      marginBottom: 0,
    },
  },
});

export const inputField = theme =>
  css({
    marginRight: '0.5em',
    '&.ant-input': {
      border: `1px solid ${theme.gray500}`,
      borderRadius: 3,
      background: theme.white,
      fontSize: '0.75rem',
    },
    '& .ant-input-affix-wrapper': {
      width: '100%',
    },
  });

export const promoCodeSection = theme =>
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > svg:first-of-type': {
      marginRight: '0.25em',
      width: '0.75rem',
      '& path': {
        fill: theme.green2,
        opacity: 0.8,
      },
    },

    '& > p': {
      color: theme.green2,
      margin: '0 1em 0 0',
    },

    '& .icon-trash': {
      color: theme.red,
      cursor: 'pointer',
    },
  });

export const headerSection = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1em',
  '@media (max-width: 500px)': {
    flexWrap: 'wrap',
    rowGap: '0.5em',
    '& div': {
      width: '100%',
    },
    '& input': {
      marginRight: '0',
    },
  },
});
