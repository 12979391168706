import { css } from 'emotion';
import { Mobile, Desktop } from 'assets/css/breakpoints';

export const storeAddressesItem = theme =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1 3 0',
    padding: '1em 0',
    border: `1px solid ${theme.gray500}`,
    borderRadius: 10,
    marginBottom: '1em',
    '& .mainInfo': {
      [Desktop]: {
        borderRight: `1px solid ${theme.gray500}`,
      },
      padding: '0 2em',
      minHeight: '10vh',
      flex: '2 1 0',
      '& .checkOnMap': {
        margin: '1em 0',
      },
      [Mobile]: {
        borderBottom: `1px solid ${theme.gray500}`,
        paddingTop: '1em',
        paddingBottom: '1em',
      },
    },
    '& .additional-info': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 2em',
      flex: '4 1 0',
      gap: '1em',
      '& .contact-person': {
        flex: '1 1 0',
        '& .title': {
          fontWeight: 500,
          color: theme.gray200,
        },
      },
      '& .working-days-hours': {
        flex: '1 1 0',
        '& .title': {
          fontWeight: 500,
          color: theme.gray300,
        },
      },
    },
    [Mobile]: {
      display: 'block',
    },
  });

export const seeOnTheMapContainer = css({
  marginTop: '1em',
});
