import React, { useState } from 'react';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import Slider from 'react-slick';
import FeatherIcon from 'feather-icons-react';
import { isDesktop } from 'react-device-detect';
import { CarouselSkeleton } from 'components/ui';
import { useDeepEffect, CountdownTimer } from 'components/UIExternal';
import Deal from 'components/deal/Deal';
import { dealsSliderType } from 'enums';
import useError from 'services/errorHandling/useError';
import { getRandomDeals } from 'services/api/dealsService';
import { headingPanel, title, arrowButton, wrapper } from './styles';

const arrowSettings = (position, theme) => ({
  size: 40,
  className: arrowButton(position),
  stroke: theme.gray200,
  strokeWidth: 1,
});

const arrowGeneral = slidesPerPage => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: slidesPerPage,
  slidesToScroll: slidesPerPage,
  arrows: isDesktop,
});

const DealsOfTheDay = () => {
  const theme = useTheme();
  const { shippingTo, shippingFrom } = useLocations();
  const { getText } = useTranslations();
  const slidesPerPage = isDesktop ? 4 : 1;
  const { setError } = useError();
  const [deals, setDeals] = useState(null);

  useDeepEffect(() => {
    fetchDeals();
  }, [shippingFrom, shippingTo]);

  const fetchDeals = async () => {
    const [res, err] = await getRandomDeals({
      from: shippingFrom?.map(el => el?.id),
      to: shippingTo?.map(el => el?.id),
      promotionType: dealsSliderType?.dealsOfTheDay,
      pageSize: 10,
    });
    err ? setError(err) : setDeals(res?.items);
  };

  const arrow = position => (
    <div>
      <FeatherIcon icon={`chevron-${position}`} {...arrowSettings(position, theme)} />;
    </div>
  );

  const settings = {
    ...arrowGeneral(slidesPerPage),
    nextArrow: arrow('right', theme),
    prevArrow: arrow('left', theme),
  };

  return (
    <div className="content-container deals-of-the-day">
      <div className={headingPanel}>
        <h2 className={title}>{getText('promoteDealsOfTheDay')}</h2>
        <CountdownTimer />
      </div>
      {isNil(deals) && <CarouselSkeleton numberOfDealsPerSlide={4} />}
      <div className={`${wrapper(isDesktop && deals?.length < slidesPerPage + 1)} row`}>
        <Slider {...settings}>
          {deals?.map(deal => (
            <div className="col" key={deal.id}>
              <Deal deal={deal} dataId="deals-of-the-day" />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default DealsOfTheDay;
