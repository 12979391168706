import { css } from 'emotion';
import styled from '@emotion/styled';
import { Desktop, Mobile } from '../../../assets/css/breakpoints';

export const MerchantOfficePage = styled.div`
  ${Desktop} {
    margin-top: 0.5em;
  }
  ${Mobile} {
    margin-top: -2em;
  }
  background-color: ${props => props.theme.white};
  position: relative;
  & .gray-background {
    background-color: ${props => props.theme.gray600};
  }
  & > .content-container:not(:first-of-type) {
    padding-top: 0;
  }
  & > .content-container > .ant-tabs:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2.8125rem;
    background-color: ${props => props.theme.gray600};
  }
`;

export const title = theme =>
  css({
    color: theme.background100,
    fontSize: '1.4375rem',
    fontWeight: 700,
    marginBottom: 0,
  });
