import React from 'react';
import PropTypes from 'prop-types';
import bgBlue from 'assets/images/brandMaterials/city_light/bgBlue120x176.jpg';
import bgGreen from 'assets/images/brandMaterials/city_light/bgGreen120x176.jpg';
import { Page, Document, View, Image, Text } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const CityLightPosterTemplate = ({ qrCode, businessInfo }) => (
  <Document>
    <Page size={[3401.57, 4988.98]}>
      <View>
        <Image src={bgBlue} alt="" style={generalStyles.pageBackground} />
        <View style={generalStyles.wrapper}>
          <View style={s.contentBox}>
            <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
            <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
            <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
            <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
            <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
            <Image source={qrCode} style={s.qrCodeStyles} />
          </View>
        </View>
      </View>
    </Page>
    <Page size={[3401.57, 4988.98]}>
      <View>
        <Image src={bgGreen} alt="" style={generalStyles.pageBackground} />
        <View style={generalStyles.wrapper}>
          <View style={s.contentBox}>
            <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
            <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
            <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
            <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
            <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
            <Image source={qrCode} style={s.qrCodeStyles} />
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

CityLightPosterTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default CityLightPosterTemplate;
