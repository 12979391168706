import { TAG_TYPE } from 'components';
import { invert, pick } from 'lodash-es';

export const TRANSACTION_CURRENCY = {
  ONE: 'ONE',
  EUR: 'Euro',
  OESP: 'OESP',
};

export const TRANSACTION_CURRENCY_SELECT = getText =>
  Object.entries(TRANSACTION_CURRENCY).map(([label, value]) => ({ label: getText(label), value }));

export const TRANSACTION_STATUS = {
  pending: 1,
  failed: 2,
  created: 3,
  successful: 4,
  reverted: 5,
  cancelled: 6,
  partiallyPaid: 8,
  awaitingClearing: 10,
  awaitingSettlement: 7,
  settled: 9,
};

export const TRANSACTION_STATUS_INVERSE = invert(TRANSACTION_STATUS);

export const TRANSACTION_STATUS_SELECT = getText =>
  Object.entries(pick(TRANSACTION_STATUS, ['pending', 'awaitingSettlement', 'settled'])).map(([label, value]) => ({
    label: getText(label),
    value,
  }));

export const TRANSACTION_STATUS_COLOR = {
  [TRANSACTION_STATUS.pending]: TAG_TYPE.WARNING,
  [TRANSACTION_STATUS.failed]: TAG_TYPE.RED,
  [TRANSACTION_STATUS.created]: TAG_TYPE.BLUE,
  [TRANSACTION_STATUS.successful]: TAG_TYPE.GREEN,
  [TRANSACTION_STATUS.reverted]: TAG_TYPE.GREEN_LIGHT,
  [TRANSACTION_STATUS.cancelled]: TAG_TYPE.RED_LIGHT,
  [TRANSACTION_STATUS.partiallyPaid]: TAG_TYPE.YELLOW,
  [TRANSACTION_STATUS.awaitingClearing]: TAG_TYPE.WARNING,
  [TRANSACTION_STATUS.awaitingSettlement]: TAG_TYPE.YELLOW,
  [TRANSACTION_STATUS.settled]: TAG_TYPE.GREEN,
};

export const CLEARING_SORT = {
  creationDateAsc: 0,
  creationDateDesc: 1,
  clearingDateAsc: 2,
  clearingDateDesc: 3,
};

export const CLEARING_SORT_SELECT = getText => [
  { label: getText('oldestByCreationDate'), value: CLEARING_SORT.creationDateAsc },
  { label: getText('newestByCreationDate'), value: CLEARING_SORT.creationDateDesc },
  { label: getText('oldestByClearingDate'), value: CLEARING_SORT.clearingDateAsc },
  { label: getText('newestByClearingDate'), value: CLEARING_SORT.clearingDateDesc },
];

export const SETTLEMENT_SORT = {
  creationDateAsc: 0,
  creationDateDesc: 1,
};

export const SETTLEMENT_SORT_SELECT = getText => [
  { label: getText('oldestBySettlementDate'), value: SETTLEMENT_SORT.creationDateAsc },
  { label: getText('newestBySettlementDate'), value: SETTLEMENT_SORT.creationDateDesc },
];
