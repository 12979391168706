import { css } from 'emotion';

export const popupTitle = theme =>
  css({
    fontSize: '1.25rem',
    fontWeight: 500,
    color: theme.gray100,
  });

export const popup = css({
  '& .ant-modal-footer': {
    border: 'none',
    display: 'flex',
    paddingLeft: '1.75em',
    paddingBottom: '1.25em',
    paddingTop: 0,
  },
  '& .ant-modal-content': {
    borderRadius: '8px',
  },
  '& .ant-tabs-tab': {
    padding: '0.5em 2.25em 0px 0px !important',
  },
  '& .ant-collapse-header': {
    padding: '0.3125em 2.5em 0.3125em 0px !important',
  },
});

export const responsiveImage = css({
  maxWidth: '100%',
  overflow: 'hidden',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
});
export const tabs = theme =>
  css({
    '& .ant-tabs-tab': {
      background: 'none !important',
      border: 'none !important',
    },
    '& .ant-tabs-tab:hover': {
      color: 'black !important',
    },
    '& .ant-tabs-tab-active .ant-tabs-tab-btn': {
      borderBottom: `2px solid ${theme.primary_yellow} !important`,
      color: 'black !important',
    },
    '& .ant-tabs-nav': {
      borderBottom: 'none !important',
    },
    '& .ant-collapse .ant-collapse-item': {
      border: 'none !important',
      borderBottom: 'none !important',
      '& .ant-collapse-header': { fontSize: '1rem' },
      '& .ant-collapse-header:hover': { backgroundColor: theme.ui_hover_rgba },
      '& .ant-collapse-content': { border: `1px solid  ${theme.ui_hover_rgba} !important` },
      '& .ant-collapse-content-box': { padding: 16 },
    },
    '& .ant-collapse-item-active': {
      backgroundColor: theme.ui_hover_rgba,
      fontWeight: 'normal',
      width: '100%',
    },
    '& .ant-collapse': {
      border: 'none !important',
      backgroundColor: 'white',
      width: '100%',
    },
    '& .ant-tabs-content': {
      overflow: 'auto',
    },
  });
