export const guideLines = {
  ourBrand: { title: 'ourBrand', sectionNumber: '01' },
  introduction: { title: 'introduction' },
  brandValues: { title: 'brandValues' },
  keyBrandTraits: { title: 'keyBrandTraits' },
  theLogo: { title: 'theLogo', sectionNumber: '02' },
  logo: { title: 'logo' },
  lockupsUses: { title: 'lockupsUses' },
  ourLogo: { title: 'ourLogo' },
  oneColorUse: { title: 'oneColorUse' },
  logoMisUse: { title: 'logoMisUse' },
  partnerShip: { title: 'partnerShip' },
  icons: { title: 'icons' },
  typography: { title: 'typography', sectionNumber: '03' },
  typeface: { title: 'typeface' },
  headlineInformation: { title: 'headlineInformation' },
  brandColors: { title: 'brandColors', sectionNumber: '04' },
  primaryBrandColors: { title: 'primaryBrandColors' },
  secondarySupportiveColors: { title: 'secondarySupportiveColors' },
  accessibleColorCombination: { title: 'accessibleColorCombination' },
  examples: { title: 'examples', sectionNumber: '05' },
  peoplePhotography: { title: 'peoplePhotography' },
  smilingFunEngagingExpressive: { title: 'smilingFunEngagingExpressive' },
  brandedContent: { title: 'brandedContent' },
  flatSolidDecorativeBold: { title: 'flatSolidDecorativeBold' },
  merchantBrandMaterials: { title: 'merchantBrandMaterials', sectionNumber: '06' },
  outdoorPrints: { title: 'outdoorPrints' },
  advertisingPosters: { title: 'advertisingPosters' },
  outdoorBillboards: { title: 'outdoorBillboards' },
  outdoorBanners: { title: 'outdoorBanners' },
  facadeSign: { title: 'facadeSign' },
  vehicleBranding: { title: 'vehicleBranding' },
  printMaterials: { title: 'printMaterials' },
  brochurePrint: { title: 'brochurePrint' },
  tShirt: { title: 'tShirt' },
  businessCard: { title: 'businessCard' },
  badges: { title: 'badges' },
  bag: { title: 'bag' },
  indoorPoster: { title: 'indoorPoster' },
  notebookDesign: { title: 'notebookDesign' },
  capBranding: { title: 'capBranding' },
  stickersDesign: { title: 'stickersDesign' },
  priceLabelsDesign: { title: 'priceLabelsDesign' },
  boxStickers: { title: 'boxStickers' },
  brandMaterialsRules: { title: 'brandMaterialsRules', sectionNumber: '07' },
};
