import React from 'react';
import { Page, Document, Text, View, Svg, Path, G, Rect } from '@react-pdf/renderer';

const color = {
  blue: {
    st0: '#3A6DB5',
    st1: '#3469B3',
    st2: '#FECF09',
    st3: '#FFFFFF',
    st4: '#FECF0C',
  },
  yellow: {
    st0: '#FECF09',
    st1: '#3A6DB5',
    st2: '#FFFFFF',
  },
};

const TShirtsTemplate = () => {
  const SVGComponentBlue = () => (
    <Svg x="0px" y="0px" viewBox="0 0 841.9 595.3" style={{ position: 'absolute', top: 0 }}>
      <Rect x={1} y={0} fill={color.blue.st0} width={841.9} height={297.6} />
      <Rect x={0.7} y={297.6} fill={color.blue.st1} width={841.9} height={297.6} />
      <Path
        fill={color.blue.st2}
        d="M574.9,386.6h-58.2v-88.7c0-25.1-10-49.2-27.8-67c-17.8-17.8-41.9-27.8-67-27.8  c-25.1,0-49.2,10-67,27.8c-17.8,17.8-27.8,41.9-27.8,67v88.7h-58.2v-88.7c0-40.6,16.1-79.5,44.8-108.2  c28.7-28.7,67.6-44.8,108.2-44.8s79.5,16.1,108.2,44.8c28.7,28.7,44.8,67.6,44.8,108.2L574.9,386.6z"
      />
      <G>
        <Path
          fill={color.blue.st3}
          d="M69.6,55.1c-4.2-0.6-8.6-0.2-12.9-0.3c-0.2,0-0.3,0.2-0.6,0.3v30c3.8,0,7.5,0.1,11.2,0   C75.1,85,81.6,79,82.2,71.5C82.9,63.4,77.6,56.2,69.6,55.1z M73,73.5c-1.7,2.7-5.2,3.8-8.5,3.1V63.4c3.2-0.9,6.4,0,8.2,2.5   c0.8,1.1,1.2,2.4,1.3,3.8S73.7,72.3,73,73.5L73,73.5z"
        />
        <Path fill={color.blue.st3} d="M83.2,119.3V89.8h7.7v10.5h10V89.8h7.8v29.6H101v-10.6H91v10.6H83.2z" />
        <Path fill={color.blue.st3} d="M91.5,63v2.8h6.3v8.4l-6.3,0.1v2.7h14.3v8H83.2V54.9h22.6V63H91.5z" />
        <Path fill={color.blue.st3} d="M192.9,119.6h-22.4V89.7h22.4v7.7h-14.3v3.2h6.2v7.9h-6.2v3.1h14.3L192.9,119.6z" />
        <Path
          fill={color.blue.st3}
          d="M81.6,94.1c-1.9,1.5-5.8,4.9-5.8,4.9s-2.9-1.5-5.3-2c-1.5-0.3-4.6-0.7-5.6,1   c-1,1.7,1.5,2.5,2.6,2.7c1.5,0.2,3.3,0,4.8,0.3c6.2,1.1,9.6,4.8,9,10.4c-0.2,2-1.1,3.8-2.4,5.3c-5.4,5.9-17.3,4.4-22.5-2.1l6.1-4.4   c0,0,2.5,1.8,5.2,2.2c1.6,0.2,3.4,0.2,4.8-0.6c0.4-0.2,0.7-0.6,0.8-1.1c0.3-1.1-1-1.7-1.9-1.8c-1.5-0.2-3-0.4-4.5-0.5   c-5.6-0.6-9.5-3.8-10.3-8.4c-0.5-2.7,0.2-5.1,2-7.1C63.7,86.8,75.6,88.4,81.6,94.1z"
        />
        <Path
          fill={color.blue.st3}
          d="M168.8,115.1l-6.5,4.5l-9.9-11l-0.2,10.7h-7.7V89.8h7.6v11.2c0,0,6.9-7.6,10.2-11.4l6.3,4.8   l-8.7,10.2L168.8,115.1z"
        />
        <Path
          fill={color.blue.st4}
          d="M126.3,55c5.7,10.2,11.1,19.9,16.6,29.9h-8.9L126.3,71c-2.7,4.9-5.3,9.3-7.8,13.8h-8.7   L126.3,55z"
        />
        <Path
          fill={color.blue.st4}
          d="M126.3,105.6l-7.8,13.8h-8.7l16.5-29.8l16.6,29.8h-8.9C131.6,114.9,129.1,110.5,126.3,105.6z"
        />
        <Path fill={color.blue.st3} d="M152.4,54.9v22h14.4v8h-22.5v-30H152.4z" />
        <Path
          fill={color.blue.st3}
          d="M215.2,110.4c0.7-0.9,1.2-1.5,1.7-2.2c4.8-6.7,1.3-16.7-6.7-18.1c-5.1-0.9-10.5-0.2-15.9-0.2   v29.7h7.9v-6.3c0,0,4.2-0.5,6.4,1.6c2.4,2.3,4.7,4.6,7.4,7.2l5.4-5.8v-0.4L215.2,110.4z M211,101.6c-0.1,0.9-0.5,1.7-1.1,2.3   c-0.6,0.6-1.4,1-2.3,1.1c-1.7,0.1-3.4,0-5.3,0v-7.4c2,0,3.9-0.2,5.7,0.1C209.9,97.9,211.2,99.8,211,101.6z"
        />
      </G>
    </Svg>
  );

  const SVGComponentYellow = () => (
    <Svg x="0px" y="0px" viewBox="0 0 841.9 595.3" style={{ position: 'abolute', top: 0 }}>
      <Rect id="XMLID_74_" x={0} y={0} fill={color.yellow.st0} width={841.9} height={595.3} />
      <G>
        <Path
          fill={color.yellow.st1}
          d="M69.6,55.1c-4.2-0.6-8.6-0.2-12.9-0.3c-0.2,0-0.3,0.2-0.6,0.3v30c3.8,0,7.5,0.1,11.2,0   C75.1,85,81.6,79,82.2,71.5C82.9,63.4,77.6,56.2,69.6,55.1z M73,73.5c-1.7,2.7-5.2,3.8-8.5,3.1V63.4c3.2-0.9,6.4,0,8.2,2.5   c0.8,1.1,1.2,2.4,1.3,3.8S73.7,72.3,73,73.5L73,73.5z"
        />
        <Path fill={color.yellow.st1} d="M83.2,119.3V89.8h7.7v10.5h10V89.8h7.8v29.6H101v-10.6H91v10.6H83.2z" />
        <Path fill={color.yellow.st1} d="M91.5,63v2.8h6.3v8.4l-6.3,0.1v2.7h14.3v8H83.2V54.9h22.6V63H91.5z" />
        <Path
          fill={color.yellow.st1}
          d="M192.9,119.6h-22.4V89.7h22.4v7.7h-14.3v3.2h6.2v7.9h-6.2v3.1h14.3L192.9,119.6z"
        />
        <Path
          fill={color.yellow.st1}
          d="M81.6,94.1c-1.9,1.5-5.8,4.9-5.8,4.9s-2.9-1.5-5.3-2c-1.5-0.3-4.6-0.7-5.6,1   c-1,1.7,1.5,2.5,2.6,2.7c1.5,0.2,3.3,0,4.8,0.3c6.2,1.1,9.6,4.8,9,10.4c-0.2,2-1.1,3.8-2.4,5.3c-5.4,5.9-17.3,4.4-22.5-2.1l6.1-4.4   c0,0,2.5,1.8,5.2,2.2c1.6,0.2,3.4,0.2,4.8-0.6c0.4-0.2,0.7-0.6,0.8-1.1c0.3-1.1-1-1.7-1.9-1.8c-1.5-0.2-3-0.4-4.5-0.5   c-5.6-0.6-9.5-3.8-10.3-8.4c-0.5-2.7,0.2-5.1,2-7.1C63.7,86.8,75.6,88.4,81.6,94.1z"
        />
        <Path
          fill={color.yellow.st1}
          d="M168.8,115.1l-6.5,4.5l-9.9-11l-0.2,10.7h-7.7V89.8h7.6v11.2c0,0,6.9-7.6,10.2-11.4l6.3,4.8   l-8.7,10.2L168.8,115.1z"
        />
        <Path
          fill={color.yellow.st2}
          d="M126.3,55c5.7,10.2,11.1,19.9,16.6,29.9h-8.9L126.3,71c-2.7,4.9-5.3,9.3-7.8,13.8h-8.7   L126.3,55z"
        />
        <Path
          fill={color.yellow.st2}
          d="M126.3,105.6l-7.8,13.8h-8.7l16.5-29.8l16.6,29.8h-8.9C131.6,114.9,129.1,110.5,126.3,105.6z"
        />
        <Path fill={color.yellow.st1} d="M152.4,54.9v22h14.4v8h-22.5v-30H152.4z" />
        <Path
          fill={color.yellow.st1}
          d="M215.2,110.4c0.7-0.9,1.2-1.5,1.7-2.2c4.8-6.7,1.3-16.7-6.7-18.1c-5.1-0.9-10.5-0.2-15.9-0.2   v29.7h7.9v-6.3c0,0,4.2-0.5,6.4,1.6c2.4,2.3,4.7,4.6,7.4,7.2l5.4-5.8v-0.4L215.2,110.4z M211,101.6c-0.1,0.9-0.5,1.7-1.1,2.3   c-0.6,0.6-1.4,1-2.3,1.1c-1.7,0.1-3.4,0-5.3,0v-7.4c2,0,3.9-0.2,5.7,0.1C209.9,97.9,211.2,99.8,211,101.6z"
        />
      </G>
      <G>
        <G>
          <Path
            fill={color.yellow.st1}
            d="M262,220.4h60.5v92.2c0,26.1,10.4,51.2,28.8,69.6c18.5,18.5,43.5,28.8,69.6,28.8    c26.1,0,51.2-10.4,69.6-28.8c18.5-18.5,28.8-43.5,28.8-69.6v-92.2h60.5v92.2c0,42.2-16.8,82.6-46.6,112.4s-70.3,46.6-112.4,46.6    s-82.6-16.8-112.4-46.6s-46.6-70.3-46.6-112.4L262,220.4z"
          />
          <Rect fill={color.yellow.st1} x={261.9} y={220.4} width={318} height={63.2} />
          <Rect fill={color.yellow.st2} x={293.5} y={123.6} width={102.7} height={47.4} />
          <Rect fill={color.yellow.st2} x={443.7} y={123.6} width={102.7} height={47.4} />
        </G>
      </G>
    </Svg>
  );

  return (
    <Document>
      <Page size={[841.9, 595.3]}>
        <View>
          <SVGComponentBlue />
          <View>
            <View>
              <Text />
            </View>
          </View>
        </View>
      </Page>

      <Page size={[841.9, 595.3]}>
        <View>
          <SVGComponentYellow />
          <View>
            <View>
              <Text />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default TShirtsTemplate;
