import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from '@veraio/core';
import { useTheme } from 'emotion-theming';
import { DatePicker as AntDatePicker } from 'antd';
import moment from 'moment';
import { datePicker } from './styles';

const DatePicker = ({ placeholder, dateFormat, onChange, defaultValue, disabledDate, className }) => {
  const theme = useTheme();
  const format = dateFormat || 'DD/MM/YYYY';

  const onChangeHandler = date => {
    if (isFunction(onChange)) onChange(date?.format('YYYY-MM-DD'));
  };

  return (
    <AntDatePicker
      defaultValue={defaultValue && moment(defaultValue, 'YYYY-MM-DD')}
      disabledDate={disabledDate}
      format={format}
      onChange={e => onChangeHandler(e)}
      className={datePicker(theme, className)}
      placeholder={placeholder || format}
    />
  );
};

DatePicker.propTypes = {
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  disabledDate: PropTypes.func,
  className: PropTypes.string,
};

export default DatePicker;
