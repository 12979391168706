import { useRef, useEffect } from 'react';
import { deepEqual } from '@veraio/core';

export const useDeepEffect = (callback, deps) => {
  const ref = useRef();

  if (!ref.current || !deepEqual(deps, ref.current)) ref.current = deps;

  useEffect(callback, ref.current);
};
