import React from 'react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Rate } from 'antd';
import { Deal, Button, useUrlParams } from 'components';
import { formatDefaultDate, scrollToTop } from 'utils';
import noReviewsYet from 'assets/images/no-reviews.svg';
import noStoreAddress from 'assets/images/public-merchant-no-store-address.svg';
import { StoreAddresses } from './StoreAddresses';
import {
  overviewContainer,
  dealList,
  dealItem,
  overviewMerchantContent,
  overviewMerchantReviews,
  noContent,
} from './styles';

export const OverviewTab = ({ overview, tabs }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const { queryParams, setQueryParams } = useUrlParams();

  const handleClickViewAll = tab => {
    setQueryParams({ ...queryParams, tab });
    scrollToTop();
  };

  return (
    <div className={overviewContainer}>
      <div className={overviewMerchantContent}>
        <div className="tab">
          <h3>{getText('about')}</h3>
          <p>{getDynamicTranslation(overview?.description)?.description}</p>
        </div>
        <div className="tab">
          <h3>{getText('stores')}</h3>
          {overview?.addresses?.length ? (
            <StoreAddresses addresses={overview?.addresses} />
          ) : (
            <div className={noContent}>
              <img src={noStoreAddress} alt="No store address" />
            </div>
          )}
        </div>
        <div className="tab">
          <div className="deals-header">
            <h3>{getText('deals')}</h3>
            <Button type="link" onClick={() => handleClickViewAll(tabs.deals)}>
              {getText('viewAll')}
            </Button>
          </div>
          <div className={dealList(theme)}>
            {overview?.deals?.map(deal => (
              <Deal key={deal.id} deal={deal} className={dealItem} />
            ))}
          </div>
        </div>
      </div>
      <div className={overviewMerchantReviews(theme)}>
        {overview.reviews?.length ? (
          <div>
            <div className="reviews-header">
              <h3>{getText('reviews')}</h3>
              <div className="rating">
                <Rate disabled value={overview.rating} />
                <p>
                  {getText('ratingStarsReviewsCount', {
                    rating: Math.floor(overview.rating),
                    maxStars: 5,
                    reviewCount: overview.reviewsCount,
                  })}
                </p>
              </div>
            </div>
            {overview.reviews?.map(review => (
              <div key={review.id} className="reviews-item">
                <h6 className="reviewer-name">{review.authorName}</h6>
                <p className="review-date">{getText('reviewedDate', { date: formatDefaultDate(review.date) })}</p>
                <div className="rating">
                  <Rate disabled value={review.stars} />
                  <span>{getText('ratingStarsCount', { ratingCount: Math.floor(review.stars), maxStars: 5 })} </span>
                </div>
                <p>{review?.description}</p>
              </div>
            ))}
            <Button type="link" onClick={() => handleClickViewAll(tabs.reviews)}>
              {getText('viewAll')}
            </Button>
          </div>
        ) : (
          <div className={noContent}>
            <img src={noReviewsYet} alt="No reviews yet" />
          </div>
        )}
      </div>
    </div>
  );
};

OverviewTab.propTypes = { overview: PropTypes.object, tabs: PropTypes.object };
