import { css, keyframes } from 'emotion';

export const truckAnimation = keyframes({
  '0%': {
    left: 0,
    opacity: 0,
  },
  '100%': {
    left: '50%',
    transform: 'translateX(-50%)',
    opacity: 1,
  },
});

export const truckImage = css({
  position: 'absolute',
  animation: `${truckAnimation} 1.4s ease-out 0s 1`,
  animationFillMode: 'forwards',
});

export const deliveryAnimation = keyframes({
  '0%': {
    bottom: '-2rem',
    opacity: 0,
  },
  '100%': {
    bottom: '0',
    opacity: 1,
  },
});

export const deliveryImage = css({
  position: 'absolute',
  right: '0',
  animation: `${deliveryAnimation} 0.6s ease-in-out 1.4s 1`,
  animationFillMode: 'both',
});
