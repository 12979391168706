import { css } from 'emotion';

export const mainContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.5em 0',
  '& .ant-progress-line': {
    maxWidth: '5em',
    width: '87%',
  },
  paddingLeft: '1.5em',
});

export const label = theme =>
  css({
    color: theme.background200,
    fontSize: '0.6875rem',
    lineHeight: '1.375rem',
    fontWeight: 500,
    marginRight: '0.75em',
  });

export const link = theme =>
  css({
    color: theme.blue2,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    textDecoration: 'underline',
  });

export const countLabel = theme =>
  css({
    fontSize: '0.75rem',
    color: theme.gray200,
    padding: '0.5em 1em',
  });

export const progressBar = css({
  flex: '1 0 12.5em',
});
