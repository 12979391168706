import apiRoutes from 'config/apiRoutes';
import { deleteReq, getReq, postReq } from '../axios/makeRequest';

export const saveListingFilter = async (name, filter, setError) => {
  const result = await postReq(apiRoutes.SAVE_LISTING_FILTER, { ...filter, name });
  result[1] && setError && setError(result[1]);
  return result[0];
};
export const getAllListingFilters = async setError => {
  const result = await getReq(apiRoutes.SAVED_FILTERS_GET);
  result[1] && setError && setError(result[1]);
  return result[0];
};

export const deleteFilterById = async (id, setError) => {
  const result = await deleteReq(apiRoutes.SAVED_FILTERS_DELETE(id));
  result[1] && setError && setError(result[1]);
  return result[0];
};
