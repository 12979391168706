import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Image, Text, View } from '@react-pdf/renderer';
import merchantPDFVersion1 from 'assets/images/qrCode/QR-Code-PDF_V01.png';
import merchantPDFVersion2 from 'assets/images/qrCode/QR-Code-PDF_V02.png';
import Logo from 'assets/images/Logo_white.png';
import { styles } from './styles';

const MerchantQRTemplate = ({ businessName, subtitleBusinessName, qrCodeImg, version }) => {
  const { document, pageBackground, header, title, subTitle, qrCodeImage, footer } = styles;

  return (
    <Document>
      <Page>
        <View fixed style={document}>
          <Image src={version === 1 ? merchantPDFVersion1 : merchantPDFVersion2} style={pageBackground} />
          <View>
            {version === 1 && (
              <View style={header}>
                <View>
                  <Image source={Logo} style={header.logoImg} />
                </View>
                <View>
                  <Text>dealshaker.oneecosystem.eu</Text>
                </View>
              </View>
            )}
            <View style={[title, version === 1 ? title.v1 : title.v2]}>
              <Text>{businessName}</Text>
            </View>
            <View style={[subTitle, version === 1 ? subTitle.v1 : subTitle.v2]}>
              <Text>{subtitleBusinessName}</Text>
            </View>
            <View style={qrCodeImage}>
              <Image src={qrCodeImg} />
            </View>
            {version === 2 && (
              <View style={footer}>
                <View>
                  <Image source={Logo} style={header.logoImg} />
                </View>
                <View>
                  <Text>dealshaker.oneecosystem.eu</Text>
                </View>
              </View>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

MerchantQRTemplate.propTypes = {
  businessName: PropTypes.string,
  subtitleBusinessName: PropTypes.string,
  qrCodeImg: PropTypes.string,
  version: PropTypes.number,
};

export default MerchantQRTemplate;
