import { css } from 'emotion';

export const tabsStyles = theme =>
  css({
    '& .ant-tabs-nav-list': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '0 2em',
    },
    '& .ant-tabs-tab': {
      color: theme.gray300,
      marginLeft: '0',
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      borderBottom: `2px solid ${theme.primary_yellow}`,
      color: theme.gray100,
    },
    '& .ant-tabs-nav::before': {
      borderBottom: 'none !important',
    },
    '& .ant-tabs-tab:hover': {
      color: theme.gray100,
    },
    '& .ant-tabs-ink-bar': {
      width: 0,
      background: 'none',
    },
  });

export const deliveryLabel = css({
  fontWeight: 600,
  margin: '1em 0',
});
