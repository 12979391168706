import { isString, isNumber } from '@veraio/core';

export const commonStyles = ({
  displayFlex,
  display,
  align,
  justify,
  basis,
  grow,
  shrink,
  flex,
  direction,
  wrap,
  flow,
  minHeight,
  height,
  maxHeight,
  minWidth,
  width,
  maxWidth,
  padding,
  margin,
  bold,
  textDecoration,
  fontSize,
  fontWeight,
  color,
  textTransform,
  position,
  backgroundColor,
  borderRadius,
  top,
  right,
  bottom,
  left,
}) =>
  [
    displayFlex && { display: 'flex' },
    isString(display) && { display },
    isString(align) && { alignItems: align },
    isString(justify) && { justifyContent: justify },
    (isNumber(basis) || isString(basis)) && { flexBasis: basis },
    (isNumber(grow) || isString(grow)) && { flexGrow: grow },
    (isNumber(shrink) || isString(shrink)) && { flexShrink: shrink },
    (isNumber(flex) || isString(flex)) && { flex },
    isString(direction) && { flexDirection: direction },
    isString(wrap) && { flexWrap: wrap },
    isString(flow) && { flexFlow: flow },
    (isNumber(minHeight) || isString(minHeight)) && { minHeight },
    (isNumber(height) || isString(height)) && { height },
    (isNumber(maxHeight) || isString(maxHeight)) && { maxHeight },
    (isNumber(minWidth) || isString(minWidth)) && { minWidth },
    (isNumber(width) || isString(width)) && { width },
    (isNumber(maxWidth) || isString(maxWidth)) && { maxWidth },
    (isNumber(padding) || isString(padding)) && { padding },
    (isNumber(margin) || isString(margin)) && { margin },
    bold && { fontWeight: 600 },
    isString(textDecoration) && { textDecoration },
    isNumber(fontWeight) && { fontWeight },
    (isNumber(fontSize) || isString(fontSize)) && { fontSize },
    isString(color) && (theme => ({ color: `${theme[color] ?? color}` })),
    isString(backgroundColor) &&
      (theme => ({
        backgroundColor: `${theme[backgroundColor] ?? backgroundColor}`,
      })),
    isString(textTransform) && { textTransform },
    isString(position) && { position },
    (isNumber(borderRadius) || isString(borderRadius)) && { borderRadius },
    (isNumber(top) || isString(top)) && { top },
    (isNumber(right) || isString(right)) && { right },
    (isNumber(bottom) || isString(bottom)) && { bottom },
    (isNumber(left) || isString(left)) && { left },
  ].filter(Boolean);
