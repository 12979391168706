import envConfig from 'config/envConfig';
import { getReq, postReq, putReq } from '../axios/makeRequest';

const baseUrl = `${envConfig.apiAddress}/api/PromoCodes`;

export const apiRoutes = {
  GET_PROMO_CODES_LIST: accountId => `${baseUrl}/List/${accountId}`,
};

export const getPromoCodes = options => getReq(`${baseUrl}/List/${options.accountId}`, { ...options });

export const createPromoCode = promoCodeData => postReq(`${baseUrl}/Create`, promoCodeData);

export const enablePromoCode = promoCodeId => putReq(`${baseUrl}/Enable/${promoCodeId}`);

export const disablePromoCode = promoCodeId => putReq(`${baseUrl}/Disable/${promoCodeId}`);
