import { css } from 'emotion';

export const guideLineIntroductionSection = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const guideLineIntroductionTitle = theme =>
  css({
    color: theme.blue,
    textTransform: 'uppercase',
    flex: '3 0 0',
    fontSize: '3rem',
    fontWeight: 700,
    textAlign: 'center',
  });

export const guideLIneIntroductionContent = theme =>
  css({
    color: theme.blue,
    fontSize: '1rem',
    flex: '2 0 0',
  });
