import React, { useRef, useState, useEffect } from 'react';
import { isNil, isFunction } from '@veraio/core';
import { Icon } from '../../ui/Icon';
import { dropDownOptionItem, dropDownGroupLabel, noResultsContainer } from './styles';

export default (
  {
    isOpen,
    options: initOptions,
    selected,
    withSearch,
    multiSelect,
    onSelect,
    displayKey,
    containerRef,
    uniqueKey,
    small,
    theme,
  },
  searchBarRef,
) => {
  const searchRef = useRef({});
  const searchResults = useRef([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [scrollItems, setScrollItems] = useState([]);
  const hasSearch = searchRef.current?.value?.length;
  const scrollSize = 50;
  const needInfiniteScroll = (hasSearch ? searchResults.current?.length : initOptions.length) > scrollSize;

  useEffect(() => {
    isOpen && searchRef.current?.focus && searchRef.current.focus();
  }, [isOpen]);

  useEffect(() => {
    needInfiniteScroll && setScrollItems(initOptions.slice(0, scrollSize));
  }, [initOptions.length]);

  const getOptionCode = val => (isNil(val) ? null : val[uniqueKey] ?? val[displayKey]);
  const selectedCodes = multiSelect ? selected.map(getOptionCode) : getOptionCode(selected);

  const compareValues = (value, input) =>
    String(value)
      .toLowerCase()
      .trim()
      .indexOf(input.toLowerCase().trim()) !== -1;

  const filterValues = input => el =>
    el.groupLabel ?? (compareValues(el[uniqueKey], input) || compareValues(el[displayKey], input));

  const filterResults = input => {
    // eslint-disable-next-line no-param-reassign
    containerRef.current.scrollTop = 0;
    const results = initOptions
      .filter(filterValues(input))
      .filter((el, i, arr) => !el?.groupLabel || (arr[i + 1] && !arr[i + 1]?.groupLabel));

    searchResults.current = results;
    return results.length ? setFilteredResults(results.slice(0, scrollSize)) : setFilteredResults(null);
  };

  const handleSelectOption = option => {
    onSelect(option);
    isFunction(searchBarRef?.current?.changeInputValue) && withSearch && searchBarRef?.current?.changeInputValue('');
  };

  const fetchMoreResults = () =>
    !hasSearch
      ? setScrollItems(prev => prev.concat(initOptions.slice(prev.length, prev.length + scrollSize)))
      : setFilteredResults(prev => prev.concat(searchResults.current.slice(prev.length, prev.length + scrollSize)));

  const renderBaseOptionElement = (option, i) => {
    const optionCode = getOptionCode(option);
    const hasRender = isFunction(option.render);
    const optionRender = hasRender ? option.render(option) : option[displayKey];
    const isSelected = multiSelect ? selectedCodes.indexOf(optionCode) !== -1 : optionCode === selectedCodes;

    return option.groupLabel ? (
      <h2 key={`dropDownItem${i}`} className={dropDownGroupLabel(theme)}>
        {option.groupLabel}
      </h2>
    ) : (
      <div
        key={`dropDownItem${i}`}
        role="menuitem"
        tabIndex={0}
        onClick={() => handleSelectOption(option)}
        className={dropDownOptionItem(isSelected, hasRender, small, theme)}
      >
        {multiSelect && (
          <Icon material iconName={isSelected ? 'check_box' : 'check_box_outline_blank'} color="secondary" />
        )}
        {optionRender}
      </div>
    );
  };

  const noResults = (
    <div className={noResultsContainer(theme)}>
      <h6>No results found</h6>
    </div>
  );

  const allOptions = hasSearch ? filteredResults : needInfiniteScroll ? scrollItems : initOptions;

  const renderList = allOptions ? allOptions.map(renderBaseOptionElement) : noResults;

  return {
    allOptionsLength: (hasSearch ? searchResults.current : initOptions)?.length,
    optionsLength: allOptions?.length,
    filterResults,
    searchRef,
    renderList,
    fetchMoreResults,
    needInfiniteScroll,
  };
};
