import { css } from 'emotion';

export const header = css({
  display: 'flex',
});

export const transactionsRowStatus = css({
  textAlign: 'center',
  '& .clearing-date': {
    marginTop: '5px;',
  },
});

export const textMutedStyle = theme =>
  css({
    color: theme.gray300,
  });

export const textLinkStyle = theme =>
  css({
    color: theme.primary_blue,
    padding: 0,
    '&:hover': {
      color: theme.primary_blue,
    },
  });
