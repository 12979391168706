import { css } from 'emotion';

export const codeContainer = css({
  '& .usedBy': {
    wordBreak: 'break-word',
  },
});

export const codeValue = css({
  display: 'flex',
  '& .bolder-text': {
    fontSize: '.8rem',
  },
});
