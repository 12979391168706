import { graphParams } from 'services/models/statistics';
import envConfig from 'config/envConfig';
import { getReq } from '../axios/makeRequest';

const baseUrl = `${envConfig.apiAddress}/api/Statistics`;
const address = `${envConfig.apiAddress}/api`;

const apiRoutes = {
  STATISTICS_OVERVIEW: `${address}/Statistics/Overview`,
  STATISTICS_GRAPHS: `${address}/Statistics/Graphs`,
  STATISTICS_DAILY_SALES: `${address}/Statistics/DailySales`,
  STATISTICS_REGISTERED_BUSINESSES: `${address}/Statistics/RegisteredBusinessesCount`,
  STATISTICS_LOGGED_USERS: `${address}/Statistics/LoggedInUsersCount`,
};

export const getStatsOverview = (businessId, startDate, endDate) =>
  getReq(apiRoutes.STATISTICS_OVERVIEW, { businessId, startDate, endDate });

export const getGraphs = options => getReq(`${baseUrl}/Graphs?${graphParams(options)}`);

export const getDailySales = (businessId, startDate, endDate) =>
  getReq(apiRoutes.STATISTICS_DAILY_SALES, { businessId, startDate, endDate });

export const getRegisteredBusinessesCount = () => getReq(apiRoutes.STATISTICS_REGISTERED_BUSINESSES);

export const getLoggedUsersCount = () => getReq(apiRoutes.STATISTICS_LOGGED_USERS);
