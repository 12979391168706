import { css } from 'emotion';
import styled from '@emotion/styled';
import { BlackRating } from 'components/ui';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const featuredDealTemplate = theme =>
  css({
    height: '100%',
    whiteSpace: 'pre-wrap',
    display: 'block',
    color: theme.gray100,
    backgroundColor: theme.white,
    border: `1px solid ${theme.white}`,
    padding: 10,
    '&:hover': {
      backgroundColor: theme.white,
      border: `1px solid ${theme.gray400}`,
    },
    [Mobile]: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  });

export const thumbnailContainer = css({
  position: 'relative',
  width: '100%',
  paddingBottom: '57%',
  overflow: 'hidden',
  height: 0,
  borderRadius: '3px',
});

export const thumbnail = css({
  width: '100%',
  height: 'auto',
  position: 'absolute',
  top: '-9999px',
  bottom: '-9999px',
  left: '-9999px',
  right: '-9999px',
  margin: 'auto',
});

export const dealDiscountStamp = css({
  top: '0.5em',
  left: '0.5em',
  height: '2.5rem',
  width: '2.5rem',
});
export const row = css({
  paddingTop: '0.625em',
  [Desktop]: { padding: '1.5625em 1.5625em 1.5625em 0.9375em' },
});

export const deliveryMethodLabel = css({
  fontSize: '0.875rem',
  fontWeight: 400,
});

export const StarRatings = styled(BlackRating)`
  margin-right: 0.625em;
  &.ant-rate {
    font-size: 0.75rem;
  }
`;

export const ratingsCountLink = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
    textDecoration: 'underline',
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
  });

export const TitleLabel = styled.label`
  font-size: 1.125rem;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0.3125em;
  ${Mobile} {
    min-height: 3.75rem;
  }
`;

export const DeliveryIcon = styled.span`
  ${Mobile} {
    :before {
      position: absolute;
      top: 0.3125em;
      left: -0.8125em;
    }
  }

  font-size: 1.25rem;
  margin-left: 1em;
  color: ${props => props.theme.gray200};
`;

export const addWishlistBtn = theme =>
  css({
    color: theme.gray100,
    margin: '0.625em auto 0',
    '& svg': {
      marginRight: '0.25em',
    },
  });
