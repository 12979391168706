import React, { useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { Table } from 'components/UIExternal';
import { TransactionsTotalsPanel, TransactionsFilterBar } from 'components/screens';
import { useUser } from 'stores';
import { CLEARING_SORT_SELECT } from 'enums';
import { getTransactionsClearingList } from 'services';
import { CLEARING_FILTER_KEYS, CLEARING_COLUMNS, CLEARING_SETTLEMENT_SORTING_KEYS } from '../config';
import { tabContentContainer, transactionsTableContainer } from '../styles';

const ClearingTab = ({ pendingClearingSettlementAmounts = {} }) => {
  useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const { getText } = useTranslations();
  const theme = useTheme();
  const tableRef = useRef();
  const { currentAccount, isMerchant } = useUser();

  const {
    pendingClearingCoinWalletBalance,
    pendingClearingCashWalletBalance,
    pendingClearingOespWalletBalance,
    pendingSettlementCoinWalletBalance,
    pendingSettlementCashWalletBalance,
    pendingSettlementOespWalletBalance,
  } = pendingClearingSettlementAmounts;

  const totals = [
    {
      one: pendingClearingCoinWalletBalance,
      fiat: pendingClearingCashWalletBalance,
      oesp: pendingClearingOespWalletBalance,
      title: getText('awaitingClearing'),
    },
    {
      one: pendingSettlementCoinWalletBalance,
      fiat: pendingSettlementCashWalletBalance,
      oesp: pendingSettlementOespWalletBalance,
      title: getText('readyForSettlement'),
    },
  ];

  const fetchTransactions = options => getTransactionsClearingList(options, currentAccount?.id);

  const sortOptions = CLEARING_SORT_SELECT(getText);
  const initQueryParams = {
    [CLEARING_SETTLEMENT_SORTING_KEYS.sorting]: sortOptions[2].value,
  };

  return (
    <div className={tabContentContainer} data-id="clearing-tab">
      <div data-id="clearing-tab-totals">
        <TransactionsTotalsPanel totalsData={totals} />
      </div>

      <div className={transactionsTableContainer} data-id="clearing-tab-table">
        <Table
          ref={tableRef}
          hasPagination
          filterBar={inheritOptions => (
            <TransactionsFilterBar
              {...inheritOptions}
              filterKeys={CLEARING_FILTER_KEYS}
              sortingKeys={CLEARING_SETTLEMENT_SORTING_KEYS}
              sortOptions={sortOptions}
            />
          )}
          pageSize={10}
          withPageDropdown
          filterKeys={CLEARING_FILTER_KEYS}
          sortingKeys={CLEARING_SETTLEMENT_SORTING_KEYS}
          getDataMethod={fetchTransactions}
          columns={CLEARING_COLUMNS(theme, getText, isMerchant, currentAccount?.name)}
          defaultQueryParams={initQueryParams}
        />
      </div>
    </div>
  );
};

export default ClearingTab;
