import { css } from 'emotion';

export const translationsTabs = (theme, hideClose) => css(
  {
    overflow: 'unset !important',
    '& .ant-tabs-tab': {
      background: 'none !important',
      border: 'none !important',
    },
    '& .ant-tabs-tab:first-child': {
      paddingLeft: '0px !important',
    },
    '& .ant-tabs-tab-active .ant-tabs-tab-btn': {
      borderBottom: `2px solid ${theme.primary_yellow} !important`,
    },
    '& .ant-tabs-nav::before': {
      borderBottom: 'none !important',
    },
    '& .ant-tabs-nav': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  },
  hideClose && {
    '& .ant-tabs-nav': {
      '& .ant-tabs-nav-list': {
        display: 'none'
      }
    }
  }
);

export const translationsInputSkeleton = css({
  width: '100%',
});

export const translationsCountryDropdown = css({
  minWidth: '12.5rem',
  '& div:nth-child(2)': {
    maxHeight: '12.1875rem',
  },
});
