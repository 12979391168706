import { css } from 'emotion';
import styled from '@emotion/styled';
import { BlackRating } from 'components/ui';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const couponTemplate = theme =>
  css({
    color: theme.gray100,
    display: 'block',
    padding: 0,
  });

export const couponThumbnail = css({
  width: '100%',
  height: 'auto',
});

export const thumbnailContainer = isVertical =>
  css({
    position: 'relative',
    marginBottom: isVertical && 24,
    [Mobile]: {
      marginBottom: !isVertical && 24,
    },
    height: '10.625rem',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    justifyContent: 'center',
  });

export const merchantInfo = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.75rem',
  '& img': {
    margin: '0px 0px 0px 0.25em',
  },
});
export const titleWrapper = isVertical =>
  css({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    [Desktop]: {
      marginLeft: !isVertical && 24,
      marginRight: !isVertical && 24,
    },
  });

export const couponTitle = css({
  fontSize: '1.125rem',
  lineHeight: 1.3,
  fontWeight: 500,
  marginBottom: '0.25em',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
});

export const verticalTitle = css({
  fontSize: '0.875rem',
  lineHeight: 1.3,
  fontWeight: 500,
  marginBottom: '0.25em',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
});

export const StarRatings = styled(BlackRating)`
  &.ant-rate {
    font-size: 1rem;
  }
`;

export const ratingsCountLink = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
    marginLeft: '0.3125em',
    textDecoration: 'underline',
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
    marginLeft: '0.3125em',
  });

export const couponDescription = css({
  flex: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  fontSize: '1rem',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  marginBottom: 0,
  '@media (min-width: 993px)': {
    minHeight: '3.0625rem',
    maxHeight: '3.25rem',
  },
});

export const couponDescriptionVertical = css({
  flex: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  fontSize: '0.875rem',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  marginBottom: '0.75em',
  '@media (min-width: 993px)': {
    minHeight: '2.75em',
  },
});

export const dealEndDateWrapper = css({
  fontSize: '0.6875rem',
  marginBottom: 0,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '.bold': {
    fontWeight: 700,
    marginRight: '0.75em',
    marginLeft: '0.3125em',
  },
  '.icon-Ends-on': {
    fontSize: '1.25rem',
    lineHeight: '1rem',
    marginRight: '0.375em',
  },
  '& .delivery-icon': {
    fontSize: '1.375rem',
    marginRight: '0.25em',
  },
  '& .icon-QuestionMark-dotted': {
    fontSize: '0.875rem',
    marginLeft: '0.1875em',
  },
});

export const buyerInfoContainer = isVertical =>
  css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [Mobile]: {
      marginTop: !isVertical && 20,
      alignItems: 'flex-start',
    },
    '& label': { fontWeight: 400 },
    '& .coupons-count': { fontWeight: 700, marginLeft: '0.1875em' },
    '& .coupons-count-wrapper': { [Mobile]: { flexDirection: 'row' } },
    '& .icon-Messages': { fontWeight: 700 },
    '& .message-merchant-btn': {
      '& a': {
        paddingLeft: '0',
      },
    },
  });

export const couponCountContainer = (theme, isVertical) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: isVertical ? 'row' : 'column',
    marginTop: isVertical && 20,
    borderRadius: 3,
    padding: '1em',
    background: theme.gray600,
    [Mobile]: {
      '& > div': {
        gap: '1em',
      },
    },
  });
