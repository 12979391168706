import React, { useState, useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { isNil, invert, isEmpty } from 'lodash-es';
import { Tabs, Spin } from 'antd';
import ListingDeal from 'components/deal/ListingDeal';
import { SearchInput, SortDropdown } from 'components/ui';
import { Button, Pagination, useUrlParams } from 'components/UIExternal';
import ConfirmationPopup from 'components/popups/ConfirmationPopup';
import useError from 'services/errorHandling/useError';
import { getWishlistDeals, clearWishlist } from 'services/api/wishlist';
import { mb } from 'assets/css/globalCSS';
import emptyWishlistImg from 'assets/images/emptyWishlist.svg';
import {
  myProfileTab,
  navigationWrapper,
  tabsWrapper,
  emptyWishlistWrapper,
  emptyWishlistLabelWrapper,
  wishlistBanner,
  wishlistDealsWrapper,
  filtersWrapper,
  sortStyles,
} from './styles';

const tabsConfig = {
  all: 'All',
  availableSoon: 'Available Soon',
};

const dealsSortingMap = {
  [tabsConfig.all]: 0,
  [tabsConfig.availableSoon]: 1,
};

const filterKeys = {
  search: 'searchText',
  availability: 'availability',
  sorting: 'dealsSorting',
};

const MyWishlistTab = () => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();
  const { queryParams, setQueryParams } = useUrlParams();
  const [deals, setDeals] = useState(null);
  const [activeTab, setActiveTab] = useState(
    invert(dealsSortingMap)[queryParams[filterKeys.availability]] ?? tabsConfig.all,
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const paginationRef = useRef();
  const [searchTerm, setSearchTerm] = useState('');
  const { TabPane } = Tabs;

  const fetchDeals = async options => {
    const [res, err] = await getWishlistDeals(options);
    err ? setError(err) : setDeals(res?.items.map(deal => ({ ...deal, isInWishList: true })));
    return res;
  };

  const onTabClickHandler = newActiveTab => {
    if (newActiveTab === activeTab) return;
    setActiveTab(newActiveTab);
    setQueryParams({ [filterKeys.availability]: dealsSortingMap[newActiveTab] });
  };

  const onReloadPage = async () => {
    if (paginationRef?.current.totalResults - 1 === 0 && queryParams.searchText?.length > 0) {
      setSearchTerm('');
      paginationRef.current.applyFilters({ [filterKeys.search]: null });
    } else {
      const updatedDeals = await fetchDeals(queryParams);
      paginationRef.current.changeTotalResults(updatedDeals);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleClearWishlist();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClearWishlist = async () => {
    const [, err] = await clearWishlist();
    err ? setError(err) : setDeals([]);
  };

  const handleSearchSubmit = val => {
    paginationRef.current?.applyFilters({ [filterKeys.search]: val });
  };

  const handleSearchChange = val => {
    setSearchTerm(val);
  };

  return (
    <>
      <div className={myProfileTab}>
        <div className={navigationWrapper(theme)}>
          <div className={tabsWrapper(theme)}>
            <Tabs
              destroyInactiveTabPane
              defaultActiveKey={activeTab}
              activeKey={activeTab}
              onTabClick={onTabClickHandler}
            >
              <TabPane tab={getText('all')} key={tabsConfig.all} />
              <TabPane tab={getText('availableSoon')} key={tabsConfig.availableSoon} />
            </Tabs>
          </div>
          <div className={filtersWrapper}>
            <SearchInput
              allowClear
              onSearch={handleSearchChange}
              defaultValue={queryParams[filterKeys.search]}
              value={searchTerm}
              onPressEnter={handleSearchSubmit}
              placeholder={getText('searchForDeals')}
            />
            <SortDropdown
              allowClear
              inputClass={sortStyles}
              value={queryParams[filterKeys.sorting]}
              onSortChange={val => paginationRef.current?.applyFilters({ [filterKeys.sorting]: val })}
            />
          </div>
        </div>

        {isNil(deals) && (
          <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
            <Spin size="large" />
          </div>
        )}

        <div className={wishlistBanner}>
          <Pagination ref={paginationRef} onChange={fetchDeals} pageSize={4} filterKeys={filterKeys} />
          {!isEmpty(deals) && (
            <Button type="secondary" small onClick={() => setIsModalVisible(true)} className="clearBtn">
              {getText('clearWishlist')}
            </Button>
          )}
        </div>

        <div className={wishlistDealsWrapper}>
          {deals?.map((deal, idx) => (
            <ListingDeal
              key={idx}
              deal={deal}
              isWishlistDeal
              mainDealName={deal.name}
              reloadPage={onReloadPage}
              mainDealId={deal.id}
              isMerchant={deal?.isOwner}
            />
          ))}
        </div>

        {isEmpty(deals) && (
          <div className={emptyWishlistWrapper}>
            <img src={emptyWishlistImg} alt="Empty folder" />
            <div className={emptyWishlistLabelWrapper(theme)}>
              <label>{getText('noDealsInWishlist')}</label>
              <Button type="primary" small linkTo="/">
                {getText('browseDeals')}
              </Button>
            </div>
          </div>
        )}
      </div>
      <ConfirmationPopup
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        text={getText('sureClearWishlist')}
      />
    </>
  );
};

export default MyWishlistTab;
