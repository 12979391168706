import { getReq, postReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';

const baseUrl = `${envConfig.apiAddress}/api`;

export const getAvailableCredits = () => getReq(`${baseUrl}/Accounts/GetAvailableCredits`);

export const getCreditPacks = async () => getReq(`${baseUrl}/CreditPacks/List`);

export const buyCredits = packId => postReq(`${baseUrl}/CreditPurchases/Buy`, { packId });
