import { css } from 'emotion';

export const savedFiltersSelect = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '0.5em !important',
  paddingBottom: '1.5em !important',
  '& .ant-select': {
    fontSize: '1.0625rem',
    borderRadius: '21px !important',
  },
  '& .ant-select-selector': {
    fontSize: '1.0625rem',
    borderRadius: '21px !important',
  },
  '& .ant-select-arrow': {
    marginTop: '-0.875em !important',
  },
  '& .ant-select-clear': {
    marginTop: '-0.875em !important',
  },
  '& .ant-select-selection-item .icon-Delete': {
    display: 'none',
  },
});

export const buttonStyles = css({
  minHeight: 'auto',
  padding: '0',
});
