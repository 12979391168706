import { css } from 'emotion';

export const mapContainer = (className, theme) =>
  css({
    '& .leaflet-popup': {
      zIndex: 2,
    },

    '& .marker-cluster': {
      color: theme.gray100,
      backgroundColor: theme.primary_blue,
      '& div': {
        backgroundColor: theme.primary_blue_hover,
      },
    },
    '& .leaflet-marker-icon': {
      zIndex: '1 !important',
    },
    '& .leaflet-popup-content': {
      '& a': {
        minHeight: 0,
        padding: 0,
        maxWidth: '10rem',
        whiteSpace: 'break-spaces',
        '&:not(:last-of-type)': {
          marginBottom: '1.5em',
        },
      },
    },
    ...className,
  });
