import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { getToken } from '@oneecosystem/authenticate';
import { CloudUploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { Row, Col, Input, Form, Spin, message, Upload } from 'antd';
import OrderSummaryCoupon from 'components/coupons/OrderSummaryCoupon';
import { Button } from 'components/UIExternal';
import { NestedCol } from 'components/ui';
import apiRoutes from 'config/apiRoutes';
import MediaTypesEnum from 'enums/MediaTypesEnum';
import useError from 'services/errorHandling/useError';
import { getRefundRequestDeal, createRefund } from 'services/api/refundService';
import { removeMedia } from 'services/api/mediaService';
import { mb } from 'assets/css/globalCSS';
import {
  titleForm,
  refundFormWrapper,
  wrapperInput,
  uploadItem,
  imageUploadDragDrop,
  imagesPreview,
  formButtons,
  blackTextLink,
} from './styles';

const RequestRefundForm = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const history = useHistory();
  const { setError } = useError();
  const { couponId } = useParams();
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const [images, setImages] = useState([]);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [refundDeal, setRefundDeal] = useState();
  const [disableRequestButton, setDisableRequestButton] = useState(false);

  const getMapData = () =>
    images.map(image => ({
      uid: image.id,
      url: image.url,
      thumbUrl: image.thumbnailUrl,
      name: image.id,
      status: 'done',
    }));

  const imgUploadProps = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    accept: MediaTypesEnum.Image.allowedTypes.join(', '),
    action: apiRoutes.MEDIA_ADD_IMAGE,
    headers: { Authorization: `Bearer ${getToken().access_token}` },
    beforeUpload: file => {
      if (file.size > MediaTypesEnum.Image.allowedSize) {
        message.error(`${file.name} ${getText('uploadFailedFileSizeBigger')}`);
        return Upload.LIST_IGNORE;
      }
      if (!MediaTypesEnum.Image.allowedTypes.includes(file.type)) {
        message.error(`${file.name} ${getText('uploadFailedWrongFormat')}`);
        return Upload.LIST_IGNORE;
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') setImages([...images, info.file.response]);
      if (status === 'done') message.success(`${info.file.name} ${getText('fileUploadedSuccessfully')}`);
      else if (status === 'error') message.error(`${info.file.name} ${getText('fileUploadFailed')}`);
    },
  };

  const onRemoveImage = async id => {
    setDeleteInProgress(true);
    const [, err] = await removeMedia(id);
    err && setError(err);
    setImages(images.filter(img => img.id !== id));
    setDeleteInProgress(false);
  };

  const submitForm = values => {
    setDisableRequestButton(true);
    const payload = {
      couponId: Number(couponId),
      title: values.title,
      description: values.description,
      images: images.map((image, i) => ({ id: image.id, order: i })),
    };
    createRefund(payload, setError).then(() => {
      setDisableRequestButton(false);
      history.push('/my-profile/coupons');
    });
  };

  useEffect(() => {
    getRefundRequestDeal(couponId, setError).then(res => res && setRefundDeal(res));
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-container">
      <h2 className={titleForm(theme)}>{getText('requestRefundFor')}</h2>
      {refundDeal ? (
        <>
          <Row>
            <Col lg={24} span={24} className={mb({ lg: 35, span: 30 })}>
              {refundDeal && <OrderSummaryCoupon coupon={refundDeal} isInRefundForm />}
            </Col>
          </Row>
          <Row>
            <Col lg={16} span={24} className={refundFormWrapper(theme)}>
              <Form form={form} onFinish={submitForm} layout="vertical">
                <Form.Item
                  label={getText('title')}
                  name="title"
                  className={wrapperInput}
                  rules={[
                    {
                      required: true,
                      message: getText('pleaseEnterDealTitle'),
                    },
                  ]}
                >
                  <Input type="text" placeholder={getText('enterTitle')} />
                </Form.Item>
                <Form.Item
                  label={getText('description')}
                  name="description"
                  className={wrapperInput}
                  rules={[
                    {
                      required: true,
                      message: getText('pleaseEnterDealDescription'),
                    },
                  ]}
                >
                  <TextArea type="text" placeholder={getText('describeReason')} />
                </Form.Item>
                <Form.Item label={getText('uploadImages')} className={uploadItem}>
                  <Row gutter={[30, 30]}>
                    <NestedCol span={24} lg={8} totalWidth={16}>
                      <Upload.Dragger className={imageUploadDragDrop} {...imgUploadProps}>
                        <p className="ant-upload-drag-icon">
                          <CloudUploadOutlined />
                        </p>
                        <p className="ant-upload-text">
                          <p>{getText('dragAndDropImages')}</p>
                        </p>
                      </Upload.Dragger>
                    </NestedCol>
                    <NestedCol span={24} lg={6} totalWidth={16}>
                      {deleteInProgress ? (
                        <div className="content-container">
                          <Spin size="large" />
                        </div>
                      ) : (
                        <ImgCrop>
                          <Upload
                            className={imagesPreview}
                            onPreview={null}
                            listType="picture-card"
                            fileList={getMapData()}
                            onRemove={e => onRemoveImage(e.uid)}
                          />
                        </ImgCrop>
                      )}
                    </NestedCol>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col lg={16} span={24} className={formButtons}>
              <Button type="link" small className={blackTextLink(theme)} onClick={() => history.goBack()}>
                {getText('goBack')}
              </Button>
              <Button type="primary" small disabled={disableRequestButton} onClick={() => form.submit()}>
                {getText('requestRefund')}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default RequestRefundForm;
