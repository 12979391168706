import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Text, View, Svg, Path, G, Image, Rect, Polygon } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const color = {
  st0: '#3A6DB5',
  st1: '#FECF09',
  st2: '#3469B3',
  st3: '#FFFFFF',
  st4: '#FECF0C',
};

const BusinessCardTemplate = ({ qrCode, businessInfo }) => {
  const SVGComponentBack = () => (
    <Svg x="0px" y="0px" viewBox="0 0 255.1 141.7" style={generalStyles.pageBackground}>
      <Rect x={-6.6} y={-7.1} fill={color.st0} width={269.3} height={155.9} />
      <Path
        fill={color.st1}
        d="M237.1,34.7c-9.3-9-14.6-21.4-14.7-34.3c0-2.5,0.1-5,0.5-7.5h-30.2c-0.2,2.6-0.3,5.2-0.3,7.9  c0.3,20.9,8.8,40.8,23.8,55.4c12.7,12.4,29.1,20,46.5,21.9V47.8C253.1,46.2,244.2,41.6,237.1,34.7z"
      />
    </Svg>
  );

  const SVGComponentFront = () => (
    <Svg id="Layer_1" x="0px" y="0px" viewBox="0 0 255.1 141.7" style={{ position: 'absolute', top: 0 }}>
      <Rect x={-7.6} y={-7} fill={color.st0} width={269.3} height={155.9} />
      <G id="XMLID_1_">
        <Path
          id="XMLID_35_"
          fill={color.st2}
          d="M179.3,101.3l-22.9,40.4h-25.7l48.6-87.5l49.1,87.6h-26.2C194.9,128.8,187.5,115.6,179.3,101.3   z"
        />
        <Path
          id="XMLID_3_"
          fill={color.st2}
          d="M157-7.1l-26.3,47.5h25.7c7.5-13.2,14.9-26.3,23-40.6l22.9,40.6h26.1   c-8.9-15.9-17.7-31.6-26.5-47.4H157z"
        />
      </G>
      <G id="XMLID_5_">
        <Path
          id="XMLID_17_"
          fill={color.st3}
          d="M89.7,63.3c-1,1.6-3,2.2-5,1.8v-7.7c1.9-0.5,3.7,0,4.8,1.4c0.5,0.6,0.7,1.4,0.7,2.2   C90.2,61.9,90,62.7,89.7,63.3L89.7,63.3z M87.7,52.7c-2.5-0.3-5-0.1-7.5-0.2c-0.1,0-0.2,0.1-0.3,0.2v17.4c2.2,0,4.4,0,6.5,0   c4.5-0.1,8.3-3.6,8.7-7.9C95.4,57.5,92.3,53.3,87.7,52.7z"
        />
        <Polygon
          id="XMLID_16_"
          fill={color.st3}
          points="95.6,89.9 95.6,72.8 100.1,72.8 100.1,78.9 105.9,78.9 105.9,72.8 110.5,72.8    110.5,89.9 106,89.9 106,83.8 100.2,83.8 100.2,89.9  "
        />
        <Polygon
          id="XMLID_15_"
          fill={color.st3}
          points="100.5,57.3 100.5,58.9 104.1,58.9 104.1,63.8 100.5,63.8 100.5,65.3 108.8,65.3    108.8,70 95.6,70 95.6,52.5 108.8,52.5 108.8,57.3  "
        />
        <Polygon
          id="XMLID_14_"
          fill={color.st3}
          points="159.6,90.1 146.5,90.1 146.5,72.7 159.6,72.7 159.6,77.2 151.2,77.2 151.2,79.1    154.9,79.1 154.9,83.7 151.3,83.7 151.3,85.5 159.6,85.5  "
        />
        <Path
          id="XMLID_13_"
          fill={color.st3}
          d="M94.6,75.3c-1.1,0.9-3.4,2.8-3.4,2.8s-1.7-0.9-3.1-1.2c-0.9-0.2-2.7-0.4-3.2,0.6   c-0.6,1,0.9,1.5,1.5,1.6c0.9,0.1,1.9,0,2.8,0.2c3.6,0.6,5.6,2.8,5.3,6c-0.1,1.1-0.6,2.2-1.4,3.1C90,91.7,83.1,90.9,80,87.1l3.6-2.5   c0,0,1.5,1.1,3,1.3c0.9,0.1,2,0.1,2.8-0.4c0.2-0.1,0.4-0.4,0.5-0.6c0.2-0.6-0.6-1-1.1-1.1c-0.9-0.1-1.8-0.2-2.7-0.3   c-3.3-0.3-5.5-2.2-6-4.9c-0.3-1.6,0.1-3,1.2-4.1C84.2,71,91.2,72,94.6,75.3z"
        />
        <Path
          id="XMLID_12_"
          fill={color.st3}
          d="M145.5,87.5l-3.8,2.6l-5.8-6.4l-0.1,6.2h-4.5V72.8h4.5v6.5c0,0,4-4.4,5.9-6.6l3.6,2.8l-5.1,5.9   L145.5,87.5z"
        />
        <Path
          id="XMLID_11_"
          fill={color.st4}
          d="M120.8,52.6c3.3,5.9,6.4,11.5,9.7,17.3h-5.2l-4.5-8c-1.6,2.8-3.1,5.4-4.5,8h-5.1L120.8,52.6z"
        />
        <Path
          id="XMLID_10_"
          fill={color.st4}
          d="M120.8,81.9l-4.5,8h-5.1l9.6-17.3l9.7,17.3h-5.2C123.8,87.4,122.4,84.8,120.8,81.9z"
        />
        <Polygon
          id="XMLID_9_"
          fill={color.st3}
          points="135.9,52.6 135.9,65.3 144.3,65.3 144.3,70 131.2,70 131.2,52.6  "
        />
        <Path
          id="XMLID_6_"
          fill={color.st3}
          d="M170.1,79.6c0,0.5-0.3,1-0.6,1.3c-0.4,0.4-0.8,0.6-1.3,0.6c-1,0.1-2,0-3.1,0v-4.3   c1.2,0,2.3-0.1,3.3,0C169.5,77.5,170.2,78.6,170.1,79.6z M172.6,84.7c0.4-0.5,0.7-0.9,1-1.3c2.8-3.9,0.8-9.7-3.9-10.5   c-3-0.5-6.1-0.1-9.2-0.1v17.2h4.6v-3.7c0,0,2.5-0.3,3.7,0.9c1.4,1.3,2.8,2.7,4.3,4.2l3.2-3.4v-0.2L172.6,84.7z"
        />
      </G>
    </Svg>
  );

  return (
    <Document>
      <Page size={[255.1, 141.7]}>
        <View>
          <SVGComponentBack />
          <View style={generalStyles.wrapper}>
            <View style={s.contentBox}>
              <Image source={qrCode} style={s.qrCodeStyles} />
              <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
              <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
              <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size={[255.1, 141.7]}>
        <SVGComponentFront />
      </Page>
    </Document>
  );
};

BusinessCardTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default BusinessCardTemplate;
