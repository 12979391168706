import React from 'react';
import { Skeleton } from 'components/UIExternal';
import { loadingOrderContainer, loadingOrderCardRow } from './styles';

const LoadingCard = () => (
  <div className={loadingOrderContainer}>
    <section className={loadingOrderCardRow}>
      <Skeleton width="20%" height={20} />
      <Skeleton width="20%" height={20} />
      <Skeleton width="30%" height={14} margin="0 0 0 auto" />
    </section>
    <section className={loadingOrderCardRow}>
      <Skeleton width="40%" height={60} />
      <Skeleton width="100%" height={14} />
    </section>
    <section className={loadingOrderCardRow}>
      <Skeleton width="30%" height={14} />
      <Skeleton width="30%" height={14} />
    </section>
  </div>
);

export const LoadingOrderCard = () =>
  Array(4)
    .fill(null)
    .map((_, ind) => <LoadingCard key={ind} />);
