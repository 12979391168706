export const changeStatusOptions = [
  { value: 1, label: 'Enable' },
  { value: 2, label: 'Disable' },
];

export const codeStatuses = {
  1: 'Not Used',
  2: 'Used',
  3: 'Disabled',
};

export const codeStatusesFilter = getText => [
  { value: 1, label: getText('status-filter-not-used') },
  { value: 2, label: getText('status-filter-used') },
  { value: 3, label: getText('status-filter-disabled') },
];

export const dateSorting = getText => [
  { value: 0, label: getText('date-descending') },
  { value: 1, label: getText('date-ascending') },
];

export const qrCodes = getText => [
  {
    value: 1,
    label: getText('versionNumber', { number: 1 }),
  },
  {
    value: 2,
    label: getText('versionNumber', { number: 2 }),
  },
];
