import { getReq, postReq, deleteReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';
import { getWishListParams, getWishListDealsBody } from 'services/models/wishList';

const baseUrl = `${envConfig.apiAddress}/api/Wishlists`;

export const getWishlistDeals = async options => {
  const [res, err] = await getReq(`${baseUrl}/Get?${getWishListParams(options)}`);
  return [res && getWishListDealsBody(res), err];
};

export const addDealToWishlist = dealId => postReq(`${baseUrl}/Add`, { dealId });

export const deleteDealFromWishlist = dealId => deleteReq(`${baseUrl}/Remove/${dealId}`);

export const clearWishlist = () => deleteReq(`${baseUrl}/Clear`);
