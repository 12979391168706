import { css } from 'emotion';

export const countDownTimerContainer = theme =>
  css({
    '& .countdown-label': {
      marginRight: '0.5em',
    },
    '& .countdown-timer': {
      padding: '0.25em',
      fontSize: '1rem',
      borderRadius: 4,
      color: theme.white,
      backgroundColor: theme.primary_orange,
    },
  });
