import { css } from 'emotion';

export const worldWideOptionContainer = css({
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row nowrap',
  fontSize: '1rem',
  cursor: 'pointer',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
});
