import { StyleSheet } from '@react-pdf/renderer';

export const pdfStyles = StyleSheet.create({
  document: {
    padding: 20,
    fontFamily: 'Noto',
  },
  headline: {
    backgroundColor: '#000',
    color: '#fff',
    fontWeight: 600,
    fontSize: 18,
    padding: '5px 10px',
    borderRadius: 4,
    margin: '20px 0',
  },
  logo: {
    width: 80,
    display: 'block',
  },
  orderSummary: {
    orderNumber: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
      fontSize: 16,
    },
    table: {
      border: '2px solid #DCDDE0',
      borderRadius: 4,
      header: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #DCDDE0',
        element: {
          fontSize: 13,
          padding: 5,
          fontWeight: 600,
          leftEl: {
            borderLeft: '1px solid #DCDDE0',
          },
        },
        content: {
          width: '33%',
        },
        quantity: {
          width: '6%',
          alignItems: 'center',
        },
      },
      body: {
        row: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        element: {
          fontSize: 11,
          padding: 5,
          leftEl: {
            borderLeft: '1px solid #DCDDE0',
          },
        },
        content: {
          width: '33%',
        },
        quantity: {
          width: '6%',
          alignItems: 'center',
        },
      },
    },
    total: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 10,
      fontWeight: 600,
      fontSize: 16,
    },
  },
  grid: {
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    element: {
      width: '50%',
    },
    title: {
      fontSize: 13,
      fontWeight: 600,
    },
    content: {
      fontSize: 11,
      marginTop: 5,
    },
  },
});
