import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Rate } from 'antd';
import { Pagination, Dropdown } from 'components';
import { formatDefaultDate } from 'utils';
import { reviewsFilterSelect } from 'enums';
import { setError, getReviewsListByBusiness } from 'services';
import noReviewsYet from 'assets/images/no-reviews.svg';
import {
  reviewsContainer,
  reviews,
  reviewsSummaryInfo,
  rating,
  barContainer,
  bar,
  reviewItem,
  noReviews,
  reviewSortFilter,
} from './styles';

const filterKeys = { reviewsSorting: 'reviewsSorting' };

export const ReviewsTab = ({ reviewsSummary, businessId }) => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const paginationRef = useRef();
  const [reviewsList, setReviewsList] = useState(null);

  const fetchReviewsByBusiness = async options => {
    const [res, err] = await getReviewsListByBusiness(options, businessId);
    err ? setError(err) : setReviewsList(res.items);
    return res;
  };

  if (!reviewsSummary) {
    return (
      <div className={noReviews}>
        <h2>{getText('reviews')}</h2>
        <img src={noReviewsYet} alt="No reviews yet" />
      </div>
    );
  }

  return (
    <>
      <div className={reviewsContainer}>
        <div className={reviews}>
          <div className="reviews-header">
            <h3>{getText('reviews')}</h3>
            <Dropdown
              placeholder="Select sorting"
              options={reviewsFilterSelect}
              onChange={val => paginationRef.current?.applyFilters({ [filterKeys.reviewsSorting]: val?.value })}
              className={reviewSortFilter}
            />
          </div>
          {reviewsList?.length ? (
            reviewsList?.map(review => (
              <div key={review.id} className={reviewItem(theme)}>
                <div className="header">
                  <div className="author-name">{review.authorName}</div>
                  <p className="review-date">{getText('reviewedDate', { date: formatDefaultDate(review.date) })}</p>
                </div>
                <div className="rating">
                  <Rate disabled value={review.stars} />
                  <span>{getText('ratingStarsCount', { ratingCount: review.stars, maxStars: 5 })}</span>
                </div>
                <div className="review-title">{review.title}</div>
                <div>{review.description}</div>
              </div>
            ))
          ) : (
            <div className={noReviews}>
              <img src={noReviewsYet} alt="No reviews yet" />
            </div>
          )}
          <Pagination
            ref={paginationRef}
            onChange={fetchReviewsByBusiness}
            pageSize={4}
            filterKeys={filterKeys}
            justify="center"
          />
        </div>
        <div className={rating(theme)}>
          <div className={reviewsSummaryInfo}>
            <h2>{reviewsSummary?.rating.toFixed(2)}</h2>
            <Rate disabled value={reviewsSummary?.rating} />
            <div className="stars-count">{reviewsSummary?.reviewsCount} reviews</div>
          </div>
          <div>
            <div className={barContainer}>
              <div className={bar(reviewsSummary?.star5Percent, theme)}>
                <div className="fillBar" />
              </div>
              <div className="stars-count">5 stars</div>
            </div>

            <div className={barContainer}>
              <div className={bar(reviewsSummary?.star4Percent, theme)}>
                <div className="fillBar" />
              </div>
              <div className="stars-count">4 stars</div>
            </div>

            <div className={barContainer}>
              <div className={bar(reviewsSummary?.star3Percent, theme)}>
                <div className="fillBar" />
              </div>
              <div className="stars-count">3 stars</div>
            </div>

            <div className={barContainer}>
              <div className={bar(reviewsSummary?.star2Percent, theme)}>
                <div className="fillBar" />
              </div>
              <div className="stars-count">2 stars</div>
            </div>

            <div className={barContainer}>
              <div className={bar(reviewsSummary?.star1Percent, theme)}>
                <div className="fillBar" />
              </div>
              <div className="stars-count">1 stars</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ReviewsTab.propTypes = {
  reviewsSummary: PropTypes.object,
  businessId: PropTypes.number,
};
