import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const mainContainer = (theme, className) =>
  css(
    {
      fontWeight: 400,
      fontSize: '0.875rem',
      color: theme.gray100,
      [Mobile]: { width: '100%' },
      '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
        borderRadius: '28px',
        border: `1px solid ${theme.gray500}`,
        [Desktop]: { width: '12.5rem' },
        backgroundColor: 'transparent',
      },
      '& .ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
        border: `1px solid ${theme.gray500}`,
      },
      '& .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector': {
        borderColor: theme.gray500,
        boxShadow: 'none',
      },
    },
    className,
  );
