import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import React from 'react';
import { isDesktop } from 'react-device-detect';
import { DealSkeleton } from '../DealSkeleton/DealSkeleton';
import { oneItemCarouselWrapper, DealContainer, inputWrapper } from './styles';

export const CarouselSkeleton = ({ numberOfDealsPerSlide }) =>
  !numberOfDealsPerSlide ? (
    isDesktop ? (
      <div className={oneItemCarouselWrapper}>
        <div style={{ width: '48%' }}>
          <Skeleton.Image size="large" />
        </div>
        <div className={inputWrapper}>
          <Skeleton.Input />
          <Skeleton.Input />
          <Skeleton.Input />
        </div>
      </div>
    ) : (
      <div className={oneItemCarouselWrapper}>
        <DealSkeleton numberOfDeals={1} />
      </div>
    )
  ) : (
    <DealContainer>
      <DealSkeleton numberOfDeals={numberOfDealsPerSlide} />
    </DealContainer>
  );

CarouselSkeleton.propTypes = {
  numberOfDealsPerSlide: PropTypes.number,
};
