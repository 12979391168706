import { css } from 'emotion';
import styled from '@emotion/styled';

export const MyProfilePage = styled.div`
  // for the before tag to work this needs to be a styled component
  background-color: ${props => props.theme.white};
  position: relative;

  & .gray-background {
    background-color: ${props => props.theme.gray600};
  }
  & > .content-container:not(:first-of-type) {
    padding-top: 0;
  }
  & > .content-container > .ant-tabs:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2.8125rem;
    background-color: ${props => props.theme.gray600};
  }
`;

export const title = theme =>
  css({
    color: theme.background100,
    fontSize: '1.4375rem',
    fontWeight: 700,
    marginBottom: 0,
  });
