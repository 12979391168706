import { invert } from 'lodash-es';

export const promoCodeType = {
  fiat: 1,
  percent: 2,
};

export const promoCodeTypeInverse = invert(promoCodeType);

export const promoCodeTypeSelect = getText =>
  Object.entries(promoCodeType).map(([key, value]) => ({
    label: getText(`create-promo-code-${key}`),
    value,
  }));

export const promoCodeStatus = {
  notUsed: 1,
  used: 2,
  disabled: 3,
};
