import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import { isNil, isArray } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { calculateDealPrice, useLocations } from '@oneecosystem/dealshaker-core';
import { Row, Col, Collapse } from 'antd';
import { isDesktop } from 'react-device-detect';
import { SortDropdown } from 'components/ui';
import { Button, Skeleton, useDeepEffect, useUrlParams } from 'components/UIExternal';
import FeaturedDeals from 'components/deal/FeaturedDealsPanel';
import apiRoutes from 'config/apiRoutes';
import useError from 'services/errorHandling/useError';
import { usePageNumberPaging } from 'services/axios/paging';
import { getDealsPriceRanges } from 'services/api/dealsService';
import { getCategory } from 'services/api/category';
import ListingDeal from 'components/deal/ListingDeal';
import { FilterDealsPanel } from './components';
import { listingContainer, mainContainer, topListContainer, fullWidth, skeletonCustomStyles } from './styles';

const getStateForParamsFromUrlParams = params => ({
  categoryId: (params?.categoryId && Number(params?.categoryId)) ?? null,
  searchText: params?.searchText ?? null,
  isFeatured: Boolean(params?.isFeatured),
  isAvailableNow: Boolean(params?.isAvailableNow),
  isPriceOneOnly: Boolean(params?.isPriceOneOnly),
  discountAbove: (params?.discountAbove && Number(params?.discountAbove)) ?? null,
  deliveryMethods: (isArray(params?.deliveryMethods)
    ? params?.deliveryMethods
    : [params?.deliveryMethods].filter(el => !isNil(el))
  ).map(Number),
  rating: (params?.rating && Number(params?.rating)) ?? null,
  dealsSorting: params?.dealsSorting === 0 || params?.dealsSorting ? params?.dealsSorting : null,
  from: params?.from?.map(Number) ?? [],
  to: params?.to?.map(Number) ?? [],
});

const DealListingPage = () => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const { shippingFrom, shippingTo } = useLocations();
  const { setError } = useError();
  const { queryParams } = useUrlParams();
  const { categoryId, searchText, dealsSorting } = queryParams;
  const [categoryName, setCategoryName] = useState(null);
  const [priceRangesForDeals, setPriceRangesForDeals] = useState(null);
  const [sortingValue, setSortingValue] = useState(dealsSorting);
  const pageSize = 20;
  const [pageNumber, setPageNumber] = useState(1);
  const [filterValues, setFilterValues] = useState(getStateForParamsFromUrlParams(queryParams));

  const [searchedDeals, loadNewPage, loadNewFilter, pagingInfo = null] = usePageNumberPaging(
    apiRoutes.DEALS_LIST,
    pageSize,
    { pageSize: 10 },
    false,
  );

  const loadedDeals = searchedDeals.map(el => calculateDealPrice(el));

  // refactored useEffect
  useDeepEffect(() => {
    setFilterValues(getStateForParamsFromUrlParams(queryParams));
  }, [queryParams]);

  useDeepEffect(() => {
    setFilterValues(prev => ({ ...prev, from: shippingFrom.map(el => el.id), to: shippingTo.map(el => el.id) }));
  }, [shippingFrom, shippingTo]);

  useEffect(() => {
    categoryId && fetchCategory();
  }, [filterValues?.categoryId]);

  useEffect(() => {
    searchText && getHandleSearchTextChange(searchText);
  }, [searchText]);

  useDeepEffect(() => {
    loadNewFilter(filterValues);
    getDealsPriceRanges(filterValues, setError).then(res => setPriceRangesForDeals(res));
  }, [filterValues]);

  useEffect(() => {
    loadNewPage(pageNumber);
  }, [pageNumber]);

  const fetchCategory = async () => {
    const [res, err] = await getCategory(categoryId);
    if (err) return setError(err);
    setCategoryName(res);
  };

  const getHandleSortChange = value => setSortingValue(value);

  const getHandleSearchTextChange = value => {
    const updatedFilterValues = { ...filterValues };
    updatedFilterValues.searchText = value;
    setFilterValues(updatedFilterValues);
  };

  return (
    <>
      <FeaturedDeals categoryName={categoryName} className={listingContainer(theme)} />
      <div className={`content-container ${mainContainer}`}>
        <Row>
          <Col lg={6} span={24}>
            <Collapse collapsible={isDesktop && 'disabled'} defaultActiveKey={isDesktop && '1'}>
              <Collapse.Panel showArrow={!isDesktop} header={getText('filters')} key="1">
                <FilterDealsPanel
                  initialValues={filterValues}
                  onChange={setFilterValues}
                  priceRangesForDeals={priceRangesForDeals}
                  preSelectedCategoryId={categoryId}
                  sortingValue={sortingValue}
                  searchText={searchText}
                />
              </Collapse.Panel>
            </Collapse>
          </Col>
          <Col lg={{ offset: 1, span: 17 }} span={24}>
            <div className={`${fullWidth} products-list-container`}>
              <div className={`${topListContainer(theme)} products-count`}>
                {loadedDeals?.length > 0 ? (
                  <label>
                    {getText('showingProductsCount', {
                      count: pagingInfo?.count > 1000 ? '1k+' : pagingInfo?.count || 0,
                    })}
                  </label>
                ) : (
                  <Skeleton count={1} height={20} marginBottom={12} className={skeletonCustomStyles} />
                )}
                {isDesktop && (
                  <SortDropdown className="list-deals-sort" onSortChange={getHandleSortChange} value={sortingValue} />
                )}
              </div>
              {loadedDeals.map((deal, n) => (
                <ListingDeal key={n} deal={deal} mainDealName={deal.name} isMerchant={deal?.isOwner} />
              ))}
              {loadedDeals?.length > 0 && pagingInfo?.totalPages > pageNumber && (
                <Button type="secondary" small onClick={() => setPageNumber(pageNumber + 1)}>
                  {getText('loadMoreDeals')}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
      )
    </>
  );
};

export default DealListingPage;
