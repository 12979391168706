import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  contentBox: {
    position: 'absolute',
    bottom: '30%',
    width: '50%',
    right: 0,
    infoText: {
      marginTop: 20,
      position: 'absolute',
      left: 500,
    },
  },
  textBox: {
    country: {
      fontSize: 100,
      fontWeight: 600,
      marginBottom: 10,
      color: '#3A6DB5',
    },
    businessName: {
      fontSize: 200,
      fontWeight: 800,
      marginBottom: 10,
      color: '#3A6DB5',
    },
    location: {
      fontSize: 100,
      marginBottom: 10,
      color: '#3A6DB5',
    },
  },
  qrCodeStyles: {
    width: 450,
    marginRight: 50,
  },
});
