import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isEmpty } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Form, Input, Modal, Radio } from 'antd';
import { deliveryMethod } from 'enums';
import useError from 'services/errorHandling/useError';
import { updateDealStepData } from 'services/api/dealsService';
import FormActions from '../FormActions/FormActions';
import BusinessAddressesPicker from './components/BusinessAddressesPicker/BusinessAddressesPicker';
import BusinessAddressForm from '../../../MerchantOffice/components/BusinessAddressForm';
import ContinentPicker from './components/ContinentPicker/ContinentPicker';
import { box, radioGroup, addressesContainer } from './styles';

const StepFour = ({ stepFourData, currentStep, dealId, setManualStep, handleCreateStepData }) => {
  const theme = useTheme();
  const { setError } = useError();
  const [form] = Form.useForm();
  const { getText } = useTranslations();
  const { availableBusinessAddresses } = stepFourData;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showFiltersError, setShowFiltersError] = useState(false);

  useEffect(() => {
    form.resetFields();
    availableBusinessAddresses.concat(availableBusinessAddresses.map(addr => addr));
  }, [stepFourData]);

  const toggleIsModalVisible = () => setIsModalVisible(!isModalVisible);

  const onFormFinishHandler = data => {
    if (dealId) updateDealStepData(data, 4, dealId, setError).then(() => setManualStep(5));
  };

  const onFinishFailed = errorInfo =>
    errorInfo && isEmpty(errorInfo?.values.businessAddressIds) && setShowFiltersError(true);

  return (
    <>
      <Form
        className="block-form-item"
        form={form}
        initialValues={stepFourData}
        onFinish={onFormFinishHandler}
        onFinishFailed={onFinishFailed}
        onFieldsChange={changedValue => {
          const filteredValues = changedValue.filter(el => el.name[0] === 'businessAddressIds');
          if (filteredValues.length > 0) setShowFiltersError(false);
        }}
        scrollToFirstError
      >
        <div className={box(false, theme)}>
          <Form.Item
            colon={false}
            labelCol={{ lg: 24 }}
            name="deliveryMethodId"
            initialValue={deliveryMethod.selfOrganizedShipping}
            labelAlign="left"
            label={getText('deliveryMethod')}
            rules={[
              {
                required: true,
                message: getText('pleaseSelectDeliveryMethod'),
              },
            ]}
          >
            <Radio.Group className={radioGroup(theme)}>
              <Radio.Button value={deliveryMethod.selfOrganizedShipping}>
                {getText('selfOrganizedShipping')}
              </Radio.Button>
              <Radio.Button value={deliveryMethod.redeemAtLocation}>{getText('redeemAtLocation')}</Radio.Button>
              <Radio.Button defaultValue value={deliveryMethod.onlineService}>
                {getText('onlineService')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className={box(showFiltersError, theme)}>
          <Form.Item
            name="businessAddressIds"
            className={addressesContainer}
            valuePropName="activeAddress"
            rules={[
              {
                required: true,
                message: getText('pleaseSelectMerchantAddress'),
              },
            ]}
          >
            <BusinessAddressesPicker
              addresses={availableBusinessAddresses}
              toggleIsModalVisible={toggleIsModalVisible}
            />
          </Form.Item>
          <Form.Item
            colon={false}
            labelCol={{ lg: 24 }}
            name="additionalInstructions"
            initialValue=""
            labelAlign="left"
            label={getText('additionalInstructions')}
          >
            <Input placeholder={getText('moreInstructions')} />
          </Form.Item>
        </div>
        <div className={box(false, theme)}>
          <Form.Item
            colon={false}
            name="countryIds"
            valuePropName="selectedCountriesIdsFromData"
            labelCol={{ lg: 24 }}
            labelAlign="left"
            label={getText('countries')}
            rules={[
              {
                required: true,
                message: getText('pleaseSelectCountry'),
              },
            ]}
          >
            <ContinentPicker addressesCount={availableBusinessAddresses?.length} />
          </Form.Item>
        </div>
        <Form.Item>
          <FormActions
            setManualStep={setManualStep}
            currentStep={currentStep}
            onNextStepHandler={() => form.submit()}
          />
        </Form.Item>
      </Form>
      <Modal onCancel={toggleIsModalVisible} open={isModalVisible} footer={null} width={800}>
        <BusinessAddressForm isInModal toggleIsModalVisible={toggleIsModalVisible} setStepData={handleCreateStepData} />
      </Modal>
    </>
  );
};

StepFour.propTypes = {
  stepFourData: PropTypes.object,
  currentStep: PropTypes.number,
  setManualStep: PropTypes.func,
  dealId: PropTypes.number,
  handleCreateStepData: PropTypes.func,
};
export default StepFour;
