import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { dealStatus } from 'enums/deal';
import useError from 'services/errorHandling/useError';
import { getCreateDealStepData } from 'services/api/dealsService';
import { mr } from 'assets/css/globalCSS';

const StatusControlButtons = ({ statusId, dealId, closeDealModal, isMobile }) => {
  const { getText } = useTranslations();
  const { setError } = useError();

  const handleCreateDealStepData = async () => {
    const [, err] = await getCreateDealStepData(1, dealId);
    return err && setError(err);
  };

  return (
    <>
      <Button
        type={!isMobile ? 'info' : 'link'}
        small
        linkTo={`/edit-deal/${dealId}`}
        onClick={handleCreateDealStepData}
        className={mr({ lg: 10 })}
      >
        {getText('edit')}
      </Button>
      {(statusId === dealStatus.approved || statusId === dealStatus.pending || statusId === dealStatus.draft) && (
        <Button
          type={!isMobile ? 'info' : 'link'}
          small
          onClick={() => closeDealModal(true)}
          className={mr({ lg: 10 })}
        >
          {getText('closeDeal')}
        </Button>
      )}
    </>
  );
};

StatusControlButtons.propTypes = {
  dealId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closeDealModal: PropTypes.func,
  statusId: PropTypes.number,
  isMobile: PropTypes.bool,
};

export default StatusControlButtons;
