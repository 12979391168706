import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const reviewsContainer = css({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '10rem',
  minHeight: '80vh',
  [Mobile]: {
    flexDirection: 'column-reverse',
    alignItems: 'stretch',
    gap: '2rem',
  },
});

export const reviews = css({
  flex: '2 1 0',
  '& .reviews-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1.5em',
    '& h3': {
      marginBottom: '1rem'
    }
  },
});

export const rating = theme =>
  css({
    flex: '2 1 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${theme.gray500}`,
    borderRadius: 20,
    padding: '2em',
    boxShadow: `1px 1px 8px 2px ${theme.gray100}20`,
    '& > div': {
      width: '100%',
    },

    '& .stars-count': {
      color: theme.gray300,
    },
    [Mobile]: {
      flexDirection: 'column',
      gap: '1rem',
    },
  });

export const reviewsSummaryInfo = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '& h2': {
    fontSize: '3rem',
  },
  '& .ant-rate-star': {
    fontSize: 16
  }
});

export const barContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '1rem',
  marginBottom: '1rem',
});

export const bar = (value, theme) =>
  css({
    height: '.5rem',
    backgroundColor: theme.gray500,
    position: 'relative',
    borderRadius: 10,
    flex: '2 1 0',
    '& .fillBar': {
      position: 'absolute',
      borderRadius: 10,
      height: '100%',
      width: `${value}%`,
      backgroundColor: theme.primary_yellow,
    },
  });

export const reviewItem = theme =>
  css({
    borderBottom: `1px solid ${theme.gray500}`,
    padding: '2em 1em',
    marginBottom: '1em',
    '& .header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .author-name': {
        fontWeight: 600,
        marginBottom: '1sem',
      },
      '& .review-date': {
        color: theme.gray300,
      },
    },
    '& .rating': {
      marginBottom: '1em',
      '& > span': {
        marginLeft: '1em',
        fontSize: 12
      },
    },
    '& .review-title': {
      fontWeight: 600,
    },
  });

export const noReviews = css({
  minHeight: '80vh',
  '& img': {
    margin: '0 auto',
  },
});

export const reviewSortFilter = css({
  margin: '0 0 1em auto',
  width: '14rem',
});
