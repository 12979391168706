import { css } from 'emotion';

export const mainContainer = css({
  margin: '2.5em auto',
  '& .paragraph-text-wrap': {
    padding: '1.5em 1em 2em 1em',
    fontSize: '1rem',
    lineHeight: '1.25rem',

    '& h4': {
      marginBottom: '0.725rem',
      marginTop: '0.725rem',
    },
  },
  '& .icon-text-columns': {
    display: 'flex',
    alignItems: 'top',
    '& .icon-column': {
      width: 64,
      marginRight: '1rem',
    },
  },
});

export const header = css({
  display: 'flex',
});
