import { css } from 'emotion';

export const mainContainer = theme =>
  css({
    backgroundColor: theme.qrCodev2,
    margin: '2.5em auto',
    '& .terms-text-wrap': {
      padding: '1.5em 1em 2em 1em',
    },
  });
