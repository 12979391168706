import React from 'react';
import PropTypes from 'prop-types';
import { isFunction, isString } from 'lodash-es';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Dropdown } from 'components/UIExternal';
import { mainContainer } from './styles';

const CountryDropdown = ({ onChange, placeholder, value, className, noClear, uniqueKey = 'id' }) => {
  const countries = useLocations(locationsState => locationsState.countries);
  const handleChange = (prop, newValue) => {
    isFunction(onChange) &&
      onChange(
        prop,
        newValue,
        countries.find(el => el.id === prop),
      );
  };

  return (
    <div className={mainContainer} {...(isString(className) && { className })}>
      <Dropdown
        small
        withSearch
        noClear={noClear}
        placeholder={placeholder}
        options={countries}
        uniqueKey={uniqueKey}
        displayKey="name"
        mapValue={el => el?.id}
        value={value}
        onChange={handleChange}
        className={className}
      />
    </div>
  );
};

CountryDropdown.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.number,
  className: PropTypes.string,
  noClear: PropTypes.bool,
  uniqueKey: PropTypes.string,
};

export default CountryDropdown;
