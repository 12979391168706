import { css } from 'emotion';

export const brandColorsPage22Container = theme =>
  css({
    display: 'grid',
    gridTemplateColumns: '200px 200px',
    gridTemplateRows: '200px 200px',
    gap: '1em',
    color: theme.white,
    '& .body': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 0 0',
      textTransform: 'uppercase',
    },
    '& .header, .footer': { padding: '1em' },
    '& .dsGreen, .primaryOrange, .dsViolette, .dsNavy': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '& .dsGreen': {
      '& .header': { backgroundColor: theme.green2 },
      '& .body': { backgroundColor: theme.green3 },
      '& .footer': { backgroundColor: theme.green3 },
    },
    '& .primaryOrange': {
      '& .header': { backgroundColor: theme.red },
      '& .body': { backgroundColor: theme.primary_orange },
      '& .footer': { backgroundColor: theme.primary_orange },
    },
    '& .dsViolette': {
      '& .header': { backgroundColor: theme.purple },
      '& .body': { backgroundColor: theme.purple2 },
      '& .footer': { backgroundColor: theme.purple2 },
    },
    '& .dsNavy': {
      '& .header': { backgroundColor: theme.background100 },
      '& .body': { backgroundColor: theme.background200 },
      '& .footer': { backgroundColor: theme.background200 },
    },
  });
