import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { initTranslationsStore, useTranslations, getSavedLanguage, getBrowserLanguage } from '@veraio/strank';
import { getStorageValue } from '@veraio/core';
import {
  initCurrenciesStore,
  initLocationsStore,
  setDisplayFiatOnly,
  useLocations,
} from '@oneecosystem/dealshaker-core';
import Routing from 'screens/Routing';
import HeaderPanel from 'components/layouts/HeaderPanel';
import FooterPanel from 'components/layouts/FooterPanel';
import { ScrollToTop } from 'components/layouts/ScrollToTop';
import { useDeepEffect } from 'components/UIExternal';
import { Routes } from 'enums';
import { useUser } from 'stores';
import { AppStyles, contentWrapper } from './styles';
import { Logout, RedirectCallback, NotFound } from './Public';

const App = () => {
  const { isDisabled, userInfo, isAuthenticated } = useUser();
  const geoLocation = useLocations(locationsState => locationsState.geoLocation);
  const { changeLanguage, allEnvLanguages } = useTranslations();

  useEffect(() => {
    isAuthenticated && setDisplayFiatOnly(getStorageValue('fiatOnly') ?? false);
  }, [isAuthenticated]);

  useEffect(() => {
    initializeStores();
  }, []);

  useDeepEffect(() => {
    geoLocation?.languageCode &&
      allEnvLanguages?.length &&
      changeLanguage(getSavedLanguage() ?? geoLocation.languageCode ?? getBrowserLanguage());
  }, [geoLocation, allEnvLanguages]);

  const initializeStores = async () => {
    initTranslationsStore();
    await initLocationsStore();
    initCurrenciesStore();
  };

  const mainApp = (
    <AppStyles>
      <HeaderPanel />
      <main className={contentWrapper}>
        {!isDisabled ? (
          <>
            <ScrollToTop />
            <Routing />
          </>
        ) : (
          <h1 className="content-container">
            Your current profile is {userInfo.status} and you do not have access to the portal.
          </h1>
        )}
      </main>
      {!isDisabled && <FooterPanel />}
    </AppStyles>
  );

  return (
    <Switch>
      <Route exact path={Routes.redirectCallback} component={RedirectCallback} />
      <Route exact path={Routes.logout} component={Logout} />
      <Route exact path={Routes.notFound} component={NotFound} />
      <Route render={() => mainApp} />
    </Switch>
  );
};

export default App;
