import React from 'react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { switchButtonContainer, switchToggle, switchContainer } from './styles';

const SwitchButton = props => {
  const { leftLabel, rightLabel, value, onChange, size = 24, color } = props;
  const theme = useTheme();
  const switchButtonStyles = switchButtonContainer(leftLabel || rightLabel, false, props)(theme);
  const switchToggleStyles = switchToggle(
    { width: size, smallWidth: size - size / 4, padding: size / 8 },
    color,
    value,
  )(theme);

  const handleChange = () => isFunction(onChange) && onChange(value);

  return (
    <div className={switchContainer}>
      <div className="switch-content-container">
        <span className="label-left">{leftLabel}</span>
        <div role="checkbox" tabIndex={0} className={switchButtonStyles} onClick={handleChange} aria-checked={value}>
          <span className={switchToggleStyles} />
        </div>
        <span className="label-right">{rightLabel}</span>
      </div>
    </div>
  );
};

SwitchButton.propTypes = {
  leftLabel: PropTypes.any,
  rightLabel: PropTypes.any,
  className: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  size: PropTypes.number,
  color: PropTypes.string,
  isTouched: PropTypes.bool,
  required: PropTypes.bool,
};

export default SwitchButton;
