import queryString from 'query-string';
import { isNumber, pick } from '@veraio/core';
import { calculateDealPrice } from '@oneecosystem/dealshaker-core';

export const getWishListParams = options => {
  const { searchText, dealsSorting, availability } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(dealsSorting) && { dealsSorting }),
      ...(isNumber(availability) && { availability }),
      ...(searchText && searchText?.length && { searchText }),
    },
    { arrayFormat: 'index' },
  );
};

export const getWishListDealsBody = res => ({ ...res, items: res.items.map(el => calculateDealPrice(el)) });
