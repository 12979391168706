export const getCountryPhoneCodeOptions = countries => {
  const options = [];
  countries?.forEach(el => {
    const foundOption = options?.find(op => Number(op.value) === Number(el.phoneCode));
    if (foundOption) {
      const foundOptionIndex = options?.indexOf(foundOption);
      options[foundOptionIndex].label = `${options[foundOptionIndex].label.slice(0, -1)}, ${el.name})`;
    } else {
      options.push({
        label: `+ ${el.phoneCode} (${el.name})`,
        value: el.phoneCode
      });
    }
  });

  return options.sort((a, b) => Number(a.value.split('/')[0]) - Number(b.value.split('/')[0]));

};