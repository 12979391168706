import React, { useState } from 'react';
import { useTheme } from 'emotion-theming';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { isNil } from '@veraio/core';
import { findFiatCurrency, DEFAULT_FIAT_CURRENCY } from '@oneecosystem/dealshaker-core';
import { InputNumber } from 'antd';
import { Button, Dropdown } from 'components/UIExternal';
import { useUser } from 'stores';
import { promoCodeType, promoCodeTypeSelect } from 'enums';
import { createPromoCode } from 'services/api/promoCodes';
import useError from 'services/errorHandling/useError';
import { container, header, valueBlock, discount, titleLabel, validateError } from './styles';

export const CreatePromoCode = () => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const history = useHistory();
  const { setError } = useError();
  const { currentAccountBusinesses } = useUser();
  const [createData, setCreateData] = useState({ typeId: promoCodeType.fiat, value: null, businessId: null });

  const selectedBusiness = currentAccountBusinesses?.find(el => el.id === createData?.businessId)?.currencyCode
    ?.currentValue;
  const businessCurrency = findFiatCurrency(selectedBusiness) ?? DEFAULT_FIAT_CURRENCY;
  const registeredBusinessesOptions = currentAccountBusinesses.map(business => ({
    label: getDynamicTranslation(business.name)?.name?.currentValue,
    value: business?.id,
  }));

  const handleCreatePromoCode = async () => {
    const data =
      createData?.typeId === promoCodeType.percent
        ? { ...createData, value: Number(createData?.value).toFixed(0) }
        : createData;

    const [, err] = await createPromoCode(data);
    if (err) return setError(err);

    toast.success(getText('successCreatePromoCode'));
    history.goBack();
  };

  const handleDiscountValidation = value => isNil(value) || value === '' || value > 100 || value < 1;

  const handleActionButtonValidation = data => {
    const { businessId, typeId, value } = data || {};
    const isPercent = typeId === promoCodeType.percent;
    const isFiat = typeId === promoCodeType.fiat;

    return !businessId || !typeId || (isPercent && (value < 1 || value > 100)) || (isFiat && value <= 0);
  };

  return (
    <div className={container}>
      <div className={header(theme)}>{getText('generatePromoCode')}</div>
      <div className={valueBlock(theme)}>
        <label className={titleLabel}>{getText('business')}</label>
        <Dropdown
          placeholder={getText('selectHere')}
          options={registeredBusinessesOptions}
          mapValue={data => data?.value}
          onChange={value => setCreateData(prev => ({ ...prev, businessId: value }))}
        />
      </div>
      <div className={valueBlock(theme)}>
        <label className={titleLabel}>{getText('value')}</label>
        <Dropdown
          noClear
          options={promoCodeTypeSelect(getText)}
          value={createData?.typeId}
          onChange={({ value }) => setCreateData(prev => ({ ...prev, value: '', typeId: value }))}
        />
      </div>
      <div className={discount}>
        <label className={titleLabel}>{getText('discount')}</label>
        <InputNumber
          type="number"
          controls={false}
          prefix={createData?.typeId === promoCodeType.percent ? '%' : businessCurrency.symbol}
          value={createData?.value}
          precision={createData?.typeId === promoCodeType.percent ? 0 : 2}
          onChange={value => setCreateData(prev => ({ ...prev, value: value ? Number(value)?.toFixed(2) : '' }))}
        />
        {createData?.typeId === promoCodeType.percent && handleDiscountValidation(createData?.value) && (
          <span className={validateError(theme)}>
            {getText('percentShouldBeBetweenStartEnd', { start: 1, end: 100 })}
          </span>
        )}
      </div>
      <Button disabled={handleActionButtonValidation(createData)} type="primary" onClick={handleCreatePromoCode}>
        {getText('createPromoCode')}
      </Button>
      <Button type="link" onClick={() => history.goBack()}>
        {getText('back')}
      </Button>
    </div>
  );
};

export default CreatePromoCode;
