import { css } from 'emotion';

export const containerClass = (className, theme) =>
  css(
    {
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row wrap',
      margin: '1em 0',

      '& .breadcrumb-item': {
        // If there is icon inside it will align them at end by default
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row nowrap',

        '& .breadcrumb-text': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '12rem',
        },
        '&:not(.breadcrumb-item-last)': {
          color: theme.blue2,
        },
      },
      '& .breadcrumb-delimiter': {
        margin: '0 0.5em',
      },
    },
    className,
  );
