import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { getToken } from '@oneecosystem/authenticate';
import { CloudUploadOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { Row, Col, Input, Form, Rate, Upload, message, Spin } from 'antd';
import CommentReview from 'components/review/CommentReview';
import { NestedCol } from 'components/ui';
import { Button } from 'components/UIExternal';
import MediaTypesEnum from 'enums/MediaTypesEnum';
import { Routes } from 'enums';
import apiRoutes from 'config/apiRoutes';
import { addNewReview, addNewDispute, getSingleReview, editReview } from 'services';
import { removeMedia } from 'services/api/mediaService';
import useError from 'services/errorHandling/useError';
import { mb } from 'assets/css/globalCSS';
import {
  disputeTitleWrapper,
  titleForm,
  reviewFormWrapper,
  disputedReviewWrapper,
  starRatings,
  starsWrapper,
  wrapperInput,
  uploadItem,
  imageUploadDragDrop,
  imagesPreview,
  formButtons,
  blackTextLink,
  disputedSubmittedWrapper,
} from './styles';

const addToPayload = data => ({
  title: data.values.title,
  description: data.values.description,
  media: data.images.map((image, i) => ({ id: image.id, order: i })),
  ...(data.values?.rating && { stars: data.values.rating }),
});

const LeaveReviewForm = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const history = useHistory();
  const { setError } = useError();
  const location = useLocation();
  const isInDisputeForm = location.pathname.split('/').includes('dispute-review');
  const isInEditMode = location.pathname.split('/').includes('edit-review');
  const { dealId, reviewId } = useParams();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [ratingSelected, setRatingSelected] = useState();
  const [images, setImages] = useState([]);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [disputedReview, setDisputedReview] = useState();
  const [currentReview, setCurrentReview] = useState();
  const [disputeSubmitted, setDisputeSubmitted] = useState(false);
  const [disableAddBtn, setDisableAddBtn] = useState(false);

  useEffect(() => {
    (isInDisputeForm || isInEditMode) && fetchSingleReview();
    !isInEditMode && setCurrentReview({});
    window.scrollTo(0, 0);
  }, []);

  const fetchSingleReview = async () => {
    const [res, err] = await getSingleReview(reviewId);
    err && setError(err);
    if (isInDisputeForm) setDisputedReview(res);
    else {
      setCurrentReview(res);
      setImages(res.media);
      setRatingSelected(res.stars);
    }
  };

  const submitForm = values => {
    if (isInDisputeForm) handleAddDispute({ reviewId: Number(reviewId), ...addToPayload({ values, images }) });
    else if (isInEditMode) handleEditReview({ id: Number(reviewId), ...addToPayload({ values, images }) });
    else handleAddReview({ dealId: Number(dealId), ...addToPayload({ values, images }) });
  };

  const handleAddReview = async data => {
    const [res, err] = await addNewReview(data);
    err ? setError(err) : setDisableAddBtn(false);
    history.push(`/deal/${res.dealName}/reviews`);
  };

  const handleEditReview = async data => {
    const [res, err] = await editReview(data);
    err ? setError(err) : setDisableAddBtn(false);
    history.push(`/deal/${res.dealName}/reviews`);
  };

  const handleAddDispute = async payloadDispute => {
    const [, err] = await addNewDispute(payloadDispute);
    err ? setError(err) : setDisputeSubmitted(true);
    setDisableAddBtn(false);
  };

  const getMapData = () =>
    images.map(image => ({
      uid: image.id,
      url: image.url,
      thumbUrl: image.thumbnailUrl,
      name: image.id,
      status: 'done',
    }));

  const imgUploadProps = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    accept: MediaTypesEnum.Image.allowedTypes.join(', '),
    action: apiRoutes.MEDIA_ADD_IMAGE,
    headers: { Authorization: `Bearer ${getToken().access_token}` },
    beforeUpload: file => {
      if (file.size > MediaTypesEnum.Image.allowedSize) {
        message.error(`${file.name} ${getText('uploadFailedFileSizeBigger')}`);
        return Upload.LIST_IGNORE;
      }
      if (!MediaTypesEnum.Image.allowedTypes.includes(file.type)) {
        message.error(`${file.name} ${getText('uploadFailedWrongFormat')}`);
        return Upload.LIST_IGNORE;
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') setImages([...images, info.file.response]);
      if (status === 'done') message.success(`${info.file.name} file uploaded successfully.`);
      else if (status === 'error') message.error(`${info.file.name} file upload failed.`);
    },
  };

  const onRemoveImage = async id => {
    setDeleteInProgress(true);
    const [, err] = await removeMedia(id);
    err && setError(err);
    setImages(images.filter(img => img.id !== id));
    setDeleteInProgress(false);
  };

  return isInDisputeForm && disputeSubmitted ? (
    <Row>
      <Col lg={{ offset: 6, span: 12 }} span={24} className={disputedSubmittedWrapper(theme)}>
        <span className="icon-Tick" />
        <label>{getText('disputeSendForApproval')}</label>
        <p>{getText('moderatorReviewNotified')}</p>
        <Button type="primary" linkTo={`${Routes.merchantOffice}${Routes.myDeals}`}>
          {getText('goToProfile')}
        </Button>
      </Col>
    </Row>
  ) : (
    <div className="content-container">
      {isInDisputeForm ? (
        <div className={disputeTitleWrapper(theme)}>
          <h2>{getText('disputeReview')}</h2>
          <p>{getText('explainReviewRecheckedCountryManager')}</p>
        </div>
      ) : (
        <h2 className={titleForm(theme)}>{isInEditMode ? getText('editReview') : getText('leaveReview')}</h2>
      )}
      {currentReview ? (
        <>
          <Row>
            <Col lg={16} span={24} className={reviewFormWrapper(theme)}>
              <Form
                form={form}
                onFinish={submitForm}
                layout="vertical"
                onValuesChange={changedValues => {
                  Object.keys(changedValues)[0] === 'rating' && setRatingSelected(changedValues.rating);
                }}
                initialValues={{
                  rating: currentReview?.stars,
                  title: currentReview?.title,
                  description: currentReview?.description,
                }}
              >
                {!isInDisputeForm && (
                  <div className={`flex ${starsWrapper(theme)}`}>
                    <Form.Item
                      label={getText('overallRating')}
                      name="rating"
                      rules={[
                        { required: true },
                        () => ({
                          validator(_, value) {
                            if (value > 0) return Promise.resolve();
                            return Promise.reject(getText('leaveRateError'));
                          },
                        }),
                      ]}
                    >
                      <Rate className={starRatings(theme)} />
                    </Form.Item>
                    {!!ratingSelected && <label>{getText('starsCount', { count: ratingSelected })}</label>}
                  </div>
                )}
                <Form.Item
                  label={getText('title')}
                  name="title"
                  className={wrapperInput}
                  rules={[{ required: true, message: getText('pleaseEnterTitle') }]}
                >
                  <Input type="text" placeholder={getText('enterTitle')} />
                </Form.Item>
                <Form.Item
                  label={getText('description')}
                  name="description"
                  className={wrapperInput}
                  rules={[{ required: true, message: getText('pleaseEnterDescription') }]}
                >
                  <TextArea type="text" placeholder={getText('enterDescription')} />
                </Form.Item>
                <Form.Item label={getText('uploadImages')} className={uploadItem}>
                  <Row gutter={[30, 30]}>
                    <NestedCol span={24} lg={8} totalWidth={16}>
                      <Upload.Dragger className={imageUploadDragDrop} {...imgUploadProps}>
                        <p className="ant-upload-drag-icon">
                          <CloudUploadOutlined />
                        </p>
                        <p className="ant-upload-text">
                          <p>{getText('dragAndDropBrowseFiles')}</p>
                        </p>
                      </Upload.Dragger>
                    </NestedCol>
                    <NestedCol span={24} lg={6} totalWidth={16}>
                      {deleteInProgress ? (
                        <div className="content-container">
                          <Spin size="large" />
                        </div>
                      ) : (
                        <ImgCrop>
                          <Upload
                            className={imagesPreview}
                            onPreview={null}
                            listType="picture-card"
                            fileList={getMapData()}
                            onRemove={e => onRemoveImage(e.uid)}
                          />
                        </ImgCrop>
                      )}
                    </NestedCol>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
            {isInDisputeForm && (
              <Col lg={16} span={24} className={disputedReviewWrapper(theme)}>
                {disputedReview && <CommentReview reviews={disputedReview} />}
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={16} span={24} className={formButtons}>
              <Button type="link" small className={blackTextLink(theme)} onClick={() => history.goBack()}>
                {getText('goBack')}
              </Button>
              <Button
                type="primary"
                small
                disabled={disputedReview?.inDispute || disableAddBtn}
                onClick={() => form.submit()}
              >
                {isInDisputeForm && getText('submitDispute')}
                {isInEditMode && getText('editReview')}
                {!isInDisputeForm && !isInEditMode && getText('leaveReview')}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default LeaveReviewForm;
