import React, { useState } from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import { isTokenValid } from '@oneecosystem/authenticate';
import { mb } from 'assets/css/globalCSS';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { isMobile } from 'react-device-detect';
import { NestedCol } from 'components/ui';
import { Button } from 'components/UIExternal';
import { DealPrice } from 'components/shared';
import { deliveryMethodSelect } from 'enums';
import useError from 'services/errorHandling/useError';
import { addDealToWishlist, deleteDealFromWishlist } from 'services/api/wishlist';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import DiscountStamp from '../DiscountStamp';
import {
  featuredDealTemplate,
  thumbnailContainer,
  thumbnail,
  dealDiscountStamp,
  deliveryMethodLabel,
  TitleLabel,
  StarRatings,
  ratingsCountLabel,
  ratingsCountLink,
  DeliveryIcon,
  row,
  addWishlistBtn,
} from './styles';

const FeaturedDeal = ({ deal }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const { setError } = useError();
  const { id, name, media, discount, isInWishList, rating, reviewsCount } = deal;
  const isLoggedIn = isTokenValid();
  const [addedToWishlist, setAddedToWishlist] = useState(isInWishList);
  const [wishlistBtnDisabled, setWishlistBtnDisabled] = useState(!isLoggedIn);

  const handleDealWishList = async wishListRequest => {
    const [, err] = await wishListRequest(id);
    err ? setError(err) : setAddedToWishlist(prev => !prev);
    setWishlistBtnDisabled(false);
  };

  const manageWishlist = () => {
    setWishlistBtnDisabled(true);

    addedToWishlist ? handleDealWishList(deleteDealFromWishlist) : handleDealWishList(addDealToWishlist);
  };

  const thumbnailUrl =
    media?.typeId === 3 ? `https://img.youtube.com/vi/${media?.url}/0.jpg` : media?.url || placeholderThumbnail;

  return (
    <div className={featuredDealTemplate(theme)}>
      <Row className={row} gutter={[50, 0]}>
        <NestedCol totalWidth={19} lg={11} span={24}>
          <div className={thumbnailContainer}>
            <img className={thumbnail} alt="Featured Deal" src={thumbnailUrl} />
            {discount > 0 && <DiscountStamp deal={deal} className={dealDiscountStamp} />}
          </div>
        </NestedCol>
        <NestedCol totalWidth={19} lg={8} span={24}>
          <TitleLabel>{getDynamicTranslation(deal?.dealDetails)?.title}</TitleLabel>
          <div className={mb({ lg: 20, span: 20 })}>
            <StarRatings theme={theme} value={rating} disabled />
            {reviewsCount > 0 ? (
              <Button type="link" small className={ratingsCountLink(theme)} linkTo={`/deal/${name}/reviews`}>
                {reviewsCount}
              </Button>
            ) : (
              <label className={ratingsCountLabel(theme)}>{reviewsCount}</label>
            )}
            <DeliveryIcon theme={theme} className={deliveryMethodSelect[deal.deliveryMethodId]?.icon} />
            <label className={deliveryMethodLabel}>{getText(deliveryMethodSelect[deal.deliveryMethodId]?.label)}</label>
          </div>
          <DealPrice deal={deal} showSavings dataId="featured-deal-price-panel" />
          <div className={`flex ${isMobile && 'space-between'}`}>
            <Button type="primary" small linkTo={`/deal/${name}`}>
              {getText('viewDetails')}
            </Button>
            <Button
              type="link"
              small
              disabled={wishlistBtnDisabled}
              className={addWishlistBtn(theme)}
              onClick={manageWishlist}
            >
              {!addedToWishlist ? (
                <>
                  <FeatherIcon icon="heart" size={17} stroke={theme.gray300} />
                  {getText('addToWishlist')}
                </>
              ) : (
                <>
                  <FeatherIcon icon="heart" size={17} stroke={theme.white} fill={theme.red} />
                  {getText('addedToWishlist')}
                </>
              )}
            </Button>
          </div>
        </NestedCol>
      </Row>
    </div>
  );
};

FeaturedDeal.propTypes = {
  deal: PropTypes.object,
};

export default FeaturedDeal;
