import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  contentBox: {
    position: 'absolute',
    width: '80%',
    bottom: '16%',
    left: '10%',
  },
  textBox: {
    country: {
      fontSize: 44,
      fontWeight: 600,
      color: '#FECF09',
    },
    businessName: {
      fontSize: 40,
      fontWeight: 800,
      color: '#fff',
    },
    location: {
      fontSize: 30,
      color: '#fff',
    },
  },
  qrCodeStyles: {
    width: 150,
    position: 'absolute',
    bottom: '-60%',
    right: '5%',
  },
});
