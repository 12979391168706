import React from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isMobile } from 'react-device-detect';
import { Col, Row } from 'antd';
import { Button } from 'components/UIExternal';
import { useUser } from 'stores';
import config from 'config/envConfig';
import EditProfileIconMobile from 'assets/images/editProfileIconMobile.svg';
import EditProfileIcon from 'assets/images/editProfileIcon.svg';
import { mb, mr } from 'assets/css/globalCSS';
import {
  myProfileStyles,
  spanTextWrapperStyles,
  profileInfoText,
  editProfileBox,
  editProfileText,
  icon,
  goToOneLifeBtn,
} from './styles';

const ProfileInformationTab = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { userInfo } = useUser();

  return (
    <div className={myProfileStyles}>
      <Row>
        <Col lg={6} span={24}>
          <div className={mb({ lg: 74, span: 32 })}>
            <div className={`flex ${mb({ lg: 16, span: 16 })} ${spanTextWrapperStyles}`}>
              <span className={`${icon(theme)} ${mr({ lg: 16, span: 8 })}`}>
                <span className="icon-Email" />
              </span>
              <p className={profileInfoText(theme)}>
                <label>{getText('name')}:</label>
                <br />
                {`${userInfo?.firstName ? userInfo.firstName : '-'} ${userInfo?.lastName ? userInfo.lastName : ''}`}
              </p>
            </div>
            <div className={`flex ${mb({ lg: 16, span: 16 })} ${spanTextWrapperStyles}`}>
              <span className={`${icon(theme)} ${mr({ lg: 16, span: 8 })}`}>
                <span className="icon-Email" />
              </span>
              <p className={profileInfoText(theme)}>
                <label>{getText('emailAddress')}:</label>
                <br />
                {userInfo?.email ? userInfo.email : '-'}
              </p>
            </div>
            <div className={`flex ${mb({ lg: 16, span: 16 })} ${spanTextWrapperStyles}`}>
              <span className={`${icon(theme)} ${mr({ lg: 16, span: 8 })}`}>
                <span className="icon-Phone" />
              </span>
              <p className={profileInfoText(theme)}>
                <label>{getText('phoneNumber')}:</label>
                <br />
                {userInfo?.phone ? userInfo.phone : '-'}
              </p>
            </div>
            <div className={`flex ${mb({ lg: 16, span: 16 })} ${spanTextWrapperStyles}`}>
              <span className={`${icon(theme)} ${mr({ lg: 16, span: 8 })}`}>
                <span className="icon-Profile" />
              </span>
              <p className={profileInfoText(theme)}>
                <label>{getText('userName')}:</label>
                <br />
                {userInfo?.username ? userInfo.username : '-'}
              </p>
            </div>
            <div className={`flex ${mb({ lg: 16, span: 16 })} ${spanTextWrapperStyles}`}>
              <span className={`${icon(theme)} ${mr({ lg: 16, span: 8 })}`}>
                <span className="icon-Location" />
              </span>
              <p className={profileInfoText(theme)}>
                <label>{getText('postalCode')}:</label>
                <br />
                {userInfo?.postcode ? userInfo.postcode : '-'}
              </p>
            </div>
          </div>
        </Col>
        <Col lg={14} span={24}>
          <div className={`${isMobile ? 'flex-column' : 'flex'} ${mb({ lg: 0, span: 40 })} ${editProfileBox(theme)}`}>
            <img src={isMobile ? EditProfileIconMobile : EditProfileIcon} alt="Edit Profile Icon" />
            <div className={`flex-column ${editProfileText}`}>
              <label>{getText('editProfileInformation')}</label>
              <p>{getText('editYourProfileInformationOrGoOneLifeAccount')}</p>
              <Button
                type="secondary"
                small
                linkTo={`${config.oneLifePortalUrl}/profile/overview`}
                className={goToOneLifeBtn}
              >
                {getText('goOneLifeAccount')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileInformationTab;
