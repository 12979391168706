import { isArray } from '@veraio/core';

let timeoutId = 0;

export const delay = (callback, delayOnChange = 500) => {
  clearTimeout(timeoutId);
  timeoutId = setTimeout(callback, delayOnChange);
};

export const objectToQuery = (obj, encode = true) => {
  const str = composeQueryString(getPathToObj(obj));
  return encode ? encodeURI(str) : str;
};

const getPathToObj = (obj, path = []) => {
  const result = [];

  const keys = Object.keys(obj);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const value = obj[key];

    if ((!isArray(value) && value !== null) || (isArray(value) && value.length > 0)) {
      // deep copy
      const newPath = path.slice();
      newPath.push(key);

      let everyPath = [];
      if (typeof value === 'object') everyPath = getPathToObj(value, newPath);
      else {
        everyPath.push({
          path: newPath,
          val: value,
        });
      }

      for (let index = 0; index < everyPath.length; index++) {
        const path = everyPath[index];
        result.push(path);
      }
    }
  }

  return result;
};

const composeQueryString = paths => {
  let result = '';

  for (let index = 0; index < paths.length; index++) {
    const item = paths[index];

    let pathString = '';
    if (item.path.length > 1) pathString = item.path.reduce((a, b) => `${a}[${b}]`);
    else [pathString] = item.path;

    if (result) pathString = `&${pathString}=${item.val}`;
    else pathString = `?${pathString}=${item.val}`;

    result += pathString;
  }

  return result;
};

export const groupParamsByKey = params =>
  [...params.entries()].reduce((acc, tuple) => {
    const [key, val] = tuple;
    // eslint-disable-next-line no-prototype-builtins
    if (acc.hasOwnProperty(key)) {
      if (isArray(acc[key])) acc[key] = [...acc[key], parseInt(val, 10)];
      else acc[key] = [acc[key], parseInt(val, 10)];
    } else acc[key] = [parseInt(val, 10)];

    return acc;
  }, {});
