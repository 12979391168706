import { createStore, useStore } from '@veraio/core';
import { getText } from '@veraio/strank';
import { setError, getMerchantList, getCitiesByCountry } from 'services';

const initState = {
  merchantList: null,
  citiesList: null,
  locationFilter: { country: null, city: null },
  selectedFilter: null,
  breadcrumbs: null,
  categories: null,
};

const merchantListStore = createStore(initState);

export const initMerchantListStore = state => merchantListStore.setState(prev => ({ ...prev, ...(state ?? {}) }));

export const resetMerchantListStore = () => merchantListStore.setState(initState);

export const fetchMerchants = async options => {
  const [res, err] = await getMerchantList(options);
  if (err) return setError(err);
  merchantListStore.setState(prev => ({ ...prev, merchantList: res.items }));
  return [res, err];
};

export const fetchCitiesByCountry = async options => {
  const [res, err] = await getCitiesByCountry(options);
  if (err) return setError(err);
  merchantListStore.setState(prev => ({ ...prev, citiesList: res.items }));
  return res;
};

export const selectFilter = options =>
  merchantListStore.setState(prev => ({ ...prev, selectedFilter: { ...prev.selectedFilter, ...options } }));

export const setBreadcrumbs = breadcrumbs => merchantListStore.setState(prev => ({ ...prev, breadcrumbs }));

export const setCategories = categories => merchantListStore.setState(prev => ({ ...prev, categories }));

export const setLocation = locationFilter => {
  merchantListStore.setState(prev => ({ ...prev, locationFilter }));
};

export const findCategoryPath = (categories, targetId, path = []) =>
  categories.reduce((acc, category) => {
    const currentPath = [...path, category];
    if (category.id === targetId) return currentPath;

    return category.subCategories?.length
      ? acc || findCategoryPath(category.subCategories, targetId, currentPath)
      : acc;
  }, null);

export const handleSelectCategory = (allCategories, category) => {
  const hasSubCategories = !!category.subCategories?.length;
  const isAll = category.id === 0;

  const { breadcrumbs } = merchantListStore.getState();

  // Create a new breadcrumb based on whether the category has subcategories
  const newBreadcrumbs = (hasSubCategories && breadcrumbs?.length) || isAll ? [...breadcrumbs, category] : [category];

  // Find the path to the selected category
  const pathToCategory = findCategoryPath(allCategories, category.id);

  const finalBreadcrumbs = pathToCategory || newBreadcrumbs;

  const parentLevel = pathToCategory?.length - (pathToCategory?.length >= 3 ? 2 : 1);

  // Determine the new categories
  const newCategories =
    pathToCategory?.length > 1
      ? pathToCategory[pathToCategory.length - parentLevel].subCategories
      : category.subCategories || [];

  // Update the state with the final breadcrumbs and new categories
  setBreadcrumbs(finalBreadcrumbs);
  setCategories([{ id: 0, name: getText('all') }, ...newCategories]);
  selectFilter({ categoriesPath: finalBreadcrumbs });
};

const formatStringSimple = str =>
  str.replace(/(^|-)(\w)/g, (match, p1, p2) => (p1 ? ` ${p2.toUpperCase()}` : p2.toUpperCase()));

const flat = categoriesArray =>
  categoriesArray?.flatMap(category => {
    const { subCategories, ...flatCategory } = category;
    const flattenedSubCategories = subCategories ? flat(subCategories) : [];

    const formatFlatCategory = {
      ...flatCategory,
      name: formatStringSimple(flatCategory?.name),
    };

    return [formatFlatCategory, ...flattenedSubCategories];
  });

export const flattenCategories = allCategories => flat(allCategories);

export const useMerchantList = callback => useStore(merchantListStore, callback);
