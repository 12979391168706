import { getReq, postReq, deleteReq, putReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';
import {
  updateShoppingCartDeliveryBody,
  updateShoppingCartDealQuantityBody,
  addToShoppingCartBody,
} from '../models/shoppingCart';

const baseUrl = `${envConfig.apiAddress}/api/ShoppingCart`;

export const getShoppingCart = () => getReq(`${baseUrl}/Get`);

export const addToShoppingCart = data => postReq(`${baseUrl}/Add`, addToShoppingCartBody(data));

export const addPromoCodeToShoppingCart = data => postReq(`${baseUrl}/PromoCode`, data);

export const updateShoppingCartDealQuantity = data =>
  putReq(`${baseUrl}/Quantity`, updateShoppingCartDealQuantityBody(data));

export const updateShoppingCartDelivery = items => putReq(`${baseUrl}/Delivery`, updateShoppingCartDeliveryBody(items));

export const updateShoppingCartPaymentMethods = data => putReq(`${baseUrl}/PaymentMethod`, data);

export const removeDealFromShoppingCart = dealId => deleteReq(`${baseUrl}/Delete/${dealId}`);

export const removeAllDealsFromShoppingCart = () => deleteReq(`${baseUrl}/Delete`);

export const removePromoCodeFromShoppingCart = data => deleteReq(`${baseUrl}/PromoCode`, data);
