import { css } from 'emotion';
import { Phone } from 'assets/css/breakpoints';

export const orderDetailsContainer = theme =>
  css({
    maxHeight: '63vh',
    overflowY: 'auto',
    padding: '0 1.5em',
    border: `1px solid ${theme.gray500}`,
    borderRadius: 5,
    flex: '4 0 0',
    marginBottom: '1em',
    [Phone]: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      maxHeight: 'none',
      zIndex: 100000,
      backgroundColor: theme.white,
      marginBottom: 0,
    },
    '& .gray': {
      color: theme.dark_gray,
    },
    '& .order-details-section': {
      borderRadius: 10,
      border: `1px solid ${theme.gray400}`,
      padding: '2em',
      '&:last-of-type': {
        marginBottom: '2em',
      },
    },
    '& .order-details-section-title': {
      margin: '2em 0 0.8em',
    },
    '&[aria-hidden="true"]': {
      display: 'none',
    },
  });
