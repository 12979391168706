import { css } from 'emotion';

export const popoverContainer = theme =>
  css({
    '& .ant-popover-inner-content': {
      padding: '0.5em',
      background: theme.gray100,
      borderRadius: 3,
      maxWidth: 700,
      color: theme.white,
    },
    '& .ant-popover-arrow-content': {
      background: theme.gray100,
    },
    '& p': {
      marginBottom: 0,
      fontSize: '0.75rem',
    },
  });
