/* eslintdisable nouselessescape */
export default {
  Add: '"\\e900"',
  ArrowLeft: '"\\e901"',
  Attachment: '"\\e902"',
  Back: '"\\e903"',
  Bell: '"\\e904"',
  Bullet: '"\\e905"',
  Calendar: '"\\e906"',
  CartActive: '"\\e907"',
  Cart: '"\\e908"',
  Close: '"\\e909"',
  Copy: '"\\e90a"',
  Coupon2: '"\\e90b"',
  Coupons: '"\\e90c"',
  Cover: '"\\e90d"',
  CurrencyEuro: '"\\e90e"',
  CurrencyOne: '"\\e90f"',
  Dashboard: '"\\e910"',
  Dealsbag: '"\\e911"',
  Delete: '"\\e912"',
  Delivery1: '"\\e913"',
  DeliveryTypeOnlineService: '"\\e914"',
  DeliveryTypeRedeematlocation: '"\\e915"',
  DeliveryTypeSelforganized: '"\\e916"',
  DoubleChevron: '"\\e917"',
  Download: '"\\e918"',
  Email: '"\\e919"',
  Endson: '"\\e91a"',
  Favourite: '"\\e91b"',
  Filter: '"\\e91c"',
  Groupmessage: '"\\e91d"',
  Hamburger: '"\\e91e"',
  Inbox: '"\\e91f"',
  Info2: '"\\e920"',
  Info: '"\\e921"',
  Location: '"\\e922"',
  Locationsmall: '"\\e923"',
  Messages: '"\\e924"',
  Moderator: '"\\e925"',
  More: '"\\e926"',
  Notifcations: '"\\e927"',
  Percent: '"\\e928"',
  Phone: '"\\e929"',
  Play: '"\\e92a"',
  Price: '"\\e92b"',
  Profile: '"\\e92c"',
  QuestionMark: '"\\e92d"',
  QuestionMarkdotted: '"\\e92e"',
  Search: '"\\e92f"',
  SingleChevron: '"\\e930"',
  Star: '"\\e931"',
  Switch: '"\\e932"',
  Tick: '"\\e933"',
  View: '"\\e934"',
  Wallet: '"\\e935"',
  facebook: '"\\ea90"',
};
