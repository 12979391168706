import { css } from 'emotion';
import { commonStyles } from 'components';

export const searchBarContainer = props =>
  css([
    {
      position: 'relative',
      zIndex: 50,
      width: '100%',
      borderRadius: 24,
    },
    props.className,
    ...commonStyles(props),
  ]);

export const searchBarInputContainer = (props, theme) =>
  css([
    {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 25,
      width: '100%',
      border: `1px solid ${theme.gray100}`,
    },
    props.className,
    ...commonStyles(props),
  ]);

export const searchBarInput = css({
  fontSize: '1em',
  lineHeight: 1.2,
  paddingRight: '1rem',
  outline: 'none',
  border: 'none',
  background: 'transparent',
  borderRadius: 6,
  width: '100%',

  '&:focus, &:active': {
    border: 'none',
    boxShadow: 'none',
  },
});

export const searchBarInputIcon = theme =>
  css({
    cursor: 'pointer',
    padding: '0.4em',
    color: theme.gray300,
  });

export const searchBarSuggestionsContainer = theme =>
  css({
    position: 'absolute',
    top: 'calc(100% + 8px)',
    left: 0,
    right: 0,
    color: theme.gray200,
    maxHeight: 300,
    zIndex: 20,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    backgroundColor: theme.white,
    boxShadow: `0px 3px 5px 0px ${theme.gray100}30`,
  });

export const searchBarSuggestionItem = theme =>
  css({
    padding: '0.8em 1.5em',
    color: theme.gray200,

    '&:focus, &:hover': {
      outline: 'none',
      backgroundColor: theme.gray600,
      color: theme.gray100,
    },
  });
