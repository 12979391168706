import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { Row, Col, Rate } from 'antd';
import { Thumbnail, ThumbnailContainer } from 'components/deal/Deal/styles';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import {
  dailySalesDealCard,
  dailySalesDealTitle,
  starRatings,
  ratingsCountLabel,
  soldCountLabelWrapper,
  soldCountDesktopLabel,
  soldCountMobileLabel,
} from '../styles';

const DailySalesDeal = ({ deal }) => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { name, title, rating, soldItems, media } = deal;

  return (
    <Row className={dailySalesDealCard} gutter={[28, 0]}>
      <Col lg={4} span={24}>
        <Link to={`/deal/${name}`}>
          <ThumbnailContainer>
            <Thumbnail src={media?.url || placeholderThumbnail} alt={name} />
          </ThumbnailContainer>
        </Link>
      </Col>
      <Col lg={18} span={24}>
        <span className={dailySalesDealTitle}>{getDynamicTranslation(title)?.title}</span>
        <Rate className={starRatings(theme)} value={rating} disabled />
        <label className={ratingsCountLabel}>{getText('averageCount', { count: rating.toFixed(1) })}</label>
        <label className={`${soldCountMobileLabel} mobile-only`}>
          {getText('soldItemsCount', { count: soldItems })}
        </label>
      </Col>
      <Col lg={2} className={soldCountLabelWrapper}>
        <label className={`${soldCountDesktopLabel} desktop-only`}>{soldItems}</label>
      </Col>
    </Row>
  );
};

DailySalesDeal.propTypes = {
  deal: PropTypes.object,
};

export default DailySalesDeal;
