import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Avatar, Popover } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { isTokenValid } from '@oneecosystem/authenticate';
import { Button } from 'components/UIExternal';
import { Routes } from 'enums';
import { getCacheVal, removeCacheVal, setCacheVal } from 'utils/cacheUtils';
import { getUnreadChat } from 'services/api/chatService';
import { getTimeFromNow } from 'utils';
import { mb, mt } from 'assets/css/globalCSS';
import noChatsImg from 'assets/images/chat/start-conversation.png';
import noChatServiceImg from 'assets/images/chat/chat-out-of-service.png';
import { badgeContainer, noNotificationPanelContainer, badgeNumber, popOverClass, chatBlock } from './styles';

const ChatMiniPanel = () => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  useEffect(() => {
    errorCount >= 3 && setCacheVal('chat-out-of-service', true);
  }, [errorCount]);

  useEffect(() => {
    callChatMessages();
    const chatMessageInterval = setInterval(() => callChatMessages, 3000);
    return () => clearInterval(chatMessageInterval);
  }, []);

  const toggleVisible = () => setVisible(!visible);

  const isChatOutOfService = () => getCacheVal('chat-out-of-service');

  const contentButton = () => (
    <Button type="default" fullWidth linkTo={`${Routes.myProfile}${Routes.messages}`} onClick={() => setVisible(false)}>
      {getText('goToMessages')}
    </Button>
  );

  const callChatMessages = async () => {
    if (isTokenValid() && !isChatOutOfService()) {
      await getUnreadChat(1, 6).then(res => {
        if (res[0]) {
          setMessages(res[0].items);
          setTotalCount(res[0].totalCount > 5 ? '5+' : res[0].totalCount);
        }
        if (res[1]?.status === 500) setErrorCount(prev => prev + 1);
      });
    }
  };

  const contentNoMessages = (
    <div className={noNotificationPanelContainer(theme)}>
      <div className="flex-column flex-center fullWidth">
        <img className={`${mt({ lg: 15, md: 15, xs: 15 })} ${mb({ lg: 15, span: 15 })}`} src={noChatsImg} alt="Logo" />
        <h4>{getText('youDontHaveAnyNewMessages')}</h4>
        {contentButton()}
      </div>
    </div>
  );

  const contentChatOutOfService = (
    <div className={noNotificationPanelContainer(theme)}>
      <div className="flex-column flex-center fullWidth">
        <img
          className={`${mt({ lg: 15, md: 15, xs: 15 })} ${mb({ lg: 15, span: 15 })}`}
          src={noChatServiceImg}
          alt="Logo"
        />
        <h4>{getText('messagingServiceCurrentlyUnavailable')}</h4>
        <Button
          type="link"
          fullWidth
          onClick={() => {
            setErrorCount(0);
            removeCacheVal('chat-out-of-service');
          }}
        >
          {getText('checkForNewMessages')}
        </Button>
      </div>
    </div>
  );

  const messageContent = (message, ind) => {
    const dealTitle = getDynamicTranslation(message.dealTitle)?.title;

    return (
      <Link to={`/chat?chatId=${message.chatId}`} className={chatBlock} key={ind}>
        <Avatar.Group style={{ marginRight: 5 }} maxCount={2}>
          {message?.participants?.map((participant, i) => (
            <Avatar key={i} src={participant.avatarThumbnailUrl}>
              {participant.firstName?.charAt(0)}
            </Avatar>
          ))}
        </Avatar.Group>
        <div style={{ justifyContent: 'center' }}>
          <div className="flex">
            <label className="titleTimeContainer">{message.name}</label>
            <span className="icon-Bullet" />
            <label className="time">{getTimeFromNow(message.lastMessageDate)}</label>
          </div>
          {!!message.dealTitle?.length && (
            <p style={{ textAlign: 'center' }}>
              {message.dealTitle.length > 70 ? dealTitle.substring(0, 70).concat('...') : dealTitle}
            </p>
          )}
        </div>
      </Link>
    );
  };

  const content = (
    <div className="flex flex-column">
      <h3> {getText('yourMessages')}</h3>
      {totalCount && messages.map((msg, ind) => messageContent(msg, ind))}
      {contentButton()}
    </div>
  );

  const popoverContent = () => {
    if (isChatOutOfService()) return contentChatOutOfService;
    if (totalCount) return content;
    return contentNoMessages;
  };

  return (
    <Popover
      open={visible}
      onOpenChange={toggleVisible}
      content={popoverContent()}
      placement="bottomRight"
      overlayClassName={popOverClass(theme)}
      trigger="click"
    >
      <div className={badgeContainer}>
        <FeatherIcon icon="message-square" size={26} stroke="#fff" strokeWidth={1} />
        <span className={badgeNumber(theme, totalCount)}>{totalCount}</span>
      </div>
    </Popover>
  );
};

export default ChatMiniPanel;
