import { css } from 'emotion';

export const borderedContainer = (theme, className) =>
  css(
    {
      border: `1px solid ${theme.gray500}`,
      padding: '1em',
      marginBottom: '1em',
      borderRadius: 8,

      '&: last-child': {
        marginBottom: 0,
      },

      '& .incompatible': {
        color: theme.red,
        fontSize: '0.75rem',
        marginLeft: '0.6em',
      },
    },
    className,
  );

export const marginBottom = val =>
  css({
    marginBottom: val,
  });
