import { getReq, postReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';
import { allSoldCouponsParams, couponsBody } from '../models/coupons';

const baseUrl = `${envConfig.apiAddress}/api/Coupons`;
const baseUrlAPI = `${envConfig.apiAddress}/api`;

export const getSoldCoupons = async params => {
  const [res, err] = await getReq(`${baseUrl}/Sold`, { ...allSoldCouponsParams(params) });
  return [res && couponsBody(res), err];
};

export const getBoughtCoupons = async params => {
  const [res, err] = await getReq(`${baseUrl}/Bought`, { ...params });
  return [res && couponsBody(res), err];
};

export const getExpiredCoupons = async params => {
  const [res, err] = await getReq(`${baseUrl}/Expired`, { ...params });
  return [res && couponsBody(res), err];
};

export const getCouponDetails = id => getReq(`${baseUrl}/Details/${id}`);

export const redeemCoupons = couponCodes => postReq(`${baseUrl}/RedeemCoupon`, { couponCodes });

export const confirmRedeemCoupons = couponCodes =>
  postReq(`${baseUrl}/ConfirmRedeemCoupon`, {
    couponCodes,
  });

export const redeemCouponsAnonymous = (bin, couponCodes) =>
  postReq(`${baseUrl}/RedeemCoupon/Anonymous`, {
    bin,
    couponCodes,
  });

export const confirmRedeemCouponAnonymous = async (bin, couponCodes) =>
  postReq(`${baseUrl}/ConfirmRedeemCoupon/Anonymous`, { bin, couponCodes });

export const viewCouponPdf = (id, lang) =>
  getReq(`${baseUrlAPI}/PdfGenerator/Coupon/${id}?languageCode=${lang}`, {}, { responseType: 'blob' });
