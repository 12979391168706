import { css } from 'emotion';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { Mobile } from 'assets/css/breakpoints';

export const localizationPanelStyles = isMobileView =>
  css({
    fontSize: '0.75rem',
    justifyContent: `${isMobileView ? 'space-between' : 'initial'}`,
    [Mobile]: {
      padding: '0.375rem 1rem',
    },
  });

export const CustomHeaderActionButton = styled(Button)`
  display: flex;
  align-items: center;
  & .filtered {
    color: #47bbfd;
  }
  &.ant-btn-text {
    color: inherit;
    height: inherit;
    font-size: inherit;
    padding: 0;
    &:hover,
    &:active,
    &:focus {
      color: inherit;
    }
  }
  & .label {
    color: #99a2bb;
  }
  & .tooltip {
    position: relative;
    margin-left: 0.5em;
  }
  & .tooltip:hover {
    background: rgba(255, 255, 255, 0.08);
    & .locationFilter-popup {
      display: block;
    }
  }
`;

export const LocationSettingsLabel = styled.label`
  margin-left: 0.3125em;
`;
