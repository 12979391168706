import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Progress } from 'antd';
import { Button } from 'components/UIExternal';
import { label, link, mainContainer, countLabel, progressBar } from './styles';

const StarReviewsInfo = ({
  starsCount,
  reviewsCount,
  percentValue,
  setStarsFilter,
  isOverview,
  dealName,
  disabledLinks,
}) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const location = useLocation();
  const reviewsCountText = getText('reviewsSummaryCount', { count: reviewsCount });
  const reviewsLink = location.pathname.split('/').includes('reviews')
    ? `/deal/${dealName}/reviews#${starsCount}`
    : `/deal/${dealName}/reviews#${starsCount}`;

  return (
    <div className={mainContainer}>
      <div className={progressBar}>
        <label className={label(theme)}>{getText('starsCount', { count: starsCount })}</label>
        <Progress percent={percentValue} showInfo={false} strokeColor={theme.primary_yellow} />
      </div>

      {reviewsCount > 0 ? (
        <Button
          small
          disabled={disabledLinks}
          type="link"
          className={link(theme)}
          {...(setStarsFilter && { onClick: () => setStarsFilter(starsCount) })}
          {...(!setStarsFilter && !isOverview && { linkTo: reviewsLink })}
        >
          {reviewsCountText}
        </Button>
      ) : (
        <div className={countLabel(theme)}>{reviewsCountText}</div>
      )}
    </div>
  );
};

StarReviewsInfo.propTypes = {
  reviewsCount: PropTypes.number,
  starsCount: PropTypes.number,
  percentValue: PropTypes.number,
  setStarsFilter: PropTypes.func,
  dealName: PropTypes.string,
  isOverview: PropTypes.bool,
  disabledLinks: PropTypes.bool,
};

export default StarReviewsInfo;
