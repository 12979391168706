import { parseJson } from '@veraio/core';
import moment from 'moment';
import { removeTimeZone } from 'utils';
import { notificationType } from 'enums';

export const getAllNotificationsParams = options => ({
  ...options,
  targetNotifications: Object.keys(notificationType),
  beforePublishDate: removeTimeZone(moment()),
});

export const getAllNotificationsBody = res => ({
  ...res,
  items: res.items.map(el => ({ ...el, additionalData: parseJson(el.additionalData, {}) })),
});
