import { css } from 'emotion';

export const iconContainer = css({
  width: '1.9375rem',
  height: '1.9375rem',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
