import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Spin, Tabs } from 'antd';
import { useUrlParams } from 'components';
import { errorCodes } from 'enums';
import { setError, getBusinessOverview, getBusinessReviewsSummary } from 'services';
import { mb } from 'assets/css/globalCSS';
import { NotFound } from '../errorPages';
import { OverviewTab } from './OverviewTab';
import { DealsTab } from './DealsTab';
import { ReviewsTab } from './ReviewsTab';
import { PublicMerchantHeader } from './PublicMerchantHeader';
import { publicMerchant } from './styles';

const tabs = { overview: 'overview', deals: 'deals', reviews: 'reviews' };

export const MerchantPublicPage = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { businessId } = useParams();
  const { queryParams, setQueryParams } = useUrlParams(null);
  const [businessOverview, setBusinessOverview] = useState(null);
  const [reviewsSummary, setReviewsSummary] = useState(null);

  useEffect(() => {
    fetchBusinessOverview();
    fetchReviewsSummary();
  }, []);

  const fetchBusinessOverview = async () => {
    const [res, err] = await getBusinessOverview(businessId);
    err && setError(err);
    setBusinessOverview(res ?? err);
  };

  const fetchReviewsSummary = async () => {
    const [res, err] = await getBusinessReviewsSummary(businessId);
    err ? setError(err) : setReviewsSummary(res);
  };

  const tabItems = [
    {
      key: tabs.overview,
      label: getText(tabs.overview),
      children: <OverviewTab overview={businessOverview} tabs={tabs} />,
    },
    { key: tabs.deals, label: getText(tabs.deals), children: <DealsTab businessId={Number(businessId)} /> },
    {
      key: tabs.reviews,
      label: getText(tabs.reviews),
      children: <ReviewsTab reviewsSummary={reviewsSummary} businessId={Number(businessId)} />,
    },
  ];

  if (businessOverview?.status === errorCodes.NOT_FOUND) return <NotFound />;

  return (
    <div className={publicMerchant(theme)}>
      {businessOverview ? (
        <>
          <PublicMerchantHeader merchantData={businessOverview} />
          <section className="content-container">
            <Tabs
              destroyInactiveTabPane
              defaultActiveKey={tabs.overview}
              activeKey={queryParams.tab}
              onTabClick={tab => setQueryParams({ ...queryParams, tab, pageSize: undefined })}
              items={tabItems}
              theme={theme}
            />
          </section>
        </>
      ) : (
        <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};
