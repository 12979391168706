import { css } from 'emotion';

export const seeMeOnMap = theme =>
  css({
    color: theme.gray100,
    paddingLeft: 0,
    position: 'relative',
    opacity: 1,
    '& svg': {
      marginRight: '.5em',
    },
  });

export const modalContainer = css({
  '& .leaflet-container': {
    height: '75vh',
  },
});
