import styled from '@emotion/styled';
import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

// REMOVE
export const HeadingPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const title = css({
  [Mobile]: { fontSize: '1.1875rem' },
  fontWeight: 700,
  marginBottom: '0.375em',
});

export const wrapper = hideClones =>
  css({
    '& .slick-cloned': { display: hideClones && 'none !important' },
    '& .slick-track': { margin: hideClones && '0 !important' },
    '& .slick-list': {
      margin: '0 -0.9375em',
      padding: '0px !important',
    },
    '& .slick-arrow:before': {
      display: 'none',
    },
  });
