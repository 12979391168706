import React from 'react';
import { useTranslations } from '@veraio/strank';
import { isNil } from '@veraio/core';
import { Skeleton, ProgressBar } from 'components/UIExternal';
import { useOrders, selectedOrderDeliveryDetails } from 'stores';
import packedIcon from 'assets/images/orders/packed.svg';
import ongoingIcon from 'assets/images/orders/ongoing.svg';
import deliveredIcon from 'assets/images/orders/delivered.svg';
import { DeliveryAddressInfo } from './DeliveryAddressInfo';
import { truckImage, deliveryImage } from './styles';

export const OrderDetailsDelivery = () => {
  const { getText } = useTranslations();
  const { statusId: deliveryStatus, orderId } = useOrders(ordersState => ({
    ...ordersState?.selectedOrder?.delivery,
    orderId: ordersState?.selectedOrder?.id,
  }));
  const { isDeliveryCompleted, isDeliveryOnGoing } = selectedOrderDeliveryDetails();

  const progressBarConfig = [
    {
      image: packedIcon,
      isCompleted: true,
      label: getText('packed'),
    },
    {
      image: ongoingIcon,
      isCompleted: isDeliveryOnGoing || isDeliveryCompleted,
      label: getText('ongoing'),
      ...((isDeliveryOnGoing || isDeliveryCompleted) && { imageClassName: truckImage }),
    },
    {
      image: deliveredIcon,
      isCompleted: isDeliveryCompleted,
      label: getText('delivered'),
      ...(isDeliveryCompleted && { imageClassName: deliveryImage }),
    },
  ];

  return (
    <>
      <h3 className="order-details-section-title">{getText('delivery')}</h3>
      <section className="order-details-section" id="order-details-delivery-section">
        <DeliveryAddressInfo />
        {isNil(deliveryStatus) ? (
          <Skeleton height={20} width="100%" />
        ) : (
          <ProgressBar key={`${orderId}-${deliveryStatus}`} steps={progressBarConfig} />
        )}
      </section>
    </>
  );
};
