import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const loadMoreBtn = css({
  [Mobile]: {
    margin: 'auto',
    width: '100%',
    '& span': {
      margin: 'auto',
    },
  },
});

export const sortWrapper = theme =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '2.5em',
    marginBottom: '3.625em',
    '& label': {
      color: theme.gray200,
    },
  });

export const backBtn = theme =>
  css({
    marginBottom: '1.5em',
    justifyContent: 'start !important',
    '& .icon-Arrow-Left': {
      fontSize: '1.3125rem',
      color: theme.blue,
    },
    '& .icon-Arrow-Left:before': {
      verticalAlign: 'baseline',
    },
    '& span:not(:first-child)': {
      fontSize: '1.1875rem',
      fontWeight: 700,
      color: theme.gray100,
    },
  });

export const titleWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '0.9375em',
  '& p': {
    fontSize: '1.4375rem',
    fontWeight: 700,
    margin: 0,
  },
  '& a': {
    maxWidth: '11.875rem',
  },
});

export const commentsWrapper = css({
  marginBottom: '9.375em',
  [Mobile]: {
    marginTop: '1.6875em',
    marginBottom: '3.9375em',
  },
});

export const backButton = theme =>
  css({
    paddingLeft: '0',
    color: theme.gray100,
    fontSize: '1.1875rem',
    fontWeight: 700,
  });
