import moment from 'moment';

export const defaultDate = '0001-01-01T00:00:00';

export const validDate = date => (date === defaultDate ? null : date);

export const formatDefaultDate = date => moment(date).format('DD/MM/YYYY');

export const formatDescriptiveDate = date => moment(date).format('DD MMM YYYY');

export const formatDescriptiveDateFullMonth = date => moment(date).format('DD MMMM YYYY');

export const formatDescriptiveDateTime = date => moment(date).format('DD MMM YYYY hh:mm');

export const formatDescriptiveDateTime24h = date => moment(date).format('DD MMM YYYY HH:mm');

export const formatTime = date => moment(date).format('HH:mm');

export const isValidDaysPeriod = (start, end) => moment(end).isSameOrAfter(start, 'day');

export const isFutureOrSameDate = date => moment(date).isSameOrAfter(moment(), 'day');

export const isFutureDate = date => moment(date).isAfter(moment(), 'day');

export const couponsLimit = date =>
  moment(date)
    .add(30, 'days')
    .format('DD/MM/YYYY');
