import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const notificationPanelContainer = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.white,
    borderRadius: 4,
    padding: 0,
    [Mobile]: {
      width: '100%',
      left: '0 !important',
      '& .ant-popover-content': { width: '100%' },
    },
    [Desktop]: { maxWidth: '21.875rem' },
  });

export const badgeContainer = css({
  position: 'relative',
  padding: '0.5em 0.75em',
  [Mobile]: {
    padding: '0.25em',
  },
});

export const badgeNumber = (theme, count) =>
  css({
    position: 'absolute',
    top: 0,
    right: '0.625em',
    display: count < 1 && 'none',
    backgroundColor: theme.primary_yellow,
    fontSize: '0.5rem',
    fontWeight: 700,
    borderRadius: 2,
    padding: '0.0625em',
    color: theme.gray100,
  });

export const popOverClass = theme =>
  css({
    position: 'fixed',
    [Mobile]: {
      width: '100%',
      background: theme.white,
    },
  });
