import { css } from 'emotion';

export const mainContainer = theme =>
  css({
    '& label': {
      fontSize: '1rem',
      fontWeight: 500,
    },
    '& .ant-input-number': {
      width: '6rem',
      maxWidth: '6rem',
      border: `1px solid ${theme.gray200}`,
      borderRadius: '3px',
      '&:focus, &:hover, .ant-input-number-focused': {
        boxShadow: 'none',
        borderColor: theme.primary_blue,
      },
    },
    '& .ant-input-number-input': {
      fontSize: '1rem',
      fontWeight: 400,
      color: theme.gray100,
      height: '2.5rem',
    },
    '& .ant-input-number-handler-wrap': {
      display: 'none',
    },
    '& .ant-slider-rail': {
      backgroundColor: theme.gray500,
    },
    '& .ant-slider-track': {
      backgroundColor: theme.primary_blue,
    },
    '& .ant-slider-handle': {
      backgroundColor: theme.slider_step,
      borderColor: theme.slider_step,
    },
  });

export const inputsContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& .input-and-currency': {
    display: 'flex',
    gap: '1em',
    alignItems: 'baseline',
  },
});
