import { css } from 'emotion';

export const orderContentProgressBlock = theme =>
  css({
    margin: '2em 0',
    padding: '1em 1.5em',
    borderRadius: 6,
    border: `1px solid ${theme.green2}`,
    boxShadow: '0 0 0.4rem 0 rgba(0, 0, 0, 0.25)',

    '& .fulfill-content-container': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '1rem',
    },

    '& .fulfill-steps-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      color: theme.green2,
      fontWeight: 300,
    },
  });

export const orderContentProgressTitle = theme =>
  css({
    color: theme.gray100,
    fontWeight: 500,
    fontSize: '1.4rem',
  });

export const orderContentProgressStepBar = css({
  gap: '50%',
  '& .side': {
    flex: '1 0 0',
  },
});
