import queryString from 'query-string';
import { pick } from '@veraio/core';

export const getAccountsParams = options => {
  const { searchText } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(searchText && searchText?.length && { searchText }),
    },
    { arrayFormat: 'index' },
  );
};
