import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Page, Document, View, Image, Text } from '@react-pdf/renderer';
import { isNumber, pick } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, printPrice, convertCurrencies } from '@oneecosystem/dealshaker-core';
import { isNil, chunk } from 'lodash-es';
import DealStatus from 'enums/DealStatus';
import useError from 'services/errorHandling/useError';
import { getDeals } from 'services/api/brandMaterialService';
import bgBlueInsidebgBlue from 'assets/images/brandMaterials/brochure/bgBlueInsidebgBlue30.5x21.jpg';
import bgBlueFrontbgBlue from 'assets/images/brandMaterials/brochure/bgBlueFrontbgBlue30.5x21.jpg';
import placeholderThumbnail from 'assets/images/brandMaterials/brochure/placeholderThumbnail.jpg';
import discountStamp from 'assets/images/brandMaterials/brochure/discountStamp.png';
import { s } from './styles';
import { generalStyles } from '../styles';

const BrochureTemplate = ({ qrCode, businessInfo }) => {
  const { displayFiatOnly } = useCurrencies(currenciesState =>
    pick(currenciesState, ['displayFiatOnly', 'selectedCurrency']),
  );
  const { getText, getDynamicTranslation } = useTranslations();
  const { setError } = useError();
  const [deals, setDeals] = useState();

  useEffect(() => {
    if (!isNil(businessInfo.id)) fetchDealsData();
  }, [businessInfo.id]);

  const fetchDealsData = async () => {
    const [res, err] = await getDeals({ businessId: businessInfo?.id, dealsSorting: 0, pageSize: 100 });

    res
      ? setDeals(
          res.items
            .filter(deal => deal.statusId === DealStatus.Approved.id)
            .map(el => ({
              ...el,
              dealDetails: getDynamicTranslation(el.dealDetails)?.title,
              businessName: getDynamicTranslation(el.businessName)?.name,
            })),
        )
      : setError(err);
  };

  const smallDeal = (deal, idx) => {
    const fiatOriginCurrency = deal.currencyCode;
    const price = convertCurrencies({ fiat: deal.priceFiat, fiatCrypto: deal.priceCrypto, fiatOriginCurrency });
    const fiatLabelSign = 'code';
    const discountedPrice = convertCurrencies({
      fiat: deal.discountedPriceFiat,
      fiatCrypto: deal.discountedPriceCrypto,
      fiatOriginCurrency,
    });

    const basePriceProps = { fiatOriginCurrency, fiatLabelSign };

    return (
      <View style={s.contentBox.dealContainer} key={idx}>
        <View style={[s.contentBox.dealImgContainer]}>
          {deal.media?.url ? (
            <Image src={deal.media?.url} alt="" style={s.contentBox.dealImg} />
          ) : (
            <Image src={placeholderThumbnail} alt="" style={s.contentBox.noDealImg} />
          )}
          {deal.discount > 0 && (
            <View style={s.contentBox.dealImgContainer.discountContainer}>
              <Image src={discountStamp} alt="" />
              <Text style={s.contentBox.dealImgContainer.discountContainer.discountValue}>-{deal.discount}%</Text>
            </View>
          )}
        </View>
        <Text style={s.contentBox.dealName}>{deal.dealDetails}</Text>
        {isNumber(price.fiatLocal) && !displayFiatOnly && (
          <Text
            style={[
              s.contentBox.dealPrices,
              deal.discount > 0 ? s.contentBox.dealPrices.hasDiscount : s.contentBox.dealPrices.noDiscount,
            ]}
          >
            {printPrice({ ...basePriceProps, fiat: deal.priceFiat, total: deal?.price })}
          </Text>
        )}
        {isNumber(price.crypto) && !displayFiatOnly && (
          <Text
            style={[
              s.contentBox.dealPrices,
              deal.discount > 0 ? s.contentBox.dealPrices.hasDiscount : s.contentBox.dealPrices.noDiscount,
            ]}
          >
            {printPrice({ ...basePriceProps, fiatCrypto: deal.priceCrypto, total: deal?.price })}
          </Text>
        )}
        {displayFiatOnly && (
          <Text
            style={[
              s.contentBox.dealPrices,
              deal.discount > 0 ? s.contentBox.dealPrices.hasDiscount : s.contentBox.dealPrices.noDiscount,
            ]}
          >
            {printPrice({ ...basePriceProps, fiat: deal.price, total: deal.price })}
          </Text>
        )}
        {deal.discount > 0 && (
          <View>
            {isNumber(discountedPrice?.fiatLocal) && !displayFiatOnly && (
              <Text style={s.contentBox.discountedPrices}>
                {printPrice({ ...basePriceProps, fiat: deal.discountedPriceFiat, total: deal?.discountedPrice })}
              </Text>
            )}
            {isNumber(discountedPrice?.crypto) && !displayFiatOnly && (
              <Text style={s.contentBox.discountedPrices}>
                {printPrice({
                  ...basePriceProps,
                  fiatCrypto: deal.discountedPriceCrypto,
                  total: deal?.discountedPrice,
                })}
              </Text>
            )}
            {displayFiatOnly && (
              <Text style={s.contentBox.discountedPrices}>
                {printPrice({
                  ...basePriceProps,
                  fiat: deal.discountedPriceFiat,
                  total: deal?.discountedPrice,
                })}
              </Text>
            )}
          </View>
        )}
      </View>
    );
  };

  return (
    <Document>
      {!isNil(deals) &&
        chunk(deals, 12).map((deal, idx) => (
          <Page size={[864.5669, 595.276]} key={idx}>
            <View>
              <Image src={bgBlueInsidebgBlue} alt="" style={generalStyles.pageBackground} />
              <View style={generalStyles.wrapper}>
                {idx === 0 && (
                  <View style={[s.row, s.header]}>
                    <View>
                      <Text>{getText('newProducts')}</Text>
                    </View>
                    <View>
                      <Text style={s.header.subheader}>{businessInfo?.name?.currentValue}</Text>
                    </View>
                  </View>
                )}
                <View style={s.contentBox}>
                  <View style={s.contentBox.side}>{deal?.splice(0, 6).map(smallDeal)}</View>
                  <View style={[s.contentBox.side, s.contentBox.side.rightSide]}>
                    {deal?.splice(0, 6).map(smallDeal)}
                  </View>
                </View>
              </View>
            </View>
          </Page>
        ))}

      <Page size={[864.5669, 595.276]}>
        <View>
          <Image src={bgBlueFrontbgBlue} alt="" style={generalStyles.pageBackground} />
          <View style={s.back.wrapper}>
            <View style={s.back.contentBox}>
              <Text style={s.back.textBox.country}>{businessInfo?.country?.currentValue}</Text>
              <Text style={s.back.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
              <Text style={s.back.textBox.location}>{businessInfo?.street?.currentValue}</Text>
              <Text style={s.back.textBox.location}>{businessInfo?.email?.currentValue}</Text>
              <Text style={s.back.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
              <Image source={qrCode} style={s.back.qrCodeStyles} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

BrochureTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default BrochureTemplate;
