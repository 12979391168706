import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const contentContainer = css({
  '& .section-title': {
    marginBottom: '1.25em',
  },
  [Mobile]: {
    '& .section-title': {
      marginBottom: '0.625em',
    },
    '& .section-subtitle': {
      fontSize: '0.875rem',
    },
  },
});

export const paragraphs = theme =>
  css({
    '&': {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      color: theme.gray200,
      marginBottom: '1.875em',
      [Mobile]: {
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        marginBottom: '1em',
      },
    },
  });

export const merchantContainer = css({
  display: 'flex',
  marginBottom: '1.875em',
  [Mobile]: {
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap !important',
    },
  },
});

export const fullWidth = css({
  width: '100%',
});

export const packageStyles = theme =>
  css({
    color: theme.gray300,
    marginRight: '1.5em',

    '&: last-child': {
      marginRight: 0,
    },
  });

export const packageContainer = css({
  marginBottom: '2em',
  display: 'flex',
  flexWrap: 'wrap',
  '& span': {
    display: 'block',
  },
  '& .package-weight': {
    marginRight: '1em',
  },
});

export const tagListStyles = css({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '0.3125em',
  marginBottom: '1.25em',
});

export const tagStyles = theme =>
  css({
    background: theme.gray600,
    borderRadius: 4,
    padding: '0.25em 0.75em',
    marginRight: '0.25em',
    marginTop: '0.3125em',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '0.3125em',
      cursor: 'pointer',
    },
  });
