import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { Popover } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Notification } from 'components/ui';
import { Button } from 'components/UIExternal';
import { Routes } from 'enums';
import useError from 'services/errorHandling/useError';
import { getAllNotifications, markNotificationAsSeen } from 'services/api/notifications';
import { mt, mb } from 'assets/css/globalCSS';
import noNotifications from 'assets/images/noNotifications.svg';
import { notificationPanelContainer, badgeContainer, badgeNumber, popOverClass } from './styles';

const NotificationsMiniPanel = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { setError } = useError();
  const [isPopupShown, setIsPopupSown] = useState(null);
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    fetchNotifications();
    const notificationsInterval = setInterval(() => fetchNotifications(), 60000);
    return () => clearInterval(notificationsInterval);
  }, []);

  const fetchNotifications = async () => {
    const [res, err] = await getAllNotifications({ pageNumber: 1, pageSize: 5, isSeen: false });
    res ? setNotifications(res) : setError(err);
  };

  const markAsSeen = async () => {
    const [res, err] = await markNotificationAsSeen(notifications?.items.map(el => el.id));
    err && setError(err);
    fetchNotifications();
    return res;
  };

  const togglePopup = () => {
    !!isPopupShown && notifications?.items?.length && markAsSeen();
    setIsPopupSown(prev => !prev);
  };

  return (
    <Popover
      content={
        <div className={notificationPanelContainer(theme)}>
          {notifications?.items?.length ? (
            <>
              <h3> {getText('newNotifications')}</h3>
              {notifications?.items?.map(notification => (
                <Notification key={notification.id} data={notification} />
              ))}
            </>
          ) : (
            <>
              <img
                src={noNotifications}
                className={`${mt({ lg: 16, md: 16, xs: 16 })} ${mb({ lg: 16, span: 16 })}`}
                alt="Logo"
              />
              <h4 className={mb({ lg: 24 })}>{getText('noNewNotifications')}</h4>
            </>
          )}
          <Button type="default" fullWidth linkTo={Routes.notifications} onClick={togglePopup}>
            {getText('goToNotificationsCenter')}
          </Button>
        </div>
      }
      showArrow={false}
      open={isPopupShown}
      onOpenChange={togglePopup}
      placement="bottomRight"
      overlayClassName={popOverClass(theme)}
      trigger="click"
    >
      <div className={badgeContainer}>
        <FeatherIcon icon="bell" size={26} stroke={theme.white} strokeWidth={1} />
        {notifications?.totalCount > 0 && (
          <span className={badgeNumber(theme, notifications?.totalCount)}>{notifications?.totalCount}</span>
        )}
      </div>
    </Popover>
  );
};

export default NotificationsMiniPanel;
