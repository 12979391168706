import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { AutoComplete } from 'antd';
import { getAddressSuggestions } from 'services';
import useError from 'services/errorHandling/useError';
import { searchInput, resultsContainer } from './styles';

export const AddressAutocomplete = ({ value, onChange, onSelect, coordinates }) => {
  const { getText } = useTranslations();
  const { setError } = useError();
  const [addressSuggestions, setAddressSuggestions] = useState([]);

  const fetchAddressSuggestions = useMemo(
    () =>
      debounce(async address => {
        const [res, err] = await getAddressSuggestions({ address, coordinates });
        err ? setError(err) : setAddressSuggestions(res.map(address => ({ value: address, label: address })));
      }, 500),
    [],
  );

  return (
    <AutoComplete
      filterOption
      placeholder={getText('searchByAddress')}
      notFoundContent={getText('noResultsFound')}
      popupClassName={resultsContainer}
      className={searchInput}
      options={addressSuggestions}
      onSearch={fetchAddressSuggestions}
      onSelect={onSelect}
      value={value}
      onChange={onChange}
    />
  );
};

AddressAutocomplete.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  coordinates: PropTypes.string,
};
