import { getReq, putReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';
import { getAllNotificationsParams, getAllNotificationsBody } from '../models';

const baseUrl = `${envConfig.messagingCenterApi}/api/messaging-center/Notifications`;

export const getAllNotifications = async options => {
  const [res, err] = await getReq(`${baseUrl}/All`, getAllNotificationsParams(options));
  return [res && getAllNotificationsBody(res), err];
};

export const markNotificationAsSeen = notificationIds => putReq(`${baseUrl}/MarkAsSeen`, { notificationIds });

export const markNotificationsAsRead = notificationIds => putReq(`${baseUrl}/MarkAsRead`, { notificationIds });
