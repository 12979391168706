import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const mainContainer = css({
  [Mobile]: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
});
export const cardContainer = theme =>
  css({
    padding: '2.9375em 3.3125em 2.5em',
    borderRadius: 4,
    border: `1px solid ${theme.gray500}`,
    boxShadow: '0px 2px 22px rgba(197, 200, 204, 0.24)',
    fontWeight: 500,
    color: theme.gray100,
    position: 'relative',
    marginBottom: '1.875em',
    display: 'flex',
    flexDirection: 'column',
    [Mobile]: {
      marginBottom: 0,
      padding: '1em 0.75em',
    },
    '& label': {
      margin: '0.4375em 0',
      display: 'block',
      '& .icon-QuestionMark-dotted': {
        fontSize: '1rem',
        marginLeft: '0.1875em',
      },
    },
    '& .price-panel-discount-stamp': {
      top: '-1.375rem',
      right: '1.875rem',
      height: '3.125rem',
      width: '3.1875em',
      [Mobile]: {
        top: '1.25rem',
        right: '-0.4375rem',
      },
    },
    '& .discount-text': {
      fontSize: '0.875rem',
      lineHeight: 1.625,
      margin: 0,
    },
    '& .price-text': {
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
      marginTop: 0,
      [Mobile]: {
        fontSize: '1.375rem',
      },
    },
    '& .exchange-text': {
      fontSize: '1rem',
      lineHeight: '1.1875rem',
      marginBottom: 0,
    },
    '& .helper-text': {
      fontSize: '0.6875rem',
      lineHeight: '0.8125rem',
      fontWeight: 400,
      marginTop: '0.3125em',
    },
    '& .sold-out': {
      backgroundColor: theme.gray500,
      borderRadius: 2,
      padding: '1em 2em',
      margin: '1em 0',
      fontSize: '1.125rem',
      color: theme.gray200,
      lineHeight: 1,
      textAlign: 'center',
    },
  });

export const availableOnLabel = theme =>
  css({
    background: theme.pale_yellow,
    display: 'flex',
    alignItems: 'center',
    padding: '0.3125em',
    marginTop: '1.25em',
    marginBottom: '0.625em',
    '& svg': {
      marginRight: '0.3125em',
    },
  });

export const addWishlistBtn = theme =>
  css({
    color: theme.gray100,
    margin: '0.625em auto 0',
    '& svg': {
      marginRight: '0.3125em',
    },
  });

export const deliveryInfoContainer = theme =>
  css({
    fontWeight: 400,
    fontSize: '0.875rem',
    color: theme.gray100,
    '& .price-panel-icon': {
      fontSize: '1.5rem',
      marginRight: '0.5em',
    },
    '& .delivery-info': {
      [Mobile]: {
        display: 'flex',
        '& span': { display: 'flex' },
      },
    },
    '& .icon-Info': {
      fontSize: '0.875rem',
      marginLeft: '0.3125em',
    },
    '& .coupons-container': {
      lineHeight: '1.3125rem',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '& .icon-Delivery-1': {
      color: theme.purple,
    },
    '& .icon-coupons': {
      color: theme.background200,
    },
    '& p': {
      marginBottom: '0.3125em',
    },
    '& ul': {
      paddingLeft: '1.5em',
      marginBottom: '1.125em',
      '& li': {
        paddingLeft: '0.625em',
      },
    },
  });

export const addToCartButton = theme => ({
  marginTop: '1em',
  display: 'flex',
  flexDirection: 'column',

  '& .insufficientFunds': {
    color: theme.red,
    fontSize: '0.75rem',
  },
});
