import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { InputNumber } from 'antd';
import { mul, add, sub, div } from 'exact-math';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isDesktop } from 'react-device-detect';
import { BaseCheckbox } from 'components/ui/Checkbox';
import { Button } from 'components/UIExternal';
import { Routes } from 'enums';
import { updateDealStepData } from 'services/api/dealsService';
import useError from 'services/errorHandling/useError';
import boxImage from 'assets/images/addDeal/star-box.svg';
import FormActions from '../FormActions/FormActions';
import { box, promoteWrapper, checkbox, inputNumber, totalCredits, availableCredits } from './styles';

const StepFive = ({ stepFiveData, setManualStep, currentStep, dealId, setIsDealSubmitted }) => {
  const theme = useTheme();
  const history = useHistory();
  const { getText } = useTranslations();
  const { setError } = useError();
  const [isInDealsOfTheDay, setIsInDealsOfTheDay] = useState(stepFiveData.dealOfTheDaySelected || false);
  const [dealOfTheDayPeriod, setDealOfTheDayPeriod] = useState(stepFiveData.dealOfTheDayPeriod);
  const [isInFeatured, setIsInFeatured] = useState(stepFiveData?.featuredSelected || false);
  const [featuredPeriod, setFeaturedPeriod] = useState(stepFiveData?.featuredPeriod);

  const dailyCost = stepFiveData?.dealOfTheDayCost || 5;
  const featuredCost = stepFiveData?.featuredCost || 10;
  const dealOfDayCost = isInDealsOfTheDay ? mul(dealOfTheDayPeriod || 0, dailyCost) : 0;
  const featuredDealCost = isInFeatured ? mul(featuredPeriod || 0, featuredCost) : 0;
  const totalCreditsNumber = add(dealOfDayCost, featuredDealCost);
  const maxDealOfTheDay = Math.floor(div(sub(stepFiveData?.accountCredits || 0, featuredDealCost), dailyCost));
  const maxFeaturedDeal = Math.floor(div(sub(stepFiveData?.accountCredits || 0, dealOfDayCost), featuredCost));

  const onSubmit = async () => {
    const [, err] = await updateDealStepData(
      {
        dealOfTheDaySelected: isInDealsOfTheDay,
        dealOfTheDayPeriod: isInDealsOfTheDay ? dealOfTheDayPeriod : 0,
        featuredSelected: isInFeatured,
        featuredPeriod: isInFeatured ? featuredPeriod : 0,
      },
      5,
      dealId,
    );

    err ? setError(err) : setIsDealSubmitted(true);
  };
  const onPreview = () => {
    history.push(`/deal/preview/${stepFiveData.name}`);
  };
  return (
    <>
      <div className={box(theme)}>
        {isDesktop && <img src={boxImage} alt="blue box" />}
        <h5>{getText('chooseWherePromoteDeal')}</h5>
        <div className={promoteWrapper(theme)}>
          <div className="flex">
            <BaseCheckbox
              disabled={stepFiveData.accountCredits === 0}
              onChange={e => setIsInDealsOfTheDay(e.target.checked)}
              checked={isInDealsOfTheDay}
              className={checkbox}
              theme={theme}
            />
            <div className="flex-column text">
              <h4>{getText('promoteDealsOfTheDay')}</h4>
              <label>{`${getText('creditsPerDay')}: ${stepFiveData?.dealOfTheDayCost} `}</label>
            </div>
          </div>
          <InputNumber
            disabled={stepFiveData.accountCredits === 0 || !isInDealsOfTheDay}
            onChange={e => setDealOfTheDayPeriod(e)}
            className={inputNumber}
            formatter={value => `${value} ${getText('days')}`}
            parser={value => value.replace(getText('days'), '')}
            defaultValue={stepFiveData?.dealOfTheDayPeriod}
            min={0}
            max={maxDealOfTheDay}
          />
        </div>
        <div className={promoteWrapper(theme)}>
          <div className="flex">
            <BaseCheckbox
              disabled={stepFiveData.accountCredits === 0}
              onChange={e => setIsInFeatured(e.target.checked)}
              checked={isInFeatured}
              className={checkbox}
              theme={theme}
            />
            <div className="flex-column text">
              <h4>{getText('promoteInFeaturedDeals')}</h4>
              <label>{`${getText('creditsPerDay')}: ${stepFiveData?.featuredCost} `}</label>
            </div>
          </div>
          <InputNumber
            disabled={stepFiveData.accountCredits === 0 || !isInFeatured}
            className={inputNumber}
            formatter={value => `${value} ${getText('days')}`}
            parser={value => value.replace(getText('days'), '')}
            defaultValue={stepFiveData?.featuredPeriod}
            onChange={e => setFeaturedPeriod(e)}
            min={0}
            max={maxFeaturedDeal}
          />
        </div>
        <label className={totalCredits}>{getText('totalCreditsCount', { count: totalCreditsNumber })}</label>
        <div className={`flex flex-center ${availableCredits(theme)}`}>
          <p>{getText('youHaveCreditsAvailableCount', { count: stepFiveData?.accountCredits })}</p>
          <Button
            type="link"
            small
            linkTo={{ pathname: Routes.buyCredits, state: { redirectUrl: `/edit-deal/${dealId}` } }}
          >
            {getText('buyMore')}
          </Button>
        </div>
      </div>
      <FormActions
        currentStep={currentStep}
        onNextStepHandler={onSubmit}
        onPreview={onPreview}
        setManualStep={setManualStep}
        submitDisabled={(isInDealsOfTheDay && dealOfTheDayPeriod < 1) || (isInFeatured && featuredPeriod < 1)}
      />
    </>
  );
};

StepFive.propTypes = {
  stepFiveData: PropTypes.object,
  setManualStep: PropTypes.func,
  setIsDealSubmitted: PropTypes.func,
  currentStep: PropTypes.number,
  dealId: PropTypes.number,
};
export default StepFive;
