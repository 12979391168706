import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { isDesktop, isMobile } from 'react-device-detect';
import { Input, InputNumber } from 'antd';
import { Button, Row, Col, Image } from 'components/UIExternal';
import { DealPrice } from 'components/shared';
import TickIcon from 'assets/images/icn-tick-filled.svg';
import { ReactComponent as IconTickEmpty } from 'assets/images/icn-tick.svg';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import {
  dealContainer,
  thumbnailContainer,
  priceInfoContainer,
  dealContentContainer,
  onlyOneStyles,
  removeButton,
  marginBottom,
  dealMerchantCardHeader,
  inputField,
  promoCodeSection,
  textHighlight,
  headerSection,
} from './styles';

const SummaryDealCard = ({ businesses, onQuantityChange, onRemoveDeal, onAddPromoCode, onRemovePromoCode }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const [promoCodeValue, setPromoCodeValue] = useState([]);

  const handleQuantityChange = (deal, quantity) => quantity > 0 && onQuantityChange({ ...deal, quantity });

  const handleValueOverMaxCoupons = (deal, value) =>
    value?.length ? (value > deal?.maximumCouponsPerCustomer ? deal?.maximumCouponsPerCustomer : value) : 1;

  return (
    <div className={dealContainer}>
      <Row gap={12} className={dealMerchantCardHeader}>
        <Col lg={6} md={12} className={marginBottom(24)}>
          <h5 className="section-subtitle flex">
            {getText('shoppingFrom')}
            <div className="span-img">
              <span className={textHighlight(theme)}>
                <Link to={`/merchant/${businesses?.businessId}/overview`}>
                  {getDynamicTranslation(businesses?.businessName)?.name}
                </Link>
              </span>
              <img src={TickIcon} alt="tick" />
            </div>
          </h5>
        </Col>
        <Col lg={6} md={12}>
          {businesses?.promoCode ? (
            <div className={promoCodeSection(theme)}>
              <IconTickEmpty />
              <p>{`${businesses?.promoCode?.code} ${getText('applied')}`}</p>
              <FeatherIcon
                className="icon-trash"
                icon="trash"
                size={15}
                onClick={() => onRemovePromoCode(businesses)}
              />
            </div>
          ) : (
            <div className={headerSection}>
              <Input
                placeholder={getText('havePromocode')}
                className={inputField(theme)}
                onChange={e => setPromoCodeValue(e?.target.value)}
              />
              <Button
                small
                type="secondary"
                disabled={promoCodeValue?.length <= 12}
                onClick={() => onAddPromoCode(businesses?.businessId, promoCodeValue)}
              >
                {getText('apply')}
              </Button>
            </div>
          )}
        </Col>
      </Row>
      {businesses?.items?.map(deal => (
        <Row gap={8} key={deal.dealId}>
          <Col lg={4} md={4}>
            <Image
              src={deal?.media ? deal?.media.url : placeholderThumbnail}
              alt="Deal"
              className={thumbnailContainer}
            />
          </Col>
          {isMobile && (
            <Col lg={4} md={4} sm={12}>
              <div className="flex space-between">
                <Link to={`/deal/${deal?.name}`}>
                  <h6 className="section-subtitle">{getDynamicTranslation(deal?.title ?? deal?.dealDetails)?.title}</h6>
                </Link>
                <Button type="link" small onClick={() => onRemoveDeal(deal?.dealId)}>
                  <FeatherIcon icon="x" size={14} stroke={theme.gray100} />
                </Button>
              </div>
            </Col>
          )}
          <Col lg={8} md={8} sm={12}>
            <div className={dealContentContainer}>
              {isDesktop && (
                <Link to={`/deal/${deal?.name}`}>
                  <h6 className="section-subtitle">{getDynamicTranslation(deal?.title ?? deal?.dealDetails)?.title}</h6>
                </Link>
              )}

              <div>
                <div className={priceInfoContainer(theme)}>
                  <InputNumber
                    min={1}
                    max={deal?.maximumCouponsPerCustomer}
                    value={deal.quantity}
                    onChange={value => handleQuantityChange(deal, value)}
                    disabled={deal?.maximumCouponsPerCustomer === 1}
                    parser={value => handleValueOverMaxCoupons(deal, value)}
                    addonBefore={<span className="icon-Coupons" />}
                    className="price-info-container-input"
                  />
                  <h6 className="section-subtitle price-info-container-label">
                    <DealPrice deal={deal} dataId="summary-deal-price-panel" />
                  </h6>
                </div>
                {deal?.maximumCouponsPerCustomer === 1 && (
                  <p className={onlyOneStyles}>{getText('onlyOneDealAvailable')}</p>
                )}
                <Button type="link" small onClick={() => onRemoveDeal(deal?.dealId)} className={removeButton(theme)}>
                  {getText('remove')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
};

SummaryDealCard.propTypes = {
  businesses: PropTypes.object,
  onRemoveDeal: PropTypes.func,
  onQuantityChange: PropTypes.func,
  onAddPromoCode: PropTypes.func,
  onRemovePromoCode: PropTypes.func,
};

export default SummaryDealCard;
