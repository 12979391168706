import { isNumber, pick } from '@veraio/core';
import queryString from 'query-string';

export const getTransactionsListParams = (options, accountId) => {
  const { statusId, currency, searchText, transactionsSorting } = options;

  const qs = queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(isNumber(accountId) && { accountId }),
      ...(isNumber(statusId) && { statusId }),
      ...(!!String(transactionsSorting.toString())?.length > 0 && {
        transactionsSorting: transactionsSorting.toString(),
      }),
      ...(!!String(currency)?.length > 0 && { currency }),
      ...(!!String(searchText)?.length > 0 && { searchText }),
    },
    { arrayFormat: 'index' },
  );

  return qs;
};
