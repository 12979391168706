const CryptoProvider = {
  Bitpay: {
    id: 1,
    value: 'Bitpay',
  },
  Coinbase: {
    id: 2,
    value: 'Coinbase',
  },

  getById: id => {
    const key = Object.keys(CryptoProvider).find(key => CryptoProvider[key].id === id);
    return key && CryptoProvider[key];
  },
};

export default CryptoProvider;
