import apiRoutes from 'config/apiRoutes';
import envConfig from 'config/envConfig';
import { onGoingChatResponse } from 'services/models/chatModels';
import { getReq, postReq, putReq } from '../axios/makeRequest';

const baseUrl = `${envConfig.messagingCenterApi}/api/messaging-center/Chat`;

export const getMetaDataForUserAndDeal = async (userIds, dealId, setError) => {
  const result = await getReq(`${apiRoutes.CHAT_GET_METADATA_FOR_USER_AND_DEAL}`, {
    userIds,
    dealId,
    pageSize: 5,
    pageNumber: 1,
  });
  result[1] && setError && setError(result[1]);
  return result[0] && onGoingChatResponse(result[0]);
};
export const getChatMetadata = async (chatId, setError) => {
  const result = await getReq(`${apiRoutes.CHAT_GET_METADATA(chatId)}`);
  result[1] && setError && setError(result[1]);
  return result[0] && onGoingChatResponse(result[0]);
};
export const getUnreadChat = async (pageNumber, pageSize, setError) => {
  const result = await getReq(`${apiRoutes.CHAT_GET_ALL_UNREAD}`, {
    pageNumber: pageNumber || null,
    pageSize: pageSize || null,
  });
  result[1] && setError && setError(result[1]);
  return result;
};
export const markChatAsArchived = async (chatId, setError) => {
  const result = await putReq(`${apiRoutes.CHAT_MARK_AS_ARCHIVED(chatId)}`);
  result[1] && setError && setError(result[1]);
  return result[0];
};
export const renameChat = async (name, chatId, setError) => {
  const result = await putReq(`${apiRoutes.CHAT_RENAME}`, { name, chatId });
  result[1] && setError && setError(result[1]);
  return result[0];
};
export const startChat = async (participants, setError) => {
  const [result, error] = await postReq(`${apiRoutes.CHAT_START}`, { participants });
  error && setError && setError(error);
  return [result, error];
};
export const startChatWithMessage = async (data, setError) => {
  const result = await postReq(`${apiRoutes.CHAT_START_WITH_MESSAGE}`, { ...data });
  result[1] && setError && setError(result[1]);
  return result[0];
};
export const sendMessage = async (chatId, text, media, setError) => {
  const result = await postReq(`${apiRoutes.CHAT_SEND_MESSAGE}`, { chatId, text, media });
  result[1] && setError && setError(result[1]);
  return result[0];
};
export const searchUsers = async (usernameText, setError) => {
  const result = await getReq(`${apiRoutes.CHAT_SEARCH_USERS(usernameText)}`);
  result[1] && setError && setError(result[1]);
  return result[0];
};
export const getChatModerator = async (dealId, setError) => {
  const result = await getReq(`${baseUrl}/GetModerator`, { dealId });
  result[1] && setError && setError(result[1]);
  return result[0];
};
export const leaveChat = async (chatId, setError) => {
  const result = await putReq(`${apiRoutes.CHAT_LEAVE(chatId)}`);
  result[1] && setError && setError(result[1]);
  return result[0];
};
