import { css } from 'emotion';

export const guideContentPageContainer = (content, theme) =>
  css({
    textAlign: 'justify',
    alignSelf: 'center',
    display: content?.img ? 'flex' : 'block',
    gap: '2em',
    alignItems: 'center',
    padding: '1em 0',
    '& .guideContentPageTitle': {
      color: theme.blue,
      textTransform: 'uppercase',
      textAlign: 'center',
      marginBottom: '1em',
    },
    '& .guideContentPageSubTitle': {
      color: theme.blue,
      marginBottom: '1.5em',
    },
    '& .guideContentPageText': {
      color: theme.blue,
      fontSize: '1.1rem',
      marginBottom: '1.5em',
    },
    '& .guideLinesContentPageImg': {
      width: content?.title ? '50%' : '100%',
    },
  });
