import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import logoBlack from 'assets/images/guideline/logos/logoBlack.svg';
import logoBlue from 'assets/images/guideline/logos/logoBlue.svg';
import logoColor from 'assets/images/guideline/logos/logoColor.svg';
import logoColorBlue from 'assets/images/guideline/logos/logoColorBlue.svg';
import logoWhiteBlue from 'assets/images/guideline/logos/logoWhiteBlue.svg';

import { brandGuideLogosContainer } from './styles';

export const BrandGuideLogos = () => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div className={brandGuideLogosContainer(theme)}>
      <a href={logoColor} download>
        <img src={logoColor} alt="logoColor" />
        <p className="downloadOverflow">{getText('download')}</p>
      </a>
      <a href={logoBlack} download>
        <img src={logoBlack} alt="logoBlack" />
        <p className="downloadOverflow">{getText('download')}</p>
      </a>
      <a href={logoWhiteBlue} download>
        <img src={logoWhiteBlue} alt="logoWhiteBlue" />
        <p className="downloadOverflow">{getText('download')}</p>
      </a>
      <a href={logoBlue} download>
        <img src={logoBlue} alt="logoBlue" />
        <p className="downloadOverflow">{getText('download')}</p>
      </a>
      <a href={logoColorBlue} download>
        <img src={logoColorBlue} alt="logoColorBlue" />
        <p className="downloadOverflow">{getText('download')}</p>
      </a>
    </div>
  );
};
