import { useState } from 'react';
import { useEventListener } from './useEventListener';

export const useScrollToTop = () => {
  const [scrollPosition, setScrollPosition] = useState(false);

  useEventListener('scroll', () => (window.scrollY > 1000 ? setScrollPosition(true) : setScrollPosition(false)));

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return { scrollPosition, scrollToTop };
};
