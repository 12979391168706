import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { BrandColorPage21 } from './components/BrandColorPage21';
import { BrandColorPage22 } from './components/BrandColorPage22';
import { BrandColorPage23 } from './components/BrandColorPage23';
import { guideBrandColorsContainer, guideBrandColorsTextContainer } from './styles';

export const BrandGuideBrandColorsPage = ({ content }) => {
  const { getText } = useTranslations();
  const theme = useTheme();

  const pagesComponent = {
    page21: <BrandColorPage21 />,
    page22: <BrandColorPage22 />,
    page23: <BrandColorPage23 />,
  };

  return (
    <div className={guideBrandColorsContainer}>
      <div className={guideBrandColorsTextContainer(theme)}>
        <h1 className="brandColorsTitle">{getText(content?.title)}</h1>
        <p className="brandColorsText">{getText(content?.text1)}</p>
      </div>
      <div>{pagesComponent[content.page]}</div>
    </div>
  );
};
