import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  contentBox: {
    position: 'absolute',
    bottom: 300,
    left: 600,
  },
  textBox: {
    businessName: {
      fontSize: 300,
      fontWeight: 800,
      color: '#fff',
      marginBottom: 100,
    },
    location: {
      fontSize: 140,
      color: '#fff',
    },
  },
});
