import React, { cloneElement } from 'react';
import { useTheme } from 'emotion-theming';
import { Switch, Redirect, Route, NavLink, matchPath, useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { pick, isFunction } from '@veraio/core';
import { omit } from 'lodash-es';
import { stepsStyles, stepContainer } from './styles';

export const useStepIndicator = ({ steps, redirectToFirstIncomplete, onChangeStep }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const history = useHistory();
  const firstIncompleteStep = steps?.find(step => !step.completed) ?? steps.at(-1);
  const activeStepIndex = steps?.findIndex(step => matchPath(window.location.pathname, step.route.path));

  const goNext = () => {
    if (activeStepIndex === steps.length) return;

    history.push(steps.at(activeStepIndex + 1)?.route?.path);
    isFunction(onChangeStep) && onChangeStep(steps.at(activeStepIndex + 1));
  };

  const goPrevious = () => {
    if (!activeStepIndex) return;

    history.push(steps.at(activeStepIndex - 1)?.route?.path);
    isFunction(onChangeStep) && onChangeStep(steps.at(activeStepIndex - 1));
  };

  const Steps = ({ className }) => (
    <div className={stepsStyles(theme, className)}>
      {steps?.map((step, index) => {
        const isActive = steps.at(activeStepIndex).label === step.label;
        const isCompleted = step.completed || step.label === firstIncompleteStep.label;

        return (
          <NavLink
            key={step.label}
            to={step?.route?.path}
            disabled={!isActive && !isCompleted}
            className={stepContainer(theme, isActive, isCompleted)}
          >
            <p className="index">{index + 1}</p>
            <label className="label">{getText(step.label)}</label>
          </NavLink>
        );
      })}
    </div>
  );

  const Content = incomingProps => {
    if (steps.some(step => !step?.route?.path || !step?.route?.component)) return null;

    return (
      <Switch>
        {steps
          .filter(el => el.completed || el.label === firstIncompleteStep.label)
          .map(step => (
            <Route
              key={step.route.path}
              {...omit(step.route, ['component', 'path'])}
              path={step.route.path}
              render={props =>
                cloneElement(step.route.component, {
                  ...props,
                  ...incomingProps,
                  ...pick(step, 'completed'),
                  goNext,
                  goPrevious,
                })
              }
            />
          ))}
        <Redirect to={redirectToFirstIncomplete ? firstIncompleteStep?.route?.path : steps.at(0)?.route?.path} />
      </Switch>
    );
  };

  return [Steps, Content];
};
