import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isMobile } from 'react-device-detect';
import { SeeOnMapAddresses } from 'components';
import { storeAddressesItem, seeOnTheMapContainer } from './styles';

export const StoreAddresses = ({ addresses }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();

  return addresses?.map(address => (
    <div key={address.id} className={storeAddressesItem(theme)}>
      <div className="mainInfo">
        <h5 className="merchant-name">{getDynamicTranslation(address.name)?.name}</h5>
        <div className="merchant-location">
          {[address?.street, address?.city, address?.state, address?.postcode, address?.country]
            .filter(Boolean)
            .join(', ')}
        </div>
        {isMobile && !!addresses?.length && (
          <SeeOnMapAddresses
            fullWidth
            merchantAddressesList={addresses}
            type="info"
            label="checkOnTheMap"
            className={seeOnTheMapContainer}
          />
        )}
      </div>
      <div className="additional-info">
        <div className="contact-person">
          <p className="title">{getText('contactPerson')}</p>
          <p>
            {getText('name')}: {address.contactPerson ?? 'N/A'}
          </p>
          <p>
            {getText('phone')}: {address?.phoneNumber ?? 'N/A'}
          </p>
        </div>
        <div className="working-days-hours">
          <p className="title">Working days & hours</p>
          <p>
            {getText('workingHours')}: {address?.workingHours ?? 'N/A'}
          </p>
          <p>
            {getText('workingDays')}: {address?.workingDays ?? 'N/A'}
          </p>
        </div>
      </div>
    </div>
  ));
};

StoreAddresses.propTypes = { addresses: PropTypes.array };
