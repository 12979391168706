import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from 'emotion-theming';
import { debounce } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import { AutoComplete, Input } from 'antd';
import { useTranslations } from '@veraio/strank';
import { isDesktop, isMobile } from 'react-device-detect';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'components/UIExternal';
import MobileMegaMenu from 'components/ui/MegaMenu/MobileMegaMenu/MobileMegaMenu';
import { useUser } from 'stores';
import useError from 'services/errorHandling/useError';
import { getAllCategories } from 'services/api/category';
import { searchForDeals } from 'services/api/dealsService';
import logo from 'assets/images/Logo_white.png';
import LocalizationPanel from '../LocalizationPanel';
import UserHeaderActions from './UserHeaderActions';
import NavigationBar from '../NavigationBarPanel';
import {
  lightBlueHeaderContainer,
  blueHeaderContainer,
  topHeaderStyles,
  middleHeader,
  LogoIcon,
  fixedPosition,
  searchResultWrapper,
  verticalSeparator,
  searchSuffixButtons,
  searchIconWrapper,
} from './styles';

const Header = () => {
  const theme = useTheme();
  const history = useHistory();
  const { getText } = useTranslations();
  const { isDisabled } = useUser();
  const { setError } = useError();
  const [categories, setCategories] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const fetchAllCategories = async () => {
    const [res, err] = await getAllCategories();
    err ? setError(err) : setCategories(res);
  };

  const onIconClick = () => searchText && history.push(`/listing?searchText=${searchText}`);

  const onSearchChange = async e => {
    if (e?.length === 0) return setSearchResult([]);
    const [res, err] = await searchForDeals({ dealTitle: e, tags: e });
    err && setError(err);

    setSearchResult((res ?? []).map(el => ({ ...el, value: el.title })));
  };

  const handleSearch = useMemo(() => debounce(onSearchChange, 500), []);

  const handleSelect = value => {
    const params = stringify({ searchText: value });
    if (value?.length > 0) history.push(`/listing?${params}`);
    document.activeElement.blur();
  };

  const handleClear = () => {
    setSearchText(null);
    setSearchResult([]);
  };

  return (
    <header className={fixedPosition}>
      <>
        {!isDisabled && (
          <div className={`${lightBlueHeaderContainer(theme)} location-top-navBar`}>
            <div className={`${topHeaderStyles} content-container top-navBar`}>
              <LocalizationPanel />
            </div>
          </div>
        )}
        <div className={blueHeaderContainer(theme)}>
          <div className={`${middleHeader(theme)} content-container middle-navbar`}>
            <div className="flex flex-center logo-and-search">
              {isMobile && !isDisabled && <MobileMegaMenu categories={categories} />}

              <Button type="link" small linkTo="/">
                <img className={LogoIcon} src={logo} alt="Logo" />
              </Button>

              {!isDisabled && (
                <AutoComplete
                  popupClassName={searchResultWrapper}
                  notFoundContent={getText('noResultsFound')}
                  options={searchResult}
                  onSelect={handleSelect}
                  value={searchText}
                  onSearch={e => {
                    handleSearch(e);
                    setSearchText(e);
                  }}
                  placeholder={getText('searchForDeals')}
                >
                  <Input
                    onPressEnter={onIconClick}
                    suffix={
                      <div className={searchSuffixButtons}>
                        <FeatherIcon icon="x" size={14} onClick={handleClear} />
                        <div className={verticalSeparator} />
                        <div role="button" tabIndex="0" onClick={onIconClick} className={searchIconWrapper}>
                          <FeatherIcon icon="search" size={14} />
                        </div>
                      </div>
                    }
                  />
                </AutoComplete>
              )}
            </div>
            <UserHeaderActions />
          </div>
        </div>
        {isDesktop && !isDisabled && <NavigationBar categories={categories} />}
      </>
    </header>
  );
};

export default Header;
