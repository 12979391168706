import { css } from 'emotion';

export const cityFilterContainer = css({
  '& .suggestion': {
    justifyContent: 'space-between',
  },
  '& .no-merchant-found': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },
});

export const cityFilterSearchBar = css({
  '& .suggestion': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export const cityFilterBody = css({
  overflowY: 'auto',
  maxHeight: '22rem',
  '.tags': {
    marginTop: '1rem',
    '.city-tag': {
      fontSize: '0.7rem',
      padding: '0.4em 0.6em',
      margin: '0 0.2em',
    },
  },
});

export const cityFilterItem = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    padding: '1.5em',
    borderBottom: `1px solid ${theme.gray600}`,
    '& .city-name': {
      marginTop: '.5em',
    },
    '& input[type="checkbox"]': {
      position: 'relative',
      marginRight: '1em',
      '&:before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 20,
        height: 20,
        top: 0,
        left: 0,
        backgroundColor: theme.white,
        border: `1px solid ${theme.gray400}`,
      },
      '&:checked:before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 20,
        height: 20,
        top: 0,
        left: 0,
        backgroundColor: theme.primary_blue,
      },
      '&:checked:after': {
        content: "''",
        display: 'block',
        width: 4,
        height: 14,
        border: `1px solid ${theme.white}`,
        borderWidth: '0 2px 2px 0',
        WebkitTransform: 'rotate(45deg)',
        transform: 'rotate(45deg)',
        position: 'absolute',
        top: 2,
        left: 8,
      },
    },
  });

export const cityFilterCTAButtons = css({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem',
  position: 'relative',
  bottom: 0,
});

export const searchBar = theme =>
  css({
    display: 'block',
    boxShadow: 'none',
    '&.ant-input-affix-wrapper': {
      display: 'flex',
      padding: '1em',
      flex: '1 1 0',
      borderRadius: 40,
    },
    '&.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused, &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover': {
      outline: 'none',
      border: `1px solid ${theme.gray100}85`,
      boxShadow: 'none',
    },
  });
