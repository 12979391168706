import { css } from 'emotion';
import { Mobile, Desktop } from 'assets/css/breakpoints';

export const userProfileImage = css({
  height: '1.5rem',
  width: '1.5rem',
  borderRadius: 20,
});

export const miniUserProfileMenu = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.white,
    fontSize: '1rem',
    '& label': { fontSize: '1.125rem' },
    [Desktop]: { minWidth: '18.75rem' },
  });

export const yourAccountsTitle = css({
  paddingLeft: '0.625em',
  fontWeight: 700,
  marginBottom: '0.3125em',
});

export const bottomProfile = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '0.3125em',
  '& span': { width: '100%' },
  '& a:last-child': {
    marginTop: '1em',
  },
});

export const addDealMiniUserStyle = css({
  marginBottom: '1em',
});

export const accountId = css({
  marginBottom: 0,
  paddingLeft: '0.625em',
  opacity: 0.6,
  fontSize: '0.875rem',
});

export const iconLinks = theme =>
  css({
    '& .feather': {
      marginRight: '0.3125em',
    },
    color: theme.gray100,
  });

export const popoverWrapper = theme =>
  css({
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'fixed !important',
    paddingTop: '0px !important',
    backgroundColor: theme.white,
    borderRadius: 4,
    '& label': { fontSize: '1.125rem' },
    [Mobile]: {
      width: '100%',
      left: '0 !important',
      '& .ant-popover-content': {
        width: '100%',
      },
    },
  });

export const accountsDialogMenu = theme =>
  css({
    minWidth: '18.75rem',
    maxHeight: '21.875rem',
    overflowY: 'auto',
    backgroundColor: theme.white,
    marginTop: '0.3125em',
    fontSize: '1rem',
  });

export const scrollContainer = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.white,
  });

export const accountWrapper = theme =>
  css({
    ':first-of-type': {
      backgroundColor: theme.ui_hover_rgba,
    },
    '&:hover': { backgroundColor: theme.ui_hover_rgba },
  });

export const accountAndSwitchButton = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

export const noMatch = css({
  fontSize: '0.875rem',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

export const usernames = css({
  paddingLeft: '0.625em',
  fontSize: '1.125rem',
  fontWeight: 700,
});

export const walletAccountDialog = theme =>
  css({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 2,
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0.5em',
    width: '100%',
    paddingLeft: '1.0625em',
    '& span.icon-Wallet': {
      color: theme.primary_blue,
      marginTop: '0.0625em',
    },
    '& label': {
      marginLeft: '0.9375em',
      fontSize: '0.875rem',
    },
  });

export const badgeContainer = css({
  position: 'relative',
  padding: '0.5em 0.75em',
  [Mobile]: {
    padding: '0.25em',
  },
});

export const wallet = theme =>
  css({
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 2,
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0.5em',
    width: '100%',
    paddingLeft: '1.0625em',
    backgroundColor: theme.ui_hover_rgba,
    '& span.icon-Wallet': {
      color: theme.blue,
      marginTop: '0.0625em',
    },
    '& > p': {
      marginLeft: '0.6375em',
    },
  });
