import React, { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isPlainObject, has } from 'lodash-es';
import { useTheme } from 'emotion-theming';
import { Button } from 'components/UIExternal';
import FeatherIcon from 'feather-icons-react';
import { Input } from 'antd';
import { searchInput, linkBtn } from './styles';

const Search = forwardRef((props, ref) => {
  const {
    inputClass,
    placeholder,
    onSearch,
    onPressEnter,
    onIconClick,
    allowClear,
    value,
    noValue,
    defaultValue,
    className,
  } = props;
  const theme = useTheme();
  const inputRef = useRef();

  useEffect(() => {
    const closeIcon = inputRef.current?.input?.parentNode?.querySelector('.ant-input-clear-icon');
    if (closeIcon) closeIcon.onclick = e => isFunction(onPressEnter) && onPressEnter('', e);
  }, [onPressEnter]);

  const onChange = e => isFunction(onSearch) && onSearch(e?.target?.value);

  return (
    <Input
      ref={reference => {
        isFunction(ref) && ref(reference);
        // eslint-disable-next-line no-param-reassign
        if (isPlainObject(ref) && has(ref, 'current')) ref.current = reference;
        inputRef.current = reference;
      }}
      allowClear={allowClear}
      className={inputClass ? `${inputClass} ${searchInput(theme, className)}` : searchInput(theme, className)}
      placeholder={placeholder}
      onChange={onChange}
      onPressEnter={e => isFunction(onPressEnter) && onPressEnter(e?.target?.value, e)}
      value={noValue || !value ? undefined : value}
      defaultValue={defaultValue}
      prefix={
        <Button type="link" className={linkBtn} small onClick={onIconClick}>
          <FeatherIcon icon="search" size={15} />
        </Button>
      }
    />
  );
});

Search.propTypes = {
  inputClass: PropTypes.string,
  onIconClick: PropTypes.func,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  value: PropTypes.string,
  noValue: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default Search;
