import { getReq, postReq, putReq, deleteReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';

const baseUrl = `${envConfig.apiAddress}/api/BusinessAddresses`;

export const getAllBusinessAddresses = params => getReq(`${baseUrl}/List`, params);

export const getSingleBusinessAddress = addressId => getReq(`${baseUrl}/Get/${addressId}`);

export const addNewBusinessAddress = data => postReq(`${baseUrl}/Create`, data);

export const updateBusinessAddress = data => putReq(`${baseUrl}/Update`, data);

export const deleteBusinessAddress = addressId => deleteReq(`${baseUrl}/Delete/${addressId}`);
