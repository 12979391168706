import { css } from 'emotion';

export const noDealsContainer = css({
  '& img': {
    margin: '0 auto',
  },
  margin: '4em auto 0',
  textAlign: 'center',
  minHeight: '30vh',
});
