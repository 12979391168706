import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Menu, Spin } from 'antd';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { useTheme } from 'emotion-theming';
import { isMobile } from 'react-device-detect';
import { BorderedShadowlessBox } from 'components/ui';
import { toggleItemInArray, toggleObjectItemsInArray } from 'utils';
import { categoriesFilterContent, secondLevel } from './styles';

const ContinentPicker = ({ onChange, selectedCountriesIdsFromData = [] }) => {
  const { continents, countries } = useLocations();
  const { getText } = useTranslations();
  const theme = useTheme();
  const [selectedIds, setSelectedIds] = useState(selectedCountriesIdsFromData);
  const [menuTop, setMenuTop] = useState(0);
  const menuRef = useRef();

  useEffect(() => {
    onChange(selectedIds);
  }, [selectedIds]);

  useEffect(() => {
    if (menuRef.current && menuRef.current !== null) {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const { y } = menuRef.current.getBoundingClientRect();
      setMenuTop(y + winScroll);
    }
  }, [menuRef.current]);

  const getSelectedCountriesCount = continent =>
    continent.countries.filter(country => selectedIds?.length && selectedIds.indexOf(country.id) > -1).length;

  const toggleAllHandler = checked => {
    checked ? setSelectedIds(countries.map(el => el.id)) : setSelectedIds([]);
  };
  return continents?.length ? (
    <BorderedShadowlessBox style={{ width: '100%' }} theme={theme} ref={menuRef}>
      <Menu
        data-automation-id="ContinentPicker-b2b1a0bf-7782-45ae-83ec-27f5a8d7347d"
        className={categoriesFilterContent}
        triggerSubMenuAction="click"
        multiple
        selectedKeys={[]}
        mode={isMobile ? 'inline' : 'vertical'}
      >
        <>
          <Menu.Item
            data-automation-id="ContinentPicker-b79b4a95-893e-43a5-a8e4-e77fbe543eab"
            icon={
              <Checkbox
                checked={selectedIds.length === countries.length}
                indeterminate={selectedIds.length && selectedIds.length < countries.length}
                onChange={e => toggleAllHandler(e.target.checked)}
                data-automation-id="ContinentPicker-d8e8cb81-3500-4a44-b65c-519145d27dda"
              />
            }
            key="worldwide"
          >
            {getText('worldwide')}
          </Menu.Item>
          {continents.map(continent => (
            <Menu.SubMenu
              data-automation-id="ContinentPicker-9aab7b89-0713-4830-b206-d71299ac13bc"
              popupClassName={secondLevel(menuTop)}
              key={continent.id}
              icon={
                <Checkbox
                  style={{ marginRight: 15 }}
                  onChange={() => setSelectedIds(toggleObjectItemsInArray(selectedIds, continent.countries, 'id'))}
                  checked={getSelectedCountriesCount(continent) === continent.countries.length}
                  indeterminate={
                    getSelectedCountriesCount(continent) < continent.countries.length &&
                    getSelectedCountriesCount(continent) > 0
                  }
                  data-automation-id="ContinentPicker-c6622cb5-ff9f-499b-b50c-d30af1e5ecb1"
                />
              }
              title={<span>{getText(continent.name)}</span>}
            >
              {continent?.countries?.map(country => (
                <Menu.Item
                  data-automation-id="ContinentPicker-d69fd3ee-0b41-4929-8a04-ad560b453140"
                  icon={
                    <Checkbox
                      checked={selectedIds.indexOf(country.id) > -1}
                      data-automation-id="ContinentPicker-a7b2631b-0e7e-43e6-97fb-7961a5de42f5"
                    />
                  }
                  onClick={e => setSelectedIds(toggleItemInArray(selectedIds, parseInt(e.key, 10)))}
                  key={country.id}
                >
                  {getText(country.name)}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}
        </>
      </Menu>
    </BorderedShadowlessBox>
  ) : (
    <div className="content-container">
      <Spin size="large" />
    </div>
  );
};

ContinentPicker.propTypes = {
  onChange: PropTypes.func,
  selectedCountriesIdsFromData: PropTypes.array,
};

export default ContinentPicker;
