import React from 'react';
import PropTypes from 'prop-types';
import { backgroundPicture, imageContainer, child } from './styles';

const Image = props => {
  const { children, src, alt, width, height, img } = props;

  return img ? (
    <>
      <img
        src={src}
        alt={alt}
        width={width || '100%'}
        height={height || 'auto'}
        className={imageContainer(props)}
      />
      <div className={child(props)}>{children}</div>
    </>
  ) : (
    <div title={alt} className={backgroundPicture(props)}>
      {children}
    </div>
  );
};

Image.propTypes = {
  children: PropTypes.any,
  img: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Image;
