import React from 'react';
import PropTypes from 'prop-types';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { useTranslations } from '@veraio/strank';
import { locationFiltered, locationFilteredTooltip } from './styles';

const CountriesFilteredToolTip = ({ selectedCountries }) => (
  <div className={`${locationFilteredTooltip} locationFilter-popup`}>
    <span className="country-names">
      {selectedCountries
        ?.slice(0, 3)
        ?.map(el => el?.name)
        ?.join(', ')}
      {selectedCountries?.length > 3 && <span className="country-count">+{selectedCountries?.length - 3}</span>}
    </span>
  </div>
);

const RenderToolTip = () => {
  const { shippingFrom, shippingTo } = useLocations();
  const { getText } = useTranslations();

  return (
    <>
      {!!shippingFrom?.length && (
        <div className="tooltip">
          <span className="label">{getText('dealsFrom')}: </span>
          <span className={locationFiltered}>{shippingFrom?.length}</span>
          <CountriesFilteredToolTip selectedCountries={shippingFrom} />
        </div>
      )}
      {!!shippingTo?.length && (
        <div className="tooltip">
          <span className="label">{getText('shippingTo')}: </span>
          <span className={locationFiltered}>{shippingTo?.length}</span>
          <CountriesFilteredToolTip selectedCountries={shippingTo} />
        </div>
      )}
    </>
  );
};

CountriesFilteredToolTip.propTypes = {
  selectedCountries: PropTypes.array,
};

export default RenderToolTip;
