import { css } from 'emotion';
import styled from '@emotion/styled';
import { BlackRating } from 'components/ui';
import { Mobile } from 'assets/css/breakpoints';

export const couponTemplate = css({
  width: '100%',
});

export const couponThumbnail = css({
  width: '100%',
  height: 'auto',
});

export const thumbnailContainer = css({
  position: 'relative',
  height: '10.625rem',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  justifyContent: 'center',
  marginRight: '1.5em',
  [Mobile]: {
    marginBottom: '1.5em',
    width: '100%',
  },
});

export const merchantInfo = css({
  alignItems: 'center',
  fontSize: '0.75rem',
  '& img': {
    margin: '0px 0px 0px 0.25em',
  },
  [Mobile]: {
    marginBottom: 0,
  },
});

export const titleWrapper = css({
  height: '100%',
});

export const couponTitle = css({
  fontSize: '1.125rem',
  lineHeight: 1.3,
  fontWeight: 500,
  marginBottom: '0.25em',
  [Mobile]: {
    fontSize: '0.875rem',
    lineHeight: '1.5em',
    fontWeight: 700,
  },
});

export const StarRatings = styled(BlackRating)`
  &.ant-rate {
    font-size: 1rem;
  }
`;

export const ratingsCountLink = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
    marginLeft: '0.3125em',
    textDecoration: 'underline',
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
    marginLeft: '0.3125em',
  });

export const CouponDescription = styled.p`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  font-size: 1rem;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  ${Mobile} {
    font-size: 0.875rem;
    margin-bottom: 0;
  }
`;

export const dealEndDateWrapper = css({
  fontSize: '0.75rem',
  marginBottom: 0,
  [Mobile]: {
    marginTop: '0.3125em',
  },
  '.bold': {
    fontWeight: 700,
  },
  '.icon-Ends-on': {
    fontSize: '1.25rem',
    lineHeight: 1,
    marginRight: '0.375em',
  },
  '& .icon-Delivery-Type-Redeem-at-location': {
    fontSize: '1.375rem',
    marginRight: '0.25em',
  },
  '& .icon-QuestionMark-dotted': {
    fontSize: '0.875rem',
    marginLeft: '0.1875em',
  },
  '& .delivery-icon': {
    [Mobile]: {
      fontSize: '1.25rem',
    },
  },
});

export const buyerInfoContainer = css({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  [Mobile]: {
    marginTop: '1.25em',
    marginBottom: '1.25em',
    height: 'auto',
  },
  '& label': { fontWeight: 400 },
  '& .icon-Messages': { fontWeight: 700 },
});

export const couponLabel = theme =>
  css({
    marginTop: '0.625em',
    fontSize: '0.75rem',
    color: theme.gray200,
    '& span': {
      marginLeft: '0.1875em',
      fontSize: '1rem',
      color: theme.gray100,
    },
  });
