import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  contentBox: {
    position: 'absolute',
    bottom: 10,
    width: '80%',
    display: 'flex',
    marginLeft: '10%',
  },
  textBox: {
    country: {
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 5,
      color: '#FECF09',
    },
    businessName: {
      fontSize: 15,
      fontWeight: 800,
      marginBottom: 5,
      color: '#fff',
    },
    location: {
      fontSize: 10,
      color: '#fff',
    },
  },
  qrCodeStyles: {
    width: 40,
    position: 'absolute',
    top: -50,
    marginRight: 0,
    marginLeft: 'auto',
  },
});
