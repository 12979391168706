/* eslint-disable default-case */
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { sub, mul } from 'exact-math';
import { isNumber, pick } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, getDealFinalPrices } from '@oneecosystem/dealshaker-core';
import { couponStatus } from 'enums';
import { priceContainer, priceStyles, beforeDiscountStyles, savingStyles } from './styles';
import PriceLabel from '../PriceLabel';

export const DealPrice = ({ deal, showSavings, showFinalPrice, className, dataId }) => {
  const { displayFiatOnly } = useCurrencies(currenciesState =>
    pick(currenciesState, ['displayFiatOnly', 'selectedCurrency']),
  );
  const { getText } = useTranslations();
  const theme = useTheme();

  const hasFiatPrice = isNumber(deal?.priceFiat);
  const hasDiscount = isNumber(deal?.discountedPriceFiat) || isNumber(deal?.discountedPriceCrypto);
  const applyQuantity = price =>
    isNumber(deal?.quantity) && isNumber(price) && deal.quantity > 1 ? mul(deal.quantity, price) : price;
  const price = applyQuantity(deal.price);
  const priceFiat = hasFiatPrice ? applyQuantity(deal.priceFiat) : null;
  const priceCrypto = applyQuantity(deal?.priceCrypto);
  const discountedPrice = hasDiscount && applyQuantity(deal?.discountedPrice);
  const discountedPriceFiat = hasDiscount && applyQuantity(deal?.discountedPriceFiat);
  const discountedPriceCrypto = applyQuantity(deal?.discountedPriceCrypto);
  const finalPrice = getDealFinalPrices(deal);
  const savingFiat = hasDiscount && sub(deal.price, deal.discountedPrice);
  const redeemedCouponsCount = deal?.coupons?.filter(el => el.statusId === couponStatus.redeemed).length;
  const quantity = redeemedCouponsCount ?? deal.quantity;
  const fiatOriginCurrency = deal?.currencyCode;

  const getPrintPriceConfig = priceData => {
    const printPriceConfig = {
      fiat: priceData.price,
      fiatOriginCurrency,
      total: priceData.price,
    };

    if (!displayFiatOnly) {
      printPriceConfig.fiat = priceData.priceFiat;
      printPriceConfig.fiatCrypto = priceData.priceCrypto;
      printPriceConfig.percentage = deal?.percentRatio;
    }

    return printPriceConfig;
  };

  const priceComponent = () => {
    switch (true) {
      case showFinalPrice:
        return (
          <p className={priceStyles(theme)} data-id="deal-price-amount" data-fiat={priceFiat} data-crypto={priceCrypto}>
            <PriceLabel {...getPrintPriceConfig(finalPrice)} />
            {!!quantity && <span className="quantity"> x {quantity}</span>}
          </p>
        );
      case hasDiscount:
        return (
          <>
            <p
              className={beforeDiscountStyles(theme)}
              data-id="deal-price-amount"
              data-fiat={priceFiat}
              data-crypto={priceCrypto}
            >
              <PriceLabel {...getPrintPriceConfig({ price, priceFiat, priceCrypto })} />
            </p>
            <p
              className={`${priceStyles(theme)} ${className ?? ''}`}
              data-id="deal-price-discounted-amount"
              data-discounted-fiat={discountedPriceFiat}
              data-discounted-crypto={discountedPriceCrypto}
            >
              <PriceLabel
                {...getPrintPriceConfig({
                  price: discountedPrice,
                  priceFiat: discountedPriceFiat,
                  priceCrypto: discountedPriceCrypto,
                })}
              />
            </p>
          </>
        );
      default:
        return (
          <p className={priceStyles(theme)} data-id="deal-price-amount" data-fiat={priceFiat} data-crypto={priceCrypto}>
            <PriceLabel {...getPrintPriceConfig({ price, priceFiat, priceCrypto })} />
          </p>
        );
    }
  };

  return (
    <div
      className={priceContainer(className)}
      data-id={dataId ?? 'deal-price-panel'}
      data-deal-id={deal?.id}
      data-price={deal?.price}
      data-has-discount={!!deal?.discountedPrice}
      data-discount={deal?.discount}
      data-quantity={redeemedCouponsCount ?? deal?.quantity}
      data-percent={deal?.percentRatio}
    >
      {priceComponent()}

      {showSavings && hasDiscount && (
        <p className={savingStyles(theme)} data-id="save-approximately-price" data-savings={savingFiat}>
          <>
            {getText('saveApproximatelyPrice', { price: '' })}
            <PriceLabel fiat={savingFiat} total={savingFiat} />
          </>
        </p>
      )}
    </div>
  );
};

DealPrice.propTypes = {
  deal: PropTypes.object,
  showSavings: PropTypes.bool,
  showFinalPrice: PropTypes.bool,
  className: PropTypes.string,
  dataId: PropTypes.string,
};

export default DealPrice;
