import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import {
  progressLineWrapper,
  progressLine,
  progressLineCheckPoints,
  progressLinePoint,
  progressStepImages,
  progressStepStepsWrapper,
  progressStep,
} from './styles';

export const ProgressBar = props => {
  const theme = useTheme();
  const { steps } = props;
  const { getText } = useTranslations();
  const progressPercentage = parseInt((steps?.findLastIndex(el => el.isCompleted) / (steps.length - 1)) * 100, 10);
  const completedSteps = steps.filter(el => el?.isCompleted);

  return (
    <>
      <div className={progressStepImages}>
        {steps?.map((step, ind) => (
          <img key={`${step?.label}-${ind}`} className={step?.imageClassName} src={step?.image} alt={step?.label} />
        ))}
      </div>
      {/* wrapper */}
      <div className={progressLineWrapper(theme)}>
        {/* line */}
        <div className={progressLine(progressPercentage, completedSteps?.length - 1, theme)} />
        {/* dots */}
        <div className={progressLineCheckPoints}>
          {steps?.map((step, ind) => (
            <span key={`${step?.label}-${ind}`} className={progressLinePoint(step?.isCompleted, theme)} />
          ))}
        </div>
      </div>
      <div className={progressStepStepsWrapper}>
        {steps?.map((step, ind) => (
          <span key={`${step?.label}-${ind}`} className={progressStep}>
            {getText(step?.label)}
          </span>
        ))}
      </div>
    </>
  );
};

ProgressBar.propTypes = {
  images: PropTypes.array,
  stepList: PropTypes.array,
  stepLabelList: PropTypes.string,
  order: PropTypes.object,
  steps: PropTypes.array,
};
