import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const couponCodeHeader = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [Mobile]: { flexDirection: 'column' },
  [Desktop]: { marginTop: '3.25em' },
});

export const couponCodeHeaderLabel = css({
  fontSize: '1.75rem',
  fontWeight: 500,
  [Mobile]: { width: '100%', marginBottom: '1em', marginTop: '2.75em' },
});

export const couponCodeTemplate = css({
  alignItems: 'center',
  padding: '1em',
  marginTop: '1em',
  '& label': {
    marginTop: '0.625em',
  },
});

export const couponCodeLabel = css({
  fontSize: '1.4375rem',
  fontWeight: 500,
});
export const statusWrapper = css({
  [Desktop]: { marginLeft: '0.625em' },
});

export const buttonWrapper = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      gap: '1em',
      flexWrap: 'wrap',
    },
    '& .popoverBtn': {
      border: 'none',
      background: 'transparent',
      fontSize: '1.25rem',
      padding: '0px 0.125em',
      marginLeft: '0.625em',
    },
    '& .popoverBtn:hover': {
      background: theme.ui_hover,
    },
    [Desktop]: { float: 'right' },
    [Mobile]: {
      marginTop: '0.9375em',
      overflow: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    },
  });

export const copyButton = css({
  '&.ant-btn.ant-btn': {
    fontSize: '1rem',
  },
  '&.ant-btn:focus': {
    color: 'inherit !important',
  },
});
