import React from 'react';
import PropTypes from 'prop-types';
import bgBlue from 'assets/images/brandMaterials/billboard_landscape/bgBlue400x300.jpg';
import { Page, Document, View, Image, Text } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const BillBoardLandscapeTemplate = ({ qrCode, businessInfo }) => (
  <Document>
    <Page size={[8503.94, 11338.6]} orientation="landscape">
      <View>
        <Image src={bgBlue} alt="" style={generalStyles.pageBackground} />
        <View style={generalStyles.wrapper}>
          <View style={s.contentBox}>
            <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
            <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
            <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
            <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
            <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
            <Image source={qrCode} style={s.qrCodeStyles} />
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

BillBoardLandscapeTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default BillBoardLandscapeTemplate;
