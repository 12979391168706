import React from 'react';
import PaperBagTemplate from './paperBag';
import BadgesTemplate from './badges';
import BoxStickerTemplate from './boxSticker';
import LampPostBannerTemplate from './lampPostBanner';
import NotebookTemplate from './noteBook';
import OutdoorStoreSignTemplate from './outdoorStoreSign';
import TShirtsTemplate from './tShirt';
import CarTemplate from './car';
import CapTemplate from './cap';
import StickersTemplate from './stikers';
import LabelsTemplate from './labels';
import OutdoorPosterTemplate from './outdoorPoster';
import BusinessCardTemplate from './businessCard';
import StoreSignStreetTemplate from './StoreSignStreet';
import BillBoardPosterTemplate from './billBoardPoster';
import CityLightPosterTemplate from './cityLightPoster';
import IndoorPosterTemplate from './indoorPoster';
import BillBoardLandscapeTemplate from './billBoardLandscape';
import BrochureTemplate from './brochure';

export const selectTemplate = (templateId, qrCode, businessInfo, dealLabels) => {
  const templates = {
    1: <PaperBagTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    2: <BillBoardLandscapeTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    3: <BillBoardPosterTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    4: <BoxStickerTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    5: <BusinessCardTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    6: <CapTemplate />,
    7: <CarTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    8: <CityLightPosterTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    9: <LabelsTemplate dealsInfo={dealLabels} />,
    10: <NotebookTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    11: <LampPostBannerTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    12: <OutdoorPosterTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    13: <OutdoorStoreSignTemplate businessInfo={{ ...businessInfo }} />,
    14: <StickersTemplate />,
    15: <StoreSignStreetTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    16: <TShirtsTemplate />,
    17: <BadgesTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    18: <BrochureTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
    19: <IndoorPosterTemplate qrCode={qrCode} businessInfo={{ ...businessInfo }} />,
  };

  return templates[templateId];
};
