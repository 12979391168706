import { css } from 'emotion';

export const guideSectionPageContainer = css({
  display: 'flex',
  alignItems: 'center',
});

export const guideSectionPageNumber = theme =>
  css({
    color: theme.primary_yellow,
    fontSize: '6rem',
    fontWeight: 600,
  });

export const guideSectionPageTitle = theme =>
  css({
    color: theme.white,
    fontSize: '6rem',
    fontWeight: 600,
    lineHeight: 1,
  });
