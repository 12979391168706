import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isEmpty } from '@veraio/core';
import OptionsList from './OptionsList';
import { Icon } from '../../ui/Icon';
import { useDropDown } from './useDropDown';
import { prepareOptions } from './utils';
import {
  dropDownContainer,
  dropDownSelect,
  dropDownOptionsContainer,
  dropDownRightIcon,
  dropDownText,
  rightIconsContainer,
  dropDownDeleteIcon,
} from './styles';

const Dropdown = forwardRef((props, ref) => {
  const {
    leftIcon,
    rightIcon,
    multiSelect,
    onTop,
    onLeft,
    options: initOptions = [],
    maxOptions = 7,
    disabled: initDisabled,
    className,
    withSearch,
    small,
    displayKey = 'label',
    uniqueKey = 'value',
    noClear,
  } = props;

  const theme = useTheme();
  const options = prepareOptions(initOptions, displayKey, uniqueKey);
  const disabled = !options || !options.length || initDisabled;

  const {
    openClose,
    handleBlur,
    handleSelect,
    handleClear,
    handleReset,
    isOpen,
    selected,
    displayValue,
    selectRef,
    calculateDisplayValue,
  } = useDropDown({ ...props, options });

  useImperativeHandle(ref, () => ({
    changeValue: handleSelect,
    reset: handleReset,
    value: selected,
    displayValue,
    clear: handleClear,
  }));

  const numberOfOptions = options.length;

  const isSelected = multiSelect ? !!selected?.length : !isEmpty(selected);

  return (
    <div
      role="menuitem"
      tabIndex={0}
      onBlur={handleBlur}
      className={dropDownContainer(disabled, className, theme)}
      onClick={openClose}
    >
      <div className={dropDownSelect(isSelected, small, theme)}>
        {leftIcon && <Icon {...leftIcon} />}
        <span className={dropDownText(isSelected)} title={displayValue || undefined}>
          {calculateDisplayValue()}
        </span>
        <div className={rightIconsContainer}>
          {!noClear && isSelected && (
            <Icon
              material
              iconName="close"
              color="textLightSecondary"
              size="1.25rem"
              className={dropDownDeleteIcon(theme)}
              onClick={handleClear}
            />
          )}
          <Icon
            material
            iconName="keyboard_arrow_down"
            color="textLightSecondary"
            size="1.25rem"
            className={dropDownRightIcon(isOpen, theme)}
            {...rightIcon}
          />
        </div>
      </div>
      <OptionsList
        isOpen={isOpen}
        displayKey={displayKey}
        uniqueKey={uniqueKey}
        withSearch={withSearch}
        multiSelect={multiSelect}
        options={options}
        selected={selected}
        onSelect={handleSelect}
        small={small}
        className={dropDownOptionsContainer(isOpen, numberOfOptions, maxOptions, onTop, onLeft, theme)}
        containerRef={selectRef}
        theme={theme}
      />
    </div>
  );
});

Dropdown.propTypes = {
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  multiSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  mapValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.array]),
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        values: PropTypes.array,
      }),
    ),
  ]),
  small: PropTypes.bool,
  onTop: PropTypes.bool,
  onLeft: PropTypes.bool,
  maxOptions: PropTypes.number,
  noClear: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  withSearch: PropTypes.bool,
  noSimplify: PropTypes.bool,
  displayKey: PropTypes.string,
  uniqueKey: PropTypes.string,
};

export default Dropdown;
