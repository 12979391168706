import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Rate } from 'antd';
import { round } from 'exact-math';
import { useTheme } from 'emotion-theming';
import { Button } from 'components/UIExternal';
import StarReviewsInfo from 'components/review/StarReviewsInfo';
import {
  reviewsContainer,
  starRatings,
  ratingsCountLabel,
  ratingLabel,
  ratingContainer,
  starsContainer,
  merchantRatingsContainer,
  merchantRatingWrapper,
} from './styles';

const ReviewsSummary = ({
  reviewsAndComments,
  dealId,
  dealName,
  setStarsFilter,
  isInPublicMerchant,
  isOverview,
  disabledLinks,
}) => {
  const { getText } = useTranslations();
  const theme = useTheme();

  const {
    rating,
    lastReviewsRating,
    reviewsCount,
    star1Count,
    star1Percent,
    star2Count,
    star2Percent,
    star3Count,
    star3Percent,
    star4Count,
    star4Percent,
    star5Count,
    star5Percent,
    canLeaveReview,
  } = reviewsAndComments;

  const starsArray = [
    {
      starsCount: star5Count,
      percent: star5Percent,
    },
    {
      starsCount: star4Count,
      percent: star4Percent,
    },
    {
      starsCount: star3Count,
      percent: star3Percent,
    },
    {
      starsCount: star2Count,
      percent: star2Percent,
    },
    {
      starsCount: star1Count,
      percent: star1Percent,
    },
  ];

  return (
    <div className={`${reviewsContainer(theme)} reviews-summary`}>
      {isInPublicMerchant ? (
        <div className={merchantRatingsContainer}>
          <div className={merchantRatingWrapper(theme)}>
            <p className="rating">{round(lastReviewsRating ?? 0, -2)}</p>
            <p className="description">{getText('lastDealsCount', { count: 10 })}</p>
            <Rate value={lastReviewsRating} disabled />
          </div>
          <div className={merchantRatingWrapper(theme)}>
            <p className="rating">{round(rating ?? 0, -2)}</p>
            <p className="description">{getText('average')}</p>
            <Rate value={rating >= 1 ? rating : 0} disabled />
          </div>
        </div>
      ) : (
        <div className={ratingContainer}>
          <label className={ratingLabel(theme)}>{round(rating ?? 0, -2)}</label>
          <Rate className={starRatings(theme)} value={rating} disabled />
          <label className={ratingsCountLabel(theme)}>{getText('reviewsCount', { count: reviewsCount })}</label>
        </div>
      )}
      <div className={starsContainer}>
        {starsArray.map((item, idx) => (
          <StarReviewsInfo
            key={idx}
            starsCount={starsArray.length - idx}
            reviewsCount={item.starsCount}
            percentValue={item.percent}
            setStarsFilter={setStarsFilter}
            isOverview={isOverview}
            dealName={dealName}
            disabledLinks={disabledLinks}
          />
        ))}
      </div>
      {canLeaveReview && (
        <>
          <h6 className="section-subtitle">{getText('leaveReview')}</h6>
          <p>{getText('shareReviewHelpOthers')}</p>
          <Button type="default" small fullWidth linkTo={`/deal/${dealId}/leave-review`}>
            {getText('leaveReview')}
          </Button>
        </>
      )}
    </div>
  );
};

ReviewsSummary.propTypes = {
  reviewsAndComments: PropTypes.object,
  dealId: PropTypes.number,
  setStarsFilter: PropTypes.func,
  isInPublicMerchant: PropTypes.bool,
  dealName: PropTypes.string,
  isOverview: PropTypes.bool,
  disabledLinks: PropTypes.bool,
};

export default ReviewsSummary;
