import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const myProfileStyles = css({
  margin: '2em 0',
});

export const myProfileTab = css({
  backgroundColor: 'white',
});

export const spanTextWrapperStyles = css({
  [Mobile]: {
    '& span': {
      marginRight: '1em',
    },
  },
});

export const tabFilter = css({
  display: 'flex',
  alignItems: 'center',
  margin: '1.5em 0',
  gap: '1em',
  [Mobile]: {
    marginTop: '0.9375em',
    flexDirection: 'column',
    width: '100%',
    '& .search-input': { width: '100%', marginBottom: '0.3125em' },
    '& .ant-select': { width: '100%' },
    '& .coupons-history-bought-deals-sort': {
      width: '100%',
    },
  },
  '& > *:not(:first-child)': {
    [Desktop]: { marginLeft: '1em' },
  },
});

export const title = css({
  fontWeight: 700,
  fontSize: '1.4375rem',
  margin: '1.5em 0px 1em 0px',
});

export const loadMoreBtn = css({
  [Mobile]: {
    margin: 'auto',
    marginBottom: '2.1875em',
    width: '100%',
    '& span': {
      margin: 'auto',
    },
  },
  marginBottom: '2.5em',
});

export const couponsContainer = css({
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: '1.25em',
  marginBottom: '1.875em',
});

export const couponPagingContainer = css({
  paddingTop: '0.625em',
  paddingBottom: '0.625em',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill,minmax(18.125em, 1fr))',
  columnGap: '1.125em',
  rowGap: '1.125em',
});

export const profileInfoText = theme =>
  css({
    wordBreak: 'break-all',
    marginBottom: 0,
    '& label': {
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray200,
    },
  });

export const icon = theme =>
  css({
    height: '2rem',
    width: '2rem',
    backgroundColor: theme.primary_blue,
    borderRadius: '50%',
    fontSize: '1rem',
    marginTop: '0.375em',
    color: theme.white,
    display: 'table',
    textAlign: 'center',
    flexShrink: 0,
    '& span': {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
  });

export const editProfileBox = theme =>
  css({
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.08)',
    borderRadius: '3px',
    minHeight: '13rem',
    [Mobile]: {
      background: theme.gray600,
    },
    '& img': {
      [Desktop]: {
        marginLeft: '1.4375em',
      },
      [Mobile]: {
        height: '5rem',
        width: '7.5rem',
        alignSelf: 'center',
        marginTop: '1.5em',
      },
    },
  });

export const editProfileText = css({
  justifyContent: 'center',
  '& a': {
    textWrap: 'wrap',
  },
  '& button': {
    justifyContent: 'center',
    fontWeight: '400 !important',
  },
  '& label': {
    textWrap: 'wrap',
  },
  [Desktop]: {
    paddingLeft: '3.625em',
    paddingRight: '1.25em',
    '& label': {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    '& p': {
      marginBottom: '1.5em',
    },
    '& button': {
      width: '10rem',
    },
  },
  [Mobile]: {
    padding: '1em',
    '& label': {
      fontSize: '0.875rem',
      fontWeight: 700,
      marginTop: '0.625em',
      marginBottom: '0.3125em',
    },
    '& p': {
      fontSize: '0.75rem',
      marginBottom: '1.5625em',
    },
  },
});

export const navigationWrapper = theme =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '1em',
    alignItems: 'center',
    marginTop: '1.5em',
    marginBottom: '0.75em',
    [Desktop]: {
      borderBottom: `1px solid ${theme.gray500}`,
    },
  });

export const tabsWrapper = theme =>
  css({
    display: 'flex !important',
    alignItems: 'center',
    '& .ant-tabs-nav': {
      marginBottom: '0px !important',
    },
    '& .ant-tabs-ink-bar': {
      background: `${theme.gray100} !important`,
    },
    '& .ant-tabs-nav-operations': {
      display: 'none !important',
    },
  });

export const emptyWishlistWrapper = css({
  marginTop: '3.5em',
  marginBottom: '8.125em',
  display: 'flex !important',
  alignItems: 'center',
  justifyContent: 'center',
  [Mobile]: {
    flexDirection: 'column',
    marginBottom: '4.375em',
  },
});

export const emptyWishlistLabelWrapper = theme =>
  css({
    display: 'flex',
    gap: '1em',
    flexWrap: 'wrap',
    '& label': {
      display: 'block',
      fontSize: '1.125rem',
      fontWeight: 700,
      color: theme.gray200,
    },
    '& button': {
      marginTop: '1.5em',
    },
    [Desktop]: {
      marginLeft: '1.75em',
    },
    [Mobile]: {
      marginTop: '1.25em',
      justifyContent: 'center',
      '& button': {
        margin: 'auto',
        marginTop: '1.5em',
      },
    },
  });

export const wishlistBanner = css({
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: '1em',
  justifyContent: 'space-between',
  margin: '1.25em 0',

  '& .clearBtn': {
    marginLeft: 'auto',
  },
});

export const wishlistDealsWrapper = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill,minmax(250px, 1fr))',
  columnGap: '1.5em',
  rowGap: '0.5em',
  marginBottom: '1.875em',
});

export const goToOneLifeBtn = css({
  width: '60%',
});

export const filtersWrapper = css({
  display: 'flex',
  alignItems: 'flex-end',
  '& .ant-input-affix-wrapper': {
    marginRight: 12,
    padding: '0.5em 0.6875em',
  },
});

export const sortStyles = css({
  '& .ant-select > .ant-select-selector': {
    height: '2.5rem !important',
    padding: '0.2em 1em !important',
    borderRadius: '36px !important',
  },
});

export const noDealsWrapper = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '3.125em',
  marginBottom: '3.125em',
  '& img': {
    width: '11.0625rem',
  },
  [Mobile]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
});

export const noDealsLabels = theme =>
  css({
    '& label': {
      fontSize: '1.125rem',
      fontWeight: 700,
      color: theme.gray200,
    },
    '& p': {
      color: theme.gray200,
      marginTop: '0.3125em',
      marginBottom: '1em',
    },
    [Desktop]: {
      marginLeft: '1.75em',
    },
    [Mobile]: {
      marginTop: '1.25em',
    },
  });

export const loadDealsWrapper = css({
  display: 'flex',
  gap: '2em',
});
