import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { getText } from '@veraio/strank';
import { PriceLabel } from 'components/shared';
import { paymentMethodWalletContainer } from './styles';

const PaymentMethodWallet = ({ label, balanceLabelPrintPriceConfig, insufficientFunds }) => {
  const theme = useTheme();

  return (
    <div className={paymentMethodWalletContainer(theme)}>
      <h5 className="title">{getText(label)}</h5>
      <p className="subtitle">
        {getText('yourBalance')} <PriceLabel {...balanceLabelPrintPriceConfig} />
      </p>
      {insufficientFunds && <p className="insufficientFunds">({getText('yourBalanceNotEnough')})</p>}
    </div>
  );
};

PaymentMethodWallet.propTypes = {
  label: PropTypes.string,
  balanceLabelPrintPriceConfig: PropTypes.shape(),
  insufficientFunds: PropTypes.bool,
  price: PropTypes.object,
};

export default PaymentMethodWallet;
