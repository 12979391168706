import styled from '@emotion/styled';
import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const carousel = (width, teasedWidth, itemsPerPage, teaseSecondItemMobile, teaseSecondItemDesktop) =>
  css({
    '& .slick-arrow': { [Mobile]: { top: '105% !important' } },
    [Mobile]: {
      '& .slick-prev': { left: '0px !important' },
      '& .slick-next': { right: '0px !important' },
    },
    '& .slick-list .slick-slide': {
      [Desktop]: {
        width: `${teaseSecondItemDesktop ? teasedWidth : width}% !important`,
        marginRight: teaseSecondItemDesktop && itemsPerPage === 1 ? 24 : 0,
      },
      [Mobile]: {
        width: `${teaseSecondItemMobile ? teasedWidth : width}% !important`,
      },
    },

    // This is because of jump container on next slide
    // '& .slick-list .slick-slide .deal-thumbnail-container': {
    //   paddingBottom: '57%',
    // },
    '& .slick-arrow:before': {
      display: 'none',
    },
    '& .deal': {
      marginRight: 30,
      [Mobile]: {
        display: 'block',
      },
    },
  });

export const carouselDots = css({
  bottom: '-40px !important',
  [Mobile]: { bottom: '-50px !important' },
  '& li button': {
    width: '0.625rem !important',
    height: '0.625rem !important',
    border: '2px solid #19285E !important',
    borderRadius: '5px !important',
  },
  '& li.slick-active': {
    width: '1rem !important',
    '& button': {
      background: '#19285E !important',
    },
  },
});

export const flex = css({
  display: 'flex !important',
});

export const RightArrowButton = styled.button`
  position: absolute;
  display: ${props => (props.visible ? 'block' : 'none')};
  background-color: #fff !important;
  font-size: 2.5rem;
  z-index: 2;
  line-height: 0;
  //box-shadow: -1px 0px 6px rgb(0 0 0 / 16%);
  border: 0;
  border-radius: 22px;
  padding: 3px;
  cursor: pointer;

  & span {
    color: gray;
    z-index: 2;
    font-size: 2.5rem;
  }

  &:hover {
    opacity: 0.8;
  }
`;
export const LeftArrowButton = styled.button`
  position: absolute;
  display: ${props => (props.visible ? 'block' : 'none')};
  background-color: #fff !important;
  font-size: 2.5rem;
  line-height: 0;
  z-index: 2;
  border: 0;
  border-radius: 22px;
  padding: 0.1875em;
  //box-shadow: 1px 0px 6px rgb(0 0 0 / 16%);
  cursor: pointer;
  transform: scale(-1, 1);

  & span {
    color: gray;
    z-index: 2;
    font-size: 2.5rem;
  }

  &:hover {
    opacity: 0.8;
  }
`;
