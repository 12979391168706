import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const sortDropdown = theme =>
  css({
    margin: '2.1875em 0',
    '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      borderRadius: '28px',
      border: `1px solid ${theme.gray500}`,
      width: '15.625rem',
      backgroundColor: 'transparent',
    },
    '& .ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
      border: `1px solid ${theme.gray500}`,
    },
    '& .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      borderColor: theme.gray500,
      boxShadow: 'none',
    },
  });
export const filterWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
  [Mobile]: { overflow: 'auto' },
});

export const filterButtons = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    margin: '1.25em 0',
    '& .start-chat': {
      color: theme.blue,
      textDecoration: 'underline',
    },
    '& .feather': {
      marginRight: '0.3125em',
    },
  });

export const notificationsTable = theme =>
  css({
    marginBottom: '1.5em !important',
    '& .unread': { background: theme.ui_hover_rgba },
    '& .icon-Notifcations': {
      boxShadow: '0px 2px 16px 0px rgba(34, 34, 34, 0.12)',
      borderRadius: '50%',
      fontSize: '1.5rem',
      display: 'flex',
      marginRight: '0.75em',
      width: '2.5rem',
      height: '2.5rem',
      backgroundColor: theme.white,
      color: theme.primary_orange,
      borderColor: theme.gray600,
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .icon-Coupons': {
      boxShadow: '0px 2px 16px 0px rgba(34, 34, 34, 0.12)',
      borderRadius: '50%',
      fontSize: '1.4375rem',
      display: 'flex',
      width: '2.5rem',
      height: '2.5rem',
      marginRight: '0.75em',
      backgroundColor: theme.white,
      color: theme.primary_blue,
      borderColor: theme.gray600,
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .icon-Dealsbag': {
      boxShadow: '0px 2px 16px 0px rgba(34, 34, 34, 0.12)',
      width: '2.5rem',
      height: '2.5rem',
      borderRadius: '50%',
      fontSize: '1.3125rem',
      display: 'flex',
      marginRight: '0.75em',
      backgroundColor: theme.white,
      color: theme.purple,
      borderColor: theme.gray600,
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .icon-Star': {
      boxShadow: '0px 2px 16px 0px rgba(34, 34, 34, 0.12)',
      borderRadius: '50%',
      fontSize: '1.5rem',
      width: '2.5rem',
      height: '2.5rem',
      display: 'flex',
      marginRight: '0.75em',
      backgroundColor: theme.white,
      color: theme.primary_yellow,
      borderColor: theme.gray600,
      justifyContent: 'center',
      alignItems: 'center',
    },
    [Desktop]: { '& .ant-avatar-group': { alignItems: 'center' } },
    [Mobile]: {
      '& .ant-list-item': {
        border: 'none !important',
        borderRadius: 4,
      },
    },
  });

export const icon = css({
  [Desktop]: {
    width: '7%',
  },
  [Mobile]: {
    width: '15%',
  },
});

export const typeTime = theme =>
  css({
    border: 'none',
    background: 'none',
    cursor: 'pointer !important',
    marginLeft: '1em',
    [Desktop]: {
      '& .type': {
        color: theme.gray200,
        fontSize: '1.125rem',
      },
      '& .time': {
        color: theme.gray300,
        fontSize: '0.8125rem',
        cursor: 'pointer',
      },
    },
    [Mobile]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .time': {
        color: theme.gray300,
        fontSize: '0.75rem',
      },
    },
  });

export const message = css({
  [Desktop]: {
    width: '63%',
  },
});

export const dealTitle = css({
  fontSize: '1rem',
  fontWeight: 500,
  marginBottom: '1.25em',
  textTransform: 'uppercase',
});

export const moreBtn = theme =>
  css({
    fontSize: '1.375rem',
    color: theme.gray100,
  });

export const mobileWrapperTypeMessage = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '0.3125em',
  '& a': { cursor: 'pointer' },
});

export const bold = theme =>
  css({
    fontWeight: 700,
    color: `${theme.blue2} `,
  });

export const noChatsContainer = theme =>
  css({
    display: 'flex',
    [Mobile]: { flexDirection: 'column' },
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.125rem',
    color: theme.gray300,
    marginBottom: '3.125em',
    '& div': { marginLeft: 35, alignItems: 'flex-start' },
    '& button': { width: 'fit-content', marginTop: '1.5em' },
    '& label': { fontSize: '0.875rem' },
    '& h4': { fontWeight: 700 },
  });

export const noNotificationImage = css({
  marginBottom: '1.875em',
  marginTop: '2.5em',
  [Mobile]: {
    width: '40%',
  },
  [Desktop]: {
    width: '15%',
  },
});

export const messageWrapper = (theme, isCurrentUser) =>
  css({
    marginBottom: '1.25em',
    '& .avatar': { [Desktop]: { width: '70%' } },
    '& .reverse-avatar ': {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      marginLeft: 'auto',
      width: '70%',
    },
    '& .message': {
      backgroundColor: isCurrentUser ? theme.skyblue : theme.gray550,
      borderRadius: 2,
      padding: '0.5em 0.75em',
      marginTop: '0.25em',
      marginLeft: isCurrentUser && 'auto',
      [Desktop]: {
        width: '70%',
      },
    },
    '& .image ': { marginTop: '0.5em' },
    '& .user-name-label ': { margin: '0 0.3125em', fontSize: '0.8125rem', fontWeight: 500 },
    '& .hour-label ': {
      fontSize: '0.6875rem',
      color: theme.gray200,
      display: 'flex',
      alignItems: 'center',
    },
  });

export const blackTextLink = theme =>
  css({
    color: theme.gray100,
  });
