import { css } from 'emotion';

export const header = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '0.25em',

  '& .promo-code-filters': {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '1em',
    '@media (max-width: 450px)': {
      flexWrap: 'wrap',
    },
  },
});

export const table = theme =>
  css({
    border: `1px solid ${theme.gray500}`,
  });

export const status = (statusId, theme) => {
  const statusColor = {
    1: theme.green2,
    2: theme.red,
    3: theme.dark_gray,
  };

  return css({
    '& .dot': {
      marginRight: '0.4375em',
      marginBottom: '0.1875em',
      borderRadius: '5px',
      height: '0.3125rem',
      width: '0.3125rem',
      display: 'inline-block',
      background: statusColor[statusId],
    },
  });
};
