import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { guideContentPageContainer } from './styles';

export const BrandGuideContentPage = ({ content }) => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div className={guideContentPageContainer(content, theme)}>
      <div className="guideContentPageTextContainer">
        <h1 className="guideContentPageTitle">{getText(content.title)}</h1>
        <h4 className="guideContentPageSubTitle">{getText(content?.subTitle1)}</h4>
        <p className="guideContentPageText">{getText(content.text1)}</p>
        <h4 className="guideContentPageSubTitle">{getText(content?.subTitle2)}</h4>
        <p className="guideContentPageText">{getText(content?.text2)}</p>
        <h4 className="guideContentPageSubTitle">{getText(content?.subTitle3)}</h4>
        <p className="guideContentPageText">{getText(content?.text3)}</p>
      </div>
      {content?.img && <img src={content?.img} alt="img" className="guideLinesContentPageImg" />}
    </div>
  );
};
