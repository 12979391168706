import { css } from 'emotion';

export const orderDetailsDeliveryAddressContainer = css({
  '& .align-start': {
    alignSelf: 'flex-start',
  },
  '& .order-details-delivery-section': {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2em',
    '&:last-of-type': {
      marginBottom: '4em',
    },
  },
  '& .order-details-delivery-info-row': {
    display: 'flex',
    alignItems: 'center',

    '& .order-details-delivery-label': {
      width: '5rem',
    },

    '& .order-details-delivery-sub-label': {
      width: '4.5rem',
    },
  },
});
