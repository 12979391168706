import { USER_ROLES } from '@oneecosystem/authenticate';

export const UserStatus = {
  New: 'New',
  Pending: 'Pending',
  Active: 'Active',
  Frozen: 'Frozen',
  Terminated: 'Terminated',
};

export const userRoles = {
  ...USER_ROLES,
  DS_ADMIN: 'admin',
  DS_COUNTRY_MANAGER: 'countryManager',
  DS_AREA_MANAGER: 'areaManager',
  DS_REGIONAL_DIRECTOR: 'regionalDirector',
};
