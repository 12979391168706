import styled from '@emotion/styled';
import { Input } from 'antd';

export const TextInput = styled(Input)`
  font-weight: 400;
  font-size: 0.75rem;
  color: ${props => props.theme.gray100};
  padding: 0.625em;
  &.ant-input {
    border: 1px solid ${props => props.theme.gray500};
    border-radius: 0.125em;
  }
  &.ant-input:focus,
  .ant-input-focused {
    border-color: ${props => props.theme.primary_blue};
    box-shadow: none;
  }
`;
