import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { useTranslations, getText } from '@veraio/strank';
import { isArray, isFunction } from '@veraio/core';
import { Row, Col, Image, Button } from 'components/UIExternal';
import { DealPrice } from 'components/shared';
import { useShoppingCart } from 'stores';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import {
  thumbnailItemContainer,
  itemContentContainer,
  titleStyles,
  cantDeliverMessage,
  cantDeliverContainer,
} from './styles';

const ShoppingCartItem = props => {
  const { item, onUnableToDelivery } = props;
  const { getDynamicTranslation } = useTranslations();
  const { removeDeal } = useShoppingCart();
  const theme = useTheme();

  const handleRemoveItem = async () => {
    const newShoppingCart = await removeDeal(item?.dealId);
    isArray(newShoppingCart?.businesses) && onUnableToDelivery(newShoppingCart?.businesses);
  };

  return (
    <Row gap={8}>
      <Col xl={2} lg={3} md={4} sm={4}>
        <Image
          src={item?.media ? item?.media.url : placeholderThumbnail}
          alt="Deal"
          className={thumbnailItemContainer}
        />
      </Col>
      <Col xl={6} lg={4} md={4} sm={8}>
        <div className={itemContentContainer}>
          <Link to={`/deal/${item?.name}`}>
            <p className={titleStyles}>{getDynamicTranslation(item?.title)?.title}</p>
          </Link>
          <DealPrice deal={item} dataId="shopping-cart-deal-price-panel" />
        </div>
      </Col>
      {isFunction(onUnableToDelivery) && !item?.canBeDelivered && (
        <div className={cantDeliverContainer} id="unable-to-deliver">
          <p className={cantDeliverMessage(theme)}>{getText('sorryCantDeliver')}</p>
          <Button type="danger" onClick={handleRemoveItem}>
            {getText('remove')}
          </Button>
        </div>
      )}
    </Row>
  );
};

ShoppingCartItem.propTypes = {
  onUnableToDelivery: PropTypes.func,
  item: PropTypes.object,
};

export default ShoppingCartItem;
