import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { useHistory } from 'react-router-dom';
import { Rate } from 'antd';
import { Button } from 'components';
import { Routes } from 'enums';
import { useMerchantList, useUser } from 'stores';
import { formatDefaultDate } from 'utils';
import merchantDefaultAvatar from 'assets/images/merchant-no-avatar.svg';
import verifiedMerchantListing from 'assets/images/verifiedMerchantListing.svg';
import { merchantCard, merchantCardInfo, merchantCardAttributes } from './styles';

export const MerchantListCard = ({ merchant }) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();
  const { userMiniProfileRef, isAuthenticated, currentAccountBusinesses } = useUser();
  const history = useHistory();
  const categoriesPath = useMerchantList(store => store.selectedFilter?.categoriesPath);
  const firstCategory = categoriesPath?.length
    ? merchant.categories.filter(cat => cat.id === categoriesPath[categoriesPath?.length - 1].id)
    : [];
  const restArr = [...firstCategory, ...merchant.categories.filter(el => !firstCategory.find(e => el.id === e.id))];
  const isMyBusiness = !!currentAccountBusinesses?.find(business => business.id === merchant?.id);

  const handleLoginClick = () => {
    if (isAuthenticated) history.push(`${Routes.ongoingChat}?userId=${merchant.merchantUserId}&dealId=0`);
    else userMiniProfileRef?.current?.onClick();
  };

  return (
    <div className={merchantCard(theme)}>
      <div className={merchantCardInfo(theme)}>
        <div className="info">
          <img src={merchant?.avatar?.url ?? merchantDefaultAvatar} alt="merchant avatar" className="user-avatar" />
          <div>
            <div className="flex">
              <h4 className="merchant-name">{getDynamicTranslation(merchant.name)?.name}</h4>
              {merchant.isVerified && (
                <img src={verifiedMerchantListing} alt="verified merchant" className="verified-merchant" />
              )}
            </div>
            <p>{merchant.country}</p>
          </div>
        </div>
        <div className="category-tags">
          {restArr.slice(0, 2).map(category => (
            <span key={category.id} className="tag">
              {getText(category.name)}
            </span>
          ))}
          {merchant.categories?.length > 2 && (
            <span className="tags-more">
              +{merchant.categories?.length - 2} {getText('more')}
            </span>
          )}
        </div>
      </div>

      <div className={merchantCardAttributes(theme)}>
        <div className="attributes">
          <div className="soldDeals">
            <h5>{getText('soldDeals')}</h5>
            <h4>{merchant.soldDeals}</h4>
          </div>
          <div className="merchantSince">
            <h5>{getText('merchantSince')}</h5>
            <h4>{merchant.createdYear}</h4>
          </div>
          <div className="rating">
            <h5>{getText('ratingStarsCount', { ratingCount: Math.floor(merchant.rating), maxStars: 5 })}</h5>
            <Rate value={merchant.rating} disabled />
          </div>
        </div>
        <div className="callToAction">
          <div className="buttons">
            <Button fullWidth type="info" linkTo={`/merchant/${merchant.id}`}>
              {getText('viewDetails')}
            </Button>
            {!isMyBusiness && (
              <Button fullWidth type="primary" onClick={handleLoginClick}>
                {getText('message')}
              </Button>
            )}
          </div>
          <h6 className="last-active">
            {getText('lastActive')} {formatDefaultDate(merchant.lastActive)}
          </h6>
        </div>
      </div>
    </div>
  );
};

MerchantListCard.propTypes = { merchant: PropTypes.object, onLoginClick: PropTypes.func };
