import React from 'react';
import { MerchantOrderIdLink, PriceLabel, Tag } from 'components';
import { CRYPTO_CURRENCIES } from '@oneecosystem/dealshaker-core';
import { TRANSACTION_STATUS_COLOR, TRANSACTION_STATUS_INVERSE } from 'enums';
import { validDate } from 'utils';
import moment from 'moment';
import { transactionsRowStatus, textMutedStyle } from './styles';

export const BUNDLE_TRANSCTIONS_COLUMNS = (theme, getText = () => {}, isMerchant = true, accountUsername) => {
  let columns = [
    {
      name: getText('transactionId'),
      render: row => (
        <>
          {`#${row.id}`}
          {row.externalId && <span>{`#${row.externalId}`}</span>}
        </>
      ),
      width: 130,
    },
    {
      name: getText('dateCreation'),
      value: 'date',
      dateTimeFormat: 'DD-MM-YYYY HH:mm',
      width: 130,
    },
    {
      name: getText('buyer'),
      columnId: 'buyer',
      render: row =>
        row.merchantNickname === accountUsername && (
          <div>
            <strong>{row.buyerName}</strong>
            <br />
            <span className={textMutedStyle(theme)}>{row.buyerPhone}</span>
            <br />
            <span className={textMutedStyle(theme)}>{row.buyerEmail}</span>
            <br />
            <span className={textMutedStyle(theme)}>{row.buyerNickname}</span>
          </div>
        ),
    },
    {
      name: getText('merchant'),
      columnId: 'merchant',
      render: row => (
        <div>
          <strong>{row.merchantName}</strong>
          <br />
          <span className={textMutedStyle(theme)}>{row.merchantPhone}</span>
          <br />
          <span className={textMutedStyle(theme)}>{row.merchantEmail}</span>
          <br />
          <span className={textMutedStyle(theme)}>{row.merchantNickname}</span>
        </div>
      ),
    },
    {
      name: getText('amount'),
      render: row => (
        <PriceLabel
          fiat={row.originalAmount}
          fiatOriginCurrency={row.originalCurrency}
          crypto={row.oneCoinAmount || row.oespAmount}
          cryptoDesiredCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
          cryptoOriginCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
          fiatOnly={false}
          ignoreCryptoRestricted
        />
      ),
      width: 220,
    },
    {
      name: getText('orderId'),
      render: row => <MerchantOrderIdLink merchantNickname={row.merchantNickname} orderId={row.orderId} />,
      width: 130,
    },
    {
      name: getText('reason'),
      value: 'reason',
      width: 180,
    },
    {
      name: getText('status'),
      render: row => (
        <div className={transactionsRowStatus}>
          <Tag type={TRANSACTION_STATUS_COLOR[row.status]} fontSize="0.725rem">
            {getText(TRANSACTION_STATUS_INVERSE[row.status])}
          </Tag>
          {row.clearingDate && (
            <div className="clearing-date">
              <span>on {validDate(row.clearingDate) && moment(row.clearingDate).format('DD MMM YYYY')}</span>
            </div>
          )}
        </div>
      ),
      width: 180,
    },
  ];
  if (!isMerchant) columns = columns.filter(c => c.columnId !== 'buyer');

  return columns;
};
