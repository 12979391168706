import { getReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';
import { brandMaterialsParams, brandMaterialsBody } from 'services/models/brandMaterials';

const baseUrl = `${envConfig.apiAddress}/api`;

export const getBusiness = id => getReq(`${baseUrl}/Businesses/Profile/${id}`);

export const getDeals = async params => {
  const [res, err] = await getReq(`${baseUrl}/Deals/GetMerchantDeals?${brandMaterialsParams(params)}`);
  return [res && brandMaterialsBody(res), err];
};
