import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  contentBox: {
    position: 'absolute',
    width: '40%',
    bottom: 100,
    right: 50,
  },
  textBox: {
    country: {
      fontSize: 18,
      fontWeight: 600,
      color: '#FECF09',
      marginBottom: 10,
    },
    businessName: {
      fontSize: 30,
      fontWeight: 800,
      color: '#fff',
      marginBottom: 10,
    },
    location: {
      fontSize: 18,
      color: '#fff',
      marginBottom: 5,
    },
  },
  qrCodeStyles: {
    width: 80,
    marginRight: 0,
    marginLeft: 'auto',
  },
});
