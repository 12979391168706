import envConfig from 'config/envConfig';
import { getReq } from '../axios/makeRequest';
import { getTransactionsListParams } from '../models/transactions';

const baseUrl = `${envConfig.apiAddress}/api/Transactions`;

export const getTransactionsClearingList = (options, accountId) =>
  getReq(`${baseUrl}/ClearingList?${getTransactionsListParams(options, accountId)}`);

export const getTransactionsSettlementList = (options, accountId) =>
  getReq(`${baseUrl}/SettlementList?${getTransactionsListParams(options, accountId)}`);

export const getBundledTransactions = (accountId, transactionId) =>
  getReq(`${baseUrl}/BundledTransactions?accountId=${accountId}&transactionId=${transactionId}`);

export const getMyPendingClearingSettlementAmounts = accountId =>
  getReq(`${baseUrl}/MyPendingAmounts?accountId=${accountId}`);
