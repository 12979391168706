import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { getText } from '@veraio/strank';
import { Icon } from 'components/ui';
import { useButtonConfig } from './useButtonArrow';
import {
  buttonWrapper,
  inputField,
  arrowButton,
  arrowButtonNextPopover,
  optionList,
  optionItem,
  optionItemValue,
} from './styles';

export const ButtonArrow = props => {
  const theme = useTheme();
  const { options, disabled } = props;
  const {
    open,
    handleSelectOption,
    handleClickArrow,
    isOpen,
    selectedOption,
    selectedIndex,
    handleClickOutside,
  } = useButtonConfig(props);

  const isDisabled = disabled || selectedOption?.disabled;

  const isItemSelected = options.findIndex(option => option.value === selectedOption?.value);

  return (
    <div role="button" tabIndex={0} className={buttonWrapper} onBlur={handleClickOutside}>
      <button onClick={open} className={inputField(selectedOption?.color, theme)} disabled={isDisabled}>
        <p>{selectedOption?.label}</p>
      </button>
      <button
        className={arrowButton(selectedOption?.color, isDisabled, theme)}
        onClick={handleClickArrow}
        disabled={isDisabled}
      >
        <Icon iconName="las la-caret-right" size="1rem" />
        <div className={`${arrowButtonNextPopover(theme)} tooltip`}>
          {getText((options?.at(selectedIndex + 1) ?? options?.at(0))?.label)}
        </div>
      </button>
      {isOpen && (
        <ul className={optionList(theme)}>
          {options.map((option, ind) => (
            <li key={ind}>
              <button onClick={() => handleSelectOption(option)} className={optionItem(theme)}>
                <span role="button" tabIndex={0} className={optionItemValue(option, disabled, theme)}>
                  {option.label}
                </span>
                {isItemSelected === ind && <Icon iconName="las la-check" size="1.25rem" color="green" />}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

ButtonArrow.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string]),
};
