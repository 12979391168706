import { css } from 'emotion';

export const oneItemCarouselWrapper = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .ant-skeleton': { width: '100%', display: 'flex', justifyContent: 'center' },
  '& .ant-skeleton-input': {
    borderRadius: '4px',
    width: '100%',
    height: '1.25rem !important',
    marginTop: '0.625em',
  },
  '& .ant-skeleton-image': { width: '100% !important', height: '10.625rem !important', borderRadius: '4px' },
});
