import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  contentBox: {
    position: 'absolute',
    bottom: 10,
    width: '80%',
    display: 'flex',
    marginLeft: '5%',
  },
  wrapper: {
    height: '100%',
    position: 'relative',
  },
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  textBox: {
    country: {
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 5,
      color: '#FECF09',
    },
    businessName: {
      fontSize: 45,
      fontWeight: 800,
      marginBottom: 5,
      color: '#fff',
    },
    location: {
      fontSize: 24,
      color: '#fff',
    },
  },
  qrCodeStyles: {
    width: 80,
    position: 'absolute',
    bottom: 20,
    right: -20,
    marginRight: 0,
    marginLeft: 'auto',
  },
  qrCodeStylesSecondPage: {
    width: 80,
    position: 'absolute',
    bottom: 20,
    right: -20,
  },
});
