import { useDeepEffect } from 'components/UIExternal';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useDeepEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};
