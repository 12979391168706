import { css } from 'emotion';
import topBackgroundImage from 'assets/images/globalStructurePage/globalStructureTopImage.svg';
import bottomBackgroundImage from 'assets/images/globalStructurePage/globalStructureBottomImage.svg';

export const globalStructureTop = theme =>
  css({
    background: `${theme.skyblue} url(${topBackgroundImage}) no-repeat 90% 10% fixed`,
    minHeight: '35rem',
    display: 'flex',
    alignItems: 'center',
    '& .topContainer': {
      width: '30rem',
      marginLeft: '15vw',
      '& p': {
        marginTop: '2em',
        textAlign: 'justify',
      },
    },
  });

export const globalStructureBottom = theme =>
  css({
    minHeight: 230,
    backgroundColor: theme.background100,
    display: 'flex',
    padding: '0 4em',
    marginTop: '10em',
    '& .content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      background: `url(${bottomBackgroundImage}) no-repeat center right`,
    },
    '& .applyForCM': {
      color: theme.white,
      fontSize: '2rem',
    },
  });
