import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/UIExternal';
import Slider from 'react-slick';
import { useTheme } from 'emotion-theming';
import FeatherIcon from 'feather-icons-react';
import { isDesktop } from 'react-device-detect';
import { arrowStyles, wrapper, imgButton } from './styles';

const Arrow = ({ onClick, customClass, iconName, showBtn }) =>
  showBtn && (
    <Button type="link" small className={customClass} onClick={onClick}>
      <FeatherIcon icon={iconName} size={40} />
    </Button>
  );

const GalleryMaterials = ({ materialGallery, changeMainImage }) => {
  const theme = useTheme();
  const slidesPerPage = isDesktop ? 3 : 1;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesPerPage,
    slidesToScroll: slidesPerPage,
    arrows: isDesktop,
    nextArrow: (
      <Arrow
        customClass={`${arrowStyles(theme, 'right')}`}
        iconName="chevron-right"
        showBtn={materialGallery?.length > 6}
      />
    ),
    prevArrow: (
      <Arrow
        customClass={`${arrowStyles(theme, 'left')}`}
        iconName="chevron-left"
        showBtn={materialGallery?.length > 3}
      />
    ),
  };

  return (
    <div className={wrapper}>
      <Slider {...settings}>
        {materialGallery?.map((imgSrc, i) => (
          <Button
            type="default"
            small
            key={i}
            className={imgButton}
            onClick={() => changeMainImage(prev => ({ ...prev, imgReduced: imgSrc }))}>
            <img key={i} src={imgSrc} alt="" className="img" />
          </Button>
        ))}
      </Slider>
    </div>
  );
};

Arrow.propTypes = {
  onClick: PropTypes.func,
  customClass: PropTypes.any,
  iconName: PropTypes.string,
  showBtn: PropTypes.bool,
};

GalleryMaterials.propTypes = {
  changeMainImage: PropTypes.func,
  materialGallery: PropTypes.array,
};

export default GalleryMaterials;
