import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const overviewContainer = css({
  display: 'flex',
  gap: '2rem',
  flexWrap: 'wrap',
  [Mobile]: {
    flexDirection: 'column-reverse',
  },
});

export const overviewMerchantContent = css({
  flex: '2 1 0',
  '& .tab': {
    marginTop: '1.5em',
  },
  '& .deals-header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const overviewMerchantReviews = theme =>
  css({
    flex: '1 1 0',
    border: `1px solid ${theme.gray400}`,
    height: 'fit-content',
    borderRadius: 10,
    boxShadow: `4px 4px 20px 5px ${theme.gray100}20`,
    padding: '1.2em',
    marginTop: '2em',
    '& .reviews-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .rating': {
        textAlign: 'right',
        fontSize: 12,
      },
    },
    '& .reviews-item': {
      margin: '2em 0',
      '& .reviewer-name': {
        fontWeight: 600,
      },
      '& .review-date': {
        color: theme.gray300,
      },
      '& .rating': {
        '& > span': {
          marginLeft: '0.5em',
          fontSize: 12,
        },
      },
    },
  });

export const dealList = theme =>
  css({
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '1.5rem',
    margin: '2em 0',
    '.ant-rate > .ant-rate-star': {
      fontSize: '0.75rem',
      '&.ant-rate-star-full svg': {
        color: theme.gray100,
        stroke: 'none',
      },

      '&.ant-rate-star-zero svg': {
        color: 'transparent',
        stroke: theme.gray400,
        strokeWidth: '3rem',
      },
    },
  });

export const dealItem = css({
  width: 240,
});

export const noContent = css({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
});
