import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const box = (showFiltersError, theme) =>
  css({
    [Desktop]: {
      border: showFiltersError ? `1px solid ${theme.red}` : `2px solid ${theme.gray500}`,
      borderRadius: 3,
      padding: '1.5em 3.3125em 0.5em 1.75em',
    },
    '& .ant-form-item-control-input-content': {
      display: 'flex',
    },
    '& .ant-form-item-label .ant-form-item-no-colon': {
      fontWeight: 500,
      fontSize: '0.8125rem !important',
    },
    marginBottom: '1.4375em',
  });

export const radioGroup = theme =>
  css({
    '& .ant-radio-button-wrapper': {
      marginRight: '0.5em',
      textAlign: 'center',
      [Mobile]: { width: '100%', marginBottom: '0.5em' },
    },
    '& .ant-radio-button-wrapper-checked': {
      background: `${theme.skyblue} !important`,
      color: 'black !important',
    },
  });

export const addressesContainer = css({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  '& .address-cart': {
    marginRight: '0.75em',
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  [Desktop]: {
    flexWrap: 'wrap',
  },
  [Mobile]: {
    overflowX: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});
