import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Text, View, Svg, Path, G, Image, Rect, Polygon, Circle } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const color = {
  dark: {
    st0: '#3A6DB5',
    st1: '#FFFFFF',
    st2: '#FECF0C',
    st3: '#FECF09',
  },
  light: {
    st0: '#5AA4DA',
    st1: '#FFFFFF',
    st2: '#FECF09',
  },
};

const BoxStickerTemplate = ({ qrCode, businessInfo }) => {
  const SVGComponentDark = () => (
    <Svg x="0px" y="0px" viewBox="0 0 708.7 283.5" style={generalStyles.pageBackground}>
      <Rect id="XMLID_74_" x={-28.1} y={-28.3} fill={color.dark.st0} width={765.4} height={340.2} />
      <G id="XMLID_5_">
        <Path
          fill={color.dark.st1}
          d="M41.2,42.1c-1.2,2-3.8,2.8-6.2,2.3v-9.6c2.3-0.6,4.7,0,6,1.8c0.6,0.8,0.9,1.8,0.9,2.7   C42,40.3,41.7,41.3,41.2,42.1L41.2,42.1z M38.8,28.7c-3.1-0.4-6.3-0.2-9.4-0.2c-0.1,0-0.3,0.1-0.4,0.2v21.8c2.8,0,5.5,0,8.2,0   c5.6-0.1,10.4-4.5,10.9-9.9C48.5,34.8,44.6,29.5,38.8,28.7z"
        />
        <Polygon
          fill={color.dark.st1}
          points="48.7,75.4 48.7,53.9 54.2,53.9 54.2,61.6 61.6,61.6 61.6,53.9 67.3,53.9 67.3,75.4    61.6,75.4 61.6,67.7 54.4,67.7 54.4,75.4  "
        />
        <Polygon
          fill={color.dark.st1}
          points="54.8,34.5 54.8,36.5 59.3,36.5 59.3,42.6 54.8,42.7 54.8,44.6 65.2,44.6 65.2,50.4    48.7,50.4 48.7,28.6 65.2,28.6 65.2,34.5  "
        />
        <Polygon
          fill={color.dark.st1}
          points="128.8,75.6 112.4,75.6 112.4,53.9 128.7,53.9 128.7,59.5 118.3,59.5 118.3,61.8    122.9,61.8 122.9,67.6 118.4,67.6 118.4,69.9 128.8,69.9  "
        />
        <Path
          fill={color.dark.st1}
          d="M47.5,57.1c-1.4,1.1-4.2,3.5-4.2,3.5s-2.1-1.1-3.9-1.5c-1.1-0.2-3.3-0.5-4.1,0.7   c-0.7,1.2,1.1,1.8,1.9,2c1.1,0.2,2.4,0,3.5,0.2c4.5,0.8,7,3.5,6.6,7.5c-0.2,1.4-0.8,2.8-1.7,3.9c-3.9,4.3-12.6,3.2-16.5-1.5   l4.5-3.2c0,0,1.8,1.3,3.8,1.6c1.2,0.2,2.5,0.2,3.5-0.5c0.3-0.2,0.5-0.4,0.6-0.8c0.2-0.8-0.8-1.2-1.4-1.3c-1.1-0.2-2.2-0.3-3.3-0.4   c-4.1-0.4-6.9-2.7-7.5-6.1c-0.4-2,0.2-3.7,1.5-5.2C34.4,51.8,43.1,52.9,47.5,57.1z"
        />
        <Path
          fill={color.dark.st1}
          d="M111.2,72.3l-4.7,3.3l-7.2-8l-0.1,7.8h-5.6V53.9H99V62c0,0,5-5.5,7.4-8.2l4.6,3.5l-6.4,7.4   L111.2,72.3z"
        />
        <Path
          fill={color.dark.st2}
          d="M80.2,28.7c4.1,7.4,8.1,14.4,12.1,21.7h-6.5l-5.7-10c-2,3.5-3.8,6.8-5.7,10h-6.4L80.2,28.7z"
        />
        <Path fill={color.dark.st2} d="M80.2,65.4l-5.7,10h-6.4l12-21.6l12.1,21.7h-6.5C84,72.2,82.2,69,80.2,65.4z" />
        <Polygon fill={color.dark.st1} points="99.2,28.6 99.2,44.6 109.7,44.6 109.7,50.4 93.3,50.4 93.3,28.6  " />
        <Path
          fill={color.dark.st1}
          d="M142,62.5c-0.1,0.6-0.3,1.2-0.8,1.7c-0.4,0.5-1,0.7-1.7,0.8c-1.2,0.1-2.5,0-3.9,0v-5.4   c1.5,0,2.8-0.1,4.2,0C141.2,59.8,142.1,61.2,142,62.5z M145,68.9c0.5-0.7,0.9-1.1,1.2-1.6c3.5-4.8,1-12.1-4.9-13.1   c-3.7-0.6-7.6-0.1-11.6-0.1v21.6h5.8V71c0,0,3.1-0.4,4.6,1.1c1.7,1.7,3.5,3.3,5.4,5.2l4-4.2v-0.3L145,68.9z"
        />
      </G>
      <Path
        fill={color.dark.st3}
        d="M655.8,52.8c-11.9-11.9-18.7-28.1-18.7-45c0-12.7,3.8-25,10.8-35.4h-43.6  C600.2-16.4,598-4.5,598,7.7c0,27.3,10.8,53.4,30.1,72.7c19.3,19.3,45.4,30.1,72.7,30.1c12.8,0,25.4-2.4,37.1-6.9V59.5  c-10.7,7.7-23.7,11.9-37.1,11.9C684,71.5,667.8,64.7,655.8,52.8z"
      />
    </Svg>
  );

  const SVGComponentLight = () => (
    <Svg x="0px" y="0px" viewBox="0 0 425.2 708.7" style={s.pageBackground}>
      <Rect x={-28.3} y={-28.3} fill={color.light.st0} width={481.9} height={765.4} />
      <G>
        <G>
          <Circle fill={color.light.st1} cx={168.5} cy={362.1} r={24.7} />
          <Circle fill={color.light.st1} cx={256.7} cy={362.1} r={24.7} />
        </G>
        <Path
          fill={color.light.st2}
          d="M266.9,413v12.3c0,14.4-5.7,28.2-15.9,38.4c-10.2,10.2-24,15.9-38.4,15.9   c-14.4,0-28.2-5.7-38.4-15.9c-10.2-10.2-15.9-24-15.9-38.4V413H125l0,12.3c0,23.2,9.2,45.5,25.7,62c16.4,16.4,38.7,25.7,62,25.7   s45.5-9.2,62-25.7c16.4-16.4,25.7-38.7,25.7-62V413H266.9z"
        />
      </G>
    </Svg>
  );

  return (
    <Document>
      <Page size={[708.7, 283.5]}>
        <View>
          <SVGComponentDark />
          <View style={generalStyles.wrapper}>
            <View style={s.contentBox}>
              <Image source={qrCode} style={s.qrCodeStyles} />
              <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
              <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
              <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size={[425.2, 708.7]}>
        <View>
          <SVGComponentLight />
          <View style={s.wrapper}>
            <View style={s.contentBox}>
              <Image source={qrCode} style={s.qrCodeStylesSecondPage} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

BoxStickerTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default BoxStickerTemplate;
