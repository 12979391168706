import moment from 'moment';
import { getProperty, isFunction, startCase } from '@veraio/core';
import { validDate } from './valueFormatter';

export const splitArrayIntoChunksOfLen = (arr, len) => {
  const chunks = [],
    n = arr.length;
  let i = 0;
  while (i < n) chunks.push(arr.slice(i, (i += len)));

  return chunks;
};

export const toggleItemInArray = (array, item) => {
  const foundIndex = array.indexOf(item);
  if (foundIndex === -1) return [...array, item];
  return array.filter((e, i) => i !== foundIndex);
};

export const toggleObjectItemsInArray = (array, items, itemProp) =>
  items
    .map(item => item[itemProp])
    .reduce((accumulator, currentItem) => toggleItemInArray(accumulator, currentItem), array);

export const toggleItemsInArray = (array, items) =>
  items.reduce((accumulator, currentItem) => toggleItemInArray(accumulator, currentItem), array);

export const displayProperty = (config, data) => {
  const value = getProperty(data, config.value);
  const checkFlag = flag => config[flag] && value !== null;

  switch (true) {
    case checkFlag('bool'):
      return value ? 'Yes' : 'No';
    case checkFlag('date'):
      return validDate(value) ? moment(value).format('DD/MM/YYYY') : 'N/A';
    case checkFlag('validDate'):
      return validDate(value) ?? 'N/A';
    case checkFlag('dateTime'):
      return validDate(value) ? moment(value).format('DD/MM/YYYY, HH:mm:ss') : 'N/A';
    case checkFlag('time'):
      return validDate(value) ? moment(value).format('HH:mm:ss') : 'N/A';
    case checkFlag('startCase'):
      return startCase(value) || 'N/A';
    case isFunction(config.render):
      return config.render(data);
    default:
      return value ?? 'N/A';
  }
};
