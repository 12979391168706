import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#19285E',
  },
  leftSection: {
    flexBasis: '55%',
    padding: '50px 30px',
    color: '#FFFFFF',
  },
  rightSection: {
    flexBasis: '45%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#E0E0E0',
  },
  header: {
    borderBottom: '1px solid #F4F5F7',
    paddingBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    width: 100,
    height: 'auto',
    alignItems: 'flex-start',
  },
  brandLink: {
    alignItems: 'flex-end',
  },
  middle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: 'auto 0',
  },
  businessDetails: {
    flexBasis: '70%',
  },
  businessName: {
    fontSize: 20,
    fontWeight: 900,
    marginBottom: 12,
  },
  businessDescription: {
    fontSize: 13,
    color: '#C5C8CC',
    marginBottom: 32,
  },
  promoCode: {
    backgroundColor: '#FFFFFF',
    color: '#19285E',
    fontSize: 16,
    fontWeight: 900,
    padding: 5,
    textAlign: 'center',
    borderRadius: 5,
    marginBottom: 16,
    alignSelf: 'flex-start',
  },
  qr: {
    width: 110,
    height: 'auto',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dateLabel: {
    fontSize: 16,
    marginBottom: 10,
  },
  date: {
    fontSize: 13,
    color: '#C5C8CC',
  },
  discount: {
    fontSize: 20,
    fontWeight: 900,
    textAlign: 'right',
  },
  imageRight: {
    width: '100%',
    height: 'auto',
  },
});
