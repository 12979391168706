import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from 'emotion-theming';
import { getText } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { MerchantAddressMap } from 'components/shared';
import { Button, Modal } from 'components/UIExternal';
import useError from 'services/errorHandling/useError';
import { seeMeOnMap, modalContainer } from './styles';

export const SeeOnMapAddresses = ({ merchantAddressesList }) => {
  const theme = useTheme();
  const { setError } = useError();
  const [userCoords, setUserCoords] = useState(null);
  const [mapRef, setMapRef] = useState();
  const modalRef = useRef();

  useEffect(() => {
    mapRef?._containerId &&
      mapRef.flyToBounds(
        merchantAddressesList.map(el => [el.latitude, el.longitude]),
        { animate: true, duration: 1.4 },
      );
  }, [mapRef?._containerId]);

  const handleOpenModal = () => {
    modalRef.current?.open();
    getUserCoordinates();
  };

  const getUserCoordinates = () =>
    new Promise(resolve => {
      navigator.geolocation.getCurrentPosition(
        event => {
          const coords = { lat: event.coords.latitude, lng: event.coords.longitude };
          setUserCoords(coords);
          resolve(coords);
        },
        error => {
          resolve();
          setUserCoords(undefined);
          setError(error);
        },
      );
    });

  return (
    <>
      <Button small type="link" onClick={handleOpenModal} className={seeMeOnMap(theme)}>
        <FeatherIcon icon="map" size={20} stroke={theme.gray100} />
        <span>{getText('seeOnMap')}</span>
      </Button>
      <Modal large ref={modalRef} className={modalContainer}>
        <MerchantAddressMap ref={setMapRef} merchantAddressesList={merchantAddressesList} userPosition={userCoords} />
      </Modal>
    </>
  );
};
