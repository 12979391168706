import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const mainContainer = css({
  padding: '8.125em 1.25em 9.375em 1.25em',
  '& h2': {
    fontSize: '2.375rem',
    marginTop: '3.75em',
    textAlign: 'center',
  },
  '& img': {
    [Mobile]: { width: '100%' },
  },
  '& p': {
    fontSize: '1.125rem',
    marginBottom: '2.5em',
    textAlign: 'center',
    [Desktop]: {
      width: '48%',
    },
  },
});
