import React, { useEffect } from 'react';
import { softLogout } from '@oneecosystem/authenticate';

const Logout = () => {
  useEffect(() => {
    softLogout();
  }, []);

  return (
    <div id="splash-screen" className="kt-splash-screen">
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  );
};

export default Logout;
