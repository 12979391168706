import { css } from 'emotion';

export const container = css({
  background: '#FFF',
  boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.08)',
  width: '34.9375em',
  margin: '3.125em auto 3.125em',
  padding: '0.75em',
  display: 'flex',
  flexDirection: 'column',
});

export const header = theme =>
  css({
    background: theme.ui_hover,
    color: theme.gray100,
    fontWeight: 700,
    boxShadow: 'inset 0px -1px 0px #DCDDE0',
    padding: '0.75em 0 0.75em 0.75em',
    marginBottom: '0.875em',
    fontSize: '1.625rem',
  });

export const valueBlock = theme =>
  css({
    borderRadius: '2px',
    display: 'flex',
    fontSize: '0.75rem',
    color: theme.gray200,
    flexDirection: 'column',
    marginBottom: '0.875em',
    '& .ant-select-single .ant-select-selector .ant-select-selection-item': {
      lineHeight: 3,
    },
    '& .ant-select': {
      height: '3rem',
    },
    '& .ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
      height: '3rem',
    },
  });

export const discount = css({
  borderRadius: 2,
  marginBottom: '2em',

  '& .ant-input-number-prefix': {
    fontWeight: 400,
  },

  '& .ant-input-number-affix-wrapper': {
    height: '3rem',
    width: '100%',
    fontSize: '1rem',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
});

export const titleLabel = css({
  fontSize: '0.8125rem',
  fontWeight: 500,
});

export const validateError = theme =>
  css({
    color: theme.red,
  });
