import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslations } from '@veraio/strank';

const useError = () => {
  const { getText } = useTranslations();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      const errors = error?.data?.errors || null;
      if (errors) errors.forEach(errorsKey => toast.error(getText(errorsKey.code)));
      else toast.error(getText('defaultErrorMessage'));
    }
  }, [error]);

  return { defaultErrorMessage: getText('defaultErrorMessage'), setError };
};

export default useError;
