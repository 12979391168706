import { css, keyframes } from 'emotion';

/* Animations */
const cartLinesAnimation = keyframes`
from, to {
  opacity: 0;
}
8%, 92% {
  opacity: 1;
}
`;

const cartTopAnimation = keyframes`
from {
  stroke-dashoffset: -338;
}
50% {
  stroke-dashoffset: 0;
}
to { 
  stroke-dashoffset: 338;
}
`;

const cartWheel1Animation = keyframes`
from { 
  transform: rotate(-0.25turn);
}
to {
  transform: rotate(2.75turn);
}
`;

const cartWheel2Animation = keyframes`
from {
  transform: rotate(0.25turn);
}
to {
  transform: rotate(3.25turn);
}
`;

const cartWheelStrokeAnimation = keyframes`
from, to {
  stroke-dashoffset: 81.68;
}
50% {
  stroke-dashoffset: 40.84;
},
`;

const ellipsisAnimation = keyframes`
  0%, 100% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
`;

// shopping cart styles
export const cartStyles = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& .preloader__msg': {
      position: 'relative',
      '&::after': {
        position: 'absolute',
        left: '100%',
        content: '""',
        display: 'inline-block',
        paddingLeft: '0.2em',
        animation: `${ellipsisAnimation} 1.5s infinite steps(3)`,
      },
    },
    '& .cart': {
      display: 'block',
      margin: '0 auto 1.5em auto',
      width: '11rem',
      height: '11rem',
    },
    '& .cart__lines, .cart__top, .cart__wheel1, .cart__wheel2, .cart__wheel-stroke': {
      animation: `${cartLinesAnimation} 2s ease-in-out infinite`,
    },
    '& .cart__lines': {
      stroke: theme.blue,
    },
    '& .cart__top': {
      animationName: `${cartTopAnimation}`,
    },
    '& .cart__wheel1': {
      animationName: `${cartWheel1Animation}`,
      transform: 'rotate(-0.25turn)',
      transformOrigin: '43px 111px',
    },
    '& .cart__wheel2': {
      animationName: `${cartWheel2Animation}`,
      transform: 'rotate(0.25turn)',
      transformOrigin: '102px 111px',
    },
    '& .cart__wheel-stroke': {
      animationName: `${cartWheelStrokeAnimation}`,
    },
    '& .cart__track': {
      stroke: theme.gray500,
      transition: 'stroke 0.4s',
    },
  });
