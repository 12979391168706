import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { currentPositionPin } from './markerIcons';
import { mapContainer } from './styles';
import './mapSmoothWheelZoom';

export const Map = forwardRef(
  ({ children, userPosition, center, zoom, position, smoothSensitivity, className }, ref) => {
    const theme = useTheme();

    return (
      <MapContainer
        ref={ref}
        smoothWheelZoom
        scrollWheelZoom={false}
        center={center ?? { lat: 0, lng: 0 }}
        zoom={zoom ?? 4}
        attributionControl={false}
        position={position ?? 'topright'}
        smoothSensitivity={smoothSensitivity ?? 1}
        className={mapContainer(className, theme)}
      >
        {/* map background layer */}
        {/* must be minZoom <= zoom */}
        <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" minZoom={4} />
        {/* map user current position marker */}
        {!!userPosition && <Marker position={userPosition} icon={currentPositionPin} zIndexOffset={100} />}
        {children}
      </MapContainer>
    );
  },
);

Map.propTypes = {
  children: PropTypes.any,
  userPosition: PropTypes.object,
  center: PropTypes.object,
  zoom: PropTypes.number,
  position: PropTypes.string,
  smoothSensitivity: PropTypes.number,
  className: PropTypes.object,
};

export * from './markerIcons';
