import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { useDeepEffect } from 'components/UIExternal';
import { countDownTimerContainer } from './styles';

const DEFAULT_REMAINING_TIME = { hours: 0, minutes: 0, seconds: 0 };

const CountdownTimer = ({ endDateTime, startDateTime }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const [remainingTime, setRemainingTime] = useState(DEFAULT_REMAINING_TIME);

  useDeepEffect(() => {
    const interval = setInterval(() => {
      calculateRemainingTime(interval);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endDateTime]);

  const calculateRemainingTime = interval => {
    const startTime = startDateTime ?? moment.utc();
    const endTime = endDateTime ?? moment.utc().endOf('day');
    const duration = moment.duration(endTime.diff(startTime));

    if (duration.asSeconds() < 0) {
      setRemainingTime(DEFAULT_REMAINING_TIME);
      return clearInterval(interval);
    }

    setRemainingTime({ hours: duration?.asHours(), minutes: duration?.minutes(), seconds: duration?.seconds() });
  };

  const hours = `0${parseInt(remainingTime.hours, 10)}`.slice(-2);
  const minutes = `0${remainingTime.minutes}`.slice(-2);
  const seconds = `0${remainingTime.seconds}`.slice(-2);

  return (
    <div className={countDownTimerContainer(theme)}>
      <label className="countdown-label">{getText('endsIn')}</label>
      <span className="countdown-timer">{`${hours}:${minutes}:${seconds}`}</span>
    </div>
  );
};

CountdownTimer.propTypes = {
  endDateTime: PropTypes.instanceOf(moment),
  startDateTime: PropTypes.instanceOf(moment),
};

export default CountdownTimer;
