import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Text, View, Svg, Path, G, Rect, Polygon } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const color = {
  st0: '#3A6DB5',
  st1: '#FFFFFF',
  st2: '#FECF0C',
  st3: '#FECF09',
};

const OutdoorStoreSignTemplate = ({ businessInfo }) => {
  const SVGComponent = () => (
    <Svg x="0px" y="0px" viewBox="0 0 7653.5 2834.6" style={{ position: 'absolute', top: 0 }}>
      <Rect x={0} y={0} fill={color.st0} width={7653.5} height={2834.7} />
      <G>
        <Path
          fill={color.st1}
          d="M669.2,535.8c-9.7,15.8-30.5,22.2-50,18.3v-77c18.7-5.1,37.5-0.1,48.1,14.4   c4.5,6.4,7.1,14,7.4,21.9S673.2,529.1,669.2,535.8L669.2,535.8z M649.6,428.7c-24.7-3.4-50.1-1.4-75.2-1.6c-1,0-2,1.2-3.3,2v174.2   c22.4,0,43.9,0.3,65.3-0.1c45.1-0.8,83.2-35.7,86.9-79C727.2,477.2,696.1,435,649.6,428.7z"
        />
        <Polygon
          fill={color.st1}
          points="728.7,802.3 728.7,630.3 773.5,630.3 773.5,691.5 832.1,691.5 832.1,630.5    877.9,630.5 877.9,802.4 832.7,802.4 832.7,740.8 774.4,740.8 774.4,802.3  "
        />
        <Polygon
          fill={color.st1}
          points="777.5,474.8 777.5,491.2 814.2,491.2 814.2,540 777.6,540.3 777.6,555.8 861,555.8    861,602.3 728.7,602.3 728.7,427.6 860.9,427.6 860.9,474.8  "
        />
        <Polygon
          fill={color.st1}
          points="1370.1,803.6 1239,803.6 1239,630 1369.7,630 1369.7,674.8 1286.5,674.8 1286.5,693.5    1322.8,693.5 1322.8,739.5 1286.8,739.5 1286.8,757.8 1370.1,757.8  "
        />
        <Path
          fill={color.st1}
          d="M719.3,655.4c-11.4,8.7-34,28.3-34,28.3s-17-8.6-31.1-11.6c-9-1.9-26.7-4.1-32.5,5.5   c-5.9,9.9,8.7,14.6,15.3,15.6c8.7,1.4,19.4,0.2,28,1.7c36.4,6.3,56.2,27.7,52.7,60.2c-1.4,11.5-6.2,22.3-13.8,31   c-31.3,34.2-100.9,25.5-131.7-12.3l35.7-25.3c0,0,14.7,10.6,30.3,12.8c9.3,1.3,20,1.4,28.3-3.6c2.4-1.3,4.2-3.6,5-6.2   c1.5-6.3-6.1-9.9-11.2-10.7c-8.8-1.4-17.7-2.1-26.6-3c-32.6-3.4-55.3-22-60.2-49.1c-2.9-15.7,1.4-29.6,11.6-41.5   C614.8,613,684.6,622.4,719.3,655.4z"
        />
        <Path
          fill={color.st1}
          d="M1229.3,777.7l-38,26.2l-58-63.9l-0.9,62.4h-45.1V630.4h44.6v64.9c0,0,40.1-44.2,59.4-66   l36.5,27.7l-50.9,59.4L1229.3,777.7z"
        />
        <Path
          fill={color.st2}
          d="M980.9,428c33.1,59.1,64.6,115.5,97.1,173.6h-51.8L981,521.3c-16,28.3-30.7,54.3-45.5,80.4   h-50.9L980.9,428z"
        />
        <Path
          fill={color.st2}
          d="M980.9,722.2l-45.3,80h-50.9l96.2-173.3l97.1,173.4h-51.8C1011.8,776.7,997,750.6,980.9,722.2z   "
        />
        <Polygon
          fill={color.st1}
          points="1133.2,427.7 1133.2,555.6 1217.4,555.6 1217.4,602.2 1085.8,602.2 1085.8,427.7  "
        />
        <Path
          fill={color.st1}
          d="M1475.9,699.2c-0.4,5.1-2.6,9.8-6.2,13.4c-3.6,3.6-8.4,5.9-13.5,6.3c-9.9,0.7-19.9,0.2-30.9,0.2   v-43.2c11.8,0,22.7-1.1,33.3,0.3C1469.4,677.6,1476.8,688.8,1475.9,699.2z M1500.1,750.1c4.1-5.3,7.2-9,10-12.8   c27.9-38.7,7.7-97.2-39.2-105.2c-29.8-5-61.2-0.9-92.7-0.9v172.6h46.2v-36.9c0,0,24.6-2.9,37.2,9.1c13.9,13.2,27.7,26.7,43.4,41.7   l31.8-33.7v-2.4L1500.1,750.1z"
        />
      </G>
      <Path
        fill={color.st3}
        d="M7653.5,0L7653.5,0c-475.8,0-932.2,189-1268.6,525.5c-336.5,336.5-525.5,792.8-525.5,1268.6  v1040.6h682.5V1794.1c0-294.7,117.1-577.4,325.5-785.8c208.4-208.4,491.1-325.5,785.8-325.5c0.1,0,0.2,0,0.4,0V0z"
      />
    </Svg>
  );

  return (
    <Document>
      <Page size={[7653.5, 2834.6]}>
        <View>
          <SVGComponent />
          <View style={generalStyles.wrapper}>
            <View style={s.contentBox}>
              <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
              <View style={s.row}>
                <Text style={s.textBox.location}>{businessInfo?.country?.currentValue} </Text>
                <Text style={s.textBox.location}> / {businessInfo?.street?.currentValue}</Text>
                <Text style={s.textBox.location}> / {businessInfo?.email?.currentValue}</Text>
                <Text style={s.textBox.location}> / tel: +{businessInfo?.phone?.currentValue}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

OutdoorStoreSignTemplate.propTypes = {
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  qrCode: PropTypes.string,
};

export default OutdoorStoreSignTemplate;
