import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isString } from '@veraio/core';
import { colorTagContainer, TAG_TYPE, backgroundColorMap } from './styles';

export { TAG_TYPE, backgroundColorMap };

export const Tag = props => {
  const theme = useTheme();
  const { children, className } = props;

  return <span className={`${colorTagContainer(props)(theme)} ${isString(className) && className} `}>{children}</span>;
};

Tag.propTypes = {
  type: PropTypes.oneOf(Object.values(TAG_TYPE)),
  children: PropTypes.any,
  className: PropTypes.string,
};
