import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const categoriesFilterContent = css({
  [Mobile]: {
    width: '100%',
  },
  width: '50%',
  minHeight: '21.25rem',
});
export const secondLevel = menuTop =>
  css({
    zIndex: '1 !important',
    top: `${menuTop + 2}px !important`,
    maxHeight: '20.625rem !important',
    maxWidth: '13.75rem !important',
    overflow: 'hidden',
    '& .ant-menu.ant-menu-sub.ant-menu-vertical': {
      maxHeight: '20.625rem',
    },
    '& .ant-menu-sub': {
      boxShadow: 'none',
    },
  });
