import { Mobile } from 'assets/css/breakpoints';
import { css } from 'emotion';

export const businessCartStyles = theme =>
  css({
    backgroundColor: 'transparent',
    border: `1px solid ${theme.gray500}`,
    marginBottom: 24,
  });

export const confirmContentContainer = css({
  padding: '3rem 9rem',
  '& h3': {
    margin: '0 0 1rem 0rem',
  },
});

export const submittedOrderWrapper = theme =>
  css({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.125em 2.5em',
    marginBottom: '1.5em',
    marginTop: '1.5em',
    '& h2': {
      fontSize: '1.4375rem',
      fontWeight: 700,
      marginBottom: '0.5em',
      [Mobile]: {
        textAlign: 'center',
      },
    },
    '& label': {
      fontSize: '1rem',
      fontWeight: 500,
      color: theme.gray200,
      textAlign: 'center',
    },
  });
