import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const disputeTitleWrapper = theme =>
  css({
    marginBottom: '3.125em',
    '& h2': {
      fontWeight: 500,
      fontSize: '2.375rem',
      color: theme.background100,
      marginBottom: '0.3125em',
    },
    '& p': {
      fontSize: '1rem',
    },
    [Mobile]: {
      marginBottom: '2.1875em',
    },
  });

export const titleForm = theme =>
  css({
    fontWeight: 500,
    fontSize: '2.375rem',
    color: theme.background100,
    marginBottom: '3em',
    [Mobile]: {
      fontSize: '1.375rem',
      marginBottom: '1.375em',
    },
  });

export const reviewFormWrapper = theme =>
  css({
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      padding: '1.375em 1.75em',
    },
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: '0.8125em !important',
      fontWeight: 500,
    },
    '& .ant-input::placeholder, .ant-select-selection-placeholder': {
      fontSize: '0.75rem',
    },
    '& .ant-form-item-label': {
      paddingBottom: '0 !important',
    },
  });

export const disputedReviewWrapper = theme =>
  css({
    marginTop: '1.4375em',
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      padding: '1.375em 1.75em',
    },
  });

export const starRatings = theme =>
  css({
    '&.ant-rate': {
      fontSize: '1.9375rem',
      lineHeight: '1em',
      color: theme.yellow,
      marginRight: '0.625em',
      marginTop: '0.6875em',
    },
  });

export const starsWrapper = theme =>
  css({
    '& label': {
      alignSelf: 'center',
      marginTop: '0.5em',
      fontSize: '0.875rem !important',
      color: theme.gray300,
    },
    [Mobile]: {
      '& .ant-row.ant-form-item': {
        flexDirection: 'row',
      },
    },
  });

export const wrapperInput = css({
  width: '85%',
  [Mobile]: { width: '100%' },
});

export const uploadItem = css({ marginBottom: 0 });

export const imageUploadDragDrop = css({ height: '9.25rem !important' });

export const imagesPreview = css({
  '& .ant-upload-list-item.ant-upload-list-item-done': {
    padding: '0 ',
    border: 'none',
  },
  '& .ant-upload-list-item-actions a': { display: 'none' },
  '& .ant-upload-list-item-image': {
    objectFit: 'cover !important',
  },
  '& .ant-upload-list-item-info': {
    borderRadius: 3,
  },
  '& .ant-upload-list-picture-card-container': {
    borderRadius: '4px !important',
    [Desktop]: { width: '4.8125rem', height: '4.25rem' },
    [Mobile]: { width: '6.25rem', height: '5rem' },
  },
});

export const formButtons = css({
  marginTop: '1.4375em',
  marginBottom: '4.875em',
  display: 'flex !important',
  justifyContent: 'space-between',
  '& span': {
    margin: 'auto',
  },
  [Mobile]: {
    flexDirection: 'column-reverse',
    marginBottom: '3em',
  },
});

export const blackTextLink = theme =>
  css({
    color: theme.gray100,
  });

export const disputedSubmittedWrapper = theme =>
  css({
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5.0625em',
    marginBottom: '4.75em',
    padding: '3.75em 4.375em',
    '& .icon-Tick': {
      borderRadius: '25px',
      fontSize: '1.75rem',
      display: 'flex',
      marginRight: '0.75em',
      width: '3.6875rem',
      height: '3.6875rem',
      backgroundColor: theme.green2,
      color: theme.white,
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& label': {
      display: 'inline-block',
      fontSize: '1.4375rem',
      fontWeight: 700,
      marginTop: '1em',
      marginBottom: '0.5em',
    },
    '& p': {
      fontSize: '1rem',
      color: theme.gray200,
      marginBottom: '1.875em',
    },
    [Desktop]: {
      background: '#E6EDF273',
    },
    [Mobile]: {
      marginTop: '0.625em',
      marginBottom: '3.125em',
    },
  });
