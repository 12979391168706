import { css } from 'emotion';

export const brandGuideMaterialPageContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  '& .brandGuideExamplePageImg': { width: '50%' },
});

export const brandGuideLineExamplePageTextContainer = theme =>
  css({
    flex: '1 0 0',
    '& .blueText, .yellowText, .purpleText, .blackText': {
      fontSize: '3.1rem',
      lineHeight: 1.2,
      marginBottom: '1em',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    '& .blueText': { color: theme.blue },
    '& .yellowText': { color: theme.primary_yellow },
    '& .purpleText': { color: theme.purple },
    '& .blackText': { color: theme.gray100 },
  });
