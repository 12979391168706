import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const steps = theme =>
  css({
    [Mobile]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& label': {
      alignItems: 'center',
      display: 'flex',
      fontSize: '0.75rem',
      marginLeft: '0.5em',
      color: theme.gray200,
    },
    '& .step': {
      cursor: 'pointer',
    },
    '& div': {
      display: 'flex',
      marginBottom: '1.5em',
      [Mobile]: { marginRight: '0.9375em' },
    },
    '& p': {
      width: '1.5rem',
      height: '1.5rem',
      textAlign: 'center',
      fontSize: '0.75rem',
      fontWeight: 500,
      verticalAlign: 'middle',
      paddingTop: '0.1875em',
      background: theme.gray550,
      borderRadius: '3px',
      marginBottom: 0,
    },
    '& .current p': { color: theme.white, background: theme.primary_blue },
    '& .current label': { color: theme.gray100 },
    '& .finished p': { color: theme.white, background: theme.background100 },
  });

export const savedToDraftWrapper = css({
  padding: '1.0625em',
  '& label': {
    fontSize: '1rem',
    marginTop: '0.25em',
  },
  '& p': {
    fontSize: '0.6875rem',
    marginTop: 0,
    marginBottom: 0,
  },
});
