import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Text, View, Svg, Path, G, Image, Rect, Polygon } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const color = {
  st0: '#3A6DB5',
  st1: '#3469B3',
  st2: '#FFFFFF',
  st3: '#FECF0C',
  st4: '#FECF09',
};

const StoreSignStreetTemplate = ({ qrCode, businessInfo }) => {
  const SVGComponent = () => (
    <Svg x="0px" y="0px" viewBox="0 0 1417.3 1984.3" style={generalStyles.pageBackground}>
      <Rect x={-70.9} y={-70.9} fill={color.st0} width={1559.1} height={2126} />
      <G>
        <Path
          fill={color.st1}
          d="M510.9,0c175.4,313.4,342.7,612.3,514.8,920.3H751.3L511.1,494.2   c-84.9,150-163,287.9-241.3,426.4H0L510.9,0z"
        />
        <Path
          fill={color.st1}
          d="M510.9,1559.5l-240.2,424.2H0.9l509.8-918.8l514.9,919.3H751.1   C674.3,1848.2,596.3,1709.8,510.9,1559.5z"
        />
      </G>
      <G>
        <Path
          fill={color.st2}
          d="M195,196.9c-5.1,8.4-16.2,11.8-26.5,9.7v-40.8c9.9-2.7,19.9,0,25.5,7.7   c2.4,3.4,3.8,7.4,3.9,11.6C198.2,189.2,197.2,193.3,195,196.9L195,196.9z M184.6,140.1c-13.1-1.8-26.6-0.7-39.9-0.8   c-0.5,0-1.1,0.6-1.8,1v92.4c11.9,0,23.3,0.2,34.6,0c23.9-0.4,44.1-18.9,46.1-41.9C225.8,165.8,209.3,143.4,184.6,140.1z"
        />
        <Polygon
          fill={color.st2}
          points="226.6,338.1 226.6,246.9 250.4,246.9 250.4,279.4 281.4,279.4 281.4,247 305.7,247    305.7,338.2 281.8,338.2 281.8,305.6 250.8,305.6 250.8,338.1  "
        />
        <Polygon
          fill={color.st2}
          points="252.5,164.5 252.5,173.2 271.9,173.2 271.9,199.1 252.5,199.3 252.5,207.4    296.8,207.4 296.8,232.1 226.6,232.1 226.6,139.5 296.7,139.5 296.7,164.5  "
        />
        <Polygon
          fill={color.st2}
          points="566.7,338.8 497.2,338.8 497.2,246.8 566.5,246.8 566.5,270.6 522.3,270.6    522.3,280.5 541.6,280.5 541.6,304.9 522.5,304.9 522.5,314.5 566.7,314.5  "
        />
        <Path
          fill={color.st2}
          d="M221.6,260.3c-6,4.6-18,15-18,15s-9-4.6-16.5-6.2c-4.7-1-14.2-2.2-17.2,2.9   c-3.1,5.2,4.6,7.7,8.1,8.3c4.6,0.7,10.3,0.1,14.9,0.9c19.3,3.3,29.8,14.7,28,31.9c-0.7,6.1-3.3,11.8-7.3,16.5   c-16.6,18.1-53.5,13.5-69.8-6.5l18.9-13.4c0,0,7.8,5.6,16.1,6.8c4.9,0.7,10.6,0.7,15-1.9c1.3-0.7,2.2-1.9,2.6-3.3   c0.8-3.4-3.2-5.2-5.9-5.7c-4.7-0.8-9.4-1.1-14.1-1.6c-17.3-1.8-29.3-11.6-31.9-26c-1.5-8.3,0.7-15.7,6.2-22   C166.2,237.8,203.2,242.7,221.6,260.3z"
        />
        <Path
          fill={color.st2}
          d="M492,325.1L471.9,339l-30.7-33.9l-0.5,33.1h-23.9V247h23.7v34.4c0,0,21.3-23.4,31.5-35   l19.4,14.7l-27,31.5L492,325.1z"
        />
        <Path
          fill={color.st3}
          d="M360.3,139.7c17.5,31.3,34.3,61.2,51.5,92h-27.4l-24-42.6c-8.5,15-16.3,28.8-24.1,42.7h-27   L360.3,139.7z"
        />
        <Path
          fill={color.st3}
          d="M360.3,295.7l-24,42.4h-27l51-91.9l51.5,92h-27.5C376.7,324.6,368.9,310.7,360.3,295.7z"
        />
        <Polygon fill={color.st2} points="441.1,139.5 441.1,207.3 485.7,207.3 485.7,232.1 415.9,232.1 415.9,139.5  " />
        <Path
          fill={color.st2}
          d="M622.8,283.5c-0.2,2.7-1.4,5.2-3.3,7.1c-1.9,1.9-4.4,3.1-7.1,3.4c-5.3,0.4-10.6,0.1-16.4,0.1   v-22.9c6.2,0,12-0.6,17.7,0.2C619.3,272,623.3,278,622.8,283.5z M635.6,310.5c2.2-2.8,3.8-4.8,5.3-6.8   c14.8-20.5,4.1-51.6-20.8-55.8c-15.8-2.7-32.4-0.5-49.1-0.5v91.5h24.5v-19.6c0,0,13-1.5,19.7,4.8c7.4,7,14.7,14.2,23,22.1   l16.9-17.9v-1.3L635.6,310.5z"
        />
      </G>
      <Path
        fill={color.st4}
        d="M1251.2,317.3c-85.9-83.8-135-198.3-136.5-318.3c-0.3-23.5,1.3-46.9,4.5-69.9H839.2  c-2.1,24.2-3.1,48.7-2.8,73.2c2.4,193.8,81.7,378.6,220.4,513.9c117.6,114.7,269.7,185.8,431.3,203.3V438.9  C1399.4,423.2,1316.6,381.2,1251.2,317.3z"
      />
    </Svg>
  );

  return (
    <Document>
      <Page size={[1417.3, 1984.3]}>
        <View>
          <SVGComponent />
          <View style={generalStyles.wrapper}>
            <View style={s.contentBox}>
              <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
              <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
              <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
              <Image source={qrCode} style={s.qrCodeStyles} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

StoreSignStreetTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default StoreSignStreetTemplate;
