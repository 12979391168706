import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import { Modal, Spin, Tabs } from 'antd';
import { useTranslations } from '@veraio/strank';
import { useLocations, setShippingFrom, setShippingTo } from '@oneecosystem/dealshaker-core';
import ContinentsTab from './components/ContinentsTab';
import { popup, tabs, popupTitle } from './styles';

// eslint-disable-next-line import/no-mutable-exports
let locationsModalRef = {};

const LocationPopup = () => {
  const theme = useTheme();
  const [openedTab, setOpenedTab] = useState(null);
  const { getText } = useTranslations();
  const { continents, shippingFrom, shippingTo } = useLocations();

  useEffect(() => {
    locationsModalRef = { ...modalRef };
  }, []);

  const openModal = tab => setOpenedTab(tab === '1' || tab === '2' ? tab : '1');

  const closeModal = () => setOpenedTab(null);

  const modalRef = { open: openModal, close: closeModal };

  return (
    <Modal
      width={653}
      zIndex={1200}
      open={!!openedTab}
      onCancel={closeModal}
      destroyOnClose
      wrapClassName={popup}
      footer={null}
    >
      <h3 className={popupTitle(theme)}>{getText('locationSettings')}</h3>
      {continents?.length ? (
        <Tabs destroyInactiveTabPane className={tabs(theme)} onChange={setOpenedTab} activeKey={openedTab}>
          <Tabs.TabPane closable={false} key="1" tab={getText('dealsFrom')}>
            <ContinentsTab countries={shippingFrom} setCountries={setShippingFrom} />
          </Tabs.TabPane>
          <Tabs.TabPane closable={false} key="2" tab={getText('shippingTo')}>
            <ContinentsTab countries={shippingTo} setCountries={setShippingTo} />
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <div className="content-container">
          <Spin size="large" />
        </div>
      )}
    </Modal>
  );
};

export default LocationPopup;
export { locationsModalRef };
