import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { InputNumber } from 'antd';
import { Button, Col, Row, Image } from 'components/UIExternal';
import { DealPrice } from 'components/shared';
import { deliveryMethodSelect } from 'enums';
import { formatDefaultDate } from 'utils';
import placeholderThumbnail from 'assets/images/placeholderThumbnail.svg';
import { mr } from 'assets/css/globalCSS';
import {
  contentRowContainer,
  dealName,
  dealContentContainer,
  dealEndDateWrapper,
  thumbnailContainer,
  rowStyles,
  priceTemplateStyles,
} from './styles';

const MiniShoppingCartDealItem = ({ deal, onRemove, onQuantityChange }) => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { name, title, media, maximumCouponsPerCustomer, dealEndDate, deliveryMethodId } = deal;

  const handleQuantityChange = quantity => quantity > 0 && onQuantityChange({ ...deal, quantity });

  const handleValueOverMaxCoupons = (deal, value) =>
    value?.length ? (value > deal?.maximumCouponsPerCustomer ? deal?.maximumCouponsPerCustomer : value) : 1;

  return (
    <div className={contentRowContainer(theme)}>
      <Row gap={6} className={rowStyles}>
        <Col lg={4} md={4} sm={4} xs={4}>
          <Image img src={media ? media.url : placeholderThumbnail} alt="Deal" className={thumbnailContainer} />
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
          <Link to={`/deal/${name}`}>
            <p className={dealName(16)}>{getDynamicTranslation(title ?? deal.dealDetails)?.title}</p>
          </Link>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <Button type="link" small onClick={() => onRemove(deal.dealId ?? deal.id)}>
            <FeatherIcon icon="x" size={12} />
          </Button>
        </Col>

        <Col lg={4} md={4} sm={4} xs={4}>
          <InputNumber
            min={1}
            max={deal?.maximumCouponsPerCustomer}
            value={deal.quantity}
            onChange={value => handleQuantityChange(value)}
            disabled={deal?.maximumCouponsPerCustomer === 1}
            parser={value => handleValueOverMaxCoupons(deal, value)}
            addonBefore={<span className="icon-Coupons" />}
          />
        </Col>

        <Col lg={8} md={8} sm={8} xs={8} className={priceTemplateStyles}>
          <DealPrice deal={deal} dataId="mini-cart-deal-price-panel" />
        </Col>

        <Col lg={12} md={12} sm={12} xs={12}>
          <div className={dealContentContainer}>
            {maximumCouponsPerCustomer === 1 && <p>{getText('onlyOneDealAvailable')}</p>}
            <div className={dealEndDateWrapper}>
              <span className="icon-Ends-on" />
              <label>{getText('endsOn')} </label>
              <label className={`${mr({ lg: 12 })} bold-text`}>{formatDefaultDate(dealEndDate)}</label>
              <span className={`delivery-icon ${deliveryMethodSelect[deliveryMethodId]?.icon}`} />
              <label>{getText(deliveryMethodSelect[deliveryMethodId]?.label)}</label>
              <span className="icon-QuestionMark-dotted" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

MiniShoppingCartDealItem.propTypes = {
  deal: PropTypes.object,
  onRemove: PropTypes.func,
  onQuantityChange: PropTypes.func,
};

export default MiniShoppingCartDealItem;
