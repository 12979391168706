import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const search = css({
  border: 'none !important',
});
export const searchResultWrapper = css({
  borderRadius: '10px !important',
});
export const noMessagesBox = css({
  textAlign: 'center',
  '& .date': { fontSize: '0.75rem' },
  '& img': { marginTop: '4.375em' },
  '& .start': { fontSize: '1.125rem', fontWeight: 700, marginBottom: 0 },
  '& .ask-anything': { fontSize: '0.875rem', marginBottom: '3.75em' },
});
export const uploadBtn = theme =>
  css({
    paddingLeft: '0.625em',
    borderRadius: 3,
    padding: '0.625em',
    color: theme.gray200,
    backgroundColor: theme.gray600,
    '& .click-here-lbl': { fontSize: '0.625rem', color: theme.gray100 },
    '& .attach-text-lbl': { fontSize: '0.625rem', color: theme.gray200 },
  });

export const participantSelector = css({
  marginTop: '1.25em',
  display: 'flex',
  flexDirection: 'column',
  padding: '1.25em 1.25em 0 1.25em',
  '& p': { margin: '0 0 0 0.625em', fontSize: '1rem' },
  '& .wrapper': { alignItems: 'center', marginBottom: '1.0625em' },
});
export const imagesPreview = css({
  '& .ant-upload-list-item.ant-upload-list-item-done': {
    padding: '0 ',
    border: 'none',
  },
  '& .ant-upload-list-item-actions a': { display: 'none' },
  '& .ant-upload-list-item-image': {
    objectFit: 'cover !important',
  },
  '& .ant-upload-list-item-info': {
    borderRadius: 3,
  },
  '& .ant-upload-list-picture-card-container': {
    borderRadius: '4px !important',
    [Desktop]: { width: '4.8125rem', height: '4.25rem' },
    [Mobile]: { width: '6.25rem', height: '5rem' },
  },
});

export const startChatBtnDisabled = css({
  backgroundColor: '#DAEBFF !important',
  border: 'none !important',
});

export const backButton = theme =>
  css({
    color: theme.gray100,
    fontSize: '1.1875rem',
    fontWeight: 700,
  });
