export const mapOrderBusinessToCheckoutBusiness = orderBusiness => {
  const { id, name } = orderBusiness;

  return {
    businessId: id,
    businessName: [
      {
        name,
      },
    ],
  };
};

export const mapOrderItemToCheckoutItem = item => ({
  ...item,
  title: [
    {
      title: item.title,
    },
  ],
  dealId: item.id,
  media: {
    url: item.media,
  },
});
