import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { concatStrings } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { Icon } from 'components/ui';
import { containerClass } from './styles';

const Breadcrumbs = props => {
  const { items, className } = props;
  const { getText } = useTranslations();
  const theme = useTheme();

  if (!items.every(el => el.label || el.icon)) return null;

  return (
    <div className={containerClass(className, theme)}>
      {items.map((el, index, arr) => {
        const isNotLastElement = index !== arr.length - 1;
        const itemContent = (
          // If there is icon inside of it we should separate text from icon thats why we need container and span
          <div className={concatStrings('breadcrumb-item', !isNotLastElement && 'breadcrumb-item-last')}>
            {!!el?.icon && <Icon size={20} iconName={el.icon} />}
            {!!el?.label && <span className="breadcrumb-text">{getText(el.label)}</span>}
          </div>
        );

        return (
          <Fragment key={`breadcrumb-${index}`}>
            {el.url && isNotLastElement ? <Link to={el.url}>{itemContent}</Link> : itemContent}
            {isNotLastElement && <span className="breadcrumb-delimiter">&gt;</span>}
          </Fragment>
        );
      })}
    </div>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
};

export default Breadcrumbs;
