import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';
import Datetime from 'react-datetime';
import moment from 'moment';
import { isString, isUndefined, omit } from '@veraio/core';
import { inputField } from './styles';

const Input = forwardRef(
  ({ value, label, onChange, theme, dateTimeProps, className, inputProps, ...inputAttr }, ref) => {
    const baseInputProps =
      isUndefined(value) || isUndefined(onChange) ? { ref } : { value: value || '', onChange, ref };
    const Component = inputAttr.type === 'textarea' ? 'textarea' : 'input';
    const dateTimeFormat = dateTimeProps?.format ?? 'DD/MM/YYYY';

    return dateTimeProps ? (
      <Datetime
        dateFormat={dateTimeFormat}
        onBlur={inputAttr.onBlur}
        value={value && moment(value).format(dateTimeFormat)}
        closeOnSelect
        className={className}
        onChange={val =>
          (!val?.length || moment(val, dateTimeFormat, true).isValid()) &&
          onChange({
            target: {
              value: isString(val) && !val?.length ? null : moment(val).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
            },
          })
        }
        inputProps={{
          ref,
          className: inputField(theme),
          ...inputProps,
        }}
        {...omit(dateTimeProps, 'format')}
      />
    ) : (
      <>
        {label && <div className="label">{label}</div>}
        <Component ref={ref} className={inputField(theme, className)} {...baseInputProps} {...inputAttr} />
      </>
    );
  },
);

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  dateTimeProps: PropTypes.any,
  theme: PropTypes.object,
};

export default withTheme(Input);
