import { getReq, postReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';

const baseUrl = `${envConfig.apiAddress}/api/BankDetails`;

export const getBankDetails = id => getReq(`${baseUrl}/Get/${id}`);

export const createBankDetails = values => postReq(`${baseUrl}/Create`, values);

export const updateBankDetails = values => postReq(`${baseUrl}/Update`, values);
