import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  contentBox: {
    position: 'absolute',
    width: '80%',
    left: '10%',
    bottom: '25%',
  },
  textBox: {
    country: {
      fontSize: 60,
      fontWeight: 600,
      color: '#FECF09',
      marginBottom: 20,
    },
    businessName: {
      fontSize: 80,
      fontWeight: 800,
      color: '#fff',
      marginBottom: 20,
    },
    location: {
      fontSize: 60,
      color: '#fff',
    },
  },
  qrCodeStyles: {
    width: 300,
    position: 'absolute',
    bottom: '-105%',
    right: '-1%',
  },
});
