import { css } from 'emotion';
import { Mobile, Phone } from 'assets/css/breakpoints';

export const merchantCoverImage = image =>
  css({
    minHeight: '25rem',
    maxHeight: '30rem',
    background: `url(${image}) center center / cover no-repeat`,
  });

export const merchantInfoContainer = theme =>
  css({
    position: 'relative',
    background: theme.background100,
    color: theme.gray500,
    padding: '2em',
    '& .verified-merchant-img': {
      position: 'absolute',
      top: '-60px',
      left: 0,
      right: 0,
      margin: '0 auto',
      [Mobile]: {
        top: '-2.5rem',
        width: '6rem',
      },
    },
  });

export const merchantInfo = theme =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: 1228,
    margin: '4em auto 2em',
    [Mobile]: {
      marginTop: '3em',
      '& > div': {
        width: '100%',
      },
    },
    '& .info': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [Mobile]: {
        flexDirection: 'row-reverse',
        marginBottom: '2em',
      },
      [Phone]: {
        justifyContent: 'space-between',
        width: '100%',
      },
      '& img': {
        marginRight: '1em',
        width: 150,
        height: 150,
        borderRadius: 80,
      },
      '& .name': {
        fontSize: '1.2rem',
      },
      '& .contact': {
        marginTop: '.5em',
        color: theme.gray400,
        [Mobile]: {
          display: 'flex',
          flexDirection: 'column',
          gap: '.5rem',
          marginBottom: '1em',
        },
        '& span': {
          fontSize: '.9rem',
          marginRight: '1em',
          '& .las': {
            marginRight: '.5em',
          },
        },
      },
    },
    '& .cta-buttons': {
      display: 'flex',
      gap: '1rem',
      marginBottom: '1em',
      justifyContent: 'flex-end',
      [Mobile]: {
        width: '100%',
      },
      [Phone]: {
        justifyContent: 'space-between',
        width: '100%',
      },
    },
    '& .last-active': {
      color: theme.gray300,
    },
  });

export const merchantInfoStatistics = theme =>
  css({
    maxWidth: 1228,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '2rem',
    padding: '3em 0',
    '& .statistic': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1 1 30px',
      background: `${theme.white}20`,
      padding: '1em',
      borderRadius: '20px',
      fontSize: '1.2rem',
      '& h5': {
        fontSize: '3.7rem',
        color: theme.primary_blue,
        fontWeight: 400,
      },
      '&.rate': {
        '& .reviews-count': {
          fontSize: 12,
        },
        h5: {
          fontSize: '3.7rem',
          color: theme.primary_yellow,
        },
      },
      [Mobile]: {
        width: '100%',
      },
    },
    [Mobile]: {
      flexDirection: 'column',
      gap: '2em',
    },
  });

export const shareButtons = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '1rem',
  '& .sharable-button': {
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
  },
});
