import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Text, View, Svg, Path, G, Image, Rect, Polygon } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const color = {
  st0: '#3A6DB5',
  st1: '#3469B3',
  st2: '#FFFFFF',
  st3: '#FECF0C',
  st4: '#FECF09',
};

const LampPostBannerTemplate = ({ qrCode, businessInfo }) => {
  const SVGComponent = () => (
    <Svg x="0px" y="0px" viewBox="0 0 2267.7 5102.4" style={generalStyles.pageBackground}>
      <Rect x={1} y={1} fill={color.st0} width={2267.7} height={5102.4} />
      <G>
        <Path
          fill={color.st1}
          d="M1130.5,712.2c388.1,693.4,758.2,1354.8,1139.1,2036.1h-607l-531.6-942.6   c-187.8,332-360.6,637.1-533.8,943.4H0.2L1130.5,712.2z"
        />
        <Path
          fill={color.st1}
          d="M1130.6,4162.6l-531.4,938.5H2.1L1130,3068.4l1139.2,2034h-607.3   C1492,4801.2,1319.4,4495.2,1130.6,4162.6z"
        />
      </G>
      <G>
        <Path
          fill={color.st2}
          d="M362.1,657.9c-8.1,13.2-25.6,18.6-41.8,15.3v-64.4c15.6-4.3,31.4-0.1,40.2,12.1   c3.8,5.4,6,11.7,6.2,18.3C367,645.7,365.4,652.2,362.1,657.9L362.1,657.9z M345.7,568.2c-20.6-2.8-41.9-1.1-62.9-1.3   c-0.8,0-1.7,1-2.8,1.6v145.7c18.8,0,36.7,0.2,54.6-0.1c37.7-0.7,69.6-29.9,72.7-66.1C410.6,608.8,384.6,573.5,345.7,568.2z"
        />
        <Polygon
          fill={color.st2}
          points="411.9,880.8 411.9,736.9 449.4,736.9 449.4,788.1 498.4,788.1 498.4,737 536.7,737    536.7,880.8 498.9,880.8 498.9,829.4 450.1,829.4 450.1,880.8  "
        />
        <Polygon
          fill={color.st2}
          points="452.7,606.7 452.7,620.5 483.4,620.5 483.4,661.3 452.8,661.6 452.8,674.5    522.6,674.5 522.6,713.5 411.9,713.5 411.9,567.3 522.5,567.3 522.5,606.7  "
        />
        <Polygon
          fill={color.st2}
          points="948.5,881.8 838.9,881.8 838.9,736.6 948.2,736.6 948.2,774.1 878.5,774.1    878.5,789.8 909,789.8 909,828.3 878.8,828.3 878.8,843.5 948.5,843.5  "
        />
        <Path
          fill={color.st2}
          d="M404,757.9c-9.5,7.3-28.4,23.7-28.4,23.7s-14.2-7.2-26-9.7c-7.5-1.6-22.4-3.4-27.1,4.6   c-4.9,8.3,7.3,12.2,12.8,13.1c7.2,1.1,16.2,0.2,23.4,1.4c30.5,5.2,47,23.2,44.1,50.4c-1.1,9.6-5.2,18.7-11.6,26   C365,896,306.8,888.7,281,857l29.8-21.2c0,0,12.3,8.9,25.4,10.7c7.8,1.1,16.8,1.1,23.6-3c2-1.1,3.5-3,4.1-5.2   c1.3-5.3-5.1-8.2-9.4-8.9c-7.4-1.2-14.8-1.7-22.2-2.5c-27.3-2.9-46.2-18.4-50.3-41c-2.4-13.2,1.1-24.8,9.7-34.7   C316.6,722.4,375,730.2,404,757.9z"
        />
        <Path
          fill={color.st2}
          d="M830.7,860.2l-31.8,21.9l-48.5-53.5l-0.8,52.2h-37.7V737h37.3v54.3c0,0,33.6-36.9,49.7-55.2   l30.6,23.2L787,808.9L830.7,860.2z"
        />
        <Path
          fill={color.st3}
          d="M622.9,567.7c27.7,49.5,54.1,96.6,81.3,145.2h-43.3l-37.9-67.2c-13.4,23.7-25.7,45.4-38.1,67.3   h-42.6L622.9,567.7z"
        />
        <Path
          fill={color.st3}
          d="M622.9,813.8L585,880.7h-42.6l80.5-145l81.3,145.1h-43.3C648.7,859.3,636.4,837.5,622.9,813.8z   "
        />
        <Polygon fill={color.st2} points="750.3,567.4 750.3,674.4 820.7,674.4 820.7,713.4 710.6,713.4 710.6,567.4  " />
        <Path
          fill={color.st2}
          d="M1037,794.5c-0.4,4.2-2.2,8.2-5.2,11.2c-3,3-7,4.9-11.3,5.3c-8.3,0.6-16.7,0.1-25.9,0.1V775   c9.8,0,19-0.9,27.9,0.2C1031.6,776.5,1037.8,785.8,1037,794.5z M1057.3,837.1c3.4-4.4,6-7.5,8.3-10.7c23.4-32.4,6.5-81.4-32.8-88   c-24.9-4.2-51.2-0.8-77.5-0.8V882h38.6v-30.9c0,0,20.6-2.4,31.1,7.6c11.7,11.1,23.2,22.3,36.3,34.9l26.6-28.2v-2L1057.3,837.1z"
        />
      </G>
      <Path
        fill={color.st4}
        d="M1884.3,760.5c-59.2-216.3-30-447.3,81.2-642.1L2033,0h-588.3  c-120.8,282.2-143.6,598.5-61.8,897.5c95.5,349.3,325.9,646.3,640.4,825.7c78.3,44.7,160.2,81.1,244.5,109.2v-568.2  C2079.7,1152.5,1942.1,972,1884.3,760.5z"
      />
    </Svg>
  );

  return (
    <Document>
      <Page size={[2267.7, 5102.4]}>
        <View>
          <SVGComponent />
          <View style={generalStyles.wrapper}>
            <View style={s.contentBox}>
              <Image source={qrCode} style={s.qrCodeStyles} />
              <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
              <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
              <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

LampPostBannerTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default LampPostBannerTemplate;
