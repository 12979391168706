import { css } from 'emotion';

export const myProfileTab = css({
  backgroundColor: 'white',
});

export const title = css({
  fontWeight: 700,
  fontSize: '1.4375rem',
});

export const couponsContainer = css({
  paddingTop: '0.625em',
  paddingBottom: '0.625em',
  display: 'flex',
  '& > .coupon:not(:first-of-type)': {
    marginLeft: '0.625em',
  },
});

export const couponImageThumbnail = css({
  display: 'block',
  padding: 0,
});

// coupon

export const couponTemplate = isVertical =>
  css({
    height: !isVertical && 180,
    display: 'flex',
    flexDirection: isVertical ? 'column' : 'row',
  });

export const thumbnailContainer = css({
  position: 'relative',
  marginBottom: '1.5em',
  height: '10.625rem',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 4,
  justifyContent: 'center',
});

export const couponThumbnail = css({
  width: '100%',
  height: 'auto',
});

export const couponTitle = theme =>
  css({
    fontSize: '1.125rem',
    marginBottom: 0,
    fontWeight: 500,
    color: theme.gray100,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  });

export const infoWrapper = theme =>
  css({
    flexWrap: 'wrap',
    alignItems: 'center',
    '& span, .merchantLinkButton': {
      fontWeight: 400,
      fontSize: '0.75rem',
      color: theme.gray100,
      padding: 0,
    },
    '& .bolder-text': {
      fontWeight: 700,
      marginLeft: 4,
    },
  });

export const starRatings = css({
  '&.ant-rate': {
    fontSize: '1rem',
  },
});

export const ratingsCountLink = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray200,
    marginLeft: '0.3125em',
    textDecoration: 'underline',
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray200,
    marginLeft: '0.3125em',
  });

export const couponDescription = theme =>
  css({
    color: theme.gray100,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    marginTop: '0.3125em',
    paddingRight: '1.875em',
  });

export const dealEndDateWrapper = theme =>
  css({
    '& .bold-text': {
      fontWeight: 700,
    },
    '& span': {
      fontWeight: 400,
      fontSize: '0.75rem',
      color: theme.gray100,
      alignSelf: 'center',
    },
    '.icon-Ends-on': {
      fontSize: '1rem',
      lineHeight: '1rem',
      marginRight: '0.5em',
    },
    '& .icon-Delivery-Type-Redeem-at-location': {
      fontSize: '1.25rem',
      marginRight: '0.25em',
    },
    '& .icon-Delivery-Type-Self-organized': {
      fontSize: '1.25rem',
      marginRight: '0.3125em',
      marginLeft: '0.3125em',
    },
    '& .icon-QuestionMark-dotted': {
      fontSize: '0.875rem',
      marginLeft: '0.1875em',
    },
  });
