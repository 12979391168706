export const bankDetailsInfo = [
  {
    label: 'merchantFullName',
    value: 'fullName',
  },
  {
    label: 'bankName',
    value: 'bankName',
  },
  {
    label: 'bankAccountNumber',
    value: 'iban',
  },
  {
    label: 'bicSwiftCode',
    value: 'bic',
  },
  {
    label: 'address',
    value: 'address',
  },
  {
    label: 'preferredCurrency',
    value: 'currency',
  },
];
