import { xorBy } from 'lodash-es';

export const selectAllOption = (list, prevSelection, currentSelection) => {
  const selectedItem = xorBy(prevSelection ?? [], currentSelection ?? [], 'id')[0];
  const hasAllSelected = !!currentSelection.find(el => el.id === 0);

  if (selectedItem?.id === 0) return hasAllSelected ? list : [];
  if (!hasAllSelected && currentSelection.length === list.length - 1) return list;
  if (hasAllSelected && prevSelection.length > currentSelection.length)
    return currentSelection.filter(el => el.id !== 0);

  return currentSelection;
};
