import { Mobile } from 'assets/css/breakpoints';
import { css } from 'emotion';

export const categoryCardTemplate = (theme, image) =>
  css({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    minWidth: '11.5rem',
    maxWidth: '15.625rem',
    height: '12.9375rem',
    borderRadius: '12px',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    textAlign: 'right',
    padding: '1.25em',
    [Mobile]: {
      margin: '0px 0px 1.125em 0px',
      minWidth: '8.125rem',
      maxWidth: '10rem',
      height: '8.125rem',
    },
    '& > p': {
      fontSize: '1.125rem',
      margin: 0,
      color: theme.gray100,
      fontWeight: 700,
      [Mobile]: {
        fontSize: '0.75rem',
        fontWeight: 700,
      },
    },
    '& .entertainment-and-hobby': {
      wordBreak: 'normal',
      maxWidth: '9.375rem',
      whiteSpace: 'normal',
      [Mobile]: {
        maxWidth: '6.25rem',
      },
    },
    '& .global-and-digital': {
      wordBreak: 'normal',
      maxWidth: '4.3125rem',
      whiteSpace: 'normal',
    },
    '& .dealsCount': {
      fontSize: '0.875rem',
      fontWeight: 500,
      marginTop: '0.625em',
      [Mobile]: {
        fontSize: '0.5rem',
        marginBottom: 0,
      },
    },
    '& .services-deals, .global-and-digital-deals': {
      color: theme.blue,
    },
    '& .products-deals, .featured-deals': { color: theme.gray200 },
    '& .discounted-deals, .entertainment-and-hobby-deals, .travel-deals, .home-and-auto-deals': {
      color: theme.white,
      fontWeight: 400,
    },
  });
