import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Row, Col } from 'antd';
import { useTranslations } from '@veraio/strank';
import CommentReview from 'components/review/CommentReview';
import ReviewsSummary from 'components/review/ReviewsSummary';
import { Button } from 'components/UIExternal';
import { mb } from 'assets/css/globalCSS';
import { commentsContainer, sectionHeaderContainer, borderContainer, noReviews } from './styles';

const ReviewsAndCommentsPanel = ({ reviewsSummary, reviewsList, dealName, dealId }) => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div className={`${mb({ lg: 20 })} reviews-and-comments-container`}>
      <div className={sectionHeaderContainer}>
        <h4 className="section-subtitle">{getText('reviewsAndComments')}</h4>
      </div>
      {reviewsList && reviewsList.length > 0 ? (
        <div className={borderContainer(theme)}>
          <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 26 }}>
            <Col lg={8} span={24} className="gutter-row">
              <ReviewsSummary reviewsAndComments={reviewsSummary} dealId={dealId} dealName={dealName} />
            </Col>
            <Col lg={16} span={24}>
              <div className={commentsContainer}>
                {reviewsList &&
                  reviewsList.length > 0 &&
                  reviewsList.map((item, idx) => <CommentReview key={idx} reviews={item} />)}
                <Button type="info" small fullWidth linkTo={`/deal/${dealName}/reviews`}>
                  {getText('viewAllReviews')}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className={noReviews(theme)}>
          <p>{getText('noReviewsYet')}</p>
          {reviewsSummary.canLeaveReview && (
            <Button type="default" small linkTo={`/deal/${dealId}/leave-review`}>
              {getText('leaveReview')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

ReviewsAndCommentsPanel.propTypes = {
  reviewsSummary: PropTypes.object,
  reviewsList: PropTypes.array,
  dealName: PropTypes.string,
  dealId: PropTypes.number,
};

export default ReviewsAndCommentsPanel;
