import { StyleSheet } from '@react-pdf/renderer';
import { css } from 'emotion';

export const qrBlock = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& p': {
    textAlign: 'center',
  },
  '& img': {
    width: 80,
  },
});

export const dropDownStyles = css({
  width: 130,
});

export const qrColorThemePreview = (theme, version) =>
  css({
    width: 100,
    height: 20,
    marginBottom: 5,
    background: version === 1 ? theme.qrCodev1 : theme.qrCodev2,
  });

export const styles = StyleSheet.create({
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  document: {
    fontFamily: 'Noto',
    height: '100%',
    color: '#fff',
    display: 'flex',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    marginBottom: 120,
    fontSize: 14,
    logoImg: {
      display: 'block',
      width: 100,
    },
  },
  title: {
    fontSize: 18,
    height: 40,
    width: '100vw',
    textAlign: 'center',
    margin: '0 auto',
    v1: {
      color: '#fff',
    },
    v2: {
      color: '#000',
      marginTop: 110,
    },
  },
  subTitle: {
    fontSize: 30,
    height: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 500,
    margin: '20px auto 0',
    textAlign: 'center',
    v1: {
      color: '#fff',
    },
    v2: {
      color: '#000',
    },
  },
  priceTitle: {
    fontSize: 18,
    height: 40,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 400,
    margin: '20px auto 0',
    textAlign: 'center',
    v1: {
      color: '#fff',
    },
    v2: {
      color: '#000',
    },
  },
  qrCodeImage: {
    margin: '64px auto 0',
    width: 224,
  },
  footer: {
    fontSize: 14,
    width: '100vw',
    marginTop: 150,
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
