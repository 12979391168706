import { useHistory, useParams } from 'react-router-dom';
import { isJsonString, parseJson, deepEqual, isPlainObject, isNil, isNumber } from '@veraio/core';
import qs from 'query-string';
import { mapValues, omit, omitBy, isArray } from 'lodash-es';
import moment from 'moment';

const dateTimeFormat = 'MM-DD-YYYY';

export const useUrlParams = () => {
  const history = useHistory();
  const pathParams = useParams();

  const extractQuery = search =>
    mapValues(
      qs.parse(search, {
        arrayFormat: 'index',
      }),
      el => {
        switch (true) {
          case isJsonString(el):
            return parseJson(el);
          case (/\d-\d-\d/g.test(el) || /\d\/\d\/\d/g.test(el)) && moment(el, dateTimeFormat).isValid():
            return moment(el, dateTimeFormat);
          case isNumber(el):
            return Number(el);
          default:
            return el;
        }
      },
    );

  const queryParams = extractQuery(history.location.search);

  const setQueryParams = params => {
    const queryParams = extractQuery(history.location.search);
    const mappedQueryParams = omitBy(
      mapValues({ ...queryParams, ...params }, el => {
        switch (true) {
          case isPlainObject(el):
            return JSON.stringify(el);
          case moment.isMoment(el):
            return el.format(dateTimeFormat);
          case moment.isDate(el):
            return moment(el).format(dateTimeFormat);
          default:
            return el;
        }
      }),
      isNil,
    );

    const filteredUrlParams = qs.stringify(mappedQueryParams, { arrayFormat: 'index' });
    const newParams = filteredUrlParams ? `?${filteredUrlParams}` : history.location.search;
    !deepEqual(queryParams, mappedQueryParams) &&
      history.replace({ to: history.location.pathname, state: history.location.state, search: newParams });
  };

  const clearQueryParams = paramsToClear => {
    let search = '';
    const queryParams = extractQuery(history.location.search);
    if (isArray(paramsToClear)) search = `?${qs.stringify(omit(queryParams, paramsToClear))}`;

    history.replace({ to: history.location.pathname, state: history.location.state, search });
  };

  return {
    pathParams,
    queryParams,
    setQueryParams: params => setQueryParams(params),
    clearQueryParams: params => clearQueryParams(params),
    stringifyQueryParams: (params, config) => qs.stringify(params, config),
    parseQueryParams: (params, config) => qs.parse(params, config),
  };
};
