import React, { useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Table } from 'components/UIExternal';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { TransactionsFilterBar, TransactionsTotalsPanel } from 'components/screens';
import { useUser } from 'stores';
import { SETTLEMENT_SORT_SELECT } from 'enums';
import { setError, getTransactionsSettlementList } from 'services';
import { SETTLEMENT_FILTER_KEYS, SETTLEMENT_COLUMNS, CLEARING_SETTLEMENT_SORTING_KEYS } from '../config';
import { tabContentContainer, transactionsTableContainer } from '../styles';

const SettlementTab = ({ pendingClearingSettlementAmounts = {} }) => {
  const { getText } = useTranslations();
  useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const theme = useTheme();
  const { currentAccount } = useUser();
  const tableRef = useRef();

  const {
    pendingSettlementCoinWalletBalance,
    pendingSettlementCashWalletBalance,
    pendingSettlementOespWalletBalance,
  } = pendingClearingSettlementAmounts;

  const totals = [
    {
      one: pendingSettlementCoinWalletBalance,
      fiat: pendingSettlementCashWalletBalance,
      oesp: pendingSettlementOespWalletBalance,
      title: getText('awaitingSettlement'),
    },
  ];

  const fetchTransactions = async options => {
    const [res, err] = await getTransactionsSettlementList(options, currentAccount?.id);
    if (err) return setError(err);

    return [res, err];
  };

  const sortOptions = SETTLEMENT_SORT_SELECT(getText);
  const initQueryParams = {
    [CLEARING_SETTLEMENT_SORTING_KEYS.sorting]: sortOptions[0].value,
  };

  return (
    <div className={tabContentContainer} data-id="settlement-tab">
      <div data-id="settlement-tab-totals">
        <TransactionsTotalsPanel totalsData={totals} />
      </div>

      <div className={transactionsTableContainer} data-id="settlement-tab-table">
        <Table
          ref={tableRef}
          hasPagination
          filterBar={inheritOptions => (
            <TransactionsFilterBar
              {...inheritOptions}
              filterKeys={SETTLEMENT_FILTER_KEYS}
              sortingKeys={CLEARING_SETTLEMENT_SORTING_KEYS}
              sortOptions={sortOptions}
            />
          )}
          pageSize={10}
          withPageDropdown
          filterKeys={SETTLEMENT_FILTER_KEYS}
          sortingKeys={CLEARING_SETTLEMENT_SORTING_KEYS}
          getDataMethod={fetchTransactions}
          columns={SETTLEMENT_COLUMNS(theme, getText)}
          defaultQueryParams={initQueryParams}
        />
      </div>
    </div>
  );
};

export default SettlementTab;
