import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import { galleryWrapper } from './styles';

const ImageGalleryPanel = ({ items, showNav = false, onClick, startIndex = 0 }) => (
  <div className={`${galleryWrapper} image-gallery-wrapper`}>
    <ImageGallery
      items={items}
      showFullscreenButton={false}
      onClick={onClick}
      showPlayButton={false}
      showNav={showNav}
      startIndex={startIndex}
    />
  </div>
);

ImageGalleryPanel.propTypes = {
  items: PropTypes.array,
  startIndex: PropTypes.number,
  showNav: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ImageGalleryPanel;
