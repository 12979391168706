import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { toast } from 'react-hot-toast';
import { Button } from 'components/UIExternal';
import { useUser } from 'stores';
import notFoundManager from 'assets/images/globalStructurePage/managerNotFound.png';
import { globalStructureFindManagerProfile, globalStructureFindManagerProfileImage } from './styles';

export const GlobalStructureManagerProfile = ({ manager }) => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const { isAuthenticated } = useUser();

  return (
    <div className={globalStructureFindManagerProfile(theme)}>
      <img src={manager?.userImg || notFoundManager} alt="manager" className={globalStructureFindManagerProfileImage} />
      <p className="name">{manager.userName}</p>
      <p className="country">{manager.userCountry}</p>

      <Button
        small
        type="secondary"
        {...(isAuthenticated
          ? { linkTo: `/chat?dealId=0&userId=${manager.userId}` }
          : { onClick: () => toast.error(getText('loginYourAccountAllBenefits')) })}
      >
        {getText('contact')}
      </Button>
    </div>
  );
};
