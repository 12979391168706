import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isNumber, pick } from '@veraio/core';
import { useCurrencies, printPrice, convertCurrencies } from '@oneecosystem/dealshaker-core';
import { Page, Document, Text, View, Svg, Path, G, ClipPath, Defs } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const SVGComponentYellow = () => (
  <Svg width="566.93pt" height="340.157pt" viewBox="0 0 566.93 340.157" style={generalStyles.pageBackground}>
    <Defs>
      <ClipPath id="clip1">
        <Path d="M 0 0 L 566.929688 0 L 566.929688 340 L 0 340 Z M 0 0 " />
      </ClipPath>
      <ClipPath id="clip2">
        <Path d="M 0 0 L 566.929688 0 L 566.929688 170 L 0 170 Z M 0 0 " />
      </ClipPath>
    </Defs>
    <G id="surface1">
      <G clipPath="url(#clip1)" clipRule="nonzero">
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(99.80011%,99.934387%,99.986267%)',
            fillOpacity: 1,
          }}
          d="M 0.0351562 339.363281 L 566.929688 339.363281 L 566.929688 0 L 0.0351562 0 Z M 0.0351562 339.363281 "
        />
      </G>
      <G clipPath="url(#clip2)" clipRule="nonzero">
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(98.023987%,80.300903%,3.364563%)',
            fillOpacity: 1,
          }}
          d="M 0 169.285156 L 566.929688 169.285156 L 566.929688 0 L 0 0 Z M 0 169.285156 "
        />
      </G>
    </G>
  </Svg>
);

const SVGComponentGreen = () => (
  <Svg width="566.93pt" height="340.157pt" viewBox="0 0 566.93 340.157" style={generalStyles.pageBackground}>
    <Defs>
      <ClipPath id="clip1">
        <Path d="M 0 0 L 566.929688 0 L 566.929688 340 L 0 340 Z M 0 0 " />
      </ClipPath>
      <ClipPath id="clip2">
        <Path d="M 0 0 L 566.929688 0 L 566.929688 170 L 0 170 Z M 0 0 " />
      </ClipPath>
    </Defs>
    <G id="surface1">
      <G clipPath="url(#clip1)" clipRule="nonzero">
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(99.80011%,99.934387%,99.986267%)',
            fillOpacity: 1,
          }}
          d="M 0.0351562 339.363281 L 566.929688 339.363281 L 566.929688 0 L 0.0351562 0 Z M 0.0351562 339.363281 "
        />
      </G>
      <G clipPath="url(#clip2)" clipRule="nonzero">
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(45.300293%,80.33905%,53.450012%)',
            fillOpacity: 1,
          }}
          d="M 0 169.285156 L 566.929688 169.285156 L 566.929688 0 L 0 0 Z M 0 169.285156 "
        />
      </G>
    </G>
  </Svg>
);

const SVGComponentBlue = () => (
  <Svg width="566.93pt" height="340.157pt" viewBox="0 0 566.93 340.157" style={generalStyles.pageBackground}>
    <Defs>
      <ClipPath id="clip1">
        <Path d="M 0 0 L 566.929688 0 L 566.929688 340 L 0 340 Z M 0 0 " />
      </ClipPath>
      <ClipPath id="clip2">
        <Path d="M 0 0 L 566.929688 0 L 566.929688 170 L 0 170 Z M 0 0 " />
      </ClipPath>
    </Defs>
    <G id="surface1">
      <G clipPath="url(#clip1)" clipRule="nonzero">
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(99.80011%,99.934387%,99.986267%)',
            fillOpacity: 1,
          }}
          d="M 0.0351562 339.363281 L 566.929688 339.363281 L 566.929688 0 L 0.0351562 0 Z M 0.0351562 339.363281 "
        />
      </G>
      <G clipPath="url(#clip2)" clipRule="nonzero">
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(25.932312%,40.24353%,72.915649%)',
            fillOpacity: 1,
          }}
          d="M 0 169.285156 L 566.929688 169.285156 L 566.929688 0 L 0 0 Z M 0 169.285156 "
        />
      </G>
    </G>
  </Svg>
);

const LabelsTemplate = ({ dealsInfo }) => {
  const { displayFiatOnly } = useCurrencies(currenciesState =>
    pick(currenciesState, ['displayFiatOnly', 'selectedCurrency']),
  );

  return (
    <Document>
      {dealsInfo
        ?.filter(el => el.id !== 0)
        .map(businessInfo => {
          const fiatOriginCurrency = businessInfo.currencyCode;
          const price = convertCurrencies({
            fiat: businessInfo?.discountedPriceFiat ?? businessInfo.priceFiat,
            fiatCrypto: businessInfo?.discountedPriceCrypto ?? businessInfo?.priceCrypto,
            fiatOriginCurrency,
          });
          const priceFiat = printPrice({
            fiat: businessInfo?.discountedPriceFiat ?? businessInfo?.priceFiat,
            fiatOriginCurrency: businessInfo?.currencyCode,
            fiatLabelSign: 'code',
            total: businessInfo?.discountedPrice ?? businessInfo?.price,
          });
          const priceCrypto = printPrice({
            fiatCrypto: businessInfo?.discountedPriceCrypto ?? businessInfo?.priceCrypto,
            fiatOriginCurrency: businessInfo?.currencyCode,
            fiatLabelSign: 'code',
          });

          const totalPrice = printPrice({
            fiat: businessInfo?.discountedPrice ?? businessInfo?.price,
            fiatOriginCurrency: businessInfo?.currencyCode,
            fiatLabelSign: 'code',
            total: businessInfo?.discountedPrice ?? businessInfo?.price,
          });

          return (
            <Fragment key={businessInfo.id}>
              <Page size={[566.93, 340.157]}>
                <View>
                  <SVGComponentBlue />
                  <View style={generalStyles.wrapper}>
                    <View style={s.contentBox}>
                      <Text style={[s.textBox.businessName, s.textBox.pricesBlock.blue]}>{businessInfo?.title}</Text>
                      <View style={s.textBox.pricesBlock}>
                        {isNumber(price.fiatLocal) && !displayFiatOnly && (
                          <Text style={[s.textBox.pricesBlock.price, s.textBox.pricesBlock.blue]}>{priceFiat}</Text>
                        )}
                        {isNumber(price.crypto) && !displayFiatOnly && (
                          <Text style={[s.textBox.pricesBlock.price, s.textBox.pricesBlock.blue]}>{priceCrypto}</Text>
                        )}
                        {displayFiatOnly && (
                          <Text style={[s.textBox.pricesBlock.price, s.textBox.pricesBlock.blue]}>{totalPrice}</Text>
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </Page>
              <Page size={[566.93, 340.157]}>
                <View>
                  <SVGComponentGreen />
                  <View style={generalStyles.wrapper}>
                    <View style={s.contentBox}>
                      <Text style={[s.textBox.businessName, s.textBox.pricesBlock.yellow]}>{businessInfo?.title}</Text>
                      <View style={s.textBox.pricesBlock}>
                        {isNumber(price.fiatLocal) && !displayFiatOnly && (
                          <Text style={[s.textBox.pricesBlock.price, s.textBox.pricesBlock.yellow]}>{priceFiat}</Text>
                        )}
                        {isNumber(price.crypto) && !displayFiatOnly && (
                          <Text style={[s.textBox.pricesBlock.price, s.textBox.pricesBlock.yellow]}>{priceCrypto}</Text>
                        )}
                        {displayFiatOnly && (
                          <Text style={[s.textBox.pricesBlock.price, s.textBox.pricesBlock.blue]}>{totalPrice}</Text>
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </Page>
              <Page size={[566.93, 340.157]}>
                <View>
                  <SVGComponentYellow />
                  <View style={generalStyles.wrapper}>
                    <View style={s.contentBox}>
                      <Text style={[s.textBox.businessName, s.textBox.pricesBlock.green]}>{businessInfo?.title}</Text>
                      <View style={s.textBox.pricesBlock}>
                        {isNumber(price.fiatLocal) && !displayFiatOnly && (
                          <Text style={[s.textBox.pricesBlock.price, s.textBox.pricesBlock.green]}>{priceFiat}</Text>
                        )}
                        {isNumber(price.crypto) && !displayFiatOnly && (
                          <Text style={[s.textBox.pricesBlock.price, s.textBox.pricesBlock.green]}>{priceCrypto}</Text>
                        )}
                        {displayFiatOnly && (
                          <Text style={[s.textBox.pricesBlock.price, s.textBox.pricesBlock.blue]}>{totalPrice}</Text>
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </Page>
            </Fragment>
          );
        })}
    </Document>
  );
};

LabelsTemplate.propTypes = {
  dealsInfo: PropTypes.any,
};

export default LabelsTemplate;
