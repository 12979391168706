import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { FilterRateContainer, FilterRating } from './styles';

const ListingRatingFilter = ({ value, onRateChange }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const maxValue = 5;

  const onChange = value => {
    onRateChange(value);
  };

  return (
    <FilterRateContainer theme={theme}>
      <FilterRating value={value} onChange={onChange} />
      <label>
        {value < maxValue ? getText('starsAndHigherCount', { count: value }) : getText('starsCount', { count: value })}
      </label>
    </FilterRateContainer>
  );
};

ListingRatingFilter.propTypes = {
  value: PropTypes.number,
  onRateChange: PropTypes.func,
};

export default ListingRatingFilter;
