import React from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { guideSectionPageNumber, guideSectionPageTitle, guideSectionPageContainer } from './styles';

export const BrandGuideSectionPage = ({ section }) => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div className={guideSectionPageContainer}>
      <div>
        <p className={guideSectionPageNumber(theme)}>{section?.sectionNumber}</p>
        <h1 className={guideSectionPageTitle(theme)}>{getText(section?.title)}</h1>
      </div>
    </div>
  );
};
