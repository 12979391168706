import React from 'react';
import { pick } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { CheckButton, CircleProgress } from 'components/UIExternal';
import { useOrders, selectedOrderPaymentDetails, selectedOrderDeliveryDetails } from 'stores';
import { orderStatus } from 'enums';
import { orderContentProgressBlock } from './styles';

export const OrderDetailsFulfill = () => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const { editable, totalPriceFiat, id: orderId, statusId } = useOrders(ordersState => ({
    editable: ordersState?.editable,
    // It should be subscribed to changes in payment and delivery so it can render progress
    ...pick(ordersState?.selectedOrder, ['payment', 'delivery', 'totalPriceFiat', 'id', 'statusId']),
  }));
  const { isFiatPaymentCompleted, isFiatManualPayment } = selectedOrderPaymentDetails();
  const { isDeliveryCompleted } = selectedOrderDeliveryDetails();
  const isEditable = editable && statusId === orderStatus.new;

  const fulfillProgress = () => {
    if (isFiatManualPayment && !!totalPriceFiat) {
      if (isFiatPaymentCompleted && isDeliveryCompleted) return 100;
      return isFiatPaymentCompleted || isDeliveryCompleted ? 50 : 0;
    }

    return isDeliveryCompleted ? 100 : 0;
  };

  if (!isEditable || ((isFiatPaymentCompleted || !totalPriceFiat) && isDeliveryCompleted)) return null;

  return (
    <section className={orderContentProgressBlock(theme)}>
      <h4>{getText('whatLeftFulFilOrder')}</h4>
      <div className="fulfill-content-container">
        <div className="fulfill-steps-container">
          {isFiatManualPayment && !!totalPriceFiat && (
            <a href="#order-details-payment-section">
              <CheckButton radio disabled checked={isFiatPaymentCompleted} className={{ cursor: 'pointer' }}>
                {getText('waitingPaymentComplete')}
              </CheckButton>
            </a>
          )}
          <a href="#order-details-delivery-section">
            <CheckButton radio disabled checked={isDeliveryCompleted} className={{ cursor: 'pointer' }}>
              {getText('finishDelivery')}
            </CheckButton>
          </a>
        </div>
        <CircleProgress key={orderId} progress={fulfillProgress()} className="side" />
      </div>
    </section>
  );
};
