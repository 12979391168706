import React, { useRef, useLayoutEffect } from 'react';
import { OrderFilters, OrdersList, OrderDetails } from 'components/shared';
import { initOrdersStore, resetOrdersStore } from 'stores';
import { ordersContainer } from './styles';

const filterKeys = {
  search: 'searchText',
  status: 'statusId',
  dateFrom: 'dateFrom',
};

export const MyProfileOrders = () => {
  const paginationRef = useRef();

  useLayoutEffect(() => {
    initOrdersStore({ editable: false });
    return () => resetOrdersStore();
  }, []);

  return (
    <>
      <OrderFilters onChange={paginationRef.current?.applyFilters} filterKeys={filterKeys} />
      <div className={ordersContainer}>
        <OrdersList filterKeys={filterKeys} paginationRef={paginationRef} />
        <OrderDetails />
      </div>
    </>
  );
};
