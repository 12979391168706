import React from 'react';
import { Page, Document, View, Svg, Path, G, ClipPath, Defs } from '@react-pdf/renderer';

const StickersTemplate = () => {
  const SVGComponentFirst = () => (
    <Svg width="198.423pt" height="198.423pt" viewBox="0 0 198.423 198.423">
      <Defs>
        <ClipPath id="clip1">
          <Path d="M 0 0 L 198.421875 0 L 198.421875 198.421875 L 0 198.421875 Z M 0 0 " />
        </ClipPath>
      </Defs>
      <G id="surface1">
        <G clipPath="url(#clip1)" clipRule="nonzero">
          <Path
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: 'rgb(26.062012%,40.423584%,73.033142%)',
              fillOpacity: 1,
            }}
            d="M 0.335938 198.324219 L 198.421875 198.324219 L 198.421875 0 L 0.335938 0 Z M 0.335938 198.324219 "
          />
        </G>
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 58.875 78.699219 C 56.273438 78.34375 53.585938 78.554688 50.9375 78.53125 C 50.832031 78.53125 50.726562 78.65625 50.585938 78.738281 L 50.585938 97.121094 C 52.953125 97.121094 55.214844 97.152344 57.476562 97.113281 C 62.230469 97.03125 66.25 93.347656 66.644531 88.773438 C 67.066406 83.816406 63.785156 79.363281 58.875 78.699219 M 60.945312 90.007812 C 59.921875 91.671875 57.722656 92.347656 55.671875 91.933594 L 55.671875 83.808594 C 57.644531 83.265625 59.628906 83.800781 60.75 85.332031 C 61.226562 86.011719 61.5 86.8125 61.535156 87.640625 C 61.570312 88.46875 61.367188 89.292969 60.945312 90.007812 Z M 60.945312 90.007812 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 67.226562 118.125 L 67.226562 99.972656 L 71.953125 99.972656 L 71.953125 106.433594 L 78.136719 106.433594 L 78.136719 99.992188 L 82.96875 99.992188 L 82.96875 118.132812 L 78.203125 118.132812 L 78.203125 111.640625 L 72.050781 111.640625 L 72.050781 118.125 Z M 67.226562 118.125 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 72.378906 83.558594 L 72.378906 85.292969 L 76.25 85.292969 L 76.25 90.445312 L 72.382812 90.480469 L 72.382812 92.109375 L 81.1875 92.109375 L 81.1875 97.023438 L 67.230469 97.023438 L 67.230469 78.585938 L 81.175781 78.585938 L 81.175781 83.558594 Z M 72.378906 83.558594 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 134.917969 118.261719 L 121.085938 118.261719 L 121.085938 99.941406 L 134.878906 99.941406 L 134.878906 104.671875 L 126.089844 104.671875 L 126.089844 106.648438 L 129.929688 106.648438 L 129.929688 111.5 L 126.125 111.5 L 126.125 113.425781 L 134.914062 113.425781 Z M 134.917969 118.261719 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 66.230469 102.625 C 65.03125 103.546875 62.644531 105.613281 62.644531 105.613281 C 62.644531 105.613281 60.851562 104.707031 59.367188 104.386719 C 58.421875 104.1875 56.546875 103.960938 55.945312 104.972656 C 55.324219 106.015625 56.863281 106.511719 57.554688 106.625 C 58.46875 106.765625 59.601562 106.644531 60.511719 106.800781 C 64.355469 107.460938 66.4375 109.726562 66.078125 113.15625 C 65.933594 114.367188 65.425781 115.511719 64.621094 116.433594 C 61.3125 120.042969 53.972656 119.128906 50.71875 115.128906 L 54.484375 112.460938 C 54.484375 112.460938 56.03125 113.582031 57.683594 113.808594 C 58.660156 113.941406 59.796875 113.949219 60.664062 113.425781 C 60.917969 113.28125 61.105469 113.046875 61.1875 112.769531 C 61.347656 112.097656 60.550781 111.726562 60.007812 111.640625 C 59.082031 111.492188 58.136719 111.421875 57.203125 111.320312 C 53.765625 110.957031 51.371094 109.003906 50.855469 106.144531 C 50.554688 104.484375 51 103.019531 52.082031 101.761719 C 55.203125 98.152344 62.570312 99.136719 66.230469 102.625 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 120.058594 115.527344 L 116.046875 118.292969 L 109.929688 111.550781 L 109.828125 118.132812 L 105.074219 118.132812 L 105.074219 99.992188 L 109.785156 99.992188 L 109.785156 106.835938 C 109.785156 106.835938 114.019531 102.175781 116.054688 99.867188 L 119.910156 102.792969 L 114.542969 109.0625 Z M 120.058594 115.527344 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(97.996521%,79.974365%,3.775024%)',
            fillOpacity: 1,
          }}
          d="M 93.84375 78.628906 C 97.335938 84.867188 100.667969 90.820312 104.09375 96.949219 L 98.632812 96.949219 L 93.847656 88.46875 C 92.160156 91.453125 90.605469 94.199219 89.046875 96.957031 L 83.675781 96.957031 Z M 93.84375 78.628906 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(97.996521%,79.974365%,3.775024%)',
            fillOpacity: 1,
          }}
          d="M 93.847656 109.675781 L 89.0625 118.121094 L 83.691406 118.121094 L 93.839844 99.828125 L 104.089844 118.132812 L 98.625 118.132812 C 97.097656 115.421875 95.546875 112.667969 93.847656 109.675781 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 109.910156 78.59375 L 109.910156 92.089844 L 118.796875 92.089844 L 118.796875 97.015625 L 104.910156 97.015625 L 104.910156 78.59375 Z M 109.910156 78.59375 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 148.640625 112.613281 C 149.070312 112.058594 149.394531 111.667969 149.691406 111.257812 C 152.636719 107.179688 150.507812 100.996094 145.550781 100.160156 C 142.402344 99.632812 139.09375 100.0625 135.769531 100.0625 L 135.769531 118.273438 L 140.640625 118.273438 L 140.640625 114.382812 C 140.640625 114.382812 143.234375 114.078125 144.566406 115.339844 C 146.035156 116.734375 147.488281 118.15625 149.152344 119.734375 L 152.511719 116.175781 L 152.511719 115.917969 Z M 146.078125 107.246094 C 146.03125 107.78125 145.800781 108.28125 145.417969 108.664062 C 145.039062 109.046875 144.535156 109.28125 144 109.332031 C 142.953125 109.410156 141.894531 109.351562 140.734375 109.351562 L 140.734375 104.789062 C 141.976562 104.789062 143.128906 104.675781 144.25 104.820312 C 145.394531 104.96875 146.171875 106.144531 146.078125 107.246094 "
        />
        <Path
          style={{
            fill: 'none',
            strokeWidth: 1,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            stroke: 'rgb(92.945862%,11.064148%,14.105225%)',
            strokeOpacity: 1,
            strokeMiterlimit: 10,
          }}
          d="M -0.000675 0.0016625 C -0.000675 -39.138963 -31.727238 -70.865525 -70.867863 -70.865525 C -110.004581 -70.865525 -141.731144 -39.138963 -141.731144 0.0016625 C -141.731144 39.138381 -110.004581 70.864944 -70.867863 70.864944 C -31.727238 70.864944 -0.000675 39.138381 -0.000675 0.0016625 Z M -0.000675 0.0016625 "
          transform="matrix(1,0,0,-1,170.0788,99.2126)"
        />
      </G>
    </Svg>
  );

  const SVGComponentSecond = () => (
    <Svg width="198.423pt" height="198.423pt" viewBox="0 0 198.423 198.423">
      <Defs>
        <ClipPath id="clip1">
          <Path d="M 0 99 L 198.421875 99 L 198.421875 198.421875 L 0 198.421875 Z M 0 99 " />
        </ClipPath>
        <ClipPath id="clip2">
          <Path d="M 0 0 L 198.421875 0 L 198.421875 100 L 0 100 Z M 0 0 " />
        </ClipPath>
      </Defs>
      <G id="surface1">
        <G clipPath="url(#clip1)" clipRule="nonzero">
          <Path
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: 'rgb(26.062012%,40.423584%,73.033142%)',
              fillOpacity: 1,
            }}
            d="M 0.15625 198.421875 L 198.421875 198.421875 L 198.421875 99.210938 L 0.15625 99.210938 Z M 0.15625 198.421875 "
          />
        </G>
        <G clipPath="url(#clip2)" clipRule="nonzero">
          <Path
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: 'rgb(45.100403%,80.265808%,53.240967%)',
              fillOpacity: 1,
            }}
            d="M 0.15625 99.210938 L 198.421875 99.210938 L 198.421875 0 L 0.15625 0 Z M 0.15625 99.210938 "
          />
        </G>
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(98.023987%,80.300903%,3.364563%)',
            fillOpacity: 1,
          }}
          d="M 135.046875 120.269531 L 121.46875 120.269531 L 121.46875 99.566406 C 121.46875 93.703125 119.140625 88.078125 114.996094 83.933594 C 110.847656 79.785156 105.222656 77.457031 99.359375 77.457031 C 93.496094 77.457031 87.871094 79.785156 83.726562 83.933594 C 79.578125 88.078125 77.25 93.703125 77.25 99.566406 L 77.25 120.269531 L 63.671875 120.269531 L 63.671875 99.566406 C 63.671875 90.097656 67.433594 81.019531 74.125 74.328125 C 80.820312 67.632812 89.898438 63.871094 99.367188 63.871094 C 108.835938 63.871094 117.914062 67.632812 124.609375 74.328125 C 131.300781 81.019531 135.0625 90.097656 135.0625 99.566406 Z M 135.046875 120.269531 "
        />
        <Path
          style={{
            fill: 'none',
            strokeWidth: 1,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            stroke: 'rgb(92.945862%,11.064148%,14.105225%)',
            strokeOpacity: 1,
            strokeMiterlimit: 10,
          }}
          d="M -0.000675 0.0016625 C -0.000675 -39.138963 -31.727238 -70.865525 -70.867863 -70.865525 C -110.004581 -70.865525 -141.731144 -39.138963 -141.731144 0.0016625 C -141.731144 39.138381 -110.004581 70.864944 -70.867863 70.864944 C -31.727238 70.864944 -0.000675 39.138381 -0.000675 0.0016625 Z M -0.000675 0.0016625 "
          transform="matrix(1,0,0,-1,170.0788,99.2126)"
        />
      </G>
    </Svg>
  );

  const SVGComponentThird = () => (
    <Svg width="198.423pt" height="198.423pt" viewBox="0 0 198.423 198.423">
      <Defs>
        <ClipPath id="clip1">
          <Path d="M 0 0 L 198.421875 0 L 198.421875 198.421875 L 0 198.421875 Z M 0 0 " />
        </ClipPath>
      </Defs>
      <G id="surface1">
        <G clipPath="url(#clip1)" clipRule="nonzero">
          <Path
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: 'rgb(26.062012%,40.423584%,73.033142%)',
              fillOpacity: 1,
            }}
            d="M 0.15625 198.421875 L 198.421875 198.421875 L 198.421875 0 L 0.15625 0 Z M 0.15625 198.421875 "
          />
        </G>
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 91.535156 73.820312 C 91.535156 79.296875 87.09375 83.738281 81.613281 83.738281 C 76.132812 83.738281 71.691406 79.296875 71.691406 73.820312 C 71.691406 68.339844 76.132812 63.898438 81.613281 63.898438 C 87.09375 63.898438 91.535156 68.339844 91.535156 73.820312 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 127.042969 73.820312 C 127.042969 79.296875 122.601562 83.738281 117.121094 83.738281 C 111.640625 83.738281 107.199219 79.296875 107.199219 73.820312 C 107.199219 68.339844 111.640625 63.898438 117.121094 63.898438 C 122.601562 63.898438 127.042969 68.339844 127.042969 73.820312 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(98.023987%,80.300903%,3.364563%)',
            fillOpacity: 1,
          }}
          d="M 121.214844 94.320312 L 121.214844 99.261719 C 121.214844 105.054688 118.914062 110.609375 114.820312 114.707031 C 110.722656 118.804688 105.167969 121.105469 99.375 121.105469 C 93.582031 121.105469 88.027344 118.804688 83.929688 114.707031 C 79.832031 110.609375 77.53125 105.054688 77.53125 99.261719 L 77.53125 94.320312 L 64.109375 94.320312 L 64.101562 99.261719 C 64.101562 108.613281 67.820312 117.582031 74.433594 124.195312 C 81.046875 130.808594 90.015625 134.523438 99.367188 134.523438 C 108.71875 134.523438 117.691406 130.808594 124.300781 124.195312 C 130.914062 117.582031 134.628906 108.613281 134.628906 99.261719 L 134.628906 94.320312 Z M 121.214844 94.320312 "
        />
        <Path
          style={{
            fill: 'none',
            strokeWidth: 1,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            stroke: 'rgb(92.945862%,11.064148%,14.105225%)',
            strokeOpacity: 1,
            strokeMiterlimit: 10,
          }}
          d="M -0.000675 0.0016625 C -0.000675 -39.138963 -31.727238 -70.865525 -70.867863 -70.865525 C -110.004581 -70.865525 -141.731144 -39.138963 -141.731144 0.0016625 C -141.731144 39.138381 -110.004581 70.864944 -70.867863 70.864944 C -31.727238 70.864944 -0.000675 39.138381 -0.000675 0.0016625 Z M -0.000675 0.0016625 "
          transform="matrix(1,0,0,-1,170.0788,99.2126)"
        />
      </G>
    </Svg>
  );

  const SVGComponentFouth = () => (
    <Svg width="198.423pt" height="198.423pt" viewBox="0 0 198.423 198.423">
      <Defs>
        <ClipPath id="clip1">
          <Path d="M 0 0 L 198.421875 0 L 198.421875 198.421875 L 0 198.421875 Z M 0 0 " />
        </ClipPath>
      </Defs>
      <G id="surface1">
        <G clipPath="url(#clip1)" clipRule="nonzero">
          <Path
            style={{
              stroke: 'none',
              fillRule: 'nonzero',
              fill: 'rgb(98.023987%,80.300903%,3.364563%)',
              fillOpacity: 1,
            }}
            d="M 0 198.421875 L 198.421875 198.421875 L 198.421875 0 L 0 0 Z M 0 198.421875 "
          />
        </G>
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(26.062012%,40.423584%,73.033142%)',
            fillOpacity: 1,
          }}
          d="M 63.964844 82.085938 L 77.378906 82.085938 L 77.378906 102.539062 C 77.378906 108.332031 79.679688 113.886719 83.773438 117.984375 C 87.871094 122.082031 93.425781 124.382812 99.21875 124.382812 C 105.011719 124.382812 110.566406 122.082031 114.664062 117.984375 C 118.761719 113.886719 121.0625 108.332031 121.0625 102.539062 L 121.0625 82.085938 L 134.476562 82.085938 L 134.476562 102.539062 C 134.476562 111.890625 130.761719 120.863281 124.148438 127.476562 C 117.535156 134.085938 108.566406 137.804688 99.210938 137.804688 C 89.859375 137.804688 80.890625 134.085938 74.277344 127.476562 C 67.664062 120.863281 63.949219 111.890625 63.949219 102.539062 Z M 63.964844 82.085938 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(26.062012%,40.423584%,73.033142%)',
            fillOpacity: 1,
          }}
          d="M 63.949219 82.085938 L 134.476562 82.085938 L 134.476562 96.105469 L 63.949219 96.105469 Z M 63.949219 82.085938 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 70.957031 60.621094 L 93.738281 60.621094 L 93.738281 71.136719 L 70.957031 71.136719 Z M 70.957031 60.621094 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(100%,100%,100%)',
            fillOpacity: 1,
          }}
          d="M 104.25 60.621094 L 127.027344 60.621094 L 127.027344 71.136719 L 104.25 71.136719 Z M 104.25 60.621094 "
        />
        <Path
          style={{
            fill: 'none',
            strokeWidth: 1,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            stroke: 'rgb(92.945862%,11.064148%,14.105225%)',
            strokeOpacity: 1,
            strokeMiterlimit: 10,
          }}
          d="M -0.000675 0.0016625 C -0.000675 -39.138963 -31.727238 -70.865525 -70.867863 -70.865525 C -110.004581 -70.865525 -141.731144 -39.138963 -141.731144 0.0016625 C -141.731144 39.138381 -110.004581 70.864944 -70.867863 70.864944 C -31.727238 70.864944 -0.000675 39.138381 -0.000675 0.0016625 Z M -0.000675 0.0016625 "
          transform="matrix(1,0,0,-1,170.0788,99.2126)"
        />
      </G>
    </Svg>
  );

  return (
    <Document>
      <Page>
        <View>
          <View style={{ position: 'absolute', top: 10, left: 10 }}>
            <View>
              <SVGComponentFirst />
            </View>
            <View style={{ marginTop: 10 }}>
              <SVGComponentSecond />
            </View>
          </View>
          <View style={{ position: 'absolute', top: 10, right: 10 }}>
            <View>
              <SVGComponentThird />
            </View>
            <View style={{ marginTop: 10 }}>
              <SVGComponentFouth />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default StickersTemplate;
