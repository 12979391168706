import { css } from 'emotion';

export const mb30 = css({
  marginBottom: '1.875em',
});

export const sortDropdown = css({
  width: '100%',
  marginBottom: '0.9375em !important',
  marginTop: '0.9375em !important',
  '& .ant-select-selector': {
    width: '100% !important',
  },
});

export const categoriesContainer = theme =>
  css({
    border: `1px solid ${theme.gray500}`,
    boxSizing: 'border-box',
    boxShadow: `0px 2px 8px ${theme.gray_rgba}`,
    borderRadius: '3px',
    fontSize: '0.875rem',
    marginBottom: '1.875em',
  });

export const shippingBoxWrapper = theme =>
  css({
    padding: '0.75em 0.5em 0.625em 0.5em',
    background: `${theme.lightgray} !important`,
    '& label': {
      marginBottom: '0.5em',
      fontSize: '1rem',
      fontWeight: 500,
      display: 'block',
    },
    '& .ant-tag': {
      background: theme.lightblue_rgba,
      marginBottom: '0.3125em',
      border: 'none',
      borderRadius: 4,
      fontWeight: 400,
      fontSize: '0.875rem',
    },
  });

export const mainContainer = theme =>
  css({
    '& .underline': {
      borderBottom: `1px solid ${theme.gray500}`,
    },
    '& h6.section-subtitle': {
      fontSize: '1rem',
      fontWeight: 500,
      marginBottom: '1.5em',
    },
    '& .mt-30': {
      marginTop: '1.875em',
    },
    '& h6.rating-title': {
      marginBottom: '0.9375em',
    },
    '& .merchant-save-filter': {
      marginTop: '2em',
      '& > input': {
        marginBottom: '0.5em',
      },
    },
  });

export const checkboxesWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  margin: '1.875em 0',
  '& .ant-checkbox-wrapper + .ant-checkbox-wrapper': {
    marginLeft: 0,
    marginTop: '0.3125em',
  },
});
