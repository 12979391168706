import disputeIcon from 'assets/icons/notifications/icn-dispute.png';
import reviewIcon from 'assets/icons/notifications/icn-review.png';
import dealIcon from 'assets/icons/notifications/icn-deal.png';
import cartIcon from 'assets/icons/notifications/icn-cart.png';
import businessIcon from 'assets/icons/notifications/icn-business.png';
import manual from 'assets/icons/notifications/icn-manual.png';
import refund from 'assets/icons/notifications/icn-refund.png';

export const notificationType = {
  // USER NOTIFICATIONS
  UserDisputeStarted: {
    icon: disputeIcon,
    url: ({ dealName }) => `/deal/${dealName}/reviews`,
  },
  UserDisputeApproved: {
    icon: disputeIcon,
    url: ({ dealName }) => `/deal/${dealName}/reviews`,
  },
  UserDisputeDeclined: {
    icon: disputeIcon,
    url: ({ dealName }) => `/deal/${dealName}/reviews`,
  },
  UserReviewStarted: {
    icon: reviewIcon,
    url: ({ dealId }) => `/deal/${dealId}/leave-review`,
  },
  UserDealAvailableInWishlist: {
    icon: dealIcon,
    url: ({ dealName }) => `/deal/${dealName}`,
  },
  UserShoppingCartPending24Hours: {
    icon: cartIcon,
    url: () => '/shopping-cart',
  },
  UserOrderRefundApprovedCM: {
    icon: refund,
    url: ({ dealName }) => `/deal/${dealName}`,
  },
  UserOrderRefundDeniedCM: {
    icon: refund,
    url: ({ dealName }) => `/deal/${dealName}`,
  },
  // MERCHANT NOTIFICATIONS
  MerchantBusinessComment: {
    icon: businessIcon,
    url: () => '/merchant-office/merchant-accounts',
  },
  MerchantDealComment: {
    icon: dealIcon,
    url: () => '/merchant-office/my-deals?pageNumber=1&pageSize=10',
  },
  MerchantBusinessVerificationApproved: {
    icon: businessIcon,
    url: () => '/merchant-office/merchant-accounts',
  },
  MerchantBusinessVerificationDeclined: {
    icon: businessIcon,
    url: () => '/merchant-office/merchant-accounts',
  },
  MerchantBusinessDetailVerificationApproved: {
    icon: businessIcon,
    url: () => '/merchant-office/merchant-accounts',
  },
  MerchantBusinessDetailVerificationDeclined: {
    icon: businessIcon,
    url: () => '/merchant-office/merchant-accounts',
  },
  MerchantBusinessAddressVerificationApproved: {
    icon: businessIcon,
    url: () => '/merchant-office/address-book',
  },
  MerchantBusinessAddressVerificationDeclined: {
    icon: businessIcon,
    url: () => '/merchant-office/address-book',
  },
  MerchantBusinessAddressDetailVerificationApproved: {
    icon: businessIcon,
    url: () => '/merchant-office/address-book',
  },
  MerchantBusinessAddressDetailVerificationDeclined: {
    icon: businessIcon,
    url: () => '/merchant-office/address-book',
  },
  MerchantDealBought: {
    icon: dealIcon,
    url: ({ dealName }) => `/deal/${dealName}`,
  },
  MerchantDealApproved: {
    icon: dealIcon,
    url: ({ dealName }) => `/deal/${dealName}`,
  },
  MerchantDealDeclined: {
    icon: dealIcon,
    url: ({ dealName }) => `/deal/${dealName}`,
  },
  MerchantDisputeApproved: {
    icon: disputeIcon,
    url: ({ dealName }) => `/deal/${dealName}/reviews`,
  },
  MerchantDisputeDeclined: {
    icon: disputeIcon,
    url: ({ dealName }) => `/deal/${dealName}/reviews`,
  },
  MerchantReviewPosted: {
    icon: reviewIcon,
    url: ({ dealName }) => `/deal/${dealName}/reviews`,
  },
  MerchantOrderRefundRequested: {
    icon: refund,
    url: ({ dealTitle }) => `/my-profile/coupons?searchText=${dealTitle}`,
  },
  MerchantOrderRefundApproved: {
    icon: refund,
    url: ({ dealTitle }) => `/my-profile/coupons?searchText=${dealTitle}`,
  },
  MerchantCouponRefundDenied: {
    icon: refund,
    url: ({ dealTitle }) => `/my-profile/coupons?searchText=${dealTitle}`,
  },
  Manual: {
    icon: manual,
    url: () => {},
  },
};
