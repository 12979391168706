import React, { useRef, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Button, Row, Col, Skeleton } from 'components/UIExternal';
import { SummaryDealCard, SummaryPricesCard, EmptyShoppingCart } from 'components/screens';
import ConfirmationPopup from 'components/popups/ConfirmationPopup';
import { useShoppingCart } from 'stores';
import { Routes } from 'enums';
import { mainContainer, sectionTitle, merchantListItemContainer, itemsCountStyles, headerSection } from './styles';

const ShoppingCart = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { shoppingCart, updateDealQuantity, removeDeal, addPromoCode, removePromoCode } = useShoppingCart();
  const [showModal, setShowModal] = useState(false);
  const removedDealId = useRef();
  const cartItems = shoppingCart?.businesses?.flatMap(business => business.items);
  const isLoaded = !isNil(shoppingCart);

  const handleOpenConfirmationModal = id => {
    setShowModal(true);
    removedDealId.current = id;
  };

  const handleDeleteItemFromShoppingCart = () => {
    setShowModal(false);
    removeDeal(removedDealId.current);
  };

  return (
    <section className={`content-container ${mainContainer(theme)}`}>
      <div>
        <div className={headerSection}>
          <h2 className={sectionTitle(theme)}>{getText('shoppingCart')}</h2>
          <Button type="link" small linkTo={Routes.dealListingPage}>
            {getText('keepShopping')}
          </Button>
        </div>
        {isLoaded && !!cartItems.length && (
          <p className={itemsCountStyles(theme)}>{`${cartItems.length} ${getText('items')}`}</p>
        )}
      </div>

      {isLoaded && !cartItems?.length ? (
        <EmptyShoppingCart big />
      ) : (
        <Row gap={12}>
          <Col lg={8} md={12}>
            {isLoaded ? (
              shoppingCart?.businesses.map(businesses => (
                <div className={merchantListItemContainer(theme)} key={businesses?.businessId}>
                  <SummaryDealCard
                    businesses={businesses}
                    onRemoveDeal={handleOpenConfirmationModal}
                    onQuantityChange={updateDealQuantity}
                    onAddPromoCode={addPromoCode}
                    onRemovePromoCode={removePromoCode}
                  />
                </div>
              ))
            ) : (
              <Skeleton height={320} />
            )}
          </Col>
          <Col lg={4} md={12}>
            {isLoaded ? (
              <SummaryPricesCard shoppingCart={shoppingCart} linkTo={Routes.shoppingCartCheckout} />
            ) : (
              <Skeleton width="100%" height={160} />
            )}
          </Col>
        </Row>
      )}

      <ConfirmationPopup
        isModalVisible={showModal}
        handleOk={handleDeleteItemFromShoppingCart}
        handleCancel={() => setShowModal(false)}
        text={getText('sureWantToRemoveDealFromCart')}
      />
    </section>
  );
};

export default ShoppingCart;
