import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { BaseCheckbox } from 'components/ui/Checkbox';
import ContinentCollapse from '../ContinentsCollapse';
import { checkbox, continentName } from '../ContinentsCollapse/styles';
import { worldWideOptionContainer } from './styles';

const ContinentsTab = ({ countries, setCountries }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const continents = useLocations(locationState => locationState?.continents);

  return (
    <>
      <div className={worldWideOptionContainer}>
        <BaseCheckbox
          theme={theme}
          className={checkbox(theme, !countries.length)}
          checked={!countries.length}
          onChange={e => e.target.checked && countries?.length && setCountries([])}
        />
        <span className="default-font">{getText('worldwide')}</span>
        <label className={continentName(theme)}>{getText('allCountries')}</label>
      </div>

      {continents?.map(continent => (
        <ContinentCollapse key={continent.id} continent={continent} countries={countries} setCountries={setCountries} />
      ))}
    </>
  );
};

ContinentsTab.propTypes = {
  countries: PropTypes.array,
  setCountries: PropTypes.func,
  queryKey: PropTypes.string,
};

export default ContinentsTab;
