import { css } from 'emotion';

export const ordersListContainer = theme =>
  css({
    borderRadius: 5,
    border: `1px solid ${theme.gray500}`,
    flex: '2 0 0',
    padding: '0 0 1em',
    overflow: 'hidden auto',
    marginBottom: '1em',

    '& .list': {
      height: '60vh',
      margin: '0 0 0.7em',
      overflowY: 'auto',
    },
  });

export const orderNoOrdersFound = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50vh',

  '& img': {
    height: '7rem',
    margin: '0 0 1em',
  },
});
