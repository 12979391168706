import Discounted from 'assets/images/categories/Discounted.png';
import Featured from 'assets/images/categories/Featured.png';
import GlobalAndDigital from 'assets/images/categories/Digital.png';
import Hobby from 'assets/images/categories/Entertaiment.png';
import HomeAndAuto from 'assets/images/categories/HomeAuto.png';
import Products from 'assets/images/categories/Products.png';
import Services from 'assets/images/categories/Services.png';
import Travel from 'assets/images/categories/Travel.png';

export const categoryIcons = {
  featured: 'icon-Star',
  discounted: 'icon-Percent'
};

export const categoryCardImages = {
  services: Services,
  travel: Travel,
  'home-and-auto': HomeAndAuto,
  products: Products,
  'entertainment-and-hobby': Hobby,
  'global-and-digital': GlobalAndDigital,
  featured: Featured,
  discounted: Discounted,
};
