import { css } from 'emotion';
import { commonStyles } from '../../ui/CommonStyles';

export const TAG_TYPE = {
  GREEN: 'green',
  GREEN_LIGHT: 'greenLight',
  BLUE: 'blue',
  BLUE_LIGHT: 'blueLight',
  YELLOW: 'yellow',
  YELLOW_LIGHT: 'yellowLight',
  GRAY: 'gray',
  GRAY_LIGHT: 'grayLight',
  RED: 'red',
  RED_LIGHT: 'redLight',
  PURPLE: 'purple',
  PURPLE_LIGHT: 'purpleLight',
  WARNING: 'warning',
};

export const backgroundColorMap = theme => ({
  [TAG_TYPE.GREEN]: theme.green,
  [TAG_TYPE.GREEN_LIGHT]: theme.green3,
  [TAG_TYPE.BLUE]: theme.blue,
  [TAG_TYPE.BLUE_LIGHT]: theme.primary_blue,
  [TAG_TYPE.GRAY]: theme.background100,
  [TAG_TYPE.GRAY_LIGHT]: theme.gray500,
  [TAG_TYPE.YELLOW]: theme.primary_yellow,
  [TAG_TYPE.YELLOW_LIGHT]: theme.pale_yellow,
  [TAG_TYPE.RED]: theme.red_dark,
  [TAG_TYPE.RED_LIGHT]: theme.red,
  [TAG_TYPE.PURPLE]: theme.purple,
  [TAG_TYPE.PURPLE_LIGHT]: theme.violet,
  [TAG_TYPE.WARNING]: theme.primary_orange,
});

const colorMap = type => theme =>
  [
    TAG_TYPE.GREEN,
    TAG_TYPE.BLUE,
    TAG_TYPE.GRAY,
    TAG_TYPE.RED,
    TAG_TYPE.RED_LIGHT,
    TAG_TYPE.PURPLE,
    TAG_TYPE.WARNING,
  ].includes(type)
    ? theme.white
    : theme.gray100;

export const colorTagContainer = props => theme => {
  const { type = TAG_TYPE.GREEN_LIGHT } = props;
  const backgroundColor = backgroundColorMap(theme)[type] ?? theme.green3;
  const color = colorMap(type)(theme);

  return css([
    {
      backgroundColor,
      color,
      borderRadius: '10rem',
      padding: '0.2em 0.4em',
      textTransform: 'uppercase',
    },
    ...commonStyles(props),
  ]);
};
