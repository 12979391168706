import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Icon } from 'components';
import { isObjectLike, isFunction } from 'lodash';
import { useAutocomplete } from './useAutocomplete';
import {
  searchBarContainer,
  searchBarInputContainer,
  searchBarInput,
  searchBarInputIcon,
  searchBarSuggestionsContainer,
  searchBarSuggestionItem,
} from './styles';

export const SearchBar = props => {
  const { displayKey = 'label', className, renderInput, renderSuggestionsContainer, renderSuggestion } = props;
  const { inputProps, suggestionItemProps, isOpen, suggestions, rootNodeRef } = useAutocomplete(props);
  const theme = useTheme();

  const listOfSuggestions = suggestions?.map((el, i) => {
    const itemProps = {
      className: searchBarSuggestionItem(theme),
      key: `suggestion-${i}`,
      role: 'button',
      tabIndex: 0,
      onBlur: inputProps.onBlur,
      onClick: () => suggestionItemProps.onClick(el),
      onKeyDown: suggestionItemProps.onKeyDown,
      [suggestionItemProps.itemIdAttr]: i,
    };
    return isFunction(renderSuggestion) ? (
      renderSuggestion({ itemProps, suggestionItemProps, item: el })
    ) : (
      <div {...itemProps}>{isObjectLike(el) ? el[displayKey] : el}</div>
    );
  });

  return (
    <div ref={rootNodeRef} className={`${searchBarContainer(props)} ${className}`}>
      {isFunction(renderInput) ? (
        renderInput({
          containerProps: { className: searchBarInputContainer(props, theme) },
          inputProps: { className: searchBarInput, ...inputProps },
          iconProps: {
            iconName: 'las la-search',
            className: searchBarInputIcon(theme),
          },
        })
      ) : (
        <div className={searchBarInputContainer(props, theme)}>
          <label htmlFor={inputProps.id}>
            <Icon iconName="las la-search" className={searchBarInputIcon(theme)} />
          </label>
          <input className={searchBarInput} {...inputProps} />
        </div>
      )}

      {isFunction(renderSuggestionsContainer)
        ? renderSuggestionsContainer({
            isOpen,
            suggestions,
            className: searchBarSuggestionsContainer(theme),
            children: listOfSuggestions,
          })
        : isOpen &&
          !!suggestions?.length && <div className={searchBarSuggestionsContainer(theme)}>{listOfSuggestions}</div>}
    </div>
  );
};

SearchBar.propTypes = {
  inputRef: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  displayKey: PropTypes.string,
  renderInput: PropTypes.func,
  renderSuggestionsContainer: PropTypes.func,
  renderSuggestion: PropTypes.func,
};
