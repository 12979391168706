import { css } from 'emotion';

export const whiteHeaderContainer = theme =>
  css({
    backgroundColor: theme.white,
    boxShadow: '0px 2px 16px rgba(34, 34, 34, 0.12)',
    position: 'relative',
    zIndex: 200,
  });

export const bottomHeader = css({
  paddingTop: '0.625em',
  paddingBottom: '0.625em',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap-reverse',
  '& .ant-select-auto-complete': {
    width: '100%',
    '& .ant-select-selection-search-input': {
      borderRadius: '15px',
    },
  },
});

export const nearbyButton = theme =>
  css({
    color: theme.gray100,
    marginLeft: '.3em',
  });

export const mapAndAddDealContainer = css({
  display: 'flex',
  alignItems: 'center',
  gap: '1em',
});

export const categoryBtnContainer = css({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5em',
});

export const categoryBtnStyle = (theme, active) =>
  css({
    borderRadius: '40px',
    padding: '0.25em 0.75em',
    backgroundColor: active ? theme.background100 : theme.gray550,
    border: 'none',
    position: 'relative',
    cursor: 'pointer',
    ...(active && {
      color: theme.white,
      fontWeight: '500',
    }),
    '&:hover': {
      backgroundColor: theme.background200,
      color: theme.white,
    },
  });

export const categoriesListContainer = theme =>
  css({
    // We need to use double selector of emotion css class name + ant-tooltip to increase the complexity
    // of this selector and there will be no need to use !important inside maxWidth and position
    '&.ant-tooltip': {
      overflow: 'scroll',
      maxHeight: '62vh',
      maxWidth: 'none',
      position: 'fixed',

      '& .ant-tooltip-inner': {
        width: 'fit-content',
        backgroundColor: theme.white,
        boxShadow: '0px 2px 16px rgba(34, 34, 34, 0.12)',
        padding: '1em 0 0',
        borderRadius: '0 0 8px 8px',
        display: 'flex',
        flexFlow: 'row nowrap',
        margin: '-0.4em 0',
      },

      '& .ant-tooltip-arrow': {
        visibility: 'hidden',
      },
    },
  });

export const categoriesHeading = theme =>
  css({
    color: theme.gray300,
    padding: '0 1em 0.5em',
  });

export const categoriesItem = (theme, active) =>
  css(
    {
      '& *': {
        color: theme.gray100,
      },
      '&:hover': {
        '& *': {
          color: theme.white,
        },
        backgroundColor: theme.background200,
      },
    },
    active && {
      '& *': {
        color: theme.blue,
        fontWeight: 500,
      },
    },
  );

export const productsCategoriesItemButton = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1em',
  cursor: 'pointer',
});

export const iconAndCategory = css({
  display: 'flex',
  gap: '0.5em',
  alignItems: 'center',
});

export const iconStyle = theme =>
  css({
    color: theme.gray100,
    '&:hover': {
      color: theme.white,
    },
  });

export const categoryItem = css({
  fontSize: '1.1rem',
});
