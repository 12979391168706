import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const breadcrumbsStyles = theme =>
  css({
    marginTop: '2.5em',
    '& > li ': {
      display: 'flex',
      alignItems: 'center',
    },
    '& > li a': {
      color: theme.gray100,
    },
  });

export const titleForm = theme =>
  css({
    fontWeight: 500,
    fontSize: '2.375rem',
    color: theme.background100,
    marginBottom: 0,
    [Mobile]: {
      fontSize: '1.375rem',
    },
  });

export const formNote = css({
  fontSize: '1rem',
  marginBottom: '3.125em',
});

export const accountFormWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  '& label': {
    fontSize: '0.8125rem !important',
    fontWeight: 500,
  },
  '& .ant-input::placeholder, .ant-select-selection-placeholder': {
    fontSize: '0.75rem',
  },
  '& .ant-form-item-label': {
    paddingBottom: '0 !important',
  },
});

export const formSectionWrapper = theme =>
  css({
    [Desktop]: {
      border: `2px solid ${theme.gray500}`,
      padding: '1.375em 1.75em',
      marginBottom: '1.4375em',
    },
  });

export const formSubTitle = theme =>
  css({
    fontSize: '1.125rem',
    fontWeight: 700,
    marginBottom: 0,
    paddingBottom: '0.75em',
    borderBottom: `1px solid ${theme.gray500}`,
  });

export const baseInput = css({
  marginBottom: '1.375em',
  width: '70%',
  [Mobile]: {
    flexDirection: 'column',
    width: '100%',
  },
});

export const agreementCheckboxWrapper = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.25em',
    '& .ant-checkbox-wrapper': {
      marginRight: '0.5em',
      [Desktop]: {
        marginLeft: '0.9375em',
      },
    },
    '& p': {
      color: theme.gray200,
      margin: 0,
    },
  });

export const acceptLabel = theme =>
  css({
    color: theme.gray200,
    fontWeight: '400 !important',
    [Desktop]: {
      marginLeft: '0.9375em',
    },
  });

export const formButtons = css({
  marginTop: '1.25em',
  marginBottom: '4.875em',
  display: 'flex !important',
  justifyContent: 'space-between',
  '& span': {
    margin: 'auto',
  },
  [Mobile]: {
    flexDirection: 'column-reverse',
    marginBottom: '3em',
  },
});

export const rulesModal = css({
  textDecoration: 'underline',
  padding: 0,
  marginLeft: '0.25em',
});

export const acceptCheckboxWrapper = theme =>
  css({
    marginBottom: '0.75em',
    display: 'flex',
    alignItems: 'baseline',
    '& .ant-form-item': {
      marginBottom: 0,
    },
    '& .ant-checkbox-wrapper': {
      marginLeft: 0,
    },
    '& .ant-checkbox-checked .ant-checkbox-inner': {
      backgroundColor: theme.green2,
      borderColor: theme.green2,
    },
    '& .ant-checkbox-checked.ant-checkbox-inner': {
      borderColor: theme.green2,
    },
    '& label': {
      fontWeight: 500,
      marginLeft: '0.5em',
    },
  });

export const currencyDropdown = css({
  width: '25%',
  [Mobile]: {
    width: '45%',
  },
  [Desktop]: {
    marginBottom: '0.625em !important',
  },
});
