const newestOldestList = {
  Newest: {
    id: 0,
    name: 'Newest',
  },
  Oldest: {
    id: 1,
    name: 'Oldest',
  },
};

const PriceSortOptions = {
  PriceAscending: {
    id: 2,
    name: 'PriceAscending',
  },
  PriceDescending: {
    id: 3,
    name: 'PriceDescending',
  },
};

export const getSortOptions = excludePriceOptions => {
  let options = { ...newestOldestList };

  if (!excludePriceOptions) options = Object.assign(options, PriceSortOptions);

  return options;
};
