import { css } from 'emotion';
import { Mobile, Phone } from 'assets/css/breakpoints';

export const merchantListContainer = css({
  display: 'flex',
  [Mobile]: { flexDirection: 'column' },
  gap: '2rem',
});

export const merchantListFilters = css({
  flex: '1 1 0',
});

export const filterContainer = theme =>
  css({
    padding: '1em 0',
    '& .filter-title': {
      fontWeight: 700,
    },
    '& .not-selected': {
      padding: '1em 0',
      borderBottom: `1px solid ${theme.gray500}`,
      color: theme.gray300
    },
    '& .selected': {
      background: theme.gray600,
      borderRadius: 20,
      padding: '0.8em 1em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .currentSelection': {
        color: theme.gray100,
        '& .label': {
          marginBottom: '0.5em',
          color: theme.gray300,
        },
      },
    },
  });

export const merchantListCardContainer = theme =>
  css({
    flex: '3 1 0',
    '& .no-merchant-found': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
    },
    '& .merchant-found': {
      color: theme.gray300,
      fontWeight: 400,
    },
  });

export const merchantListHeader = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  margin: '2rem 0 1rem',
  '& .title': {
    flex: '2 1 0',
  },
  [Mobile]: {
    flexDirection: 'column',
    gap: '1rem',
  },
});

export const filterButtonsMobileContainer = css({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  '& .cta-buttons': {
    display: 'flex',
    gap: '1rem',
    flex: '3 1 0',
  },
  [Phone]: {
    flexDirection: 'column',
    gap: '1rem',
  },
});

export const clear = css({
  flex: '2 1 0',
});

export const filterButtonMobile = (isSelected, theme) =>
  css({
    background: isSelected ? theme.skyblue : theme.white,
  });

export const filterModal = css({
  '& > div': {
    height: '32rem',
  },
});

export const selectFilterButton = css({
  padding: 0,
  marginTop: '1.5em',
});

export const filterHeader = theme =>
  css({
    borderBottom: `1px solid ${theme.gray500}`,
    padding: '.8em 0',
    textTransform: 'uppercase',
  });

export const searchBar = theme =>
  css({
    display: 'block',
    boxShadow: 'none',
    '&.ant-input-affix-wrapper': {
      display: 'flex',
      padding: '1em',
      flex: '1 1 0',
      borderRadius: 40,
    },
    '&.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused, &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover': {
      outline: 'none',
      border: `1px solid ${theme.gray100}85`,
      boxShadow: 'none',
    },
  });
