import { css } from 'emotion';

const itemHeight = 51;
const borderRadius = 4;

export const dropDownContainer = (disabled, className, theme) =>
  css(
    {
      position: 'relative',
      cursor: disabled ? 'not-allowed' : 'pointer',
      opacity: disabled ? 0.3 : 1,
      outline: 'none',
      fontSize: '0.875rem',
      fontWeight: 400,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      border: `1px solid ${theme.gray500}`,
      borderRadius,
    },
    className,
  );

export const dropDownSelect = (isSelected, small, theme, className) =>
  css(
    {
      border: `1px solid ${theme.white}`,
      borderRadius,
      backgroundColor: theme.whiteGrayLight,
      padding: '0.3em 0.8em',
      color: isSelected ? theme.gray200 : theme.gray400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      overflow: isSelected ? 'hidden' : 'none',
      maxHeight: isSelected ? '3.125rem' : 'auto',
      overflowY: 'auto',
      // overflowY: isSelected ? 'scroll' : 'auto',
    },
    small && {
      padding: '0.25em 0.75em',
    },
    className,
  );

export const dropDownText = isSelected =>
  css({
    userSelect: isSelected ? 'text' : 'none',
    maxHeight: isSelected ? '3.125rem' : 'auto',
    fontSize: isSelected ? '0.875rem' : '0.75rem',
  });

export const dropDownOptionsContainer = (isOpened, numberOfItems, maxNumber, onTop, onLeft, theme) =>
  css(
    {
      position: 'absolute',
      zIndex: 40,
      color: theme.gray100,
      minWidth: '100%',
      height: 'auto',
      maxHeight: numberOfItems > maxNumber ? maxNumber * itemHeight - itemHeight / 2 : 'none',
      overflowY: 'auto',
      backgroundColor: theme.gray600,
      transform: `scaleY(${isOpened ? 1 : 0})`,
      transformOrigin: onTop ? 'bottom' : 'top',
      transition: 'transform 400ms ease-in-out',
      cursor: 'default',
    },
    onTop
      ? {
          bottom: '100%',
          boxShadow: '0 -2px 4px rgba(0,0,0,0.3)',
          borderBottom: isOpened ? '1px solid #DDDDDD' : 'none',
        }
      : {
          top: '100%',
          boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
          borderTop: isOpened ? '1px solid #DDDDDD' : 'none',
        },
    onLeft && { right: 0 },
  );

export const dropDownOptionItem = (isSelected, hasRender, small, theme) =>
  css(
    {
      backgroundColor: isSelected ? theme.error : theme.white,
      color: isSelected ? theme.gray100 : '',
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: isSelected ? theme.error : theme.lightgray,
      },
      '&:focus': {
        outline: 'none',
      },
      '& i': {
        marginRight: '0.5em',
      },
    },
    !hasRender && {
      padding: '0.3em 0.8em',
    },
    small && {
      padding: '0.2em 0.6em',
    },
  );

export const dropDownGroupLabel = theme =>
  css({
    margin: 0,
    padding: '0.75em',
    cursor: 'default',
    color: theme.textLightSecondary,
    fontSize: '0.875rem',
    fontWeight: 400,
  });

export const rightIconsContainer = css({
  display: 'flex',
  alignItems: 'center',
});

export const dropDownRightIcon = isOpened =>
  css({
    transform: `rotate(${isOpened ? -180 : 0}deg)`,
    transition: 'transform 0.35s ease-out',
    cursor: 'pointer',
    display: 'inline-flex',
  });

export const dropDownDeleteIcon = theme =>
  css({
    display: 'inline-flex',
    cursor: 'pointer',
    color: theme.textLightSecondary,

    '&:hover': {
      color: theme.textLightPrimary,
    },
  });

export const dropdownSearch = theme =>
  css({
    position: 'sticky',
    top: 0,
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.grayLight}`,
  });

export const noResultsContainer = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.75em 0.25em',
    backgroundColor: theme.white,
  });

export const noResultsIcon = theme =>
  css({
    backgroundColor: theme.info,
    borderRadius: '50%',
    padding: '0.5em',
  });

export const infiniteScroll = css({
  padding: '1em',
  display: 'flex',
  justifyContent: 'center',
});
