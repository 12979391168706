import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Spin } from 'antd';
import { Button, Pagination } from 'components/UIExternal';
import ListingDeal from 'components/deal/ListingDeal';
import ReviewsSummary from 'components/review/ReviewsSummary';
import CommentReview from 'components/review/CommentReview';
import { Routes, errorCodes } from 'enums';
import { getDealDetails, getReviewsList, getDealReviewsSummary } from 'services';
import { mb } from 'assets/css/globalCSS';
import { sortWrapper, titleWrapper, commentsWrapper, backButton } from './styles';

const AllReviews = () => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const history = useHistory();
  const { dealName } = useParams();
  const urlHash = useLocation().hash;
  const starsFilter = urlHash ? urlHash.charAt(1) : null;
  const [deal, setDeal] = useState(null);
  const [reviewsList, setReviewsList] = useState(null);
  const [reviewsSummary, setReviewsSummary] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const dealData = await fetchDealDetail();
    dealData?.id ? fetchDealReviewsSummary(dealData.id) : setReviewsSummary([]);
  };

  const fetchDealDetail = async () => {
    const [detailRes, detailErr] = await getDealDetails(dealName);
    detailErr && setError(detailErr);
    setDeal(detailRes ?? {});
    return detailRes;
  };

  const fetchDealReviewsSummary = async id => {
    const [reviewsRes, reviewsErr] = await getDealReviewsSummary(id);
    reviewsErr ? setError(reviewsErr) : setReviewsSummary(reviewsRes);
    return reviewsRes;
  };

  const fetchReviews = async options => {
    const [res, err] = await getReviewsList(deal?.id, { ...options, stars: starsFilter });
    err ? setError(err?.data?.errors) : setReviewsList(res?.items);
    return res;
  };

  if (isNil(deal) || isNil(reviewsSummary)) {
    return (
      <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
        <Spin size="large" />
      </div>
    );
  }

  if (error?.data?.errors?.[0]?.code === errorCodes.DEAL_NOT_FOUND) return history.replace(Routes.notFound);

  return (
    <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 78 }} className="content-container">
      <Col lg={7} span={24} className="gutter-row">
        <Button type="link" className={backButton(theme)} linkTo={`/deal/${dealName}`}>
          <FeatherIcon icon="arrow-left" size={20} />
          {getText('backToDeal')}
        </Button>
        <div>
          <ListingDeal deal={deal} mainDealName={dealName} isVertical isMerchant={deal.isOwner} />
        </div>
      </Col>
      <Col lg={17} span={24}>
        <div className={`${titleWrapper} desktop-only`}>
          <p>{getText('allReviews')}</p>
          {reviewsSummary.canLeaveReview && (
            <Button type="default" small linkTo={`/deal/${deal.id}/leave-review`}>
              {getText('leaveReview')}
            </Button>
          )}
        </div>
        <ReviewsSummary disabledLinks reviewsAndComments={reviewsSummary} dealName={deal.name} />
        <Pagination onChange={fetchReviews} pageSize={10} justify="center" />
        {!isNil(reviewsList) && (
          <div className={commentsWrapper}>
            <p className={sortWrapper(theme)}>{getText('commentsCount', { count: reviewsList.length })}</p>
            {reviewsList.map(item => (
              <CommentReview reviews={item} />
            ))}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default AllReviews;
