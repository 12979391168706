import React from 'react';
// components
import {
  BrandGuideLinePage,
  BrandGuideWelcomePage,
  BrandGuideSectionPage,
  BrandGuideLineExamplePage,
  BrandGuideLogoPage,
  BrandGuideContentPage,
  BrandGuideBrandColorsPage,
} from 'components/screens/Public/brandGuidePage';

// background images
import guideLineFirstPage from 'assets/images/guideline/brandGuidelinesFirstPage.png';
import guideLinePageWelcomePage from 'assets/images/guideline/brandGuidelinesWelcomePage.png';
import guideLinesSectionPage from 'assets/images/guideline/brandGuideLinesSectionPage.png';

import { guideLines } from 'enums';
import {
  introduction,
  brandValues,
  keyBrandTraits,
  brandMaterialsRules,
  theLogo,
  typography,
  brandColors,
  brandMaterials,
  examples,
} from './texts';

const wrapComponent = (Component, props, key) => ({ content: <Component key={key} {...props} /> });

export const guidePages = [
  { content: <BrandGuideLinePage />, bg: guideLineFirstPage },
  { content: <BrandGuideWelcomePage />, bg: guideLinePageWelcomePage },
  // 1 our brand
  { content: <BrandGuideSectionPage section={guideLines.ourBrand} />, bg: guideLinesSectionPage },
  { content: <BrandGuideContentPage content={introduction} /> },
  ...brandValues.map((content, ind) => wrapComponent(BrandGuideContentPage, { content }, ind)),
  { content: <BrandGuideContentPage content={keyBrandTraits} /> },
  // 2 the logo
  { content: <BrandGuideSectionPage section={guideLines.theLogo} />, bg: guideLinesSectionPage },
  ...theLogo.map((content, ind) => wrapComponent(BrandGuideLogoPage, { content }, ind)),
  // 3 typography
  { content: <BrandGuideSectionPage section={guideLines.typography} />, bg: guideLinesSectionPage },
  ...typography.map((content, ind) => wrapComponent(BrandGuideContentPage, { content }, ind)),
  // 4 brand colors
  { content: <BrandGuideSectionPage section={guideLines.brandColors} />, bg: guideLinesSectionPage },
  ...brandColors.map((content, ind) => wrapComponent(BrandGuideBrandColorsPage, { content }, ind)),
  // 5 examples
  { content: <BrandGuideSectionPage section={guideLines.examples} />, bg: guideLinesSectionPage },
  ...examples.map((content, ind) => wrapComponent(BrandGuideLineExamplePage, { content }, ind)),
  // 6 merchant brand materials
  { content: <BrandGuideSectionPage section={guideLines.merchantBrandMaterials} />, bg: guideLinesSectionPage },
  ...brandMaterials.map((content, ind) => wrapComponent(BrandGuideLineExamplePage, { content }, ind)),
  // 7 brand materials rules
  { content: <BrandGuideSectionPage section={guideLines.brandMaterialsRules} />, bg: guideLinesSectionPage },
  ...brandMaterialsRules.map((content, ind) => wrapComponent(BrandGuideContentPage, { content }, ind)),
];
