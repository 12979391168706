import page10 from 'assets/images/guideline/brandGuidelinesImagePage10.png';
import page11 from 'assets/images/guideline/brandGuidelinesImagePage11.png';
import page121 from 'assets/images/guideline/brandGuidelinesImagePage12_1.png';
import page122 from 'assets/images/guideline/brandGuidelinesImagePage12_2.png';
import page13 from 'assets/images/guideline/brandGuidelinesImagePage13.png';
import page141 from 'assets/images/guideline/brandGuidelinesImagePage14_1.png';
import page142 from 'assets/images/guideline/brandGuidelinesImagePage14_2.png';
import page143 from 'assets/images/guideline/brandGuidelinesImagePage14_3.png';
import page144 from 'assets/images/guideline/brandGuidelinesImagePage14_4.png';
import page145 from 'assets/images/guideline/brandGuidelinesImagePage14_5.png';
import page146 from 'assets/images/guideline/brandGuidelinesImagePage14_6.png';
import page147 from 'assets/images/guideline/brandGuidelinesImagePage14_7.png';
import page148 from 'assets/images/guideline/brandGuidelinesImagePage14_8.png';
import page149 from 'assets/images/guideline/brandGuidelinesImagePage14_9.png';
import page1410 from 'assets/images/guideline/brandGuidelinesImagePage14_10.png';
import page1411 from 'assets/images/guideline/brandGuidelinesImagePage14_11.png';
import page1412 from 'assets/images/guideline/brandGuidelinesImagePage14_12.png';
import page15 from 'assets/images/guideline/brandGuidelinesImagePage15.png';
import page16 from 'assets/images/guideline/brandGuidelinesImagePage16.png';
import page18 from 'assets/images/guideline/brandGuidelinesImagePage18.png';
import page19 from 'assets/images/guideline/brandGuidelinesImagePage19.png';
import page25 from 'assets/images/guideline/brandGuidelinesImagePage25.png';
import page26 from 'assets/images/guideline/brandGuidelinesImagePage26.png';
import page27 from 'assets/images/guideline/brandGuidelinesImagePage27.png';
import page28 from 'assets/images/guideline/brandGuidelinesImagePage28.png';
import page30 from 'assets/images/guideline/brandGuidelinesImagePage30.png';
import page31 from 'assets/images/guideline/brandGuidelinesImagePage31.png';
import page32 from 'assets/images/guideline/brandGuidelinesImagePage32.png';
import page33 from 'assets/images/guideline/brandGuidelinesImagePage33.png';
import page34 from 'assets/images/guideline/brandGuidelinesImagePage34.png';
import page35 from 'assets/images/guideline/brandGuidelinesImagePage35.png';
import page36 from 'assets/images/guideline/brandGuidelinesImagePage36.png';
import page37 from 'assets/images/guideline/brandGuidelinesImagePage37.png';
import page38 from 'assets/images/guideline/brandGuidelinesImagePage38.png';
import page39 from 'assets/images/guideline/brandGuidelinesImagePage39.png';
import page40 from 'assets/images/guideline/brandGuidelinesImagePage40.png';
import page41 from 'assets/images/guideline/brandGuidelinesImagePage41.png';
import page42 from 'assets/images/guideline/brandGuidelinesImagePage42.png';
import page43 from 'assets/images/guideline/brandGuidelinesImagePage43.png';
import page44 from 'assets/images/guideline/brandGuidelinesImagePage44.png';
import page45 from 'assets/images/guideline/brandGuidelinesImagePage45.png';
import page46 from 'assets/images/guideline/brandGuidelinesImagePage46.png';
import page47 from 'assets/images/guideline/brandGuidelinesImagePage47.png';
import page48 from 'assets/images/guideline/brandGuidelinesImagePage48.png';
import page49 from 'assets/images/guideline/brandGuidelinesImagePage49.png';

export const introduction = { title: 'introduction', text1: 'introductionText' };

const brandValues1 = {
  title: 'brandValues',
  subTitle1: 'brandValuesOneSubtitleOne',
  text1: 'brandValuesOneTextOne',
  subTitle2: 'brandValuesOneSubtitleTwo',
  text2: 'brandValuesOneTextTwo',
};

const brandValues2 = {
  subTitle1: 'brandValuesTwoSubtitleOne',
  text1: 'brandValuesTwoTextOne',
  subTitle2: 'brandValuesTwoSubtitleTwo',
  text2: 'brandValuesTwoTextTwo',
  subTitle3: 'brandValuesTwoSubtitleThree',
  text3: 'brandValuesTwoTextThree',
};

export const keyBrandTraits = {
  title: 'keybrandTraits',
  subTitle1: 'keybrandTraitsSubtitleOne',
  text1: 'keybrandTraitsTextOne',
  subTitle2: 'keybrandTraitsSubtitleTwo',
  text2: 'keybrandTraitsTextTwo',
  subTitle3: 'keybrandTraitsSubtitleThree',
  text3: 'keybrandTraitsTextThree',
};

const brandMaterialsRules1 = {
  subTitle1: 'brandMaterialsRulesOneSubtitleOne',
  text1: 'brandMaterialsRulesOneTextOne',
  subTitle2: 'brandMaterialsRulesOneSubtitleTwo',
  text2: 'brandMaterialsRulesOneTextTwo',
  subTitle3: 'brandMaterialsRulesOneSubtitleThree',
  text3: 'brandMaterialsRulesOneTextThree',
};

const brandMaterialsRules2 = {
  subTitle1: 'brandMaterialsRulesTwoSubtitleOne',
  text1: 'brandMaterialsRulesTwoTextOne',
  subTitle2: 'brandMaterialsRulesTwoSubtitleTwo',
  text2: 'brandMaterialsRulesTwoTextTwo',
  subTitle3: 'brandMaterialsRulesTwoSubtitleThree',
  text3: 'brandMaterialsRulesTwoTextThree',
};

export const theLogo = [
  { page: 'page10', title: 'theLogo', imgSrc: page10, text1: 'theLogoText' },
  { page: 'page11:', title: 'lockups&Uses', imgSrc: page11, text1: 'lockupsAndUsesText' },
  {
    page: 'page12',
    title: 'ourLogo',
    subTitle1: 'ourLogoSubtitle',
    text1: 'ourLogoText',
    textSection: [
      { text: 'ourLogoMaxSizeText', imgSrc: page121 },
      { text: 'ourLogoMinSizeText', imgSrc: page122 },
    ],
  },
  { page: 'page13', title: 'oneColorUse', imgSrc: page13, text1: 'oneColorUseText' },
  {
    page: 'page14',
    title: 'logoMisuse',
    textSection: [
      { text: 'logoMisuseNotCrop', imgSrc: page141 },
      { text: 'logoMisuseNotChangeTransparency', imgSrc: page142 },
      { text: 'logoMisuseNotShuffleColors', imgSrc: page143 },
      { text: 'logoMisuseNotUseDiffColors', imgSrc: page144 },
      { text: 'logoMisuseNotChangeSize', imgSrc: page145 },
      { text: 'logoMisuseNotUseLogoOneLine', imgSrc: page146 },
      { text: 'logoMisuseNotDistort', imgSrc: page147 },
      { text: 'logoMisuseNotUseEffects', imgSrc: page148 },
      { text: 'logoMisuseNotReCreate', imgSrc: page149 },
      { text: 'logoMisuseNotOutline', imgSrc: page1410 },
      { text: 'logoMisuseNotRotate', imgSrc: page1411 },
      { text: 'logoMisuseNotChange', imgSrc: page1412 },
    ],
  },
  {
    page: 'page15',
    title: 'partnerShip',
    imgSrc: page15,
    text1: 'partnershipText',
    list: ['partnershipTextListBulletOne', 'partnershipTextListBulletTwo', 'partnershipTextListBulletThree'],
  },
  { page: 'page16', title: 'icons', imgSrc: page16, text1: 'iconsText' },
];

export const typography = [{ page: 'page18', title: 'typeface', img: page18, text1: 'typefaceText' }, { img: page19 }];

export const brandColors = [
  { page: 'page21', title: 'primaryBrandColors', text1: 'primaryBrandColorsText' },
  { page: 'page22', title: 'secondary&SupportiveColors', text1: 'secondarySupportiveColorsText' },
  { page: 'page23', title: 'accessibleColorCombination' },
];

export const brandMaterials = [
  { page: 'page30', text1: 'outdoorPrints', text2: 'examples', img: page30 },
  { page: 'page31', text1: 'advertisingPosters', text2: 'examples', img: page31 },
  { page: 'page32', text1: 'advertisingPosters', text2: 'examples', img: page32 },
  { page: 'page33', text1: 'advertisingPosters', text2: 'examples', img: page33 },
  { page: 'page34', text1: 'outdoorBillboards', text2: 'examples', img: page34 },
  { page: 'page35', text1: 'outdoorBanners', text2: 'examples', img: page35 },
  { page: 'page36', text1: 'facadeBranding', text2: 'examples', img: page36 },
  { page: 'page37', text1: 'vehicleBranding', text2: 'examples', img: page37 },
  { page: 'page38', text1: 'printMaterials', text2: 'examples', img: page38 },
  { page: 'page39', text1: 'brochurePrint', text2: 'examples', img: page39 },
  { page: 'page40', text1: 'tShirt', text2: 'examples', img: page40 },
  { page: 'page41', text1: 'businessCard', text2: 'examples', img: page41 },
  { page: 'page42', text1: 'badges', text2: 'examples', img: page42 },
  { page: 'page43', text1: 'bag', text2: 'examples', img: page43 },
  { page: 'page44', text1: 'indoorPosters', text2: 'examples', img: page44 },
  { page: 'page45', text1: 'notebookDesign', text2: 'examples', img: page45 },
  { page: 'page46', text1: 'capBranding', text2: 'examples', img: page46 },
  { page: 'page47', text1: 'stickersDesign', text2: 'examples', img: page47 },
  { page: 'page48', text1: 'priceLabelsDesign', text2: 'examples', img: page48 },
  { page: 'page49', text1: 'boxStickers', text2: 'examples', img: page49 },
];

export const examples = [
  { page: 'page25', text1: 'peoplePhotography', text2: 'examples', img: page25 },
  { page: 'page26', text1: 'smiling', text2: 'fun', text3: 'engaging', text4: 'expressive', img: page26 },
  { page: 'page27', text1: 'brandedContent', text2: 'examples', img: page27 },
  { page: 'page28', text1: 'flat&Solid', text2: 'decorative', text3: 'bold', img: page28 },
];

export const brandValues = [brandValues1, brandValues2];

export const brandMaterialsRules = [brandMaterialsRules1, brandMaterialsRules2];
