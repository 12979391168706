import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { mainContainer } from './styles';

const PrivacyPolicy = () => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div className={`content-container ${mainContainer(theme)}`}>
      <div className="terms-text-wrap">
        <p>{getText('privacyPolicyText')}</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
