import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const mainContainer = theme =>
  css({
    color: theme.gray100,
    padding: '2em',
  });

export const sectionTitle = theme =>
  css({
    fontSize: '2rem',
    fontWeight: 500,
    color: theme.background100,
    marginBottom: '2em',
    [Mobile]: {
      fontSize: '1.375rem',
    },
  });

export const stepsIndicatorContainer = css({
  position: 'sticky',
  top: '10.625em',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1em',
  '& > a': {
    marginBottom: '0',
  },
});

export const actionButtons = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5em 1em',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: '2em',
  '@media (max-width: 350px)': {
    '& div': {
      width: '100%',
    },
  },
});
