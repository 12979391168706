import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  contentBox: {
    position: 'absolute',
    width: '80%',
    marginLeft: '8%',
    bottom: 20,
  },
  textBox: {
    country: {
      fontSize: 12,
      fontWeight: 600,
      color: '#FECF09',
      marginBottom: 10,
    },
    businessName: {
      fontSize: 15,
      fontWeight: 800,
      color: '#fff',
      marginBottom: 10,
    },
    location: {
      fontSize: 12,
      color: '#fff',
    },
  },
  qrCodeStyles: {
    width: 50,
    marginBottom: 15,
  },
});
