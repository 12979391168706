import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const mainContainer = css({
  padding: '9.375em 0',
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  margin: 0,
  '& h2': {
    fontSize: '2.375rem',
    marginTop: '0.625em',
    textAlign: 'center',
  },
  '& img': {
    [Mobile]: { width: '100%' },
  },
  '& p': {
    fontSize: '1.125rem',
    marginBottom: '2.5em',
    textAlign: 'center',
    [Desktop]: {
      width: '42%',
    },
  },
});
