import React from 'react';
import PropTypes from 'prop-types';
import { isFunction, pick } from 'lodash';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { SearchBar, Icon, Button } from 'components';
import {
  handleSelectCategory,
  flattenCategories,
  useMerchantList,
  findCategoryPath,
  setCategories,
  setBreadcrumbs,
} from 'stores';

import {
  categoryFilterContainer,
  categoryFilterSearchBar,
  categoryFilterItem,
  breadCrumbPath,
  clearFilterButton,
} from './styles';

export const MerchantListCategoryFilter = props => {
  const { onChange, filterKeys, closeModal, hasClear, className, allCategories, withSearch = true } = props;
  const { getText } = useTranslations();
  const theme = useTheme();
  const { breadcrumbs, categories } = useMerchantList(store => pick(store, ['breadcrumbs', 'categories']));

  const handleClearFilters = () => {
    onChange({ [filterKeys.categoryId]: null });
    setCategories({ categoryId: null });
    setBreadcrumbs({ breadcrumbs: null });
  };

  const handleClickOnItem = category => {
    const selectedCategory = category?.id > 0 ? category.id : breadcrumbs[breadcrumbs?.length - 1].id;
    handleSelectCategory(allCategories, category);
    if (!category?.subCategories?.length) {
      isFunction(closeModal) && closeModal();
      onChange({ [filterKeys.categoryId]: selectedCategory });
    }
  };

  return (
    <div className={categoryFilterContainer(className)}>
      {withSearch && (
        <SearchBar
          placeholder={getText('searchForCategory')}
          displayKey="name"
          options={flattenCategories(allCategories)}
          className={categoryFilterSearchBar}
          renderSuggestion={val => (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              {...val.itemProps}
              className={`${val.itemProps.className} suggestion`}
              role={val.itemProps.role}
              onClick={() => {
                handleSelectCategory(allCategories, val.item);
                val.suggestionItemProps.onClick(val.item);
                onChange({ [filterKeys.categoryId]: val.item.id });
                !val.item?.subCategories?.length && isFunction(closeModal) && closeModal();
              }}
            >
              {findCategoryPath(allCategories, val.item.id).map(el =>
                el.subCategories?.length ? `${getText(el.name)} > ` : `${getText(el.name)}`,
              )}
              {!!val.item?.subCategories?.length && <Icon iconName="las la-angle-right" />}
            </div>
          )}
        />
      )}

      <div className={breadCrumbPath(theme)}>
        {!!breadcrumbs?.length &&
          breadcrumbs?.map(breadcrumb => (
            <span key={breadcrumb.id}>
              {breadcrumb.name} {breadcrumb.subCategories?.length ? ' > ' : null}
            </span>
          ))}
      </div>
      {!breadcrumbs?.length ? (
        allCategories?.map(category => (
          <div key={category.id} role="button" tabIndex={0} onClick={() => handleClickOnItem(category)}>
            <div className={categoryFilterItem(theme)}>
              <div>{getText(category.name)}</div>
              <Icon iconName="las la-angle-right" />
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="categories-list">
            {categories?.length > 1 &&
              categories?.map(category => (
                <div key={category.id} role="button" tabIndex={0} onClick={() => handleClickOnItem(category)}>
                  <div className={categoryFilterItem(theme)}>
                    <div>{getText(category.name)}</div>
                    {!!category?.subCategories?.length && <Icon iconName="las la-angle-right" />}
                  </div>
                </div>
              ))}
          </div>
          {hasClear && !!breadcrumbs?.length && (
            <div className={clearFilterButton}>
              <Button small type="info" onClick={handleClearFilters}>
                {getText('clear')}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

MerchantListCategoryFilter.propTypes = {
  onChange: PropTypes.func,
  filterKeys: PropTypes.object,
  closeModal: PropTypes.func,
  hasClear: PropTypes.bool,
  className: PropTypes.string,
  allCategories: PropTypes.array,
  withSearch: PropTypes.bool,
};
