import React, { useState, useCallback, useEffect } from 'react';
import { Upload } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import DraggableUploadListItem from './DraggableUploadListItem';
import { imagesPreview } from '../styles';

const DragSortingUpload = ({ items, onRemove, changeImagesOrder }) => {
  const [imagesList, setImagesList] = useState(items);
  const [isPreviewOpen, setIsPreviewOpen] = useState(null);

  useEffect(() => changeImagesOrder('images', imagesList), [imagesList]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = imagesList[dragIndex];
      dragRow &&
        setImagesList(
          update(imagesList, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          }),
        );
    },
    [imagesList],
  );

  const onChange = fileList => {
    setImagesList(fileList);
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Upload
          fileList={items}
          onChange={onChange}
          onPreview={file => setIsPreviewOpen(file)}
          onRemove={onRemove}
          className={imagesPreview}
          listType="picture"
          itemRender={(originNode, file, currFileList) => (
            <DraggableUploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
              onChange={onChange}
            />
          )}
        />
      </DndProvider>
      <Modal show={Boolean(isPreviewOpen)} onClose={() => setIsPreviewOpen(false)}>
        <img src={isPreviewOpen?.url} alt="preview" />
      </Modal>
    </>
  );
};

DragSortingUpload.propTypes = {
  items: PropTypes.array,
  onPreview: PropTypes.any,
  onRemove: PropTypes.any,
  changeImagesOrder: PropTypes.func,
};

export default DragSortingUpload;
