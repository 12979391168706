import { css } from 'emotion';

export const welcomePageContent = theme =>
  css({
    gridRow: 2,
    marginTop: 'auto',
    marginBottom: '1em',
    '& h1, h3': {
      color: theme.white,
      fontWeight: 'bolder',
    },
    '& h1': { fontSize: '4.5rem' },
    '& h3': { fontSize: '2.5rem' },
  });
