import { css } from 'emotion';

export const brandGuideLogosContainer = theme =>
  css({
    display: 'flex',
    marginTop: '2em',
    background: theme.white,
    gap: '2rem',
    '& .downloadOverflow': {
      opacity: 0,
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      background: theme.gray500,
      textAlign: 'center',
      color: theme.blue,
      fontWeight: 600,
      fontSize: '1.5rem',
      transition: 'opacity .3s ease',
    },
    '& a': {
      position: 'relative',
      display: 'block',
      width: '100%',
      borderRadius: 30,
      overflow: 'hidden',
      '&:hover .downloadOverflow': {
        opacity: 0.8,
        transition: 'opacity .8s ease',
      },
    },
  });
