import { invert } from 'lodash-es';

export const deliveryMethod = {
  selfOrganizedShipping: 1,
  redeemAtLocation: 2,
  onlineService: 3,
};

export const deliveryMethodInverse = invert(deliveryMethod);

export const deliveryMethodSelect = {
  [deliveryMethod.selfOrganizedShipping]: {
    label: 'selfOrganizedShipping',
    icon: 'las la-truck',
  },
  [deliveryMethod.redeemAtLocation]: {
    label: 'redeemAtLocation',
    icon: 'las la-map-marked-alt',
  },
  [deliveryMethod.onlineService]: {
    label: 'onlineService',
    icon: 'las la-globe',
  },
};

export const dealStatus = {
  pending: 1,
  approved: 2,
  declined: 3,
  expired: 4,
  closed: 5,
  soldOut: 6,
  draft: 7,
  suspended: 8,
};

export const dealStatusInverse = invert(dealStatus);

export const dealStatusSelect = Object.entries(dealStatus).map(([label, value]) => ({ label, value }));

export const dealsSliderType = {
  dealsOfTheDay: 1,
  featured: 2,
};

export const dealsSliderTypeInverse = invert(dealsSliderType);

export const dealWeightType = {
  gr: 1,
  kg: 2,
};

export const dealWeightTypeInverse = invert(dealWeightType);

export const dealWeightTypeSelect = [
  {
    label: 'kg',
    value: dealWeightType.kg,
  },
  {
    label: 'gr',
    value: dealWeightType.gr,
  },
];

export const couponStatus = {
  new: 1,
  notRedeemed: 2,
  redeemed: 3,
  cancelled: 4,
  refunded: 5,
  expired: 6,
  cryptoPaid: 7,
};

export const couponStatusInverse = invert(couponStatus);

const refusedReviewsStatus = {
  draft: dealStatus.draft,
  declined: dealStatus.declined,
  expired: dealStatus.expired,
};

export const refusedReviewsStatusInverse = invert(refusedReviewsStatus);

export const sortOptions = {
  newest: 0,
  oldest: 1,
  priceAscending: 2,
  priceDescending: 3,
  topSellers: 4,
};

export const sortOptionsInverse = invert(sortOptions);

export const sortOptionsSelect = getText =>
  Object.entries(sortOptions).map(([label, value]) => ({
    label: getText(label),
    value,
  }));
