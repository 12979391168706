import React from 'react';
import toast from 'react-hot-toast';
import { useTranslations } from '@veraio/strank';

const TextTranslation = ({ text }) => {
  const { getText } = useTranslations();
  return <span>{getText(text)}</span>;
};

export const setError = error => {
  const errors = error?.data?.errors || null;
  if (errors) errors.forEach(errorsKey => toast.error(<TextTranslation text={errorsKey.code} />));
  else toast.error(<TextTranslation text="defaultErrorMessage" />);
};
