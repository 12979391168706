import { css } from 'emotion';

export const businessContainer = (theme, className) =>
  css(
    {
      backgroundColor: theme.gray600,
      padding: '0.75em',
      marginBottom: '0.75rem',
      borderRadius: 8,

      '& .section-subtitle': {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5em 1em',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& .name-verified': {
          display: 'flex',
          gap: '0.5em',
          '& span': {
            margin: '0',
          },
        },
      },
      '& .subtitle': {
        margin: 0,
      },
    },
    className,
  );

export const textHighlight = theme =>
  css({
    color: theme.blue2,
    margin: '0 0.375rem',
  });

export const addressContainer = theme =>
  css({
    borderTop: `1px solid ${theme.gray500}`,
    marginTop: '1.5em',
    padding: '1.5em 0',

    '& .title': {
      marginBottom: '0.75em',
    },
  });

export const actionButton = css({
  padding: 0,
});

export const messageMerchantButtonStyles = css({
  padding: '0',
  fontSize: '0.7em',
});

export const shoppingFromMerchantStyles = css({
  display: 'flex',
  gap: '0.5em',
});
