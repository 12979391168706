import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const actionsWrapper = css({
  [Mobile]: {
    flexDirection: 'column',
    rowGap: '1em',
  },
  margin: '3.125em 0',
});

export const blackTextLink = theme =>
  css({
    color: theme.gray100,
  });
