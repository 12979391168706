import React, { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Col, Modal, Row } from 'antd';
import { DealSkeleton } from 'components/ui';
import { CategoryCard } from 'components';
import { Button } from 'components/UIExternal';
import { getTopCategories } from 'services/api/category';
import useError from 'services/errorHandling/useError';
import { headingPanel, title, panelContent, categoriesColumn } from './styles';

const TopCategoriesPanel = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { getText } = useTranslations();
  const { setError } = useError();
  const [topCategories, setTopCategories] = useState([]);

  useEffect(() => {
    fetchTopCategories();
    window.scrollTo(0, 0);
  }, []);

  const toggleModalVisible = () => setIsModalVisible(!isModalVisible);

  const fetchTopCategories = async () => {
    const [res, err] = await getTopCategories(true, true);
    if (err) return setError(err);
    setTopCategories(res);
  };

  return (
    <div className="content-container">
      <div className={headingPanel}>
        <Col className="desktop-only">
          <span className={title}>{getText('browseDealsFromRangeCategories')}</span>
        </Col>
        <Col span={24} className="mobile-only">
          <Button type="primary" fullWidth onClick={toggleModalVisible}>
            {getText('browseCategories')}
          </Button>
        </Col>
      </div>
      <div className={panelContent}>
        <Col lg={20} className={`${categoriesColumn} desktop-only`}>
          {!topCategories?.length ? (
            <DealSkeleton hideInput numberOfDeals={8} />
          ) : (
            topCategories.map((category, i) => <CategoryCard key={i} category={category} />)
          )}
        </Col>
        <Modal
          title={getText('browseCategories')}
          open={isModalVisible}
          onCancel={toggleModalVisible}
          style={{ top: 0 }}
          footer={null}
        >
          <Row gutter={12} className={categoriesColumn}>
            {topCategories?.map(category => (
              <Col key={category.id} span={12} lg={24}>
                <CategoryCard category={category} />
              </Col>
            ))}
          </Row>
        </Modal>
      </div>
    </div>
  );
};

export default TopCategoriesPanel;
