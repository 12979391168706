import styled from '@emotion/styled';
import { Mobile, Desktop } from 'assets/css/breakpoints';
import { css } from 'emotion';
import icons from 'assets/icons/dealShaker/dealshaker';

export const megaMenuContainer = css({
  alignItems: 'center',
  '& > button .icon:before': {
    content: icons.Hamburger,
  },
  '&:focus-within > button .icon:before': {
    content: icons.Close,
  },
});

export const styledMegaMenu = theme =>
  css({
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
    [Mobile]: {
      display: 'none',
      position: 'absolute',
      backgroundColor: theme.white,
      flexDirection: 'column',
      zIndex: 1,
      left: 0,
      right: 0,
    },
  });

export const MenuItemsContainer = css({
  [Mobile]: {
    padding: '1em 1em 0 1em',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const StyledMenuItem = styled.li`
  float: left;
  overflow: hidden;
  color: ${props => props.theme.gray100};
  & .mobile-content,
  & .desktop-content {
    display: none;
  }
  &:hover .desktop-content {
    display: block;
  }
  ${Desktop} {
    &:first-of-type > div > button,
    &:first-of-type > button {
      padding-left: 0;
    }
  }
  ${Mobile} {
    &:focus-within ~ li:not(:focus-within) {
      height: 0;
    }
    &:focus-within .mobile-content {
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
    }
    &:hover .desktop-content {
      display: none;
    }
  }
`;

export const StyledDropdownContent = styled.div`
  position: absolute;
  background-color: ${props => props.theme.white};
  left: 0;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  ${Mobile} {
    top: 0;
  }
`;

export const StyledDropdownButton = styled.div`
  ${Desktop} {
    & > span.icon {
      display: inline-block;
      transform: rotateZ(90deg);
      margin: auto;
    }
    &:hover > span.icon {
      transform: rotateZ(-90deg);
    }
  }
  ${Mobile} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledTextItem = (theme, isTopLevel) =>
  css({
    padding: 0,
    margin: '0 0.625em',
    textAlign: 'left',
    color: theme.gray100,
    fontWeight: isTopLevel ? 400 : 500,
    [Mobile]: {
      fontWeight: 500,
      paddingLeft: 0,
    },
  });

export const CategoryTitle = styled.label`
  font-size: 1.125rem;
  font-weight: 700;
  display: block;
  border-bottom: 1px solid;
  border-color: ${props => props.theme.gray500};
  line-height: 2.5;
`;
