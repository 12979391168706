import { css } from 'emotion';

export const brandColorsPage21Container = theme =>
  css({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '.3rem',
    height: '100%',
    color: theme.white,
    '& .primaryBlue, .skyBlueDark, .skyBlueLight, .primaryYellow, .paleYellow, .gray100, .gray60, .gray40, .gray20, .gray10, .gray5': {
      display: 'flex',
      alignItems: 'flex-end',
      padding: '1em',
    },
    '& .columnLeft': {
      display: 'grid',
      '& .primaryBlue': {
        gridRow: 'span 5',
        height: '100%',
        backgroundColor: theme.blue,
      },
      '& .skyBlueDark': {
        backgroundColor: theme.primary_blue,
      },
      '& .skyBlueLight': {
        color: theme.gray200,
        backgroundColor: theme.skyblue,
      },
      gridTemplateRows: 'auto auto auto',
    },
    '& .columnMiddle': {
      display: 'grid',
      '& .primaryYellow': {
        color: theme.gray200,
        gridRow: 'span 6',
        backgroundColor: theme.primary_yellow,
      },
      '& .paleYellow': {
        color: theme.gray200,
        backgroundColor: theme.pale_yellow,
      },
    },
    '& .columnRight': {
      display: 'grid',
      '& .gray100': {
        backgroundColor: theme.gray100,
      },
      '& .gray60': {
        backgroundColor: theme.gray200,
      },
      '& .gray40': {
        backgroundColor: theme.gray300,
      },
      '& .gray20': {
        color: theme.gray300,
        backgroundColor: theme.gray500,
      },
      '& .gray10': {
        color: theme.gray300,
        backgroundColor: theme.gray550,
      },
      '& .gray5': {
        color: theme.gray300,
        backgroundColor: theme.gray600,
      },
    },
  });
