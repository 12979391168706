import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const couponDetailsPanel = css({
  paddingBottom: 55,
  '& .reviews-summary': {
    boxSizing: 'border-box',
    marginTop: '2.75em',
    [Mobile]: {
      marginTop: '2em',
    },
  },
});

export const backButton = theme =>
  css({
    color: theme.gray100,
    fontSize: '1.1875rem',
    fontWeight: 700,
    paddingLeft: '0',
  });

export const sidePanel = css({
  width: '18.125rem',
  marginRight: '1.5625em',
  '& > *:not(:first-child)': {
    marginTop: '1.5em',
  },
});
