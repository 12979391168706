import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const heading = theme =>
  css({
    fontStyle: 'normal',
    fontWeight: 500,
    color: theme.background100,
    fontSize: '2.375rem',
    marginTop: '1em',
    [Mobile]: {
      marginTop: '0',
      fontSize: '1.375rem',
      color: theme.gray100,
    },
  });

export const paragraph = theme =>
  css({
    marginBottom: '2.125em',
    fontSize: '1rem',
    color: theme.gray200,
    [Mobile]: {
      fontSize: '0.75rem',
    },
  });
export const submittedDealWrapper = theme =>
  css({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '3.125em 3.75em',
    marginBottom: '12.5em',
    marginTop: '6.25em',
    '& h2': {
      fontSize: '1.4375rem',
      fontWeight: 700,
      marginBottom: '0.5em',
      [Mobile]: {
        textAlign: 'center',
      },
    },
    '& label': {
      fontSize: '1rem',
      fontWeight: 500,
      marginBottom: '2.5em',
      color: theme.gray200,
      textAlign: 'center',
    },
    '& .submit-deal-btns': {
      gap: '1em',
      [Mobile]: {
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
    },
    '& .ant-btn': { marginRight: '0.5em' },
    '& .success-img': { width: '3.75rem', height: '3.75rem', marginBottom: '1em' },
  });
