import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const dealsTabContainer = css({
  marginBottom: '4em',
});

export const dealsTabHead = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '1.5em',
  '& h3': {
    marginBottom: '1rem',
  },
});

export const dealsTabBody = css({
  display: 'flex',
  gap: '2rem',
  '& .cta-buttons': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1em',
    gap: '2rem',
  },
  [Mobile]: {
    flexDirection: 'column',
  },
});

export const dealsContainer = css({
  flex: '3 1 0',
});

export const dealList = theme =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 0 3em',
    gap: '2rem',
    '.ant-rate > .ant-rate-star': {
      fontSize: '0.75rem',
      '&.ant-rate-star-full svg': {
        color: theme.gray100,
        stroke: 'none',
      },

      '&.ant-rate-star-zero svg': {
        color: 'transparent',
        stroke: theme.gray400,
        strokeWidth: '3rem',
      },
    },
  });

export const dealsSortFilter = css({
  margin: '0 0 1em auto',
  width: '14rem',
});

export const dealItem = css({
  width: 240,
  [Mobile]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export const filterContainer = css({
  flex: '1 1 0',
});

export const filterButtonMobile = (isSelected, theme) =>
  css({
    background: isSelected ? theme.skyblue : theme.white,
  });

export const dealsFilter = css({
  flex: '1 1 0',
});

export const searchBar = theme =>
  css({
    display: 'block',
    boxShadow: 'none',
    '&.ant-input-affix-wrapper': {
      display: 'flex',
      padding: '1em',
      flex: '1 1 0',
      borderRadius: 40,
    },
    '&.ant-input-affix-wrapper:focus, &.ant-input-affix-wrapper-focused, &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover': {
      outline: 'none',
      border: `1px solid ${theme.gray100}85`,
      boxShadow: 'none',
    },
  });
