import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Modal, InputNumber, Spin } from 'antd';
import { useTheme } from 'emotion-theming';
import { BaseCheckbox } from 'components';
import { Button } from 'components/UIExternal';
import { Routes } from 'enums';
import useError from 'services/errorHandling/useError';
import { promoteDeal, getPromotionInfo, getPromotionsDealsInfo } from 'services/api/promotionService';
import { getAvailableCredits } from 'services/api/creditsService';
import {
  modal,
  modalContentWrapper,
  promoteWrapper,
  checkbox,
  inputNumber,
  availableCreditsWrapper,
  insufficientCredits,
} from './styles';

const PromotionsModal = ({ isModalVisible, showHideModal, dealId, setPromotedDeals, setAvailableCredits }) => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();

  const [promotionInfo, setPromotionInfo] = useState();
  const [dayDealsSelected, setDayDealsSelected] = useState(false);
  const [featuredDealsSelected, setFeaturedDealsSelected] = useState(false);
  const [dayDealsPeriod, setDayDealsPeriod] = useState(0);
  const [featuredDealsPeriod, setFeaturedDealsPeriod] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);

  useEffect(() => {
    isModalVisible && getPromotionInfo(dealId, setError).then(res => res && setPromotionInfo(res));
  }, [isModalVisible]);

  useEffect(() => {
    dayDealsSelected &&
      featuredDealsSelected &&
      setTotalCredits(
        dayDealsPeriod * promotionInfo.dealOfTheDayCost + featuredDealsPeriod * promotionInfo.featuredCost,
      );
    dayDealsSelected && !featuredDealsSelected && setTotalCredits(dayDealsPeriod * promotionInfo.dealOfTheDayCost);
    featuredDealsSelected && !dayDealsSelected && setTotalCredits(featuredDealsPeriod * promotionInfo.featuredCost);
  }, [dayDealsSelected, featuredDealsSelected, dayDealsPeriod, featuredDealsPeriod]);

  const handlePromote = () => {
    const payload = {
      id: dealId,
      dealOfTheDaySelected: dayDealsSelected,
      dealOfTheDayPeriod: dayDealsPeriod,
      featuredSelected: featuredDealsSelected,
      featuredPeriod: featuredDealsPeriod,
    };
    promoteDeal(payload, setError).then(resCode => {
      if (resCode === 200) {
        showHideModal();
        if (setPromotedDeals && setAvailableCredits) {
          fetchPromotionDealsInfo();
          handleAvailableCredits();
        }
      }
    });
  };

  const fetchPromotionDealsInfo = async () => {
    const [res, err] = await getPromotionsDealsInfo();
    return err ? setError(err) : setPromotedDeals(res.promotedDealsCount);
  };

  const handleAvailableCredits = async () => {
    const [res, err] = await getAvailableCredits();
    return err ? setError(err) : setAvailableCredits(res.credits);
  };

  return (
    <Modal
      open={isModalVisible}
      onCancel={showHideModal}
      centered
      className={modal}
      footer={
        <div className="flex">
          <Button
            type="secondary"
            small
            disabled={
              (!dayDealsSelected && !featuredDealsSelected) ||
              (!dayDealsPeriod && !featuredDealsPeriod) ||
              (dayDealsPeriod && !dayDealsSelected) ||
              (featuredDealsPeriod && !featuredDealsSelected) ||
              (!dayDealsPeriod && dayDealsSelected) ||
              (!featuredDealsPeriod && featuredDealsSelected) ||
              promotionInfo?.accountCredits < totalCredits
            }
            onClick={handlePromote}
          >
            {getText('promote')}
          </Button>
          <Button type="link" small onClick={showHideModal}>
            {getText('cancel')}
          </Button>
        </div>
      }
    >
      {promotionInfo ? (
        <div className={modalContentWrapper(theme)}>
          <h5>{getText('promoteDeal')}</h5>
          <p>{getText('selectWhereToPromote')}</p>
          <div className={promoteWrapper(theme)}>
            <div className="flex">
              <BaseCheckbox
                data-automation-id="xx"
                className={checkbox}
                theme={theme}
                checked={featuredDealsSelected}
                onChange={() => setFeaturedDealsSelected(!featuredDealsSelected)}
              />
              <div className="flex-column">
                <h6>{getText('promoteInFeaturedDeals')}</h6>
                <label className="credits-label">{getText('creditsCost', { cost: promotionInfo.featuredCost })}</label>
              </div>
            </div>
            <InputNumber
              data-automation-id="xx"
              className={inputNumber}
              formatter={value => `${value} ${getText('days')}`}
              parser={value => value.replace(getText('days'), '')}
              defaultValue={0}
              min={0}
              onChange={e => {
                setFeaturedDealsSelected(true);
                setFeaturedDealsPeriod(e);
              }}
            />
          </div>
          <div className={promoteWrapper(theme)}>
            <div className="flex">
              <BaseCheckbox
                className={checkbox}
                theme={theme}
                checked={dayDealsSelected}
                onChange={() => setDayDealsSelected(!dayDealsSelected)}
              />
              <div className="flex-column">
                <h6>{getText('promoteDealsOfTheDay')}</h6>
                <label className="credits-label">
                  {getText('creditsCost', { cost: promotionInfo.dealOfTheDayCost })}
                </label>
                {promotionInfo.dealOfTheDayRemainingDays > 0 && (
                  <label className="credits-label" style={{ marginTop: 10 }}>
                    {getText('dealAlreadyPromotedForDaysCount', { count: promotionInfo.dealOfTheDayRemainingDays })}
                  </label>
                )}
              </div>
            </div>
            <InputNumber
              data-automation-id="xx"
              className={inputNumber}
              formatter={value => `${value} ${getText('days')}`}
              parser={value => value.replace(getText('days'), '')}
              defaultValue={0}
              min={0}
              onChange={e => {
                setDayDealsSelected(true);
                setDayDealsPeriod(e);
              }}
            />
          </div>
          <label>{getText('totalCreditsCount', { count: totalCredits })}</label>
          <div className={availableCreditsWrapper(theme)}>
            <div>
              <p>
                {getText('youHaveCreditsAvailableCount', { count: promotionInfo ? promotionInfo.accountCredits : 0 })}
              </p>
              {promotionInfo?.accountCredits < totalCredits && (
                <label className={insufficientCredits(theme)}>{getText('insufficientCredits')}</label>
              )}
            </div>
            <Button type="link" small linkTo={Routes.buyCredits}>
              {getText('buy-more')}
            </Button>
          </div>
        </div>
      ) : (
        <Spin size="large" />
      )}
    </Modal>
  );
};

PromotionsModal.propTypes = {
  isModalVisible: PropTypes.bool,
  showHideModal: PropTypes.func,
  dealId: PropTypes.number,
  setPromotedDeals: PropTypes.func,
  setAvailableCredits: PropTypes.func,
};

export default PromotionsModal;
