import { css } from 'emotion';

export const locationFiltered = css({
  color: '#47BBFD',
});

export const locationFilteredTooltip = css({
  position: 'absolute',
  top: '1.5em',
  display: 'none',
  background: '#F5F5F5',
  padding: '1em',
  zIndex: 201,
  borderRadius: 8,
  boxShadow: '0 15px 15px -10px rgba(0,0,0,0.6)',

  '&:before': {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: '0.625rem',
    height: 0,
    borderLeft: '14px solid transparent',
    borderRight: '14px solid transparent',
    borderBottom: '14px solid #F5F5F5',
    top: '-0.5em',
  },

  '& .country-names': {
    color: '#616161',
    whiteSpace: 'normal',
  },
  '& .country-count': {
    marginLeft: '0.375em',
    color: '#47BBFD',
  },
});
