import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    padding: 20,
    fontFamily: 'Noto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 30,
    text: {
      fontSize: 32,
      color: '#222',
    },
    date: {
      fontSize: 16,
      textAlign: 'right',
    },
  },
  overviewRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  overviewBox: {
    border: '1px solid gray',
    borderRadius: 3,
    padding: 8,
    width: 130,
    marginBottom: 62,
    stats: {
      marginBottom: 5,
      fontSize: 16,
      fontWeight: 600,
    },
    text: {
      fontSize: 10,
    },
  },
  footer: {
    date: {
      fontSize: 8,
      textAlign: 'right',
    },
  },
});
