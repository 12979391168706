import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const listingContainer = theme =>
  css({
    boxShadow: `0px 1px 0px ${theme.gray500}`,
    '& .ant-carousel': {
      border: `1px solid ${theme.gray400_rgba}`,
    },
  });

export const mainContainer = css({
  paddingBottom: 80,
  '& .ant-collapse': {
    border: 'none',
  },
  '& .ant-collapse > .ant-collapse-item': {
    border: 'none',
  },
});

export const fullWidth = css({
  flex: 1,
});

export const topListContainer = theme =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1em',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '1.25em',
    color: theme.gray100,
    '& > div': {
      width: 'auto',
    },
    '& label': {
      fontSize: '1rem',
      [Mobile]: {
        marginTop: '0.9375em',
      },
    },
  });

export const skeletonCustomStyles = css({
  marginRight: '0.625em',
});
