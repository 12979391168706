import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { isFunction, concatStrings, isNil } from '@veraio/core';
import { Dropdown } from 'components/UIExternal';
import { getAllCategories } from 'services/api/category';
import useError from 'services/errorHandling/useError';
import { selectSubCategoryDropDown, selectCategoryDropDown, categoryContainer } from './styles';

const DealCategories = ({ value, onChange, className }) => {
  const { getText } = useTranslations();
  const { setError } = useError();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    topCategory: null,
    subCategory: null,
    subSubCategory: null,
    lastCategory: null,
  });
  const subCategoriesRef = useRef();
  const subSubCategoriesRef = useRef();
  const lastCategoriesRef = useRef();

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const fetchAllCategories = async () => {
    const [res, err] = await getAllCategories();

    if (err) return setError(err);
    const results = res.map(category => ({
      ...category,
      label: getText(category.name),
      subCategories: category.subCategories.map(subCategory => ({
        ...subCategory,
        label: getText(subCategory.name),
        subCategories: subCategory.subCategories.map(subSubCategory => ({
          ...subSubCategory,
          label: getText(subSubCategory.name),
          subCategories: subSubCategory.subCategories.map(lastCategory => ({
            ...lastCategory,
            label: getText(lastCategory.name),
          })),
        })),
      })),
    }));

    const topCategory = results.find(
      cat =>
        cat.id === value ||
        cat.subCategories.find(
          subCat =>
            subCat.id === value ||
            subCat.subCategories.find(el => el.id === value || el.subCategories.find(i => i.id === value)),
        ),
    );

    const subCategory = results
      .flatMap(cat => cat.subCategories)
      .find(
        subCat =>
          subCat.id === value ||
          subCat.subCategories.find(el => el.id === value || el.subCategories.find(i => i.id === value)),
      );

    const subSubCategory = results
      .flatMap(cat => cat.subCategories.flatMap(subCat => subCat.subCategories))
      .find(el => el.id === value || el.subCategories.find(i => i.id === value));

    const lastCategory = results
      .flatMap(cat =>
        cat.subCategories.flatMap(subCat => subCat.subCategories.flatMap(subSubCat => subSubCat.subCategories)),
      )
      .find(el => el.id === value || el.subCategories.find(i => i.id === value));

    setCategories(results);
    setSelectedCategory({ topCategory, subCategory, subSubCategory, lastCategory });
  };

  const handleChange = val => isFunction(onChange) && onChange(val?.subCategories?.length ? null : val?.id);

  const handleCategoryChange = val => {
    if (!isNil(selectedCategory?.subCategory))
      setSelectedCategory(prev => ({ ...prev, subCategory: null, subSubCategory: null, lastCategory: null }));

    setSelectedCategory(prev => ({ ...prev, topCategory: val }));
    handleChange(val);
    subCategoriesRef.current?.clear();
    subSubCategoriesRef.current?.clear();
    lastCategoriesRef.current?.clear();
  };

  const handleSubCategoryChange = val => {
    if (!isNil(selectedCategory?.subSubCategory))
      setSelectedCategory(prev => ({ ...prev, subSubCategory: null, lastCategory: null }));

    setSelectedCategory(prev => ({ ...prev, subCategory: val }));
    handleChange(val);
    subSubCategoriesRef.current?.clear();
    lastCategoriesRef.current?.clear();
  };

  const handleSubSubCategoryChange = val => {
    if (isNil(val)) return;
    if (!isNil(selectedCategory?.lastCategory)) setSelectedCategory(prev => ({ ...prev, lastCategory: null }));

    setSelectedCategory(prev => ({ ...prev, subSubCategory: val }));
    handleChange(val);
  };

  const handleLastCategoryChange = val => {
    setSelectedCategory(prev => ({ ...prev, lastCategory: val }));
    handleChange(val);
  };

  return (
    <div className={concatStrings(categoryContainer, className)}>
      <Dropdown
        noClear
        small
        placeholder={getText('pleaseChooseCategory')}
        value={selectedCategory?.topCategory}
        options={categories}
        onChange={handleCategoryChange}
        className={selectCategoryDropDown}
        uniqueKey="id"
        displayKey="label"
      />
      {!!selectedCategory?.topCategory?.subCategories?.length && (
        <Dropdown
          noClear
          small
          ref={subCategoriesRef}
          value={selectedCategory?.subCategory}
          placeholder={getText('subCategory')}
          options={selectedCategory?.topCategory?.subCategories}
          onChange={handleSubCategoryChange}
          className={selectSubCategoryDropDown}
          uniqueKey="id"
          displayKey="label"
        />
      )}
      {!!selectedCategory?.subCategory?.subCategories?.length && (
        <Dropdown
          noClear
          small
          ref={subSubCategoriesRef}
          value={selectedCategory?.subSubCategory}
          placeholder={getText('subSubCategory')}
          options={selectedCategory?.subCategory?.subCategories}
          onChange={handleSubSubCategoryChange}
          className={selectSubCategoryDropDown}
          uniqueKey="id"
          displayKey="label"
        />
      )}
      {!!selectedCategory?.subSubCategory?.subCategories?.length && (
        <Dropdown
          noClear
          small
          ref={lastCategoriesRef}
          value={selectedCategory?.lastCategory}
          placeholder={getText('lastSubCategory')}
          options={selectedCategory?.subSubCategory?.subCategories}
          onChange={handleLastCategoryChange}
          className={selectSubCategoryDropDown}
          uniqueKey="id"
          displayKey="label"
        />
      )}
    </div>
  );
};

DealCategories.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default DealCategories;
