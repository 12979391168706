import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Text, View, Svg, Path, G, Image, Rect, Polygon } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const color = {
  st0: '#3A6DB5',
  st1: '#3469B3',
  st2: '#FECF09',
  st3: '#FFFFFF',
  st4: '#FECF0C',
};

const NotebookTemplate = ({ qrCode, businessInfo }) => {
  const SVGComponent = () => (
    <Svg x="0px" y="0px" viewBox="0 0 892.9 595.3" style={{ position: 'absolute', top: 0 }}>
      <Rect x={446.5} y={-28.3} fill={color.st0} width={476.2} height={652} />
      <Rect x={-28.3} y={-28.3} fill={color.st0} width={476.2} height={652} />
      <G>
        <Path
          fill={color.st1}
          d="M627,4c52.3,93.4,102.1,182.5,153.4,274.2h-81.8l-71.6-127c-25.3,44.7-48.6,85.8-71.9,127.1   h-80.4L627,4z"
        />
        <Path
          fill={color.st1}
          d="M627.1,468.7l-71.6,126.4h-80.4L627,321.3l153.4,274h-81.8   C675.7,554.7,652.5,513.5,627.1,468.7z"
        />
      </G>
      <G>
        <Path
          fill={color.st3}
          d="M516.5,44.9c-1.5,2.4-4.6,3.4-7.5,2.8V36c2.8-0.8,5.7,0,7.3,2.2c0.7,1,1.1,2.1,1.1,3.3   C517.4,42.7,517.1,43.9,516.5,44.9L516.5,44.9z M513.5,28.7c-3.7-0.5-7.6-0.2-11.4-0.2c-0.2,0-0.3,0.2-0.5,0.3v26.3   c3.4,0,6.6,0,9.9,0c6.8-0.1,12.6-5.4,13.1-11.9C525.2,36,520.5,29.7,513.5,28.7z"
        />
        <Polygon
          fill={color.st3}
          points="525.4,85.1 525.4,59.1 532.2,59.1 532.2,68.4 541,68.4 541,59.2 548,59.2 548,85.1    541.1,85.1 541.1,75.8 532.3,75.8 532.3,85.1  "
        />
        <Polygon
          fill={color.st3}
          points="532.8,35.7 532.8,38.1 538.4,38.1 538.4,45.5 532.8,45.6 532.8,47.9 545.4,47.9    545.4,54.9 525.4,54.9 525.4,28.5 545.4,28.5 545.4,35.7  "
        />
        <Polygon
          fill={color.st3}
          points="622.3,85.3 602.5,85.3 602.5,59.1 622.2,59.1 622.2,65.9 609.6,65.9 609.6,68.7    615.1,68.7 615.1,75.6 609.7,75.6 609.7,78.4 622.3,78.4  "
        />
        <Path
          fill={color.st3}
          d="M524,62.9c-1.7,1.3-5.1,4.3-5.1,4.3s-2.6-1.3-4.7-1.8c-1.4-0.3-4-0.6-4.9,0.8   c-0.9,1.5,1.3,2.2,2.3,2.4c1.3,0.2,2.9,0,4.2,0.3c5.5,0.9,8.5,4.2,8,9.1c-0.2,1.7-0.9,3.4-2.1,4.7c-4.7,5.2-15.2,3.9-19.9-1.9   l5.4-3.8c0,0,2.2,1.6,4.6,1.9c1.4,0.2,3,0.2,4.3-0.5c0.4-0.2,0.6-0.5,0.7-0.9c0.2-1-0.9-1.5-1.7-1.6c-1.3-0.2-2.7-0.3-4-0.5   c-4.9-0.5-8.3-3.3-9.1-7.4c-0.4-2.4,0.2-4.5,1.8-6.3C508.3,56.5,518.8,57.9,524,62.9z"
        />
        <Path
          fill={color.st3}
          d="M601,81.4l-5.7,4l-8.8-9.6l-0.1,9.4h-6.8v-26h6.7V69c0,0,6.1-6.7,9-10l5.5,4.2l-7.7,9L601,81.4   z"
        />
        <Path
          fill={color.st2}
          d="M563.5,28.6c5,8.9,9.8,17.4,14.7,26.2h-7.8l-6.8-12.1c-2.4,4.3-4.6,8.2-6.9,12.1H549   L563.5,28.6z"
        />
        <Path fill={color.st2} d="M563.5,73l-6.8,12.1H549l14.5-26.2l14.7,26.2h-7.8C568.2,81.2,565.9,77.3,563.5,73z" />
        <Polygon fill={color.st3} points="586.5,28.6 586.5,47.9 599.2,47.9 599.2,54.9 579.3,54.9 579.3,28.6  " />
        <Path
          fill={color.st3}
          d="M638.2,69.5c-0.1,0.8-0.4,1.5-0.9,2c-0.5,0.5-1.3,0.9-2,1c-1.5,0.1-3,0-4.7,0V66   c1.8,0,3.4-0.2,5,0C637.2,66.3,638.4,68,638.2,69.5z M641.9,77.2c0.6-0.8,1.1-1.4,1.5-1.9c4.2-5.8,1.2-14.7-5.9-15.9   c-4.5-0.8-9.2-0.1-14-0.1v26h7v-5.6c0,0,3.7-0.4,5.6,1.4c2.1,2,4.2,4,6.6,6.3l4.8-5.1v-0.4L641.9,77.2z"
        />
      </G>
      <Path
        fill={color.st4}
        d="M841,104.3c-29.4-28.6-46.1-67.8-46.6-108.8c-0.1-8,0.4-16,1.6-23.9h-95.6  c-0.7,8.3-1.1,16.6-0.9,25c0.8,66.2,27.9,129.4,75.3,175.6c40.2,39.2,92.1,63.5,147.3,69.5v-95.9C891.7,140.5,863.4,126.1,841,104.3  z"
      />
    </Svg>
  );

  return (
    <Document>
      <Page size={[892.9, 595.3]}>
        <View>
          <SVGComponent />
          <View style={generalStyles.wrapper}>
            <View style={s.contentBox}>
              <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
              <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
              <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
              <Image source={qrCode} style={s.qrCodeStyles} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

NotebookTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default NotebookTemplate;
