import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { Select } from 'antd';
import { getSortOptions } from 'enums/SortOptionsEnum';
import { mainContainer } from './styles';

const SortDropdown = ({ inputClass, allowClear, onSortChange, excludePriceOptions, value }) => {
  const { Option } = Select;
  const theme = useTheme();
  const { getText } = useTranslations();

  const SortOptions = getSortOptions(excludePriceOptions);
  const sortOptionsKeys = Object.keys(SortOptions);

  const onChange = value => {
    if (onSortChange !== undefined) onSortChange(value);
  };

  return (
    <div className={mainContainer(theme, inputClass)}>
      <Select
        allowClear={allowClear}
        placeholder={getText('sortBy')}
        onChange={onChange}
        className={inputClass}
        defaultValue={value && Object.values(SortOptions)[value].id}
      >
        {sortOptionsKeys.map(key => (
          <Option key={SortOptions[key].id} value={SortOptions[key].id}>
            {getText(SortOptions[key].name)}
          </Option>
        ))}
      </Select>
    </div>
  );
};

SortDropdown.propTypes = {
  onSortChange: PropTypes.func,
  allowClear: PropTypes.bool,
  inputClass: PropTypes.string,
  excludePriceOptions: PropTypes.bool,
  value: PropTypes.number,
};

export default SortDropdown;
