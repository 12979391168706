import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const notificationsPage = css({
  '& .notifications-page-header-btn': {
    flexWrap: 'wrap',
    gap: '0.5em 1em',
    width: '100%',
  },
  '& .content-container:first-of-type': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '1.25em',
  },
  '& .notifications-container': {
    minWidth: '21.875rem',
    [Mobile]: {
      padding: '0',
      '& > div': {
        padding: '1em 0',
      },
    },
  },
  [Mobile]: {
    '.custom-gray-box': {
      minWidth: '21.875rem',
    },
  },
});

export const borderlessShadowlessGrayBox = theme =>
  css({
    backgroundColor: theme.gray600,
  });

export const title = theme =>
  css({
    color: theme.background100,
    fontSize: '1.4375rem',
    fontWeight: 700,
    display: 'inline-block',
  });

export const selectAll = theme =>
  css({
    marginTop: '1.5em',
    marginBottom: '1.5em',
    '& label:not(.ant-checkbox-wrapper)': {
      marginLeft: '0.5625em',
      color: theme.gray200,
    },
    '& button': {
      border: 'none',
      background: theme.white,
      color: theme.primary_blue,
      [Desktop]: {
        marginLeft: '1.5em',
      },
      [Mobile]: {
        float: 'right',
      },
    },
    '& button:hover': {
      color: theme.primary_blue_hover,
    },
  });

export const notificationsTable = () =>
  css({
    marginBottom: '1.5em !important',
    [Mobile]: {
      '& .ant-list-item': {
        border: 'none !important',
      },
    },
  });

export const typeTime = theme =>
  css({
    [Desktop]: {
      '& .title': {
        color: theme.gray200,
      },
      '& .time': {
        color: theme.gray300,
        fontSize: '0.8125rem',
      },
    },
    [Mobile]: {
      '& .time': {
        color: theme.gray300,
        fontSize: '0.75rem',
      },
    },
  });

export const notificationIcon = css({
  width: '2rem',
});

export const noNotificationsContainer = theme =>
  css({
    textAlign: 'center',
    fontSize: '1.125rem',
    color: theme.gray300,
    marginBottom: '3.125em',
  });

export const noNotificationImage = css({
  margin: '2.5em auto 1.875em auto',
  [Mobile]: {
    width: '40%',
  },
  [Desktop]: {
    width: '50%',
  },
});

export const blackTextLink = theme =>
  css({
    color: theme.gray100,
  });

export const notificationIsRead = (isRead, theme) =>
  css({
    color: !isRead ? theme.gray100 : theme.gray200,
    fontWeight: !isRead && 700,
  });
