import { css } from 'emotion';

export const categoryFilterContainer = className =>
  css([
    {
      '& .suggestion': {
        justifyContent: 'space-between',
      },
      '& .categories-list': {
        maxHeight: '18rem',
        overflowY: 'auto',
      },
    },
    className,
  ]);

export const categoryFilterSearchBar = css({
  '& .suggestion': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export const categoryFilterItem = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5em',
    borderBottom: `1px solid ${theme.gray600}`,
  });

export const breadCrumbPath = theme =>
  css({
    marginTop: '1em',
    borderBottom: `1px solid ${theme.lightgray}`,
    paddingBottom: '1em',
    color: theme.gray300,
  });

export const clearFilterButton = css({
  marginTop: '0.5em',
  textAlign: 'right',
});
