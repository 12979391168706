import { css } from 'emotion';

export const countryFilterSearchBar = css({
  '& .suggestion': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  '& div:nth-child(2)': {
    maxHeight: '15rem',
  },
});

export const countryFilterBody = css({
  overflowY: 'auto',
  maxHeight: '20rem',
  marginTop: '1em',
});

export const countryFilterItem = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5em',
    borderBottom: `1px solid ${theme.gray600}`,
  });
