import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { Collapse } from 'antd';
import { Dropdown } from 'components/UIExternal';
import { BaseCheckbox } from 'components';
import { checkbox, collapse, countryDropdownStyles } from './styles';

const ContinentCollapse = ({ continent, countries, setCountries }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { countries: continentCountries } = continent;

  const checkedContinent = continentCountries?.some(el => countries?.some(item => item?.id === el?.id));
  const dropdownValue = continentCountries.filter(el => countries?.some(item => item?.id === el?.id));
  const otherCountries = countries.filter(el => !continentCountries?.some(item => item?.id === el?.id));

  const handleSelectAll = isChecked => handleChange(isChecked ? continentCountries : []);

  const handleChange = val => setCountries(otherCountries.concat(val));

  return (
    <Collapse expandIconPosition="right" data-automation-id="index-953c1601-6c79-4a86-862a-4986efce2dbe">
      <Collapse.Panel
        key={continent?.id}
        className={collapse(checkedContinent)}
        header={
          <>
            <BaseCheckbox
              key={continent.id}
              theme={theme}
              className={checkbox(theme, checkedContinent)}
              checked={checkedContinent}
              onChange={e => handleSelectAll(e.target.checked)}
              data-automation-id="index-02750bea-3641-4396-8161-dbee5909c86e"
            />
            <span className="default-font">{continent.name}</span>
          </>
        }
      >
        <Dropdown
          multiSelect
          withSearch
          placeholder={getText('startTypingForCountries')}
          displayKey="name"
          uniqueKey="id"
          options={continentCountries}
          value={dropdownValue}
          onChange={handleChange}
          className={countryDropdownStyles}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

ContinentCollapse.propTypes = {
  continent: PropTypes.object,
  countries: PropTypes.array,
  setCountries: PropTypes.func,
};

export default ContinentCollapse;
