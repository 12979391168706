import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Popover, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { EmptyShoppingCart } from 'components/screens';
import ConfirmationPopup from 'components/popups/ConfirmationPopup';
import { useShoppingCart, useUser } from 'stores';
import { Routes } from 'enums';
import MiniCartContentRow from './MiniShoppingCartDealItem';
import {
  miniCartPanelContainer,
  badgeContainer,
  titleRemoveAllButtonContainer,
  badgeNumber,
  popOverClass,
  cartItemsStyle,
  primaryProceedBtn,
} from './styles';

const MiniShoppingCart = ({ onLoginClick }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { isAuthenticated, miniCartPopoverRef } = useUser();
  const { shoppingCart, updateDealQuantity, removeDeal, removeAllDeals } = useShoppingCart();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const removedDealId = useRef();
  const [cartPopUp, setCartPopUp] = useState();
  const cartItems = shoppingCart?.businesses?.flatMap(business => business.items);

  const handleOpenConfirmationModal = id => {
    setIsModalVisible(true);
    removedDealId.current = id;
  };

  const handleDeleteItemFromShoppingCart = () => {
    setIsModalVisible(false);
    removeDeal(removedDealId.current);
  };

  const handleLoginClick = () => {
    miniCartPopoverRef.current.onClick();
    onLoginClick();
    cartItems.length && localStorage.setItem('redirectToShoppingCart', true);
  };

  const cartContent = (
    <div className={miniCartPanelContainer(theme)}>
      <div className={titleRemoveAllButtonContainer}>
        <label> {getText('yourCart')}</label>
        <Button type="link" small onClick={removeAllDeals}>
          {getText('removeAll')}
        </Button>
      </div>
      <div className={cartItemsStyle}>
        {cartItems?.map(deal => (
          <MiniCartContentRow
            key={deal.dealId ?? deal.id}
            onQuantityChange={updateDealQuantity}
            onRemove={handleOpenConfirmationModal}
            deal={deal}
          />
        ))}
      </div>
      {isAuthenticated ? (
        <Button
          type="default"
          className={primaryProceedBtn}
          linkTo={Routes.shoppingCart}
          onClick={() => setCartPopUp(false)}
        >
          {getText('proceedToCheckout')}
        </Button>
      ) : (
        <Button type="primary" onClick={handleLoginClick} className={primaryProceedBtn}>
          {getText('proceedToCheckoutAndLogIn')}
        </Button>
      )}
    </div>
  );

  const loader = (
    <div className={miniCartPanelContainer(theme)}>
      <Spin size="large" />
    </div>
  );

  return (
    <>
      <Popover
        ref={miniCartPopoverRef}
        content={
          !cartItems ? loader : cartItems.length ? cartContent : <EmptyShoppingCart onLoginClick={handleLoginClick} />
        }
        overlayClassName={popOverClass}
        placement="bottomRight"
        open={cartPopUp}
        onOpenChange={setCartPopUp}
        trigger="click"
      >
        <div className={badgeContainer}>
          <FeatherIcon icon="shopping-cart" size={26} stroke="#fff" strokeWidth={1} />
          <span className={badgeNumber(theme, cartItems?.length < 1)}>{cartItems?.length}</span>
        </div>
      </Popover>
      <ConfirmationPopup
        isModalVisible={isModalVisible}
        handleOk={handleDeleteItemFromShoppingCart}
        handleCancel={() => setIsModalVisible(false)}
        text={!removedDealId ? getText('sureRemoveAllDealsFromCart') : getText('sureRemoveDealFromCart')}
      />
    </>
  );
};

MiniShoppingCart.propTypes = {
  onLoginClick: PropTypes.func,
};

export default MiniShoppingCart;
