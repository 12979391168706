import React from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { welcomePageContent } from './styles';

export const BrandGuideWelcomePage = () => {
  const theme = useTheme();
  const { getText } = useTranslations();

  return (
    <div className={welcomePageContent(theme)}>
      <h1>{getText('hello')}</h1>
      <h3>{getText('brandGuideHello')}</h3>
    </div>
  );
};
