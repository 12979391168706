import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isMobile } from 'react-device-detect';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { chartTitle, chartContainer, mr30 } from '../styles';

const BarChartComponent = ({ data }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const renderYAxisTick = ({ payload }) => (
    <g transform={`translate(${0},${payload.tickCoord + 2})`}>
      <text x={0} y={0} textAnchor="start" fontSize={isMobile ? 10 : 12} fill={theme.gray100}>
        {payload.value}
      </text>
    </g>
  );

  return (
    <div className={`${chartContainer} ${mr30}`}>
      <h6 className={chartTitle(theme)}>{getText('topSellingCategories')}</h6>
      <ResponsiveContainer width="100%" aspect={isMobile ? 1.0 / 1.0 : 2.0 / 1.0}>
        <BarChart
          layout="vertical"
          barCategoryGap={isMobile ? 3 : 7}
          margin={{ top: 0, right: 0, left: 20, bottom: 0 }}
          data={data.map(category => ({ key: getText(category.key), value: category.value }))}
        >
          <XAxis type="number" hide />
          <YAxis
            type="category"
            dataKey="key"
            axisLine={false}
            tickLine={false}
            width={isMobile ? 65 : 85}
            tick={renderYAxisTick}
          />
          <Bar
            dataKey="value"
            label={{ value: 'value', angle: 0, position: 'right', fill: theme.gray300 }}
            fill={theme.blue}
            radius={[2, 2, 2, 2]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

BarChartComponent.propTypes = {
  data: PropTypes.array,
};

export default BarChartComponent;
