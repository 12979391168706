import React from 'react';
import { useTranslations } from '@veraio/strank';
import { codeStatusesFilter, dateSorting, Routes } from 'enums';
import { Dropdown, Button, useUrlParams } from 'components/UIExternal';
import { promoCodesFilterBar, promoCodesFilters } from './styles';

export const PromoCodesFilterBar = props => {
  const { getText } = useTranslations();
  const { paginationRef, filterKeys } = props;
  const { queryParams } = useUrlParams();

  return (
    <div className={promoCodesFilterBar}>
      <div className={promoCodesFilters}>
        <Dropdown
          small
          placeholder={getText('filterByStatus')}
          options={codeStatusesFilter(getText)}
          value={queryParams[filterKeys.statusId]}
          onChange={val => paginationRef.current?.applyFilters({ [filterKeys.statusId]: val?.value })}
          className={{ width: '13rem' }}
        />
        <Dropdown
          small
          placeholder={getText('filterByDate')}
          options={dateSorting(getText)}
          value={queryParams[filterKeys.PromoCodeSorting]}
          onChange={val => paginationRef.current.applyFilters({ [filterKeys.promoCodeSorting]: val?.value })}
          className={{ width: '12rem' }}
        />
      </div>
      <Button type="info" linkTo={Routes.newPromoCode}>
        {getText('createPromoCode')}
      </Button>
    </div>
  );
};
