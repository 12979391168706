import React from 'react';
import { useTheme } from 'emotion-theming';
import { Skeleton } from 'components/UIExternal';
import { merchantBusinessInfoCard } from './styles';

export const BusinessCardSkeleton = () => {
  const theme = useTheme();

  return Array(5)
    .fill()
    .map((_, ind) => (
      <div key={`business-card-skeleton-${ind}`} className={merchantBusinessInfoCard(false, false, theme)}>
        <Skeleton height="1.5rem" marginBottom="0.6em" />
        <Skeleton height="1rem" marginBottom="0.6em" width="6rem" />
        <div className="business-images">
          <Skeleton count={3} height="3rem" width="3rem" />
        </div>
        <Skeleton height="0.8rem" marginBottom="0.6em" width="8rem" />
        <Skeleton height="0.8rem" width="6rem" />
      </div>
    ));
};
