import { Routes } from 'enums';

export const dealDetailsBreadcrumbs = (deal, dealDetails) => [
  { url: `${Routes.homePage}`, icon: 'las la-home' },
  ...deal?.categories?.map(breadCrumb => ({
    label: breadCrumb.name.replace(/-/g, ' '),
    url: `${Routes.dealListingPage}?categoryId=${breadCrumb.id}`,
  })),
  { label: dealDetails?.title },
];
