import React, { useEffect, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { isDesktop } from 'react-device-detect';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import Slider from 'react-slick';
import FeatherIcon from 'feather-icons-react';
import Deal from 'components/deal/Deal';
import { useUser } from 'stores';
import useError from 'services/errorHandling/useError';
import { getDealsBasedOnInterest } from 'services/api/dealsService';
import defaultUserLogo from 'assets/images/footer_user_default.svg';
import {
  headingPanel,
  title,
  arrowButton,
  wrapper,
  userProfileImage,
  horizontalLine,
  userInfoContainer,
} from './styles';

const arrowSettings = (position, theme) => ({
  size: 40,
  className: arrowButton(position),
  stroke: theme.gray200,
  strokeWidth: 1,
});

const arrowGeneral = slidesPerPage => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: slidesPerPage,
  slidesToScroll: slidesPerPage,
  arrows: isDesktop,
});

const PreviousInterestsDeals = () => {
  const { getText } = useTranslations();
  const { shippingTo, shippingFrom } = useLocations();
  const { userInfo } = useUser();
  const theme = useTheme();
  const { setError } = useError();
  const slidesPerPage = isDesktop ? 4 : 1;
  const [deals, setDeals] = useState(null);

  useEffect(() => {
    fetchDeals();
  }, [shippingFrom, shippingTo]);

  const fetchDeals = async () => {
    const [res, err] = await getDealsBasedOnInterest({
      from: shippingFrom?.map(el => el?.id),
      to: shippingTo?.map(el => el?.id),
      pageSize: 8,
    });
    err ? setError(err) : setDeals(res?.items);
  };

  const arrow = position => (
    <div>
      <FeatherIcon icon={`chevron-${position}`} {...arrowSettings(position, theme)} />;
    </div>
  );

  const settings = {
    ...arrowGeneral(slidesPerPage),
    nextArrow: arrow('right', theme),
    prevArrow: arrow('left', theme),
  };

  return (
    <div className="content-container">
      <div className={headingPanel}>
        <h2 className={title}>{getText('basedOnpreviousInterests')}</h2>
        <div className={userInfoContainer}>
          <div>
            <img className={userProfileImage} src={userInfo?.avatarUrl || defaultUserLogo} alt="Logo" />
            <span>{`${userInfo?.firstName} ${userInfo?.lastName.charAt(0)}.`}</span>
          </div>
          <div className={horizontalLine(theme)} />
        </div>
      </div>
      <div className={wrapper(isDesktop && deals?.length < slidesPerPage + 1)}>
        <Slider {...settings}>
          {deals?.map(deal => (
            <Deal key={deal.id} deal={deal} dataId="previous-interests" />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PreviousInterestsDeals;
