import React from 'react';
import PropTypes from 'prop-types';
import { pick, isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { ButtonArrow, Skeleton, Tag } from 'components/UIExternal';
import {
  deliveryMethodInverse,
  deliveryStatusSelect,
  deliveryStatusInverse,
  deliveryStatusColor,
  deliveryStatus,
  orderStatus,
} from 'enums';
import {
  changeOrderDeliveryStatus,
  selectedOrderDeliveryAddress,
  selectedOrderPaymentDetails,
  useOrders,
} from 'stores';
import { orderDetailsDeliveryAddressContainer } from './styles';

export const DeliveryAddressInfo = () => {
  const { getText } = useTranslations();
  const { delivery, id: orderId, editable, statusId: statusOrder } = useOrders(ordersState => ({
    ...pick(ordersState?.selectedOrder, ['delivery', 'id', 'statusId']),
    editable: ordersState?.editable,
  }));
  const { address } = selectedOrderDeliveryAddress();
  const { isAutomaticPaymentsCompleted } = selectedOrderPaymentDetails();
  const isDelivered = delivery?.statusId !== deliveryStatus.delivered;
  const isEditable = editable && statusOrder === orderStatus.new;

  const deliveryOptionsConfig = deliveryStatusSelect.map(option => ({
    ...option,
    color: deliveryStatusColor[option.value],
    label: getText(option.label),
    disabled: option.value === deliveryStatus.delivered,
  }));

  return (
    <section className={orderDetailsDeliveryAddressContainer}>
      <section className="order-details-delivery-section">
        <div className="order-details-delivery-info-row">
          <h5 className="gray order-details-delivery-label">{getText('type')}</h5>
          <span>
            {isNil(delivery) ? (
              <Skeleton height={20} width={150} />
            ) : (
              getText(deliveryMethodInverse[delivery?.deliveryMethodId])
            )}
          </span>
        </div>
        <div className="order-details-delivery-info-row">
          {isNil(delivery) ? (
            <Skeleton height={40} width={150} />
          ) : (
            <>
              <h5 className="gray order-details-delivery-label">{getText('status')}</h5>
              {isEditable && isAutomaticPaymentsCompleted && isDelivered ? (
                <ButtonArrow
                  value={delivery?.statusId}
                  onChange={statusId => changeOrderDeliveryStatus(orderId, statusId)}
                  options={deliveryOptionsConfig}
                />
              ) : (
                <Tag type={deliveryStatusColor[delivery?.statusId]} fontSize="0.8rem">
                  {deliveryStatusInverse[delivery?.statusId]}
                </Tag>
              )}
            </>
          )}
        </div>
      </section>
      <section className="order-details-delivery-section">
        <div className="order-details-delivery-info-row">
          <h5 className="gray align-start order-details-delivery-label">{getText('address')}</h5>
          {isNil(delivery) ? <Skeleton width={100} height={20} /> : <span>{address}</span>}
        </div>
      </section>
    </section>
  );
};

DeliveryAddressInfo.propTypes = {
  edited: PropTypes.bool,
  orderDetail: PropTypes.object,
};
