import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Popover } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { popoverContainer } from './styles';

const InformationalPopover = ({ text }) => {
  const theme = useTheme();

  return (
    <Popover
      content={<p>{text}</p>}
      overlayClassName={popoverContainer(theme)}
      zIndex={900}
      placement="bottomRight"
      trigger="click">
      <FeatherIcon icon="info" size={15} />
    </Popover>
  );
};

InformationalPopover.propTypes = {
  text: PropTypes.string,
};

export default InformationalPopover;
