import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isObjectLike, isFunction } from 'lodash-es';
import { Icon } from 'components/ui';
import { useTheme } from 'emotion-theming';
import { useAutocomplete } from './useAutocomplete';
import {
  searchBarContainer,
  searchBarInputContainer,
  searchBarInput,
  searchBarInputIcon,
  searchBarSuggestionsContainer,
  searchBarSuggestionItem,
} from './styles';

const DropdownSearchBar = forwardRef((props, searchBarRef) => {
  const theme = useTheme();
  const { displayKey = 'label', renderInput, renderSuggestionsContainer, renderSuggestion } = props;

  const { inputProps, suggestionItemProps, isOpen, suggestions, rootNodeRef } = useAutocomplete(props, searchBarRef);

  const listOfSuggestions = suggestions?.map((el, i) => {
    const itemProps = {
      key: `suggestion-${i}`,
      role: 'button',
      tabIndex: 0,
      onBlur: inputProps.onBlur,
      onClick: () => suggestionItemProps.onClick(el),
      onKeyDown: suggestionItemProps.onKeyDown,
      [suggestionItemProps.itemIdAttr]: i,
    };

    return isFunction(renderSuggestion) ? (
      renderSuggestion({ itemProps, suggestionItemProps, item: el })
    ) : (
      <div {...itemProps} className={searchBarSuggestionItem(theme)}>
        {isObjectLike(el) ? el[displayKey] : el}
      </div>
    );
  });

  return (
    <div ref={rootNodeRef} className={searchBarContainer(props)}>
      {isFunction(renderInput) ? (
        renderInput({
          containerProps: { className: searchBarInputContainer(theme) },
          inputProps: { className: searchBarInput, ...inputProps },
          iconProps: {
            iconName: 'las la-search',
            className: searchBarInputIcon,
          },
        })
      ) : (
        <div className={searchBarInputContainer(theme)}>
          <input className={searchBarInput} {...inputProps} />
          <label htmlFor={inputProps.id}>
            <Icon iconName="las la-search" className={searchBarInputIcon} />
          </label>
        </div>
      )}

      {isFunction(renderSuggestionsContainer)
        ? renderSuggestionsContainer({
            isOpen,
            suggestions,
            className: searchBarSuggestionsContainer(theme),
            children: listOfSuggestions,
          })
        : isOpen &&
          !!suggestions?.length && <div className={searchBarSuggestionsContainer(theme)}>{listOfSuggestions}</div>}
    </div>
  );
});

DropdownSearchBar.propTypes = {
  inputRef: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  displayKey: PropTypes.string,
  renderInput: PropTypes.func,
  renderSuggestionsContainer: PropTypes.func,
  renderSuggestion: PropTypes.func,
};

export default DropdownSearchBar;
