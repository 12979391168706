import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { exchangeCodeForToken } from '@oneecosystem/authenticate';
import { Routes } from 'enums';
import { useUser } from 'stores';

const RedirectCallback = ({ location, history }) => {
  const { fetchUserData } = useUser();
  const { error, state } = qs.parse(location.search);

  useEffect(() => {
    error
      ? history.push(error === 'INSUFFICIENT_RIGHTS' ? Routes.insufficientRights : Routes.notFound)
      : handleSuccessLogin();
  }, []);

  const handleSuccessLogin = async () => {
    await exchangeCodeForToken();

    await fetchUserData();

    const { location } = JSON.parse(state ?? '{}');
    history.push(location ?? '/');
  };

  return (
    <div className="kt-splash-screen">
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  );
};

RedirectCallback.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default RedirectCallback;
