import * as L from 'leaflet';
import currentPositionIcon from 'assets/images/userPin.svg';
import merchantIcon from 'assets/images/merchantPin.svg';

export const currentPositionPin = new L.Icon({
  iconSize: [50, 50],
  iconUrl: currentPositionIcon,
});

export const eyePin = new L.Icon({
  iconSize: [40, 40],
  iconUrl: merchantIcon,
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});
