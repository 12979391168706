import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Slider, InputNumber } from 'antd';
import { mainContainer, inputsContainer } from './styles';

const RangeSlider = ({ min, max, label, onMinValueChange, onMaxValueChange }) => {
  const theme = useTheme();

  const [inputMinValue, setInputMinValue] = useState(min);
  const [inputMaxValue, setInputMaxValue] = useState(max);
  const [inputMinPrevValue, setInputMinPrevValue] = useState(min);
  const [inputMaxPrevValue, setInputMaxPrevValue] = useState(max);

  const onSliderChange = value => {
    setInputMinValue(value[0]);
    setInputMaxValue(value[1]);
  };

  const onSliderAfterChange = value => {
    const [min, max] = value;

    if (value[0] !== inputMinPrevValue) {
      onMinValueChange(min);
      setInputMinPrevValue(min);
    }
    if (value[1] !== inputMaxPrevValue) {
      onMaxValueChange(max);
      setInputMaxPrevValue(max);
    }
  };

  const onMinChange = value => {
    setInputMinValue(value);
    onMinValueChange(value);
  };

  const onMaxChange = value => {
    setInputMaxValue(value);
    onMaxValueChange(value);
  };

  useEffect(() => {
    setInputMinValue(min);
    setInputMaxValue(max);
  }, [min, max]);

  return (
    <div className={mainContainer(theme)}>
      <div className={inputsContainer}>
        <InputNumber value={inputMinValue} showCount={false} onChange={onMinChange} />
        <label>-</label>
        <div className="input-and-currency">
          <InputNumber value={inputMaxValue} onChange={onMaxChange} />
          <label>{label}</label>
        </div>
      </div>
      <Slider
        min={min}
        max={max}
        range
        onChange={onSliderChange}
        onAfterChange={onSliderAfterChange}
        value={[inputMinValue, inputMaxValue]}
        data-automation-id="index-7a1311a2-4e51-4ff3-bb13-3d6c15197f57"
      />
    </div>
  );
};

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  onMinValueChange: PropTypes.func,
  onMaxValueChange: PropTypes.func,
};

export default RangeSlider;
