import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/UIExternal';
import FeatherIcon from 'feather-icons-react';
import { codeValue, codeContainer } from './styles';

const CodeTag = ({ usedBy, code }) => (
  <div className={codeContainer}>
    <p className="usedBy">{usedBy}</p>
    <div className={codeValue}>
      <span className="bolder-text">{code}</span>
      <Button small type="link" onClick={() => navigator.clipboard.writeText(code)}>
        <FeatherIcon icon="copy" size={16} />
      </Button>
    </div>
  </div>
);

CodeTag.propTypes = { usedBy: PropTypes.string, code: PropTypes.string };

export default CodeTag;
