import { css } from 'emotion';

export const redeemedTag = theme =>
  css({
    display: 'block',
    backgroundColor: theme.green,
    color: theme.white,
    borderRadius: '2px',
    height: 'fit-content',
    paddingLeft: '0.4375em',
    paddingRight: '0.4375em',
    width: 'fit-content',
  });

export const notRedeemedTag = theme =>
  css({
    display: 'block',
    backgroundColor: theme.primary_blue,
    color: theme.white,
    borderRadius: '2px',
    height: 'fit-content',
    paddingLeft: '0.4375em',
    paddingRight: '0.4375em',
    width: 'fit-content',
  });

export const refundedTag = theme =>
  css({
    display: 'block',
    backgroundColor: theme.primary_orange,
    color: theme.gray100,
    borderRadius: '2px',
    height: 'fit-content',
    paddingLeft: '0.4375em',
    paddingRight: '0.4375em',
    width: 'fit-content',
  });

export const cancelledTag = theme =>
  css({
    display: 'block',
    backgroundColor: theme.red_dark,
    color: theme.white,
    borderRadius: '2px',
    height: 'fit-content',
    paddingLeft: '0.4375em',
    paddingRight: '0.4375em',
    width: 'fit-content',
  });

export const tagLabel = css({
  fontSize: '0.6875rem',
  lineHeight: 1.5,
  textTransform: 'uppercase',
});
