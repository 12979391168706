import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Icon } from 'components/ui';

const CopyText = ({ children, successMessage, className }) => {
  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(children);
    successMessage && toast.success(successMessage);
  };

  return (
    <strong {...(className && { className })}>
      <Icon iconName="icon-Copy" onClick={handleCopyToClipBoard} /> {children}
    </strong>
  );
};

CopyText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  successMessage: PropTypes.string,
  className: PropTypes.string,
};

export default CopyText;
