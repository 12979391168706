const MerchantAddressesEnum = {
  Pending: {
    id: 1,
    value: 'Pending',
  },
  Approved: {
    id: 2,
    value: 'Approved',
  },
  Declined: {
    id: 3,
    value: 'Declined',
  },
  Edited: {
    id: 4,
    value: 'Edited',
  },

  getById: id => {
    const key = Object.keys(MerchantAddressesEnum).find(key => MerchantAddressesEnum[key].id === id);
    return key && MerchantAddressesEnum[key];
  },
};

export default MerchantAddressesEnum;
