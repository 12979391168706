import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Skeleton, Tag } from 'components';
import { useTheme } from 'emotion-theming';
import { getText } from '@veraio/strank';
import { Routes, TRANSACTION_STATUS_COLOR, TRANSACTION_STATUS, TRANSACTION_STATUS_INVERSE } from 'enums';
import { TransactionsAmountsTotals } from '../TransactionAmountsTotals';
import { transactionsInfoButtons, transactionsTotalsContainer, transactionsTotalsPanel } from './styles';
import { ReactComponent as WalletIcon } from '../../../../../../assets/images/wallet-icon-large.svg';

export const TransactionsTotalsPanel = ({ totalsData }) => {
  const theme = useTheme();
  const modalRef = useRef();

  const handleOpenStatusesLegendModal = () => modalRef.current?.open();

  return totalsData && totalsData.length > 0 ? (
    <div className={transactionsTotalsPanel(theme)}>
      <div className={transactionsTotalsContainer}>
        {totalsData.map((d, i) => (
          <TransactionsAmountsTotals key={i} fiat={d.fiat} one={d.one} oesp={d.oesp} title={d.title} />
        ))}
      </div>
      <div className={transactionsInfoButtons(theme)} data-id="transaction-totals-help">
        <Button linkTo={Routes.clearingSettlementExplained}>
          <WalletIcon />
          <label>{getText('clearingAndSettlementExplained')}</label>
        </Button>
        <Button onClick={handleOpenStatusesLegendModal}>{getText('transactionStatusLegend')}</Button>
      </div>

      <Modal ref={modalRef} modalTitle={getText('transactionStatusLegend')}>
        <span>
          <Tag type={TRANSACTION_STATUS_COLOR[TRANSACTION_STATUS.awaitingClearing]} fontSize="0.725rem">
            {getText(TRANSACTION_STATUS_INVERSE[TRANSACTION_STATUS.awaitingClearing])}
          </Tag>
        </span>
        <p>{getText('awaitingClearingLegendText')}</p>
        <br />
        <span>
          <Tag type={TRANSACTION_STATUS_COLOR[TRANSACTION_STATUS.awaitingSettlement]} fontSize="0.725rem">
            {getText(TRANSACTION_STATUS_INVERSE[TRANSACTION_STATUS.awaitingSettlement])}
          </Tag>
        </span>
        <p>{getText('awaitingSettlementLegendText')}</p>

        <br />
        <span>
          <Tag type={TRANSACTION_STATUS_COLOR[TRANSACTION_STATUS.settled]} fontSize="0.725rem">
            {getText(TRANSACTION_STATUS_INVERSE[TRANSACTION_STATUS.settled])}
          </Tag>
        </span>
        <p>{getText('settledLegendText')}</p>
      </Modal>
    </div>
  ) : (
    <Skeleton />
  );
};

TransactionsTotalsPanel.propTypes = {
  totalsData: PropTypes.arrayOf(
    PropTypes.shape({
      one: PropTypes.number,
      oesp: PropTypes.number,
      fiat: PropTypes.number,
      fiatCurrency: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};
