import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const resultsContainer = css({
  borderRadius: '10px !important',
  position: 'fixed !important',
  [Desktop]: { top: '21.6em !important' },
  [Mobile]: { top: '19.3em !important' },
});

export const searchInput = css({
  width: '100%',
  marginBottom: '1em !important',
  '& .ant-select-selector': {
    borderRadius: '20px !important',
  },
});
