import { css } from 'emotion';

export const footer = theme =>
  css({
    paddingTop: '1.875em',
    paddingBottom: '1.875em',
    backgroundColor: theme.gray600,
    color: theme.gray100,
  });

export const footerContent = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1em',
    paddingBottom: '1em',
    borderBottom: `1px solid ${theme.gray500}`,
  });

export const footerLogo = css({
  flex: '1 0 0',
  '& img': {
    height: '4rem',
  },
});

export const footerStatisticContainer = css({
  flex: '2 0 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .footerAbout': {
    '& p': {
      fontWeight: 500,
    },
    '& div': {
      marginTop: '.5em',
    },
    flex: '2 0 0',
    margin: '0 auto',
  },
});

export const copyrightContainer = css({
  fontWeight: 600,
});

export const statsPanel = css({
  display: 'flex',
  flex: '2 0 0',
});

export const statsPanelMetric = css({
  marginRight: '1.375em',
  lineHeight: 1.1,
});

export const statsPanelLabel = theme =>
  css({
    fontSize: '0.875rem',
    color: theme.gray200,
    display: 'block',
    marginBottom: '0.625em',
  });

export const statsPanelValue = theme =>
  css({
    fontSize: '1.625rem',
    fontWeight: 500,
    color: theme.blue,
    letterSpacing: 2,
  });
