import React, { useEffect, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { useHistory, Route } from 'react-router-dom';
import { BorderlessShadowlessGrayBox, Tabs } from 'components/ui';
import { Routes } from 'enums';
import { useUser } from 'stores';
import { getMyPendingClearingSettlementAmounts, setError } from 'services';
import { ProfileInformationTab, MyWishlistTab, MessagesTab, MyAddressesTab } from './components';
import { MyProfileOrders } from './Orders';
import { MyProfilePage, title } from './styles';
import { BundledTransactions, ClearingTab, SettlementTab } from './Transactions';

const MyProfile = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const history = useHistory();
  const { currentAccount } = useUser();
  const locationPath = window.location.pathname.split('/').pop();
  const [pendingClearingSettlementAmounts, setPendingClearingSettlementAmounts] = useState({});

  useEffect(() => {
    fetchMyClearingSettlementPendingAmounts();
  }, []);

  const fetchMyClearingSettlementPendingAmounts = async () => {
    const [res, err] = await getMyPendingClearingSettlementAmounts(currentAccount?.id);
    if (err) return setError(err);

    setPendingClearingSettlementAmounts(res);
  };

  const handleTabChange = tab => history.push(`${Routes.myProfile}/${tab}`);

  const tabs = {
    myProfileOrders: 'orders',
    profileInformation: 'profile-information',
    myWishlist: 'wishlist',
    yourMessages: 'messages',
    myAddresses: 'addresses',
    clearing: 'clearing',
    settlement: 'settlement',
  };

  const tabItems = [
    {
      key: tabs.myProfileOrders,
      label: getText('orders'),
      children: <MyProfileOrders />,
    },
    {
      key: tabs.profileInformation,
      label: getText('profileInformation'),
      children: <ProfileInformationTab />,
    },
    {
      key: tabs.myWishlist,
      label: getText('myWishlist'),
      children: <MyWishlistTab />,
    },
    {
      key: tabs.yourMessages,
      label: getText('yourMessages'),
      children: <MessagesTab />,
    },
    {
      key: tabs.myAddresses,
      label: getText('myAddresses'),
      children: <MyAddressesTab />,
    },
    {
      key: tabs.clearing,
      label: getText('clearing'),
      children: <ClearingTab pendingClearingSettlementAmounts={pendingClearingSettlementAmounts} />,
    },
    {
      key: tabs.settlement,
      label: getText('settlement'),
      children: <SettlementTab pendingClearingSettlementAmounts={pendingClearingSettlementAmounts} />,
    },
  ];

  return (
    <MyProfilePage theme={theme}>
      <BorderlessShadowlessGrayBox theme={theme}>
        <div className="content-container">
          <h1 className={`${title(theme)}`}>{getText('myProfile')}</h1>
        </div>
      </BorderlessShadowlessGrayBox>
      <div className="content-container">
        <Tabs
          destroyInactiveTabPane
          defaultActiveKey={tabs.myProfileOrders}
          activeKey={locationPath}
          onTabClick={handleTabChange}
          items={tabItems}
          theme={theme}
        />
        <Route path={Routes.bundledTransactions} exact component={BundledTransactions} />
      </div>
    </MyProfilePage>
  );
};

export default MyProfile;
