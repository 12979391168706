import { css, keyframes } from 'emotion';

const progressAnimation = progress =>
  keyframes({
    '0%': { strokeDashoffset: 455 },
    '100%': { strokeDashoffset: progress },
  });

export const circleStyles = progress =>
  css({
    strokeDasharray: 455,
    strokeDashoffset: 455,
    animation: `${progressAnimation(progress)} 1s linear forwards`,
  });
