import React from 'react';
import { useTranslations } from '@veraio/strank';
import emptyBoxImage from 'assets/images/noNotifications.svg';
import { noDealsContainer } from './styles';

export const NoDealsFound = () => {
  const { getText } = useTranslations();

  return (
    <div className={noDealsContainer}>
      <img src={emptyBoxImage} alt="No deals yet" />
      <h2>{getText('noDealsFound')}</h2>
    </div>
  );
};
