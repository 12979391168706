import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { SearchInput } from 'components/ui';
import { Dropdown, useUrlParams } from 'components/UIExternal';
import { TRANSACTION_CURRENCY_SELECT, TRANSACTION_STATUS_SELECT } from 'enums';
import { transactionsFilterBar } from './styles';

export const TransactionsFilterBar = ({ paginationRef, filterKeys, sortingKeys, sortOptions }) => {
  const { queryParams } = useUrlParams();
  const { getText } = useTranslations();

  const handleChange = keyValue => paginationRef.current?.applyFilters(keyValue);

  return (
    <div className={transactionsFilterBar} data-id="merchant-office-transactions-filters-bar">
      <div className="inputs-container" data-id="merchant-office-transactions-filters">
        {filterKeys.search && (
          <div>
            <SearchInput
              allowClear
              placeholder={`${getText('orderId')}, ${getText('transactionId')}, ${getText('buyerDetails')}}`}
              defaultValue={queryParams[filterKeys.search]}
              onPressEnter={val => handleChange({ [filterKeys.search]: val })}
            />
          </div>
        )}
        {filterKeys.statusId && (
          <div className="width-narrow">
            <Dropdown
              options={TRANSACTION_STATUS_SELECT(getText)}
              value={queryParams[filterKeys.statusId]}
              placeholder={getText('status')}
              onChange={val => handleChange({ [filterKeys.statusId]: val?.value })}
            />
          </div>
        )}
        {filterKeys.currency && (
          <div className="width-narrow">
            <Dropdown
              options={TRANSACTION_CURRENCY_SELECT(getText)}
              value={queryParams[filterKeys.currency]}
              placeholder={getText('currency')}
              onChange={val => handleChange({ [filterKeys.currency]: val?.value })}
            />
          </div>
        )}
      </div>
      <div className="inputs-container align-right" data-id="merchant-office-transactions-sorting">
        <label>{getText('sortBy')}</label>
        <div className="width-narrow nowrap">
          <Dropdown
            noClear
            options={sortOptions}
            value={queryParams[sortingKeys.sorting]}
            onChange={val => handleChange({ [sortingKeys.sorting]: val?.value })}
          />
        </div>
      </div>
    </div>
  );
};

TransactionsFilterBar.propTypes = {
  paginationRef: PropTypes.any,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  filterKeys: PropTypes.any,
  sortingKeys: PropTypes.any,
};
