import styled from '@emotion/styled';

export const BorderlessShadowedBox = styled.div`
  box-shadow: 0px 2px 16px rgba(34, 34, 34, 0.12);
`;
export const BorderedShadowlessBox = styled.div`
  border: ${props => `2px solid ${props.theme.gray500}`};
  border-radius: 3px;
`;
export const BorderedShadowlessGrayBox = styled.div`
  background-color: ${props => props.theme.gray600};
  border: ${props => `1px solid ${props.theme.gray500}`};
  border-radius: 3px;
`;
export const BorderlessShadowlessGrayBox = styled.div`
  background-color: ${props => props.theme.gray600};
`;
export const BorderedShadowlessBlueBox = styled.div`
  background-color: ${props => props.theme.skyblue};
  border: ${props => `1px solid ${props.theme.primary_blue}`};
  border-radius: 3px;
`;
