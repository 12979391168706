import { css } from 'emotion';
import { Mobile, Phone } from 'assets/css/breakpoints';

export const merchantCard = theme =>
  css({
    border: `1px solid ${theme.gray400}`,
    flexWrap: 'wrap',
    borderRadius: 10,
    padding: '1em 1.5em',
    display: 'flex',
    margin: '1em 0 2em',
    minHeight: '15rem',
    gap: '3rem',
    [Mobile]: {
      flexDirection: 'column',
    },
  });

export const merchantCardInfo = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2em 0',
    flex: '1 1 0',
    '& .info': {
      display: 'flex',
      '& .user-avatar': {
        width: '4.3rem',
        height: '4.3rem',
        borderRadius: 80,
        [Mobile]: {
          width: '6rem',
          height: '6rem',
        },
      },
      [Mobile]: {
        flexDirection: 'row-reverse',
        marginBottom: '2em',
        justifyContent: 'space-between',
      },
      [Phone]: {
        justifyContent: 'space-between',
      },
      '& .merchant-name': {
        wordBreak: 'word-break',
      },
      '& .verified-merchant': {
        width: '1.2rem',
        marginLeft: '.5em',
      },
      img: {
        marginRight: '1em',
      },
      p: {
        color: theme.gray300,
      }
    },
    [Mobile]: { borderBottom: `1px solid ${theme.gray400}`, paddingBottom: '2em' },
    '& .category-tags': {
      '& .tag': {
        backgroundColor: theme.skyblue,
        borderRadius: 10,
        padding: '.8em',
        marginRight: '.6em',
      },
      '& .tags-more': {
        color: theme.gray400,
      },
      [Mobile]: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
      },
    },
  });

export const merchantCardAttributes = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .attributes': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1.5rem',

      '& .soldDeals, .merchantSince, .rating': {
        '& h5': {
          textAlign: 'right',
          color: theme.gray400,
          fontSize: 14,
          fontWeight: 400,
        },
        '& h4': {
          color: theme.primary_blue,
          textAlign: 'right',
        },
      },
      '& .rating': {
        '& .ant-rate': {
          fontSize: '14px',
          marginTop: '0.1rem',
          '& .ant-rate-star': {
            marginRight: '5px !important',
            '&.ant-rate-star-full svg': {
              color: theme.primary_yellow,
              stroke: theme.primary_orange,
              strokeWidth: '3rem',
            },
            '&.ant-rate-star-zero svg': {
              color: 'transparent',
              stroke: theme.gray400,
              strokeWidth: '3rem',
            },
          },
        },
      },
      [Mobile]: {
        justifyContent: 'center',
        '& .soldDeals, .merchantSince, .rating': {
          '& h5': {
            textAlign: 'left',
          },
          '& h4': {
            color: theme.primary_blue,
            textAlign: 'left',
          },
        },
      },
    },
    '& > div': {
      flexWrap: 'wrap',
      '& h6': {
        width: 'max-content',
        margin: '0.4em 0 0 auto',
      },
    },
    '& .callToAction': {
      '& .buttons': {
        display: 'flex',
        gap: '1em',
        justifyContent: 'flex-end',
        '&>*': {
          maxWidth: '50%'
        },
        [Mobile]: { justifyContent: 'space-between', '& > a': { width: '100%' }, marginTop: '1em' },
      },
      '& .last-active': {
        color: theme.gray300,
        fontSize: '0.8rem',
        margin: '1em 0 .8em auto',
        fontWeight: 400,
      },
    },
    [Mobile]: {
      paddingTop: '2em',
    },
  });
