import { css } from 'emotion';

export const formStyle = css({
  '& label': {
    fontSize: '0.8125рem !important',
    fontWeight: 500,
  },
  '& .ant-picker': { width: '100%' },
});
export const pricePicker = theme =>
  css({
    '& .ant-slider-dot': { display: 'none' },
    '& .ant-slider': { margin: 0 },
    '& .ant-slider-mark-text': {
      transform: 'none !important',
      color: theme.gray200,
      top: '-1.875em',
      '&:last-of-type': { left: '92% !important' },
    },
    '& .ant-slider-track': {
      backgroundColor: `${theme.primary_yellow} !important`,
      height: '0.5em',
    },
    '& .ant-slider-rail': { height: '0.5em' },
    '& p': {
      marginTop: '2.5em',
      marginBottom: '2.375em',
      fontWeight: 400,
      fontSize: '0.75rem',
      color: theme.gray200,
    },
    '& h5': { textAlign: 'center' },
  });
export const discountBox = theme =>
  css({
    marginTop: '2em',
    padding: '1.25em 2.5em',
    textAlign: 'center',
    '& label': { fontSize: '0.75rem', fontWeight: 400, color: theme.gray200 },
    '& p': { fontSize: '1.4375rem', fontWeight: 700, color: theme.gray100, marginBottom: 0 },
  });
export const couponCountInput = css({
  width: '100% !important',
  '& .ant-input-number-handler-wrap': {
    opacity: 1,
    borderLeft: 'none',
    '& span': {
      borderTop: 'none',
    },
  },
});

export const packageStyles = css({
  fontSize: '0.8125rem',
  fontWeight: 500,
  marginBottom: '0.75em',
  marginTop: '1.5em',
});

export const label = theme =>
  css({
    '& .ant-form-item-label': {
      '& > label': {
        color: theme.gray300,
        fontWeight: 400,
      },
    },
  });

export const datePickerStyles = (showFiltersError, theme) =>
  css({
    '&.ant-picker': {
      border: `1px solid ${showFiltersError ? theme.red : theme.gray500}`,
      borderRadius: 2,
    },
  });

export const infoBVPoints = theme =>
  css({
    marginTop: '2.5em',
    marginBottom: '2.375em',
    fontWeight: 400,
    fontSize: '0.75rem',
    color: theme.gray200,
  });

export const popoverContainer = theme =>
  css({
    '& .ant-popover-inner-content': {
      padding: '0.5em',
      background: theme.gray100,
      borderRadius: '3px',
      maxWidth: '43.75rem',
      color: theme.white,
    },
    '& .ant-popover-arrow-content': {
      background: theme.gray100,
    },
    '& p': {
      marginBottom: 0,
      fontSize: '0.75rem',
    },
  });

export const bvPointsInfoPopupText = css({
  display: 'flex',
  alignItems: 'center',
  '& p': {
    marginRight: '0.3125em',
  },
});
