import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { brandColorPage23Container } from './styles';

export const BrandColorPage23 = () => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div>
      <div className={brandColorPage23Container(theme)}>
        <div className="brandColorPage23ContainerLeft">
          <div className="orangeOnBlue">
            <p>{getText('orangeOnBlue')}</p>
            <p className="bold">{getText('orangeOnBlue')}</p>
          </div>
          <div className="whiteOnOrange">
            <p>{getText('whiteOnOrange')}</p>
            <p className="bold">{getText('whiteOnOrange')}</p>
          </div>
          <div className="orangeOnBlack">
            <p>{getText('orangeOnBlack')}</p>
            <p className="bold">{getText('orangeOnBlack')}</p>
          </div>
          <div className="redOnGreen">
            <p>{getText('redOnGreen')}</p>
            <p className="bold">{getText('redOnGreen')}</p>
          </div>
        </div>
        <div className="brandColorPage23ContainerRight">
          <div className="blueOnOrange">
            <p>{getText('blueOnOrange')}</p>
            <p className="bold">{getText('blueOnOrange')}</p>
          </div>
          <div className="whiteOnBlue">
            <p>{getText('whiteOnBlue')}</p>
            <p className="bold">{getText('whiteOnBlue')}</p>
          </div>
          <div className="whiteOnBlack">
            <p>{getText('whiteOnBlack')}</p>
            <p className="bold">{getText('whiteOnBlack')}</p>
          </div>
          <div className="violetteOnNavy">
            <p>{getText('violetteOnNavy')}</p>
            <p className="bold">{getText('violetteOnNavy')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
