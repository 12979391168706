import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const globalStructureManagerInfoContainer = css({
  padding: '0 4em',
  margin: '2em 0',
});

export const globalStructureManagerInfoDescription = isCountryManager =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: isCountryManager ? 'row' : 'row-reverse',
    gap: '5em',
    [Mobile]: {
      flexWrap: 'wrap',
    },
    '& .imageSide': {
      flex: '1 0 0',
    },
    '& .contentSide': {
      flex: '2 0 0',
    },
    '& .paragraph': {
      textAlign: 'justify',
      marginTop: '2em',
    },
  });
