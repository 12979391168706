import { css } from 'emotion';

export const transactionsTotalsPanel = theme =>
  css({
    height: '100%',
    display: 'flex',
    backgroundColor: theme.gray900,
    alignItems: 'center',
    padding: '0.5rem 0.75rem',
  });

export const transactionsTotalsContainer = css({
  height: '100%',
  display: 'flex',
  margin: '0.5rem auto 0.5rem 0',
});

export const transactionsInfoButtons = theme =>
  css({
    width: 500,
    height: '100%',
    display: 'flex',
    alignItems: 'stretch',
    '& > div, & > a': {
      alignContent: 'center',
      marginLeft: '0.5rem',
      display: 'inline-block',
      backgroundColor: 'transparent',
      borderColor: theme.gray500,
      whiteSpace: 'normal',
      fontSize: '0.85rem',
      lineHeight: '1.125rem',
      fontWeight: 'normal',
      position: 'relative',
      cursor: 'pointer',

      '& label': {
        marginLeft: '50px',
        display: 'inline-block',
        cursor: 'pointer',
      },

      '& svg': {
        position: 'absolute',
        width: '45px',
        height: '50px',
        top: '50%',
        left: 10,
        marginTop: '-25px',
      },
    },
  });
