import { css } from 'emotion';

export const checkButtonContainer = css({
  display: 'flex',
  alignItems: 'center',
  '& .text-label': {
    margin: '0 0.5rem',
  },
});

export const draggableItem = (isHidden, theme) =>
  css({
    ...(isHidden && {
      position: 'relative',
      '& .ant-upload-list-item.ant-upload-list-item-done': {
        '&:before': {
          content: "''",
          position: 'absolute',
          inset: 0,
          display: 'block',
          width: '100%',
          height: '100%',
          background: `${theme.gray600}90`,
          zIndex: 1,
          opacity: 1,
          transition: 'opacity 0.3s ease',
          pointerEvents: 'none',
        },
        '&:hover': {
          '&:before': {
            content: "''",
            opacity: 0,
          },
        },
      },
    }),
  });
