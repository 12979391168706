import React from 'react';
import { Skeleton } from 'components/UIExternal';

const LoadingOrderDetailsDeal = () => (
  <li className="order-details-deal-container">
    <div className="order-details-deal-info">
      <Skeleton height={60} width={60} marginRight="1em" />
      <Skeleton height={20} width={200} />
    </div>
    <div className="order-details-deal-quantity">
      <Skeleton height={20} width={100} />
    </div>
    <div className="order-details-deal-price">
      <Skeleton height={20} width={100} />
    </div>
  </li>
);

export const LoadingOrderDetailsDeals = () =>
  Array(3)
    .fill(null)
    .map((_, ind) => <LoadingOrderDetailsDeal key={ind} />);
