import { useTheme } from 'emotion-theming';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Select } from 'antd';
import { Button } from 'components/UIExternal';
import { savedFiltersSelect, buttonStyles } from './styles';

const SavedFiltersPanel = ({ allSavedFilters, deleteFilter, loadFilterUrl, clearFilter, clearParentFilter }) => {
  const theme = useTheme();
  const { getText } = useTranslations();

  return (
    <Select
      className={savedFiltersSelect}
      allowClear
      placeholder={getText('savedFilters')}
      onClear={() => {
        clearFilter({});
        clearParentFilter({});
        window.history.pushState({}, null, '/listing?');
      }}
      optionFilterProp="key"
      onSelect={(_, option) => {
        const currentFilter = allSavedFilters.find(filter => filter.id === Number(option.key));
        loadFilterUrl(currentFilter);
      }}
      filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {allSavedFilters?.length &&
        allSavedFilters.map(filter => (
          <Select.Option value={filter.name} key={filter.id}>
            <div className="flex space-between" style={{ alignItems: 'center' }}>
              {filter.name}
              <Button className={buttonStyles} onClick={() => deleteFilter(filter.id)}>
                <FeatherIcon icon="trash" size={24} stroke={theme.gray100} />
              </Button>
            </div>
          </Select.Option>
        ))}
    </Select>
  );
};
SavedFiltersPanel.propTypes = {
  allSavedFilters: PropTypes.array,
  loadFilterUrl: PropTypes.func,
  clearFilter: PropTypes.func,
  clearParentFilter: PropTypes.func,
  deleteFilter: PropTypes.func,
};
export default SavedFiltersPanel;
