import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Menu } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button, useUrlParams } from 'components/UIExternal';
import { setError, getAllCategories } from 'services';
import { blackTextLink } from 'assets/css/globalCSS';
import { arrow } from './styles';

export const CategoryFilter = ({ onFetchData, className }) => {
  const { SubMenu } = Menu;
  const theme = useTheme();
  const { getText } = useTranslations();
  const [allCategories, setAllCategories] = useState([]);
  const { queryParams, setQueryParams } = useUrlParams();
  const [openKeys, setOpenKeys] = useState([queryParams?.categoryId]);

  const { categoryId } = queryParams;
  const searchForParent = (array, string) => {
    const find = ({ id, subCategories }) => id === string || (subCategories && subCategories.some(find));
    return array.filter(find)[0];
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const fetchAllCategories = async () => {
    const [res, err] = await getAllCategories();
    if (err) return setError(err);

    setAllCategories(res);
    searchForParent(res, Number(categoryId))
      ? setOpenKeys([searchForParent(res, Number(categoryId))?.id.toString(), categoryId])
      : setOpenKeys([categoryId]);
  };

  const handleSubMenuClick = val => {
    const key = val.toString();
    setOpenKeys(prevOpenKeys =>
      prevOpenKeys.includes(key) ? prevOpenKeys.filter(k => k !== key) : [...prevOpenKeys, key],
    );
  };

  const getHandleFilterChange = value => {
    setQueryParams({ ...queryParams, categoryId: value });
    onFetchData({ ...queryParams });
  };

  const renderSubCategories = category => {
    if (!category.subCategories || category.subCategories.length === 0) return null;

    return category.subCategories.map(subCategory =>
      subCategory.subCategories && subCategory.subCategories.length > 0 ? (
        <SubMenu
          key={subCategory.id}
          title={
            <div className="flex space-between">
              <Button
                type="link"
                small
                className={blackTextLink(theme)}
                onClick={() => getHandleFilterChange(subCategory.id)}
              >
                {getText(subCategory.name)}
              </Button>
              <Button
                type="link"
                small
                className={arrow(theme)}
                onClick={() => handleSubMenuClick(subCategory.id)}
                theme={theme}
              >
                <span
                  className={`${openKeys.includes(subCategory.id.toString()) && 'isExpanded'} icon-SingleChevron`}
                />
              </Button>
            </div>
          }
        >
          {renderSubCategories(subCategory)}
        </SubMenu>
      ) : (
        <Menu.Item onClick={() => getHandleFilterChange(subCategory.id)} key={subCategory.id}>
          {getText(subCategory.name)}
        </Menu.Item>
      ),
    );
  };

  return (
    <Menu openKeys={openKeys} mode="inline" triggerSubMenuAction="click" expandIcon={<></>} className={className}>
      {allCategories &&
        allCategories.map(category =>
          category.subCategories && category.subCategories.length > 0 ? (
            <SubMenu
              key={category.id}
              title={
                <div className="flex space-between">
                  <Button
                    type="link"
                    small
                    className={blackTextLink(theme)}
                    onClick={() => getHandleFilterChange(category.id)}
                  >
                    {getText(category.name)}
                  </Button>
                  <Button type="link" small className={arrow(theme)} onClick={() => handleSubMenuClick(category.id)}>
                    <span
                      className={`${openKeys.includes(category.id.toString()) && 'isExpanded'} icon-SingleChevron`}
                    />
                  </Button>
                </div>
              }
            >
              {renderSubCategories(category)}
            </SubMenu>
          ) : (
            <Menu.Item key={category.id}>{getText(category.name)}</Menu.Item>
          ),
        )}
    </Menu>
  );
};

CategoryFilter.propTypes = {
  categoryChangeHandler: PropTypes.func,
  onFetchData: PropTypes.func,
  className: PropTypes.string,
};
