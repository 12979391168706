import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { isNil, isNumber } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Spin } from 'antd';
import { SortDropdown } from 'components/ui';
import { Button, useUrlParams, Pagination } from 'components/UIExternal';
import ListingDeal from 'components/deal/ListingDeal';
import useError from 'services/errorHandling/useError';
import { getDealDetails, getAllConnectedDeals } from 'services/api/dealsService';
import { mb } from 'assets/css/globalCSS';
import { titleWrapper, backButton } from './styles';

const AllConnectedDeals = () => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const { dealName } = useParams();
  const { setError } = useError();
  const { queryParams, setQueryParams } = useUrlParams();
  const [deal, setDeal] = useState(null);
  const [connectedDeals, setConnectedDeals] = useState(null);

  useEffect(() => {
    fetchDealDetails();
  }, []);

  const fetchDealDetails = async () => {
    const [res, err] = await getDealDetails(dealName);
    err && setError(err);
    setDeal(res);
  };

  const fetchConnectedDeals = async options => {
    const noPageChange = !isNumber(options.pageNumber);
    const params = { ...queryParams, ...options, ...(noPageChange && { pageNumber: 1 }) };
    setQueryParams(params);
    const [res, err] = await getAllConnectedDeals(dealName, params);
    err ? setError(err) : setConnectedDeals(res?.items);
    return res;
  };

  return (
    <>
      {(isNil(deal) || isNil(connectedDeals)) && (
        <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
          <Spin size="large" />
        </div>
      )}

      <Row gutter={{ xs: 0, sm: 0, md: 0, lg: 78 }} className="content-container">
        <Col lg={7} span={24} className="gutter-row">
          <Button type="link" className={backButton(theme)} linkTo={`/deal/${dealName}`}>
            <FeatherIcon icon="arrow-left" size={20} />
            {getText('backToDeal')}
          </Button>

          {!isNil(deal) && (
            <div className="connected-deals">
              <ListingDeal deal={deal} isVertical mainDealName={dealName} isMerchant={deal?.isOwner} />
            </div>
          )}
        </Col>
        <Col lg={17} span={24}>
          <div className={`${titleWrapper} connected-deals-title` /* desktop-only */}>
            <p>{getText('allConnectedDeals')}</p>
            <SortDropdown
              onSortChange={val => fetchConnectedDeals({ dealsSorting: val })}
              value={queryParams.dealsSorting}
              className="sortdropdown-connected-deals"
            />
          </div>
          <div>
            {!isNil(connectedDeals) &&
              connectedDeals?.map((connectedDeal, idx) => (
                <ListingDeal
                  key={idx}
                  deal={connectedDeal}
                  isMerchant={deal?.isOwner}
                  mainDealId={deal?.id}
                  mainDealName={connectedDeal?.name}
                />
              ))}
          </div>
          <Pagination justify="center" onChange={fetchConnectedDeals} pageSize={8} />
        </Col>
      </Row>
    </>
  );
};

export default AllConnectedDeals;
