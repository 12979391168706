import { css } from 'emotion';

export const backgroundPicture = ({ size, src, width, height, position, repeat, className }) =>
  css(
    {
      backgroundImage: `url(${src})`,
      backgroundSize: size || 'cover',
      backgroundPosition: position || 'center',
      backgroundRepeat: repeat || 'no-repeat',
      width: width || '100%',
      height: height || '100%',
    },
    className,
  );

export const imageContainer = ({ size, position, className }) =>
  css(
    {
      objectFit: size || 'cover',
      objectPosition: position || 'center',
    },
    className,
  );

export const child = ({ fontSize, color }) =>
  css(
    {
      fontWeight: 'bold',
      fontSize: fontSize || '1rem',
    },
    color && {
      color,
    },
  );
