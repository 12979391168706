import React, { useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { useUser } from 'stores';
import { getBundledTransactions } from 'services';
import { useTheme } from 'emotion-theming';
import { Button, Icon, Table } from 'components';
import { useHistory, useParams } from 'react-router-dom';
import { header } from './styles';
import { tabContentContainer, transactionsTableContainer } from '../../styles';
import { BUNDLE_TRANSCTIONS_COLUMNS } from './config';

const BundledTransactions = () => {
  const { getText } = useTranslations();
  const history = useHistory();
  const theme = useTheme();
  const { transactionId } = useParams();
  const { currentAccount, isMerchant } = useUser();
  const tableRef = useRef();

  const fetchBundledTransactions = async (accountId, transactionId) => {
    const [res, err] = await getBundledTransactions(accountId, transactionId);

    return [{ items: res }, err];
  };

  return (
    <div className={tabContentContainer} data-id="bundled-trabsactions-tab">
      <div className={header}>
        <Button type="link" onClick={() => history.goBack()} small>
          <Icon iconName="las la-arrow-left" />
        </Button>
        <h2 className="flex flex-center deal-name">#{transactionId}</h2>
      </div>
      {currentAccount && currentAccount.id && (
        <div className={transactionsTableContainer} data-id="bundled-trabsactions-tab-table">
          <Table
            ref={tableRef}
            hasPagination
            pageSize={10}
            withPageDropdown
            getDataMethod={() => fetchBundledTransactions(currentAccount.id, transactionId)}
            columns={BUNDLE_TRANSCTIONS_COLUMNS(theme, getText, isMerchant, currentAccount?.name)}
          />
        </div>
      )}
    </div>
  );
};

export default BundledTransactions;
