import { css } from 'emotion';

export const publicMerchant = theme =>
  css({
    '& .ant-tabs-top': {
      '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
        color: theme.gray100,
      },
      '& .ant-tabs-tab:hover': {
        color: theme.gray100,
      },
      '& .ant-tabs-tab': {
        color: theme.gray300,
      },
      '& .ant-tabs-ink-bar': {
        background: theme.primary_yellow,
      },
      '& .ant-tabs-tab-btn:focus': {
        color: theme.gray100,
      },
    },
    '& .ant-rate-star': {
      marginRight: '5px !important',
      fontSize: 14,
      '&.ant-rate-star-full svg': {
        color: theme.primary_yellow,
        stroke: theme.primary_orange,
        strokeWidth: '3rem',
      },

      '&.ant-rate-star-zero svg': {
        color: 'transparent',
        stroke: theme.gray400,
        strokeWidth: '3rem',
      },
    },
  });
