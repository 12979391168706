import styled from '@emotion/styled';
import { Rate } from 'antd';

const BasicRatingStyles = styled(Rate)`
  & .ant-rate-star:not(:last-child) {
    margin-right: 3px;
  }
`;

export const BlackRating = styled(BasicRatingStyles)`
  &.ant-rate {
    color: ${props => props.theme.gray100};
  }
`;

export const FilterRating = styled(BasicRatingStyles)`
  &.ant-rate {
    color: ${props => props.theme.primary_yellow};
  }
`;

export const FilterRateContainer = styled.div`
  font-weight: 500;
  color: ${props => props.theme.gray100};
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
`;
