import { css } from 'emotion';

export const brandGuideLogoContainer = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    '& .brandGuideLogoContainerLeft': {
      flex: '2 0 0',
      textAlign: 'justify',
    },
    '& .brandGuideLogoContainerRight': {
      flex: '3 0 0',
      margin: '0 0 0 auto',
      '&.page14': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        '& div': {
          display: 'grid',
          gridTemplateColumns: 'auto 120px',
          '& .subText': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.skyblue,
            padding: '1em',
            fontSize: '.8rem',
          },
          '& img': {
            width: '100%',
            display: 'block',
            margin: 'auto',
          },
        },
      },
    },
    '& .brandGuideLogoContainerTitle': {
      color: theme.blue,
      textTransform: 'uppercase',
      fontWeight: 600,
    },
    '& .brandGuideLogoContainerText': {
      color: theme.blue,
      fontSize: '1.1rem',
      '&.subText': {
        textAlign: 'center',
      },
    },
    '& .brandGuideLogoContainerList': {
      marginTop: '1em',
      listStyle: 'disc',
    },
  });

export const brandGuideLogoImg = css({
  width: '70%',
  margin: '0 auto',
});
