import { css } from 'emotion';

export const pageOneGuide = theme =>
  css({
    fontSize: '8rem',
    color: theme.white,
    lineHeight: '1.2',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  });
