import { css } from 'emotion';

export const modalContainer = theme =>
  css({
    '& .ant-modal-body': {
      padding: '1.875em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& h5': {
      marginBottom: '1.25em',
      fontSize: '1.4375rem',
      fontWeight: 700,
    },
    '& p': {
      marginBottom: '1.25em',
      fontSize: '1rem',
      color: theme.gray200,
    },
    '& .modal-buttons-container button:first-of-type': {
      marginRight: '0.625em',
    },
  });

export const questionMarkIcon = theme =>
  css({
    marginBottom: '1.5em',
    borderRadius: '21px',
    fontSize: '2.1875rem',
    display: 'flex',
    width: '2.9375rem',
    height: '2.9375rem',
    backgroundColor: theme.violet,
    color: theme.white,
    justifyContent: 'center',
    alignItems: 'center',
  });
