import React, { useState } from 'react';
import { useTheme } from 'emotion-theming';
import { isDesktop } from 'react-device-detect';
import FeatherIcon from 'feather-icons-react';
import { Tooltip } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { useUser } from 'stores';
import { Routes } from 'enums';
import CategoriesList from './CategoriesList';
import {
  whiteHeaderContainer,
  bottomHeader,
  nearbyButton,
  mapAndAddDealContainer,
  categoryBtnStyle,
  categoryBtnContainer,
  categoriesListContainer,
} from './styles';

const NavigationBar = ({ categories }) => {
  const theme = useTheme();
  const { isDisabled, isMerchant } = useUser();
  const { getText } = useTranslations();
  const [activeCategories, setActiveCategories] = useState({
    category: null,
    subCategories: null,
    subSubCategories: null,
    lastCategories: null,
  });

  const handleOpenSection = (isOpen, selectedCategory) =>
    setActiveCategories({
      category: isOpen ? selectedCategory : null,
      subCategory: null,
      subSubCategory: null,
      lastCategories: null,
    });

  return (
    <>
      {isDesktop && !isDisabled && (
        <div className={whiteHeaderContainer(theme)}>
          <div className={`${bottomHeader} content-container`}>
            <div className={categoryBtnContainer}>
              {categories?.map((category, ind) => {
                const isActive = activeCategories?.category?.id === category?.id;
                return (
                  <Tooltip
                    key={ind}
                    open={isActive}
                    title={
                      <CategoriesList
                        activeCategories={activeCategories}
                        onChange={setActiveCategories}
                        onClose={() => handleOpenSection(false)}
                      />
                    }
                    trigger="click"
                    placement="bottomLeft"
                    overlayClassName={categoriesListContainer(theme)}
                    onOpenChange={open => handleOpenSection(open, category)}
                  >
                    <span className={categoryBtnStyle(theme, isActive)}>{getText(category?.name)}</span>
                  </Tooltip>
                );
              })}
            </div>
            <div className={mapAndAddDealContainer}>
              <Button type="link" small linkTo={Routes.nearbyMerchants}>
                <FeatherIcon icon="map-pin" size={20} stroke={theme.gray100} />
                <p className={nearbyButton(theme)}>{getText('nearByMerchants')}</p>
              </Button>
              {isMerchant && (
                <Button type="info" small linkTo={Routes.addDealPage}>
                  {getText('addDeal')}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NavigationBar;
