import React from 'react';
import { FacebookShareButton, WhatsappShareButton, FacebookMessengerShareButton } from 'react-share';
import { getText } from '@veraio/strank';
import { Button, Icon } from 'components';
import { copyToClipBoard } from 'utils';
import { shareButtons } from './styles';

export const SharableButtons = ({ toggleModal }) => {
  const shareableLink = window.location.href.replace(/&tab=\w+/, '&tab=overview');
  const handleCopyToClipBoard = () => {
    copyToClipBoard(shareableLink, getText('linkCopied'));
    toggleModal(false);
  };

  return (
    <div className={shareButtons}>
      <Button type="default" onClick={handleCopyToClipBoard}>
        <Icon iconName="las la-2x la-copy" />
        {getText('copyUrl')}
      </Button>
      <Button type="default">
        <FacebookShareButton url={shareableLink} className="sharable-button">
          <Icon iconName="lab la-2x la-facebook" />
          <span>{getText('facebook')}</span>
        </FacebookShareButton>
      </Button>
      <Button type="default">
        <WhatsappShareButton url={shareableLink} className="sharable-button">
          <Icon iconName="lab la-2x la-whatsapp" />
          <span>{getText('whatsapp')}</span>
        </WhatsappShareButton>
      </Button>
      <Button type="default">
        <FacebookMessengerShareButton url={shareableLink} className="sharable-button">
          <Icon iconName="lab la-2x la-facebook-messenger" />
          <span>{getText('messenger')}</span>
        </FacebookMessengerShareButton>
      </Button>
    </div>
  );
};
