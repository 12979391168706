import { Font } from '@react-pdf/renderer';
import styled from '@emotion/styled';
import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';
import Noto from 'assets/fonts/noto/regular.ttf';
import NotoSemiBold from 'assets/fonts/noto/medium.ttf';
import NotoBold from 'assets/fonts/noto/bold.ttf';

Font.register({
  family: 'Noto',
  fonts: [{ src: Noto }, { src: NotoSemiBold, fontWeight: 600 }, { src: NotoBold, fontWeight: 800 }],
});

export const AppStyles = styled.div`
  ${Desktop} {
    & .mobile-only {
      display: none;
    }
  }
  ${Mobile} {
    & .desktop-only {
      display: none;
    }
  }
`;

export const contentWrapper = css({
  paddingTop: '9.375em',
  [Mobile]: {
    paddingTop: '7.125em',
  },
  '@media (max-width: 520px)': {
    paddingTop: '10em',
  },
  '@media (max-width: 383px)': {
    paddingTop: '13em',
  },
});
