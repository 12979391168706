import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  contentBox: {
    position: 'absolute',
    width: '100%',
    height: 160,
    top: 0,
    padding: 15,
  },
  textBox: {
    businessName: {
      fontSize: 16,
      fontWeight: 800,
      color: '#fff',
    },
    pricesBlock: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      bottom: 0,
      right: 0,
      fontSize: 40,
      fontWeight: 800,
      price: {
        marginRight: 10,
      },
      blue: {
        color: '#fff',
      },
      yellow: {
        color: '#19295D',
      },
      green: {
        color: '#19295D',
      },
    },
  },
});
