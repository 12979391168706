import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Text, View, Svg, Path, G, Image, Polygon } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const color = {
  st0: '#3A6DB5',
  st1: '#FECF0C',
};

const CarTemplate = ({ qrCode, businessInfo }) => {
  const SVGComponent = () => (
    <Svg x="0px" y="0px" viewBox="0 0 5669.3 1417.3" style={{ position: 'absolute', top: 0 }}>
      <G>
        <Path
          fill={color.st0}
          d="M446.1,441.2c-10.3,16.9-32.7,23.7-53.4,19.5v-82.3c20-5.5,40.1-0.1,51.4,15.4   c4.8,6.9,7.6,15,8,23.4C452.4,425.7,450.4,434,446.1,441.2L446.1,441.2z M425.1,326.7c-26.4-3.6-53.6-1.4-80.4-1.7   c-1.1,0-2.1,1.2-3.6,2.1v186.2c24,0,46.9,0.3,69.8-0.1c48.2-0.8,88.9-38.1,92.9-84.5C508.1,378.5,474.9,333.4,425.1,326.7z"
        />
        <Polygon
          fill={color.st0}
          points="509.8,726.1 509.8,542.2 557.6,542.2 557.6,607.7 620.3,607.7 620.3,542.4    669.2,542.4 669.2,726.2 621,726.2 621,660.4 558.6,660.4 558.6,726.1  "
        />
        <Polygon
          fill={color.st0}
          points="561.9,375.9 561.9,393.5 601.2,393.5 601.2,445.7 562,446.1 562,462.6 651.2,462.6    651.2,512.3 509.8,512.3 509.8,325.5 651.1,325.5 651.1,375.9  "
        />
        <Polygon
          fill={color.st0}
          points="1195.5,727.5 1055.4,727.5 1055.4,541.9 1195.1,541.9 1195.1,589.8 1106,589.8    1106,609.8 1144.9,609.8 1144.9,659 1106.4,659 1106.4,678.5 1195.4,678.5  "
        />
        <Path
          fill={color.st0}
          d="M499.7,569.1c-12.1,9.3-36.3,30.3-36.3,30.3s-18.2-9.2-33.2-12.4c-9.6-2-28.6-4.3-34.7,5.9   c-6.3,10.6,9.3,15.6,16.3,16.7c9.3,1.4,20.7,0.2,30,1.8c38.9,6.7,60,29.6,56.4,64.4c-1.5,12.3-6.6,23.8-14.8,33.2   c-33.5,36.6-107.8,27.3-140.8-13.2l38.1-27c0,0,15.7,11.4,32.4,13.6c9.9,1.3,21.4,1.4,30.2-3.9c2.6-1.4,4.5-3.8,5.3-6.6   c1.6-6.8-6.5-10.5-12-11.4c-9.4-1.5-19-2.2-28.4-3.2c-34.8-3.7-59.1-23.5-64.3-52.4c-3.1-16.8,1.5-31.7,12.4-44.4   C388,523.8,462.6,533.7,499.7,569.1z"
        />
        <Path
          fill={color.st0}
          d="M1045,699.8l-40.6,28l-62-68.3l-1,66.7h-48.2V542.4h47.7v69.3c0,0,42.9-47.2,63.5-70.6   l39.1,29.6l-54.4,63.5L1045,699.8z"
        />
        <Path
          fill={color.st1}
          d="M779.4,326c35.4,63.2,69.1,123.5,103.8,185.6h-55.3l-48.5-85.9c-17.1,30.3-32.9,58.1-48.7,86   h-54.4L779.4,326z"
        />
        <Path
          fill={color.st1}
          d="M779.4,640.5L731,726h-54.4l102.8-185.3l103.8,185.4h-55.4   C812.4,698.7,796.6,670.8,779.4,640.5z"
        />
        <Polygon
          fill={color.st0}
          points="942.2,325.6 942.2,462.4 1032.2,462.4 1032.2,512.2 891.5,512.2 891.5,325.6  "
        />
        <Path
          fill={color.st0}
          d="M1308.6,615.9c-0.5,5.4-2.8,10.5-6.7,14.4c-3.8,3.9-8.9,6.3-14.4,6.8   c-10.6,0.8-21.3,0.2-33.1,0.2V591c12.6,0,24.3-1.1,35.6,0.3C1301.6,592.8,1309.5,604.7,1308.6,615.9z M1334.5,670.3   c4.4-5.6,7.6-9.6,10.6-13.7c29.9-41.3,8.3-104-41.9-112.4c-31.9-5.4-65.4-1-99.1-1v184.5h49.4v-39.4c0,0,26.3-3.1,39.8,9.7   c14.9,14.2,29.6,28.5,46.4,44.5l34-36.1v-2.6L1334.5,670.3z"
        />
      </G>
    </Svg>
  );

  return (
    <Document>
      <Page size={[5669.3, 1417.3]}>
        <View>
          <SVGComponent />
          <View style={generalStyles.wrapper}>
            <View style={s.contentBox}>
              <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
              <View>
                <Image source={qrCode} style={s.qrCodeStyles} />
                <View style={s.contentBox.infoText}>
                  <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
                  <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
                  <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
                  <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

CarTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default CarTemplate;
