import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isNil, isEmpty } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Row } from 'antd';
import { dealWeightType } from 'enums';
import { MerchantAndAddresses } from './components';
import {
  contentContainer,
  merchantContainer,
  fullWidth,
  packageStyles,
  packageContainer,
  tagListStyles,
  tagStyles,
  paragraphs,
} from './styles';

const DealContentPanel = ({ deal }) => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { addresses, additionalInformation, height, width, length, weight, weightTypeId } = deal;
  const renderParagraphs = text =>
    text?.split('\n').map((paragraph, idx) => (
      <p className={paragraphs(theme)} key={idx}>
        {paragraph}
      </p>
    ));
  const weightType = Object.keys(dealWeightType).find(el => dealWeightType[el] === weightTypeId);

  const dealDetails = getDynamicTranslation(deal.dealDetails);

  return (
    <div className={contentContainer}>
      <h4 className="section-title">{getText('description')}</h4>
      {renderParagraphs(dealDetails.description)}

      <h6 className="section-subtitle">{getText('dealSpecificTAndC')}</h6>
      {renderParagraphs(dealDetails.termsAndConditions)}

      {!isEmpty(deal.tags) && (
        <>
          <h6 className="section-subtitle">{getText('tags')}</h6>
          <div className={tagListStyles}>
            {deal.tags?.map((tag, idx) => (
              <div key={idx} className={tagStyles(theme)}>
                {tag}
              </div>
            ))}
          </div>
        </>
      )}

      <h6 className="section-subtitle">{getText('packageSize')}</h6>
      <div className={packageContainer}>
        <span className={packageStyles(theme)}>
          {getText('height')} {height} cm
        </span>
        <span className={packageStyles(theme)}>
          {getText('width')} {width} cm
        </span>
        <span className={packageStyles(theme)}>
          {getText('depth')} {length} cm
        </span>
      </div>

      {!isNil(weight) && !isNil(weightTypeId) && (
        <div className={packageContainer}>
          <h6 className="section-subtitle package-weight">{getText('packageWeight')}</h6>
          <span className={packageStyles(theme)}>
            {weight} {weightType}
          </span>
        </div>
      )}

      {!!addresses.length && (
        <>
          <h6 className="section-subtitle">{getText('merchantAndAddresses')}</h6>
          <div className={merchantContainer}>
            <Row gutter={[26, 26]} className={fullWidth}>
              {addresses.map((address, idx) => (
                <MerchantAndAddresses key={idx} address={address} />
              ))}
            </Row>
          </div>
        </>
      )}

      {additionalInformation && (
        <>
          <h6 className="section-subtitle">{getText('additionalInformation')}</h6>
          <p>{additionalInformation}</p>
        </>
      )}
    </div>
  );
};

DealContentPanel.propTypes = {
  deal: PropTypes.object,
};

export default DealContentPanel;
