import axios from 'axios';

export const setUpTheme = async () => {
  try {
    const { data } = await axios.get('/theme.json');
    return data;
  } catch (err) {
    return {};
  }
};
