import React from 'react';
import PropTypes from 'prop-types';
import CategoriesColumn from './CategoriesColumn';

const CategoriesList = ({ activeCategories, onChange, onClose }) => (
  <>
    {activeCategories?.category && (
      <CategoriesColumn
        isMain
        category={activeCategories.category}
        childCategory={activeCategories.subCategory}
        onChange={subCategory => onChange(prev => ({ ...prev, subCategory, subSubCategory: null }))}
        onClose={onClose}
      />
    )}
    {activeCategories?.subCategory && (
      <CategoriesColumn
        category={activeCategories.subCategory}
        childCategory={activeCategories.subSubCategory}
        onChange={subSubCategory => onChange(prev => ({ ...prev, subSubCategory }))}
        onClose={onClose}
      />
    )}
    {activeCategories?.subSubCategory && (
      <CategoriesColumn
        category={activeCategories.subSubCategory}
        childCategory={activeCategories.lastCategories}
        onClose={onClose}
      />
    )}
  </>
);

CategoriesList.propTypes = {
  activeCategories: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default CategoriesList;
