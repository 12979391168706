import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { PDFDownloadLink } from '@react-pdf/renderer';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import QRCode from 'qrcode';
import { Dropdown, Button } from 'components/UIExternal';
import { qrCodes } from 'enums/MerchantOffice';
import { mr } from 'assets/css/globalCSS';
import { qrBlock, versionDropdown } from './styles';
import MerchantQRTemplate from './merchantQRPdf';

const MerchantQRCodeBlock = ({ link, businessName }) => {
  const { getText } = useTranslations();
  const [qrCode, setQrCode] = useState({ src: null, ref: null, version: 1 });
  const refImg = useRef();

  useEffect(() => {
    generateQR(link);
  }, []);

  const generateQR = async text => {
    const res = await QRCode.toDataURL(text);
    res && setQrCode(prev => ({ ...prev, src: res }));
  };

  return (
    <div className={qrBlock}>
      <div>
        <p>{getText('qrCodeMerchantProfile')}</p>
        <Dropdown
          small
          noClear
          value={qrCode.version}
          placeholder={getText('selectDesignVersion')}
          options={qrCodes(getText)}
          onChange={e => setQrCode(prev => ({ ...prev, version: e.value }))}
          className={versionDropdown}
        />
        <PDFDownloadLink
          document={
            <MerchantQRTemplate
              subtitleBusinessName={getText('thisIsMyBusinessName', { name: businessName })}
              businessName={businessName}
              qrCodeImg={qrCode.src}
              version={qrCode.version}
            />
          }
          fileName={`Merchant code ${moment().format('yyyy-mm-DD')}`}
        >
          {() => (
            <Button type="secondary" small fullWidth>
              <FeatherIcon icon="download" size={15} className={mr({ lg: 5, md: 5 })} />
              {getText('downloadQr')}
            </Button>
          )}
        </PDFDownloadLink>
      </div>

      <img src={qrCode.src} alt="qr" ref={refImg} />
    </div>
  );
};

MerchantQRCodeBlock.propTypes = {
  link: PropTypes.string,
  businessName: PropTypes.string,
};

export default MerchantQRCodeBlock;
