import { invert } from 'lodash-es';

export const businessStatus = {
  pending: 1,
  approved: 2,
  declined: 3,
  edited: 4,
};

export const businessStatusInverse = invert(businessStatus);

export const businessStatusSelect = Object.entries(businessStatus).map(el => ({ label: el[0], value: el[1] }));

export const businessType = {
  individual: 1,
  business: 2,
};

export const businessTypeInverse = invert(businessType);

export const businessTypeSelect = Object.entries(businessType).map(el => ({ label: el[0], value: el[1] }));
