import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const tabWrapper = css({
  padding: '2em 0px',
});

export const headerContainer = css({
  display: 'flex',
  gap: '1em',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media (max-width: 530px)': {
    justifyContent: 'center',
  },
});

export const headerTitle = theme =>
  css({
    color: theme.gray100,
    fontSize: '1.5em',
    fontWeight: 700,
  });

export const headerSubtitle = theme =>
  css({
    color: theme.gray100,
    fontSize: '1rem',
    width: '51.875rem',
    marginBottom: '3em',
    [Mobile]: {
      width: '100%',
    },
    '@media (max-width: 530px)': {
      textAlign: 'center',
    },
  });

export const materialContainer = css({
  display: 'flex',
  alignItems: 'center',
  padding: '2em 0',
  [Mobile]: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
});

export const imgContainer = css({
  width: '18.75rem',
  height: '11.875rem',
  overflow: 'hidden',
  borderRadius: '10px',
  position: 'relative',
  '& img': {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    width: '18.75rem',
  },
});

export const infoContainer = css({
  padding: '1.5em',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const free = theme =>
  css({
    background: theme.free,
    color: theme.green,
    padding: '0.25em 0.5em',
    fontSize: '0.6875rem',
    display: 'flex',
    textTransform: 'uppercase',
    fontWeight: 600,
    marginBottom: '0.5em',
  });

export const materialTitle = theme =>
  css({
    fontWeight: 500,
    color: theme.gray100,
    fontSize: '2rem',
  });

export const materialSizes = theme =>
  css({
    color: theme.gray100,
    fontSize: '1rem',
  });

export const downLoadBtn = css({
  marginBottom: '1em',
  marginTop: '0.75em',
  width: '14.0625rem',
  [Mobile]: {
    display: 'flex',
    flex: 1,
  },
});

export const materialRow = theme =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: `1px solid ${theme.gray500}`,
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
    },
    [Mobile]: {
      justifyContent: 'center',
    },
    '@media (max-width: 830px)': {
      '& > div': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > a': {
          paddingBottom: '0',
        },
        '& > div': {
          paddingTop: '0',
        },
      },
    },
    '@media (max-width: 615px)': {
      flexDirection: 'column',
      '& > a': {
        paddingBottom: '0',
        margin: '0 auto',
      },
      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > a': {
          alignItems: 'center',
          '& div:last-child': {
            textAlign: 'center',
          },
        },
      },
    },
  });

export const dropDownBusiness = css({
  width: '14.0625rem',
});

export const actionContainer = css({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
});

export const selectSection = css({
  padding: '1.5em',
});
