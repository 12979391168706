import { css } from 'emotion';
// import { Mobile } from 'assets/css/breakpoints';

export const modalContainer = css({
  '& .ant-modal-body': {
    padding: '1.875em',
    display: 'flex',
    flexDirection: 'column',
  },
  '& .buttons': {
    justifyContent: 'center',
  },
  '& .buttons :first-child': {
    marginRight: '0.5em',
  },
});

export const responseForm = css({
  '& .ant-form-item': {
    marginTop: '1.25em',
  },
  '& .ant-form-item-control-input-content > textarea': {
    minHeight: '6.25rem',
  },
});
