export const merchantAddressesMapContainer = {
  position: 'relative',

  '& .leaflet-popup-content': {
    '& .business-pin-label': {
      margin: '0 0 0.3em',
    },
    '& a': {
      minHeight: 0,
      padding: 0,
      maxWidth: '10rem',
      whiteSpace: 'break-spaces',
      '&:not(:last-of-type)': {
        marginBottom: '1.5em',
      },
    },
  },
};
