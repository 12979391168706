import React from 'react';
import { useTranslations } from '@veraio/strank';
import { userRoles } from 'enums';
import cMImage from 'assets/images/globalStructurePage/globalStructureCMImage.svg';
/* when we have two roles for manage. One for country and one for area */
// import aMImage from 'assets/images/globalStructurePage/globalStructureAMImage.svg';
import { globalStructureManagerInfoContainer, globalStructureManagerInfoDescription } from './styles';

/* when we have two roles for manage. One for country and one for area */

// const managerInfo = {
//   [userRoles.DS_COUNTRY_MANAGER]: {
//     title: 'whoAreMangersRole',
//     img: cMImage,
//     description: ['whoAreCmParagraphOne', 'whoAreCmParagraphTwo', 'whoAreCmParagraphThree'],
//   },
//   [userRoles.DS_AREA_MANAGER]: {
//     title: 'whoAreMangersRole',
//     img: aMImage,
//     description: ['whoAreAmParagraphOne', 'whoAreAmParagraphTwo', 'whoAreAmParagraphThree'],
//   },
// };

const managerInfo = {
  title: 'whoAreMangersRole',
  img: cMImage,
  description: ['whoAreManagersParagraphOne', 'whoAreCmParagraphTwo', 'whoAreCmParagraphThree'],
};

export const GlobalStructureManagerInfo = ({ role }) => {
  const { getText } = useTranslations();

  return (
    <div className={globalStructureManagerInfoContainer}>
      <div className={globalStructureManagerInfoDescription(role === userRoles.DS_COUNTRY_MANAGER)}>
        {/* when we have two roles for manage. One for country and one for area */}

        {/* <img src={managerInfo[role].img} alt="manager" className="imageSide" />
        <div className="contentSide">
          <h1>{getText(managerInfo[role]?.title, { role: getText(role) })}</h1>
          {managerInfo[role].description.map((paragraph, ind) => (
            <p key={ind} className="paragraph">
              {getText(paragraph)}
            </p>
          ))}
        </div> */}

        <img src={managerInfo.img} alt="manager" className="imageSide" />
        <div className="contentSide">
          <h1>{getText('whoAreManagers')}</h1>
          {managerInfo.description.map((paragraph, ind) => (
            <p key={ind} className="paragraph">
              {getText(paragraph)}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
