import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import {
  DiscountStampBottom,
  DiscountStampTop
} from './styles';

const DiscountStamp = ({ deal, position, className }) => {
  const theme = useTheme();
  const DiscountStampComponent = position && position === 'top' ? DiscountStampTop : DiscountStampBottom;

  return (
    <DiscountStampComponent theme={theme} className={className}>
      <label>
          -{deal.discount}
          <span className='icon-Percent'/>
      </label>
    </DiscountStampComponent>
  );
};

DiscountStamp.propTypes = {
  deal: PropTypes.object,
  position: PropTypes.string,
  className: PropTypes.string
};

export default DiscountStamp;