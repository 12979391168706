import { css } from 'emotion';
import { isNumber, isString } from '@veraio/core';

const NUMBER_OF_COLUMNS = 12;

export const row = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
};

export const rowComp = ({
  column,
  reverse,
  noWrap,
  align,
  justify,
  padding,
  margin,
  minWidth,
  className,
  gap,
  horizontalGap,
  verticalGap,
  flex,
}) =>
  css(
    row,
    {
      flexDirection: column ? (reverse ? 'column-reverse' : 'column') : reverse ? 'row-reverse' : 'row',
      flexWrap: noWrap ? 'no-wrap' : 'wrap',
    },
    align && {
      alignItems: align,
    },
    justify && {
      justifyContent: justify,
    },
    gap && {
      margin: `-${gap}px`,
    },
    horizontalGap && {
      marginLeft: `-${horizontalGap}px`,
      marginRight: `-${horizontalGap}px`,
    },
    verticalGap && {
      marginTop: `-${verticalGap}px`,
      marginBottom: `-${verticalGap}px`,
    },
    flex && { flex },
    padding && { padding },
    margin && { margin },
    minWidth && { minWidth },
    className || {},
  );

const baseCol = {
  flexBasis: '100%',
  maxWidth: '100%',
  flexGrow: 1,
  flexShrink: 0,
  outline: 'none',
};

export const col = baseCol;

export const xsCol = xs => ({
  '@media (max-width: 575px)': {
    flexBasis: `${100 / (NUMBER_OF_COLUMNS / xs)}%`,
    maxWidth: `${100 / (NUMBER_OF_COLUMNS / xs)}%`,
  },
});

export const smCol = sm => ({
  '@media (min-width: 576px)': {
    flexBasis: `${100 / (NUMBER_OF_COLUMNS / sm)}%`,
    maxWidth: `${100 / (NUMBER_OF_COLUMNS / sm)}%`,
  },
});

export const mdCol = md => ({
  '@media (min-width: 768px)': {
    flexBasis: `${100 / (NUMBER_OF_COLUMNS / md)}%`,
    maxWidth: `${100 / (NUMBER_OF_COLUMNS / md)}%`,
  },
});

export const lgCol = lg => ({
  '@media (min-width: 992px)': {
    flexBasis: `${100 / (NUMBER_OF_COLUMNS / lg)}%`,
    maxWidth: `${100 / (NUMBER_OF_COLUMNS / lg)}%`,
  },
});

export const xlCol = xl => ({
  '@media (min-width: 1200px)': {
    flexBasis: `${100 / (NUMBER_OF_COLUMNS / xl)}%`,
    maxWidth: `${100 / (NUMBER_OF_COLUMNS / xl)}%`,
  },
});

export const colComp = ({
  xs,
  sm,
  md,
  lg,
  xl,
  className = {},
  gap,
  verticalGap,
  horizontalGap,
  dFlex,
  align,
  justify,
  direction,
  wrap,
  flow,
  flex,
  basis,
  grow,
  shrink,
  height,
  width,
}) =>
  css(
    baseCol,
    xs && xsCol(xs),
    sm && smCol(sm),
    md && mdCol(md),
    lg && lgCol(lg),
    xl && xlCol(xl),
    (isNumber(gap) || isString(gap)) && {
      padding: gap,
    },
    (isNumber(verticalGap) || isString(verticalGap)) && {
      paddingTop: verticalGap,
      paddingBottom: verticalGap,
    },
    (isNumber(horizontalGap) || isString(horizontalGap)) && {
      paddingLeft: horizontalGap,
      paddingRight: horizontalGap,
    },
    dFlex && {
      display: 'flex',
    },
    align && {
      alignItems: align,
    },
    justify && {
      justifyContent: justify,
    },
    (isNumber(basis) || isString(basis)) && {
      flexBasis: basis,
    },
    (isNumber(grow) || isString(grow)) && {
      flexGrow: grow,
    },
    (isNumber(shrink) || isString(shrink)) && {
      flexShrink: shrink,
    },
    isString(flex) && {
      flex,
    },
    isString(direction) && {
      flexDirection: direction,
    },
    isString(wrap) && {
      flexWrap: wrap,
    },
    isString(flow) && {
      flexFlow: flow,
    },
    height,
    width,
    className,
  );
