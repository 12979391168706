import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { brandGuideLogoContainer, brandGuideLogoImg } from './styles';

export const BrandGuideLogoPage = ({ content }) => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div className={brandGuideLogoContainer(theme)}>
      <div className="brandGuideLogoContainerLeft">
        <h1 className="brandGuideLogoContainerTitle">{getText(content.title)}</h1>
        <h3 className="brandGuideLogoContainerTitle">{getText(content.subTitle1)}</h3>
        <p className="brandGuideLogoContainerText">{getText(content.text1)}</p>
        {content?.list && (
          <ul className="brandGuideLogoContainerList">
            {content?.list?.map(el => (
              <li className="brandGuideLogoContainerText">{getText(el)}</li>
            ))}
          </ul>
        )}
      </div>
      <div className={`${content.page} brandGuideLogoContainerRight`}>
        {!content.textSection?.length ? (
          <img className={brandGuideLogoImg} src={content.imgSrc} alt="logo" />
        ) : (
          content.textSection.map((item, ind) => (
            <div key={ind}>
              <p className="brandGuideLogoContainerText subText">{getText(item.text)}</p>
              <img className={brandGuideLogoImg} src={item.imgSrc} alt="logo" />
            </div>
          ))
        )}
      </div>
    </div>
  );
};
