import React from 'react';
import { useTheme } from 'emotion-theming';
import { useUser } from 'stores';
import { Link } from 'react-router-dom';
import { textLinkStyle } from './styles';

const MerchantOrderIdLink = ({ merchantNickname, orderId, orderIdLinkTo }) => {
  const { currentAccount, isMerchant } = useUser();
  const theme = useTheme();
  const linkTo = orderIdLinkTo || `/merchant-office/orders?searchText=${orderId}`;

  return orderId && isMerchant && merchantNickname === currentAccount?.name ? (
    <Link className={textLinkStyle(theme)} to={linkTo}>
      #{orderId}
    </Link>
  ) : (
    <>{orderId && `#${orderId}`}</>
  );
};

export default MerchantOrderIdLink;
