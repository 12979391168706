/* eslint-disable no-console */
import axios from 'axios';
import { objectToQuery } from 'utils/queryUtils';

const getProperty = (properties, object) => properties.reduce((xs, x) => xs && xs[x], object);

export const getReq = async (url, queryObject = {}, config = {}, keys = []) => {
  try {
    const query = objectToQuery(queryObject);
    const response = await axios.get(url + query, config);
    const data = getProperty(['data'].concat(keys), response);
    return Promise.resolve([data, null, response.status]);
  } catch ({ response: err }) {
    console.log(err);
    return Promise.resolve([null, err, err?.status]);
  }
};

export const postReq = async (url, body, config, keys = []) => {
  try {
    const response = await axios.post(url, body, config);
    const data = getProperty(['data'].concat(keys), response);
    return Promise.resolve([data, null, response.status]);
  } catch ({ response: err }) {
    console.log(err);
    return Promise.resolve([null, err, err?.status]);
  }
};

export const putReq = async (url, body, config, keys = []) => {
  try {
    const response = await axios.put(url, body, config);
    const data = getProperty(['data'].concat(keys), response);
    return Promise.resolve([data, null, response.status]);
  } catch ({ response: err }) {
    console.log(err);
    return Promise.resolve([null, err, err?.status]);
  }
};

export const patchReq = async (url, body, config, keys = []) => {
  try {
    const response = await axios.patch(url, body, config);
    const data = getProperty(['data'].concat(keys), response);
    return Promise.resolve([data, null, response.status]);
  } catch ({ response: err }) {
    console.log(err);
    return Promise.resolve([null, err, err?.status]);
  }
};

export const deleteReq = async (url, body, config, keys = []) => {
  try {
    const response = await axios.delete(url, { data: body, ...config });
    const data = getProperty(['data'].concat(keys), response);
    return Promise.resolve([data, null, response.status]);
  } catch ({ response: err }) {
    console.log(err);
    return Promise.resolve([null, err, err?.status]);
  }
};
