import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const noCartItemsPanelContainer = (isBig, theme) =>
  css({
    [Mobile]: {
      width: '100%',
      left: '0 !important',
      '& .ant-popover-content': {
        width: '100%',
      },
    },
    [Desktop]: {
      minWidth: '15.625rem',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: theme.white,
    fontWeight: 'bold',

    '& .image': {
      flex: 'none',
      marginTop: '0.9375em',
      marginBottom: '0.9375em',
      width: isBig ? '18.75rem' : 'auto',
    },

    '& .title': {
      fontSize: isBig ? '2rem' : '0.875rem',
    },

    '&:last-child': {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 0,
    },
  });

export const loginButton = css({
  marginBottom: '1em',
});
