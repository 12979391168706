import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isMobile } from 'react-device-detect';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { chartTitle, chartContainer, chartLabel } from '../styles';

const AreaChartComponent = ({ data, totalSales }) => {
  const theme = useTheme();
  const { getText } = useTranslations();

  return (
    <div className={chartContainer}>
      <h6 className={chartTitle(theme)}>{getText('totalSales')}</h6>
      <label className={chartLabel(theme)}>{totalSales}</label>
      <ResponsiveContainer width="100%" aspect={isMobile ? 1.0 / 1.0 : 3.0 / 1.0}>
        <AreaChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: -30,
            bottom: 0,
          }}
        >
          <XAxis dataKey="key" tick={{ fontSize: 10 }} axisLine={false} tickLine={false} />
          <YAxis tick={{ fontSize: 8 }} axisLine={false} tickLine={false} />
          <Tooltip />
          <Area dataKey="value" stroke={theme.primary_blue} fill={theme.skyblue_rgba} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

AreaChartComponent.propTypes = {
  data: PropTypes.array,
  totalSales: PropTypes.number,
};

export default AreaChartComponent;
