import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isFunction } from '@veraio/core';
import { CheckButton } from 'components/UIExternal';
import { borderedContainer, marginBottom } from './styles';

const CheckoutDeliveryMethod = ({ children, label, checked, onChange, className }) => {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = val => {
    setIsChecked(val);
    isFunction(onChange) && onChange();
  };

  return (
    <div className={borderedContainer(theme, className)}>
      <CheckButton
        radio
        colorChecked="primary_blue"
        colorUnChecked="skyblue"
        checked={isChecked}
        disabled={isChecked}
        onChange={handleChange}
        className={isChecked ? marginBottom(16) : ''}
      >
        <h5>{label}</h5>
      </CheckButton>
      {!!isChecked && children}
    </div>
  );
};

CheckoutDeliveryMethod.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default CheckoutDeliveryMethod;
