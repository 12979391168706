import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { cryptoSign } from '@oneecosystem/dealshaker-core';
import { useTheme } from 'emotion-theming';
import { isMobile } from 'react-device-detect';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { round } from 'exact-math';
import { chartTitle, pieChartContainer, legendColorBox, legendItem } from '../styles';

const PaymentPieChart = ({ data }) => {
  const theme = useTheme();
  const { getText } = useTranslations();

  const Colors = [theme.red_dark, theme.primary_orange, theme.primary_blue];

  const renderLegend = ({ payload }) => (
    <div>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} className={legendItem}>
          <span>
            <span className={legendColorBox(entry.color)} />
            <label>{entry.payload.key === 'PriceOneCoin' ? getText(cryptoSign()) : getText(entry.payload.key)}</label>
          </span>
          <label> {round(entry?.payload?.value ?? 0, -2)}</label>
        </div>
      ))}
    </div>
  );

  return (
    <div className={pieChartContainer}>
      <h6 className={chartTitle(theme)}>{getText('paymentMethod')}</h6>
      <ResponsiveContainer width="100%" aspect={isMobile ? 1.0 / 1.0 : 1.0 / 1.5}>
        <PieChart>
          <Pie dataKey="value" data={data} innerRadius={50} outerRadius={70} fill={theme.gray500}>
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={Colors[index % Colors.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend verticalAlign="bottom" content={renderLegend} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

PaymentPieChart.propTypes = {
  data: PropTypes.array,
};

export default PaymentPieChart;
