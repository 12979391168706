import { css } from 'emotion';

export const containerStyle = ({ zIndex, fullScreen, fixed }) =>
  css([
    {
      position: fixed ? 'fixed' : 'absolute',
      zIndex: zIndex || 200,
    },
    fullScreen && {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  ]);
