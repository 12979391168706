import React, { useEffect, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { getRegisteredBusinessesCount } from 'services/api/statisticsService';
import useError from 'services/errorHandling/useError';
import { statsPanel, statsPanelMetric, statsPanelLabel, statsPanelValue } from './styles';

const StatisticsPanel = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { setError } = useError();

  const [registeredBusinesses, setRegisteredBusinesses] = useState(0);

  useEffect(() => {
    fetchBusinessesCount();
  }, []);

  const fetchBusinessesCount = async () => {
    const [res, err] = await getRegisteredBusinessesCount();
    err ? setError(err) : setRegisteredBusinesses(res);
  };

  return (
    <div className={statsPanel}>
      <div className={statsPanelMetric}>
        <label className={statsPanelLabel(theme)}>{getText('registeredBusinesses')}</label>
        <label className={statsPanelValue(theme)}>{registeredBusinesses}</label>
      </div>
    </div>
  );
};

export default StatisticsPanel;
