import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const navigationWrapper = theme =>
  css({
    marginTop: '1.5em',
    [Desktop]: {
      borderBottom: `1px solid ${theme.gray500}`,
    },
  });

export const tabsWrapper = theme =>
  css({
    display: 'flex !important',
    alignItems: 'center',
    marginBottom: '1em',
    '& .ant-tabs-nav': {
      marginBottom: '0px !important',
    },
    '& .ant-tabs-ink-bar': {
      background: `${theme.gray100} !important`,
    },
  });

export const businessesSelectorWrapper = css({
  display: 'flex !important',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& .ant-select.ant-select-single': {
    width: '90%',
    [Mobile]: {
      width: '100%',
      marginTop: '0.9375em',
    },
  },
  '& .ant-select-selector': {
    borderRadius: '36px !important',
    width: '100% !important',
  },
});

export const searchWrapper = css({
  display: 'flex !important',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& .ant-select.ant-select-single': {
    width: '90%',
    [Mobile]: {
      width: '100%',
      marginTop: '0.9375em',
      marginBottom: '0.9375em',
    },
  },
  '& input.ant-input': {
    paddingLeft: '0px !important',
  },
});

export const sortWrapper = css({
  display: 'flex !important',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& div:first-child': {
    width: '90%',
    [Mobile]: {
      width: '100%',
    },
  },
  '& .ant-select.ant-select-single': {
    width: '100%',
  },
  '& .ant-select-selector': {
    width: '100% !important',
  },
});

export const availableCreditsBox = theme =>
  css({
    margin: '2.125em 0',
    [Desktop]: {
      marginLeft: '1.5em',
      marginBottom: '4.9375em',
      boxShadow: '0px 3px 4px 0px #00000014',
    },
    [Mobile]: {
      border: `2px solid ${theme.gray500}`,
    },
  });

export const noCreditsBox = css({
  background: '#E6EDF273',
  marginTop: '2.125em',
  [Desktop]: {
    marginLeft: '1.5em',
    marginBottom: '4.9375em',
  },
});

export const promoteTitle = theme =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em',
    '& label': {
      fontWeight: 500,
      color: theme.gray100,
    },
  });

export const creditsLeftWrapper = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& label': {
      fontSize: '2.375rem',
      fontWeight: 500,
    },
    '& p': {
      fontSize: '0.75rem',
      marginBottom: '0.5em',
      color: theme.gray200,
    },
    '& a': {
      marginBottom: '0.75em',
      fontSize: '0.75rem',
      color: theme.blue2,
    },
    '& a:hover': {
      color: theme.blue,
    },
  });

export const promotedDealsWrapper = css({
  background: '#E6EDF273',
  padding: '1em',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& label': {
    fontSize: '0.75rem',
  },
  '& label:not(:first-child)': {
    fontSize: '1rem',
  },
});

export const noVipDealsWrapper = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 3.125em 1.25em 3.125em',
    fontSize: '0.75rem',
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'baseline',
    },
    '& label': {
      marginTop: '0.5em',
    },
    '& a': {
      color: theme.blue2,
      textDecoration: 'underline',
      marginLeft: '1em',
    },
    '& a:hover': {
      color: theme.blue,
      textDecoration: 'underline',
    },
  });

export const noDealsWrapper = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '3.125em',
  marginBottom: '3.125em',
  '& img': {
    width: '11.0625rem',
  },
  [Mobile]: {
    flexDirection: 'column',
  },
});

export const noDealsLabels = theme =>
  css({
    '& label': {
      fontSize: '1.125rem',
      fontWeight: 700,
      color: theme.gray200,
      [Mobile]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    '& p': {
      color: theme.gray200,
      margin: '1em',
      [Mobile]: {
        textAlign: 'center',
      },
    },
    [Desktop]: {
      marginLeft: '1.75em',
    },
    [Mobile]: {
      marginTop: '1.25em',
    },
  });

export const noDealsButtonsWrapper = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.5em 1em',
  justifyContent: 'center',
  '& a': {
    margin: '0',
  },
  '& button': {
    width: '7.5rem',
  },
});

export const dealCardsWrapper = css({
  marginTop: '1em',
  marginBottom: '6.25em',
  [Mobile]: {
    marginBottom: '3.125em',
  },
});

export const thumbnailContainer = css({
  height: '12.5em',
  padding: 0,
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  borderRadius: '4px',
});

export const thumbnail = css({
  width: '100%',
  display: 'block',
});

export const dealDiscountStamp = css({
  top: 0,
  left: 0,
  height: '2.5rem',
  width: '2.5rem',
});

export const imgTag = (theme, isFeatured) =>
  css({
    position: 'absolute',
    background: isFeatured ? theme.primary_orange : theme.violet,
    color: theme.white,
    padding: '0.0625em 0.1875em',
    fontSize: '0.6875em',
    fontWeight: 500,
    letterSpacing: 1,
    textTransform: 'uppercase',
    left: 0,
    bottom: '0.625em',
  });

export const infoWrapper = css({
  display: 'flex',
  justifyContent: 'space-between',
});

export const dealOptionsWrapper = css({
  display: 'flex',
});

export const popoverWrapper = theme =>
  css({
    paddingTop: '0px !important',
    backgroundColor: theme.white,
    borderRadius: 4,
    '& .ant-popover-inner-content': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  });

// REMOVE
export const priceBeforeDiscountLabel = theme =>
  css({
    fontSize: '0.875rem',
    fontWeight: 400,
    color: theme.gray300,
    textDecoration: 'line-through',
    marginRight: '0.25em',
    lineHeight: 1.5,
  });

export const dealTitle = css({
  fontSize: '1.125rem',
  fontWeight: 500,
});

export const labelsWrapper = theme =>
  css({
    fontSize: '0.75rem',
    '& span:first-child': {
      borderRight: `1.5px solid ${theme.gray400}`,
      paddingRight: '0.25em',
    },
    '& span:nth-child(2)': {
      paddingLeft: '0.4375em',
    },
  });

export const dateCouponsWrapper = css({
  fontSize: '0.75rem',
});

export const starRatings = theme =>
  css({
    '&.ant-rate': {
      fontSize: '0.875rem',
      color: theme.gray100,
    },
  });

export const ratingsCountLabel = theme =>
  css({
    marginLeft: '0.75em',
    fontSize: '0.8125rem',
    color: theme.gray200,
    textDecoration: 'underline',
  });

export const sliderWrapper = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    '& a': {
      marginLeft: '0.3125em',
      fontSize: '0.75rem',
      color: theme.blue2,
    },
  });

export const progressBar = theme =>
  css({
    width: '10rem',
    '& .ant-slider-handle': {
      display: 'none',
    },
    '& .ant-slider-track': {
      backgroundColor: `${theme.green2} !important`,
    },
  });

export const messageWrapper = theme =>
  css({
    marginTop: '0.25em',
    marginBottom: '1.5em',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    background: theme.pale_yellow,
    padding: '0.3125em 0.5625em',
    textAlign: 'center',
    '& .icon-Info-2': {
      fontSize: '0.875rem',
      marginRight: '0.4375em',
    },
  });

export const updateOnText = theme =>
  css({
    fontSize: '0.75rem',
    color: theme.gray100,
  });

export const updateMessageBtn = css({
  fontSize: '0.75rem',
  marginLeft: 'auto',
  [Mobile]: {
    flexBasis: '100%',
  },
});

export const commentMessage = css({
  fontSize: '0.8125rem !important',
  fontWeight: 500,
  flexBasis: '100%',
  marginTop: '0.625em',
  marginBottom: 0,
  textAlign: 'left',
});

export const filtersWrapper = css({
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  paddingTop: '1.5em',
  '& > span ': {
    margin: '0 1.2em 1em 0',
    minWidth: '200px',
  },
  '& > div ': {
    margin: '0 1.2em 1em 0',
  },
  '& .ant-select > .ant-select-selector': {
    borderRadius: '36px !important',
    minWidth: '12.5rem !important',

    '& .ant-input-affix-wrapper': {
      width: '25rem !important',
    },
  },
});

export const searchBarStyles = css({
  flex: '0 0 35%',
  minWidth: '14.3rem',
});

export const sortByStyles = css({
  marginBottom: 0,
});
