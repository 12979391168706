import { css } from 'emotion';
import { backgroundColorMap } from 'components/UIExternal/Tag';

export const buttonWrapper = css([
  {
    display: 'flex',
    alignItems: 'stretch',
    position: 'relative',
  },
]);

export const inputField = (backgroundColor, theme) =>
  css({
    border: 'none',
    backgroundColor: backgroundColorMap(theme)[backgroundColor],
    color: theme.white,
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '.5em .75em',
    fontSize: '0.8rem',
    borderRadius: '6px 0 0 6px',
  });

export const arrowButton = (backgroundColor, disabled, theme) =>
  css([
    {
      backgroundColor: backgroundColorMap(theme)[backgroundColor],
      border: 'none',
      cursor: disabled ? 'not-allowed' : 'pointer',
      color: theme.white,
      marginLeft: '.1em',
      padding: '.25em .4em',
      borderRadius: '0 6px 6px 0',
      '&:not([disabled]):hover .tooltip': {
        display: 'block',
      },
    },
    disabled && {
      '& *': { pointerEvents: 'none' },
    },
  ]);

export const arrowButtonNextPopover = theme =>
  css({
    color: theme.white,
    position: 'absolute',
    display: 'none',
    background: theme.blue2,
    textAlign: 'center',
    padding: '.6em 1.25em .6em 1.25em',
    borderRadius: 10,
    bottom: 'calc(100% + 11px)',
    right: 0,
    fontSize: '0.8rem',
    '&:after': {
      position: 'absolute',
      bottom: '-.6em',
      right: '0.25em',
      content: '""',
      display: 'inline-block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '10px 10px 0 10px',
      borderColor: `${theme.blue2} transparent transparent transparent`,
    },
  });

export const optionList = theme =>
  css({
    position: 'absolute',
    top: '2.75rem',
    right: 0,
    zIndex: 999,
    background: theme.white,
    boxShadow: '0 0 3px 3px rgba(0,0,0,0.1)',
    width: '10rem',
  });

export const optionItem = theme =>
  css({
    color: theme.gray100,
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    padding: '.6em 0.75em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    bottom: '50px',
    '&:hover': {
      background: theme.light_blue_hover,
    },
  });

export const optionItemValue = (option, disabled, theme) =>
  css([
    {
      color: theme.gray100,
      cursor: disabled ? 'not-allowed' : 'pointer',
      fontWeight: 400,
      fontSize: '1.1rem',
      '&:before': {
        display: 'inline-block',
        content: '""',
        width: '1rem',
        height: '1rem',
        backgroundColor: backgroundColorMap(theme)[option.color],
        marginRight: '.5em',
        borderRadius: 4,
      },
      '&.disabled': {
        pointerEvents: 'none',
        cursor: 'not-allowed',
        textDecoration: 'line-through',
      },
    },
  ]);
