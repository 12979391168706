import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Form, Popover, Input } from 'antd';
import { BaseCheckbox } from 'components/ui/Checkbox';
import { InputsWithTranslations, DealCategories } from 'components/shared';
import { businessStatus } from 'enums/business';
import { getAllBusinessesInfo } from 'services/api/businessesService';
import useError from 'services/errorHandling/useError';
import { createDeal, updateDealStepData } from 'services/api/dealsService';
import FormActions from '../FormActions/FormActions';
import { popoverContainer } from '../../../../../components/popups/InformationalPopover/styles';
import {
  checkboxesWrapper,
  tagListStyles,
  tagStyles,
  tagsHintText,
  labelAndInput,
  registeredBusinessStyle,
} from './styles';
import { Dropdown } from '../../../../../components/UIExternal';

const StepOne = ({ stepOneData, currentStep, dealId, setManualStep }) => {
  const [form] = Form.useForm();
  const theme = useTheme();
  const history = useHistory();
  const { setError } = useError();
  const { getText, getDynamicTranslation } = useTranslations();
  const [businesses, setBusinesses] = useState([]);
  const tagsInputRef = useRef();
  const translationsSectionRef = useRef();

  const registeredBusinessesOptions = businesses.map(business => ({
    label: getDynamicTranslation(business.name)?.name,
    value: business?.id,
  }));

  const mandatoryRule = {
    required: true,
    message: getText('fieldIsRequired'),
  };

  useEffect(() => {
    fetchAllBusinessesInfo();
  }, []);

  const fetchAllBusinessesInfo = async () => {
    const [res, err] = await getAllBusinessesInfo({
      statusIds: [businessStatus.approved, businessStatus.edited, businessStatus.pending],
    });
    err ? setError(err) : setBusinesses(res);
  };

  const onFormFinish = async data => {
    const formData = { ...stepOneData, ...data };

    // Wait for validation of all translation tabs and see if there is error or not
    // If there is error quit from this method
    if (translationsSectionRef.current?.validate()) return;

    if (
      formData.dealDetails[0]?.title?.length &&
      formData.dealDetails[0]?.description?.length &&
      formData.dealDetails[0]?.termsAndConditions?.length
    ) {
      dealId ? await handleUpdateDeal(formData) : await handleCreateDeal(formData);
      setManualStep(2);
    }
  };

  const handleUpdateDeal = async data => {
    const [, err] = await updateDealStepData(data, 1, dealId);
    err && setError(err);
  };

  const handleCreateDeal = async data => {
    const [res, err] = await createDeal(data);
    err && setError(err);
    return history.push(`/edit-deal/${res}`);
  };

  const onFinishFailed = errorInfo => {
    errorInfo && isNil(errorInfo?.values.categoryId);
  };

  const handleTagChange = async e => {
    const {
      target: { value },
    } = e;
    const trimmedTag = value?.trim();
    if (trimmedTag.length < 2) return;
    const prevTags = form.getFieldValue('tags') ?? [];
    form.setFieldsValue({ tags: prevTags.concat(trimmedTag) });
    form.resetFields(['tagsInput']);
  };

  const handleRemoveTag = idx => {
    const prevTags = [...form.getFieldValue('tags')];
    prevTags.splice(idx, 1);
    form.setFieldsValue({ tags: prevTags });
  };

  return (
    <Form
      className="block-form-item"
      form={form}
      initialValues={stepOneData}
      onFinish={onFormFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError
    >
      <Form.Item
        name="businessId"
        labelAlign="left"
        valuePropName="defaultValue"
        label={getText('registeredBusinesses')}
        rules={[{ required: true, message: getText('pleaseSelectBusiness') }]}
        className={labelAndInput}
      >
        <Dropdown
          placeholder={getText('selectHere')}
          options={registeredBusinessesOptions}
          value={registeredBusinessesOptions.find(el => el?.value === stepOneData?.businessId)}
          className={registeredBusinessStyle}
          mapValue={data => data?.value}
        />
      </Form.Item>
      <Form.Item
        label={getText('category')}
        labelAlign="left"
        name="categoryId"
        className={labelAndInput}
        rules={[mandatoryRule]}
      >
        <DealCategories />
      </Form.Item>
      <Form.Item name="dealDetails" data-id="dealDetails" className={labelAndInput}>
        <InputsWithTranslations
          ref={translationsSectionRef}
          formInstance={form}
          inputs={[
            {
              inputComponent: Input,
              inputProps: { placeholder: getText('pleaseEnterDealTitle') },
              formItemProps: { name: 'title', label: getText('dealTitle') },
            },
            {
              inputComponent: Input.TextArea,
              inputProps: { placeholder: getText('pleaseDescribeYourDeal'), autoSize: { minRows: 7 } },
              formItemProps: { name: 'description', label: getText('dealDescription') },
            },
            {
              inputComponent: Input.TextArea,
              inputProps: { placeholder: getText('termsAndConditions'), autoSize: { minRows: 7 } },
              formItemProps: { name: 'termsAndConditions', label: getText('termsAndConditions') },
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="tagsInput"
        labelAlign="left"
        labelCol={{ lg: 24 }}
        data-id="tagsInput"
        label={
          <Popover
            content={getText('tagHelpText')}
            overlayClassName={popoverContainer(theme)}
            zIndex={900}
            placement="bottomRight"
            trigger="click"
          >
            <div>
              <span>{getText('tags')}</span>
              <FeatherIcon icon="help-circle" size={12} />
            </div>
          </Popover>
        }
      >
        <Input
          ref={tagsInputRef}
          onPressEnter={handleTagChange}
          theme={theme}
          placeholder={getText('addTag')}
          maxLength={30}
        />
        <span className={tagsHintText(theme)}>{getText('tagsHintText')}</span>
      </Form.Item>
      <Form.Item name="tags" noStyle />
      <Form.Item shouldUpdate>
        {() => (
          <div className={tagListStyles}>
            {form.getFieldValue('tags')?.map((tag, idx) => (
              <div key={idx} className={tagStyles(theme)}>
                {tag}
                <FeatherIcon icon="x" size={12} onClick={() => handleRemoveTag(idx)} />
              </div>
            ))}
          </div>
        )}
      </Form.Item>
      <div className={checkboxesWrapper} data-id="buyOneDealCheckbox">
        <Form.Item valuePropName="checked" initialValue={false} name="singlePurchasePerUser">
          <BaseCheckbox theme={theme}>{getText('oneCustomerCanBuyOneDeal')}</BaseCheckbox>
        </Form.Item>
      </div>
      <div className={checkboxesWrapper} data-id="listedDealCheckbox">
        <Form.Item valuePropName="checked" initialValue={stepOneData?.listed ?? true} name="listed">
          <BaseCheckbox theme={theme}>{getText('allowDealOnLists')}</BaseCheckbox>
        </Form.Item>
      </div>
      <Form.Item>
        <FormActions setManualStep={setManualStep} currentStep={currentStep} onNextStepHandler={() => form.submit()} />
      </Form.Item>
    </Form>
  );
};

StepOne.propTypes = {
  stepOneData: PropTypes.object,
  setManualStep: PropTypes.func,
  dealId: PropTypes.number,
  currentStep: PropTypes.number,
};
export default StepOne;
