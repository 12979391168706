import { css } from 'emotion';

export const businessCartStyles = theme =>
  css({
    backgroundColor: 'transparent',
    border: `1px solid ${theme.gray500}`,
  });

export const actionButtons = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: '2em',
});
