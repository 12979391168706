import React from 'react';
import { Page, Document, View, Svg, Path, G } from '@react-pdf/renderer';

const CapTemplate = () => {
  const SVGComponent = () => (
    <Svg width="141.73pt" height="141.73pt" viewBox="0 0 141.73 141.73">
      <G id="surface1">
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(40.196228%,64.517212%,87.020874%)',
            fillOpacity: 1,
          }}
          d="M 56.710938 24.984375 C 56.710938 34.886719 48.6875 42.910156 38.785156 42.910156 C 28.886719 42.910156 20.859375 34.886719 20.859375 24.984375 C 20.859375 15.085938 28.886719 7.058594 38.785156 7.058594 C 48.6875 7.058594 56.710938 15.085938 56.710938 24.984375 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(40.196228%,64.517212%,87.020874%)',
            fillOpacity: 1,
          }}
          d="M 120.871094 24.984375 C 120.871094 34.886719 112.84375 42.910156 102.945312 42.910156 C 93.042969 42.910156 85.019531 34.886719 85.019531 24.984375 C 85.019531 15.085938 93.042969 7.058594 102.945312 7.058594 C 112.84375 7.058594 120.871094 15.085938 120.871094 24.984375 "
        />
        <Path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fill: 'rgb(98.023987%,80.300903%,3.364563%)',
            fillOpacity: 1,
          }}
          d="M 110.34375 62.023438 L 110.34375 70.957031 C 110.34375 81.421875 106.183594 91.460938 98.785156 98.863281 C 91.382812 106.261719 81.34375 110.421875 70.878906 110.421875 C 60.410156 110.421875 50.371094 106.261719 42.972656 98.863281 C 35.570312 91.460938 31.414062 81.421875 31.414062 70.957031 L 31.414062 62.023438 L 7.15625 62.023438 L 7.152344 70.957031 C 7.152344 87.855469 13.863281 104.058594 25.8125 116.007812 C 37.761719 127.957031 53.96875 134.671875 70.867188 134.671875 C 87.761719 134.671875 103.96875 127.957031 115.917969 116.007812 C 127.867188 104.058594 134.578125 87.855469 134.578125 70.957031 L 134.578125 62.023438 Z M 110.34375 62.023438 "
        />
      </G>
    </Svg>
  );

  return (
    <Document>
      <Page size={[141.73, 141.73]}>
        <View>
          <SVGComponent />
        </View>
      </Page>
    </Document>
  );
};

export default CapTemplate;
