import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { getText } from '@veraio/strank';
import { Pagination } from 'components/UIExternal';
import { useOrders, fetchOrders } from 'stores';
import emptyBoxImage from 'assets/images/noNotifications.svg';
import { OrderCard } from './OrderCard';
import { LoadingOrderCard } from './OrderCard/LoadingOrderCard';
import { ordersListContainer, orderNoOrdersFound } from './styles';

const NoOrdersFound = () => (
  <div className={orderNoOrdersFound}>
    <img src={emptyBoxImage} alt="Empty box" />
    <h2>{getText('noOrdersFound')}</h2>
  </div>
);

export const OrdersList = ({ paginationRef, filterKeys }) => {
  const theme = useTheme();
  const orderList = useOrders(ordersState => ordersState?.orderList);

  const listItems = () => {
    if (isNil(orderList)) return <LoadingOrderCard />;
    if (!isNil(orderList) && orderList?.length < 1) return <NoOrdersFound />;

    return orderList.map(el => <OrderCard order={el} key={el.id} />);
  };

  return (
    <aside className={ordersListContainer(theme)}>
      <section className="list">{listItems()}</section>
      <Pagination ref={paginationRef} onChange={fetchOrders} pageSize={10} filterKeys={filterKeys} justify="center" />
    </aside>
  );
};

OrdersList.propTypes = {
  paginationRef: PropTypes.object,
  filterKeys: PropTypes.object,
};
