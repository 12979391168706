import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { brandGuideMaterialPageContainer, brandGuideLineExamplePageTextContainer } from './styles';

export const BrandGuideLineExamplePage = ({ content }) => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div className={brandGuideMaterialPageContainer}>
      <div className={brandGuideLineExamplePageTextContainer(theme)}>
        <p className="blueText">{getText(content?.text1)}</p>
        <p className="yellowText">{getText(content?.text2)}</p>
        <p className="purpleText">{getText(content?.text3)}</p>
        <p className="blackText">{getText(content?.text4)}</p>
      </div>
      <img className="brandGuideExamplePageImg" src={content?.img} alt="brand material" />
    </div>
  );
};
