import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const availableCreditsBox = theme =>
  css({
    [Desktop]: {
      boxShadow: '0px 3px 4px 0px #00000014',
    },
    [Mobile]: {
      border: `2px solid ${theme.gray500}`,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginRight: '1em',
      marginBottom: '1em',
    },
  });

export const noCreditsBox = theme =>
  css({
    background: theme.gray900,
  });

export const promoteTitle = theme =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em',
    fontSize: '1.5rem',
    '& span': {
      fontWeight: 500,
      color: theme.gray100,
    },
  });

export const creditsLeftWrapper = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& span': {
      fontSize: '2.375rem',
      fontWeight: 500,
    },
    '& p': {
      fontSize: '0.75rem',
      marginBottom: '0.5em',
      color: theme.gray200,
    },
    '& a': {
      marginBottom: '0.75em',
      fontSize: '0.75rem',
    },
  });

export const promotedDealsWrapper = theme =>
  css({
    background: theme.gray900,
    padding: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
      fontSize: '0.75rem',
    },
  });

export const noVipDealsWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0px 3.125em 1.25em 3.125em',
  fontSize: '0.75rem',
  textAlign: 'center',
  '& img': {
    width: '11.5rem',
  },
});

export const buyMore = css({
  padding: 0,
});
