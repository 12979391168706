import styled from '@emotion/styled';
import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const HeadingPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const title = css({
  fontSize: '1.4375rem',
  [Mobile]: { fontSize: '1.1875rem' },
  fontWeight: 700,
});

export const wrapper = css({
  marginTop: '1.25em',
  '& .slick-slide': {
    maxWidth: '16.5rem',
    maxHeight: '10.25rem',
    overflow: 'hidden',
    padding: '0 0.3125em',
  },
});

export const arrowStyles = (theme, direction) =>
  css({
    position: 'absolute',
    top: '40%',
    display: 'block',
    zIndex: 1,
    right: direction === 'right' ? '-1.25em' : null,
    left: direction === 'left' ? '-1.25em' : null,
    lineHeight: 0,
    borderRadius: '22px',
    background: theme.white,
    boxShadow: '-1px 0px 6px rgba(0, 0, 0, 0.16)',
    padding: 1,
    cursor: 'pointer',
    color: theme.gray300,
    '& .feather': {
      zIndex: 1,
    },
    '& .right': {
      right: '-1.25em',
    },
    '& .left': {
      left: '-1.25em',
    },
  });

export const imgButton = css({
  border: 'none',
  '&:hover': {
    background: 'transperant',
  },
});
