import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  contentBox: {
    position: 'absolute',
    bottom: 80,
    width: '80%',
    display: 'flex',
    alignSelf: 'center',
  },
  textBox: {
    country: {
      fontSize: 26,
      fontWeight: 600,
      marginBottom: 10,
      color: '#FECF09',
    },
    businessName: {
      fontSize: 60,
      fontWeight: 800,
      marginBottom: 10,
      color: '#3A6DB5',
    },
    location: {
      fontWeight: 600,
      fontSize: 26,
      marginBottom: 10,
      color: '#3A6DB5',
    },
  },
  qrCodeStyles: {
    width: 150,
    marginRight: 0,
    marginLeft: 'auto',
  },
});
