import { Mobile } from 'assets/css/breakpoints';
import { css } from 'emotion';

export const mainContainer = theme =>
  css({
    boxSizing: 'border-box',
    border: `1px solid ${theme.gray500}`,
    borderRadius: 3,
    padding: '0.9375em 1.25em',
    height: '100%',
    marginRight: '1.625em',
    '&:last-of-type': {
      marginRight: 0,
    },
    color: theme.gray100,
    [Mobile]: {
      width: '12.75rem',
      minWidth: '12.75rem',
    },
  });
export const title = theme =>
  css({
    color: theme.gray100,
    fontSize: '0.875rem',
    lineHeight: '1.75rem',
    fontWeight: 500,
    borderBottom: `1px solid ${theme.gray500}`,
    marginBottom: '1.25em',
  });
export const workingHoursLabel = theme =>
  css({
    color: `${theme.gray200} !important`,
    fontSize: '0.6875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    margin: '0.625em 0 0.125em 0',
  });
