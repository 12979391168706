const DealStatus = {
  Pending: {
    id: 1,
    value: 'Pending',
  },
  Approved: {
    id: 2,
    value: 'Approved',
  },
  Declined: {
    id: 3,
    value: 'Declined',
  },
  Expired: {
    id: 4,
    value: 'Expired',
  },
  Closed: {
    id: 5,
    value: 'Closed',
  },
  SoldOut: {
    id: 6,
    value: 'SoldOut',
  },
  Draft: {
    id: 7,
    value: 'Draft',
  },
  Suspended: {
    id: 8,
    value: 'Suspended',
  },

  getById: id => {
    const key = Object.keys(DealStatus).find(key => DealStatus[key].id === id);
    return key && DealStatus[key];
  },
};

export default DealStatus;
