import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const sectionHeaderContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '1.25em',
});

export const commentsContainer = css({
  paddingRight: '1.75em',
  paddingTop: '1.5em',
  paddingBottom: '1.625em',
  borderLeft: 'none',
  '& .ant-btn': {
    padding: '0 2em',
    [Mobile]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '0.8125rem',
    },
  },
});

export const borderContainer = theme =>
  css({
    border: `1px solid ${theme.gray500}`,
    borderRadius: 3,
    [Mobile]: {
      border: 'none',
    },
    '& .reviews-summary': {
      border: 'none',
      borderRight: `1px solid ${theme.gray500}`,
      borderRadius: 0,
      [Mobile]: {
        border: `1px solid ${theme.gray500}`,
        borderRadius: 3,
      },
    },
  });

export const noReviews = theme =>
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    color: theme.gray200,
    '& p': {
      marginBottom: 0,
    },
    '& a': {
      textWrap: 'wrap',
      [Mobile]: {
        width: '100%',
      },
    },
  });
