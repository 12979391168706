import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { isFunction } from '@veraio/core';
import { checkButtonContainer, checkboxContainer } from './styles';

export const CheckButton = props => {
  const { checked, onChange, radio, children, className, disabled } = props;
  const [check, setCheck] = useState(checked);
  const theme = useTheme();

  useEffect(() => {
    setCheck(checked);
  }, [checked]);

  const handleChange = () =>
    !disabled &&
    setCheck(prev => {
      isFunction(onChange) && onChange(!prev);
      return !prev;
    });

  return (
    <div role="button" tabIndex={0} className={checkButtonContainer(disabled, className)} onClick={handleChange}>
      <span className={checkboxContainer(disabled, className, check, radio, theme)} />
      {children}
    </div>
  );
};

CheckButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  radio: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  colorChecked: PropTypes.string,
  colorUnChecked: PropTypes.string,
};
