import React from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { useUrlParams, Dropdown } from 'components/UIExternal';
import { SearchInput } from 'components/ui';
import { useOrders } from 'stores';
import { orderStatus, orderStatusSelect } from 'enums';
import { filterBar, orderFilter } from './styles';

export const orderDateOptions = () => [
  { label: getText('lastThreeDays'), value: 3 },
  { label: getText('lastWeek'), value: 7 },
  { label: getText('lastTwoWeeks'), value: 14 },
  { label: getText('lastMonth'), value: 30 },
  { label: getText('lastThreeMonths'), value: 90 },
  { label: getText('lastSixMonths'), value: 180 },
  { label: getText('lastYear'), value: 356 },
];

export const OrderFilters = ({ onChange, filterKeys }) => {
  const { queryParams } = useUrlParams();
  const editable = useOrders(ordersState => ordersState.editable);

  return (
    <div className={filterBar}>
      <SearchInput
        allowClear
        inputClass={`${orderFilter} search`}
        placeholder={getText(editable ? 'orderMerchantSearch' : 'orderBuyerSearch')}
        defaultValue={queryParams[filterKeys.search]}
        onPressEnter={val => onChange({ [filterKeys.search]: val })}
      />
      <Dropdown
        allowClear
        className={orderFilter}
        options={orderStatusSelect(getText).filter(
          el => el.value !== orderStatus.declined && el.value !== orderStatus.canceled,
        )}
        value={queryParams[filterKeys.status]}
        placeholder={getText('status')}
        onChange={val => onChange({ [filterKeys.status]: val?.value })}
      />
      <Dropdown
        allowClear
        className={orderFilter}
        options={orderDateOptions(getText)}
        value={queryParams[filterKeys.dateFrom]}
        placeholder={getText('mostRecent')}
        onChange={val => onChange({ [filterKeys.dateFrom]: val?.value })}
      />
    </div>
  );
};

OrderFilters.propTypes = {
  onChange: PropTypes.func,
  filterKeys: PropTypes.object,
};
