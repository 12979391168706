import { StyleSheet } from '@react-pdf/renderer';

export const s = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    color: '#fff',
    fontSize: 28,
    fontWeight: 600,
    width: '70%',
    marginTop: 35,
    marginLeft: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    subheader: {
      fontWeight: 600,
      fontSize: 18,
      marginTop: 5,
    },
  },
  contentBox: {
    position: 'absolute',
    top: '21%',
    display: 'flex',
    flexDirection: 'row',
    margin: '0 auto',
    width: '100%',
    side: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '50%',
      padding: 15,
      rightSide: {
        marginLeft: 10,
      },
    },
    dealContainer: {
      padding: 3,
      marginBottom: 20,
      marginTop: 20,
      width: 130,
    },
    dealImgContainer: {
      width: 120,
      height: 60,
      objectFit: 'cover',
      border: '1px solid #e1e1e1',
      overflow: 'hidden',
      position: 'relative',
      textAlign: 'center',
      discountContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 15,
        discountValue: {
          position: 'absolute',
          top: 5,
          left: 0,
          right: 0,
          fontSize: 5,
          color: '#fff',
        },
      },
    },
    dealImg: {
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    noDealImg: {
      display: 'block',
      width: '100%',
    },
    dealName: {
      fontSize: 10,
      fontWeight: 600,
      color: '#000',
      width: 100,
      height: 30,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginTop: 5,
    },
    dealPrices: {
      fontSize: 10,
      color: 'blue',
      padding: 5,
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      hasDiscount: {
        color: 'gray',
        textDecoration: 'line-through',
      },
      noDiscount: {
        marginTop: 12,
      },
    },
    discountedPrices: {
      color: 'blue',
      fontSize: 12,
    },
  },
  textBox: {
    country: {
      fontSize: 50,
      fontWeight: 600,
      color: '#FECF09',
    },
    businessName: {
      fontSize: 60,
      fontWeight: 800,
      color: '#fff',
    },
    location: {
      fontSize: 50,
      color: '#fff',
    },
  },
  back: {
    wrapper: {
      height: '100%',
    },
    contentBox: {
      position: 'absolute',
      width: '40%',
      bottom: '20%',
      right: '5%',
    },
    textBox: {
      country: {
        fontSize: 14,
        fontWeight: 600,
        color: '#FECF09',
        marginBottom: 20,
      },
      businessName: {
        fontSize: 20,
        fontWeight: 800,
        color: '#fff',
        marginBottom: 20,
      },
      location: {
        fontSize: 14,
        color: '#fff',
      },
    },
    qrCodeStyles: {
      width: 80,
      position: 'absolute',
      bottom: -100,
      right: 0,
    },
  },
});
