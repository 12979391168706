import { css } from 'emotion';

export const titleWrapper = theme =>
  css({
    marginBottom: '3.125em',
    '& h2': {
      fontSize: '2.375em',
      fontWeight: 500,
      color: theme.background100,
      marginBottom: '0.3125em',
    },
    '& p': {
      fontSize: '1rem',
      color: theme.gray100,
    },
  });

export const creditPackBox = theme =>
  css({
    border: `2px solid ${theme.gray500}`,
  });

export const selectedCreditPackBox = theme =>
  css({
    border: `2px solid ${theme.primary_blue}`,
    background: '#E6EDF273',
  });

export const creditCard = theme =>
  css({
    textAlign: 'center',
    '& img': {
      margin: '0 auto',
      marginTop: '0.625em',
      marginBottom: '0.875em',
    },
    '& h6': {
      fontSize: '1.1875rem',
      fontWeight: 700,
      color: theme.gray100,
      marginBottom: 0,
    },
    '& p': {
      marginTop: '0.25em',
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray200,
    },
  });

export const creditTitleWrapper = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const bestDealLabel = (theme, isBestDeal) =>
  css({
    display: isBestDeal ? 'inline-block' : 'none',
    background: theme.green3,
    color: theme.white,
    fontSize: '0.6875rem',
    fontWeight: 500,
    padding: '0.0625em 0.1875em',
    marginLeft: '0.375em',
  });

export const selectBtn = css({
  width: '60%',
  marginTop: '1.4375em',
  marginBottom: '1.75em',
});

export const availableCreditsBox = theme =>
  css({
    border: `1px solid ${theme.gray500}`,
  });

export const creditsTitle = theme =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em',
    '& span': {
      fontSize: '1rem',
    },
    '& label': {
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray100,
    },
    '& p': {
      fontSize: '0.6875rem',
      color: theme.gray200,
      marginBottom: 0,
    },
  });

export const creditsLeftWrapper = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '0.625em',
    '& label': {
      fontSize: '2.375rem',
      fontWeight: 500,
    },
    '& p': {
      fontSize: '0.75rem',
      marginBottom: '0.5em',
      color: theme.gray200,
    },
  });

export const promotedDealsWrapper = () =>
  css({
    background: '#E6EDF273',
    padding: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& label': {
      fontSize: '0.75rem',
    },
    '& label:not(:first-child)': {
      fontSize: '1rem',
    },
  });

export const noCreditsBox = css({
  background: '#E6EDF273',
});

export const noVipDealsWrapper = () =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 3.125em 1.25em 3.125em',
    fontSize: '0.75rem',
    '& img': {
      maxWidth: '6.25rem',
    },
    '& label': {
      marginTop: '0.9375em',
    },
  });

export const cashWalletWrapper = theme =>
  css({
    paddingTop: '0.9375em',
    marginBottom: '0.875em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.gray100,
    '& p': {
      marginBottom: 0,
      fontSize: '0.8125rem',
      fontWeight: 500,
    },
    '& label': {
      fontSize: '1rem',
    },
  });

export const amountWrapper = theme =>
  css({
    paddingBottom: '0.9375em',
    borderBottom: `1px solid ${theme.gray500}`,
  });

export const amountDetails = theme =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h5': {
      fontSize: '1.125rem',
      fontWeight: 700,
      marginBottom: 0,
      color: theme.gray100,
    },
    '& .amount': {
      fontSize: '1.5rem',
      color: theme.gray100,
    },
    '& .insufficient-balance': {
      fontSize: '0.75rem',
      color: theme.red,
    },
  });

export const modalSummary = theme =>
  css({
    textAlign: 'center',
    '& img': {
      width: '6.25rem',
    },
    '& h6': {
      fontSize: '1.125rem',
      fontWeight: 700,
      marginBottom: 0,
    },
    '& label': {
      fontSize: '1rem',
      color: theme.gray200,
    },
    '& p': {
      marginTop: '0.5em',
      color: theme.gray100,
    },
    '& button': {
      margin: 'auto',
    },
  });
