import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { getText } from '@veraio/strank';
import { useDrag, useDrop } from 'react-dnd';
import { Tooltip } from 'antd';
import { CheckButton, Icon } from 'components';
import { checkButtonContainer, draggableItem } from './styles';

const type = 'DraggableUploadList';

const DraggableUploadList = ({ originNode, moveRow, file, fileList, onChange }) => {
  const ref = useRef();
  const theme = useTheme();
  const index = fileList.indexOf(file);

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) return {};
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    item: { type },
    begin: () => ({ index }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;

  const handleOnChange = isHidden => {
    const index = fileList.findIndex(el => el.id === file.id);
    fileList.splice(index, 1, { ...file, isHidden: !isHidden });
    onChange(fileList);
  };

  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''} ${draggableItem(
        file?.isHidden,
        theme,
      )}`}
    >
      {file.status === 'error' ? errorNode : originNode}
      <CheckButton checked={!file.isHidden} className={checkButtonContainer} onChange={handleOnChange}>
        <span className="text-label">{getText('visibleOnPublicPage')}</span>
        <Tooltip title={getText('hiddenImageHelp')} placement="right" color={theme.blue}>
          <Icon iconName="las la-lg la-info-circle" />
          {/* in order to trigger the tooltip, the icon should be followed by a space character  */}
          &nbsp;
        </Tooltip>
      </CheckButton>
    </div>
  );
};

DraggableUploadList.propTypes = {
  fileList: PropTypes.any,
  file: PropTypes.any,
  originNode: PropTypes.any,
  moveRow: PropTypes.any,
  onChange: PropTypes.func,
};

export default DraggableUploadList;
