import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { getOrdersDetails, setError } from 'services';
import QRCode from 'qrcode';
import { CheckoutBusiness, ShoppingCartItem } from 'components/screens';
import { Row, Col, CountdownTimer, CopyText } from 'components/UIExternal';
import { BorderlessShadowlessGrayBox, StatusProgressIcon } from 'components/ui';
import { getOrderExternalPayment } from 'services/api/orders';
import { businessCartStyles, confirmContentContainer, submittedOrderWrapper } from './styles';
import { mapOrderBusinessToCheckoutBusiness, mapOrderItemToCheckoutItem } from './utils';

const GuestOrderDetails = () => {
  const location = useLocation();
  const [ordersWithExternalPaymentData, setOrdersWithExternalPaymentData] = useState([]);

  const { getText } = useTranslations();
  const theme = useTheme();

  useEffect(() => {
    const ordersIds = location.state?.ordersIds;
    fetchOrdersWithExternalPaymentData(ordersIds);
  }, []);

  const fetchOrdersWithExternalPaymentData = async ordersIds => {
    if (!ordersIds || !Array.isArray(ordersIds) || ordersIds.length < 1) return null;

    const orders = await fetchOrdersDetails(ordersIds);
    // TODO: Check if order already has external transactionId, e.g. if page is reloaded.

    const [externalPaymentData, errors] = await fetchExternalPaymentData(orders);
    if (errors.length > 0) setError(errors.join('; '));

    // TODO: retry mechanism

    const ordersWithExternalPaymentData = orders.map(order => ({
      ...order,
      externalPayment: externalPaymentData.find(item => item.orderId === order.id)?.externalPaymentData,
    }));

    setOrdersWithExternalPaymentData(ordersWithExternalPaymentData);
  };

  const fetchOrdersDetails = async orderIds => {
    const [res, err] = await getOrdersDetails(orderIds);
    if (err) return setError(err);

    return res;
  };

  const fetchExternalPaymentData = async ordersData => {
    const promises = [];
    ordersData.forEach(order => {
      const requestPromise = getOrderExternalPayment(order.id);
      promises.push(requestPromise);
    });

    const externalPaymentResultData = [];
    const faultResults = [];
    const results = await Promise.allSettled(promises);
    results.forEach(async (result, i) => {
      if (result.status === 'fulfilled' && !result.value[1]) {
        const qrCode = await QRCode.toDataURL(result.value[0].paymentAddress);
        const externalPaymentData = {
          qrCode,
          ...result.value[0],
        };

        externalPaymentResultData.push({
          orderId: ordersData[i].id,
          externalPaymentData,
        });
      } else {
        faultResults.push({
          orderId: ordersData[i].id,
          reason: result.reason,
        });
      }
    });

    return [externalPaymentResultData, faultResults];
  };

  return (
    <section className={`content-container ${confirmContentContainer}`}>
      <BorderlessShadowlessGrayBox theme={theme} className={submittedOrderWrapper(theme)}>
        <StatusProgressIcon customClassName="success-img" status="success" />
        <h2>{getText('yourOrderWasSubmitted')}</h2>
        <p>Your USDT payment has started. Send the indicated amount/s to the USDT wallet addresses below once.</p>
        <p>
          This is a one-time USDT address with limited time-period validation. Send only USDT to this address of the
          merchant to finalize your order before the limited time ends.
        </p>
      </BorderlessShadowlessGrayBox>

      <h3>{getText('products')}</h3>

      {ordersWithExternalPaymentData?.map(order => {
        const orderBusiness = mapOrderBusinessToCheckoutBusiness(order.business);

        return (
          <Row gap={8} className={businessCartStyles(theme)}>
            <Col lg={6} md={12}>
              <CheckoutBusiness business={orderBusiness} key={orderBusiness.id}>
                {order.orderItems?.map(item => {
                  const orderItem = mapOrderItemToCheckoutItem(item);
                  return <ShoppingCartItem item={orderItem} key={orderItem.id} />;
                })}
              </CheckoutBusiness>
            </Col>
            <Col lg={6} md={12}>
              {order.externalPayment && (
                <div>
                  <div className="guest-order-external-payment-price">
                    {/* <label className="fullWidth">{price}</label> */}
                    <div className="timer">
                      <CountdownTimer endDateTime={order?.externalPayment?.timeExpires} />
                    </div>
                  </div>
                  <div className="guest-order-external-payment-qr-container">
                    <p>
                      {getText('scanQrOrCopyPaymentAddress')}
                      <CopyText successMessage={getText('paymentAddressCopiedToClipboard')}>
                        {order?.externalPayment?.paymentAddress}
                      </CopyText>
                    </p>
                    {/* <img src={qrCode} width={150} alt="" /> */}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        );
      })}
    </section>
  );
};

export default GuestOrderDetails;
