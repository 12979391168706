import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const addressesStyles = css({
  display: 'flex',
  flexWrap: 'wrap',
});

export const addressCart = (theme, active) =>
  css({
    color: theme.gray200,
    fontSize: '0.75rem',
    lineHeight: 1.5,
    fontWeight: 400,
    border: `1px solid ${active ? theme.primary_blue_hover : theme.gray500}`,
    backgroundColor: active ? theme.skyblue : 'transparent',
    borderRadius: 8,
    padding: '0.5em',
    margin: '0 0.75em 0.75em 0',
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: '5.9375rem',
    alignItems: 'flex-start',
    flexDirection: 'column',
    whiteSpace: 'normal',
    '@media (max-width:600px)': {
      width: '100%',
    },
    '& h3': {
      fontSize: '0.8125rem',
      marginBottom: '0.25em',
      [Mobile]: {
        fontSize: '0.75rem',
      },
    },
    '&:hover': {
      cursor: 'pointer',
      border: `1px solid ${active ? 'none' : theme.gray500}`,
      backgroundColor: active ? theme.skyblue : 'transparent',
      color: theme.gray200,
    },
    [Mobile]: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
    },
  });

export const addCart = (theme, active) =>
  css(addressCart(theme, active), {
    alignItems: 'center',
    padding: '0.75em',
  });
