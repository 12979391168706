import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import QRCode from 'qrcode';
import { PDFDownloadLink, Page, Document, Text, Image, View } from '@react-pdf/renderer';
import { getDynamicTranslation } from '@veraio/strank';
import { findFiatCurrency, DEFAULT_FIAT_CURRENCY } from '@oneecosystem/dealshaker-core';
import { Button } from 'components/UIExternal';
import { promoCodeType } from 'enums';
import PromoCodePdfRightSideBackground from 'assets/images/pdf/promo-code-pdf-background.png';
import DealShakerLogo from 'assets/images/Logo_white.png';
import { styles } from './styles';

export const PromoCodePdf = ({ promoCode }) => {
  const translatedDetails = getDynamicTranslation(promoCode?.businessDetails);
  const promoCodeCurrency = findFiatCurrency(promoCode?.currencyCode) ?? DEFAULT_FIAT_CURRENCY;
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    promoCode?.businessId && generateQR();
  }, []);

  const generateQR = async () => {
    const res = await QRCode.toDataURL(`${window.location.host}/merchant/${promoCode.businessId}/overview`);
    res && setQrCode(res);
  };

  return (
    qrCode && (
      <PDFDownloadLink
        document={
          <Document>
            <Page orientation="landscape" style={styles.page}>
              {/* left section */}
              <View style={styles.leftSection}>
                {/* header */}
                <View style={styles.header}>
                  <Image style={styles.logo} src={DealShakerLogo} />
                  <Text style={styles.brandLink}>DealShaker.com</Text>
                </View>
                {/* middle section with the QR code */}
                <View style={styles.middle}>
                  <View style={styles.businessDetails}>
                    <Text style={styles.businessName}>{translatedDetails?.name}</Text>
                    <Text style={styles.businessDescription}>{translatedDetails?.description}</Text>
                    <Text style={styles.promoCode}>{promoCode?.code}</Text>
                  </View>
                  <View style={styles.qr}>
                    <Image src={qrCode} />
                  </View>
                </View>
                {/* footer */}
                <View style={styles.footer}>
                  <View>
                    <Text style={styles.dateLabel}>Date of issue</Text>
                    <Text style={styles.date}>{moment(promoCode?.createdOn).format('DD/MM/YYYY')}</Text>
                  </View>
                  <View>
                    <Text style={styles.discount}>{`${promoCode?.value} ${
                      promoCode?.typeId === promoCodeType.percent ? '%' : promoCodeCurrency.code
                    }  OFF`}</Text>
                  </View>
                </View>
              </View>
              {/* right section */}
              <View style={styles.rightSection}>
                <Image style={styles.imageRight} src={PromoCodePdfRightSideBackground} />
              </View>
            </Page>
          </Document>
        }
        fileName="promo-code"
      >
        <Button small type="secondary" leftIcon={{ iconName: 'las la-download', size: 18 }}>
          PDF
        </Button>
      </PDFDownloadLink>
    )
  );
};

PromoCodePdf.propTypes = {
  promoCode: PropTypes.object,
};
