import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isMobile } from 'react-device-detect';
import { Button } from 'components/UIExternal';
import { Routes } from 'enums';
import { blackTextLink } from 'assets/css/globalCSS';
import { actionsWrapper } from './styles';

const FormActions = ({
  currentStep,
  setManualStep,
  onSkipStepHandler,
  onNextStepHandler,
  onPreview,
  submitDisabled,
}) => {
  const theme = useTheme();
  const { getText } = useTranslations();

  const content = {
    1: (
      <>
        <Button
          type="link"
          small
          linkTo={`${Routes.merchantOffice}${Routes.myDeals}`}
          className={blackTextLink(theme)}
          data-id="cancelButton"
        >
          {getText('cancel')}
        </Button>
        <Button type="primary" small onClick={onNextStepHandler} data-id="applyNextButton">
          {getText('applyNext')}
        </Button>
      </>
    ),
    2: (
      <>
        <Button
          type="link"
          small
          onClick={() => setManualStep(currentStep - 1)}
          className={blackTextLink(theme)}
          data-id="prevStepButton"
        >
          {getText('previousStep')}
        </Button>
        <Button type="primary" small onClick={onNextStepHandler} data-id="applyNextButton">
          {getText('applyNext')}
        </Button>
      </>
    ),
    3: (
      <>
        <Button
          type="link"
          small
          onClick={() => setManualStep(currentStep - 1)}
          className={blackTextLink(theme)}
          data-id="prevStepButton"
        >
          {getText('previousStep')}
        </Button>
        <Button type="link" small onClick={onSkipStepHandler} className={blackTextLink(theme)} data-id="skipButton">
          {getText('skipForLater')}
        </Button>

        <Button type="primary" small onClick={onNextStepHandler} data-id="applyNextButton">
          {getText('applyNext')}
        </Button>
      </>
    ),
    4: (
      <>
        <Button
          type="link"
          small
          onClick={() => setManualStep(currentStep - 1)}
          className={blackTextLink(theme)}
          data-id="prevStepButton"
        >
          {getText('previousStep')}
        </Button>
        <Button type="primary" small onClick={onNextStepHandler} data-id="applyNextButton">
          {getText('applyNext')}
        </Button>
      </>
    ),
    5: (
      <>
        {!isMobile && (
          <Button type="info" small onClick={onPreview} data-id="prevButton">
            {getText('previewDeal')}
          </Button>
        )}
        <Button
          type="primary"
          small
          onClick={onNextStepHandler}
          disabled={submitDisabled}
          data-id="submitApprovalButton"
        >
          {getText('submitForApproval')}
        </Button>
      </>
    ),
  };
  return <div className={`flex space-between ${actionsWrapper}`}>{content[currentStep]}</div>;
};

FormActions.propTypes = {
  currentStep: PropTypes.number,
  setManualStep: PropTypes.func,
  onPreview: PropTypes.func,
  onNextStepHandler: PropTypes.func,
  onSkipStepHandler: PropTypes.func,
  submitDisabled: PropTypes.bool,
};
export default FormActions;
