import { css } from 'emotion';

export const searchInput = (theme, className) =>
  css(
    {
      '&.ant-input-affix-wrapper': {
        borderRadius: '20px',
        '& svg': {
          stroke: theme.gray100,
        },
      },
    },
    className,
  );

export const linkBtn = css({
  padding: '0 0.25em 0 0.125em',
  minHeight: 0,
});
