import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { brandColorsPage21Container } from './styles';

export const BrandColorPage21 = () => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div className={brandColorsPage21Container(theme)}>
      <div className="columnLeft">
        <div className="primaryBlue">
          <div>
            <p>{theme.blue}</p>
            <p>{getText('primaryBlue')}</p>
          </div>
        </div>
        <div className="skyBlueDark">
          <div>
            <p>{theme.primary_blue}</p>
            <p>{getText('skyBlue')}</p>
          </div>
        </div>
        <div className="skyBlueLight">
          <div>
            <p>{theme.skyblue}</p>
            <p>{getText('skyBlue')}</p>
          </div>
        </div>
      </div>
      <div className="columnMiddle">
        <div className="primaryYellow">
          <div>
            <p>{theme.primary_yellow}</p>
            <p>{getText('primaryYellow')}</p>
          </div>
        </div>
        <div className="paleYellow">
          <div>
            <p>{theme.pale_yellow}</p>
            <p>{getText('paleYellow')}</p>
          </div>
        </div>
      </div>
      <div className="columnRight">
        <div className="gray100">
          <div>
            <p>{theme.gray100}</p>
            <p>{getText('gray100')}</p>
          </div>
        </div>
        <div className="gray60">
          <div>
            <p>{theme.gray200}</p>
            <p>{getText('gray60')}</p>
          </div>
        </div>
        <div className="gray40">
          <div>
            <p>{theme.gray300}</p>
            <p>{getText('gray40')}</p>
          </div>
        </div>
        <div className="gray20">
          <div>
            <p>{theme.gray500}</p>
            <p>{getText('gray20')}</p>
          </div>
        </div>
        <div className="gray10">
          <div>
            <p>{theme.gray550}</p>
            <p>{getText('gray10')}</p>
          </div>
        </div>
        <div className="gray5">
          <div>
            <p>{theme.gray600}</p>
            <p>{getText('gray5')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
