import queryString from 'query-string';
import { getReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';

const baseUrl = `${envConfig.apiAddress}/api/Categories`;

export const getCategory = categoryId => getReq(`${baseUrl}/Details/${categoryId}`);

export const getAllCategories = () => getReq(`${baseUrl}/All`);

export const getTopCategories = (includeVirtualCategories, calculateCounts) => {
  const params = queryString.stringify({ includeVirtualCategories, calculateCounts }, { arrayFormat: 'index' });
  return getReq(`${baseUrl}/TopLevel?${params}`);
};
