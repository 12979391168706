import { EXTERNAL_PAYMENT_METHODS } from 'enums';

export const getPrecisionByExternalPaymentMethod = methodCode => {
  if (!methodCode) return null;

  const configValues = Object.values(EXTERNAL_PAYMENT_METHODS);
  const method = configValues.find(v => v.code.toLowerCase() === methodCode.toLowerCase());

  return method?.precision;
};
