import { css } from 'emotion';

export const filterBar = css({
  display: 'flex',
  gap: '3rem',
  margin: '1em 0 1.5em',
});

export const orderFilter = css({
  flex: '2 1 0',
  marginTop: '.4em',
  '&.search': {
    flex: '4 1 0',
  },
});
