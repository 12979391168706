import { css } from 'emotion';

export const tableContainer = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.white,
    borderRadius: 3,
  });

export const containerBody = css({
  marginBottom: '1em',
});

export const tableHead = css({
  display: 'flex',
  flexFlow: 'row nowrap',
});

export const tableRow = (hasOnClick, selectRow, theme) =>
  css([
    {
      display: 'flex',
      alignItems: 'center',
      color: theme.black100,
      transition: 'background-color .3s ease-in-out',
      borderBottom: `1px solid ${theme.gray500}`,
      '&:hover': {
        backgroundColor: theme.gray700,

        '& i': {
          color: theme.skyblue_hover,
        },
      },

      '& i': {
        color: theme.gray400,
      },
    },
    hasOnClick && {
      cursor: 'pointer',
    },
    selectRow && {
      backgroundColor: theme.skyblue_hover,
      '&:hover': {
        backgroundColor: theme.skyblue_hover,
      },
    },
  ]);

export const tableCell = ({ width, flex, sm, md, lg, xl }) =>
  css([
    {
      fontSize: '1rem',
      lineHeight: 1.46,
      letterSpacing: '0.35px',
      flex: flex ?? (width ? `0 1 ${width}px` : '1 0 60px'),
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      padding: '1em 0.375em',
      outline: 'none',
      wordBreak: 'break-word',

      '&:has(>div[role="menuitem"])': {
        overflow: 'visible',
      },
    },
    sm && {
      '@media (max-width: 575px)': {
        display: 'none',
      },
    },
    md && {
      '@media (max-width: 767px)': {
        display: 'none',
      },
    },
    lg && {
      '@media (max-width: 991px)': {
        display: 'none',
      },
    },
    xl && {
      '@media (max-width: 1199px)': {
        display: 'none',
      },
    },
  ]);

export const tableHeadCell = (options, theme) =>
  css([
    tableCell(options),
    {
      fontSize: '0.875rem',
      color: theme.black100,
      fontWeight: 500,
      backgroundColor: theme.gray700,
      borderBottom: `1px solid ${theme.gray800}`,
      padding: '1em 0.5em',

      '& i': {
        color: theme.gray400,
      },

      '& > p': {
        marginBottom: 0,
      },

      '&:hover': {
        '& i': {
          color: theme.primary_blue,
        },
      },
    },
    options?.sortable && {
      cursor: 'default',
      '& b': {
        cursor: 'pointer',
        '&:hover + i': {
          opacity: 1,
        },
      },
    },
  ]);

export const tableHeadIcon = (isAsc, isDesc) =>
  css({
    fontSize: '1.125rem',
    opacity: isAsc || isDesc ? 1 : 0,
    transform: `rotate(${isDesc ? 0 : 180}deg)`,
    transition: 'transform 300ms',
  });

export const tableCellContent = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '& span': {
    display: 'inline',
  },
};

export const tableCellEmpty = theme =>
  css({
    width: '2rem',
    height: '0.25rem',
    backgroundColor: theme.gray700,
    borderRadius: 12,
    margin: '0 auto',
  });

export const tableCellCheckButton = css({
  '& i': {
    margin: 0,
  },
});

export const paginationContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
});

export const shownResultsIndicator = theme =>
  css({
    color: theme.gray200,
    width: '5rem',
    textAlign: 'center',
    fontSize: '0.6875rem',
    marginRight: '0.5em',
  });

export const paginationButton = (disabled, isActive, theme) =>
  css([
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      userSelect: 'none',
      width: '2rem',
      height: '2rem',
      margin: '0 0.125em',
      borderRadius: '50%',

      '&:hover': {
        backgroundColor: theme.gray400,
      },
    },
    isActive && {
      backgroundColor: theme.gray600,
    },
    disabled && {
      cursor: 'not-allowed',
      color: theme.gray300,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  ]);

export const noResultsContainer = theme =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5em 1.25em',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.gray400,
  });

export const noResultsIcon = css({
  width: '4rem',
  height: '2.75rem',
  marginBottom: '1em',
});

export const noResultsTitle = css({
  fontWeight: 500,
  marginBottom: '0.75em',
});

export const noResultsSubtitle = theme =>
  css({
    color: theme.black100,
  });

export const pageSizeDropdown = css({
  width: '5rem',
});

export const tableTitleHeadline = css({
  marginBottom: '3em',
  fontWeight: 600,
});
