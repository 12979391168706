import React from 'react';
import { useTheme } from 'emotion-theming';
import { getText } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { SearchBar, Icon } from 'components';
import { fetchCitiesByCountry, setLocation } from 'stores';
import { countryFilterSearchBar, countryFilterItem, countryFilterBody } from './styles';

export const CountryFilter = () => {
  const theme = useTheme();
  const { countries } = useLocations();

  const handleSelectCountry = country => {
    setLocation({ country });
    fetchCitiesByCountry({ countryId: country.id });
  };

  return (
    <div>
      <SearchBar
        placeholder={getText('searchForCountry')}
        options={countries}
        displayKey="name"
        className={countryFilterSearchBar}
        renderSuggestion={val => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            {...val.itemProps}
            className={`${val.itemProps.className} suggestion`}
            role={val.itemProps.role}
            onClick={() => {
              handleSelectCountry(val.item);
              val.suggestionItemProps.onClick(val.item);
            }}
          >
            {val.item.name}
            <Icon iconName="las la-angle-right" />
          </div>
        )}
      />
      <div className={countryFilterBody}>
        {countries?.map((country, i) => (
          <div
            key={i}
            className={countryFilterItem(theme)}
            role="button"
            tabIndex={0}
            onClick={() => handleSelectCountry(country)}
          >
            <div>{country.name}</div>
            <Icon iconName="las la-angle-right" />
          </div>
        ))}
      </div>
    </div>
  );
};
