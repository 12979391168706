import { getReq, postReq, patchReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';
import { getOrdersDetailsParams, getOrdersListParams } from '../models';

const baseUrl = `${envConfig.apiAddress}/api/Orders`;

export const createOrder = data => postReq(`${baseUrl}/CreateOrder`, data);

export const getOrdersDetails = orderIds => getReq(`${baseUrl}/Details?${getOrdersDetailsParams(orderIds)}`);

export const getSoldOrders = options => getReq(`${baseUrl}/Sold?${getOrdersListParams(options)}`);

export const getBoughtOrders = options => getReq(`${baseUrl}/Bought?${getOrdersListParams(options)}`);

export const updateOrderDeliveryStatus = (orderId, deliveryStatusId) =>
  patchReq(`${baseUrl}/Delivery/${orderId}`, { deliveryStatusId });

export const updateOrderCashPaymentStatus = (orderId, paymentStatusId) =>
  patchReq(`${baseUrl}/Payment/${orderId}`, { paymentStatusId });

export const updateOrderStatus = (orderId, statusId) => patchReq(`${baseUrl}/Status/${orderId}`, { statusId });

export const getOrderExternalPayment = orderId => getReq(`${baseUrl}/ExternalPaymentInfo?orderId=${orderId}`);
