import { css } from 'emotion';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const box = theme =>
  css({
    [Desktop]: {
      padding: '1.5625em 5em',
      alignItems: 'center',
      border: `2px solid ${theme.gray500}`,
      borderRadius: '3px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& h5': {
      [Mobile]: { fontSize: '0.8125rem' },
      fontSize: '1.1875rem',
      marginBottom: '0.5em',
      fontWeight: 'bold',
    },
    '& p': { marginBottom: 0 },
    '& label': { fontSize: '0.75rem !important' },
  });
export const promoteWrapper = theme =>
  css({
    width: '100%',
    marginBottom: '0.5em',
    display: 'flex',
    borderRadius: 4,
    justifyContent: 'space-between',
    background: theme.gray600,
    alignItems: 'center',
    padding: '0.75em 0.5em',
    '& .text': {
      '& label': { marginLeft: '1em', color: theme.gray200, fontSize: '0.75rem !important' },
      '& h4': { marginLeft: '1em', fontSize: '0.8125rem', marginBottom: 0 },
    },
  });
export const totalCredits = css({
  [Mobile]: { display: 'none' },
  marginTop: '1em',
});
export const availableCredits = theme =>
  css({
    [Mobile]: { backgroundColor: theme.gray600, borderRadius: 4, padding: '1.75em 0' },
    marginTop: '2.1875em',
    '& p': { fontSize: '0.8125rem', color: theme.gray200 },
    '& .ant-btn': {
      fontSize: '0.8125rem',
      marginLeft: '0.5em',
      color: `${theme.blue} !important`,
      textDecoration: 'underline !important',
      padding: 0,
    },
  });
export const checkbox = css({
  alignItems: 'center !important',
  verticalAlign: 'center',
  '& .ant-checkbox-checked': {
    background: 'white',
  },
});
export const inputNumber = css({
  '& .ant-input-number-handler-wrap': { opacity: 1 },
  '& .anticon svg': { position: 'absolute' },
});
