import React from 'react';
import PropTypes from 'prop-types';
import { PriceLabel } from 'components';
import { CRYPTO_CURRENCIES } from '@oneecosystem/dealshaker-core';

import { useTheme } from 'emotion-theming';
import { amountsBox } from './styles';

export const TransactionsAmountsTotals = ({ one, oesp, fiat, fiatCurrency, title }) => {
  const theme = useTheme();

  return (
    <div className={amountsBox(theme)} data-id="transactions-total-amounts-box">
      <h6>{title}</h6>
      <div data-id="transactions-total-amounts-values">
        <label>
          <PriceLabel crypto={one} ignoreEmpty ignoreCryptoRestricted fiatOnly={false} />
        </label>
        <label>
          <PriceLabel fiat={fiat} ignoreCryptoRestricted fiatDesiredCurrency={fiatCurrency} fiatOnly={false} />
        </label>
        <label>
          <PriceLabel
            crypto={oesp}
            cryptoDesiredCurrency={CRYPTO_CURRENCIES.OESP.code}
            ignoreCryptoRestricted
            ignoreEmpty
            fiatOnly={false}
          />
        </label>
      </div>
    </div>
  );
};

TransactionsAmountsTotals.propTypes = {
  one: PropTypes.number,
  oesp: PropTypes.number,
  fiat: PropTypes.number,
  fiatCurrency: PropTypes.string,
  title: PropTypes.string,
};
