import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { brandColorsPage22Container } from './styles';

export const BrandColorPage22 = () => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div className={brandColorsPage22Container(theme)}>
      <div className="dsGreen">
        <div className="header">{theme.green2}</div>
        <p className="body">{getText('dsGreen')}</p>
        <p className="footer">{theme.green3}</p>
      </div>
      <div className="primaryOrange">
        <div className="header">{theme.red}</div>
        <p className="body">{getText('primaryOrange')}</p>
        <p className="footer">{theme.primary_orange}</p>
      </div>
      <div className="dsViolette">
        <div className="header">{theme.purple}</div>
        <p className="body">{getText('dsViolette')}</p>
        <p className="footer">{theme.purple2}</p>
      </div>
      <div className="dsNavy">
        <div className="header">{theme.background100}</div>
        <p className="body">{getText('dsNavy')}</p>
        <p className="footer">{theme.background200}</p>
      </div>
    </div>
  );
};
