import React from 'react';
import { useTranslations } from '@veraio/strank';
import { softLogout } from '@oneecosystem/authenticate';
import { Button } from 'components/UIExternal';
import ErrorImg from 'assets/images/errorPages/unouthorized-401.png';
import { mainContainer } from './styles';

const InsufficientRights = () => {
  const { getText } = useTranslations();

  return (
    <div className={`content-container flex-column flex-center ${mainContainer}`}>
      <img src={ErrorImg} alt="unauthorized" />
      <h2>{getText('dontHaveRights')}</h2>
      <p>{getText('notAuthorizedAction')}</p>
      <Button small type="primary" onClick={softLogout}>
        {getText('logOut')}
      </Button>
      <Button small type="primary" linkTo="/">
        {getText('browseDeals')}
      </Button>
    </div>
  );
};

export default InsufficientRights;
