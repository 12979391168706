import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { isFunction } from '@veraio/core';
import { setUpTheme } from './themeService';

const Theme = ({ children, initGlobalStyles }) => {
  const [theme, setTheme] = useState(null);

  useLayoutEffect(() => {
    getTheme();
  }, []);

  const getTheme = async () => {
    const result = await setUpTheme();
    isFunction(initGlobalStyles) && initGlobalStyles(result);
    setTheme(result);
  };

  // TODO: Put some loader to be shown until fetching theme config
  return theme && <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes.node.isRequired,
  initGlobalStyles: PropTypes.func,
};

export default Theme;
