import { css } from 'emotion';

export const notificationContainer = theme =>
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.25em',
    width: '100%',
    cursor: 'pointer',
    '& *': {
      cursor: 'pointer',
    },

    '&:hover': {
      backgroundColor: theme.ui_hover_rgba,
    },

    '& .icon': {
      width: '2rem',
      marginRight: '1em',
    },

    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: '0.3125em',
      width: '100%',

      '& .titleTimeContainer': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        fontWeight: 400,
        width: '100%',
        '& .icon-Bullet': {
          opacity: 0.6,
        },

        '& .title': {
          fontSize: '0.75rem',
        },

        '& .time': {
          opacity: 0.6,
          fontSize: '0.75rem',
        },
      },
      '& .message': {
        fontSize: '0.875rem',
        width: '100%',
        fontWeight: 500,
      },
    },
  });
