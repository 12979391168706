import React, { useState, useEffect } from 'react';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { isDesktop } from 'react-device-detect';
import FeatherIcon from 'feather-icons-react';
import Slider from 'react-slick';
import { Button } from 'components/UIExternal';
import Deal from 'components/deal/Deal';
import { CarouselSkeleton } from 'components/ui';
import { Routes } from 'enums';
import { getPromoDeals } from 'services/api/dealsService';
import useError from 'services/errorHandling/useError';
import { title, wrapper } from './styles';

const settings = slidesPerPage => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: slidesPerPage,
  slidesToScroll: slidesPerPage,
  arrows: isDesktop,
});

const PromoDeals = () => {
  const { getText } = useTranslations();
  const { shippingTo, shippingFrom } = useLocations();
  const { setError } = useError();
  const [deals, setDeals] = useState(null);
  const slidesPerPage = isDesktop ? 4 : 1;

  useEffect(() => {
    fetchDeals();
  }, [shippingFrom, shippingTo]);

  const fetchDeals = async () => {
    const [res, err] = await getPromoDeals({
      from: shippingFrom?.map(el => el?.id),
      to: shippingTo?.map(el => el?.id),
      pageSize: 8,
    });
    err ? setError(err) : setDeals(res);
  };

  return (
    <div className="content-container">
      <h2 className={title}>{getText('promoDeals')}</h2>
      {isNil(deals) && <CarouselSkeleton numberOfDealsPerSlide={4} />}
      <div className={wrapper(isDesktop && deals?.items.length < slidesPerPage + 1)}>
        <Slider {...settings(slidesPerPage)}>
          {deals?.items.map(deal => (
            <Deal key={deal.id} deal={deal} dataId="promo" />
          ))}
        </Slider>
      </div>
      <Button type="link" small linkTo={`${Routes.dealListingPage}?discountAbove=50`}>
        {getText('allPromoDeals')}
        <FeatherIcon icon="chevron-right" size={15} />
      </Button>
    </div>
  );
};

export default PromoDeals;
