import React from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { pageOneGuide } from './styles';

export const BrandGuideLinePage = () => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return <div className={pageOneGuide(theme)}>{getText('brandGuideline')}</div>;
};
