import React from 'react';
import { useTranslations } from '@veraio/strank';
import { isNil } from '@veraio/core';
import { useTheme } from 'emotion-theming';
import { Button, Skeleton } from 'components/UIExternal';
import { useOrders } from 'stores';
import { orderDetailsUserContainer } from './styles';

export const OrderDetailsUser = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { buyer, business, editable } = useOrders(ordersState => ({
    buyer: ordersState?.selectedOrder?.buyer,
    business: ordersState?.selectedOrder?.business,
    editable: ordersState?.editable,
  }));
  const user = editable ? buyer : business;

  const UserAvatar = name => {
    // Avatars for now are not used and returned from API - 1000 users from 480 000 have avatars
    // if (user?.avatar) return <img src={user?.avatar} className="order-details-user-avatar" alt="user" />;

    const arr = name?.split(' ');
    // If the user do not have name show placeholder of UA instead of show nothing
    const letters = `${arr.at(0).charAt(0) ?? 'U'}${arr.at(-1).charAt(0) ?? 'A'}`;

    return <span className="order-details-user-avatar">{letters}</span>;
  };

  return (
    <section className={orderDetailsUserContainer(theme)}>
      <div className="order-details-user">
        {isNil(user) ? <Skeleton height={60} width={60} circle marginRight="1em" /> : UserAvatar(user?.name)}
        {isNil(user) ? (
          <Skeleton height={20} width={150} count={2} marginTop=".5em" />
        ) : (
          <div>
            {!editable ? (
              <Button small type="link" linkTo={`/merchant/${user.id}`} padding="0" fontSize="1.25rem">
                {user?.name}
              </Button>
            ) : (
              <h4>{user?.name}</h4>
            )}
            {user?.phoneNumber && <p className="gray">{user?.phoneNumber}</p>}
          </div>
        )}
      </div>
      {isNil(user) ? (
        <Skeleton height={40} width={150} />
      ) : (
        <Button small type="link" linkTo={`/chat?dealId=${0}&userId=${user.id}`} fontSize="1.1rem">
          {editable ? getText('messageBuyer') : getText('messageMerchant')}
        </Button>
      )}
    </section>
  );
};
