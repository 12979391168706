import React from 'react';
import { useTranslations } from '@veraio/strank';
import { cryptoSign } from '@oneecosystem/dealshaker-core';
import { useTheme } from 'emotion-theming';
import { mainContainer } from './styles';

const TermsAndConditional = () => {
  const { getText } = useTranslations();
  const theme = useTheme();

  return (
    <div className={`content-container ${mainContainer(theme)}`}>
      <div className="terms-text-wrap">
        <p>{getText('termsAndConditionsText', { cryptoSign: cryptoSign() })}</p>
      </div>
    </div>
  );
};

export default TermsAndConditional;
