import styled from '@emotion/styled';
import { Tabs as AntDTabs } from 'antd';

const AntDTabPane = AntDTabs.TabPane;

export const Tabs = styled(AntDTabs)`
  &.ant-tabs-top {
    & > [role='tablist'] {
      margin-bottom: 0;
    }
    & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${props => props.theme.gray100};
    }
    & .ant-tabs-tab:hover {
      color: ${props => props.theme.gray100};
    }
    & .ant-tabs-tab {
      color: ${props => props.theme.gray300};
    }
    & .ant-tabs-ink-bar {
      background: ${props => (props.useBlackUnderline ? props.theme.gray100 : props.theme.primary_yellow)};
    }
    & .ant-tabs-tab-btn:focus {
      color: ${props => props.theme.gray300};
    }
  }
`;

export const TabPane = styled(AntDTabPane)``;
