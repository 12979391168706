import styled from '@emotion/styled';
import discountStampImage from 'assets/images/discount-stamp.svg';
import discountStampImage2 from 'assets/images/discount-stamp-2.svg';

const BasicDiscountStamp = styled.div`
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  & > label {
    color: ${props => props.theme.white};
    vertical-align: middle;
    font-weight: 700;
  }
  & .icon-Percent {
    vertical-align: text-top;
  }
`;

export const DiscountStampBottom = styled(BasicDiscountStamp)`
  background-image: url(${discountStampImage});
  & .icon-Percent {
    font-size: 0.5625rem;
  }
`;

export const DiscountStampTop = styled(BasicDiscountStamp)`
  background-image: url(${discountStampImage2});
  & > label {
    margin: 0;
    font-size: 1rem;
  }
  & .icon-Percent {
    font-size: 0.6875rem;
  }
`;
