import { StyleSheet } from '@react-pdf/renderer';

export const generalStyles = StyleSheet.create({
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  wrapper: {
    height: '100%',
    fontFamily: 'Noto',
  },
});
