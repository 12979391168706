import React from 'react';
import PropTypes from 'prop-types';
import { Page, Document, Text, View, Svg, Path, G, Image, Rect, Polygon } from '@react-pdf/renderer';
import { s } from './styles';
import { generalStyles } from '../styles';

const color = {
  dark: {
    st0: '#3A6DB5',
    st1: '#3469B3',
    st2: '#FECF09',
    st3: '#FFFFFF',
    st4: '#FECF0C',
  },
  light: {
    st0: '#5AA4DA',
    st1: '#559FD7',
    st2: '#FECF09',
    st3: '#FFFFFF',
    st4: '#FECF0C',
  },
};

const BadgesTemplate = ({ qrCode, businessInfo }) => {
  const SVGComponent = ({ version }) => (
    <Svg x="0px" y="0px" viewBox="0 0 226.8 340.2" style={{ position: 'absolute', top: 0 }}>
      <Rect x={-14.2} y={-14.2} fill={color[version].st0} width={255.1} height={368.5} />
      <G>
        <Path
          fill={color[version].st1}
          d="M87.4,0.6c30,53.6,58.6,104.8,88.1,157.5h-46.9L87.5,85.2c-14.5,25.7-27.9,49.3-41.3,73H0   L87.4,0.6z"
        />
        <Path
          fill={color[version].st1}
          d="M87.4,267.5L46.3,340H0.1l87.2-157.2l88.1,157.3h-47C115.4,316.9,102,293.2,87.4,267.5z"
        />
      </G>
      <Path
        fill={color[version].st2}
        d="M196.8,59.1C180.6,43.3,171.3,21.7,171-1c-0.1-4.4,0.2-8.8,0.9-13.2h-52.8  c-0.4,4.6-0.6,9.2-0.5,13.8c0.5,36.6,15.4,71.5,41.6,97c22.2,21.6,50.9,35.1,81.4,38.4V82C224.8,79.1,209.2,71.1,196.8,59.1z"
      />
      <G>
        <Path
          fill={color[version].st3}
          d="M22.7,52.7c-0.8,1.3-2.6,1.9-4.2,1.5v-6.4c1.6-0.4,3.1,0,4,1.2c0.4,0.5,0.6,1.2,0.6,1.8   C23.2,51.5,23,52.2,22.7,52.7L22.7,52.7z M21,43.8c-2.1-0.3-4.2-0.1-6.3-0.1c-0.1,0-0.2,0.1-0.3,0.2v14.6c1.9,0,3.7,0,5.5,0   c3.8-0.1,7-3,7.3-6.6C27.5,47.8,24.9,44.3,21,43.8z"
        />
        <Polygon
          fill={color[version].st3}
          points="27.6,75 27.6,60.6 31.4,60.6 31.4,65.8 36.3,65.8 36.3,60.7 40.1,60.7 40.1,75    36.3,75 36.3,69.9 31.5,69.9 31.5,75  "
        />
        <Polygon
          fill={color[version].st3}
          points="31.7,47.6 31.7,49 34.8,49 34.8,53.1 31.7,53.1 31.7,54.4 38.7,54.4 38.7,58.3    27.6,58.3 27.6,43.7 38.7,43.7 38.7,47.6  "
        />
        <Polygon
          fill={color[version].st3}
          points="81.3,75.1 70.3,75.1 70.3,60.6 81.2,60.6 81.2,64.4 74.3,64.4 74.3,65.9 77.3,65.9    77.3,69.8 74.3,69.8 74.3,71.3 81.3,71.3  "
        />
        <Path
          fill={color[version].st3}
          d="M26.9,62.7c-0.9,0.7-2.8,2.4-2.8,2.4s-1.4-0.7-2.6-1c-0.7-0.2-2.2-0.3-2.7,0.5   c-0.5,0.8,0.7,1.2,1.3,1.3c0.7,0.1,1.6,0,2.3,0.1c3,0.5,4.7,2.3,4.4,5c-0.1,1-0.5,1.9-1.2,2.6c-2.6,2.9-8.4,2.1-11-1l3-2.1   c0,0,1.2,0.9,2.5,1.1c0.8,0.1,1.7,0.1,2.4-0.3c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.5-0.5-0.8-0.9-0.9c-0.7-0.1-1.5-0.2-2.2-0.3   c-2.7-0.3-4.6-1.8-5-4.1c-0.2-1.3,0.1-2.5,1-3.5C18.1,59.2,24,60,26.9,62.7z"
        />
        <Path
          fill={color[version].st3}
          d="M69.5,73l-3.2,2.2l-4.8-5.3L61.4,75h-3.8V60.6h3.7v5.4c0,0,3.4-3.7,5-5.5l3.1,2.3l-4.3,5   L69.5,73z"
        />
        <Path
          fill={color[version].st4}
          d="M48.7,43.7c2.8,4.9,5.4,9.7,8.1,14.5h-4.3l-3.8-6.7c-1.3,2.4-2.6,4.5-3.8,6.7h-4.3L48.7,43.7z"
        />
        <Path
          fill={color[version].st4}
          d="M48.7,68.3L44.9,75h-4.3l8-14.5L56.9,75h-4.3C51.3,72.9,50.1,70.7,48.7,68.3z"
        />
        <Polygon fill={color[version].st3} points="61.5,43.7 61.5,54.4 68.5,54.4 68.5,58.3 57.5,58.3 57.5,43.7  " />
        <Path
          fill={color[version].st3}
          d="M90.1,66.4c0,0.4-0.2,0.8-0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.5c-0.8,0.1-1.7,0-2.6,0v-3.6   c1,0,1.9-0.1,2.8,0C89.6,64.6,90.2,65.5,90.1,66.4z M92.1,70.7c0.3-0.4,0.6-0.7,0.8-1.1c2.3-3.2,0.6-8.1-3.3-8.8   c-2.5-0.4-5.1-0.1-7.7-0.1v14.4h3.9v-3.1c0,0,2.1-0.2,3.1,0.8c1.2,1.1,2.3,2.2,3.6,3.5l2.7-2.8v-0.2L92.1,70.7z"
        />
      </G>
    </Svg>
  );

  SVGComponent.propTypes = {
    version: PropTypes.string,
  };

  return (
    <Document>
      <Page size={[226.77, 340.15]}>
        <View>
          <SVGComponent version="dark" />
          <View style={generalStyles.wrapper}>
            <View style={s.contentBox}>
              <Image source={qrCode} style={s.qrCodeStyles} />
              <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
              <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
              <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
            </View>
          </View>
        </View>
      </Page>
      <Page size={[226.77, 340.15]}>
        <View>
          <SVGComponent version="light" />
          <View style={generalStyles.wrapper}>
            <View style={s.contentBox}>
              <Image source={qrCode} style={s.qrCodeStyles} />
              <Text style={s.textBox.country}>{businessInfo?.country?.currentValue}</Text>
              <Text style={s.textBox.businessName}>{businessInfo?.name?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.street?.currentValue}</Text>
              <Text style={s.textBox.location}>{businessInfo?.email?.currentValue}</Text>
              <Text style={s.textBox.location}>tel: +{businessInfo?.phone?.currentValue}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

BadgesTemplate.propTypes = {
  qrCode: PropTypes.string,
  businessInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default BadgesTemplate;
