import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { hasCryptoAmountForCart, useCurrencies, DEFAULT_FIAT_CURRENCY } from '@oneecosystem/dealshaker-core';
import { Button } from 'components/UIExternal';
import { PriceLabel } from 'components/shared';
import { useUser } from 'stores';
import { promoCodeType, PAYMENT_METHODS_CURRENCIES } from 'enums';
import { getPrecisionByExternalPaymentMethod } from 'utils';
import { mb } from 'assets/css/globalCSS';
import { summaryBox, spaceRowSection, rightText, totalSection, regularTextStyles, goToCheckoutButton } from './styles';

const SummaryPricesCard = ({ shoppingCart, linkTo }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { currentAccount } = useUser();
  const selectedCurrency = useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const hasPromoCodes = shoppingCart.businesses?.some(el => el.promoCode);
  const insufficientFundsError = hasCryptoAmountForCart({
    shoppingCart,
    coinWalletBalance: currentAccount.coinWalletBalance,
    oespWalletBalance: currentAccount.oespWalletBalance,
  });
  const cartTotal = shoppingCart.discountedTotalLocalPrice ?? shoppingCart.totalLocalPrice;
  const cartFiatTotal = shoppingCart.discountedTotalLocalPriceFiat ?? shoppingCart.totalLocalPriceFiat;
  const cartCryptoTotal = shoppingCart.discountedTotalPriceCrypto ?? shoppingCart.totalPriceCrypto;
  const cryptoDesiredCurrency = PAYMENT_METHODS_CURRENCIES[shoppingCart.cryptoPaymentMethodId]?.code;
  const businessesFiatCurrency = selectedCurrency?.code ?? DEFAULT_FIAT_CURRENCY.code;
  const cartSelectedFiatCurrency = PAYMENT_METHODS_CURRENCIES[shoppingCart.cashPaymentMethodId]
    ? PAYMENT_METHODS_CURRENCIES[shoppingCart.cashPaymentMethodId]
    : businessesFiatCurrency;

  const isFiatDiscount = promoCode => promoCode.typeId === promoCodeType.fiat;
  const promoCodePrice = promoCode =>
    isFiatDiscount(promoCode) ? (
      <PriceLabel
        data-id="promo-code-discount"
        fiat={promoCode.value}
        fiatOriginCurrency={promoCode.currencyCode}
        fiatDesiredCurrency={cartSelectedFiatCurrency}
        fiatPrecision={getPrecisionByExternalPaymentMethod(cartSelectedFiatCurrency)}
        total={promoCode.discountedPrice}
      />
    ) : (
      <span data-id="promo-code-discount">{promoCode.value}%</span>
    );

  return (
    <div className={summaryBox}>
      {hasPromoCodes && (
        <>
          <div className={spaceRowSection(theme.gray300)} data-id="shopping-cart-subtotal-contrainer">
            <p className="subtotal">{getText('subtotal')}</p>
            <p className={rightText} data-id="shopping-cart-subtotal">
              <PriceLabel
                fiat={shoppingCart.totalLocalPriceFiat}
                fiatOriginCurrency={businessesFiatCurrency}
                fiatDesiredCurrency={cartSelectedFiatCurrency}
                crypto={shoppingCart.totalPriceCrypto}
                fiatPrecision={getPrecisionByExternalPaymentMethod(cartSelectedFiatCurrency)}
                total={shoppingCart.discountedTotalLocalPrice ?? shoppingCart.totalLocalPrice}
              />
            </p>
          </div>
          {shoppingCart.businesses
            ?.filter(business => business?.promoCode)
            .map(({ promoCode }) => (
              <div
                className={spaceRowSection(theme.green2)}
                key={promoCode.code}
                data-id="promo-code-discount-container"
              >
                <p className="subtotal">
                  <span data-id="promo-code-text">{promoCode.code}</span>&nbsp;&nbsp; - {promoCodePrice(promoCode)}
                </p>
                <p className={rightText}>
                  (
                  <PriceLabel
                    fiat={promoCode.discountedPriceFiat}
                    fiatOriginCurrency={promoCode.currencyCode}
                    fiatDesiredCurrency={cartSelectedFiatCurrency}
                    fiatCrypto={promoCode.discountedPriceCrypto}
                    fiatPrecision={getPrecisionByExternalPaymentMethod(cartSelectedFiatCurrency)}
                    total={promoCode.discountedPrice}
                  />
                  )
                </p>
              </div>
            ))}
          <hr className={mb({ lg: 16, span: 16 })} />
        </>
      )}
      <div className={totalSection} data-id="shopping-cart-total-container">
        <label>{getText('total')}:</label>
        <p className={rightText} data-id="shopping-cart-total">
          <PriceLabel
            fiat={cartFiatTotal}
            fiatOriginCurrency={businessesFiatCurrency}
            fiatDesiredCurrency={cartSelectedFiatCurrency}
            fiatPrecision={getPrecisionByExternalPaymentMethod(cartSelectedFiatCurrency)}
            crypto={cartCryptoTotal}
            cryptoDesiredCurrency={cryptoDesiredCurrency}
            fiatOnly={false}
          />
        </p>
      </div>
      <p
        className={regularTextStyles(theme)}
        data-id="shopping-cart-regular-price"
        data-fiat={cartTotal}
        data-fiat-currency={businessesFiatCurrency}
      >
        {`${getText('regular-price')}: `}&nbsp;
        <PriceLabel
          fiat={cartTotal}
          fiatOriginCurrency={businessesFiatCurrency}
          fiatDesiredCurrency={cartSelectedFiatCurrency}
          fiatPrecision={getPrecisionByExternalPaymentMethod(cartSelectedFiatCurrency)}
          total={cartTotal}
        />
      </p>
      {linkTo && (
        <Button className={goToCheckoutButton(theme)} linkTo={linkTo} disabled={!!insufficientFundsError}>
          {getText('goToCheckout')}
          {insufficientFundsError && <span className="insufficientFunds">({getText(insufficientFundsError)})</span>}
        </Button>
      )}
    </div>
  );
};

SummaryPricesCard.propTypes = {
  shoppingCart: PropTypes.object,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default SummaryPricesCard;
