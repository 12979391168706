import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Button } from 'components/UIExternal';
import { useUser } from 'stores';
import noNotifications from 'assets/images/noNotifications.svg';
import { noCartItemsPanelContainer, loginButton } from './styles';

const EmptyShoppingCart = ({ big, onLoginClick }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const { isAuthenticated } = useUser();

  return (
    <div className={noCartItemsPanelContainer(big, theme)}>
      <img className="image" src={noNotifications} alt="Logo" />
      <h4 className="title">{getText('yourCartIsEmpty')}</h4>
      {!isAuthenticated && (
        <Button type="primary" small onClick={onLoginClick} className={loginButton}>
          {getText('tryLoggingIn')}
        </Button>
      )}
    </div>
  );
};

export default EmptyShoppingCart;
