import React from 'react';
import { useTheme } from 'emotion-theming';
import { Row, Col, Spin } from 'antd';
import { useTranslations } from '@veraio/strank';
import { useAddresses } from '@oneecosystem/dealshaker-core';
import { isMobile } from 'react-device-detect';
import { Button } from 'components/UIExternal';
import { Routes } from 'enums';
import NoAddressesImg from 'assets/images/noАddresses.svg';
import { mb } from 'assets/css/globalCSS';
import AddressCard from './AddressCard';
import {
  myAddressesTab,
  myAddressesStyles,
  noAddressesContainer,
  noAddressesText,
  addressesList,
} from './addressesStyles';

const MyAddressesTab = () => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const isLoading = false;
  const addresses = useAddresses();

  const addressesContent = isLoading ? (
    <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
      <Spin size="large" />
    </div>
  ) : (
    <>
      <Row className={addressesList} gutter={[24, 24]}>
        {addresses.map(address => (
          <Col className="gutter-row" lg={8} span={24} key={address.id}>
            <AddressCard address={address} />
          </Col>
        ))}
      </Row>
    </>
  );

  const noAddresses = (
    <div className={`${noAddressesContainer} ${isMobile ? 'flex-column' : 'flex'}`}>
      <img src={NoAddressesImg} alt="No addresses logo" />
      <div className={noAddressesText(theme)}>
        <label>{getText('doNotHaveAddressesYet')}</label>
      </div>
    </div>
  );

  return (
    <div className={myAddressesTab(theme)}>
      <div className={myAddressesStyles}>
        <Button type="info" small linkTo={Routes.addDeliveryAddress}>
          {getText('addNew')}
        </Button>
      </div>
      {addresses.length > 0 ? addressesContent : noAddresses}
    </div>
  );
};

export default MyAddressesTab;
