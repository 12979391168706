import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const mainContainer = theme =>
  css({
    color: theme.gray100,
    padding: '2em',
  });

export const sectionTitle = theme =>
  css({
    fontSize: '2rem',
    fontWeight: 500,
    color: theme.background100,
    '&.orderSummaryTitle': {
      marginBottom: '1em',
      [Mobile]: {
        marginBottom: 0,
      },
    },
    [Mobile]: {
      fontSize: '1.375rem',
    },
  });

export const merchantListItemContainer = theme =>
  css({
    border: `1px solid ${theme.gray500}`,
    borderRadius: 3,
    padding: '1.25em',
    marginBottom: '1em',
    [Mobile]: {
      border: 'none',
      borderBottom: `1px solid ${theme.gray500}`,
      borderRadius: 0,
      padding: 0,
      '&:last-of-type': {
        borderBottom: 'none',
      },
    },
  });

export const itemsCountStyles = theme =>
  css({
    color: theme.gray200,
    marginBottom: '1em',
  });

export const headerSection = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@media (max-width: 530px)': {
    flexWrap: 'wrap-reverse',
    '& a': {
      paddingLeft: '0',
    },
  },
});
