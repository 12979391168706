import { Col } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const NestedCol = ({ children, totalWidth, lg, span, className }) => {
  const ratio = 24 / totalWidth;
  const newLg = Math.round(lg * ratio);

  return (
    <Col className={className} lg={newLg} span={span}>
      {children}
    </Col>
  );
};

NestedCol.propTypes = {
  lg: PropTypes.number,
  span: PropTypes.number,
  totalWidth: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default NestedCol;
