import { StyleSheet } from '@react-pdf/renderer';
import { css } from 'emotion';

export const qrBlock = css({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: '1em',
  '& p': {
    textAlign: 'center',
  },
});

export const styles = StyleSheet.create({
  pageBackground: {
    position: 'absolute',
    top: 0,
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%',
  },
  document: {
    height: '100%',
    color: '#fff',
    display: 'flex',
    fontFamily: 'Noto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    marginBottom: 120,
    fontSize: 14,
    logoImg: {
      display: 'block',
      width: 100,
    },
  },
  title: {
    fontSize: 48,
    width: '100vw',
    height: 90,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    margin: '0 auto',
    v1: {
      color: '#fff',
    },
    v2: {
      color: '#000',
      marginTop: 110,
    },
  },
  subTitle: {
    fontSize: 24,
    height: 50,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    margin: '20px auto 0',
    v1: {
      color: '#fff',
    },
    v2: {
      color: '#000',
    },
  },
  qrCodeImage: {
    margin: '64px auto 0',
    width: 224,
  },
  footer: {
    fontSize: 14,
    marginTop: 210,
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const versionDropdown = css({
  marginBottom: 8,
});
