import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Row, Col } from 'components/UIExternal';
import { displayProperty } from 'utils';
import { bankDetailsInfo } from './config';

export const OrderDetailsBankPaymentDetails = ({ bankDetails }) => {
  const { getText } = useTranslations();

  return (
    <section className="order-details-payment-bank-details" data-id="order-details-bank-payment-information">
      <h4 className="order-details-payment-bank-details-title">{getText('bankDetails')}</h4>
      <Row gap={12}>
        {bankDetailsInfo.map((el, ind) => (
          <Col key={ind} lg={4} md={4} sm={6} xs={12}>
            <h5>{getText(`${el.label}`)}</h5>
            <span className="bankDetailsProperty">{displayProperty(el, bankDetails)}</span>
          </Col>
        ))}
      </Row>
    </section>
  );
};

OrderDetailsBankPaymentDetails.propTypes = {
  bankDetails: PropTypes.object,
};
