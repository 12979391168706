import React, { useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { useAddresses, addAddress, updateAddress, useLocations } from '@oneecosystem/dealshaker-core';
import { useTheme } from 'emotion-theming';
import { toast } from 'react-hot-toast';
import { pick, isNumber } from '@veraio/core';
import { Row, Col, Input, Form, InputNumber, Spin } from 'antd';
import { Button, useUrlParams, Dropdown } from 'components/UIExternal';
import { CountryDropdown } from 'components/ui';
import { Routes } from 'enums';
import { mb } from 'assets/css/globalCSS';
import { getCountryPhoneCodeOptions } from 'utils';
import useError from 'services/errorHandling/useError';
import {
  titleForm,
  addressFormWrapper,
  nameInput,
  countryStateWrapper,
  countryInput,
  stateInput,
  cityPostcodeWrapper,
  cityInput,
  postCodeInput,
  addressLineInput,
  phoneInput,
  contactPersonInput,
  formButtons,
  blackTextLink,
  phoneCodeStyles,
  countryDropdownStyles,
} from './addressesStyles';

const DeliveryAddressForm = ({ location, history }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const countries = useLocations(locationsState => locationsState.countries);
  const { setError } = useError();
  const [form] = Form.useForm();
  const { queryParams, pathParams } = useUrlParams();
  const addresses = useAddresses();
  const isAdd = location.pathname === '/add-delivery-address';
  const address = isAdd ? {} : addresses.find(el => el?.id === Number(pathParams?.addressId)) ?? {};
  const phoneCodes = getCountryPhoneCodeOptions(countries);
  const phoneNumber = Number(address.phoneNumber?.substring(address.phoneNumber.indexOf(' ') + 1));
  const phoneCodeRef = useRef(address?.phoneNumber?.split(' ')[0]);

  const submitForm = async values => {
    const payload = {
      ...pick(values, ['name', 'state', 'city', 'postcode', 'street', 'contactPerson', 'countryId']),
      phoneNumber: `${values.phoneCode.value} ${values.phone}`,
      ...(!isAdd && { id: Number(pathParams?.addressId) }),
    };

    const method = isAdd ? addAddress : updateAddress;
    const err = await method(payload);
    if (err) return setError(err);

    toast.success(getText(isAdd ? 'addressAddedSuccessfully' : 'addressEditedSuccessfully'));
    history.push(queryParams?.redirectUrl ?? '/my-profile/addresses');
  };

  const handleCountryChange = countryId => {
    const foundCountryData = countries?.find(country => country.id === countryId);
    const foundPhoneCode = phoneCodes?.find(code => code.value === foundCountryData.phoneCode);

    phoneCodeRef.current.changeValue(foundPhoneCode);
    form.setFieldsValue({ phoneCode: foundPhoneCode.value });
  };

  return (
    <div className="content-container">
      <h2 className={titleForm(theme)}>{isAdd ? getText('addNewAddress') : getText('editAddress')}</h2>
      {address && countries && phoneCodes ? (
        <>
          <Row>
            <Col lg={12} span={24} className={addressFormWrapper(theme)}>
              <Form
                form={form}
                onFinish={submitForm}
                layout="vertical"
                initialValues={{
                  ...address,
                  countryId: countries?.find(country => country.name === address.country)?.id,
                  phoneCode: phoneCodes?.find(code => Number(code.value) === Number(address.phoneNumber?.split(' ')[0])),
                  phone: isNumber(phoneNumber) && phoneNumber,
                }}
              >
                <Form.Item
                  label={getText('name')}
                  name="name"
                  className={nameInput}
                  rules={[
                    {
                      required: true,
                      message: getText('fieldIsRequired'),
                    },
                    {
                      min: 3,
                      message: getText('nameLongerThanSymbolsCount', { count: 3 }),
                    },
                    {
                      max: 600,
                      message: getText('nameShorterThanSymbolsCount', { count: 600 }),
                    },
                  ]}
                >
                  <Input type="text" placeholder={getText('enterAddressName')} />
                </Form.Item>
                <div className={countryStateWrapper}>
                  <Form.Item
                    label={getText('country')}
                    name="countryId"
                    className={countryInput}
                    rules={[
                      {
                        required: true,
                        message: getText('fieldIsRequired'),
                      },
                    ]}
                  >
                    <CountryDropdown noClear className={countryDropdownStyles} onChange={handleCountryChange} placeholder={getText('selectCountry')} />
                  </Form.Item>
                  <Form.Item
                    label={getText('stateProvince')}
                    name="state"
                    className={stateInput}
                    rules={[
                      {
                        required: false,
                        max: 64,
                        message: getText('stateNameShorterThanSymbolsCount', { count: 64 }),
                      },
                    ]}
                  >
                    <Input type="text" placeholder={getText('selectProvince')} />
                  </Form.Item>
                </div>
                <div className={cityPostcodeWrapper}>
                  <Form.Item
                    label={getText('city')}
                    name="city"
                    className={cityInput}
                    rules={[
                      {
                        required: true,
                        message: getText('fieldIsRequired'),
                      },
                      {
                        max: 64,
                        message: getText('countryNameShorterThanSymbolsCount', { count: 64 }),
                      },
                    ]}
                  >
                    <Input type="text" placeholder={getText('enterCity')} />
                  </Form.Item>
                  <Form.Item
                    label={getText('postcode')}
                    name="postcode"
                    className={postCodeInput}
                    rules={[
                      {
                        required: true,
                        message: getText('fieldIsRequired'),
                      },
                      {
                        max: 84,
                        message: getText('postCodeShorterThanSymbolsCount', { count: 84 }),
                      },
                      {
                        min: 3,
                        message: getText('postCodeLongerThanSymbolsCount', { count: 3 }),
                      },
                    ]}
                  >
                    <Input type="text" placeholder={getText('postcode')} />
                  </Form.Item>
                </div>
                <Form.Item
                  label={getText('address-line')}
                  name="street"
                  className={addressLineInput}
                  rules={[
                    {
                      required: true,
                      message: getText('fieldIsRequired'),
                    },
                    {
                      max: 400,
                      message: getText('streetNameNotExceedNumber', { number: 400 }),
                    },
                  ]}
                >
                  <Input type="text" placeholder={getText('merchantAddress')} />
                </Form.Item>
                <label>{getText('phoneNumber')}</label>
                <div className={phoneInput}>
                  <Form.Item name="phoneCode">
                    <Dropdown
                      noClear
                      ref={phoneCodeRef}
                      placeholder={getText('phoneCode')}
                      options={phoneCodes}
                      uniqueKey="value"
                      displayKey="label"
                      withSearch
                      className={phoneCodeStyles}
                      renderSelected={selected => selected && `+ ${selected?.value}`}
                    />
                  </Form.Item>
                  <Form.Item name="phone"
                    rules={[
                      {
                        pattern: /^(?:\d{0,60})$/,
                        message: getText('phoneNumberNotExceedNumber', { number: 60 }),
                      },
                    ]}>
                    <InputNumber className="phone-number" placeholder={getText('enterPhoneNumber')} />
                  </Form.Item>
                </div>
                <Form.Item label={getText('contactPerson')} name="contactPerson" className={contactPersonInput}>
                  <Input type="text" placeholder={getText('enterFirstLastName')} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col lg={12} span={24} className={formButtons}>
              <Button
                type="link"
                small
                className={blackTextLink(theme)}
                linkTo={queryParams?.redirectUrl ?? `${Routes.myProfile}/addresses`}
              >
                {getText('goBack')}
              </Button>

              <Button type="primary" small onClick={() => form.submit()}>
                {location.pathname === '/add-delivery-address' ? getText('addAddress') : getText('editAddress')}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <div className={`content-container alignTextCenter ${mb({ lg: 100, span: 50 })}`}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default DeliveryAddressForm;
