import React from 'react';
import { Routes, TRANSACTION_STATUS_COLOR, TRANSACTION_STATUS_INVERSE } from 'enums';
import { MerchantOrderIdLink, PriceLabel, Tag } from 'components';
import { Link } from 'react-router-dom';
import { CRYPTO_CURRENCIES } from '@oneecosystem/dealshaker-core';
import { validDate } from 'utils';
import moment from 'moment';
import { textLinkStyle, textMutedStyle, transactionsRowCentered, transactionsRowStatus } from './styles';

export const CLEARING_FILTER_KEYS = {
  currency: 'currency',
  search: 'searchText',
};

export const SETTLEMENT_FILTER_KEYS = {
  statusId: 'statusId',
  currency: 'currency',
  search: 'searchText',
};

export const CLEARING_SETTLEMENT_SORTING_KEYS = {
  sorting: 'transactionsSorting',
};

export const CLEARING_COLUMNS = (theme, getText = () => {}, isMerchant = true, accountUsername) => {
  let columns = [
    {
      name: getText('transactionId'),
      render: row => (
        <>
          {`#${row.id}`}
          {row.externalId && <span>{`#${row.externalId}`}</span>}
        </>
      ),
      width: 110,
    },
    {
      name: getText('dateCreation'),
      value: 'date',
      dateTimeFormat: 'DD-MM-YYYY HH:mm',
      width: 120,
    },
    {
      name: getText('buyer'),
      columnId: 'buyer',
      render: row =>
        row.merchantNickname === accountUsername && (
          <div>
            <strong>{row.buyerName}</strong>
            <br />
            <span className={textMutedStyle(theme)}>{row.buyerPhone}</span>
            <br />
            <span className={textMutedStyle(theme)}>{row.buyerEmail}</span>
            <br />
            <span className={textMutedStyle(theme)}>{row.buyerNickname}</span>
          </div>
        ),
    },
    {
      name: getText('merchant'),
      columnId: 'merchant',
      render: row => (
        <div>
          <strong>{row.merchantName}</strong>
          <br />
          <span className={textMutedStyle(theme)}>{row.merchantPhone}</span>
          <br />
          <span className={textMutedStyle(theme)}>{row.merchantEmail}</span>
          <br />
          <span className={textMutedStyle(theme)}>{row.merchantNickname}</span>
        </div>
      ),
    },
    {
      name: getText('amount'),
      render: row => (
        <PriceLabel
          fiat={row.originalAmount}
          fiatOriginCurrency={row.originalCurrency}
          crypto={row.oneCoinAmount || row.oespAmount}
          cryptoDesiredCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
          cryptoOriginCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
          fiatOnly={false}
          ignoreCryptoRestricted
        />
      ),
    },
    {
      name: getText('orderId'),
      render: row => <MerchantOrderIdLink merchantNickname={row.merchantNickname} orderId={row.orderId} />,
      width: 100,
    },
    {
      name: getText('reason'),
      columnId: 'reason',
      value: 'reason',
    },
    {
      name: getText('status'),
      render: row => (
        <div className={transactionsRowStatus}>
          <Tag type={TRANSACTION_STATUS_COLOR[row.status]} fontSize="0.725rem">
            {getText(TRANSACTION_STATUS_INVERSE[row.status])}
          </Tag>
          {row.clearingDate && (
            <div className="clearing-date">
              <span>on {validDate(row.clearingDate) && moment(row.clearingDate).format('DD MMM YYYY')}</span>
            </div>
          )}
        </div>
      ),
      width: 150,
    },
  ];

  if (!isMerchant) columns = columns.filter(c => c.columnId !== 'buyer');

  return columns;
};

export const SETTLEMENT_COLUMNS = (theme, getText = () => {}) => [
  {
    name: getText('transactionId'),
    render: row => (
      <>
        <div>
          {`#${row.id}`}
          <br />
          {row.externalId && <span style={{ color: theme.gray300 }}>Ext #{row.externalId}</span>}
        </div>
      </>
    ),
  },
  {
    name: getText('status'),
    render: row => (
      <div className={transactionsRowCentered}>
        <Tag type={TRANSACTION_STATUS_COLOR[row.statusId]} fontSize="0.725rem">
          {getText(TRANSACTION_STATUS_INVERSE[row.statusId])}
        </Tag>
      </div>
    ),
    width: 160,
  },
  {
    name: getText('settlementDate'),
    value: 'settlementDate',
    dateTimeFormat: 'DD MMM YYYY',
  },
  {
    name: getText('amount'),
    render: row => (
      <PriceLabel
        fiat={row.originalAmount}
        fiatOriginCurrency={row.originalCurrency}
        crypto={row.oneCoinAmount || row.oespAmount}
        cryptoDesiredCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
        cryptoOriginCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
        fiatOnly={false}
        ignoreCryptoRestricted
      />
    ),
  },
  {
    name: getText('bundledTransactions'),
    render: row => (
      <Link className={textLinkStyle(theme)} to={`${Routes.myProfile}/settlement/${row.id}/bundled-transactions`}>
        {getText('viewDetails')}
      </Link>
    ),
  },
];
