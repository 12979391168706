import React from 'react';
import moment from 'moment';
import { getText, getDynamicTranslation } from '@veraio/strank';
import { findFiatCurrency, DEFAULT_FIAT_CURRENCY } from '@oneecosystem/dealshaker-core';
import { Dropdown } from 'components/UIExternal';
import { PromoCodePdf } from 'components/pdf';
import { promoCodeType, promoCodeStatus } from 'enums';
import { changeStatusOptions, codeStatuses } from 'enums/MerchantOffice';
import CodeTag from './CodeTag';
import { status } from './styles';

export const codeListColumns = (theme, handleCodeStatus) => [
  {
    name: getText('business'),
    render: row => getDynamicTranslation(row?.businessDetails)?.name,
  },
  {
    name: getText('createdOn'),
    render: row => <div className="bolder-text">{moment(row?.createdOn).format('DD/MM/YYYY · hh:mm')}</div>,
    width: 170,
  },
  {
    name: getText('status'),
    width: 140,
    render: row => (
      <div className={`${status(row?.statusId, theme)}`}>
        <span className="dot" />
        <span>{codeStatuses[row?.statusId]}</span>
      </div>
    ),
  },
  {
    name: getText('usedBy'),
    render: row => <CodeTag {...row} />,
    width: 200,
  },
  {
    name: getText('usedOn'),
    render: row => <div>{row?.usedOn ? moment(row?.usedOn).format('DD/MM/YYYY') : 'N/A'}</div>,
    width: 110,
  },
  {
    name: getText('value'),
    width: 100,
    render: row => {
      const promoCodeCurrency = findFiatCurrency(row?.currencyCode) ?? DEFAULT_FIAT_CURRENCY;

      return (
        <div className="bolder-text">
          {row?.value} {row?.typeId === promoCodeType.percent ? '%' : promoCodeCurrency.symbol}
        </div>
      );
    },
  },
  {
    name: 'PDF',
    width: 100,
    render: row => <PromoCodePdf promoCode={row} />,
  },
  {
    name: getText('statusAction'),
    width: 170,
    render: row => (
      <Dropdown
        options={changeStatusOptions}
        disabled={row?.statusId === promoCodeStatus.disabled || row?.statusId === promoCodeStatus.used}
        placeholder={getText('changeStatus')}
        small
        noClear
        onChange={e => handleCodeStatus(Number(e?.value), row?.id)}
      />
    ),
  },
];
