import { calculateDealPrice } from '@oneecosystem/dealshaker-core';
import { getReq, postReq, putReq, deleteReq } from 'services/axios/makeRequest';
import apiRoutes from 'config/apiRoutes';
import {
  merchantDealsParams,
  randomDealsParams,
  searchParams,
  connectedDealsParams,
  getAvailableDealsToConnectParams,
  getDealListingParams,
  dealListingResponse,
  getMerchantDealsInfoParams,
  dealsCategoryFeaturedParams,
  dealListingAvailableDealsResponse,
  bestSellingDealsParams,
  promoDealsParams,
  basedOnInterestsDealsParams,
  getCreateDealStepDataResponse,
} from 'services/models/deals';
import envConfig from 'config/envConfig';

const baseUrl = `${envConfig.apiAddress}/api/Deals`;

export const getDealDetails = async dealName => {
  const [res, err] = await getReq(`${baseUrl}/Details/${dealName}`);
  return [res && calculateDealPrice(res), err];
};

export const getDealsPublicProfile = async options => {
  const [res, err] = await getReq(`${baseUrl}/GetMerchantProfileDeals/?${getDealListingParams(options)}`);
  return [res && dealListingResponse(res), err];
};

export const getMerchantDeals = async (businessId, options) => {
  const params = merchantDealsParams(options);
  const [res, err] = await getReq(`${baseUrl}/GetMerchantOfficeDeals/${businessId}?${params}`);
  return [res && dealListingResponse(res), err];
};

export const getRandomDeals = async options => {
  const [res, err] = await getReq(`${baseUrl}/RandomPromotions?${randomDealsParams(options)}`);
  return [res && dealListingResponse(res), err];
};

export const searchForDeals = options => getReq(`${baseUrl}/Search?${searchParams(options)}`);

export const getBestSellingDeals = async params => {
  const [res, err] = await getReq(`${baseUrl}/BestSellers?${bestSellingDealsParams(params)}`);
  return [res && dealListingResponse(res), err];
};

export const getBusinessesDealFromSearch = (businessId, dealTitle) =>
  getReq(`${baseUrl}/List`, { businessId, SearchText: dealTitle });

export const getAvailableDealsToConnect = async (dealId, params) => {
  const [res, err] = await getReq(
    `${baseUrl}/AvailableConnectedDeals/${dealId}?${getAvailableDealsToConnectParams(params)}`,
  );
  return [res && dealListingAvailableDealsResponse(res), err];
};

export const addConnectedDeals = payload => putReq(`${baseUrl}/UpdateConnectedDeals`, payload);

export const removeConnectedDeal = payload => deleteReq(`${baseUrl}/RemoveConnectedDeal`, payload);

export const getCreateDealStepData = async (stepNumber, id) => {
  const [res, err] = await getReq(`${baseUrl}/GetStep${stepNumber}?dealId=${id}`);
  return [res && getCreateDealStepDataResponse(stepNumber, res), err];
};

export const getCurrentWizardStep = dealId => getReq(`${baseUrl}/GetCurrentWizardStep?dealId=${dealId}`);

export const updateDealStepData = (data, stepNumber, id) =>
  putReq(`${baseUrl}/UpdateStep${stepNumber}`, { id, ...data });

export const discardDraftDeal = id => putReq(`${baseUrl}/DiscardDraft/${id}`);

export const createDeal = data => postReq(`${baseUrl}/CreateDeal`, data);

export const changeDealStatus = (id, statusId) => putReq(`${baseUrl}/ChangeStatus`, { id, statusId });

export const cloneDeal = id => postReq(`${baseUrl}/Clone`, { id });

export const getDealsPriceRanges = async (payload, setError) => {
  const result = await getReq(`${apiRoutes.DEALS_LIST_PRICE_RANGES}`, {}, { params: payload });
  result[1] && setError && setError(result[1]);
  return result[0];
};

export const getDealsLeftForBusiness = async (businessId, setError) => {
  const result = await getReq(apiRoutes.DEALS_LEFT_BUSINESS(businessId));
  result[1] && setError && setError(result[1]);
  return result[0];
};

export const getAllConnectedDeals = async (dealName, params) => {
  const [res, err] = await getReq(`${baseUrl}/ListConnectedDeals/${dealName}?${connectedDealsParams(params)}`);
  return [res && dealListingResponse(res), err];
};

export const getPromoDeals = async options => {
  const [res, err] = await getReq(`${baseUrl}/PromoDeals?${promoDealsParams(options)}`);
  return [res && dealListingResponse(res), err];
};

export const getDealsBasedOnInterest = async options => {
  const [res, err] = await getReq(`${baseUrl}/BasedOnInterests?${basedOnInterestsDealsParams(options)}`);
  return [res && dealListingResponse(res), err];
};

export const getDealListing = async options => {
  const [res, err] = await getReq(`${baseUrl}/List?${getDealListingParams(options)}`);
  return [res && dealListingResponse(res), err];
};

export const getMerchantDealsInfo = async options => {
  const [res, err] = await getReq(`${baseUrl}/ListInfo?${getMerchantDealsInfoParams(options)}`);
  return [res, err];
};

export const getCategoryFeaturedDeals = options =>
  getReq(`${baseUrl}/Featured?${dealsCategoryFeaturedParams(options)}`);
