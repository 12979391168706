/* eslint-disable react/no-unused-prop-types */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin } from 'antd';
import DropdownSearchBar from './DropdownSearchBar';
import useOptions from './useOptions';
import { dropdownSearch, infiniteScroll } from './styles';

const OptionsList = props => {
  const { withSearch, className, containerRef, theme } = props;
  const searchBarRef = useRef();

  const {
    renderList,
    filterResults,
    searchRef,
    needInfiniteScroll,
    allOptionsLength,
    optionsLength,
    fetchMoreResults,
  } = useOptions(props, searchBarRef);

  const search = withSearch && (
    <DropdownSearchBar
      ref={searchBarRef}
      inputRef={searchRef}
      placeholder="Search"
      onInputChange={filterResults}
      className={dropdownSearch(theme)}
    />
  );

  const container = (
    <div role="presentation" onClick={event => event.stopPropagation()} className={className} ref={containerRef}>
      {search}
      {/* Wait for container to be mounted into the DOM
      and after that mount infinite scroll */}
      {containerRef.current.isConnected && needInfiniteScroll && (
        <InfiniteScroll
          dataLength={optionsLength}
          next={fetchMoreResults}
          hasMore={!!optionsLength && allOptionsLength > optionsLength}
          scrollableTarget={containerRef.current}
          loader={
            <div className={infiniteScroll}>
              <Spin size="default" loading />
            </div>
          }
        >
          {renderList}
        </InfiniteScroll>
      )}
      {!needInfiniteScroll && renderList}
    </div>
  );

  return container;
};

OptionsList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  displayKey: PropTypes.string,
  uniqueKey: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object, PropTypes.bool]),
  multiSelect: PropTypes.bool,
  withSearch: PropTypes.bool,
  isOpen: PropTypes.bool,
  theme: PropTypes.object,
  containerRef: PropTypes.object,
  className: PropTypes.string,
};

export default OptionsList;
