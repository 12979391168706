import { css } from 'emotion';
import { Phone } from 'assets/css/breakpoints';

export const orderDetailsHeaderContainer = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '2em 0',

  '& .side': {
    flex: '1 0 0',
  },
  '& .order-details-status-container': {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',

    '& .order-details-status': {
      fontSize: '0.8rem',
      textTransform: 'uppercase',
      padding: '0 0.5em',
      borderRadius: '5rem',
    },
  },
  '& .order-details-actions-container': {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
  },
});

export const orderDetailsCancelModal = css({
  '& .order-details-cancel-modal-actions': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1em',
    marginTop: '2em',
  },
});

export const hideOnDesktop = css({
  display: 'none',
  fontSize: '1rem',
  [Phone]: {
    display: 'block',
  },
});
