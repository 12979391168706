import { css } from 'emotion';

export const modalContainer = css({
  '& .ant-modal-content': {
    padding: '1.25em 0px',
    width: '32.5rem',
  },
  '& .ant-modal-body': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .confirmation-modal-text': {
    marginBottom: '1em',
    textAlign: 'center',
  },
  '& .confirmation-modal-buttons *:first-of-type': {
    marginRight: '4em',
  },
});
