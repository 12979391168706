import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const reviewsContainer = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.gray500}`,
    borderRadius: 3,
    padding: '1.25em 0',
    '& p': {
      marginBottom: '0.25em',
    },
    '& > div > div': {
      [Mobile]: {
        flexWrap: 'wrap',
      },
      '@media (max-width: 335px)': {
        textAlign: 'center',
        justifyContent: 'center',
      },
    },
  });

export const ratingContainer = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const merchantRatingsContainer = css({
  display: 'flex',
  gap: '1em',
  flexGrow: 1,
  alignContent: 'center',
  justifyContent: 'space-around',
  padding: '0 1.875em',
});

export const merchantRatingWrapper = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& .rating': {
      display: 'block',
      fontSize: '2.375rem',
      fontWeight: 500,
      color: theme.gray100,
    },
    '& .description': {
      display: 'block',
      fontSize: '0.75rem',
      color: theme.gray100,
    },
    '& .ant-rate': {
      marginTop: '0.5em',
    },
    '& .ant-rate-star:not(:last-child)': {
      marginRight: '0.0625em',
    },
  });

export const starsContainer = css({
  flexGrow: 5,
  padding: '0 1.875em',
  '& > div': {
    paddingLeft: '0',
    '& > a': {
      textWrap: 'wrap',
    },
  },
});

export const ratingLabel = theme =>
  css({
    fontSize: '2.375rem',
    lineHeight: '2.875rem',
    fontWeight: 500,
    color: theme.gray100,
    display: 'block',
  });

export const starRatings = theme =>
  css({
    '&.ant-rate': {
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      marginBottom: '0.5em',
      color: theme.yellow,
      [Mobile]: {
        textWrap: 'nowrap',
      },
    },
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
    color: theme.gray300,
    display: 'block',
    [Mobile]: {
      color: theme.gray100,
    },
  });
