import { css } from 'emotion';

export const brandColorPage23Container = theme =>
  css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1rem',
    '& .orangeOnBlue, .whiteOnOrange, .orangeOnBlack, .redOnGreen, .blueOnOrange, .whiteOnBlue, .whiteOnBlack, .violetteOnNavy': {
      padding: '1em',
      marginBottom: '1em',
      '& p': { fontSize: '1.5rem' },
      '& .bold': { fontWeight: 600 },
    },
    '& .orangeOnBlue': { backgroundColor: theme.blue, color: theme.primary_yellow },
    '& .whiteOnOrange': { backgroundColor: theme.primary_yellow, color: theme.white },
    '& .orangeOnBlack': { backgroundColor: theme.gray100, color: theme.primary_yellow },
    '& .redOnGreen': { backgroundColor: theme.green3, color: theme.red },
    '& .blueOnOrange': { backgroundColor: theme.primary_yellow, color: theme.blue },
    '& .whiteOnBlue': { backgroundColor: theme.blue, color: theme.white },
    '& .whiteOnBlack': { backgroundColor: theme.gray100, color: theme.white },
    '& .violetteOnNavy': { backgroundColor: theme.blue2, color: theme.purple2 },
  });
