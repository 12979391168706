import { useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import qs from 'query-string';
import { isNil, omitBy } from 'lodash-es';
import useError from 'services/errorHandling/useError';
import { getReq } from './makeRequest';

export const usePageNumberPaging = (baseUrl, pageSize, filter, doLoadOnCall = true) => {
  const { setError } = useError();
  const [pagingInfo, setPagingInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [forceExecute, setForceExecute] = useState(doLoadOnCall);
  const [queryFilter, setQueryFilter] = useState({
    ...filter,
    pageNumber: 1,
    pageSize,
  });
  const [loadedData, setLoadedData] = useState([]);

  const [{ error }, execute] = useAxios(
    {
      url: `${baseUrl}?${qs.stringify(omitBy(queryFilter, isNil), { arrayFormat: 'index' })}`,
      // paramsSerializer: {
      //   indexes: true, // use brackets with indexes
      // },
    },
    { manual: true },
  );

  useEffect(() => {
    if (doLoadOnCall || forceExecute) {
      setIsLoading(true);
      execute().then(res => {
        const { items, totalCount } = res.data;

        setPagingInfo({
          count: totalCount,
          totalPages: totalCount / pageSize,
        });

        setLoadedData([...loadedData, ...items]);
        setIsLoading(false);
      });
    }
  }, [queryFilter, doLoadOnCall]);

  useEffect(() => {
    if (error?.response) setError(error.response);
  }, [error]);

  const loadNewPage = pageNumber => {
    if (pageNumber !== queryFilter?.pageNumber) {
      setQueryFilter({
        ...queryFilter,
        pageNumber,
        pageSize,
      });
    }
  };

  const reloadAllLoadedPages = () => {
    const pageSize = queryFilter.pageNumber * queryFilter?.pageSize;
    const pageNumber = 1;
    const modifiedQueryFilter = {
      pageNumber,
      pageSize,
      ...filter,
    };
    setIsLoading(true);
    getReq(baseUrl, modifiedQueryFilter).then(res => {
      setLoadedData(res[0]?.items || []);
      setIsLoading(false);
      setPagingInfo({
        count: res[0]?.totalCount,
        totalPages: res[0]?.totalCount / pageSize,
      });
    });
  };

  const loadNewFilter = filter => {
    setLoadedData([]);
    setQueryFilter({
      ...filter,
      pageNumber: 1,
      pageSize,
    });
    setForceExecute(true);
  };

  return [loadedData, loadNewPage, loadNewFilter, pagingInfo, isLoading, reloadAllLoadedPages];
};
