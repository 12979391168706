import React, { useState } from 'react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Modal } from 'antd';
import { isDesktop, isMobile } from 'react-device-detect';
import { Button } from 'components/UIExternal';
import { BorderlessShadowlessGrayBox, StatusProgressIcon } from 'components/ui';
import { Routes } from 'enums';
import { getTimeFromNow } from 'utils';
import { mr, mb, mt } from 'assets/css/globalCSS';
import { savedToDraftWrapper, steps } from './styles';

const StepsComponent = ({ currentStep, setManualStep, lastUpdate, stepProgress, dealId }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModalVisible = () => setIsModalVisible(!isModalVisible);

  const setStep = step => {
    if (step <= stepProgress + 1) setManualStep(step);
  };

  const onMobileAndCurrentStep = step => (isMobile && currentStep === step) || isDesktop;

  return (
    <>
      <div className={steps(theme)}>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setStep(1)}
          data-id="firstStep"
          className={`${currentStep === 1 && 'current'} ${currentStep > 1 && 'finished'}`}
        >
          <p>1</p>
          {onMobileAndCurrentStep(1) && <label className="step">{getText('deal-title-and-description')}</label>}
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setStep(2)}
          data-id="secondStep"
          className={`${currentStep === 2 && 'current'} ${currentStep > 2 && 'finished'}`}
        >
          <p>2</p>
          {onMobileAndCurrentStep(2) && <label className="step step2">{getText('deal-type-and-price')}</label>}
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setStep(3)}
          data-id="thirdStep"
          className={`${currentStep === 3 && 'current'} ${currentStep > 3 && 'finished'}`}
        >
          <p>3</p>
          {onMobileAndCurrentStep(3) && <label className="step step3">{getText('gallery')}</label>}
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setStep(4)}
          data-id="fourthStep"
          className={`${currentStep === 4 && 'current'} ${currentStep > 4 && 'finished'}`}
        >
          <p>4</p>
          {onMobileAndCurrentStep(4) && <label className="step step4">{getText('merchant-addresses')}</label>}
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={() => setStep(5)}
          data-id="fifthStep"
          className={`${currentStep === 5 && 'current'} ${currentStep > 5 && 'finished'}`}
        >
          <p>5</p>
          {onMobileAndCurrentStep(5) && <label className="step step5">{getText('promoteDeal')}</label>}
        </div>
      </div>
      {stepProgress > 0 && dealId && !isMobile && (
        <>
          <BorderlessShadowlessGrayBox className={savedToDraftWrapper} theme={theme}>
            <div className="flex-column flex-center">
              <StatusProgressIcon status="success" />
              <label>{getText('savedToDrafts')}</label>
              <p>{getTimeFromNow(lastUpdate)}</p>
            </div>
          </BorderlessShadowlessGrayBox>
          <Button type="link" className={mt({ lg: 16, md: 16, xs: 16 })} fullWidth onClick={toggleModalVisible}>
            {getText('discardDraft')}
          </Button>
        </>
      )}
      <Modal
        open={isModalVisible}
        onOk={null}
        footer={
          <Button type="link" small onClick={toggleModalVisible}>
            {getText('cancel')}
          </Button>
        }
        onCancel={toggleModalVisible}
      >
        <div className="flex flex-column flex-center">
          <h2 className={`${mb({ lg: 30, md: 30, xs: 30 })} ${mt({ lg: 30, md: 30, xs: 30 })}`}>
            {getText('areYouSureYouWantToDiscardDraft')}
          </h2>
          <div className="flex flex-center">
            <Button
              type="secondary"
              small
              linkTo={`${Routes.merchantOffice}${Routes.myDeals}`}
              className={mr({ lg: 10, md: 10 })}
            >
              {getText('discardChanges')}
            </Button>
            <Button type="primary" small linkTo={`${Routes.merchantOffice}${Routes.myDeals}`}>
              {getText('deleteDraft')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

StepsComponent.propTypes = {
  currentStep: PropTypes.number,
  stepProgress: PropTypes.number,
  dealId: PropTypes.number,
  lastUpdate: PropTypes.string,
  setManualStep: PropTypes.func,
};
export default StepsComponent;
