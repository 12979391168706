import React from 'react';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Button } from 'components/UIExternal';
import { userRoles } from 'enums';
import { GlobalStructureManagerInfo } from './components/GlobalStructureManagerInfo';
import { GlobalStructureFindManager } from './components/GlobalStructureFindManager';
import { globalStructureTop, globalStructureBottom } from './styles';

const applyLink = 'https://docs.google.com/forms/d/e/1FAIpQLSfc9NtgwkptM7c3jovQTquAIJCJQupmCH21yr5bCX9sxm7YZA/viewform';

export const OurGlobalStructure = () => {
  const theme = useTheme();
  const { getText } = useTranslations();

  return (
    <>
      <div className={globalStructureTop(theme)}>
        <div className="topContainer">
          <h1>{getText('ourGlobalStructure')}</h1>
          <p>{getText('ourGlobalStructureParagraphOne')}</p>
          <p>{getText('ourGlobalStructureParagraphTwo')}</p>
          <p>{getText('ourGlobalStructureParagraphThree')}</p>
        </div>
      </div>
      <div className="content-container">
        <GlobalStructureManagerInfo role={userRoles.DS_COUNTRY_MANAGER} />
        <GlobalStructureFindManager role={userRoles.DS_COUNTRY_MANAGER} />

        {/* when we have two roles for manage. One for country and one for area */}
        {/* <GlobalStructureManagerInfo role={userRoles.DS_AREA_MANAGER} /> */}
        {/* <GlobalStructureFindManager role={userRoles.DS_AREA_MANAGER} /> */}
      </div>
      <div className={globalStructureBottom(theme)}>
        <div className="content content-container">
          <h3 className="applyForCM">{getText('wantToBecomeManager')}</h3>
          <Button type="primary" linkTo={applyLink} className="applyButton">
            {getText('applyNow')}
          </Button>
        </div>
      </div>
    </>
  );
};
