import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { isMobile } from 'react-device-detect';
import { useTranslations } from '@veraio/strank';
import {
  Pagination,
  Deal,
  MerchantListCategoryFilter,
  Dropdown,
  NoDealsFound,
  useUrlParams,
  Modal,
  Button,
  SearchInput,
} from 'components';
import { findCategoryPath, setBreadcrumbs, useMerchantList, setCategories } from 'stores';
import { sortOptionsSelect } from 'enums';
import { setError, getDealsPublicProfile, getAllCategories } from 'services';
import {
  dealsTabContainer,
  dealsTabHead,
  dealsTabBody,
  dealList,
  dealItem,
  dealsSortFilter,
  filterContainer,
  filterButtonMobile,
  dealsFilter,
  dealsContainer,
  searchBar,
} from './styles';

const filterKeys = { searchText: 'searchText', dealsSorting: 'dealsSorting', categoryId: 'categoryId' };

export const DealsTab = ({ businessId }) => {
  const theme = useTheme();
  const paginationRef = useRef();
  const modalRef = useRef();
  const { queryParams } = useUrlParams();
  const { getText } = useTranslations();
  const [dealsList, setDealsList] = useState(null);
  const [allCategories, setAllCategories] = useState(null);
  const [categoryModal, setCategoryModal] = useState(false);
  const breadcrumbs = useMerchantList(store => store.breadcrumbs);

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const fetchAllCategories = async () => {
    const [res, err] = await getAllCategories();
    if (err) return setError(err);
    queryParams?.categoryId && setBreadcrumbs(findCategoryPath(res, queryParams?.categoryId));

    setAllCategories(res);
    return res;
  };

  const fetchDeals = async options => {
    const [res, err] = await getDealsPublicProfile({ ...options, businessId });
    err ? setError(err) : setDealsList(res.items);
    return res;
  };

  const handleOnBackCategories = () => {
    if (!breadcrumbs) return;

    setBreadcrumbs(breadcrumbs.slice(0, -1));
    setCategories(breadcrumbs[breadcrumbs?.length - 2]?.subCategories);
  };

  const handleClearFilters = () => {
    paginationRef.current?.applyFilters({ [filterKeys.categoryId]: null });
    setCategories({ categoryId: null });
    setBreadcrumbs({ breadcrumbs: null });
  };

  return (
    <>
      <div className={dealsTabContainer}>
        <div className={dealsTabHead}>
          <h3>{getText('deals')}</h3>
          <Dropdown
            placeholder="Select sorting"
            options={sortOptionsSelect(getText)}
            onChange={val => paginationRef.current?.applyFilters({ [filterKeys.dealsSorting]: val?.value })}
            className={dealsSortFilter}
          />
        </div>
        <div className={dealsTabBody}>
          {isMobile ? (
            <>
              <div>
                {queryParams?.categoryId &&
                  breadcrumbs?.length &&
                  breadcrumbs?.map(path => (
                    <span>
                      {!path?.subCategories?.length ? <b>{getText(path.name)}</b> : getText(path.name)}
                      {!!path?.subCategories?.length && ' > '}
                    </span>
                  ))}
              </div>
              <div className="cta-buttons">
                <Button
                  fullWidth
                  disabled={!!breadcrumbs?.length}
                  type={queryParams?.categoryId ? 'default' : 'info'}
                  onClick={() => setCategoryModal(true)}
                  className={filterButtonMobile(queryParams.categoryId, theme)}
                >
                  {getText('categories')}
                </Button>

                <Button fullWidth type="default" onClick={handleClearFilters}>
                  {getText('clear')}
                </Button>
              </div>
              <Modal
                show={categoryModal}
                positioned="bottom"
                ref={modalRef}
                {...(breadcrumbs?.length && { onBack: handleOnBackCategories })}
                modalTitle={getText('selectCategory')}
                onClose={() => setCategoryModal(false)}
              >
                <MerchantListCategoryFilter
                  withSearch={false}
                  onChange={paginationRef.current?.applyFilters}
                  filterKeys={filterKeys}
                  closeModal={() => setCategoryModal(false)}
                  allCategories={allCategories}
                />
              </Modal>
            </>
          ) : (
            <div className={dealsFilter}>
              <SearchInput
                allowClear
                className={searchBar(theme)}
                placeholder={getText('searchForDeals')}
                onPressEnter={value => paginationRef.current?.applyFilters({ [filterKeys.searchText]: value })}
              />
              <MerchantListCategoryFilter
                hasClear
                withSearch={false}
                onChange={paginationRef.current?.applyFilters}
                filterKeys={filterKeys}
                className={filterContainer}
                allCategories={allCategories}
              />
            </div>
          )}

          <div className={dealsContainer}>
            <div className={dealList(theme)}>
              {!isNil(dealsList) && !!dealsList?.length ? (
                dealsList?.map(deal => <Deal key={deal.id} deal={deal} className={dealItem} />)
              ) : (
                <NoDealsFound />
              )}
            </div>
            <Pagination
              ref={paginationRef}
              onChange={fetchDeals}
              pageSize={6}
              filterKeys={filterKeys}
              justify="center"
            />
          </div>
        </div>
      </div>
    </>
  );
};

DealsTab.propTypes = {
  businessId: PropTypes.number,
};
