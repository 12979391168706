import envConfig from 'config/envConfig';
import apiRoutes from 'config/apiRoutes';
import { postReq } from '../axios/makeRequest';

const baseUrl = `${envConfig.apiAddress}/api/Media`;

export const removeMedia = async id => postReq(`${baseUrl}/Remove/${id}`);

export const uploadFiles = payload => postReq(`${baseUrl}/AddBusinessMedia`, payload);

export const setDealYoutubeVideo = async (dealId, videoId, setError) => {
  const result = await postReq(`${apiRoutes.MEDIA_ADD_YOUTUBE_VIDEO(dealId)}`, { videoId });
  result[1] && setError && setError(result[1]);
  return result;
};
