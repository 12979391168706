import { css } from 'emotion';

export const brandGuideLinePageContainer = theme =>
  css({
    maxWidth: 910,
    margin: '1em auto',
    '& .element': {
      margin: '1em 0',
      backgroundColor: theme.gray700,
      borderTop: `1px solid ${theme.blue}`,
      borderBottom: `1px solid ${theme.blue}`,
    },
  });

export const brandGuideLineBackground = page =>
  css({
    minHeight: page?.bg ? 700 : 600,
    background: `url(${page.bg}) no-repeat`,
    backgroundSize: '100%',
    display: 'flex',
    padding: '0 4em',
  });

export const scrollToTopArrow = (windowOffSet, theme) =>
  css({
    color: theme.blue,
    position: 'fixed',
    bottom: windowOffSet ? 70 : 0,
    right: 40,
    opacity: windowOffSet ? 1 : 0,
    transition: 'opacity .6s ease-in-out, bottom .7s ease-in-out',
  });
