import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'components/UIExternal';
import { Routes } from 'enums';
import {
  categoriesHeading,
  categoriesItem,
  productsCategoriesItemButton,
  iconAndCategory,
  iconStyle,
  categoryItem,
} from './styles';

const CategoriesColumn = ({ category, isMain, childCategory, onChange, onClose }) => {
  const theme = useTheme();
  const { getText } = useTranslations();

  const subCategories = isMain
    ? category?.subCategories
    : [{ id: category?.id, name: getText('all') }, ...category?.subCategories];

  return (
    <div>
      {isMain && <h5 className={categoriesHeading(theme)}>{getText(category.name)}</h5>}
      <ul>
        {subCategories.map(subCategory => {
          const hasSubCategories = !!subCategory?.subCategories?.length;
          const buttonProps = hasSubCategories
            ? { onClick: () => onChange(subCategory) }
            : { linkTo: `${Routes.dealListingPage}?categoryId=${subCategory.id}`, onClick: onClose };

          return (
            <li key={subCategory?.id} className={categoriesItem(theme, subCategory?.id === childCategory?.id)}>
              <Button type="link" className={productsCategoriesItemButton} {...buttonProps}>
                <div className={iconAndCategory}>
                  {isMain ? (
                    <span className={categoryItem}>{getText(subCategory?.name)}</span>
                  ) : (
                    <span>{getText(subCategory?.name)}</span>
                  )}
                  {subCategory.icon && <FeatherIcon icon={subCategory.icon} size={20} />}
                </div>
                {hasSubCategories && <FeatherIcon icon="chevron-right" size={20} className={iconStyle(theme)} />}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

CategoriesColumn.propTypes = {
  category: PropTypes.object,
  isMain: PropTypes.bool,
  childCategory: PropTypes.object,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default CategoriesColumn;
