import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const baseDealCard = css({
  marginBottom: 20,
  width: '100%',
});

export const thumbnailContainer = isSelectable =>
  css({
    width: '100%',
    height: '6.5em',
    overflow: 'hidden',
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [Mobile]: {
      height: isSelectable ? '11.25rem' : '8.5625rem',
    },
    position: 'relative',
  });

export const checkbox = css({
  position: 'absolute',
  top: '0',
  right: '0.1875rem',
});

export const thumbnail = css({
  width: '100%',
  height: 'auto',
});

export const dealTitle = css({
  marginTop: '0.5em',
  fontSize: '1rem',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  minHeight: '2.8125em',
});

export const starRatings = theme =>
  css({
    marginRight: '0.625em !important',
    '&.ant-rate': {
      fontSize: '0.75rem',
      color: theme.gray100,
    },
  });

export const ratingsCountLabel = theme =>
  css({
    fontSize: '0.8125rem',
    color: theme.gray300,
    marginLeft: '0.3125em',
    textDecoration: 'underline',
  });

export const connectedDealPreview = isSelectable =>
  css({
    display: !isSelectable && 'flex',
    padding: '0',
    background: 'transparent',
    border: 'none',
    '&:hover': {
      background: 'transparent',
      transform: 'scale(1.05)',
    },
  });
