import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const mainContainer = theme =>
  css({
    width: '100%',
    maxWidth: '13.1875rem',
    paddingLeft: '1.25em',
    boxShadow: `inset 1px 0 0 ${theme.gray500}`,
    marginBottom: '1.25em',
    '& .btn-and-img': {
      flexDirection: 'column',
    },
    flexDirection: 'column',
    '& .ant-btn': {
      width: '100%',
      justifyContent: 'center',
      marginTop: '3.75em',
    },
    '& h2': {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    [Mobile]: {
      boxShadow: 'none',
      paddingLeft: 0,
      '& h2': {
        fontSize: '1.1875rem',
      },
    },
  });

export const titleWrapper = css({
  display: 'flex',
  marginBottom: '1.25em',
  justifyContent: 'space-between',
  '& button': {
    border: 'none',
    background: 'transparent',
    marginLeft: 'auto',
    '& span': {
      fontSize: '1.5625rem',
    },
  },
});

export const dealsContainer = css({
  [Mobile]: {
    width: '100%',
    display: 'flex',
    overflowX: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const noDealsContainer = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [Mobile]: {
      alignItems: 'flex-start',
    },
    '& div': {
      textAlign: 'center',
    },
    '& img': {
      marginBottom: '1.375em',
    },
    '& label': {
      color: theme.gray200,
      display: 'block',
    },
    '& button': {
      border: 'none',
      background: 'transparent',
      color: theme.blue2,
      textDecoration: 'underline',
      marginTop: '0.5em',
      fontSize: '0.8125rem',
    },
    '& button:hover': {
      color: theme.blue,
    },
  });

export const singleDealContainer = css({
  [Mobile]: {
    width: '14.9375rem',
    minWidth: '14.9375rem',
    marginRight: '1.5625em',
  },
});

export const connectedDealsModal = css({
  '& .ant-modal-header': {
    borderBottom: 'none',
    paddingBottom: '0.75em',
  },
  '& .ant-modal-body': {
    paddingTop: 0,
  },
});

export const buttonsWrapper = theme =>
  css({
    marginTop: '1.875em',
    paddingTop: '1em',
    borderTop: `1px solid ${theme.gray500}`,
    display: 'flex',
  });

export const availableDealsContainer = css({
  marginTop: '1.875em',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill,minmax(11.5625em, 1fr))',
  columnGap: '1.5em',
  rowGap: '1.5em',
});

export const popoverWrapper = theme =>
  css({
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '0px !important',
    backgroundColor: theme.white,
    borderRadius: 4,
    '& .ant-popover-inner-content': {
      padding: 0,
    },
    '& button': {
      display: 'block',
      border: 'none',
      background: theme.white,
      padding: '0.5625em 1em',
      fontSize: '0.75rem',
    },
  });
