import React from 'react';
import { useTheme } from 'emotion-theming';
import { isPlainObject, isArray } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { loginWithPassword } from '@oneecosystem/authenticate';
import toast from 'react-hot-toast';
import { Popover, Form, Input } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'components/UIExternal';
import { mb, mr } from 'assets/css/globalCSS';
import loginImage from 'assets/images/illy.svg';
import { anonymousMiniUserProfileMenu, userDefaultImageIcon, popoverWrapper } from './styles';

const AnonymousUserMiniProfile = ({ userRef }) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const [form] = Form.useForm();

  const handleLogin = async data => {
    const res = await loginWithPassword(data);
    const err =
      // Error from our api
      res?.errorDescription ||
      // Keycloak error
      (isArray(res) && res[0]?.message && 'Incorrect username or password') ||
      // Error from sts identity
      (isPlainObject(res?.errors) && Object.values(res.errors).map(el => el[0]));
    //     Error from sts identity                                Default behavior
    err && (isArray(err) ? err.forEach(el => toast.error(el)) : toast.error(err));
  };

  const content = (
    <div className={anonymousMiniUserProfileMenu(theme)}>
      <img src={loginImage} className={mb({ lg: 12 })} alt="Logo" />
      <label className="login-title">{getText('loginYourAccountAllBenefits')}</label>
      <Form form={form} onFinish={handleLogin}>
        <Form.Item
          label={getText('email')}
          name="email"
          className="email-input"
          rules={[{ required: true, message: getText('fieldIsRequired') }]}
        >
          <Input type="text" placeholder={getText('email')} />
        </Form.Item>
        <Form.Item
          label={getText('password')}
          name="password"
          rules={[{ required: true, message: getText('fieldIsRequired') }]}
        >
          <Input type="password" placeholder={getText('password')} />
        </Form.Item>
      </Form>
      <Button small type="primary" onClick={() => form.submit()}>
        {getText('logIn')}
      </Button>
    </div>
  );

  return (
    <Popover
      ref={userRef}
      content={content}
      arrowPointAtCenter
      placement="bottomRight"
      overlayClassName={popoverWrapper}
      trigger="click"
    >
      <FeatherIcon
        icon="user"
        size={25}
        className={`${userDefaultImageIcon(theme)} ${mr({ lg: 20, md: 20, xs: 20 })}`}
      />
    </Popover>
  );
};

export default AnonymousUserMiniProfile;
