import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { SearchInput, SortDropdown } from 'components/ui';
import { Dropdown, useUrlParams } from 'components/UIExternal';
import { tabFilter } from './styles';

const filterKeys = {
  search: 'searchText',
  type: 'type',
  sorting: 'couponsSorting',
};

export const CouponsOrderHistoryTabFilter = ({ couponsTypes, couponsTypeKey, onChange }) => {
  const { getText } = useTranslations();
  const { queryParams } = useUrlParams();

  return (
    <div className={tabFilter}>
      <Dropdown
        noClear
        options={couponsTypes}
        value={queryParams[couponsTypeKey] ?? couponsTypes.at(0)}
        onChange={val => onChange({ [filterKeys.type]: val.value })}
        className="coupons-history-bought-deals"
      />
      <SearchInput
        placeholder={getText('searchForDeals')}
        defaultValue={queryParams?.searchText}
        onPressEnter={val => onChange({ [filterKeys.search]: val || null })}
        // inputClass={searchInput}
      />
      <SortDropdown
        excludePriceOptions
        value={queryParams.couponsSorting}
        onSortChange={val => onChange({ [filterKeys.sorting]: val })}
        // inputClass={sortDropdown}
      />
    </div>
  );
};

CouponsOrderHistoryTabFilter.filterKeys = filterKeys;

CouponsOrderHistoryTabFilter.propTypes = {
  couponsTypes: PropTypes.array,
  couponsTypeKey: PropTypes.string,
  onChange: PropTypes.func,
};

export default CouponsOrderHistoryTabFilter;
