import { css } from 'emotion';

export const checkbox = (theme, checked) =>
  css({
    borderRadius: '2px',
    marginBottom: '0.5em',
    display: 'flex',
    alignItems: 'center',

    '& .ant-checkbox .ant-checkbox-checked': {
      background: 'none !important',
      border: `2px solid ${theme.skyblue} !important`,
    },
    '& .ant-checkbox-inner': {
      border: checked && `2px solid ${theme.skyblue} !important`,
    },
    '&:hover': {
      background: 'none !important',
    },
  });

export const continentName = theme =>
  css({
    color: `${theme.gray400} !important`,
    fontSize: '0.75rem !important',
    marginLeft: '0.5em !important',
    marginBottom: '-0.125em',
  });

export const collapse = checked =>
  css({
    '& .ant-collapse-header': {
      fontWeight: checked && 500,
      alignItems: 'center !important',
    },
    '& .ant-collapse-content-box': {
      marginBottom: '0px !important',
    },
    '& .ant-collapse-header-text': {
      display: 'flex',
      alignItems: 'center',
    },
  });

export const countryDropdownStyles = css({
  fontSize: '0.875rem',
  lineHeight: '1.5715',
  height: 'auto',
  borderRadius: '4px',
  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});
