import { css } from 'emotion';
import { Mobile } from 'assets/css/breakpoints';

export const modal = css({
  '& .ant-modal-body': {
    paddingBottom: 0,
  },
  '& .ant-modal-footer': {
    padding: '16px 24px 24px 24px',
  },
});

export const modalContentWrapper = theme =>
  css({
    '& h5': {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    '& > label': {
      display: 'inline-block',
      fontSize: '0.75rem',
      color: theme.gray200,
      marginTop: '0.5em',
      marginBottom: '1em',
    },
  });

export const promoteWrapper = theme =>
  css({
    width: '100%',
    marginBottom: '0.5em',
    display: 'flex',
    borderRadius: 4,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    background: theme.gray600,
    padding: '0.75em 0.5em',
    '& .credits-label': { marginLeft: '1em', color: theme.gray200, fontSize: '0.75rem !important' },
    '& h6': { marginLeft: '1em', fontSize: '0.8125rem', marginBottom: 0 },
  });

export const checkbox = css({
  paddingTop: '0.125em !important',
  verticalAlign: 'center',
  '& .ant-checkbox-checked': {
    background: 'white',
  },
});

export const inputNumber = css({
  flex: 'none',
  '& .ant-input-number-handler-wrap': {
    opacity: 1,
  },
  '& .ant-input-number-handler-wrap span': {
    border: 'none',
  },
});

export const availableCreditsWrapper = theme =>
  css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.25em 1em',
    background: theme.gray600,
    '& p': {
      marginBottom: 0,
      fontSize: '0.8125rem',
      fontWeight: 500,
      color: theme.gray200,
    },
    '& a': {
      fontSize: '0.75rem',
      textDecoration: 'underline',
      color: theme.blue2,
    },
    '& a:hover': {
      textDecoration: 'underline',
      color: theme.primary_blue,
    },
    [Mobile]: {
      flexDirection: 'column',
      '& a': {
        marginTop: '0.625em',
      },
    },
  });

export const insufficientCredits = theme =>
  css({
    fontSize: '0.75rem',
    color: theme.red,
  });
