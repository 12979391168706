import { css } from 'emotion';

export const mainContainer = theme =>
  css({
    fontWeight: 400,
    marginBottom: '0.9375em',
    fontSize: '0.875rem',
    color: theme.gray100,
    '& .ant-select': {
      display: 'block',
    },
    '& .ant-select.ant-select-single': {
      backgroundColor: '#F4F4F4',
    },
    '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      border: `1px solid ${theme.gray500}`,
      backgroundColor: 'transparent',
      height: '2.5rem',
    },
    '& .ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
      border: `1px solid ${theme.gray500}`,
    },
    '& .ant-select-selector': {
      paddingTop: '0.1875em !important',
    },
    '& .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      borderColor: theme.gray500,
      boxShadow: 'none',
    },
  });
