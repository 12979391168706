import { css } from 'emotion';
import styled from '@emotion/styled';
import { Mobile } from 'assets/css/breakpoints';

export const oneItemCarouselWrapper = css({
  width: '100%',
  display: 'flex',
  '& .ant-skeleton.ant-skeleton-element': {
    width: '100%',
    height: 'auto !important',
    borderRadius: '4px',
    display: 'flex',
    marginTop: '1.5625em',
    justifyContent: 'center',
  },
  '& .ant-skeleton .ant-skeleton-input': { height: '1.875em !important', borderRadius: '0.25em' },
  '& .ant-skeleton-image': {
    width: '100% !important',
    borderRadius: 4,
    height: '20.3125rem !important',
    marginRight: '3.125em',
    [Mobile]: { marginRight: '0px !important' },
  },
});
export const inputWrapper = css({
  width: '20%',
  justifyContent: 'space-evenly',
  display: 'flex',
  flexDirection: 'column',
});

export const DealContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15.625em, 1fr));
  column-gap: 1.5em;
  row-gap: 1.5em;
  margin-bottom: 1.875em;
`;
