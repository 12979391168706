import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { omit } from '@veraio/core';
import qs from 'query-string';
import axios from 'axios';
import { renewSession, getToken } from '@oneecosystem/authenticate';
import { initAddresses } from '@oneecosystem/dealshaker-core';
import { isUserDisabled } from 'utils/user';
import { getCacheVal, setCacheVal } from 'utils/cacheUtils';
import useError from 'services/errorHandling/useError';
import { getMyProfile, getSingleUserAccount } from 'services';
import { getAllBusinesses } from 'services/api/businessesService';

export const UserContext = createContext({});
export const useUser = () => useContext(UserContext);

// eslint-disable-next-line react/prop-types
export const UserProvider = ({ children }) => {
  const miniCartPopoverRef = useRef();
  const userMiniProfileRef = useRef();
  const [state, setState] = useState({
    isAuthenticated: !!getToken(),
    userInfo: null,
    currentAccount: null,
    error: null,
    isDisabled: false,
    loaded: false,
    updateUserCurrentAccount: () => {},
  });
  const { setError } = useError();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (state?.loaded) document.getElementById('splash-screen').style.display = 'none';
  }, [state?.loaded]);

  const init = async () => {
    const { pathname: path } = window.location;
    const isOnIdentityPage = path === '/authorization-callback' || path === '/logout';

    // In any case we should call renew session, the user may have valid access and refresh tokens
    // The case where the user comes from other application we pass the refresh token as query param rt
    const refreshToken = qs.parse(window.location.search)?.rt;
    await renewSession(refreshToken);
    // If there is refresh token in the url replace the history record for it so it is destroyed and remove it from current query params
    if (refreshToken) {
      window.history.replaceState(null, '', qs.exclude(window.location.href, ['rt']));
      window.location.search = qs.extract(qs.exclude(window.location.href, ['rt']));
    }

    // Get the token from auth packages and see if the user is authenticated or not
    getToken() && !isOnIdentityPage ? fetchUserData() : setState(prev => ({ ...prev, loaded: true }));
  };

  const fetchUserData = async () => {
    const [userData, usersMeError] = await getMyProfile();
    if (usersMeError) return setError(usersMeError);

    const lastSelectedAccount = userData?.accounts?.find(account => account.id === getCacheVal('accountId'));
    !lastSelectedAccount && setCacheVal('accountId', userData?.defaultAccount.id);
    axios.defaults.headers.accountId = getCacheVal('accountId');

    const [currentAccountData, currentAccountDataErr] = await getSingleUserAccount(
      lastSelectedAccount?.id ?? getCacheVal('accountId'),
    );
    currentAccountDataErr && setError(currentAccountDataErr);

    const [resBusinesses, errorBusinesses] = await getAllBusinesses({ accountId: userData?.defaultAccount?.id });
    if (errorBusinesses) return setError(errorBusinesses);

    initAddresses(userData?.userAddresses ?? []);
    setState(prev => ({
      ...prev,
      userInfo: userData,
      currentAccount: currentAccountData ?? userData?.defaultAccount,
      currentAccountBusinesses: resBusinesses?.items,
      isDisabled: isUserDisabled(userData),
      isAuthenticated: true,
      loaded: true,
    }));
  };

  const changeCurrentAccount = newAccount => {
    setCacheVal('accountId', newAccount.id);
    axios.defaults.headers.accountId = getCacheVal('accountId');
    // setState(prev => ({ ...prev, currentAccount: newAccount }));
    window.location.reload();
  };

  const updateCurrentAccount = async () => {
    const accountId = getCacheVal('accountId');
    if (!accountId) return;

    const [res, err] = await getSingleUserAccount(accountId);
    err
      ? setError(err)
      : setState(prev => ({
          ...prev,
          currentAccount: res,
        }));
  };

  const updateCurrentAccountBusinesses = async () => {
    const [resBusinesses, errorBusinesses] = await getAllBusinesses({ accountId: getCacheVal('accountId') });
    if (errorBusinesses) return setError(errorBusinesses);

    setState(prev => ({ ...prev, currentAccountBusinesses: resBusinesses?.items }));
  };

  const updateUserCurrentAccount = async () => {
    const [currentAccount, currentAccountErr] = await getSingleUserAccount(getCacheVal('accountId'));
    currentAccountErr ? setError(currentAccountErr) : setState(prev => ({ ...prev, currentAccount }));
  };

  return (
    <UserContext.Provider
      value={{
        ...omit(state, 'loaded'),
        fetchUserData,
        changeCurrentAccount,
        updateCurrentAccount,
        updateCurrentAccountBusinesses,
        updateUserCurrentAccount,
        isMerchant: !!state?.currentAccountBusinesses?.length,
        miniCartPopoverRef,
        userMiniProfileRef,
      }}
    >
      {state.loaded && children}
    </UserContext.Provider>
  );
};
