import { omit } from '@veraio/core';
import envConfig from 'config/envConfig';
import { getReq, postReq, deleteReq, putReq } from 'services/axios/makeRequest';
import { getReviewsParams } from 'services/models/review';

const baseUrl = `${envConfig.apiAddress}/api/Reviews`;

export const getDealReviewsList = params => getReq(`${baseUrl}/List/${params.dealId}`, { ...omit(params, 'dealId') });

export const getDealReviewsSummary = dealId => getReq(`${baseUrl}/Summary/${dealId}`);

export const getDealReviewsSummaryWithLastReviews = dealId =>
  getReq(`${baseUrl}/Summary/${dealId}`, { calculateLastReviews: true });

export const getBusinessReviewsSummary = businessId => getReq(`${baseUrl}/BusinessSummary/${businessId}`);

export const getReviewsListByBusiness = async (options, businessId) =>
  getReq(`${baseUrl}/Business/${businessId}?${getReviewsParams(options)}`);

export const addNewReview = payload => postReq(`${baseUrl}/Create`, payload);

export const editReview = payload => putReq(`${baseUrl}/Update`, payload);

export const addReviewMerchantReply = payload => postReq(`${baseUrl}/MerchantReply`, payload);

export const getSingleReview = id => getReq(`${baseUrl}/Get/${id}`);

export const deleteReview = id => deleteReq(`${baseUrl}/Delete${id}`);

export const getReviewsList = (id, options) => getReq(`${baseUrl}/List/${id}?${getReviewsParams(options)}`);
