import React from 'react';
import { Toaster } from 'react-hot-toast';
import { useTheme } from 'emotion-theming';
import { alertWrapper, errorWrapper, infoWrapper, successWrapper } from './styles';

const ToasterNotifications = () => {
  const theme = useTheme();
  return (
    <Toaster
      position="bottom-center"
      reverseOrder={false}
      gutter={8}
      containerStyle={{
        position: 'fixed',
        left: 10,
        right: 10,
        bottom: 10,
        zIndex: 1000,
        fontSize: 13,
        display: 'flex',
        textAlign: 'center',
        borderRadius: 32,
      }}
      containerClassName={alertWrapper(theme)}
      toastOptions={{
        duration: 3000,
        // Define default options
        // Default options for specific types
        success: {
          className: successWrapper(theme),
          duration: 3000,
          icon: <></>,
        },
        error: {
          className: errorWrapper(theme),
          duration: 3000,
          icon: <></>,
        },
        loading: {
          className: infoWrapper(theme),
          duration: 3000,
          icon: <></>,
        },
      }}
    />
  );
};
export default ToasterNotifications;
