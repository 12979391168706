import { css } from 'emotion';

export const alertSuccessWrapper = theme =>
  css({
    position: 'fixed',
    left: '0.625em',
    right: '0.625em',
    bottom: '0.625em',
    zIndex: 1000,
    '& .ant-alert': {
      fontSize: '0.8125rem',
      borderRadius: '32px',
    },
    '& .ant-alert-content': {
      textAlign: 'center',
    },
    '& .ant-alert-message, .ant-alert-close-icon .anticon-close': {
      color: 'black',
    },
    '& .ant-alert-success': {
      backgroundColor: theme.green3,
      borderColor: theme.green3,
    },
  });
export const alertErrorWrapper = theme =>
  css({
    position: 'fixed',
    left: '0.625em',
    right: '0.625em',
    bottom: '0.625em',
    zIndex: 1000,
    fontSize: '0.8125rem',
    textAlign: 'center',
    borderRadius: '32px',
    '& .ant-alert': {
      fontSize: '0.8125rem',
      borderRadius: '32px',
    },
    '& .ant-alert-content': {
      textAlign: 'center',
    },
    '& .ant-alert-message, .ant-alert-close-icon .anticon-close': {
      color: 'black',
    },
    '& .ant-alert-error': {
      backgroundColor: theme.pale_yellow,
      borderColor: theme.pale_yellow,
    },
  });
export const alertWrapper = () =>
  css({
    position: 'fixed',
    left: '0.625em',
    width: '100%',
    right: '0.625em',
    bottom: '0.625em',
    zIndex: 1000,
    fontSize: '0.8125rem',
    textAlign: 'center',
    borderRadius: '32px',
  });
export const successWrapper = theme =>
  css({
    width: '100%',
    maxWidth: '56.25rem !important',
    background: `${theme.green3} !important`,
    borderColor: `${theme.green3} !important`,
    borderRadius: '32px !important',
  });
export const errorWrapper = theme =>
  css({
    width: '100%',
    maxWidth: '56.25rem !important',
    background: `${theme.pale_yellow} !important`,
    backgroundColor: `${theme.pale_yellow} !important`,
    borderColor: `${theme.pale_yellow} !important`,
    borderRadius: '32px !important',
  });
export const infoWrapper = theme =>
  css({
    width: '100%',
    maxWidth: '56.25rem !important',
    background: `${theme.primary_blue} !important`,
    borderColor: `${theme.primary_blue} !important`,
    borderRadius: '32px !important',
  });
export const customWrapper = theme =>
  css({
    width: '100%',
    maxWidth: '56.25rem !important',
    background: `${theme.gray400} !important`,
    borderColor: `${theme.gray400} !important`,
    borderRadius: '32px !important',
  });
