import React from 'react';
import PropTypes from 'prop-types';
import { div, mul, sub } from 'exact-math';
import { useTheme } from 'emotion-theming';
import { circleStyles } from './styles';

export const CircleProgress = ({ progress }) => {
  const theme = useTheme();

  return (
    <svg width="80" height="80" viewBox="-20.625 -20.625 206.25 206.25" style={{ transform: 'rotate(-90deg)' }}>
      <circle
        r="72.5"
        cx="82.5"
        cy="82.5"
        fill="transparent"
        stroke={theme.gray500}
        strokeWidth="16px"
        strokeDasharray="455.3px"
        strokeDashoffset="0"
      />
      <circle
        r="72.5"
        cx="82.5"
        cy="82.5"
        stroke={theme.green2}
        strokeWidth="16px"
        strokeLinecap="round"
        // 455px is max px width for this circle
        strokeDashoffset={`${sub(455, div(mul(455, progress), 100))}px`}
        fill="transparent"
        strokeDasharray="455.3px"
        className={circleStyles(sub(455, div(mul(455, progress), 100)))}
      />
    </svg>
  );
};

CircleProgress.propTypes = {
  progress: PropTypes.number,
};
