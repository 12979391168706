import React, { useState, useEffect } from 'react';
import { useTheme } from 'emotion-theming';
import { isNil, isEmpty } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Row, Col } from 'antd';
import ReviewsSummary from 'components/review/ReviewsSummary';
import CommentReview from 'components/review/CommentReview';
import { Dropdown } from 'components/UIExternal';
import { businessStatus } from 'enums/business';
import { dealStatus } from 'enums';
import useError from 'services/errorHandling/useError';
import { getAllBusinessesInfo } from 'services/api/businessesService';
import { getDealReviewsSummary, getReviewsList } from 'services/api/reviewService';
import { getMerchantDealsInfo } from 'services/api/dealsService';
import starBox from 'assets/images/addDeal/star-box.svg';
import { merchantOfficeTab, reviewsContentWrapper, commentsWrapper, dealSelectWrapper } from './styles';

const ReviewsTab = () => {
  const { getText, getDynamicTranslation } = useTranslations();
  const theme = useTheme();
  const { setError } = useError();
  const [businesses, setBusinesses] = useState();
  const [selectedDeal, setSelectedDeal] = useState();
  const [reviewsSummary, setReviewsSummary] = useState();
  const [starsFilter, setStarsFilter] = useState(null);
  const [deals, setDeals] = useState(null);
  const [reviewsList, setReviewsList] = useState();

  useEffect(() => {
    fetchAllBusinessesInfo();
  }, []);

  const fetchAllBusinessesInfo = async () => {
    const [res, err] = await getAllBusinessesInfo({
      statusIds: [
        businessStatus.approved,
        businessStatus.declined,
        businessStatus.deleted,
        businessStatus.edited,
      ].filter(id => !isNil(id)),
    });

    err ? setError(err) : setBusinesses(res);
  };

  const fetchMerchantDeals = async businessId => {
    const [res, err] = await getMerchantDealsInfo({
      businessId,
      statusIds: [dealStatus.approved, dealStatus.soldOut, dealStatus.expired, dealStatus.closed, dealStatus.declined],
    });
    err && setError(err);
    setDeals(res);
  };

  const fetchDealReviewsSummary = async deal => {
    const [res, err] = await getDealReviewsSummary(deal?.id);
    err && setError(err);
    setSelectedDeal(deal);
    setReviewsSummary(res);
  };

  const fetchDealReviewsList = async (id, options) => {
    const [res, err] = await getReviewsList(id, options);
    err && setError(err);
    setReviewsList(res);
  };

  return (
    <div className={merchantOfficeTab}>
      <div className="flex reviews-selectBusiness-searchForDeal" style={{ gap: '1em' }}>
        <Dropdown
          options={businesses?.map(el => ({ ...el, name: getDynamicTranslation(el.name)?.name }))}
          placeholder={getText('selectBusiness')}
          noClear
          displayKey="name"
          uniqueKey="id"
          onChange={val => {
            fetchMerchantDeals(val.id);
            setDeals(null);
            setSelectedDeal(null);
            setReviewsSummary(null);
            setReviewsList(null);
          }}
        />

        <Dropdown
          options={deals?.map(el => ({ ...el, title: getDynamicTranslation(el.title)?.title }))}
          placeholder={getText('searchForDeals')}
          displayKey="title"
          uniqueKey="id"
          disabled={isEmpty(deals)}
          value={selectedDeal}
          onChange={val => {
            if (val) {
              fetchDealReviewsSummary(val);
              fetchDealReviewsList(val?.id, { stars: starsFilter });
              setSelectedDeal(val);
            } else {
              setSelectedDeal(null);
              setReviewsSummary(null);
              setReviewsList(null);
            }
          }}
        />
      </div>
      <Row>
        <Col span={24} className={reviewsContentWrapper}>
          {!isNil(selectedDeal) ? (
            <>
              <h5>{selectedDeal.title}</h5>
              {reviewsSummary && <ReviewsSummary reviewsAndComments={reviewsSummary} setStarsFilter={setStarsFilter} />}
              <div className={commentsWrapper(theme)}>
                {!!reviewsList?.totalCount && (
                  <span>{getText('commentsCount', { count: reviewsList.totalCount })}</span>
                )}
                {reviewsList?.items?.map(item => (
                  <CommentReview reviews={item} />
                ))}
              </div>
            </>
          ) : (
            <div className={dealSelectWrapper(theme)}>
              <img src={starBox} alt="Box with star" />
              <label>{getText('selectDealSeeRating')}</label>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ReviewsTab;
