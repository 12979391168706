import { css } from 'emotion';

export const datePicker = (theme, className) =>
  css(
    {
      '&.ant-picker': {
        border: `1px solid ${theme.gray500}`,
        borderRadius: 2,
      },
      '& .ant-picker-input': {
        flexDirection: 'row-reverse',
      },
      '& .ant-picker-suffix': {
        margin: '-0.1875em 0.25em 0 0',
      },
    },
    className,
  );
