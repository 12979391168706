import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { useTheme } from 'emotion-theming';
import { Button } from 'components/UIExternal';
import MerchantAddressesEnum from 'enums/MerchantAddressesStatusEnum';
import { toggleItemInArray } from 'utils';
import { addressesStyles, addressCart, defaultTag, label, requiredStyles } from './styles';

const BusinessAddressesPicker = ({
  activeAddress = [],
  defaultDeliveryAddressId,
  hasDefaultTag,
  onChange,
  addresses = [],
  toggleIsModalVisible,
}) => {
  const theme = useTheme();
  const { getText, getDynamicTranslation } = useTranslations();

  return (
    <div>
      <div className={label}>
        <span className={requiredStyles(theme)}>*</span>
        {getText('merchantAddresses')}
      </div>
      {addresses?.length ? (
        <div className={addressesStyles}>
          {addresses.map((address, idx) => (
            <Button
              key={idx}
              className={addressCart(theme, activeAddress?.indexOf(address.id) !== -1)}
              type="default"
              disabled={address.statusId === MerchantAddressesEnum.Declined.id}
              onClick={() => onChange(toggleItemInArray(activeAddress, address.id))}
            >
              {hasDefaultTag && defaultDeliveryAddressId === address.id && (
                <p className={defaultTag(theme)}>
                  <span>{getText('default')}</span>
                </p>
              )}
              <h3>{getDynamicTranslation(address.name)?.name}</h3>
              <p>{address.street}</p>
              <p>{`${address.city} ${address.postcode}, ${address.country}`}</p>
            </Button>
          ))}
          <Button type="link" small onClick={toggleIsModalVisible} className={addressCart(theme)}>
            <FeatherIcon icon="plus" size={10} />
            <p>{getText('addNewAddress')}</p>
          </Button>
        </div>
      ) : (
        <div className="content-container">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

BusinessAddressesPicker.propTypes = {
  addresses: PropTypes.array,
  activeAddress: PropTypes.number,
  defaultDeliveryAddressId: PropTypes.number,
  hasDefaultTag: PropTypes.bool,
  onChange: PropTypes.func,
  toggleIsModalVisible: PropTypes.func,
};

export default BusinessAddressesPicker;
