import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Routes } from 'enums';

export const bankDetailsConfig = getText => [
  {
    url: `${Routes.homePage}`,
    label: <FeatherIcon icon="home" size={20} strokeWidth={1} />,
  },
  { url: `${Routes.merchantOffice}`, label: getText('merchantOffice') },
  {
    url: `${Routes.merchantOffice}${Routes.merchantAccounts}`,
    label: getText('merchantAccounts'),
  },
  { label: getText('bankDetails') },
];
