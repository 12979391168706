// badges
import badgesReduced from 'assets/images/brandMaterials/badges/badges_reduced.jpg';
import badgesOriginal from 'assets/images/brandMaterials/badges/badges_original.jpg';
// brochure
import brochureReduced from 'assets/images/brandMaterials/brochure/DealShaker_Brochure_All_reduced.jpg';
import brochureOriginal from 'assets/images/brandMaterials/brochure/DealShaker_Brochure_All_original.jpg';
// bag
import bagStickerReduced from 'assets/images/brandMaterials/bag/bag_reduced.jpg';
import bagStickerOriginal from 'assets/images/brandMaterials/bag/bag_original.jpg';
// billboard landscape
import billboardLandscapeReduced from 'assets/images/brandMaterials/billboard_landscape/billboard_landscape_reduced.jpg';
import billboardLandscapeOriginal from 'assets/images/brandMaterials/billboard_landscape/billboard_landscape_original.jpg';
// billboaard posted
import billboardPosterReduced from 'assets/images/brandMaterials/billboard_poster/billboard_poster_reduced.jpg';
import billboardPosterOridinal from 'assets/images/brandMaterials/billboard_poster/billboard_poster_original.jpg';
// box sticker
import boxStickerReduced from 'assets/images/brandMaterials/box_sticker/box_sticker_reduced.jpg';
import boxStickerOriginal from 'assets/images/brandMaterials/box_sticker/box_sticker_original.jpg';
// business card
import businessCardReduced from 'assets/images/brandMaterials/business_card/business_card_reduced.jpg';
import businessCardOriginal from 'assets/images/brandMaterials/business_card/business_card_original.jpg';
// cap
import capReduced from 'assets/images/brandMaterials/cap/cap_reduced.jpg';
import capOriginal from 'assets/images/brandMaterials/cap/cap_original.jpg';
// car
import carReduced from 'assets/images/brandMaterials/car/car_reduced.jpg';
import carOriginal from 'assets/images/brandMaterials/car/car_original.jpg';
// citylight
import cityLightReduced from 'assets/images/brandMaterials/city_light/city_light_reduced.jpg';
import cityLightOriginal from 'assets/images/brandMaterials/city_light/city_light_original.jpg';
// labels price
import labelsPriceReduced from 'assets/images/brandMaterials/labels_price/labels_price_reduced.jpg';
import labelsPriceOriginal from 'assets/images/brandMaterials/labels_price/labels_price_original.jpg';
// notebook
import noteBookReduced from 'assets/images/brandMaterials/notebook/notebook_reduced.jpg';
import noteBookOriginal from 'assets/images/brandMaterials/notebook/notebook_original.jpg';
// outdoor lampbanner
import outdoorLampBannersReduced from 'assets/images/brandMaterials/outdoor_lamp_banners/outdoor_lamp_banners_reduced.jpg';
import outdoorLampBannersOriginal from 'assets/images/brandMaterials/outdoor_lamp_banners/outdoor_lamp_banners_original.jpg';
// outdoor poster
import outdoorPosterReduced from 'assets/images/brandMaterials/outdoor_poster/outdoor_poster_reduced.jpg';
import outdoorPosterOriginal from 'assets/images/brandMaterials/outdoor_poster/outdoor_poster_original.jpg';
// indoor poster
import indoorPosterBlueReduced from 'assets/images/brandMaterials/indoor_poster/indoor_poster_blue_reduced.jpg';
import indoorPosterBlueOriginal from 'assets/images/brandMaterials/indoor_poster/indoor_poster_blue_original.jpg';
// outdoor store sign
import outdoorStoreSignReduced from 'assets/images/brandMaterials/outdoor_store_sign/outdoor_store_sign_reduced.jpg';
import outdoorStoreSignOriginal from 'assets/images/brandMaterials/outdoor_store_sign/outdoor_store_sign_original.jpg';
// stickers
import stickerMaskReduced from 'assets/images/brandMaterials/sticker_mask/sticker_mask_reduced.jpg';
import stickerMaskOriginal from 'assets/images/brandMaterials/sticker_mask/sticker_mask_original.jpg';
// Street store sign
import storeSignStreetReduced from 'assets/images/brandMaterials/store_sign_street/store_sign_street_reduced.jpg';
import storeSignStreetOriginal from 'assets/images/brandMaterials/store_sign_street/store_sign_street_original.jpg';
// t-shirt
import tShirtBlueReduced from 'assets/images/brandMaterials/t_shirt/t_shirt_blue_reduced.jpg';
import tShirtBlueOriginal from 'assets/images/brandMaterials/t_shirt/t_shirt_blue_original.jpg';

export const materials = [
  {
    id: 1,
    merchantInfo: true,
    imgReduced: bagStickerReduced,
    imgOriginal: bagStickerOriginal,
    isFree: true,
    title: 'paperBagSticker',
    sizeInfo: 'Paper bag sticker A3',
  },
  {
    id: 2,
    merchantInfo: true,
    imgReduced: billboardLandscapeReduced,
    imgOriginal: billboardLandscapeOriginal,
    isFree: true,
    title: 'billboardLandscape',
    sizeInfo: 'Billboard Landscape 400cm x 300cm',
  },
  {
    id: 3,
    merchantInfo: true,
    imgReduced: billboardPosterReduced,
    imgOriginal: billboardPosterOridinal,
    isFree: true,
    title: 'billboardPoster',
    sizeInfo: 'Billboard Poster 300cm x 400cm',
  },
  {
    id: 4,
    merchantInfo: true,
    imgReduced: boxStickerReduced,
    imgOriginal: boxStickerOriginal,
    isFree: true,
    title: 'boxSticker',
    sizeInfo: 'Box sticker 10cm x 25cm',
  },
  {
    id: 5,
    merchantInfo: true,
    imgReduced: businessCardReduced,
    imgOriginal: businessCardOriginal,
    isFree: true,
    title: 'businessCard',
    sizeInfo: 'Business card 50mm x 90mm',
  },
  {
    id: 6,
    merchantInfo: false,
    imgReduced: capReduced,
    imgOriginal: capOriginal,
    isFree: true,
    title: 'cap',
    sizeInfo: 'Cap 5cm x 5cm',
  },
  {
    id: 7,
    merchantInfo: true,
    imgReduced: carReduced,
    imgOriginal: carOriginal,
    isFree: true,
    title: 'car',
    sizeInfo: 'Car 200cm x 50cm',
  },
  {
    id: 8,
    merchantInfo: true,
    imgReduced: cityLightReduced,
    imgOriginal: cityLightOriginal,
    isFree: true,
    title: 'cityLightPoster',
    sizeInfo: 'City Light Poster 120cm x 176cm',
  },
  {
    id: 9,
    merchantInfo: true,
    businessInfo: true,
    imgReduced: labelsPriceReduced,
    imgOriginal: labelsPriceOriginal,
    isFree: true,
    title: 'priceLabels',
    sizeInfo: 'Labels Price 20cm x 12cm',
  },
  {
    id: 10,
    merchantInfo: true,
    imgReduced: noteBookReduced,
    imgOriginal: noteBookOriginal,
    isFree: true,
    title: 'notebook',
    sizeInfo: 'Notebook A5',
  },
  {
    id: 11,
    merchantInfo: true,
    imgReduced: outdoorLampBannersReduced,
    imgOriginal: outdoorLampBannersOriginal,
    isFree: true,
    title: 'lampPostBanner',
    sizeInfo: 'Lamp post banner 80cm x 180cm',
  },
  {
    id: 12,
    merchantInfo: true,
    imgReduced: outdoorPosterReduced,
    imgOriginal: outdoorPosterOriginal,
    isFree: true,
    title: 'outdoorPoster',
    sizeInfo: 'Outdoor Poster 50cm x 70cm',
  },
  {
    id: 13,
    merchantInfo: true,
    imgReduced: outdoorStoreSignReduced,
    imgOriginal: outdoorStoreSignOriginal,
    isFree: true,
    title: 'outdoorStoreSign',
    sizeInfo: 'Outdoor store sign 270cm x 100cm',
  },
  {
    id: 14,
    merchantInfo: false,
    imgReduced: stickerMaskReduced,
    imgOriginal: stickerMaskOriginal,
    isFree: true,
    title: 'stickers',
    sizeInfo: 'Stickers 5cm x 5cm',
  },
  {
    id: 15,
    merchantInfo: true,
    imgReduced: storeSignStreetReduced,
    imgOriginal: storeSignStreetOriginal,
    isFree: true,
    title: 'streetStoreSign',
    sizeInfo: 'Street store sign 50cm x 70cm',
  },
  {
    id: 16,
    merchantInfo: false,
    imgReduced: tShirtBlueReduced,
    imgOriginal: tShirtBlueOriginal,
    isFree: true,
    title: 'tShirt',
    sizeInfo: 'T-shirt A4',
  },
  {
    id: 17,
    merchantInfo: true,
    imgReduced: badgesReduced,
    imgOriginal: badgesOriginal,
    isFree: true,
    title: 'badges',
    sizeInfo: 'Badges 8cm x 12cm',
  },
  {
    id: 18,
    merchantInfo: true,
    imgReduced: brochureReduced,
    imgOriginal: brochureOriginal,
    isFree: true,
    title: 'brochure',
    sizeInfo: 'Brochure 30,5cm x 21cm',
  },
  {
    id: 19,
    merchantInfo: true,
    imgReduced: indoorPosterBlueReduced,
    imgOriginal: indoorPosterBlueOriginal,
    isFree: true,
    title: 'indoorPoster',
    sizeInfo: 'Indoor Poster Blue A3',
  },
];
