import { useState } from 'react';
import { isFunction, deepEqual, isNumber, isString, isPlainObject } from '@veraio/core';
import { useDeepEffect } from 'components/UIExternal';

const findValueInOptions = (val, options) =>
  options.find(option => option.value === (Object.hasOwn(val ?? {}, 'value') ? val.value : val));

const checkForValidValue = val => isNumber(val) || isString(val) || isPlainObject(val);

export const useButtonConfig = ({ onChange, defaultValue, value, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const initDefaultValue = checkForValidValue(defaultValue) ? findValueInOptions(defaultValue, options) : null;
  const [selectedOption, setSelectedOption] = useState(initDefaultValue);
  const selectedIndex = options.findIndex(option => option.value === selectedOption?.value);
  const hasValue = checkForValidValue(value);

  useDeepEffect(() => {
    const matchedValue = findValueInOptions(value, options);
    hasValue && !deepEqual(matchedValue, value) && setSelectedOption(matchedValue);
  }, [value]);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const handleClickOutside = e => !e.currentTarget?.contains(e.relatedTarget) && close();

  const handleSelectOption = item => {
    !hasValue && setSelectedOption(item);
    isFunction && onChange(item.value);
    close();
  };

  const handleClickArrow = () => handleSelectOption(options?.at(selectedIndex + 1) ?? options?.at(0));

  return {
    open,
    close,
    handleSelectOption,
    handleClickArrow,
    isOpen,
    selectedOption,
    selectedIndex,
    handleClickOutside,
  };
};
