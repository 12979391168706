import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useTheme } from 'emotion-theming';
import { Rate } from 'antd';
import { useHistory } from 'react-router-dom';
import { isDesktop, isMobile } from 'react-device-detect';
import { Button, Icon, Modal } from 'components';
import { useUser } from 'stores';
import { formatDefaultDate } from 'utils';
import { Routes } from 'enums';
import merchantDefaultAvatar from 'assets/images/merchant-no-avatar.svg';
import verifiedMerchantPublic from 'assets/images/verifiedMerchantPublic.svg';
import publicMerchantProfileCover from 'assets/images/public-merchant-profile-cover.svg';
import { SharableButtons } from './SharableButtons';
import { merchantCoverImage, merchantInfoContainer, merchantInfo, merchantInfoStatistics } from './styles';

export const PublicMerchantHeader = ({ merchantData }) => {
  const theme = useTheme();
  const modalRef = useRef();
  const { userMiniProfileRef, isAuthenticated, currentAccountBusinesses } = useUser();
  const history = useHistory();
  const { getText, getDynamicTranslation } = useTranslations();
  const [showShareModal, setShowShareModal] = useState(false);
  const isMyBusiness = !!currentAccountBusinesses?.find(business => business.id === merchantData?.id);

  const toggleModal = () => {
    if (showShareModal) {
      setShowShareModal(false);
      window.FreshworksWidget('show');
    } else {
      setShowShareModal(true);
      window.FreshworksWidget('hide');
    }
  };

  const handleLoginClick = () => {
    if (isAuthenticated) history.push(`${Routes.ongoingChat}?userId=${merchantData.merchantId}&dealId=0`);
    else userMiniProfileRef?.current?.onClick();
  };

  return (
    <>
      <div className={merchantCoverImage(merchantData?.coverImage?.url ?? publicMerchantProfileCover)} />
      {/* merchant info */}
      <div className={merchantInfoContainer(theme)}>
        <img src={verifiedMerchantPublic} alt="verified-merchant" className="verified-merchant-img" />
        <div className={merchantInfo(theme)}>
          {/* info */}
          <div className="info">
            <img src={merchantData?.avatar?.url ?? merchantDefaultAvatar} alt="Default Avatar" />
            <div>
              <p className="name">{getDynamicTranslation(merchantData?.name)?.name}</p>
              <div className="contact">
                <span>
                  <Icon iconName="las la-lg la-map-marker" />
                  {merchantData?.country}
                </span>
                <span>
                  <Icon iconName="las la-lg la-phone" />
                  {merchantData?.phone}
                </span>
                <span>
                  <Icon iconName="las la-lg la-at" />
                  {merchantData?.email}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="cta-buttons">
              {!isMyBusiness && (
                <Button type="primary" {...(isMobile && { fullWidth: true })} onClick={handleLoginClick}>
                  <Icon iconName="las la-lg la-sms" />
                  {getText('message')}
                </Button>
              )}
              <Button type="info" {...(isMobile && { fullWidth: true })} onClick={toggleModal}>
                <Icon iconName="las la-lg la-share-square" />
                {getText('share')}
              </Button>
            </div>
            {isDesktop && (
              <p className="last-active">
                {getText('lastActive')}: {formatDefaultDate(merchantData?.lastActive)}
              </p>
            )}
          </div>
        </div>

        <div className={merchantInfoStatistics(theme)}>
          <div className="statistic">
            <span>{getText('soldDeals')}</span>
            <h5>{merchantData?.soldDeals}</h5>
          </div>
          <div className="statistic">
            <span>{getText('merchantSince')}</span>
            <h5>{merchantData?.createdYear}</h5>
          </div>
          <div className="statistic rate">
            <div>
              <Rate disabled defaultValue={merchantData?.rating} />
              <span className="reviews-count">({merchantData?.reviewsCount})</span>
            </div>
            <h5>{merchantData?.rating}</h5>
          </div>
        </div>
      </div>

      <Modal
        show={showShareModal}
        positioned={isMobile ? 'bottom' : 'center'}
        ref={modalRef}
        modalTitle={getText('shareThisProfile')}
        closeIcon={<Icon iconName="las la-lg la-times" onClick={toggleModal} />}
      >
        <SharableButtons toggleModal={setShowShareModal} />
      </Modal>
    </>
  );
};

PublicMerchantHeader.propTypes = {
  merchantData: PropTypes.object,
};
