import { css } from 'emotion';

export const paymentMethodWalletContainer = theme =>
  css({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0.3em',

    '& .title': {
      margin: 0,
    },

    '& .subtitle': {
      fontSize: '0.875rem',
      marginTop: '0.5em',
    },

    '& .insufficientFunds': {
      color: theme.red,
      fontSize: '0.875rem',
      fontWeight: 600,
    },
  });
