import { invert } from 'lodash-es';

export const statisticsPeriod = {
  allTime: 0,
  day: 1,
  week: 2,
  month: 3,
  year: 4,
};

export const statisticsPeriodInverse = invert(statisticsPeriod);

export const statisticsPeriodSelect = Object.entries(statisticsPeriod).map(([label, value]) => ({ label, value }));

export const merchantImageFilePurpose = {
  media: 1,
  coverImage: 2,
  avatar: 3,
};

export const merchantImageFilPurposeInverse = invert(merchantImageFilePurpose);
